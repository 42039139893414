import { optPekerjaan } from "../components/variabel";

export const saksi2Data = (data) => {
  return {
    alamat_saksi2: data.alamat_saksi2,
    nama_saksi2: data.nama_saksi2,
    no_nik_saksi2: data.no_nik_saksi2,
    umur_saksi2: data.umur_saksi2,
    pekerjaan_saksi2: data.pekerjaan_saksi2,
    provinsi_saksi2_id: data.provinsi_saksi2_id,
    kota_saksi2_id: data.kota_saksi2_id,
    kecamatan_saksi2_id: data.kecamatan_saksi2_id,
    kelurahan_saksi2_id: data.kelurahan_saksi2_id,
    warding: {
      alamat_saksi2_warding: data.alamat_saksi2_warding,
      nama_saksi2_warding: data.nama_saksi2_warding,
      no_nik_saksi2_warding: data.no_nik_saksi2_warding,
      umur_saksi2_warding: data.umur_saksi2_warding,
      pekerjaan_saksi2_warding: data.pekerjaan_saksi2_warding,
      provinsi_saksi2_warding: data.provinsi_saksi2_warding,
      kota_saksi2_warding: data.kota_saksi2_warding,
      kecamatan_saksi2_warding: data.kecamatan_saksi2_warding,
      kelurahan_saksi2_warding: data.kelurahan_saksi2_warding,
    },
    lainnya: {
      pekerjaan_saksi2_lainnya: data.pekerjaan_saksi2_lainnya,
    },
  };
};

export const Saksi2Form = ({
  provinsi,
  kota,
  kecamatan,
  kelurahan,
  setValue,
  getValues,
  setError,
}) => {
  return [
    {
      label: "Alamat Saksi",
      name: "saksi2.alamat_saksi2",
      warding: "saksi2.warding.alamat_saksi2_warding",
      data: "alamat_saksi2",
      commentKey: "alamat_saksi2_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Alamat saksi wajib diisi",
      },
    },
    {
      label: "Nama Saksi",
      name: "saksi2.nama_saksi2",
      warding: "saksi2.warding.nama_saksi2_warding",
      data: "nama_saksi2",
      commentKey: "nama_saksi2_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama saksi wajib diisi" },
    },
    {
      label: "No. NIK Saksi",
      name: "saksi2.no_nik_saksi2",
      warding: "saksi2.warding.no_nik_saksi2_warding",
      data: "no_nik_saksi2",
      commentKey: "no_nik_saksi2_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. NIK saksi wajib diisi",
        maxLength: {
          message: "NO. NIK saksi tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Umur Saksi",
      name: "saksi2.umur_saksi2",
      warding: "saksi2.warding.umur_saksi2_warding",
      data: "umur_saksi2",
      commentKey: "umur_saksi2_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Umur saksi wajib diisi",
      },
    },
    {
      label: "Pekerjaan",
      name: "saksi2.pekerjaan_saksi2",
      warding: "saksi2.warding.pekerjaan_saksi2_warding",
      data: "pekerjaan_saksi2",
      commentKey: "pekerjaan_saksi2_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPekerjaan,
      lainnyaKey: "saksi2.lainnya.pekerjaan_saksi2_lainnya",
      lainnyaData: "pekerjaan_saksi2_lainnnya",
      lainnya: "",
      rules: { required: "Pekerjaan wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_saksi2_id",
      warding: "saksi2.warding.provinsi_saksi2_warding",
      name: "saksi2.provinsi_saksi2_id",
      value: "",
      comment: "",
      commentKey: "provinsi_saksi2_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.saksi2[5].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi saksi wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "saksi2.kota_saksi2_id",
      warding: "saksi2.warding.kota_saksi2_warding",
      data: "kota_saksi2_id",
      value: "",
      comment: "",
      commentKey: "kota_saksi2_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!getValues("saksi2.provinsi_saksi2_id")) {
          return setError("forms.saksi2[6].value", {
            type: "noid",
            message: "Pilih Provinsi saksi2 Dahulu",
          });
        }
        kota.fetch(getValues("saksi2.provinsi_saksi2_id"));
        setValue("forms.saksi2[6].loading", true);
      },
      loading: true,
      rules: { required: "Kota saksi2 wajib diisi" },
    },
    {
      name: "saksi2.kecamatan_saksi2_id",
      warding: "saksi2.warding.kecamatan_saksi2_warding",
      label: "Kecamatan",
      data: "kecamatan_saksi2_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_saksi2_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("saksi2.kota_saksi2_id"))) {
          return setError("saksi2.kecamatan_saksi2_id[7].value", {
            type: "noid",
            message: "Pilih kota saksi2 Terlebih Dahulu",
          });
        }
        kecamatan.fetch(getValues("saksi2.kota_saksi2_id"));
        setValue("forms.saksi2[7].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan saksi2 wajib diisi" },
    },
    {
      name: "saksi2.kelurahan_saksi2_id",
      warding: "saksi2.warding.kelurahan_saksi2_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_saksi2_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_saksi2_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("saksi2.kecamatan_saksi2_id"))) {
          return setError("forms.saksi2[8].value", {
            type: "noid",
            message: "Pilih Kecamatan saksi2 Terlebih Dahulu",
          });
        }
        kelurahan.fetch(getValues("saksi2.kecamatan_saksi2_id"));
        setValue("forms.saksi2[8].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan saksi2 wajib diisi" },
    },
  ];
};
