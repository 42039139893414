import React from "react";

const MenungguVerfikasi = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6718_443010)">
        <path
          d="M8.94863 2.23169C5.24397 2.23169 2.21289 5.26277 2.21289 8.96742C2.21289 12.6721 5.24397 15.7032 8.94863 15.7032C12.6533 15.7032 15.6844 12.6721 15.6844 8.96742C15.6844 5.26277 12.6533 2.23169 8.94863 2.23169ZM8.94863 14.356C5.97817 14.356 3.56004 11.9379 3.56004 8.96742C3.56004 5.99697 5.97817 3.57884 8.94863 3.57884C11.9191 3.57884 14.3372 5.99697 14.3372 8.96742C14.3372 11.9379 11.9191 14.356 8.94863 14.356ZM9.28541 5.59956H8.27505V9.641L11.7776 11.7964L12.3165 10.9208L9.28541 9.10214V5.59956Z"
          fill="white"
        />
      </g>
      <rect
        x="1.25062"
        y="1.26942"
        width="15.396"
        height="15.396"
        stroke="#CC1D15"
        strokeWidth="0.769798"
      />
      <defs>
        <clipPath id="clip0_6718_443010">
          <rect
            x="0.865723"
            y="0.884521"
            width="16.1658"
            height="16.1658"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenungguVerfikasi;
