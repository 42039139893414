import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import MainCardHeader2 from "components/Paper/MainCardHeader2";
import useSelect from "hook/useSelect";
import Page from "components/Page";
import useAutoFetch from "hook/useAutoFetch";
import { changeFilter, changeLimit, changePage } from "redux/pengaturan/slice";
import moment from "moment";
import { getManagementMerchant } from "redux/managementMerchant/action";
import { getListPembaruanAplikasi } from "redux/pengaturan/action";
import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import Dialog from "./Dialog";

const PembaruanAplikasi = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState({ dialog: false });
  const fetchData = () => dispatch(getListPembaruanAplikasi(params));

  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.pembaruanAplikasi);

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "Versi Android",
      key: "AndroidVersion",
      width: 25,
    },
    {
      name: "Versi iOS",
      key: "IosVersion",
      width: 25,
    },
    {
      name: "Kategori Pembaruan",
      key: "TextTypeUpdate",
      width: 25,
    },
    {
      name: "Tanggal dan waktu",
      custom: (item) => {
        return item?.DateActive
          ? moment(item?.DateActive).format("DD / MM / YYYY - hh:mm A")
          : "-";
      },
      width: 25,
    },
  ];

  const exportData = useFetchData({
    action: getManagementMerchant,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data,
        filename: "pembaruan-aplikasi",
      });
    },
  });

  const handleExport = (type) => {
    exportData.fetch(null, null, type);
  };

  const version = useMemo(() => {
    return {
      android_version: list?.[0]?.AndroidVersion,
      ios_version: list?.[0]?.IosVersion,
    };
  }, [list]);

  return (
    <>
      <Page
        title="Pembaruan Aplikasi"
        page={["Pengaturan"]}
        menu="Pembaruan Aplikasi"
      />

      <MainCard
        title="Riwayat Pembaruan Aplikasi"
        addButton={false}
        custom={
          <Button
            variant="primary"
            startIcon={<Refresh />}
            onClick={() =>
              setOpen({
                dialog: true,
              })
            }
          >
            Pembaruan App
          </Button>
        }
        borderColor="#C8C8C8"
      >
        <MainCardHeader2
          filterName={filterName}
          changeFilter={changeFilter}
          params={params}
          onChangeExport={(type) => handleExport(type)}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="Bisnis"
          action=""
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
        version={version}
      />
    </>
  );
};

export default PembaruanAplikasi;
