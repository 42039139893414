import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { forwardRef, Fragment, useRef } from "react";
import { Close } from "@mui/icons-material";
import FileValidator from "helper/FileValidator";

const InputImage = forwardRef(
  (
    {
      onChange,
      value,
      accept,
      showImage,
      name,
      onClose,
      maxSize,
      setError,
      ...props
    },
    ref
  ) => {
    const imageRef = useRef();
    return (
      <Fragment>
        <input
          type="file"
          hidden
          ref={imageRef}
          value=""
          accept={accept}
          onChange={(e) => {
            const err = FileValidator(e.target.files[0], {
              size: {
                maxSize: maxSize,
              },
              type: accept.split(","),
            });

            if (err) {
              setError(name, {
                type: "file",
                message: err,
              });
              return;
            }

            onChange(e.target.files[0]);
          }}
        />

        <OutlinedInput
          {...props}
          ref={ref}
          value={value?.name || ""}
          onClick={() => imageRef.current.click()}
          endAdornment={
            <InputAdornment position="end">
              <Box
                sx={{
                  backgroundColor: "#d4d5d5",
                  padding: "11px",
                  color: "#111",
                  fontSize: "14px",
                  fontWeight: "700",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  position: "absolute",
                  right: 0,
                }}
              >
                Buka Folder
              </Box>
            </InputAdornment>
          }
          sx={{
            ".MuiOutlinedInput-root": {
              paddingRight: "0px",
            },
            maxHeight: "46px",
          }}
        />

        {showImage ? (
          <Box
            sx={{
              marginTop: "10px",
              position: "relative",
              objectFit: "contain",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={
                  value
                    ? typeof value === "object"
                      ? URL.createObjectURL(value)
                      : value
                    : ""
                }
                sx={{
                  maxWidth: "100%",
                  maxHeight: 300,
                  borderRadius: "calc(0.6vw + 0.6vh)",
                  objectFit: "contain",
                  border: "1px solid #E5E7EB",
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  color: "#111",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
);

InputImage.displayName = "II";

InputImage.propTypes = {
  maxSize: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.any,
  props: PropTypes.any,
  setError: PropTypes.func,
  showImage: PropTypes.bool,
  value: PropTypes.any,
  name: PropTypes.string,
  accept: PropTypes.string,
};

InputImage.defaultProps = {
  props: null,
  onChange: () => {},
  showImage: false,
  value: "",
  onClose: null,
  maxSize: "5mb",
  setError: null,
  name: "",
  accept: "image/png,image/jpg,image/jpeg",
};

export default InputImage;
