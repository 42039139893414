import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentPage,
  FilterName,
  List,
  Loading,
  Params,
  TotalItems,
} from "redux/kartuSubsidi/selectors";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/kartuSubsidi/slice";

import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import { getKartuSubsidi, getListKartuSubsidi } from "redux/kartuSubsidi/action";
import Providers from "components/Providers";
import { card_status, card_tipe } from "./variables";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";

const KartuSubsidi = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const headers = [
    {
      name: "Penerbit",
      custom: (item) => {
        return `${item.MerchantCode} - ${item.IssuerName}`;
      },
      width: 20,
    },
    {
      name: "Nama Kartu Subsidi",
      key: "CardName",
      width: 60,
    },
    {
      name: "Kode Prefiks",
      key: "PrefixCode",
      width: 20,
    },
  ];

  const filter = [
    {
      label: "Nama Penerbit",
      value: "IssuerName",
    },
    {
      label: "Kode Penerbit",
      value: "IssuerCode",
    },
    {
      label: "Nama Kartu Subsidi",
      value: "CardName",
    },
    {
      label: "Kode Prefiks",
      value: "PrefixCode",
    },
  ];

  const filterData = [
    {
      label: "Tipe Kartu Subsidi",
      key: "CardType",
      placeholder: "Pilih kartu subsidi",
      type: "select",
      options: card_tipe,
    },
    {
      label: "Tipe Status",
      key: "Status",
      placeholder: "Pilih tipe kartu subsidi",
      type: "select",
      options: card_status,
    },
  ];

  const fetchData = async () => {
    await dispatch(getListKartuSubsidi(params));
  };

  const exportData = useFetchData({
    action: getKartuSubsidi,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "kartu-subsidi",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Providers
        title="Master Kartu Subsidi"
        currentPage={currentPage}
        params={params}
        get={fetchData}
      >
        <MainCard
          sx={{
            mt: 2,
            borderRadius: "8px",
          }}
          title="Daftar Kartu Subsidi"
          onClick={() => setOpen(true)}
        >
          <MainCardHeader
            filter={filter}
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            title="Filter Kartu Subsidi"
            onChangeExport={(type) => handleExport(type)}
          />
          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="IssuerName"
            detail={[
              {
                type: "link",
                url_key: "master-data/master-bantuan/kartu-subsidi:id",
                tooltip: "Lihat Detail Penerbit",
                variant: "icon:eye",
              },
            ]}
            increment={params?.offset + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        refresh={fetchData}
        isEdit={false}
        informasi
        ikon
      />
    </>
  );
};

export default KartuSubsidi;
