import React from "react";

const CheckSmallIcon = () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6034 0.481564C12.0847 -0.0882585 12.9404 -0.163106 13.5147 0.314388C14.0448 0.755151 14.1498 1.51248 13.7843 2.07456L13.6831 2.21072L6.6668 10.5185C6.18708 11.0865 5.34533 11.1563 4.77897 10.7049L4.65345 10.5915L0.383208 6.22661C-0.138676 5.69317 -0.125903 4.84095 0.411736 4.32314C0.908019 3.84515 1.67805 3.81919 2.20406 4.23759L2.33017 4.35144L5.55293 7.64459L11.6034 0.481564Z"
        fill="currentColor"
      />
      <mask
        id="mask0_600_2133"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="11"
      >
        <path
          d="M11.6034 0.481564C12.0847 -0.0882585 12.9404 -0.163106 13.5147 0.314388C14.0448 0.755151 14.1498 1.51248 13.7843 2.07456L13.6831 2.21072L6.6668 10.5185C6.18708 11.0865 5.34533 11.1563 4.77897 10.7049L4.65345 10.5915L0.383208 6.22661C-0.138676 5.69317 -0.125903 4.84095 0.411736 4.32314C0.908019 3.84515 1.67805 3.81919 2.20406 4.23759L2.33017 4.35144L5.55293 7.64459L11.6034 0.481564Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_600_2133)"></g>
    </svg>
  );
};

export default CheckSmallIcon;
