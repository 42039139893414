import BroadcastIcon from "assets/layout/sidebar/BroadcastIcon";
const permissionList = "Web:Report:All";

const Broadcast = {
  id: "broadcast",
  type: "collapse",
  title: "Broadcast",
  icon: BroadcastIcon,
  permission: permissionList,
  children: [
    {
      id: "broadcast-sms",
      title: "SMS",
      type: "item",
      url: "/broadcast/sms",
      permission: permissionList,
    },
    {
      id: "broadcast-push-notif",
      title: "Push Notif",
      type: "item",
      url: "/broadcast/push-notif",
      permission: permissionList,
    },
    {
      id: "broadcast-email",
      title: "Email",
      type: "item",
      url: "/broadcast/email",
      permission: permissionList,
    },
    {
      id: "broadcast-email-marketing",
      title: "Email Marketing",
      type: "item",
      url: "/broadcast/email-marketing",
      permission: permissionList,
    },
  ],
};

export default Broadcast;
