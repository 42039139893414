import Dashboard from "./dashboard";
import MasterData from "./master-data";
import ManagementPengguna from "./management-pengguna";
import CSRBansos from "./csr-bansos";
import LaporanAdjudicator from "./laporan-adjudicator";
import LaporanTransaksi from "./laporan-transaksi";
import ManagementMerchant from "./management-merchant";
import Pengaturan from "./pengaturan";
import Broadcast from "./broadcast";

const menuItems = [
  Dashboard,
  MasterData,
  ManagementMerchant,
  Broadcast,
  Pengaturan,
  ManagementPengguna,
  LaporanAdjudicator,
  LaporanTransaksi,
  CSRBansos,
];

export default menuItems;
