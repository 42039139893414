import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete as AC, Popper, TextField } from "@mui/material";
import React, { forwardRef } from "react";

const PopperCom = (io) => {
  return (
    <Popper
      {...io}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
    />
  );
};

const Autocomplete = forwardRef(({ error, InputProps, ...props }, ref) => {
  return (
    <AC
      {...props}
      ref={ref}
      PopperComponent={PopperCom}
      renderInput={(p) => (
        <TextField
          {...p}
          placeholder={props.placeholder}
          InputProps={{
            ...p.InputProps,
            ...InputProps,
            variant: props.variant,
            error: props.error,
            endAdornment: (
              <>
                <ExpandMore
                  className="IconEndAdornment"
                  onMouseDown={p.inputProps.onMouseDown}
                  sx={{
                    position: "absolute",
                    right: "13px",
                    color: "#4E4751",
                  }}
                />
              </>
            ),
          }}
        />
      )}
    />
  );
});

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.bool,
  InputProps: PropTypes.object,
  // inputSX: PropTypes.object,
};

Autocomplete.defaultProps = {
  placeholder: "-",
  variant: "",
  error: false,
  // inputSX: {},
  InputProps: {},
};
Autocomplete.displayName = "mac";
export default Autocomplete;
