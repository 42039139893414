import React from "react";

const User = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 21 22"
      fill="none"
      style={{
        paddingBottom: "2px",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9994 22H0.000394835V21.0826C-0.00800476 18.6362 0.0927904 17.2392 2.78066 16.588C5.72682 15.8752 8.63308 15.2394 7.23665 12.5378C3.09355 4.5364 6.0544 0 10.4999 0C14.8614 0 17.8936 4.367 13.7673 12.5378C12.4087 15.2218 15.2121 15.8598 18.2212 16.588C20.9175 17.2414 21.0099 18.6472 20.9994 21.1134V22Z"
        fill="white"
      />
    </svg>
  );
};

export default User;
