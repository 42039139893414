import {
  Box,
  Button,
  OutlinedInput,
  SwipeableDrawer,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import React, { useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { changeFilter } from "redux/managementPengguna/Role/slice";
import CardMobile from "components/Mobile/CardMobile";
import { ChevronRight } from "@mui/icons-material";
import TrashIcon from "assets/paper/TrashIcon";
import CardRole from "../components/cardRole";
import permission from "api/permission";
import { deleteRole, getListRole } from "redux/managementPengguna/action";
import { Link } from "react-router-dom";
import DeleteDialog from "components/Mobile/DeleteDialog";
import useFetchData from "hook/useFetchData";

const PeranMobile = () => {
  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListRole(params));

  const deleteRef = useRef(null);

  const { list, loading, params, totalItems, filterName } = useSelect(
    (state) => state.role
  );

  const action = useFetchData({
    action: deleteRole,
    message: "Berhasil hapus role",
    refresh: fetchData,
    onSuccess: () => {
      setOpen({
        ...open,
        delete: false,
      });
    },
  });

  useAutoFetch(fetchData, params.page);

  const [dialog, setDialog] = useState({
    alert: true,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const handleClickDelete = (item) => (event) => {
    deleteRef.current = {
      id: item.Id,
      name: item.ClientName,
    };
    toggleDrawer("delete", true)(event);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={0.5}>
          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile title="Daftar Merchant Baru">
          {list?.map((row, index) => (
            <Accordion key={index} disableGutters>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  "&.Mui-expanded": {
                    ".arrow": {
                      transform: "rotate(90deg)",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <ChevronRight
                      className="arrow"
                      sx={{
                        transition: "0.2s",
                      }}
                    />
                    <Typography variant="text2">{row.ClientName}</Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                <Box pl={5}>
                  <CardRole data={permission} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Link
                    to={`/management-pengguna/peran/${row.Id}`}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      variant="secondary-outlined"
                    >
                      Lihat Detail
                    </Button>
                  </Link>
                  <Button
                    variant="secondary-outlined-icon"
                    sx={{
                      width: "60px",
                    }}
                    onClick={handleClickDelete(row)}
                  >
                    <TrashIcon />
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <DeleteDialog
        open={dialog.delete}
        loading={false}
        onClose={toggleDrawer("delete", false)}
        title="Hapus Peran"
        desc="Anda yakin ingin menghapus peran"
        name={deleteRef?.current?.name}
        onSubmit={() => action.fetch(deleteRef.current.id)}
      />
    </>
  );
};

export default PeranMobile;
