import moment from "moment";
import { optPekerjaan, optWargaNegara } from "../components/variabel";

export const ayahData = (data) => {
  return {
    alamat_ayah: data.alamat_ayah,
    nama_ayah: data.nama_ayah,
    no_nik_ayah: data.no_nik_ayah,
    kewarganegaraan_ayah: data.kewarganegaraan_ayah,
    kebangsaan_ayah: data.kebangsaan_ayah,
    pekerjaan_ayah: data.pekerjaan_ayah,
    tanggal_lahir_ayah: moment(new Date(data.tanggal_lahir_ayah)).format("DD / MM / YYYY"),
    provinsi_ayah_id: data.provinsi_ayah_id,
    kota_ayah_id: data.kota_ayah_id,
    kecamatan_ayah_id: data.kecamatan_ayah_id,
    kelurahan_ayah_id: data.kelurahan_ayah_id,
    warding: {
      alamat_ayah_warding: data.alamat_ayah_warding,
      nama_ayah_warding: data.nama_ayah_warding,
      no_nik_ayah_warding: data.no_nik_ayah_warding,
      kewarganegaraan_ayah_warding: data.kewarganegaraan_ayah_warding,
      kebangsaan_ayah_warding: data.kebangsaan_ayah_warding,
      pekerjaan_ayah_warding: data.pekerjaan_ayah_warding,
      tanggal_lahir_ayah_warding: data.tanggal_lahir_ayah_warding,
      provinsi_ayah_warding: data.provinsi_ayah_warding,
      kota_ayah_warding: data.kota_ayah_warding,
      kecamatan_ayah_warding: data.kecamatan_ayah_warding,
      kelurahan_ayah_warding: data.kelurahan_ayah_warding,
    },
    lainnya: {
      pekerjaan_ayah_lainnya: data.pekerjaan_ayah_lainnya,
    },
  };
};

export const AyahForm = ({
  provinsi,
  kota,
  kecamatan,
  kelurahan,
  setValue,
  getValues,
  setError,
}) => {
  return [
    {
      label: "Alamat Ayah",
      name: "ayah.alamat_ayah",
      warding: "ayah.warding.alamat_ayah_warding",
      data: "alamat_ayah",
      commentKey: "alamat_ayah_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Alamat ayah wajib diisi",
      },
    },
    {
      label: "Nama Ayah",
      name: "ayah.nama_ayah",
      warding: "ayah.warding.nama_ayah_warding",
      data: "nama_ayah",
      commentKey: "nama_ayah_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama ayah wajib diisi" },
    },
    {
      label: "No. NIK Ayah",
      name: "ayah.no_nik_ayah",
      warding: "ayah.warding.no_nik_ayah_warding",
      data: "no_nik_ayah",
      commentKey: "no_nik_ayah_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. NIK ayah wajib diisi",
        maxLength: {
          message: "NO. NIK ayah tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Warga Negara",
      name: "ayah.kewarganegaraan_ayah",
      warding: "ayah.warding.kewarganegaraan_ayah_warding",
      data: "kewarganegaraan_ayah",
      commentKey: "kewarganegaraan_ayah_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optWargaNegara,
      rules: { required: "Kewarganegaraan ayah wajib diisi" },
    },
    // {
    //   label: "Kebangsaan",
    //   name: "ayah.kebangsaan_ayah",
    //   warding: "ayah.warding.kebangsaan_ayah_warding",
    //   data: "kebangsaan_ayah",
    //   commentKey: "kebangsaan_ayah_warding",
    //   comment: "",
    //   value: "",
    //   openNotes: false,
    //   openComment: false,
    //   type: "select",
    //   options: optWargaNegara,
    //   rules: { required: "Kebangsaan wajib diisi" },
    // },
    {
      label: "Pekerjaan",
      name: "ayah.pekerjaan_ayah",
      warding: "ayah.warding.pekerjaan_ayah_warding",
      data: "pekerjaan_ayah",
      commentKey: "pekerjaan_ayah_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPekerjaan,
      lainnyaKey: "ayah.lainnya.pekerjaan_ayah_lainnya",
      lainnyaData: "pekerjaan_ayah_lainnnya",
      lainnya: "",
      rules: { required: "Pekerjaan wajib diisi" },
    },
    {
      label: "Tanggal Lahir",
      name: "ayah.tanggal_lahir_ayah",
      warding: "ayah.warding.tanggal_lahir_ayah_warding",
      data: "tanggal_lahir_ayah",
      commentKey: "tanggal_lahir_ayah_wardingF",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "date",
      rules: { required: "Tanggal Lahir wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_ayah_id",
      warding: "ayah.warding.provinsi_ayah_warding",
      name: "ayah.provinsi_ayah_id",
      value: "",
      comment: "",
      commentKey: "provinsi_ayah_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.ayah[7].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi ayah wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "ayah.kota_ayah_id",
      warding: "ayah.warding.kota_ayah_warding",
      data: "kota_ayah_id",
      value: "",
      comment: "",
      commentKey: "kota_ayah_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!getValues("ayah.provinsi_ayah_id")) {
          return setError("forms.ayah[8].value", {
            type: "noid",
            message: "Pilih Provinsi ayah Dahulu",
          });
        }
        kota.fetch(getValues("ayah.provinsi_ayah_id"));
        setValue("forms.ayah[8].loading", true);
      },
      loading: true,
      rules: { required: "Kota ayah wajib diisi" },
    },
    {
      name: "ayah.kecamatan_ayah_id",
      warding: "ayah.warding.kecamatan_ayah_warding",
      label: "Kecamatan",
      data: "kecamatan_ayah_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_ayah_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("ayah.kota_ayah_id"))) {
          return setError("ayah.kecamatan_ayah_id[9].value", {
            type: "noid",
            message: "Pilih kota ayah Terlebih Dahulu",
          });
        }
        kecamatan.fetch(getValues("ayah.kota_ayah_id"));
        setValue("forms.ayah[9].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan ayah wajib diisi" },
    },
    {
      name: "ayah.kelurahan_ayah_id",
      warding: "ayah.warding.kelurahan_ayah_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_ayah_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_ayah_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("ayah.kecamatan_ayah_id"))) {
          return setError("forms.ayah[10].value", {
            type: "noid",
            message: "Pilih Kecamatan ayah Terlebih Dahulu",
          });
        }
        kelurahan.fetch(getValues("ayah.kecamatan_ayah_id"));
        setValue("forms.ayah[10].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan ayah wajib diisi" },
    },
  ];
};
