import PropTypes from "prop-types";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Popover,
  styled,
  Typography,
} from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import moment from "moment";
import CalendarIcon from "assets/paper/CalendarIcon";
import Years from "./components/Years";

const DateRangePicker = styled(Box)(({ width }) => ({
  backgroundColor: "#fff",
  padding: "10px",
  paddingBottom: "20px",
  paddingTop: "15px",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  justifyContent: "center",
  width: width ? width + 3 : "0px",
  maxWidth: "370px",
}));

const DateRangePickerNav = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "14px",
  margin: "0px 10px",
  // margin: "15px 11px",
}));

const ButtonChangeMonth = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  width: "10px 10px !important",
  minWidth: "0px !important",
  backgroundColor: "#f9e8e7",
  color: "#cc1d15",
  fontSize: "14px",
}));

const TableTd = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "600",
  transition: "all 0.2s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f9e8e7",
  },
}));

const Separator = styled(Box)({
  padding: "5px 5px !important",
  backgroundColor: "#fff",
});

const Table = styled(Box)({
  width: "100%",
  textAlign: "center",
  marginTop: "10px",
  transition: "all 1s",
  thead: {
    tr: {
      padding: "5px",
      fontWeight: "600 !important",
      transition: "all 1s",
      color: "#74788d",
    },
  },
});

const Heading = ({ date, changeMonth, resetDate }) => (
  <DateRangePickerNav>
    <ButtonChangeMonth onClick={() => changeMonth(date.month() - 1)}>
      <ChevronLeft />
    </ButtonChangeMonth>
    <div
      onClick={() => resetDate()}
      style={{ cursor: "pointer", fontWeight: "700", fontSize: "14px" }}
    >
      {date.format("MMMM")} {date.format("YYYY")}
    </div>
    <ButtonChangeMonth onClick={() => changeMonth(date.month() + 1)}>
      <ChevronRight />
    </ButtonChangeMonth>
  </DateRangePickerNav>
);

Heading.propTypes = {
  changeMonth: PropTypes.func,
  date: PropTypes.shape({
    format: PropTypes.func,
    month: PropTypes.func,
  }),
  resetDate: PropTypes.func,
};

Heading.defaultProps = {
  changeMonth: () => {},
  date: null,
  resetDate: () => {},
};

const Day = ({ date, value, onClick, past }) => {
  const isPast = moment().subtract(1, "day").isAfter(date);
  if (date === null) {
    return <td onClick={() => onClick(date)}>{""}</td>;
  }
  let className = [];

  if (moment().isSame(date, "day")) {
    className.push("active");
  }

  if (date.isSame(value, "day")) {
    className.push("selected");
  }

  if (past && isPast) {
    className.push("muted");
  }

  return (
    <TableTd
      component={"td"}
      onClick={() => {
        if (past && isPast) {
          return;
        }
        onClick(date);
      }}
      sx={{
        ...(past &&
          isPast && {
            cursor: "not-allowed",
          }),
        ".selected": {
          backgroundColor: "#cc1d15",
          color: "#fff",
        },
        ".muted": {
          color: "#F9E8E7",
        },
      }}
    >
      <Typography
        component="div"
        variant="labelMedium2"
        className={className.join(" ")}
        sx={{
          transition: "all 0.2s",
          width: "30px",
          height: "25px",
          justifyContent: "center",
          margin: "auto",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {date.date() || ""}
      </Typography>
    </TableTd>
  );
};

Day.propTypes = {
  currentDate: PropTypes.any,
  date: PropTypes.shape({
    date: PropTypes.func,
    isBetween: PropTypes.func,
    isSame: PropTypes.func,
  }),
  value: PropTypes.any,
  onClick: PropTypes.func,
  past: PropTypes.any,
};

Day.defaultProps = {
  currentDate: null,
  date: null,
  value: null,
  onClick: () => {},
  past: null,
};

const Days = ({ date, value, onClick, past }) => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf("month");
  const previousMonth = moment(date).subtract(1, "month");
  const previousMonthDays = previousMonth.daysInMonth();
  let days = [];
  let labels = [];

  for (let i = 0; i <= 6; i++) {
    labels.push(<span className="label">{moment().day(i).format("dd")}</span>);
  }

  for (let i = firstDayDate.day(); i > 0; i--) {
    previousMonth.date(previousMonthDays - i + 1);

    days.push(
      <Day
        key={moment(previousMonth).format("DD MM YYYY")}
        currentDate={date}
        date={null}
        value={null}
        past={past}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);
    days.push(
      <Day
        key={moment(thisDate).format("DD MM YYYY")}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(thisDate)}
        value={value}
        past={past}
      />
    );
  }

  let list = [];

  for (let i = 0; i < days.concat().length; i += 7) {
    let slicIt = days.concat().slice(i, i + 7);
    list.push(slicIt);
  }

  return (
    <Table
      component={"table"}
      sx={{
        width: "100%",
        tableLayout: "auto",
        marginTop: "20px",
      }}
    >
      <thead>
        <tr>
          {labels.map((label, i) => (
            <Box component="td" key={i}>
              <Box
                sx={{
                  width: "30px",
                  fontSize: "14px",
                  margin: "auto",
                }}
              >
                {label}
              </Box>
            </Box>
          ))}
        </tr>
        <tr>
          <Separator component="th" colSpan={7}></Separator>
        </tr>
      </thead>
      <tbody>
        {list.map((tr, i) => (
          <Fragment key={i}>
            <tr className="boy">{tr.map((td) => td)}</tr>
            <Box
              component="tr"
              sx={{
                "&:last-child": {
                  display: "none",
                },
              }}
            >
              <Separator component="td" colSpan={7}></Separator>
            </Box>
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

Days.propTypes = {
  date: PropTypes.any,
  value: PropTypes.any,
  onClick: PropTypes.func,
  past: PropTypes.any,
};

Days.defaultProps = {
  date: null,
  value: null,
  onClick: () => {},
  past: null,
};

const DatePicker2 = forwardRef(
  (
    {
      onChange,
      placeholder,
      value,
      error,
      past = false,
      format,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menu, setMenu] = useState(1);
    const [state, setState] = useState({
      date: moment(),
    });

    useEffect(() => {
      if (moment(value).isValid()) {
        let formatted = format
          ? moment(value, format).format("YYYY-MM-DD")
          : moment(value).format("YYYY-MM-DD");
        let newDate = moment(new Date(formatted));
        setState({
          date: newDate,
        });
      }
    }, [value]);

    const newValue = useMemo(() => {
      if (moment(value).isValid()) {
        let formatted = format
          ? moment(value, format).format("YYYY-MM-DD")
          : moment(value).format("YYYY-MM-DD");
        return moment(new Date(formatted));
      } else {
        return value;
      }
    }, [value]);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const resetDate = () => {
      setState({
        date: moment(),
      });
    };

    const changeMonth = (month) => {
      const { date } = state;

      date.month(month);

      setState({
        ...state,
        date: date,
      });
    };

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;
    const { date } = state;

    const handleChange = (date) => {
      const d = moment(new Date(date)).format("YYYY-MM-DD");
      // console.log(date);
      onChange(d);
      handleClose();
    };

    const changeYears = () => {
      // const years = getYears();
      setMenu(0);
      // console.log(years);
    };

    const chooseYear = (item) => {
      let data = moment(date).set("year", item);
      setState({
        date: data,
      });
      setMenu(1);
    };

    return (
      <>
        <OutlinedInput
          {...props}
          ref={ref}
          sx={{
            border: open ? "1px solid #cc1d15" : "none",
            borderRadius: "4px",
            ".MuiOutlinedInput-root": {
              paddingRight: "0px",
            },
            ...props.sx,
          }}
          error={error}
          onClick={(e) => {
            if (props.disabled || props.readOnly) return;
            handleClick(e);
          }}
          readOnly
          endAdornment={
            <Box
              sx={{
                position: "absolute",
                top: 13,
                right: "13px",
                color: "#4E4751",
              }}
              className="IconEndAdornment"
            >
              <CalendarIcon />
            </Box>
          }
          placeholder={placeholder}
          value={
            moment(newValue).isValid()
              ? moment(newValue).format("DD / MM / YYYY")
              : defaultValue
          }
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              marginTop: "10px",
              boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          {menu == 1 && (
            <DateRangePicker width={anchorEl?.offsetWidth}>
              <Heading
                date={date}
                changeMonth={(month) => changeMonth(month)}
                resetDate={() => changeYears()}
              />

              <Days
                onClick={(date) => handleChange(date)}
                date={date}
                value={newValue}
                past={past}
              />
            </DateRangePicker>
          )}
          {menu == 0 && <Years onChange={(item) => chooseYear(item)} />}
        </Popover>
      </>
    );
  }
);

DatePicker2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  past: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  sx: PropTypes.object,
};

DatePicker2.defaultProps = {
  value: null,
  onChange: () => {},
  past: false,
  error: false,
  placeholder: "DD / MM / YYYY",
  format: "YYYY-MM-DD",
  disabled: false,
  readOnly: false,
  defaultValue: "",
  sx: {},
};

DatePicker2.displayName = "dp";

export default DatePicker2;
