import Providers from "components/Providers";
import React from "react";
import BroadcastSMS from "pages/Broadcast/SMS";
import BroadcastPushNotif from "pages/Broadcast/Notif";
import BroadcastEmail from "pages/Broadcast/Email";
import BroadcastEmailMarketing from "pages/Broadcast/EmailMarketing";
import SMSBroadcastDetail from "pages/Broadcast/SMS/Detail";
import NotifBroadcastDetail from "pages/Broadcast/Notif/Detail";
import EmailbroadcastDetail from "pages/Broadcast/Email/Detail";
import ResponsiveView from "components/ResponsiveView";
import BroadcastSMSMobile from "pages/Broadcast/SMS/Mobile";
import BroadcastNotifMobile from "pages/Broadcast/Notif/Mobile";
import BroadcastEmailMobile from "pages/Broadcast/Email/Mobile";
import BroadcastEmailMarketingMobile from "pages/Broadcast/EmailMarketing/Mobile";
import RouteRoleProviders from "components/RouteRoleProviders";

const broadcastRoutes = [
  {
    path: "/broadcast/sms",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <ResponsiveView onDesktop={BroadcastSMS} onMobile={BroadcastSMSMobile} />
      </RouteRoleProviders>
    ),
  },
  {
    path: "broadcast/sms/:id",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <SMSBroadcastDetail />
      </RouteRoleProviders>
    ),
  },
  {
    path: "/broadcast/push-notif",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <ResponsiveView
          onDesktop={BroadcastPushNotif}
          onMobile={BroadcastNotifMobile}
        />
      </RouteRoleProviders>
    ),
  },
  {
    path: "/broadcast/push-notif/:id",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <NotifBroadcastDetail />
      </RouteRoleProviders>
    ),
  },
  {
    path: "/broadcast/email",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <ResponsiveView
          onDesktop={BroadcastEmail}
          onMobile={BroadcastEmailMobile}
        />
      </RouteRoleProviders>
    ),
  },
  {
    path: "/broadcast/email/:id",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <EmailbroadcastDetail />
      </RouteRoleProviders>
    ),
  },
  {
    path: "/broadcast/email-marketing",
    element: (
      <RouteRoleProviders permission="Web:Broadcast:All">
        <ResponsiveView
          onDesktop={BroadcastEmailMarketing}
          onMobile={BroadcastEmailMarketingMobile}
        />
      </RouteRoleProviders>
    ),
  },
];

export default broadcastRoutes;
