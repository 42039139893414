const permission = [
  "Web:Profile:Get",
  "Web:Profile:Edit",
  "Web:Profile:Delete",
  "Web:Password:Edit",
  "Web:Media:Edit",
  "Web:Clients:Get",
  "Web:Client:Create",
  "Web:Client:Get",
  "Web:Client:Edit",
  "Web:Client:Delete",
  "Web:RolePermission:Create",
  "Web:RolePermissions:Get",
  "Web:RolePermission:Edit",
  "Web:RolePermission:Delete",
  "Web:Roles:Get",
  "Web:Role:Create",
  "Web:Role:Get",
  "Web:Role:Edit",
  "Web:Role:Delete",
  "Web:UserPermission:Create",
  "Web:UserPermissions:Get",
  "Web:UserPermission:Edit",
  "Web:UserPermission:Delete",
  "Web:UserRole:Create",
  "Web:UserRoles:Get",
  "Web:UserRole:Edit",
  "Web:UserRole:Delete",
  "Web:Users:Get",
  "Web:User:Create",
  "Web:User:Get",
  "Web:User:Edit",
  "Web:User:Delete",
  "Web:User:Upgrade:Approve",
  "Web:User:Upgrade:Reject",
  "Web:Documents:Get",
  "Web:Document:Get",
  "Web:KategoriLokasi:All",
  "Web:Kependudukan:All",
  "Web:Lokasi:All",
  "Web:Cms:All",
  "Web:ProgramBantuan:All",
  "Web:PenerimaBantuan:All",
  "Web:Voucher:All",
  "Web:Admin:Webdashboard",
  "Web:Admin:Super",
  "Web:Admin:Visor",
  "Web:Admin:Operation",
  "Web:Report:All",
  "Web:AdjAdmin:All",
  "Web:AdjSp:All",
  "Web:AdjOps:All",
  "Web:Voucher:All",
];

export default permission;
