import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getListEmailBroadcast } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const emailSlice = createSlice({
  name: "broadcast-email",
  initialState,
  reducers: pagination,
  extraReducers: {
    [getListEmailBroadcast.pending]: (state) => {
      state.loading = true;
    },
    [getListEmailBroadcast.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListEmailBroadcast.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = emailSlice.actions;

export default emailSlice.reducer;
