import PropTypes from "prop-types";
import MainController from "components/Controller/MainController";
import React from "react";
import Radio from "components/Radio/Radio";

const InputRadio = ({ getProps, open, ...p }) => {
  return (
    <MainController
      {...getProps(`data.${p.name}`)}
      rules={p.rules || {}}
      render={({ field }) => <Radio {...field} data={p.value} />}
    />
  );
};

InputRadio.propTypes = {
  getProps: PropTypes.func,
  open: PropTypes.any,
};

InputRadio.defaultProps = {
  getProps: {},
  open: true,
};

export default InputRadio;
