import PropTypes from "prop-types";
import { Box, Radio as A, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { Circle } from "@mui/icons-material";

const Radio = forwardRef(
  ({ data, value, flexDirection, onChange, ...props }, ref) => {
    return (
      <Box
        display="flex"
        flexDirection={flexDirection}
        alignItems={flexDirection == "column" ? "start" : "center"}
        gap={2}
        ref={ref}
      >
        {data.map((item, i) => (
          <Box key={i} display="flex" alignItems="center" gap={1}>
            <A
              icon={<Circle />}
              checked={item.value === value}
              sx={{
                p: 0,
              }}
              onChange={() => onChange(item.value)}
            />
  
            <Typography
              variant="gridValue"
              sx={{
                color: item.value === value ? "#cc1d15" : "#111",
              }}
              onClick={() => onChange(item.value)}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }
);

Radio.displayName = "r";
Radio.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.func,
  flexDirection: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Radio.defaultProps = {
  data: [],
  selected: null,
  onChange: null,
  value: null,
  flexDirection: "row",
};
export default Radio;
