export const languangeList = [
  {
    label: "Indonesia",
    value: "id",
  },
  {
    label: "Inggris",
    value: "en",
  },
];

export const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];
