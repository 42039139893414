import {
  Box,
  Button,
  OutlinedInput,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import React, { useRef, useState } from "react";
import { ChevronRight, Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { changeFilter } from "redux/managementMerchant/slice";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import {
  getDetailMerchant,
  getListManagementMerchant,
} from "redux/managementMerchant/action";
import ManagementMerchantStatus from "components/Status/ManagementMerchant";
import AccordionMobileDetail from "components/Mobile/AccordionMobileDetail";
import MobileBadge2 from "components/Mobile/BadgeDetail";
import useFetchData from "hook/useFetchData";

const headers = [
  {
    name: "Tipe Bisnis",
    key: "TipeBisnis",
  },
  {
    name: "Status",
    custom: (item) => <ManagementMerchantStatus value={item.Status} auto />,
  },
];

const detail0 = [
  {
    name: "Status ",
    custom: (item) => <ManagementMerchantStatus value={item.Status} />,
  },
  {
    name: "Nama Bisnis",
    key: "NamaBisnis",
  },
  {
    name: "Kode Bisnis",
    key: "KodeBisnis",
  },
  {
    name: "Nama Pemilik",
    key: "Owner",
  },
  {
    name: "Email",
    key: "Email",
  },
  {
    name: "Website",
    key: "Website",
  },
  {
    name: "Instagram",
    key: "Instagram",
  },
  {
    name: "Nomor Ponsel",
    key: "Phone",
  },
];

const detail1 = [
  {
    name: "Tipe Bisnis",
    key: "TipeBisnis",
  },
  {
    name: "Produk",
    key: "Produk",
  },
  {
    name: "Tipe Entitas Bisnis",
    key: "TipeEntitasBisnis",
  },
  {
    name: "Kategori Bisnis",
    key: "KategoriBisnis",
  },
  {
    name: "URL Bisnis",
    key: "URLBisnis",
  },
  {
    name: "Email PIC Finance",
    key: "EmailPICFinance",
  },
  {
    name: "No. Ponsel PIC Finance",
    key: "NoPICFinance",
  },
  {
    name: "Email PIC Teknis",
    key: "EmailPICTeknis",
  },
  {
    name: "No. Ponsel PIC Teknis",
    key: "NoPICTeknis",
  },
];

const detail2 = [
  {
    name: "Alamat Pemilik",
    key: "AlamatPemilik",
  },
  {
    name: "Kewarganegaraan",
    key: "Kewarganegaraan",
  },
  {
    name: "Kode Pos",
    key: "KodePos",
  },
  {
    name: "No. Ponsel Pemilik",
    key: "NoPemilik",
  },
];

const detail3 = [
  {
    name: "KTP Pemilik Bisnis",
    custom: (item) => {
      return (
        <Typography
          variant="text2"
          sx={{ color: "#cc1d15", display: "flex", alignItems: "center" }}
        >
          Lihat KTP <ChevronRight />
        </Typography>
      );
    },
  },
  {
    name: "Nama Sesuai KTP",
    key: "NamaKTP",
  },
  {
    name: "Nomor KTP",
    key: "NomorKTP",
  },
  {
    name: "NPWP Badan Usaha",
    custom: (item) => {
      return (
        <Typography
          variant="text2"
          sx={{ color: "#cc1d15", display: "flex", alignItems: "center" }}
        >
          Lihat NPWP <ChevronRight />
        </Typography>
      );
    },
  },
  {
    name: "Nama Sesuai NPWP",
    key: "NamaKTP",
  },
  {
    name: "Nomor NPWP",
    key: "NomorKTP",
  },
  {
    name: "Alamat Sesuai NPWP",
    key: "AlamatNPWP",
  },
];

const detail4 = [
  {
    name: "Nama Bank",
    key: "NamaPemilik",
  },
  {
    name: "Nomor Rekening",
    key: "NamaRekening",
  },
  {
    name: "Nama Pemilik Rekening",
    key: "Rekening",
  },
];

const detail5 = [
  {
    name: "Catatan Operator",
    key: "CatatanOperator",
  },
  {
    name: "Catatan Supervisor",
    key: "CatatanSupervisor",
  },
];

const ManagementMerchantMobile = () => {
  const dispatch = useDispatch();

  const { list, params, filterName } = useSelect(
    (state) => state.managementMerchant
  );

  const detail = useFetchData({
    action: getDetailMerchant,
    snackbar: false,
    onSuccess: (_, event) => {
      toggleDrawer("detail", true)(event);
    },
  });

  const [page, setPage] = useState(0);

  const dataDetail = useRef({});

  const fetchData = () => dispatch(getListManagementMerchant(params));

  useAutoFetch(fetchData);

  const [dialog, setDialog] = useState({
    alert: true,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const changePage = (value) => () => setPage(value);

  const handleClickDetail = (item) => (event) => {
    dataDetail.current = item;
    detail.fetch(item.Id, null, event);
  };

  const handleClickDelete = (item) => (event) => {
    return;
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={0.5}>
          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile title="Daftar Merchant Baru">
          <AccordionMobile
            list={list}
            headers={headers}
            label="Bisnis"
            value="Bisnis"
            handleClickDetail={handleClickDetail}
            handleClickDelete={handleClickDelete}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        onOpen={toggleDrawer("detail", true)}
      >
        <DrawerTitle
          title="Detail Merchant"
          toggle={toggleDrawer("detail", false)}
        />
        <Box
          display="flex"
          gap={2}
          p={2}
          sx={{
            width: "inherit",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <MobileBadge2
            active={page == 0}
            value="Detail Bisnis"
            onClick={changePage(0)}
          />

          <MobileBadge2
            active={page == 1}
            value="Informasi Bisnis"
            onClick={changePage(1)}
          />
          <MobileBadge2
            active={page == 2}
            value="Informasi Pemilik"
            onClick={changePage(2)}
          />
          <MobileBadge2
            active={page == 3}
            value="Dokumen"
            onClick={changePage(3)}
          />
          <MobileBadge2
            active={page == 4}
            value="Detail Bank"
            onClick={changePage(4)}
          />
          <MobileBadge2
            active={page == 5}
            value="Catatan"
            onClick={changePage(5)}
          />
        </Box>

        {page == 0 && (
          <AccordionMobileDetail
            headers={detail0}
            data={detail?.response?.DetailBisnis}
          />
        )}
        {page == 1 && (
          <AccordionMobileDetail
            headers={detail1}
            data={detail.response.InformasiBisnis}
          />
        )}
        {page == 2 && (
          <AccordionMobileDetail
            headers={detail2}
            data={detail.response.InformasiPemilik}
          />
        )}
        {page == 3 && (
          <AccordionMobileDetail
            headers={detail3}
            data={detail.response.Dokumen}
          />
        )}
        {page == 4 && (
          <AccordionMobileDetail
            headers={detail4}
            data={detail.response.Bank}
          />
        )}
        {page == 5 && (
          <AccordionMobileDetail
            headers={detail5}
            data={detail.response.Catatan}
          />
        )}
      </SwipeableDrawer>
    </>
  );
};

export default ManagementMerchantMobile;
