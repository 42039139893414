import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import PropTypes from "prop-types";

const MainCard = ({
  title,
  onClick,
  addButton,
  buttonText,
  children,
  isEdit,
  custom,
  customTitle,
  paddingTitle,
  borderColor,
  margin,
  ...props
}) => {
  return (
    <Paper
      sx={{
        mt: margin ? 2 : 0 ,
        borderRadius: "8px",
        minHeight: "60px",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: paddingTitle,
          alignItems: "center",
          minHeight: "74px",
        }}
      >
        {!Boolean(customTitle) ? (
          <Typography variant={"h2"}>{title}</Typography>
        ) : (
          customTitle
        )}
        {isEdit && (
          <Button
            variant="primary-outlined"
            onClick={onClick}
            sx={{ padding: "10px 10px" }}
          >
            UBAH
          </Button>
        )}
        {addButton && (
          <Button variant="primary" startIcon={<AddIcon />} onClick={onClick}>
            {buttonText}
          </Button>
        )}
        {custom && <Box sx={{ maxWidth: "350px" }}>{custom}</Box>}
      </Box>
      <Divider
        sx={{
          borderColor: borderColor,
        }}
      />
      {children}
    </Paper>
  );
};

MainCard.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func,
  addButton: PropTypes.bool,
  buttonText: PropTypes.string,
  isEdit: PropTypes.bool,
  custom: PropTypes.node,
  customTitle: PropTypes.node,
  paddingTitle: PropTypes.number,
  borderColor: PropTypes.string,
  sx: PropTypes.any,
  margin: PropTypes.bool,
};

MainCard.defaultProps = {
  children: null,
  title: "-",
  onClick: () => {},
  addButton: true,
  buttonText: "Tambah",
  isEdit: false,
  sx: {},
  custom: null,
  customTitle: null,
  borderColor: "#f7f7f7",
  paddingTitle: 2,
  margin: true,
};
export default MainCard;
