import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import PrivateRoute from "components/PrivateRoute";
import PageNotFound from "pages/PageNotFound";
import penebitRoutes from "./penerbit";
import kartuSubsidiRoutes from "./kartu-subsidi";
import voucherRoutes from "./voucer";
import subsidiBansosRoutes from "./subsidi-bansos";
import penerimaBantuanRoutes from "./penerima-bantuan";
import voucherBansosRoutes from "./voucher-bansos";
import penggunaRoutes from "./pengguna";
import ChartDasbor from "pages/Dasbor/ChartDasbor";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ForgotPasswordConfirm from "pages/Authentication/ForgotPasswordKonfirmasi";
import sentraLokasiRoutes from "./sentra-lokasi";
import laporanAdjudicatorRoutes from "./laporan-adjudicator";
import hargaprodukppobRoutes from "./harga-produk-ppob";
import digitalIDRoutes from "./digital-id";
import laporanTransaksiRoutes from "./laporan-transaksi";
import managementMerchantRoutes from "./management-merchant";
import pengaturanRoutes from "./pengaturan";
import ProsesPembayaran from "pages/QoinCash/ProsesPembayaran";
import broadcastRoutes from "./broadcast";
import bannerRoutes from "./banner";
import ProsesPembayaranLoader from "pages/QoinCash/ProsesPembayaran/Loader";
import cmsDokumenRoutes from "./dokumen";
import cmsFormulirRoutes from "./formulir";
import Loader from "pages/Loader";
import EmailValidate from "pages/Authentication/ForgotPasswordValidate";

const router = createBrowserRouter([
  {
    path: "/auth/login/",
    element: <Login />,
  },
  {
    path: "/auth/forgot-password/",
    element: <ForgotPassword />,
  },
  {
    path: "forgot-password/reset-password",
    element: <ForgotPasswordConfirm />,
  },
  {
    path: "forgot-password/validate",
    element: <EmailValidate />,
  },
  {
    path: "qoin-cash/proses-pembayaran",
    element: <ProsesPembayaran />,
  },
  {
    path: "qoin/back-to-app",
    element: <ProsesPembayaranLoader />,
  },
  {
    path: "callback/loader",
    element: <Loader />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: <ChartDasbor />,
      },
      ...penebitRoutes,
      ...kartuSubsidiRoutes,
      ...voucherRoutes,
      ...subsidiBansosRoutes,
      ...voucherBansosRoutes,
      ...penerimaBantuanRoutes,
      ...penggunaRoutes,
      ...sentraLokasiRoutes,
      ...laporanAdjudicatorRoutes,
      ...hargaprodukppobRoutes,
      ...digitalIDRoutes,
      ...laporanTransaksiRoutes,
      ...managementMerchantRoutes,
      ...pengaturanRoutes,
      ...broadcastRoutes,
      ...bannerRoutes,
      ...cmsDokumenRoutes,
      ...cmsFormulirRoutes,
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;
