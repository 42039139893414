import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";
import { DateRange } from "@mui/icons-material";

const DateInput = ({ item }) => {
  return (
    <InputComponent item={item}>
      <OutlinedInput
        placeholder={item?.property?.keterangan?.value || "Placeholder"}
        readOnly
        endAdornment={<DateRange />}
      />
    </InputComponent>
  );
};

DateInput.propTypes = {
  item: PropTypes.object,
};
DateInput.defaultProps = {
  item: {},
};
export default DateInput;
