import React from "react";
import Providers from "components/RouteRoleProviders";
import KependudukanKTP from "pages/Kependudukan/KTP";
import KependudukanAktaLahir from "pages/Kependudukan/AktaLahir";
import KependudukanKK from "pages/Kependudukan/KK";
import DetailKTP from "pages/Kependudukan/KTP/Detail";
import DetailKK from "pages/Kependudukan/KK/Detail";
import DetailAktaLahir from "pages/Kependudukan/AktaLahir/Detail";

const laporanAdjudicatorRoutes = [
  {
    path: "/laporan-adjudicator/sentra-kependudukan/ktp",
    element: (
      <Providers permission="Web:User:Get">
        <KependudukanKTP />
      </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/sentra-kependudukan/ktp/:id",
    element: (
      <Providers permission="Web:User:Get">
        <DetailKTP />
      </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/sentra-kependudukan/kk",
    element: (
      <Providers permission="Web:User:Get">
        <KependudukanKK />
      </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/sentra-kependudukan/kk/:id",
    element: (
      <Providers permission="Web:User:Get">
        <DetailKK />
      </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/sentra-kependudukan/akta-lahir",
    element: (
      <Providers permission="Web:User:Get">
        <KependudukanAktaLahir />
      </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/sentra-kependudukan/akta-lahir/:id",
    element: (
      <Providers permission="Web:User:Get">
        <DetailAktaLahir />
      </Providers>
    ),
  },
];

export default laporanAdjudicatorRoutes;
