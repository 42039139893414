export const barChatData = [
  {
    label: "Gas",
    value: 642,
  },
  {
    label: "HP Pasca Bayar",
    value: 453,
  },
  {
    label: "ISAT",
    value: 345,
  },
  {
    label: "PBB",
    value: 213,
  },
  {
    label: "PDAM Jawa Barat",
    value: 99,
  },
];

