import Providers from "components/Providers";
import ResponsiveView from "components/ResponsiveView";
import ManagementMerchant from "pages/ManagementMerchant";
import ManagementMerchantDetail from "pages/ManagementMerchant/Detail";
import ManagementMerchantMobile from "pages/ManagementMerchant/Mobile";
import React from "react";

const managementMerchantRoutes = [
  {
    path: "/management-merchant/merchant",
    element: (
      <Providers permission="Web:User:All">
        <ResponsiveView
          onDesktop={ManagementMerchant}
          onMobile={ManagementMerchantMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/management-merchant/merchant/:id",
    element: (
      <Providers permission="Web:User:All">
        <ManagementMerchantDetail />
      </Providers>
    ),
  },
];

export default managementMerchantRoutes;
