import axios from "axios";

export const API = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "https://dev-inisa.loyalto.id/"
      : process.env.REACT_APP_API_URL,
});

export const APIAuth = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "https://dev-inisa.loyalto.id/"
      : process.env.REACT_APP_API_URL,
});

// : // ? "http://10.10.101.0:9141/api/v1"s
// "http://10.4.0.70:8080/api/inisa/"
// "http://10.10.4.0.70:9141/api/v1/
// "http://10.10.101.0:8070/api/inisa/"
// baseURL: "http://10.10.101.190:3000/api/v1/",
