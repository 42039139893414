import React from "react";

const MultiSelect = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM10.7412 14.9996C10.7476 15 10.7536 15 10.76 15C10.9477 15 11.1281 14.9308 11.2611 14.8067L16.2922 10.1255C16.5693 9.86808 16.5693 9.4508 16.2922 9.19303C16.016 8.93566 15.5667 8.93566 15.2905 9.19303L10.7874 13.3834L8.73522 11.2596C8.47357 10.9891 8.02594 10.9668 7.7348 11.2102C7.44365 11.4537 7.42014 11.8702 7.68178 12.1411L10.2333 14.7816C10.3632 14.9161 10.5471 14.9948 10.7412 14.9996Z"
        fill="black"
      />
      <mask
        id="mask0_6780_20515"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM10.7412 14.9996C10.7476 15 10.7536 15 10.76 15C10.9477 15 11.1281 14.9308 11.2611 14.8067L16.2922 10.1255C16.5693 9.86808 16.5693 9.4508 16.2922 9.19303C16.016 8.93566 15.5667 8.93566 15.2905 9.19303L10.7874 13.3834L8.73522 11.2596C8.47357 10.9891 8.02594 10.9668 7.7348 11.2102C7.44365 11.4537 7.42014 11.8702 7.68178 12.1411L10.2333 14.7816C10.3632 14.9161 10.5471 14.9948 10.7412 14.9996Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_20515)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default MultiSelect;
