import React from "react";

const PencilIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4417 0.0256348L9.68228 1.78626L13.2393 5.34376L15 3.58063L11.4417 0.0256348ZM11.4767 5.31563L12.3724 6.21001L4.45331 14.1281L0 15.0256L0.899412 10.5713L8.81537 2.65376L9.70978 3.54813L2.05009 11.1881L1.59632 13.4281L3.83641 12.9763L11.4767 5.31563ZM10.8136 4.65188L3.74266 11.7244L3.30201 11.2813L10.3729 4.21001L10.8136 4.65188Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PencilIcon;
