import React, { useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/managementPengguna/Pengguna/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import { getListPengguna, getPengguna } from "redux/managementPengguna/action";
import {
  List,
  Loading,
  FilterName,
  Params,
  TotalItems,
} from "redux/managementPengguna/Pengguna/selectors";
import RoleUser from "components/Status/RoleUser";
import moment from "moment";
import MainCardHeader2 from "components/Paper/MainCardHeader2";

const LogActivity = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const filterName = useSelector(FilterName);

  const dispatch = useDispatch();

  const [open, setOpen] = useState({
    open: false,
    import: false,
  });

  const tempData = [
    {
      tgltime: "01 / 02 / 2020 - 10:45 AM",
      Fullname: "Juan Encalada",
      TypeRole: 1,
      RoleName: "Super Admin",
      Aktivitas: "Tambah pengguna officer",
      Status: 1,
    },
    {
      tgltime: "01 / 02 / 2020 - 10:45 AM",
      Fullname: "Emma John",
      TypeRole: 4,
      RoleName: "Officer",
      Aktivitas: "Keterangan aktifitas",
      Status: 1,
    },
    {
      tgltime: "01 / 02 / 2020 - 10:45 AM",
      Fullname: "Leio McLaren",
      TypeRole: 2,
      RoleName: "Supervisor",
      Aktivitas: "Keterangan aktifitas",
      Status: 2,
    },
    {
      tgltime: "01 / 02 / 2020 - 10:45 AM",
      Fullname: "Alex Suprun",
      TypeRole: 3,
      RoleName: "Operator",
      Aktivitas: "Keterangan aktifitas",
      Status: 1,
    },
    {
      tgltime: "01 / 02 / 2020 - 10:45 AM",
      Fullname: "Leio McLaren",
      TypeRole: 2,
      RoleName: "Supervisor",
      Aktivitas: "Keterangan aktifitas",
      Status: 2,
    },
    {
      tgltime: "01 / 02 / 2020 - 10:45 AM",
      Fullname: "Leio McLaren",
      TypeRole: 2,
      RoleName: "Supervisor",
      Aktivitas: "Keterangan aktifitas",
      Status: 2,
    },
  ];

  const headers = [
    {
      name: "Tanggal & Waktu",
      key: "tgltime",
      width: 30,
    },
    {
      name: "Nama",
      key: "Fullname",
      width: 25,
    },
    {
      name: "Role",
      custom: (item) => (
        <RoleUser value={item.TypeRole} string={item.RoleName} />
      ),
      width: 30,
    },
    {
      name: "Aktivitas",
      key: "Aktivitas",
      width: 25,
    },
    {
      name: "Status",
      custom: (item) => (
        <AktifStatus
          value={parseInt(item.Status)}
          customText={["Berhasil", "Gagal"]}
        />
      ),
      width: 25,
    },
  ];

  const filterData = [
    {
      label: "Status",
      key: "Status",
      type: "switch",
      options: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Aktif",
          value: 0,
        },
      ],
    },
  ];

  const fetchData = async () => {
    await dispatch(getListPengguna(params));
  };

  const exportData = useFetchData({
    action: getPengguna,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "penerbit",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Providers
        title="Log Aktivitas"
        currentPage={params.page}
        desc
        params={params}
        get={fetchData}
      >
        <MainCard
          sx={{
            mt: 2,
            borderRadius: "8px",
          }}
          title="Log Aktivitas"
          //   onClick={() => {
          //     setDialogData({});
          //     setOpen({
          //       ...open,
          //       open: true,
          //     });
          //   }}
          borderColor="#C8C8C8"
        >
          <MainCardHeader2
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            onChangeExport={(type) => handleExport(type)}
            title="Filter Log Aktifitas"
          />
          <MainTable
            headers={headers}
            loading={false}
            data={tempData}
            summaryKey="Fullname"
            action=""
            increment={(params.page - 1) * params.limit + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={params?.page}
          />
        </MainCard>
      </Providers>
    </>
  );
};

export default LogActivity;
