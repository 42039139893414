import Providers from "components/Providers";
import ResponsiveView from "components/ResponsiveView";
import LaporanTransaksiPBB from "pages/LaporanTransaksi/PBB";
import PBBMobile from "pages/LaporanTransaksi/PBB/Mobile";
import LaporanTransaksiPPOB from "pages/LaporanTransaksi/PPOB";
import PPOBMobile from "pages/LaporanTransaksi/PPOB/Mobile";
import LaporanTransaksiTransferKeluar from "pages/LaporanTransaksi/TransferKeluar";
import TransferKeluarMobile from "pages/LaporanTransaksi/TransferKeluar/Mobile";
import LaporanTransaksiTransferMasuk from "pages/LaporanTransaksi/TransferMasuk";
import TransferMasukMobile from "pages/LaporanTransaksi/TransferMasuk/Mobile";
import React from "react";

const laporanTransaksiRoutes = [
  {
    path: "/laporan-transaksi/ppob",
    element: (
      <Providers permission="Web:Report:All">
        <ResponsiveView
          onDesktop={LaporanTransaksiPPOB}
          onMobile={PPOBMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/laporan-transaksi/pbb",
    element: (
      <Providers permission="Web:Report:All">
        <ResponsiveView onDesktop={LaporanTransaksiPBB} onMobile={PBBMobile} />
      </Providers>
    ),
  },
  {
    path: "/laporan-transaksi/transfer-masuk",
    element: (
      <Providers permission="Web:Report:All">
        <ResponsiveView
          onDesktop={LaporanTransaksiTransferMasuk}
          onMobile={TransferMasukMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/laporan-transaksi/transfer-keluar",
    element: (
      <Providers permission="Web:Report:All">
        <ResponsiveView
          onDesktop={LaporanTransaksiTransferKeluar}
          onMobile={TransferKeluarMobile}
        />
      </Providers>
    ),
  },
];

export default laporanTransaksiRoutes;
