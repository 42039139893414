import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";
import { AccessTime } from "@mui/icons-material";


const TimeInput = ({ item }) => {
  return (
    <InputComponent item={item}>
      <OutlinedInput
        placeholder={item?.property?.keterangan?.value || "Placeholder"}
        readOnly
        endAdornment={<AccessTime />}
      />
    </InputComponent>
  );
};

TimeInput.propTypes = {
  item: PropTypes.object,
};

TimeInput.defaultProps = {
  item: {},
};

export default TimeInput;
