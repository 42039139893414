import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, post } from "api";
import { Pengaturan } from "api/endpoints";

export const getListPembaruanAplikasi = createAsyncThunk(
  "pengaturan/getListPembaruanAplikasi",
  async (params) => {
    const response = await getParams(Pengaturan.pembaruanAplikasiGet, params);
    return response.data.data;
  }
);

export const getListLoginSession = async (params) => {
  const response = await getParams(Pengaturan.loginSessionGet, params);
  return response;
};

export const actionPembaruanAplikasi = async (data) => {
  const response = await post(Pengaturan.pembaruanAplikasiAction, data);
  return response;
};

export const actionLoginSession = async (data) => {
  const response = await post(Pengaturan.loginSessionAction, data);
  return response;
};
