import PropTypes from "prop-types";
import {
  OutlinedInput,
  Box,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";
import Autocomplete from "components/AutoComplete/Autocomplete";

const Select = ({ item, handleChangeContent }) => {
  const renderLabel = (option) => {
    return (
      <FormControlLabel
        onClick={(e) => {
          e.stopPropagation();
          handleChangeContent(option.id, item?.id, "value");
        }}
        className="m-0"
        value={option?.id}
        control={
          <Radio
            checked={option?.id === item?.value}
            className="p-0"
            sx={{
              color: "#dedede",
              "&.Mui-checked": {
                color: "#cc1d15",
              },
            }}
          />
        }
        label={<span className="pl-2">{option?.label}</span>}
      />
    );
  };
  return (
    <InputComponent item={item}>
      {item?.format?.type === "dropdown" ? (
        <Autocomplete
          getOptionLabel={(option) => `${String(option.label)}` || null}
          value={(function () {
            let val = item?.option.find((v) => v.id === item?.value);
            if (!val) return null;
            return val;
          })()}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id || null
          }
          renderOption={(option) => {
            return (
              <Box
                {...option}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#111",
                  padding: "10px 15px",
                  gap: "10px",
                  borderBottom: "1px solid #f7f7f7",
                }}
              >
                <Radio
                  checked={option?.["aria-selected"]}
                  sx={{
                    padding: "0px",
                    color: "#dedede",
                    "&.Mui-checked": {
                      color: "#cc1d15",
                    },
                  }}
                />
                <Typography variant="label2">{option.key}</Typography>
              </Box>
            );
          }}
          disablePortal
          onChange={(_, option) =>
            handleChangeContent(option.id, item?.id, "value")
          }
          placeholder={item?.property?.keterangan?.value || "Placeholder"}
          options={item?.option}
        />
      ) : (
        <RadioGroup
          sx={{
            display: "flex",
            padding: "10px",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
          }}
          value={item?.value}
          name="radio"
        >
          {item?.option.map((v, i) => (
            <React.Fragment key={i}>{renderLabel(v)}</React.Fragment>
          ))}
        </RadioGroup>
      )}
    </InputComponent>
  );
};

Select.propTypes = {
  handleChangeContent: PropTypes.func,
  item: PropTypes.object,
};
Select.defaultProps = {
  item: {},
  handleChangeContent: () => {},
};
export default Select;
