import { Box, LinearProgress } from "@mui/material";
import React from "react";
import LoaderIcon from "assets/loader/LoaderIcon";
import Logo from "assets/layout/logo";
import LogoSmall from "assets/layout/logoSmall";
import InisaLogo from "assets/login/new-inisa-logo.png";

const Loader = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap:  4,
      }}
    >
      <svg
        width="61"
        height="75"
        viewBox="0 0 61 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M60.6875 30.4989C60.6875 13.8104 47.2212 0.28125 30.6095 0.28125C13.9979 0.28125 0.53125 13.8104 0.53125 30.4989C0.53125 42.4157 7.39969 52.7172 17.3744 57.6368L17.3763 57.6329C18.0156 57.972 18.743 58.1659 19.5166 58.1659C22.0525 58.1659 24.1085 56.1004 24.1085 53.5527C24.1085 51.7204 23.0425 50.1418 21.5017 49.3978L21.5065 49.3882C14.5323 45.9899 9.72337 38.809 9.72337 30.4989C9.72337 18.9106 19.0745 9.51606 30.6095 9.51606C42.1446 9.51606 51.4954 18.9106 51.4954 30.4989C51.4954 37.4588 48.1189 43.6232 42.9255 47.4405C38.9033 50.3512 35.0328 46.683 35.0328 42.6582V30.6161H35.027V27.4926C35.027 26.5759 34.2871 25.8325 33.3747 25.8325H27.4462C26.5338 25.8325 25.7939 26.5759 25.7939 27.4926V30.6161V30.9228V44.8146H25.7945C25.8028 48.3353 29.2316 55.5506 35.8025 56.4634C44.5176 57.6087 48.7036 54.6588 49.8411 53.7279L49.8421 53.7292C49.8523 53.7209 49.8616 53.7115 49.8716 53.7031C49.91 53.6716 49.9453 53.6419 49.9758 53.6159C49.9758 53.6159 49.9735 53.6139 49.9732 53.6136C56.522 48.0705 60.6875 39.7756 60.6875 30.4989Z"
          fill="#D8232A"
        />
        <path
          d="M30.4036 23.8146C32.9571 23.8146 35.0269 21.7349 35.0269 19.1695C35.0269 16.6041 32.9571 14.5244 30.4036 14.5244C27.85 14.5244 25.7803 16.6041 25.7803 19.1695C25.7803 21.7349 27.85 23.8146 30.4036 23.8146Z"
          fill="#AB2430"
        />
        <mask
          id="mask0_7446_3384"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="61"
          height="59"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.531738 30.4988C0.531738 42.4156 7.39986 52.7172 17.3746 57.6367L17.3765 57.6331C18.0161 57.9723 18.7435 58.1662 19.5168 58.1662C22.053 58.1662 24.1087 56.1003 24.1087 53.5526C24.1087 51.7203 23.0427 50.1421 21.5019 49.3981L21.5067 49.3884C14.5325 45.9898 9.72386 38.8089 9.72386 30.4988C9.72386 18.9105 19.0747 9.51598 30.6097 9.51598C42.1444 9.51598 51.4959 18.9105 51.4959 30.4988C51.4959 37.4591 48.1194 43.6234 42.926 47.4405C38.9035 50.3511 35.0326 46.6829 35.0326 42.6585V30.6164H35.0272V27.4925C35.0272 26.5758 34.2873 25.8324 33.3748 25.8324H27.4464C26.534 25.8324 25.794 26.5758 25.794 27.4925V30.6164V30.923V44.8148H25.7947C25.803 48.3352 29.2318 55.5509 35.8027 56.4636C44.5181 57.6087 48.7038 54.6587 49.8413 53.7282L49.8422 53.7291C49.8525 53.7208 49.8618 53.7114 49.8717 53.7031C49.9102 53.6718 49.9455 53.6422 49.9759 53.6158C49.9759 53.6158 49.9737 53.6138 49.9734 53.6135C56.5222 48.0704 60.6877 39.7755 60.6877 30.4988C60.6877 13.8103 47.2214 0.281494 30.6097 0.281494C13.9977 0.281494 0.531738 13.8103 0.531738 30.4988Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_7446_3384)">
          <path
            d="M35.027 22.9922C35.027 31.5516 35.0328 40.8302 35.0328 42.6583C35.0328 45.2833 38.4859 58.503 52.1106 53.2523C44.2717 65.6281 29.9001 57.7573 27.6604 54.4737C25.4207 51.2838 22.528 33.4164 24.3009 25.7386C27.1939 24.5198 35.027 22.9922 35.027 22.9922Z"
            fill="#AB2430"
          />
        </g>
        <path d="M6.08789 74.5H8.57507V60.5828H6.08789V74.5Z" fill="#D8232A" />
        <path
          d="M23.2079 66.1707C23.1316 65.7111 22.987 65.2553 22.786 64.8347C22.4199 63.9899 21.9255 63.2513 21.3039 62.6274C20.9823 62.3244 20.6415 62.0416 20.2901 61.7887C19.9211 61.5224 19.5143 61.3024 19.0937 61.1371C18.212 60.769 17.316 60.5825 16.4289 60.5825C15.5258 60.5825 14.6207 60.769 13.7516 61.1317C13.3188 61.3017 12.912 61.5224 12.5417 61.7887C12.3471 61.9292 12.1553 62.0783 11.9675 62.2367L11.9829 60.5825H9.49023V74.4998H11.9774L11.9768 67.4448C11.9912 67.1582 12.0294 66.8754 12.0896 66.6026C12.1483 66.334 12.2313 66.0676 12.3342 65.8154C12.5712 65.2495 12.8908 64.7693 13.3008 64.3715C13.6788 63.9754 14.1559 63.6536 14.7265 63.4114C15.2196 63.1924 15.7925 63.0809 16.4289 63.0809C17.0194 63.0809 17.5878 63.194 18.1178 63.4159C18.6484 63.6391 19.1273 63.9631 19.5409 64.3796C19.7471 64.5873 19.9285 64.8118 20.0821 65.0469C20.2417 65.2917 20.3834 65.5542 20.4988 65.8151C20.7358 66.3836 20.8563 66.9643 20.8563 67.5412V74.4998H23.3432L23.3429 67.4916C23.3246 67.0435 23.2794 66.5984 23.2079 66.1707Z"
          fill="#D8232A"
        />
        <path d="M24.1729 74.5H26.6597V60.5828H24.1729V74.5Z" fill="#D8232A" />
        <path
          d="M29.8572 65.8414C29.9762 65.5574 30.1153 65.2974 30.2692 65.0681C30.425 64.8378 30.6087 64.6153 30.8174 64.4056C31.2467 63.9743 31.7247 63.6506 32.2463 63.4409C32.8109 63.2023 33.3889 63.0812 33.9641 63.0812C34.5389 63.0812 35.1166 63.2023 35.6805 63.4403C35.9655 63.5614 36.2249 63.7009 36.4506 63.8548C36.6802 64.0114 36.9023 64.1969 37.1104 64.4056L37.5275 64.8249L39.3328 63.0602L38.882 62.6299C38.212 61.989 37.4743 61.484 36.6725 61.1203C35.8094 60.7635 34.8976 60.5828 33.9641 60.5828C33.0113 60.5828 32.1069 60.7612 31.2756 61.1129C30.4484 61.4643 29.7027 61.9697 29.0606 62.6148C28.4178 63.2606 27.9144 64.0097 27.5653 64.8404C27.2152 65.6736 27.0376 66.5826 27.0376 67.5411V68.803H38.2408C38.1934 68.957 38.1357 69.1116 38.0671 69.2681C37.8622 69.7792 37.541 70.2556 37.1117 70.684C36.9014 70.8943 36.6798 71.0779 36.4516 71.2318C36.2243 71.3858 35.9659 71.5243 35.6821 71.6435C35.1182 71.8808 34.5399 72.0013 33.9641 72.0013C33.3889 72.0013 32.8109 71.8802 32.2367 71.6377C31.7237 71.4309 31.2464 71.1085 30.8174 70.6769L30.3946 70.2514L28.6393 72.0158L29.059 72.4406C29.7011 73.0906 30.4459 73.6014 31.272 73.9583C32.1027 74.3177 33.0087 74.5 33.9641 74.5C34.8976 74.5 35.8075 74.3235 36.6859 73.9673C37.4707 73.6198 38.2088 73.1218 38.8923 72.4748C39.2064 72.1601 39.4886 71.8119 39.7297 71.4399C39.9666 71.0763 40.1782 70.6798 40.3622 70.2566C40.7129 69.4246 40.8905 68.5189 40.8905 67.5646V66.3046H29.695C29.7415 66.1436 29.796 65.988 29.8572 65.8414Z"
          fill="#D8232A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.1073 62.6148C53.7325 63.2438 54.2307 63.9872 54.5882 64.8256C54.9482 65.6688 55.1303 66.5826 55.1303 67.5411V74.5H52.6434V72.8577C52.2529 73.1888 51.824 73.4803 51.3591 73.7296C50.406 74.2408 49.3445 74.5 48.2038 74.5C47.2484 74.5 46.3386 74.3168 45.4999 73.9554C44.6648 73.5959 43.9251 73.0954 43.3003 72.4674C42.6745 71.839 42.1763 71.0956 41.8192 70.2572C41.4598 69.4153 41.2773 68.5015 41.2773 67.5411C41.2773 66.5816 41.4598 65.6678 41.8192 64.8256C42.1766 63.9866 42.6751 63.2432 43.3003 62.6148C43.9251 61.9867 44.6648 61.4865 45.4999 61.1268C46.3386 60.7657 47.2484 60.5828 48.2038 60.5828C49.1592 60.5828 50.0687 60.7657 50.9071 61.1268C51.7413 61.4856 52.4812 61.9861 53.1073 62.6148ZM52.5565 68.5048C52.6139 68.2429 52.6434 67.9701 52.6434 67.6937V67.5411C52.6434 66.9426 52.5296 66.3497 52.3055 65.778C52.1933 65.5261 52.051 65.2643 51.8936 65.023C51.7474 64.7995 51.5758 64.6024 51.3841 64.4375L51.3578 64.4149L51.3348 64.3895C51.1469 64.1862 50.9337 64.001 50.6997 63.8397C50.4624 63.6761 50.2043 63.5311 49.935 63.41C49.39 63.1955 48.7998 63.0812 48.2038 63.0812C47.5928 63.0812 47.0038 63.1946 46.454 63.4187C45.9084 63.6641 45.4371 63.994 45.0732 64.3892L45.0495 64.4143L45.0232 64.4375C44.8315 64.6024 44.66 64.7995 44.5144 65.0224C44.3551 65.2668 44.213 65.5284 44.0925 65.8012C43.8783 66.3484 43.7645 66.9417 43.7645 67.5411C43.7645 68.1566 43.8774 68.7476 44.1002 69.2987C44.3454 69.8343 44.6709 70.3145 45.0572 70.7023C45.4435 71.0908 45.9215 71.418 46.479 71.6747C46.739 71.7852 47.024 71.8705 47.3087 71.9217C47.6014 71.9746 47.9021 72.0013 48.2038 72.0013C48.5052 72.0013 48.8059 71.9746 49.0973 71.9217C49.3833 71.8705 49.6683 71.7852 49.9456 71.6673C50.2079 71.5504 50.4691 71.4074 50.7115 71.2479C50.9398 71.0972 51.1498 70.9191 51.3344 70.7188C51.7365 70.3145 52.0622 69.8343 52.3183 69.2739C52.4132 69.042 52.4963 68.7753 52.5565 68.5048Z"
          fill="#D8232A"
        />
      </svg>

      <Box sx={{ width: "60%" }}>
        <LinearProgress
          sx={{
            ".MuiLinearProgress-bar": {
              backgroundColor: "#cc1d15",
            },
            backgroundColor: "#E8E8E8",
          }}
        />
      </Box>
    </Box>
  );
};

export default Loader;
