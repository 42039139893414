import React from "react";
import Providers from "components/RouteRoleProviders";
import CMSFormulir from "pages/CMS/Formulir";
import BuatFormulir from "pages/CMS/Formulir/Formulir";

const cmsFormulirRoutes = [
  {
    path: "/master-data/cms/formulir",
    element: (
      <Providers permission="Web:Voucher:All">
        <CMSFormulir />
      </Providers>
    ),
  },
  {
    path: "/master-data/cms/formulir/:type",
    element: (
      <Providers permission="Web:Voucher:All">
        <BuatFormulir />
      </Providers>
    ),
  },
];

export default cmsFormulirRoutes;
