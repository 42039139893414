import { createAsyncThunk } from "@reduxjs/toolkit";
import { destroy, get, getParams, post, put } from "api";
import { Banner, Dokumen, Formulir } from "api/endpoints";

export const getListBanner = createAsyncThunk(
  "cms/getListBanner",
  async (params) => {
    const response = await getParams(Banner.bannerGet, params);
    return response.data.data;
  }
);

export const getListBannerAll = async (params) => {
  return await getParams(Banner.bannerGet, params);
};

export const newBanner = async (data) => {
  const response = await post(Banner.bannerGet, data);

  return response;
};

export const updateBanner = async (data, id) => {
  const withId = `${Banner.bannerGet}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const getDetailBanner = async (id) => {
  const withId = `${Banner.bannerGet}/${id}`;
  const response = await get(withId);

  return response;
};

export const deleteBanner = async (id) => {
  const withId = `${Banner.bannerGet}/${id}`;
  const response = await destroy(withId);

  return response;
};

export const getFormulirBanner = async () => {
  const withId = Banner.bannerFormulir;
  const response = await get(withId);

  return response;
};

//dokumen
export const getListDokumen = createAsyncThunk(
  "cms/getListDokumen",
  async (params) => {
    const response = await getParams(Dokumen.list, params);
    return response.data.data;
  }
);

export const getListDokumenAll = async () => {
  let url = Dokumen.list;
  const response = await get(url);
  return response;
};

export const newDokumen = async (data) => {
  let url = Dokumen.action;
  const response = await post(url, data);
  return response;
};

export const updateDokumen = async (id, data) => {
  const withId = `${Dokumen.action}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const deleteDokumen = async (id) => {
  const withId = `${Dokumen.action}/${id}`;
  const response = await destroy(withId);
  return response;
};

export const getListFormulir = createAsyncThunk(
  "cms/getListFormulir",
  async (params) => {
    const response = await getParams(Formulir.getListFormulir, params);
    return response.data.data;
  }
);

export const newFormulir = async (data) => {
  let url = Formulir.action;
  const response = await post(url, data);
  return response;
};

export const getFormDocument = async (id) => {
  const withId = `${Formulir.action}/${id}`;

  const response = await get(withId);

  return response;
};

// export const getListFormulir = async (params) => {
//   let url = Formulir.getListFormulir;
//   const response = await getParams(url, params);
//   return response;
// };
