import React from "react";
import Providers from "components/RouteRoleProviders";
import Penerbit from "pages/MasterData/MasterBantuan/Penerbit";
import DetailPenerbit from "pages/MasterData/MasterBantuan/Penerbit/DetailPenerbit";
import ResponsiveView from "components/ResponsiveView";
import PenerbitMobile from "pages/MasterData/MasterBantuan/Penerbit/Mobile";

const penebitRoutes = [
  {
    path: "/master-data/master-bantuan/penerbit",
    element: (
      <Providers permission="Web:Voucher:All">
        <ResponsiveView onDesktop={Penerbit} onMobile={PenerbitMobile} />
      </Providers>
    ),
  },
  {
    path: "/master-data/master-bantuan/penerbit/:id",
    element: (
      <Providers permission="Web:Voucher:All">
        <DetailPenerbit />
      </Providers>
    ),
  },
];

export default penebitRoutes;
