import React from "react";

const NIK = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 29 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57523 0.5C1.66783 0.5 0.121582 2.04625 0.121582 3.95364V17.9464C0.121582 19.8538 1.66783 21.4 3.57523 21.4H24.9112C26.8186 21.4 28.3648 19.8538 28.3648 17.9464V3.95364C28.3648 2.04625 26.8186 0.5 24.9112 0.5H3.57523ZM3.65199 16.7556V16.2714C3.64728 14.9802 3.70377 14.2429 5.21007 13.8992L5.31521 13.8753C6.92921 13.5083 8.4732 13.1572 7.70725 11.7616C5.38542 7.53866 7.04471 5.14444 9.536 5.14444C11.9802 5.14444 13.6795 7.44925 11.3671 11.7616C10.6157 13.1597 12.1361 13.5059 13.797 13.8842L13.8631 13.8992C15.3741 14.2441 15.4259 14.986 15.42 16.2876V16.7556H3.65199ZM17.7736 16.7556H24.8344V14.4333H17.7736V16.7556ZM24.8344 12.1111H17.7736V9.78889H24.8344V12.1111ZM17.7736 7.46667H24.8344V5.14444H17.7736V7.46667Z"
        fill="white"
      />
      <path
        d="M13.4028 12.5219C12.9288 12.0929 12.2056 12.0974 11.7369 12.5321L8.68981 15.3585L7.70778 14.516C7.23512 14.1105 6.53461 14.1199 6.07299 14.5379C5.52535 15.0339 5.53207 15.8962 6.08737 16.3835L8.40109 18.414C8.5744 18.5661 8.83455 18.5629 9.00407 18.4066L13.4113 14.3426C13.9442 13.8512 13.9403 13.0083 13.4028 12.5219Z"
        fill="white"
        stroke="#CC1D15"
        strokeWidth="0.75759"
      />
    </svg>
  );
};

export default NIK;
