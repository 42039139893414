import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentPage,
  FilterName,
  List,
  Loading,
  Params,
  TotalItems,
} from "redux/penerimaBantuan/selectors";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/penerimaBantuan/slice";

import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import AktifStatus from "components/Status/AktifStatus";
import {
  getListPenerimaBantuan,
  getPenerimaBantuan,
} from "redux/penerimaBantuan/action";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";

const PenerimaBantuan = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);
  const dispatch = useDispatch();
  const headers = [
    {
      name: "Nama Penerima",
      key: "nama",
      width: 45,
    },
    {
      name: "NIK/NIP/ID Lain",
      key: "no_identitas",
      width: 40,
    },
    {
      name: "No. Telepon",
      key: "no_tlp",
      width: 25,
    },
    {
      name: "Email",
      key: "email",
      width: 25,
    },
    {
      name: "Status Penerima",
      width: 30,
      custom: (item) => {
        return <AktifStatus value={item?.status_verif} />;
      },
      center: true,
    },
  ];

  const filter = [
    { label: "Nama", value: "nama" },
    { label: "NIK", value: "nik" },
    { label: "NIP", value: "nip" },
    { label: "ID Lain", value: "id_lain" },
    { label: "No. Telepon", value: "no_tlp" },
    { label: "Email", value: "email" },
  ];

  const filterData = [
    {
      label: "Status ",
      key: "status_verif",
      placeholder: "Pilih status  ",
      type: "switch",
    },
  ];

  const fetchData = async () => {
    await dispatch(getListPenerimaBantuan(params));
  };

  const exportData = useFetchData({
    action: getPenerimaBantuan,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "penerima-bantuan",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <Providers
      title="Penerima Bantuan"
      desc
      currentPage={currentPage}
      params={params}
      get={fetchData}
    >
      <MainCard
        sx={{
          mt: 2,
          borderRadius: "8px",
        }}
        title="Daftar Penerima Bantuan"
        addButton={false}
      >
        <MainCardHeader
          filter={filter}
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          filterData={filterData}
          addFilter={addFilter}
          title="Filter Penerima Bantuan"
          onChangeExport={(type) => handleExport(type)}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="nama"
          detail={[
            {
              type: "link",
              url_key: "csr-bansos/penerima-bantuan:id",
              tooltip: "Lihat Detail Penerima Bantuan",
              variant: "icon:eye",
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>
    </Providers>
  );
};

export default PenerimaBantuan;
