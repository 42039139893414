import Dasbor from "assets/layout/sidebar/Dasbor";
const dashboard = {
  id: "dasbor",
  title: "Dasbor",
  url: "/",
  type: "item",
  icon: Dasbor,
};

export default dashboard;
