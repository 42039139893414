import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React from "react";

const AlertError = ({ value }) => {
  return (
    <Box
      sx={{
        py: 0.3,
        px: 1,
        backgroundColor: "#F9E8E7",
        color: "#cc1d15",
        display: "inline-flex",
        borderRadius: "8px",
        mt: 0.2,
        minWidth: "0px",
      }}
    >
      <Typography
        variant="inputDesc"
        sx={{
          color: "#cc1d15",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

AlertError.propTypes = {
  value: PropTypes.any,
};

AlertError.defaultProps = {
  value: "",
};

export default AlertError;
