import Providers from "components/Providers";
import PembaruanAplikasi from "pages/Pengaturan/PembaruanAplikasi";
import LoginSession from "pages/Pengaturan/LoginSession";
import React from "react";
import LoginSessionMobile from "pages/Pengaturan/LoginSession/Mobile";
import ResponsiveView from "components/ResponsiveView";
import PembaruanAplikasiMobile from "pages/Pengaturan/PembaruanAplikasi/Mobile";
import RouteRoleProviders from "components/RouteRoleProviders";

const pengaturanRoutes = [
  {
    path: "/pengaturan/pembaruan-aplikasi",
    element: (
      <RouteRoleProviders permission="Web:ForceUpdate:All">
        <ResponsiveView
          onDesktop={PembaruanAplikasi}
          onMobile={PembaruanAplikasiMobile}
        />
      </RouteRoleProviders>
    ),
  },
  {
    path: "/pengaturan/login-kadaluarsa",
    element: (
      <RouteRoleProviders permission="Web:LoginExpired:All">
        <ResponsiveView onDesktop={LoginSession} onMobile={LoginSessionMobile} />
      </RouteRoleProviders>
    ),
  },
];

export default pengaturanRoutes;
