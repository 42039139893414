import {
  Box,
  Button,
  OutlinedInput,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { Controller, useForm } from "react-hook-form";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import RadioMobile from "components/Mobile/Radio";
import {
  changeFilterName,
  changeFilter,
  deleteFilter,
} from "redux/sentraLokasi/Lokasi/slice";
import DrawerAction from "components/Mobile/DrawerAction";
import AccordionMobileDetail from "components/Mobile/AccordionMobileDetail";
import moment from "moment";
import { getListDigitalID } from "redux/digitalID/action";
import getRole from "helper/getRole";
import DigitalIDStatus from "components/Status/DigitalIDStatus";
import { useNavigate } from "react-router-dom";

const setDocType = (value) => {
  switch (value) {
    case "0":
      return "Other";
    case "1":
      return "AGP";
    case "2":
      return "KTP";
    case "3":
      return "SIM";
    case "4":
      return "NAME CARD";
    case "5":
      return "MITRA NAME CARD";
    case "6":
      return "PASSPORT";

    default:
      break;
  }
};

const headers = [
  {
    name: "No. Dokumen",
    key: "doc_no",
    width: 20,
  },

  {
    name: "Tipe Dok.",
    custom: (item) => setDocType(item?.doc_card_type),
    width: 20,
  },
  {
    name: "Tgl. Diterima",
    custom: (item) => {
      return item?.job_assign_date
        ? moment(item?.job_assign_date).format("DD / MM / YYYY")
        : "-";
    },
    align: "center",
    width: 20,
  },
  {
    name: "Status",
    custom: (item) => {
      return <DigitalIDStatus value={item?.adj_status} auto />;
    },
    width: 20,
  },
];

const headersDetail = [
  {
    name: "No. Dokumen",
    key: "doc_no",
    width: 20,
  },
  {
    name: "Nama",
    key: "doc_name",
    width: 20,
  },
  {
    name: "Tipe Dok.",
    custom: (item) => setDocType(item?.doc_card_type),
    width: 20,
  },
  {
    name: "Tgl. Diterima",
    custom: (item) => {
      return item?.job_assign_date
        ? moment(item?.job_assign_date).format("DD / MM / YYYY")
        : "-";
    },
    align: "center",
    width: 20,
  },
  {
    name: "Status",
    custom: (item) => {
      return <DigitalIDStatus value={item?.adj_status} />;
    },
    width: 20,
  },
];

const _filter = [
  {
    label: "Nama",
    value: "doc_name",
  },
  {
    label: "No. Dokumen",
    value: "doc_no",
  },
];

const KTPMobile = () => {
  const { isSuperVisor, isOperator } = getRole();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const type = isOperator
    ? "operator"
    : isSuperVisor
    ? "supervisor"
    : "superadmin";

  const fetchData = () => dispatch(getListDigitalID({ params, type }));

  const { list, loading, params, totalItems, filterName } = useSelect(
    (state) => state.digitalIDKTP
  );

  useAutoFetch(fetchData, filterName);

  const {
    formState: { errors },
    control,
    getValues,
    clearErrors,
    reset,
    resetField,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      filter: "",
      filterName: "",
    },
  });

  const detail = useRef(null);

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const handleChangeFilter = () =>
    dispatch(
      changeFilterName({
        name: getValues("filter"),
      })
    );

  const closeDialog = (event) => {
    toggleDrawer("filter", false)(event);
    reset();
    dispatch(
      changeFilterName({
        name: "",
      })
    );
    dispatch(deleteFilter());
  };

  const handleClickDetail = (item) => (event) => {
    navigate(`/laporan-adjudicator/digital-id/ktp/${item.id}`);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={1}>
          <MobileBadge
            value={watch("filterName") || "Pilih Parameter"}
            active={Boolean(watch("filterName"))}
            onClick={() => {
              return;
            }}
          />

          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile
          title="Daftar Anggota"
          list={list.length}
          onFilter={watch("filterName")}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="Nama"
            value="doc_name"
            handleClickDetail={handleClickDetail}
            hideDelete={true}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.filter}
        onClose={closeDialog}
        onOpen={toggleDrawer("filter", true)}
      >
        <DrawerTitle
          title="Pilih Parameter"
          toggle={toggleDrawer("filter", false)}
        />
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          <RadioMobile
            data={_filter}
            getProps={getProps}
            name="filter"
            checked={(item, field) => item.value == field.value}
            onClick={(field, item) => {
              dispatch(deleteFilter());
              field.onChange(item.value);
            }}
          />
        </Box>
        <DrawerAction
          onReset={() => resetField("filter")}
          onSubmit={(event) => {
            const label = _filter.find(
              (i) => i.value == getValues("filter")
            )?.label;
            setValue("filterName", label);
            toggleDrawer("filter", false)(event);
            handleChangeFilter();
          }}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        onOpen={toggleDrawer("detail", true)}
      >
        <DrawerTitle
          title="Detail Campaign"
          toggle={toggleDrawer("detail", false)}
        />
        <AccordionMobileDetail headers={headersDetail} data={detail?.current} />
      </SwipeableDrawer>
    </>
  );
};

export default KTPMobile;
