import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";

const SingleLine = ({ item }) => {
  return (
    <InputComponent item={item}>
      <OutlinedInput
        placeholder={item?.property?.keterangan?.value || "Placeholder"}
        readOnly
      />
    </InputComponent>
  );
};

SingleLine.propTypes = {
  item: PropTypes.object,
};
SingleLine.defaultProps = {
  item: {},
};
export default SingleLine;
