import PropTypes from "prop-types";
import {
  Box,
  Collapse,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomMultiSelectInput from "components/MultiSelect";
import React, { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FlipMove from "react-flip-move";
import FiturOpsiItem from "./FiturOpsiItem";
import Autocomplete from "components/AutoComplete/Autocomplete";
import { Delete } from "@mui/icons-material";

const DateFormatSelect = ({ onChange = () => {}, data }) => {
  return (
    <Autocomplete
      sx={{
        margin: "5px 0px",
      }}
      value={data?.value}
      disablePortal
      onChange={onChange}
      options={data?.option}
    />
  );
};

DateFormatSelect.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.any,
};

DateFormatSelect.defaultProps = {
  data: null,
  onChange: null,
};

const PropertiesInside = ({
  IOSSwitch,
  currentForm,
  currentSection,
  currentLength,
  getContentKey,
  handleChangeProperties,
  handleChangeContent,
  handleChangeContentObject,
  hideProperties,
  handleDeleteInput,
  handleChangePropertiesPanduan,
  handleSortableFiturOpsi,
}) => {
  const [collapseItem, setCollapseItem] = useState("tampilan");
  const [opsiString, setOpsiString] = useState("");
  const renderProperties = () => {
    let v = getContentKey("property");
    // let t = getContentKey("tampilan");
    const column = [
      "name",
      "ilustrasi_panduan",
      "label",
      "keterangan",
      "deskripsi",
      "batasi_karakter",
      "judul_panduan",
      "deskripsi_panduan",
      "panduan",
    ];

    const ordered = Object?.keys(v || {})
      .sort(function (a, b) {
        return column.indexOf(a) - column.indexOf(b);
      })
      .reduce((oj, key) => {
        oj[key] = v[key];
        return oj;
      }, {});

    return Object?.keys(ordered).map((key, index) => {
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            position: "relative",
            margin: "10px 0px",
          }}
        >
          <Box
            sx={{
              textTransform: "capitalize",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {key.replace("_", " ")}
          </Box>
          {key === "panduan" ? (
            <>
              <CustomMultiSelectInput
                dataArray={v[key].value}
                // getChipLabel={(item) => item.name}
                placeHolder={v[key].placeholder}
                handleDelete={(index) => {
                  let newFasilitas = v[key].value;
                  newFasilitas.splice(index, 1);
                  // setValue("fasilitas", newFasilitas);
                  handleChangePropertiesPanduan(newFasilitas, currentForm, key);
                }}
                onChange={(e) => {
                  e.target.value = normalizeInput(e.target.value);
                }}
                onEnter={(data) => {
                  handleChangePropertiesPanduan(
                    [
                      ...(v[key].value ?? []),
                      {
                        name: data,
                      },
                    ],
                    currentForm,
                    key
                  );
                }}
              />
            </>
          ) : (
            <OutlinedInput
              {...(key === "panduan"
                ? {
                    multiline: true,
                    rows: 6,
                  }
                : {})}
              placeholder={v[key].placeholder}
              autoComplete="off"
              spellCheck="false"
              onInput={(e) => {
                if (v[key].type === "number") {
                  // let local = state;
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }
              }}
              name={key}
              value={v[key].value ?? ""}
              onChange={(e) => handleChangeProperties(e, currentForm, key)}
            />
          )}
        </Box>
      );
    });
  };

  const handleTampilan = (key, val) => {
    let realVal = !val;
    handleChangeContentObject({ [key]: realVal }, currentForm, "tampilan");
    let property = getContentKey("property");

    switch (key) {
      case "batasi_karakter":
        if (realVal) {
          property.batasi_karakter = {
            type: "number",
            value: "",
            placeholder: "Jumlah Batas Karakter",
          };
        } else {
          delete property.batasi_karakter;
        }

        handleChangeContent(property, currentForm, "property");
        break;

      default:
        break;
    }
  };

  const renderTampilan = () => {
    let v = getContentKey("tampilan");
    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <Box
          sx={{
            margin: "20px 20px",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              setCollapseItem((prev) => (prev === "tampilan" ? "" : "tampilan"))
            }
          >
            <Box component={"span"}>Tampilan</Box>
            <KeyboardArrowRightIcon
              sx={{
                color: "inherit",
                transition: "transform 0.3s",
                ...(collapseItem === "option"
                  ? {
                      transform: "rotate(90deg)",
                    }
                  : {}),
              }}
              fontSize="small"
            />
          </Box>
          <Collapse in={collapseItem === "tampilan"}>
            {Object?.keys(v || {})?.map((key, index) => (
              <Box
                sx={{
                  display: "flex",
                  fontWeight: "600",
                  gap: "20px",
                  marginTop: "14px",
                }}
                key={index}
              >
                <IOSSwitch
                  checked={v[key] || false}
                  onChange={() => handleTampilan(key, v[key])}
                />
                <Typography variant="label2">
                  {key.replace(/_/g, " ")}
                </Typography>
              </Box>
            ))}
          </Collapse>
        </Box>
      </>
    );
  };

  const renderOpsi = () => {
    let v = getContentKey("option");
    let type = getContentKey("type");
    let property = getContentKey("property");
    if (!v) return null;

    const deleteOption = (value) => {
      let all = v.filter((item) => item.id !== value);
      handleChangeContent(all, currentForm, "option");

      switch (type) {
        case "select":
          let currentValue = getContentKey("value");
          if (currentValue === value) {
            handleChangeContent("", currentForm, "value");
          }
          break;

        default:
          break;
      }
    };

    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <Box
          sx={{
            margin: "20px 20px",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              setCollapseItem((prev) => (prev === "option" ? "" : "option"))
            }
          >
            <span>Fitur Opsi</span>
            <KeyboardArrowRightIcon
              sx={{
                color: "inherit",
                transition: "transform 0.3s",
                ...(collapseItem === "option"
                  ? {
                      transform: "rotate(90deg)",
                    }
                  : {}),
              }}
              fontSize="small"
            />
          </Box>
          <Collapse in={collapseItem === "option"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
                margin: "10px 0px",
              }}
            >
              <OutlinedInput
                placeholder="Tambah Opsi (enter)"
                autoComplete="off"
                spellCheck="false"
                name="option"
                value={opsiString ?? ""}
                onInput={(e) => {
                  if (
                    property?.batasi_karakter &&
                    property?.batasi_karakter?.value
                  ) {
                    if (
                      e.target.value.length > property?.batasi_karakter?.value
                    ) {
                      e.target.value = e.target.value.slice(
                        0,
                        property?.batasi_karakter?.value
                      );
                    }
                  }
                }}
                onKeyPress={(e) => {
                  if (!e?.target?.value) return;
                  let keyPress = e.keyCode || e.which;
                  if (keyPress === 13) {
                    let option = getContentKey("option");
                    let id = Math.max(...option.map((item) => item.id)) + 1;

                    option.push({ id, label: opsiString });
                    handleChangeContent(option, currentForm, "option");
                    setOpsiString("");
                  }
                }}
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart();
                  setOpsiString(e.target.value);
                }}
              />
            </Box>

            <Box sx={{ position: "relative" }}>
              <FlipMove
                duration={200}
                className="custom-scroll"
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {v?.map((item, index) => {
                  return (
                    <Box key={item.id}>
                      <FiturOpsiItem
                        v={v}
                        item={item}
                        property={property}
                        index={index}
                        deleteOption={deleteOption}
                        handleSortableFiturOpsi={handleSortableFiturOpsi}
                      />
                    </Box>
                  );
                })}
              </FlipMove>
            </Box>
          </Collapse>
        </Box>
      </>
    );
  };

  const renderFormat = () => {
    let v = getContentKey("format");
    if (!v) return null;
    return (
      <>
        {(function () {
          switch (getContentKey("type")) {
            case "multi-select":
            case "select":
              return (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                  <Box
                    sx={{
                      margin: "20px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCollapseItem((prev) =>
                          prev === "format" ? "" : "format"
                        )
                      }
                    >
                      <span>Format</span>
                      <KeyboardArrowRightIcon
                        sx={{
                          color: "inherit",
                          transition: "transform 0.3s",
                          ...(collapseItem === "format"
                            ? {
                                transform: "rotate(90deg)",
                              }
                            : {}),
                        }}
                        fontSize="small"
                      />
                    </Box>
                    <Collapse in={collapseItem === "format"}>
                      <RadioGroup
                        style={{ marginTop: 13 }}
                        value={v?.type}
                        onChange={(e) => {
                          handleChangeContentObject(
                            {
                              type: e.target.value,
                            },
                            currentForm,
                            "format"
                          );
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            fontFamily: "ManropeSemiBold",
                            fontWeight: "600",
                            paddingLeft: "20px",
                            margin: "0px",
                          }}
                          value="dropdown"
                          control={
                            <Radio
                              sx={{
                                padding: "4px",
                                color: "#969696",
                                "&.Mui-checked": {
                                  color: "#cc1d15",
                                },
                                "& .MuiSvgIcon-root": {
                                  fontSize: 32,
                                },
                              }}
                            />
                          }
                          label="Dropdown"
                        />
                        <FormControlLabel
                          value="list"
                          sx={{
                            fontWeight: "600",
                            paddingLeft: "20px",
                            margin: "0px",
                          }}
                          control={
                            <Radio
                              sx={{
                                padding: "4px",
                                color: "#969696",
                                "&.Mui-checked": {
                                  color: "#cc1d15",
                                },
                                "& .MuiSvgIcon-root": {
                                  fontSize: 32,
                                },
                              }}
                            />
                          }
                          label="List"
                        />
                      </RadioGroup>
                    </Collapse>
                  </Box>
                </>
              );

            case "date":
              return (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                  <Box
                    sx={{
                      margin: "20px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCollapseItem((prev) =>
                          prev === "format" ? "" : "format"
                        )
                      }
                    >
                      <span>Format</span>
                      <KeyboardArrowRightIcon
                        sx={{
                          color: "inherit",
                          transition: "transform 0.3s",
                          ...(collapseItem === "format"
                            ? {
                                transform: "rotate(90deg)",
                              }
                            : {}),
                        }}
                        fontSize="small"
                      />
                    </Box>
                    <Collapse in={collapseItem === "format"}>
                      <DateFormatSelect
                        data={v?.date}
                        onChange={(_, value) => {
                          handleChangeContentObject(
                            {
                              date: {
                                ...v?.date,
                                value,
                              },
                            },
                            currentForm,
                            "format"
                          );
                        }}
                      />
                    </Collapse>
                  </Box>
                </>
              );

            case "time":
              return (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                  <Box
                    sx={{
                      margin: "20px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCollapseItem((prev) =>
                          prev === "format" ? "" : "format"
                        )
                      }
                    >
                      <span>Format</span>
                      <KeyboardArrowRightIcon
                        sx={{
                          color: "inherit",
                          transition: "transform 0.3s",
                          ...(collapseItem === "format"
                            ? {
                                transform: "rotate(90deg)",
                              }
                            : {}),
                        }}
                        fontSize="small"
                      />
                    </Box>
                    <Collapse in={collapseItem === "format"}>
                      <DateFormatSelect
                        data={v?.time}
                        onChange={(_, value) => {
                          handleChangeContentObject(
                            {
                              time: {
                                ...v?.time,
                                value,
                              },
                            },
                            currentForm,
                            "format"
                          );
                        }}
                      />
                    </Collapse>
                  </Box>
                </>
              );

            case "number":
              return (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                  <Box
                    sx={{
                      margin: "20px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCollapseItem((prev) =>
                          prev === "format" ? "" : "format"
                        )
                      }
                    >
                      <span>Format</span>
                      <KeyboardArrowRightIcon
                        sx={{
                          color: "inherit",
                          transition: "transform 0.3s",
                          ...(collapseItem === "format"
                            ? {
                                transform: "rotate(90deg)",
                              }
                            : {}),
                        }}
                        fontSize="small"
                      />
                    </Box>
                    <Collapse in={collapseItem === "format"}>
                      <DateFormatSelect
                        data={v}
                        onChange={(_, value) => {
                          handleChangeContentObject(
                            { value },
                            currentForm,
                            "format"
                          );
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          fontWeight: "600",
                          fontSize: "14px",
                          gap: "20px",
                          marginTop: "15px",
                        }}
                      >
                        <IOSSwitch
                          checked={v.is_negative}
                          onChange={() => {
                            handleChangeContentObject(
                              { is_negative: !v.is_negative },
                              currentForm,
                              "format"
                            );
                          }}
                        />
                        <Box
                          sx={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            textTransform: "capitalize",
                          }}
                        >
                          Bilangan Negatif
                        </Box>
                      </Box>
                    </Collapse>
                  </Box>
                </>
              );

            default:
              return null;
          }
        })()}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          margin: "20px 20px",
        }}
      >
        {renderProperties()}
      </Box>
      {renderOpsi()}
      {renderFormat()}
      {renderTampilan()}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

      <Box
        sx={{
          fontSize: "14px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "20px",
        }}
        onClick={() => {
          handleDeleteInput(currentSection, currentForm, currentLength);
          hideProperties();
        }}
      >
        <Delete style={{ width: 24, color: "#74788d" }} />
        <span>Hapus</span>
      </Box>
    </>
  );
};

PropertiesInside.propTypes = {
  IOSSwitch: PropTypes.any,
  currentForm: PropTypes.any,
  currentLength: PropTypes.any,
  currentSection: PropTypes.any,
  getContentKey: PropTypes.func,
  handleChangeContent: PropTypes.func,
  handleChangeContentObject: PropTypes.func,
  handleChangeProperties: PropTypes.func,
  handleChangePropertiesPanduan: PropTypes.func,
  handleDeleteInput: PropTypes.any,
  hideProperties: PropTypes.any,
  handleSortableFiturOpsi: PropTypes.any,
};

PropertiesInside.defaultProps = {
  IOSSwitch: null,
  currentForm: null,
  currentLength: null,
  currentSection: null,
  getContentKey: null,
  handleChangeContent: null,
  handleChangeContentObject: null,
  handleChangeProperties: null,
  handleChangePropertiesPanduan: null,
  handleDeleteInput: null,
  hideProperties: null,
  handleSortableFiturOpsi: null,
};

export default PropertiesInside;
