import { Box, Button, Paper, SwipeableDrawer, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { getListLoginSession } from "redux/pengaturan/action";
import useFetchData from "hook/useFetchData";

const LoginSessionMobile = () => {
  const [dialog, setDialog] = useState({
    alert: true,
  });

  const get = useFetchData({
    action: getListLoginSession,
    snackbar: false,
  });

  useEffect(() => {
    get.fetch();
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };


  return (
    <>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="label2">Expired Login</Typography>
          <Typography
            variant="text2"
            component="div"
            sx={{ display: "flex", gap: 1 }}
          >
            <span>{get?.response?.Hours}</span>
            <span>Hour</span>
            <span>{get?.response?.Minutes}</span>
            <span>Minutes</span>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="label2">Pesan</Typography>
          <Typography variant="text2">{get?.response?.Message}</Typography>
        </Box>
      </Paper>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default LoginSessionMobile;
