import { Box } from "@mui/material";
import React from "react";

const DropColumnEmpty = (props) => {
  return (
    <Box
      sx={{
        height: "140px",
        border: "2px dashed #B5B5B5",
        backgroundColor: "#F5F6F8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        color: "#969696",
        px: 2,
        width: "100%",
        textAlign: "center",
      }}
      {...props}
    >
      Taruh di sini untuk menambahkan
    </Box>
  );
};

export default DropColumnEmpty;
