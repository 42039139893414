import React from "react";
import Providers from "components/RouteRoleProviders";
import TNI from "pages/LaporanAdjudicator/DigitalId/TNI";
import KTP from "pages/LaporanAdjudicator/DigitalId/KTP";
import DetailTNI from "pages/LaporanAdjudicator/DigitalId/TNI/Detail";
import DetailKTP from "pages/LaporanAdjudicator/DigitalId/KTP/Detail";
import ResponsiveView from "components/ResponsiveView";
import KTPMobile from "pages/LaporanAdjudicator/DigitalId/KTP/Mobile";
import TNIMobile from "pages/LaporanAdjudicator/DigitalId/TNI/Mobile";
import DetailKTPMobile from "pages/LaporanAdjudicator/DigitalId/KTP/Mobile/Detail";
import DetailTNIMobile from "pages/LaporanAdjudicator/DigitalId/TNI/Mobile/Detail";

const digitalIDRoutes = [
  {
    path: "/laporan-adjudicator/digital-id/tni",
    element: (
      //   <Providers permission="Web:Voucher:All">
      <ResponsiveView onDesktop={TNI} onMobile={TNIMobile} />
      //   </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/digital-id/tni/:id",
    element: (
      //   <Providers permission="Web:Voucher:All">
      <ResponsiveView onDesktop={DetailTNI} onMobile={DetailTNIMobile} />

      //   </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/digital-id/ktp",
    element: (
      //   <Providers permission="Web:Voucher:All">
      <ResponsiveView onDesktop={KTP} onMobile={KTPMobile} />
      //   </Providers>
    ),
  },
  {
    path: "/laporan-adjudicator/digital-id/ktp/:id",
    element: (
      //   <Providers permission="Web:Voucher:All">
      <ResponsiveView onDesktop={DetailKTP} onMobile={DetailKTPMobile} />
      //   </Providers>
    ),
  },
];

export default digitalIDRoutes;
