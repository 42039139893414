import PropTypes from "prop-types";
import { Box, ClickAwayListener, OutlinedInput, Paper } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";
import DropColumnEmpty from "./DropColumnEmpty";
import DropColumn from "./DropColumn";
import FlipMove from "react-flip-move";
import Items from "./Items";

const Section = ({
  section,
  detail,
  ismulti,
  forms,
  setSectionData,
  isColumn1,
  handleDrop,
  handleDropLeft,
  handleDropRight,
  renderInput,
  handleSortable,
  currentForm,
  handleDeleteInput,
  handleClickItem,
  handleChange,
  handleClickAway,
  handleUpdateEmptyColumn,
  setCurrentForm,
}) => {
  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept: "builder",
    canDrop: () => true,
    drop: (item) => {
      if (isOverCurrent) {
        handleDrop(section, item);
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [{ isOverCurrentLeft }, dropLeft] = useDrop({
    accept: "builder",
    drop: (item) => {
      if (isOverCurrentLeft) {
        handleDropLeft(section, item);
      }
    },
    collect: (monitor) => ({
      isOverCurrentLeft: monitor.isOver({ shallow: true }),
    }),
  });

  const [{ isOverCurrentRight }, dropRight] = useDrop({
    accept: "builder",
    drop: (item) => {
      if (isOverCurrentRight) {
        handleDropRight(section, item);
      }
    },
    collect: (monitor) => ({
      isOverCurrentRight: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: 2,
        mt: 1,
        minHeight: "973px",
      }}
    >
      <Box display="flex" flexDirection="column">
        <OutlinedInput
          variant="v4"
          placeholder="Label Formulir"
          value={detail?.title || ""}
          onChange={(e) =>
            setSectionData(
              {
                title: e.target.value,
              },
              section
            )
          }
        />
        <OutlinedInput
          variant="v5"
          placeholder="Deskripsi (Opsional)"
          value={detail?.description || ""}
          onChange={(e) =>
            setSectionData(
              {
                description: e.target.value,
              },
              section
            )
          }
        />
      </Box>

      <Box sx={{ borderBottom: "1px solid #DEDEDE", my: 1, mb: 5 }} />

      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <FlipMove duration={200}>
            {forms &&
              forms?.length !== 0 &&
              forms?.map((item, index) => {
                return (
                  <Box key={item?.id}>
                    <Items
                      section={section}
                      item={item}
                      index={index}
                      renderInput={renderInput}
                      handleSortable={handleSortable}
                      currentForm={currentForm}
                      handleDeleteInput={handleDeleteInput}
                      handleClickItem={handleClickItem}
                      handleChange={handleChange}
                      handleClickAway={handleClickAway}
                      handleUpdateEmptyColumn={handleUpdateEmptyColumn}
                      handleDrop={handleDrop}
                      handleDropLeft={handleDropLeft}
                      handleDropRight={handleDropRight}
                      isColumn1={isColumn1}
                      setCurrentForm={setCurrentForm}
                    />
                  </Box>
                );
              })}
          </FlipMove>
        </Box>
      </ClickAwayListener>

      {canDrop && (
        <>
          {isColumn1 ? (
            <DropColumn ref={drop}>Taruh di sini untuk menambahkan</DropColumn>
          ) : (
            <Box display="flex" gap={1}>
              <DropColumn ref={dropLeft}>
                Taruh di sini untuk menambahkan
              </DropColumn>
              <DropColumn ref={dropRight}>
                Taruh di sini untuk menambahkan
              </DropColumn>
            </Box>
          )}
        </>
      )}

      {!canDrop && !forms?.length && (
        <>
          {isColumn1 ? (
            <DropColumnEmpty>Taruh di sini untuk menambahkan</DropColumnEmpty>
          ) : (
            <Box display="flex" gap={1}>
              <DropColumnEmpty>Taruh di sini untuk menambahkan</DropColumnEmpty>
              <DropColumnEmpty>Taruh di sini untuk menambahkan</DropColumnEmpty>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

Section.propTypes = {
  currentForm: PropTypes.any,
  detail: PropTypes.any,
  forms: PropTypes.any,
  handleChange: PropTypes.any,
  handleClickAway: PropTypes.func,
  handleClickItem: PropTypes.any,
  handleDeleteInput: PropTypes.any,
  handleDrop: PropTypes.func,
  handleDropLeft: PropTypes.func,
  handleDropRight: PropTypes.func,
  handleSortable: PropTypes.any,
  handleUpdateEmptyColumn: PropTypes.any,
  isColumn1: PropTypes.bool,
  ismulti: PropTypes.any,
  renderInput: PropTypes.any,
  section: PropTypes.any,
  setCurrentForm: PropTypes.any,
  setSectionData: PropTypes.func,
};

Section.defaultProps = {
  currentForm: null,
  handleChange: null,
  handleClickItem: null,
  handleDeleteInput: null,
  handleSortable: null,
  renderInput: null,
  detail: null,
  forms: null,
  ismulti: null,
  section: null,
  setSectionData: null,
  isColumn1: true,
  handleDrop: () => {},
  handleDropLeft: () => {},
  handleDropRight: () => {},
  handleClickAway: () => {},
  handleUpdateEmptyColumn: null,
  setCurrentForm: null,
};
export default Section;
