export const defaultValues = {
  name: "",
  name_en: "",
  color: "",
  icon: "",
  is_active: 1,
};

export const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];
