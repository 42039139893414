import React from "react";
import Providers from "components/RouteRoleProviders";
import CMSBanner from "pages/CMS/Banner";
import CreateBanner from "pages/CMS/Banner/Create";

const bannerRoutes = [
  {
    path: "/master-data/cms/kelola-banner",
    element: (
      <Providers permission="Web:Voucher:All">
        <CMSBanner />
      </Providers>
    ),
  },
  {
    path: "/master-data/cms/kelola-banner/create",
    element: (
      <Providers permission="Web:Voucher:All">
        <CreateBanner />
      </Providers>
    ),
  },
  {
    path: "/master-data/cms/kelola-banner/edit/:id",
    element: (
      <Providers permission="Web:Voucher:All">
        <CreateBanner />
      </Providers>
    ),
  },
];

export default bannerRoutes;
