import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListPenerbit, getPenerbit } from "redux/penerbit/action";
import {
  penerbitCurrentPage,
  penerbitFilterName,
  penerbitList,
  penerbitLoading,
  penerbitParams,
  penerbitTotalItems,
} from "redux/penerbit/selectors";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/penerbit/slice";
import AktifStatus from "components/Status/AktifStatus";
import DateFormat from "helper/DateFormat";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import Import from "./Import";

const Penerbit = () => {
  const list = useSelector(penerbitList);
  const loading = useSelector(penerbitLoading);
  const params = useSelector(penerbitParams);
  const totalItems = useSelector(penerbitTotalItems);
  const currentPage = useSelector(penerbitCurrentPage);
  const filterName = useSelector(penerbitFilterName);
  const dispatch = useDispatch();
  
  const [open, setOpen] = useState({
    open: false,
    import: false,
  });
  
  const headers = [
    {
      name: "Nama Penerbit",
      key: "IssuerName",
      width: 30,
    },
    {
      name: "Kode Penerbit",
      key: "IssuerCode",
      width: 25,
    },
    {
      name: "Status Penerbit",
      custom: (item) => <AktifStatus value={parseInt(item.IssuerStatus)} />,
      width: 25,
    },
    {
      name: "Tgl. Status",
      custom: (item) => {
        return DateFormat(item?.InsertedDate);
      },
      width: 30,
    },
  ];

  const filter = [
    {
      label: "Nama Penerbit",
      value: "IssuerName",
    },
    {
      label: "Kode Penerbit",
      value: "IssuerCode",
    },
  ];

  const filterData = [
    {
      label: "Status",
      key: "IssuerStatus",
      type: "switch",
      options: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Aktif",
          value: 0,
        },
      ],
    },
    {
      label: "Tanggal Status",
      key: "InsertedDate",
      placeholder: "Pilih Tanggal",
      type: "datepicker",
      options: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Aktif",
          value: 0,
        },
      ],
    },
  ];

  const fetchData = async () => {
    await dispatch(getListPenerbit(params));
  };

  const exportData = useFetchData({
    action: getPenerbit,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "penerbit",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };
  return (
    <>
      <Providers
        title="Master Penerbit"
        currentPage={currentPage}
        params={params}
        get={fetchData}
      >
        <MainCard
          sx={{
            mt: 2,
            borderRadius: "8px",
          }}
          title="Daftar Penerbit"
          onClick={() =>
            setOpen({
              ...open,
              open: true,
            })
          }
        >
          <MainCardHeader
            filter={filter}
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            onChangeExport={(type) => handleExport(type)}
            title="Filter Penerbit"
            onClickImport={() =>
              setOpen({
                ...open,
                import: true,
              })
            }
          />
          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="IssuerName"
            detail={[
              {
                type: "link",
                url_key: "master-data/master-bantuan/penerbit:id",
                tooltip: "Lihat Detail Penerbit",
                variant: "icon:eye",
              },
            ]}
            increment={params?.offset + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        refresh={fetchData}
        isEdit={false}
      />

      <Import
        open={open.import}
        onClose={() =>
          setOpen({
            ...open,
            import: false,
          })
        }
        refresh={fetchData}
        isEdit={false}
      />
    </>
  );
};

export default Penerbit;
