import PropTypes from "prop-types";
import { Box, Button, Grid, SwipeableDrawer } from "@mui/material";
import React, { useState, useEffect } from "react";
import DrawerTitle from "./DrawerTitle";
import DynamicForm from "components/Form/DynamicForm";
import VerticalGrid from "components/Grid/VerticalGrid";
import { useDispatch } from "react-redux";

const FilterDrawer = ({
  open,
  filterData,
  onClose,
  onOpen,
  fetchData,
  addFilter,
}) => {
  const [data, setData] = useState({
    list: {},
    tempList: {},
    action: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addFilter(data));
  }, [data]);

  const resetFilter = () =>
    setData({
      ...data,
      tempList: data.list,
      list: {},
      action: true,
    });

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <DrawerTitle title="Filter Broadcast" toggle={onClose} />
      <Box p={2}>
        <Grid container spacing={2}>
          <DynamicForm
            filterData={filterData}
            md={12}
            grid={VerticalGrid}
            data={data}
            setData={setData}
          />
        </Grid>
      </Box>
      <Box p={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" gap={2}>
            <Button
              variant="secondary-outlined"
              sx={{
                width: "100%",
              }}
              onClick={(event) => {
                resetFilter();
                onClose(event);
              }}
            >
              Batal
            </Button>
            <Button
              variant="secondary-outlined"
              sx={{
                width: "100%",
              }}
              onClick={() => resetFilter()}
            >
              Bersihkan
            </Button>
          </Box>
          <Button
            variant="primary"
            onClick={(event) => {
              fetchData();
              resetFilter();
              onClose(event);
            }}
          >
            Terapkan
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

FilterDrawer.propTypes = {
  addFilter: PropTypes.func,
  fetchData: PropTypes.func,
  filterData: PropTypes.any,
  onClose: PropTypes.func,
  onOpen: PropTypes.any,
  open: PropTypes.any,
};

FilterDrawer.defaultProps = {
  addFilter: () => {},
  fetchData: () => {},
  filterData: [],
  onClose: () => {},
  onOpen: () => {},
  open: false,
};

export default FilterDrawer;
