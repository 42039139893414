export const statusList = [
  { value: "not-active", label: "Not Active" },
  { value: "active", label: "Active" },
  { value: "blacklist", label: "Blacklist" },
  { value: "suspend", label: "Suspend" },
  { value: "expired", label: "Expired" },
  { value: "paid", label: "Paid" },
  { value: "other", label: "Other" },
  { value: "deleted", label: "Deleted" },
  { value: "active + premium", label: "Active + Premium" },
];

export const roleId = [
  {
    label: "Superadmin",
    desc: "Memiliki akses penuh ke semua pengaturan",
    value: "1147",
  },
  {
    label: "Supervisor",
    desc: "Mengelola fungsi akses dalam persetujuan dan pembaruan",
    value: "1148",
  },
  {
    label: "Operator",
    desc: "Mengatur semua akses pembaruan, kecuali validasi dan persetujuan.",
    value: "1149",
  },
  {
    label: "Officer",
    desc: "Mengatur proses menebus voucher",
    value: "1146",
  },
];
