import { Typography, Grid, Box, Button } from "@mui/material";
import PeriodeFormat from "components/Format/PeriodeFormat";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import MainStatus from "components/Status/MainStatus";
import MainTable from "components/Table/MainTable";
import DateFormat from "helper/DateFormat";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { kategoriDetail } from "redux/sentraLokasi/action";
import { status_voucher } from "pages/MasterData/MasterBantuan/Voucher/variables";
import Dialog from "./Dialog";
import PencilIcon from "assets/paper/PencilIcon";

const Detail = () => {
  const [res, setRes] = useState(null);
  const [open, setOpen] = useState(null);

  const id = useParams().id;

  const action = useFetchData({
    action: kategoriDetail,
    snackbar: false,
  });

  useEffect(() => {
    action.fetch(id);
  }, []);

  useEffect(() => {
    setRes(action.response);
  }, [action.response]);

  const informasi = [
    {
      label: "Nama Kategori",
      value: "name",
    },
    {
      label: "Nama Kategori (inggris)",
      value: "name_en",
    },
    {
      label: "Status",
      value: "is_active",
      component: AktifStatus,
    },
  ];

  const informasiGO = useMemo(() => {
    return informasi.map((item) => {
      return {
        name: item.label,
        value: item.component ? (
          <item.component value={res?.[item.value]} />
        ) : (
          res?.[item.value]
        ),
      };
    });
  }, [res]);

  const dataFill = useMemo(() => {
    if (open?.informasi) {
      return {
        name: res?.name,
        name_en: res?.name_en,
        color: res?.color,
        is_active: parseInt(res?.is_active),
      };
    } else {
      return {
        icon: res?.icon,
      };
    }
  }, [open]);

  return (
    <>
      <PageTitle
        title={`Detail ${res?.name || " "}`}
        desc={res?.informasi?.IssuerName}
        to="/master-data/master-bantuan/penerbit"
      />

      <Grid container spacing={2}>
        <Grid xs={12} md={7} item>
          <MainCard
            title="Informasi"
            addButton={false}
            isEdit={false}
            sx={{
              height: "94%",
            }}
            custom={
              <Button
                variant="secondary-outlined"
                sx={{
                  display: "flex",
                  gap: "10px",
                  borderRadius: "10px",
                  borderWidth: "2px",
                  fontWeight: "bold",
                }}
                onClick={() =>
                  setOpen({
                    ...open,
                    informasi: true,
                    ikon: false,
                  })
                }
              >
                <PencilIcon />
                Ubah
              </Button>
            }
          >
            <MainLoading loading={action.loading} height={"100%"}>
              {informasiGO.map((item, i) => (
                <MainGrid
                  key={i}
                  label={item.name}
                  value={item.value}
                  labelMd={4}
                  striped="odd"
                  valueMd={7}
                  center={item.name == "Status"}
                  sx={{
                    height: "calc(100%/5)",
                  }}
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <MainCard
            title="Ikon Kategori"
            addButton={false}
            isEdit={false}
            custom={
              <Button
                variant="secondary-outlined"
                sx={{
                  display: "flex",
                  gap: "10px",
                  borderRadius: "10px",
                  borderWidth: "2px",
                  fontWeight: "bold",
                }}
                onClick={() =>
                  setOpen({
                    ...open,
                    informasi: false,
                    ikon: true,
                  })
                }
              >
                <PencilIcon />
                Ubah
              </Button>
            }
          >
            <MainLoading loading={action.loading}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    marginTop: "23px",
                    width: "352px",
                    height: "152px",
                    objectFit: "contain",

                    padding: "20px",
                  }}
                  src={res?.icon}
                />
              </Box>
            </MainLoading>
          </MainCard>
        </Grid>
      </Grid>

      <Dialog
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        setRes={setRes}
        id={id}
        resetData={dataFill}
        {...open}
      />
    </>
  );
};

export default Detail;
