import { createSlice } from "@reduxjs/toolkit";
import pagination2 from "redux/pagination2";
import { getListRole } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    page: 1,
  },
  totalItems: 0,
  message: "",
  filterName: "search",
  loading: false,
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: pagination2,
  extraReducers: {
    [getListRole.pending]: (state) => {
      state.loading = true;
    },
    [getListRole.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.records;
      state.totalItems = action.payload.total_record;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = roleSlice.actions;

export default roleSlice.reducer;
