import React from "react";
import Providers from "components/RouteRoleProviders";
import VoucherBansosDetail from "pages/CSR/ProgramBantuan/VoucherBansos/Voucher";
import PenerimaVoucherBansos from "pages/CSR/ProgramBantuan/VoucherBansos/Penerima";
import VoucherBansos from "pages/CSR/ProgramBantuan/VoucherBansos";
import ResponsiveView from "components/ResponsiveView";
import VoucherBansosMobile from "pages/CSR/ProgramBantuan/VoucherBansos/Mobile";
import VoucherBansosPenerimaMobile from "pages/CSR/ProgramBantuan/VoucherBansos/Mobile/Penerima";
import VoucherBansosDetailMobile from "pages/CSR/ProgramBantuan/VoucherBansos/Mobile/Detail";

const voucherBansosRoutes = [
  {
    path: "/csr-bansos/program-bantuan/voucer-bansos/voucher/:id",
    element: (
      <Providers permission="Web:ProgramBantuan:All">
        <ResponsiveView
          onDesktop={VoucherBansosDetail}
          onMobile={VoucherBansosDetailMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/voucer-bansos/penerima/:id",
    element: (
      <Providers permission="Web:ProgramBantuan:All">
        <ResponsiveView
          onDesktop={PenerimaVoucherBansos}
          onMobile={VoucherBansosPenerimaMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/voucer-bansos",
    element: (
      <Providers permission="Web:ProgramBantuan:All">
        <ResponsiveView
          onDesktop={VoucherBansos}
          onMobile={VoucherBansosMobile}
        />
      </Providers>
    ),
  },
];

export default voucherBansosRoutes;
