import { Box } from "@mui/material";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import BannerStatus from "components/Status/BannerStatus";
import MainTable from "components/Table/MainTable";
import ExportHelper from "helper/ExportHelper";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBanner, getListBanner, getListBannerAll } from "redux/CMS/action";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/CMS/Banner/slice";

const CMSBanner = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.CMSBanner);

  const navigate = useNavigate()

  const [open, setOpen] = useState({
    delete: false,
  });

  const id = useRef();

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListBanner(params));

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "#",
      width: 15,
      align: "center",
      custom: (item) => {
        return (
          <Box
            component="img"
            src={item.data_records.gambar}
            alt="gambar"
            sx={{
              width: "60px",
              height: "36px",
              objectFit: "cover",
              borderRadius: "4px",
            }}
          />
        );
      },
    },
    {
      name: "Nama Banner",
      custom: (item) => {
        return item.data_records.nama_banner || "-";
      },
      width: 15,
    },
    {
      name: "Dibuat Oleh",
      custom: (item) => {
        return item.data_records.dibuat_oleh || "-";
      },
      width: 15,
    },
    {
      name: "Tanggal Dibuat",
      custom: (item) => {
        return moment(item.created_at).format("DD / MM / YYYY");
      },
      width: 30,
    },
    {
      name: "Status Banner",
      custom: (item) => {
        return <BannerStatus value={item.data_records.status} />;
      },
      width: 25,
    },
  ];

  const filter = [{ label: "Nama Banner", value: "nama_banner" }];

  const filterData = [
    {
      label: "Tanggal Dibuat",
      key: "created_at",
      placeholder: "Pilih Tanggal",
      type: "datepicker2",
    },
  ];

  const exportData = useFetchData({
    action: getListBannerAll,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "banner",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const deleteData = useFetchData({
    action: deleteBanner,
    message: "Banner Berhasil di hapus",
    onFinal: () => {
      setOpen({
        ...open,
        delete: false,
      });
      fetchData();
    },
  });

  return (
    <>
      <Page title="Kelola Banner" />

      <MainCard
        sx={{
          mt: 2,
          borderRadius: "8px",
        }}
        title="Daftar Banner"
        onClick={() => navigate('/master-data/cms/kelola-banner/create')}
      >
        <MainCardHeader
          filter={filter}
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          filterData={filterData}
          addFilter={addFilter}
          title="Filter Banner"
          onChangeExport={(type) => handleExport(type)}
        />
        <MainTable
          headers={headers}
          no={false}
          loading={loading}
          data={list}
          summaryKey="no_registrasi"
          detail={[
            {
              type: "link",
              url_key: "master-data:cms:kelola-banner:edit:id",
              tooltip: "Lihat Detail Banner",
              variant: "icon:pencil",
            },
            {
              type: "button",
              tooltip: "Hapus Banner",
              variant: "icon:delete",
              onClick: (e, item) => {
                id.current = item.id;
                setOpen({
                  ...open,
                  delete: true,
                });
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <DeleteDialog
        open={open.delete}
        loading={deleteData.loading}
        onClose={() =>
          setOpen({
            ...open,
            delete: false,
          })
        }
        onSubmit={() => {
          deleteData.fetch(id.current);
        }}
      />
    </>
  );
};

export default CMSBanner;
