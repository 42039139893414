import { Chip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const StatusChip = ({
  label,
  color,
  minWidth,
  maxWidth,
  background,
  auto,
  ...props
}) => {
  return (
    <Chip
      label={label}
      sx={{
        border: "1px solid",
        borderColor: color,
        backgroundColor: background,
        color: color,
        ...(auto
          ? {
              width: "100%",
            }
          : {
              minWidth: minWidth,
              maxWidth: maxWidth,
            }),
        ...props.sx,
      }}
    />
  );
};

StatusChip.defaultProps = {
  value: 0,
  background: "#e7f4ed",
  minWidth: "180px",
  maxWidth: "180px",
  color: "#cc1d15",
  label: "-",
  auto: false,
  sx: {},
};

StatusChip.propTypes = {
  background: PropTypes.any,
  color: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.number,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  auto: PropTypes.bool,
  sx: PropTypes.object,
};
export default StatusChip;
