import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useMemo, useState } from "react";
import { statusList } from "./variables";
import { Controller, useForm } from "react-hook-form";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import { SketchPicker } from "react-color";
import { Box, OutlinedInput, Popover,  } from "@mui/material";
import FileInputBox from "components/TextField/FileInputBox";
import { newKategori, updateKategori } from "redux/sentraLokasi/action";
import Autocomplete from "components/AutoComplete/Autocomplete";

const Dialog = ({
  open,
  onClose,
  refresh,
  informasi,
  ikon,
  resetData,
  id,
  setRes,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset: resetForm,
    watch,
    clearErrors,
    setError,
    setValue,
    unregister,
  } = useForm({
    defaultValues: useMemo(() => {
      let obj = {};
      if (informasi) {
        obj = {
          ...obj,
          name: "",
          name_en: "",
          color: "",
          is_active: 1,
        };
      }
      if (ikon) {
        obj = {
          ...obj,
          icon: null,
        };
      }

      return obj;
    }, []),
    mode: "onChange",
  });

  const [elem, setElem] = useState(null);

  const action = useFetchData({
    action: !id ? newKategori : updateKategori,
    reset: resetForm,
    message: !id
      ? "Berhasil menambah kategori lokasi baru"
      : "Berhasil mengubah kategori lokasi",
    refresh: refresh,
    onSuccess: (data) => {
      onClose();
      if (setRes) {
        setRes(data);
      }
    },
  });

  useEffect(() => {
    if (resetData) {
      resetForm(resetData);
    }
  }, [resetData]);

  const onSubmit = async (data) => {
    if (resetData?.icon && data?.icon === resetData?.icon) {
      return onClose();
    }

    //convert to base64
    await convertToBase64(data.icon, async (base64, type) => {
      const body = {};
      if(base64 != null & type != null){
        data.icon = base64
        data.iconType = type
      }
      for (let key in data) {
        body[key] = data[key];
      }

      const formData = new URLSearchParams(body)
      await action.fetch(formData, id);
    })
  };

  const convertToBase64 = async (file, handler = null) => {
    if (file == null ){
      return handler(null, null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      const itype = arr[0].split("/")[1].split(";")[0]
      if (handler != null) {
        handler(arr[1], itype.split("+")[0])
      }
    }
  }

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
          resetForm();
        }}
        title={`${id ? "Edit" : "Tambah"} Kategori Lokasi`}
        loading={action.loading}
        valid={isValid}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        width="sm"
      >
        {informasi && (
          <>
            <HorizontalGrid label={"Nama Kategori"}>
              <MainController
                {...getProps("name")}
                rules={{
                  required: "Nama Kategori wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan Nama Kategori"
                  />
                )}
              />
             
            </HorizontalGrid>
            <HorizontalGrid label={"Nama Inggris"}>
              <MainController
                {...getProps("name_en")}
                rules={{
                  required: "Nama kategori dalam bahasa inggris wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan Nama kategori dalam bahasa inggris"
                  />
                )}
              />
            </HorizontalGrid>
            <HorizontalGrid label={"Warna Background "}>
              <MainController
                {...getProps("color")}
                rules={{ required: "Warna Background wajib diisi" }}
                render={({ field }) => (
                  <>
                    <OutlinedInput
                      {...field}
                      onClick={(e) => setElem(e.currentTarget)}
                      placeholder="Pilih warna (hex) / Masukan warna (hex)"
                      endAdornment={
                        <Box
                          sx={{
                            backgroundColor: watch("color"),
                            position: "absolute",
                            right: "10px",
                            width: "30px",
                            borderRadius: "4px",
                            height: "30px",
                          }}
                        ></Box>
                      }
                    />
                    <Popover
                      open={Boolean(elem)}
                      anchorEl={elem}
                      onClose={() => setElem(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        sx: {
                          boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.09)",
                        },
                      }}
                    >
                      <SketchPicker
                        color={field.value}
                        onChange={(color) => field.onChange(color.hex)}
                      />
                    </Popover>
                  </>
                )}
              />
            </HorizontalGrid>
            <HorizontalGrid label={"Status"}>
              <MainController
                {...getProps("is_active")}
                rules={{ required: "Status penerbit wajib diisi" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={statusList}
                    value={
                      statusList.find((item) => item.value == field.value) ||
                      null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value == value.value
                    }
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(1);
                      }
                      field.onChange(nv.value);
                    }}
                  />
                )}
              />
            </HorizontalGrid>
          </>
        )}
        {ikon && (
          <HorizontalGrid label={"Ikon"} centerLabel={false}>
            <MainController
              {...getProps("icon")}
              rules={{ required: "Ikon wajib diisi" }}
              desc="Atur kategori ikon. Hanya gambar format *.png yang diterima"
              render={({ field }) => (
                <>
                  <FileInputBox
                    {...field}
                    accept="image/png"
                    setError={setError}
                    onClose={() => field.onChange(null)}
                  />
                </>
              )}
            />
          </HorizontalGrid>
        )}
      </MainDialog>
    </>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  resetData: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
  informasi: PropTypes.bool,
  ikon: PropTypes.bool,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  resetData: null,
  id: null,
  setRes: null,
  informasi: false,
  ikon: false,
};
export default Dialog;
