import PropTypes from "prop-types";
import { Box, InputAdornment } from "@mui/material";
import React from "react";

const EndAdornment = ({ text }) => {
  return (
    <InputAdornment position="end">
      <Box
        sx={{
          backgroundColor: "#d4d5d5",
          padding: "11px",
          color: "#111",
          fontSize: "14px",
          fontWeight: "700",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          position: "absolute",
          right: 0,
        }}
      >
        {text}
      </Box>
    </InputAdornment>
  );
};

EndAdornment.propTypes = {
  text: PropTypes.any,
};

EndAdornment.defaultProps = {
  text: "Buka Folder",
};

export default EndAdornment;
