import { ExpandMore, Search } from "@mui/icons-material";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import PageTitle from "components/Page/PageTitle";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import NIKStatus from "components/Status/NIKStatus";
import MainTable from "components/Table/MainTable";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPenerimaBansos } from "redux/subsidiBansos/action";
import {
  List,
  Loading,
  TotalItems,
  CurrentPage,
  Params,
  Semua,
  TidakDitemukan,
  TidakSama,
  Unverif,
  Verif,
  NamaProgram,
} from "redux/subsidiBansos/PenerimaBansos/selectors";
import {
  changeLimit,
  changePage,
  changeSearch,
  changeTipe,
} from "redux/subsidiBansos/PenerimaBansos/slice";
import { NIKStatusV } from "../variables";

const PenerimaSubsidiBansos = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  // const filterName = useSelector(FilterName);
  // const fail = useSelector(Fail);
  const semua = useSelector(Semua);
  const tidakDitemukan = useSelector(TidakDitemukan);
  const tidakSama = useSelector(TidakSama);
  const unverif = useSelector(Unverif);
  const verif = useSelector(Verif);
  const namaProgram = useSelector(NamaProgram);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const io = async () => {
      await fetchData();
    };
    io();
  }, [currentPage, params.limit, params.tipe, params.search]);

  const fetchData = async () => {
    await dispatch(
      getPenerimaBansos({
        params,
        id,
      })
    );
  };

  const penerimaOption = [
    {
      id: 0,
      label: "Semua",
      value: semua,
      color: "#4E4751",
    },
    {
      id: 1,
      label: "Terverifikasi",
      value: verif,
      backgroundColor: "#E7F4ED",
      color: "#129350",
    },
    {
      id: 2,
      label: "Tidak Terverifikasi",
      value: unverif,
      backgroundColor: "#F1E5FE",
      color: "#7300F7",
    },
    {
      id: 3,
      label: "Tidak Ditemukan",
      value: tidakDitemukan,
      backgroundColor: "#EDECED",
      color: "#4E4751",
    },
    {
      id: 4,
      label: "Tidak Sama",
      value: tidakSama,
      backgroundColor: "#FEF7E5",
      color: "#F7B500",
    },
  ];

  const headers = [
    {
      name: "Nama Penerima",
      key: "nama",
      width: 30,
    },
    {
      name: "NIK/NIP/ID Lain",
      key: "no_identitas",
      width: 50,
    },
    {
      name: "Status ID Penerima",
      custom: (item) => {
        return <NIKStatus arr={penerimaOption} value={item?.status_verif} />;
      },
      width: 20,
    },
  ];

  return (
    <>
      <PageTitle
        title={`Penerima ${namaProgram}`}
        text="Dasbor / CSR Bansos / Program Bantuan / Kartu Subsidi / "
        lastText="Detail Penerima Bantuan"
      />
      <MainCard
        addButton={false}
        customTitle={
          <Autocomplete
            options={penerimaOption}
            value={penerimaOption.find((item) => item.id === params?.tipe)}
            onChange={(_, nv) => {
              dispatch(changeTipe(nv.id));
            }}
            sx={{
              outline: "2px solid #4E5D78 !important",
              borderRadius: "5px",
              width: "230px",
            }}
            disableClearable
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    padding: "10px 10px",
                  }}
                >
                  {option.label}
                  <Box
                    sx={{
                      backgroundColor: option.color,
                      marginLeft: "10px",
                      width: "20px",
                      textAlign: "center",
                      borderRadius: "4px",
                      color: "white",
                    }}
                  >
                    {option.value}
                  </Box>
                </li>
              );
            }}
            renderInput={(p) => (
              <TextField
                {...p}
                sx={{
                  input: {
                    padding: "4px !important",
                  },
                }}
                InputProps={{
                  ...p.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      <Box
                        sx={{
                          backgroundColor: penerimaOption.find(
                            (item) => item.id === params?.tipe
                          )?.color,
                          marginLeft: "10px",
                          width: "20px",
                          textAlign: "center",
                          borderRadius: "4px",
                          color: "white",
                        }}
                      >
                        {
                          penerimaOption.find(
                            (item) => item.id === params?.tipe
                          )?.value
                        }
                      </Box>
                      <ExpandMore
                        sx={{ position: "absolute", right: "13px" }}
                      />
                    </React.Fragment>
                  ),
                  sx: {
                    backgroundColor: "#fff !important",
                  },
                }}
                placeholder=""
              />
            )}
          />
        }
        custom={
          <TextField
            placeholder="Search..."
            onChange={(e) => {
              dispatch(changeSearch(e.target.value));
            }}
            value={params?.search || ""}
            InputProps={{
              sx: {
                outline: "1px solid #D0D0D0",
                backgroundColor: "#fff !important",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    fontSize="medium"
                    sx={{
                      color: "#4E4751",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        }
      >
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          action=""
          summaryKey="nama"
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>
    </>
  );
};

export default PenerimaSubsidiBansos;
