import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import TrashIcon from "assets/paper/TrashIcon";

const MainDialog = ({
  open,
  onClose,
  title,
  children,
  loading,
  handleSubmit,
  onSubmit,
  valid,
  width,
  action,
  closeButton,
  submitText,
  customWidth,
  type,
  onReset,
  custom,
  buttonWidth,
  secondaryVariant,
  ...props
}) => {
  const br = width == "lg" ? "1108px" : width == "xs" ? "400px" : "570px";
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (loading) {
          return;
        }

        onClose();
      }}
      scroll="body"
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            maxWidth: Boolean(customWidth) ? customWidth : br,
            width: "calc(100% - 10%)",
            margin: "0px",
          },
        },
      }}
    >
      {closeButton && (
        <DialogTitle sx={{ m: 0, p: 2 }} variant="h2">
          {title}
          {closeButton && (
            <IconButton
              aria-label="close"
              disabled={loading}
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 13,
                color: "#4E4751",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers {...props}>
          {children}
        </DialogContent>
        {action && (
          <DialogActions>
            {type == 1 ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                p={2}
              >
                <Button variant="gray" onClick={onReset}>
                  <TrashIcon /> Reset
                </Button>
                <Box display="flex" gap={1}>
                  <Button variant="gray" onClick={onClose}>
                    Batal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loading || !valid}
                    sx={{
                      minWidth: buttonWidth,
                    }}
                  >
                    {submitText}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Stack direction={"row"} spacing={2} mx="auto">
                <Button
                  variant={secondaryVariant}
                  onClick={onClose}
                  sx={{
                    minWidth: buttonWidth ? buttonWidth : "inherit",
                  }}
                >
                  Batal
                </Button>
                {custom && custom}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading || !valid}
                  sx={{
                    minWidth: buttonWidth,
                  }}
                >
                  {submitText}
                </Button>
              </Stack>
            )}
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

MainDialog.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.any,
  width: PropTypes.any,
  action: PropTypes.bool,
  closeButton: PropTypes.bool,
  submitText: PropTypes.string,
  customWidth: PropTypes.string,
  type: PropTypes.number,
  onReset: PropTypes.func,
  custom: PropTypes.any,
  buttonWidth: PropTypes.string,
  secondaryVariant: PropTypes.string,
};

MainDialog.defaultProps = {
  onClose: () => {},
  open: false,
  title: "",
  children: null,
  loading: false,
  valid: true,
  handleSubmit: () => {},
  onSubmit: null,
  width: "lg",
  action: true,
  closeButton: true,
  type: 0,
  submitText: "Submit",
  customWidth: null,
  custom: null,
  onReset: () => {},
  buttonWidth: "170px",
  secondaryVariant: "gray",
};
export default MainDialog;
