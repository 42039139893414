import React from "react";
import Providers from "components/RouteRoleProviders";
import Voucher from "pages/MasterData/MasterBantuan/Voucher";
import DetailBansosVoucher from "pages/MasterData/MasterBantuan/Voucher/DetailBansosVoucher";
import VoucherMobile from "pages/MasterData/MasterBantuan/Voucher/Mobile";
import ResponsiveView from "components/ResponsiveView";

const voucherRoutes = [
  {
    path: "/master-data/master-bantuan/voucer",
    element: (
      <Providers permission="Web:Voucher:All">
        <ResponsiveView onDesktop={Voucher} onMobile={VoucherMobile} />
      </Providers>
    ),
  },
  {
    path: "/master-data/master-bantuan/voucer/:id",
    element: (
      <Providers permission="Web:Voucher:All">
        <DetailBansosVoucher />
      </Providers>
    ),
  },
];

export default voucherRoutes;
