import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";

const MultiLine = ({ item }) => {
  return (
    <InputComponent item={item}>
      <OutlinedInput
        placeholder={item?.property?.keterangan?.value || "Placeholder"}
        multiline
        rows={6}
        readOnly
      />
    </InputComponent>
  );
};

MultiLine.propTypes = {
  item: PropTypes.object,
};
MultiLine.defaultProps = {
  item: {},
};
export default MultiLine;
