import PageTitle from "components/Page/PageTitle";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as Tool,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import DasborCard from "./components/DasborCard";
import User from "./icon/User";
import Email from "./icon/Email";
import NIK from "./icon/NIK";
import MainCard2 from "components/Paper/MainCard2";
import PieChart from "./components/PieChart";
import Attention from "assets/dasbor/chart/Attention";
import BorderLinearProgress from "./components/BarChart";
import Progress from "./components/Progress";
import { barChatData } from "./variable";
import CircleIcon from "./components/CircleIcon";
import TerdaftarIcon from "assets/dasbor/merchant/TerdaftarIcon";
import VerifikasiIcon from "assets/dasbor/merchant/VerifikasiIcon";
import MenungguVerfikasi from "assets/dasbor/merchant/MenungguVerfikasi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tool,
  Legend,
  ArcElement,
  BarElement
);

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Internet & TV Kabel",
      data: [0, 200, 100, 150, 60, 30, 200, 340, 180, 240, 180, 380],
      tension: 0.3,
      borderWidth: 1.5,
      borderColor: "#CC1D15",
      backgroundColor: "#CC1D15",
      pointBorderColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
      pointHoverBackgroundColor: "#CC1D15",
      pointHoverBorderColor: "#CC1D15",
    },
    {
      label: "PDAM",
      data: [50, 100, 120, 190, 260, 160, 130, 110, 200, 360, 290, 190],
      tension: 0.3,
      borderWidth: 1.5,
      borderColor: "#0C6CE2",
      backgroundColor: "#0C6CE2",
      pointBorderColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
      pointHoverBackgroundColor: "#0C6CE2",
      pointHoverBorderColor: "#0C6CE2",
      order: 1,
    },
  ],
};

const dataBar = {
  labels: [
    "Payment PPOB",
    "Transfer Out to Back Account",
    "QRIS",
    "Wildlife Komodo",
    "Travel",
  ],
  datasets: [
    {
      label: "# of Votes",
      data: [20, 30, 10, 17, 23],
      backgroundColor: ["#560C09", "#91150F", "#CC1D15", "#DD6862", "#F5B419"],
      borderColor: ["#fff", "#fff", "#fff"],
      borderWidth: 2,
      fill: false,
    },
  ],
};

const dataBar2 = {
  labels: ["Approved", "Rejected"],
  datasets: [
    {
      label: "# of Votes",
      data: [74, 26],
      backgroundColor: ["#cc1d15", "#CD7758"],
      borderColor: ["#fff", "#fff"],
      borderWidth: 2,
    },
  ],
};

const dataBar3 = {
  labels: ["KTP", "SIM", "Paspor"],
  datasets: [
    {
      label: "# of Votes",
      data: [40, 35, 25],
      backgroundColor: ["#cc1d15", "#CD7758", "#FAA900"],
      borderColor: ["#fff", "#fff", "#fff"],
      borderWidth: 2,
    },
  ],
};

const barChart = {
  labels: [
    "Gas",
    "HP Pascabayar",
    "ISAT",
    "PBB",
    "PDAM Jawa Barat",
    "PDAM Jawa Timur",
    "PLN",
    "PLNPASCA",
    "Telkom",
    "Telkomsel",
  ],
  datasets: [
    {
      data: [970, 820, 750, 600, 570, 400, 390, 370, 360, 240, 180],
      color: "#231f20;",
      borderRadius: 30,
      barThickness: 20,
      backgroundColor: "#E14343",
    },
  ],
};

const TBToption = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    htmlLegend: {
      containerID: "legend-container",
    },
    legend: {
      display: false,
    },
    datalabels: {
      color: "#fff",
      anchor: "center",
      align: "end",
      formatter: function (value, ctx) {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.forEach((data) => {
          sum += data;
        });
        let percentage = ((value * 100) / sum).toFixed() + "%";
        return percentage;
      },
      labels: {
        value: {
          color: "#fff",
        },
      },
      font: {
        size: "10",
        family: "Manrope",
        weight: "normal",
      },
    },
  },
};

const TBToption2 = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    htmlLegend: {
      containerID: "legend-container2",
    },
    legend: {
      display: false,
    },
    datalabels: {
      color: "#fff",
      anchor: "center",
      align: "center",
      formatter: function (value, ctx) {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.forEach((data) => {
          sum += data;
        });
        let percentage = ((value * 100) / sum).toFixed() + "%";
        return percentage;
      },
      labels: {
        value: {
          color: "#fff",
        },
      },
      font: {
        size: "10",
        family: "Manrope",
        weight: "normal",
      },
    },
  },
};

const TBToption3 = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    htmlLegend: {
      containerID: "legend-container3",
    },
    legend: {
      display: false,
    },
    datalabels: {
      color: "#fff",
      anchor: "center",
      align: "center",
      formatter: function (value, ctx) {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.forEach((data) => {
          sum += data;
        });
        let percentage = ((value * 100) / sum).toFixed() + "%";
        return percentage;
      },
      labels: {
        value: {
          color: "#fff",
        },
      },
      font: {
        size: "10",
        family: "Manrope",
        weight: "normal",
      },
    },
  },
};

const barOptions = {
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: "y",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return context.formattedValue + " Transaction";
        },
      },
    },
  },
  layout: {
    padding: {
      bottom: 30,
      top: 0,
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#231f20",
        font: {
          weight: "700",
          size: 12,
          family: "Manrope",
        },
        padding: 10,
      },
    },
    x: {
      ticks: {
        color: "#231f20",
        font: {
          size: 12,
          weight: "700",
          family: "Manrope",
        },
        stepSize: 200,
      },
    },
  },
};

const ChartDasbor = () => {
  const { sidebar } = useSelector((state) => state.global);

  return (
    <>
      <PageTitle title={`Dashboard`} backBtn={false} />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          <DasborCard
            desc="210"
            title="Pengguna Tedaftar"
            tooltipString="Total user dihitung berdasarkan jumlah nomor handphone yang telah terverifikasi"
            icon={<User />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DasborCard
            desc="131"
            title="Email Terverifikasi"
            tooltipString="Total email terverifikasi dihitung berdasarkan jumlah email dan nomor handphone yang telah terverifikasi"
            icon={<Email />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DasborCard
            desc="64"
            title="NIK Terverifikasi"
            tooltipString="Total NIK terverifikasi dihitung berdasarkan jumlah NIK dan nomor handphone yang telah terverifikasi"
            icon={<NIK />}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <PieChart
            id="legend-container"
            title="Jenis Transaksi  "
            data={dataBar}
            options={TBToption}
            sidebar={sidebar}
            tooltipString="Total user dihitung berdasarkan jumlah nomor handphone yang telah terverifikasi"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PieChart
            title="Status Digitalisasi KTP"
            id="legend-container2"
            data={dataBar2}
            options={TBToption2}
            sidebar={sidebar}
            tooltipString="Total digitalisasi KTP berdasarkan status ajudikasinya"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PieChart
            title="Jenis Digitalisasi Kartu"
            id="legend-container3"
            data={dataBar3}
            options={TBToption3}
            sidebar={sidebar}
            tooltipString="Total digitalisasi kartu berdasarkan jenis/tipe kartu"
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <MainCard2
            title={"5 Produk Teratas"}
            custom={
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      py: 1,
                      px: 2,
                      textAlign: "left",
                      maxWidth: "250px",
                    },
                  },
                }}
                title={
                  "10 produk teratas yang memiliki jumlah transaksi terbanyak"
                }
                placement="bottom"
                arrow
              >
                <span style={{ cursor: "pointer", marginRight: "10px" }}>
                  <Attention />
                </span>
              </Tooltip>
            }
          >
            <Box display="flex" flexDirection="column" p={2} gap={2}>
              {barChatData.map((item, i) => (
                <Progress key={i} label={item.label} value={item.value} />
              ))}
            </Box>
          </MainCard2>
        </Grid>

        <Grid item xs={12} md={4}>
          <MainCard2
            title={"Merchant"}
            sx={{
              minHeight: "357px",
            }}
          >
            <Box m={2} display="flex" flexDirection="column" gap={3}>
              <Box display="flex" alignItems="center" gap={2}>
                <CircleIcon icon={TerdaftarIcon} />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography variant="text2">Terdaftar</Typography>
                  <Typography variant="title4">45</Typography>
                </Box>
              </Box>
              <Box
                component="span"
                sx={{ borderBottom: "1px solid #f7f7f7" }}
              />
              <Box display="flex" alignItems="center" gap={2}>
                <CircleIcon icon={VerifikasiIcon} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="text2">Verififikasi</Typography>
                  <Typography variant="title4">45</Typography>
                </Box>
              </Box>
              <Box
                component="span"
                sx={{ borderBottom: "1px solid #f7f7f7" }}
              />
              <Box display="flex" alignItems="center" gap={2}>
                <CircleIcon icon={MenungguVerfikasi} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="text2">Menunggu Verifikasi</Typography>
                  <Typography variant="title4">15</Typography>
                </Box>
              </Box>
              <Box
                component="span"
                sx={{ borderBottom: "1px solid #f7f7f7" }}
              />
            </Box>
          </MainCard2>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartDasbor;
