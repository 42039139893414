import PropTypes from "prop-types";
import { Box, OutlinedInput, Popover } from "@mui/material";
import React, { useRef, useEffect, useState, useMemo } from "react";
import Swiper from "./Swiper";
import TimeIcon from "assets/paper/TimeIcon";

const hoursData = Array.from({ length: 24 }, (_, i) => {
  if (i < 10) {
    i = `0${i}`;
  }
  return i.toString();
});

const minutesData = Array.from({ length: 60 }, (_, i) => {
  if (i < 10) {
    i = `0${i}`;
  }
  return i.toString();
});

const TimePicker = ({
  hours,
  minutes,
  seconds,
  onChangeHours,
  onChangeMinutes,
  onChangeSeconds,
  withSeconds,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const imageRef = useRef(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const secondsData = useMemo(() => {
    if (withSeconds) {
      return Array.from({ length: 60 }, (_, i) => {
        if (i < 10) {
          i = `0${i}`;
        }
        return i.toString();
      });
    }
  }, [withSeconds]);

  const value = withSeconds
    ? `${hours || ""}${hours ? ":" : ""}${minutes || ""}${minutes ? ":" : ""}${
        seconds || ""
      }`
    : `${hours || ""}${hours ? ":" : ""}${minutes || ""}`;
  return (
    <>
      <OutlinedInput
        {...props}
        ref={imageRef}
        onClick={(e) => {
          if (props.disabled) return;
          handleClick(e);
        }}
        placeholder="00:00"
        value={value}
        readOnly
        endAdornment={
          <Box
          sx={{
            position: "absolute",
            top: 10,
            right: "11px",
            color: "#4E4751",
          }}
        >
          <TimeIcon />
        </Box>
        }
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginTop: "10px",
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.08)",
            overflow: "hidden",
            display: "flex",
          },
        }}
      >
        <Swiper
          seconds={seconds}
          secondsData={secondsData}
          withSeconds={withSeconds}
          hoursData={hoursData}
          hours={hours}
          minutes={minutes}
          open={open}
          minutesData={minutesData}
          onChangeHours={(e) => onChangeHours(hoursData[e])}
          onChangeMinutes={(e) => onChangeMinutes(minutesData[e])}
          onChangeSeconds={(e) => onChangeSeconds(secondsData[e])}
        />

        <div className="border-timepicker"></div>
      </Popover>
    </>
  );
};

TimePicker.propTypes = {
  hours: PropTypes.any,
  minutes: PropTypes.any,
  seconds: PropTypes.any,
  onChangeHours: PropTypes.func,
  onChangeMinutes: PropTypes.func,
  onChangeSeconds: PropTypes.func,
  disabled: PropTypes.bool,
  withSeconds: PropTypes.bool,
};

TimePicker.defaultProps = {
  hours: "",
  minutes: "",
  seconds: "",
  onChangeHours: () => {},
  onChangeMinutes: () => {},
  onChangeSeconds: () => {},
  disabled: false,
  withSeconds: false,
};

export default TimePicker;
