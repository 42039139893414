import { useEffect, useLayoutEffect, useState } from "react";

const useGetRole = () => {
  const [role, setRole] = useState({
    isSuperVisor: false,
    isOperator: false,
  });

  useLayoutEffect(() => {
    let permission = localStorage.getItem("permission-ntt");
    if (permission) {
      permission = JSON.parse(permission);
      let superV = permission.find((p) => p === "Web:AdjSp:All");
      let oprtr = permission.find((p) => p === "Web:AdjOps:All");
      // console.log(Boolean(superV));
      let localRole = {
        isSuperVisor: superV ? true : false,
        isOperator: oprtr ? true : false,
      };
      setRole(localRole);
    }
  }, []);

  return role;
};

export default useGetRole;
