import { Box } from "@mui/material";
import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import BroadcastStatus from "components/Status/BroadcastStatus";
import MainTable from "components/Table/MainTable";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  cancelNotifBroadcast,
  exportBroadcastSMS,
  getListPushNotif,
} from "redux/broadcast/action";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/broadcast/Notif/slice";
import Dialog from "./Dialog";
import DeleteDialog from "components/Dialog/DeleteDialog";

const filter = [
  {
    label: "Judul",
    value: "MessageTitle",
  },
  {
    label: "Konten",
    value: "MessageBody",
  },
  {
    label: "Pengiriman",
    type: "date",
    value: "SendDate",
  },
  {
    label: "Status",
    value: "Status",
  },
];

const headers = [
  {
    name: "Judul",
    key: "MessageTitle",
    width: 30,
  },
  {
    name: "Konten",
    custom: (data) => {
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          {data.MessageBody}
        </Box>
      );
    },
    width: 30,
  },
  {
    name: "Pengiriman",
    custom: (data) => {
      return moment(data.SendDate).format("DD / MM / YYYY");
    },
    width: 10,
  },
  {
    name: "Dibuat",
    custom: (data) => {
      moment.updateLocale("id", {
        relativeTime: {
          future: "dalam %s",
          past: "%s lalu",
          s: "beberapa detik",
          ss: "%d detik",
          m: "1 menit",
          mm: "%d menit",
          h: "1 jam",
          hh: "%d jam",
          d: "1 hari",
          dd: "%d hari",
        },
      });
      const createdMoment = moment(data.CreatedAt).locale("id");
      var stringAgo = createdMoment.format("DD / MM / YYYY");

      // if createdMoment time is lower than 1 day with current time return a * time ago
      if (moment().diff(createdMoment, "days") < 1) {
        stringAgo = createdMoment.fromNow();
      }

      return (
        <>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              maxWidth: "200px",
            }}
          >
            {data?.CreatedBy}
          </Box>
          <Box style={{ color: "#878889" }}>{stringAgo}</Box>
        </>
      );
    },
    width: 20,
  },
  {
    name: "Status",
    width: 30,
    custom: (data) => {
      return (
        <BroadcastStatus
          value={data.Status}
          sx={{
            minWidth: "118px",
            height: "34px",
          }}
        />
      );
    },
  },
];

const BroadcastPushNotif = () => {
  const [open, setOpen] = useState({
    dialog: false,
    delete: false,
  });

  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.pushNotifBroadcast);
  const dispatch = useDispatch();

  const id = useRef();

  const fetchData = () => dispatch(getListPushNotif(params));

  useAutoFetch(fetchData, currentPage, filterName);

  const cancel = useFetchData({
    action: cancelNotifBroadcast,
    message: "Broadcast Notif berhasil dibatalkan",
    onFinal: () => {
      setOpen({
        ...open,
        delete: false,
      });
      fetchData();
    },
  });

  const exportData = useFetchData({
    action: exportBroadcastSMS,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "broadcast-notif",
      });
    },
  });

  const handleExport = (type) => {
    exportData.fetch("", "", type);
  };

  return (
    <>
      <Page title="Broadcast" page={["Broadcast"]} menu="Push Notif" />

      <MainCard
        title="Push Notif"
        onClick={() =>
          setOpen({
            dialog: true,
          })
        }
        borderColor="#C8C8C8"
      >
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          fetchData={fetchData}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="MessageTitle"
          detail={[
            {
              type: "link",
              tooltip: "Lihat data",
              variant: "icon:eye",
              url_key: "broadcast/push-notif:Id",
            },
            {
              type: "button",
              tooltip: "Batalkan",
              variant: "icon:cancel",
              isHidden: (item) => {
                const send = moment(item.SendDate);
                const now = moment();

                if (now.diff(send, "minutes") > 3 || item.Status !== 2) {
                  return true;
                }
                return false;
              },
              onClick: (_, item) => {
                id.current = item.Id;
                setOpen({
                  ...open,
                  delete: true,
                });
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
      />

      <DeleteDialog
        open={open.delete}
        loading={cancel.loading}
        onClose={() =>
          setOpen({
            ...open,
            delete: false,
          })
        }
        title="Batalkan Broadcast?"
        onSubmit={() => {
          cancel.fetch(id.current);
        }}
      />
    </>
  );
};

export default BroadcastPushNotif;
