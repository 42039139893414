import PropTypes from "prop-types";
import { Delete } from "@mui/icons-material";
import { Box, OutlinedInput, styled } from "@mui/material";
import React, { useRef, useEffect, useContext } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const StyledBox = styled(Box)(({ drag }) => ({
  display: "flex",
  fontFamily: "ManropeSemiBold",
  fontWeight: "600",
  gap: "20px",
  marginTop: "15px",
  ...(drag
    ? {
        border: "3px dashed #cc1d15",
        transition: "0.1s",
      }
    : {}),
}));

const FiturOpsiItem = ({
  style,
  v,
  item,
  index,
  deleteOption,
  property,
  handleSortableFiturOpsi,
  handleChangeOpsi,
}) => {
  const dragRef = useRef(null);
  const dropRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "opsi",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === undefined) {
        return;
      }
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleSortableFiturOpsi(v, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "opsi",
    item: () => {
      return { ...item, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  drag(dragRef);
  drop(dropRef);

  return (
    <StyledBox
      drag={isDragging}
      ref={dropRef}
      data-handler-id={handlerId}
      style={{ ...style }}
    >
      <Box
        sx={{
          border: "none",
          outline: "none",
          cursor: "pointer",
          display: "flex",
          marginTop: "auto",
          marginBottom: "auto",
        }}
        ref={dragRef}
      >
        <DragIndicatorIcon fontSize="small" />
      </Box>
      <OutlinedInput
        value={item.label ?? ""}
        placeholder="Masukkan nama opsi"
        autoComplete="off"
        spellCheck="false"
        onInput={(e) => {
          if (property?.batasi_karakter && property?.batasi_karakter?.value) {
            if (e.target.value.length > property?.batasi_karakter?.value) {
              e.target.value = e.target.value.slice(
                0,
                property?.batasi_karakter?.value
              );
            }
          }
        }}
        onChange={(e) => handleChangeOpsi(e, v, item, index)}
      />
      <Box
        sx={{
          border: "none",
          outline: "none",
          cursor: "pointer",
          display: "flex",
          marginTop: "auto",
          marginBottom: "auto",
        }}
        onClick={() => deleteOption(item?.id)}
      >
        <Delete style={{ width: 24, color: "#74788d", cursor: "pointer" }} />
      </Box>
    </StyledBox>
  );
};

FiturOpsiItem.propTypes = {
  deleteOption: PropTypes.func,
  handleChangeOpsi: PropTypes.func,
  handleSortableFiturOpsi: PropTypes.func,
  index: PropTypes.any,
  item: PropTypes.any,
  property: PropTypes.any,
  style: PropTypes.any,
  v: PropTypes.any,
};

FiturOpsiItem.defaultProps = {
  deleteOption: null,
  handleChangeOpsi: null,
  handleSortableFiturOpsi: null,
  index: null,
  item: null,
  property: null,
  style: null,
  v: null,
};

export default FiturOpsiItem;
