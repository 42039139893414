import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import DragIcon from "assets/cms/formulir/DragIcon";
import React from "react";

const DragCard = ({ icon: Icon, name, description }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      px={1}
      sx={{
        outlineColor: "#fff",
        cursor: "pointer",
        transition: "outline-color 0.3s",
        m: 0.5,
        "&:hover": {
          outline: "2px solid",
          outlineColor: "#cc1d15",
          borderRadius: 1,
          ".dragicon": {
            width: "30px",
            overflow: "visible",
          },
        },
        py: 1,
      }}
    >
      <Box
        className="dragicon"
        sx={{
          width: "0px",
          overflow: "hidden",
          transition: "width 0.2s",
          mt: 0.9,
        }}
      >
        <DragIcon />
      </Box>
      <Box
        sx={{
          p: 1,
          backgroundColor: "#F8F8F8",
          display: "flex",
          borderRadius: 1,
        }}
      >
        <Icon />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={0.5}
        sx={{
          overflow: "hidden",
          ml: 1.5,
        }}
      >
        <Typography variant="label2">{name}</Typography>
        <Typography
          variant="text2"
          color="#74788D"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

DragCard.propTypes = {
  description: PropTypes.any,
  icon: PropTypes.any,
  name: PropTypes.any,
};

DragCard.defaultProps = {
  description: "",
  icon: null,
  name: "",
};

export default DragCard;
