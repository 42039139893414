import PropTypes from "prop-types";
import React, { useRef, useEffect, useMemo } from "react";
import { register } from "swiper/element/bundle";
import "./style.css";

register();

const Swiper = ({
  hoursData,
  minutesData,
  hours,
  minutes,
  open,
  onChangeHours,
  onChangeMinutes,
  onChangeSeconds,
  seconds,
  withSeconds,
  secondsData,
}) => {
  const swiperElRef = useRef(null);
  const swiperElRef2 = useRef(null);
  const swiperElRef3 = useRef(null);

  useEffect(() => {
    swiperElRef.current.addEventListener("touchend", (e) => {
      const [swiper] = e.detail;
      onChangeHours(swiper.realIndex);
    });

    swiperElRef.current.addEventListener("tap", (e) => {
      const [swiper] = e.detail;
      onChangeHours(swiper.realIndex);
    });

    swiperElRef2.current.addEventListener("touchend", (e) => {
      const [swiper] = e.detail;
      onChangeMinutes(swiper.realIndex);
    });

    swiperElRef2.current.addEventListener("tap", (e) => {
      const [swiper] = e.detail;
      onChangeMinutes(swiper.realIndex);
    });

    if(withSeconds){
      swiperElRef3.current.addEventListener("touchend", (e) => {
        const [swiper] = e.detail;
        onChangeSeconds(swiper.realIndex);
      });
      
      swiperElRef3.current.addEventListener("tap", (e) => {
        const [swiper] = e.detail;
        onChangeSeconds(swiper.realIndex);
      });

    }
  }, []);

  const jam = useMemo(() => {
    if (open) {
      return hours;
    }
  }, [open]);

  const menit = useMemo(() => {
    if (open) {
      return minutes;
    }
  }, [open]);

  return (
    <>
      <swiper-container
        ref={swiperElRef}
        slide-to-clicked-slide="true"
        slides-per-view="3"
        direction="vertical"
        free-mode="true"
        free-mode-sticky="true"
        free-mode-momentum-ratio="0.25"
        free-mode-velocity-ratio="0.25"
        free-mode-minimum-velocity="0.25"
        loop="true"
        loop-prevents-sliding="false"
        centered-slides="true"
        initial-slide={hoursData.findIndex((i) => i == jam)}
      >
        {hoursData.map((item) => (
          <swiper-slide key={item}>
            <span>{item}</span>
          </swiper-slide>
        ))}
      </swiper-container>
      <swiper-container
        ref={swiperElRef2}
        slides-per-view="3"
        direction="vertical"
        free-mode="true"
        free-mode-sticky="true"
        free-mode-momentum-ratio="0.25"
        free-mode-velocity-ratio="0.25"
        free-mode-minimum-velocity="0.25"
        loop="true"
        loop-prevents-sliding="false"
        centered-slides="true"
        slide-to-clicked-slide="true"
        initial-slide={minutesData.findIndex((i) => i == menit)}
      >
        {minutesData.map((item) => (
          <swiper-slide key={item}>
            <span>{item}</span>
          </swiper-slide>
        ))}
      </swiper-container>

      {withSeconds && (
        <swiper-container
          ref={swiperElRef3}
          slides-per-view="3"
          direction="vertical"
          free-mode="true"
          free-mode-sticky="true"
          free-mode-momentum-ratio="0.25"
          free-mode-velocity-ratio="0.25"
          free-mode-minimum-velocity="0.25"
          loop="true"
          loop-prevents-sliding="false"
          centered-slides="true"
          slide-to-clicked-slide="true"
          initial-slide={secondsData.findIndex((i) => i == seconds)}
        >
          {secondsData.map((item) => (
            <swiper-slide key={item}>
              <span>{item}</span>
            </swiper-slide>
          ))}
        </swiper-container>
      )}
    </>
  );
};

Swiper.propTypes = {
  hoursData: PropTypes.array,
  minutesData: PropTypes.array,
  hours: PropTypes.string,
  minutes: PropTypes.string,
  onChangeHours: PropTypes.func,
  onChangeMinutes: PropTypes.func,
  onChangeSeconds: PropTypes.func,
  open: PropTypes.bool,
  seconds: PropTypes.string,
  withSeconds: PropTypes.bool,
  secondsData: PropTypes.array,
};

Swiper.defaultProps = {
  onChangeHours: () => {},
  onChangeMinutes: () => {},
  onChangeSeconds: () => {},
  hoursData: [],
  minutesData: [],
  hours: "",
  minutes: "",
  open: false,
  seconds: "",
  withSeconds: false,
  secondsData: [],
};

export default Swiper;
