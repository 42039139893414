import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import PencilIcon from "assets/paper/PencilIcon";
import MainController from "components/Controller/MainController";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import Page from "components/Page";
import MainCard from "components/Paper/MainCard";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useState } from "react";
import {
  actionLoginSession,
  getListLoginSession,
} from "redux/pengaturan/action";
import { Controller, useForm } from "react-hook-form";
import TrashIcon from "assets/paper/TrashIcon";
import CheckPermission from "helper/permission";
import PageNotFound from "pages/PageNotFound";

const EndAdornment = ({ text }) => {
  return (
    <InputAdornment position="end">
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#c8c8c8",
          height: "100%",
          right: 0,
          display: "flex",
          width: "70px",
          alignItems: "center",
          justifyContent: "center",
          color: "#111",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        <Typography variant="text2">{text}</Typography>
      </Box>
    </InputAdornment>
  );
};

EndAdornment.propTypes = {
  text: PropTypes.any,
};
EndAdornment.defaultProps = {
  text: "",
};
const LoginSession = () => {
  
  const [edit, setEdit] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    clearErrors,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      hours: "",
      minutes: "",
      message: "",
    },
  });

  const get = useFetchData({
    action: getListLoginSession,
    snackbar: false,
    onSuccess: (data) => {
      reset({
        hours: data.Hours,
        minutes: data.Minutes,
        message: data.Message,
      });
    },
  });

  const update = useFetchData({
    action: actionLoginSession,
    message: "Berhasil update login kadaluarsa",
    onSuccess: (data) => {
      get.fetch();
      setEdit(false);
    },
  });

  useEffect(() => {
    get.fetch();
  }, []);

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const onReset = () => reset();
  const onClose = () => setEdit(false);
  const onSubmit = (data) => {
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    update.fetch(formData);
  };
  return (
    <>
      <Page
        title="Login Kadaluarsa"
        page={["Pengaturan"]}
        menu="Login Kadaluarsa"
      />

      <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid item md={9} xs={12}>
          <MainCard
            addButton={false}
            title="Konfigurasi"
            borderColor="#C8C8C8"
            custom={
              !edit ? (
                <Button
                  variant="secondary-outlined"
                  startIcon={<PencilIcon />}
                  onClick={() => setEdit(true)}
                  sx={{
                    width: "100px",
                    justifyContent: "space-evenly",
                  }}
                >
                  Ubah
                </Button>
              ) : null
            }
          >
            <Box display="flex" flexDirection="column" gap={2} p={2}>
              <HorizontalGrid
                label={"Expired Login"}
                center={false}
                col={[3, 9]}
              >
                <Box display="flex" gap={2}>
                  <MainController
                    {...getProps("hours")}
                    rules={{
                      required: "Expired hour wajib diisi",
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <OutlinedInput
                        {...field}
                        variant="v1"
                        disabled={!edit}
                        error={invalid}
                        placeholder="Masukan expired hours"
                        endAdornment={<EndAdornment text="Hour" />}
                      />
                    )}
                  />
                  <MainController
                    {...getProps("minutes")}
                    rules={{
                      required: "Expired minute wajib diisi",
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <OutlinedInput
                        {...field}
                        variant="v1"
                        disabled={!edit}
                        error={invalid}
                        placeholder="Masukan expired minute"
                        endAdornment={<EndAdornment text="Minute" />}
                      />
                    )}
                  />
                </Box>
              </HorizontalGrid>

              <HorizontalGrid
                label={"Warm Message"}
                center={false}
                col={[3, 9]}
              >
                <MainController
                  {...getProps("message")}
                  rules={{
                    required: "Message wajib diisi",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput
                      {...field}
                      disabled={!edit}
                      variant="v1"
                      error={invalid}
                      placeholder="Masukan warn message"
                      multiline
                      rows={6}
                    />
                  )}
                />
              </HorizontalGrid>
            </Box>

            <Collapse in={edit}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                borderTop="1px solid #C8C8C8"
                p={2}
              >
                <Button variant="gray" onClick={onReset}>
                  <TrashIcon /> Reset
                </Button>
                <Box display="flex" gap={1}>
                  <Button variant="gray" onClick={onClose}>
                    Batal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={update.loading || !isValid}
                    sx={{
                      minWidth: "170px",
                    }}
                  >
                    Perbarui
                  </Button>
                </Box>
              </Box>
            </Collapse>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginSession;
