import React from "react";

const Cancel = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5757_380790)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.41048 6.91023C6.6238 6.69691 6.96966 6.69691 7.18298 6.91023L9.50049 9.22774L11.818 6.91023C12.0313 6.69691 12.3772 6.69691 12.5905 6.91023C12.8038 7.12356 12.8038 7.46942 12.5905 7.68274L10.273 10.0002L12.5905 12.3178C12.8038 12.5311 12.8038 12.8769 12.5905 13.0903C12.3772 13.3036 12.0313 13.3036 11.818 13.0903L9.50049 10.7727L7.18299 13.0903C6.96967 13.3036 6.62381 13.3036 6.41048 13.0903C6.19716 12.8769 6.19716 12.5311 6.41049 12.3178L8.72799 10.0002L6.41048 7.68274C6.19716 7.46942 6.19716 7.12356 6.41048 6.91023Z"
          fill="#CC1D15"
        />
      </g>
      <rect x="0.5" y="1" width="18" height="18" rx="9" stroke="#CC1D15" />
      <defs>
        <clipPath id="clip0_5757_380790">
          <rect
            width="13"
            height="13"
            fill="white"
            transform="translate(3 3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cancel;
