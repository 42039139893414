import { getParams } from "api";
import { Region } from "api/endpoints";

export const getProvinsi = async (params) => {
  const response = await getParams(Region.getProvinsi, params);
  return response;
};

export const getKota = async (id, params) => {
  const response = await getParams(`${Region.getKota}/${id}`, params);
  return response;
};

export const getKecamatan = async (id, params) => {
  const response = await getParams(`${Region.getKecamatan}/${id}`, params);
  return response;
};

export const getKelurahan = async (id, params) => {
  const response = await getParams(`${Region.getKelurahan}/${id}`, params);
  return response;
};
