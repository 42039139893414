import PropTypes from "prop-types";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import MainStatus from "components/Status/MainStatus";
import FormatRupiah from "helper/FormatRupiah";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { tipe_voucher } from "./variables";
import Dialog from "./Dialog";
import { getDetailVoucerBansos } from "redux/voucher/action";
import StatusKartuSubsidi from "components/Status/StatusKartuSubsidi";
import CopyIcon from "assets/voucher/detail/CopyIcon";

const informasiList = [
  "IssuerId",
  "MerchantCode",
  "VoucherName",
  "VoucherType",
  "Status",
  "StartDate",
  "EndDate",
  "VoucherDesc",
  "VoucherTC",
  "AmountValue",
  "RewardAmount",
  "PercentageValue",
  "MinTrx",
  "MaxTrx",
  "Budget",
  "Distributed",
];

const informasi = [
  "IssuerName",
  "MerchantCode",
  "VoucherName",
  "VoucherType",
  "Status",
  "PeriodeVoucher",
  "VoucherDesc",
  "VoucherTC",
  "AmountValue",
  "RewardAmount",
  "PercentageValue",
  "MinTrx",
  "MaxTrx",
  "Budget",
  "Distributed",
  "DistributeCounter",
  "Signature",
  "IssuerPublic",
];

const voucher = ["PrefixCode", "Start", "End", "GenerateCounter"];

const kode_voucher = ["PrefixCode", "End", "Start"];

const titleArr = [
  {
    name: "IssuerName",
    value: "Penerbit Voucer",
  },
  {
    name: "MerchantCode",
    value: "Kode Penerbit",
  },
  {
    name: "VoucherName",
    value: "Nama Voucer",
  },
  {
    name: "VoucherType",
    value: "Tipe Voucer",
  },
  {
    name: "Status",
    value: "Status Voucer",
  },
  {
    name: "PeriodeVoucher",
    value: "Periode Voucer",
  },
  {
    name: "VoucherDesc",
    value: "Deskripsi Voucer",
  },
  {
    name: "VoucherTC",
    value: "Syarat & Ketentuan",
  },
  {
    name: "AmountValue",
    value: "Nominal Nilai",
  },
  {
    name: "RewardAmount",
    value: "Nilai Reward",
  },
  {
    name: "PercentageValue",
    value: "Nilai Persentase",
  },
  {
    name: "MinTrx",
    value: "Minimum Transaksi",
  },
  {
    name: "MaxTrx",
    value: "Maksimum Transaksi",
  },
  {
    name: "Budget",
    value: "Jumlah Anggaran",
  },
  {
    name: "Distributed",
    value: "Jumlah Distribusi",
  },
  {
    name: "DistributeCounter",
    value: "Voucer Terdistribusi",
  },
  {
    name: "Signature",
    value: "Digital Signature",
  },
  {
    name: "IssuerPublic",
    value: "RSA Public Key",
  },
  {
    name: "PrefixCode",
    value: "Kode Prefiks",
  },
  {
    name: "Start",
    value: "Nomor Awal",
  },
  {
    name: "End",
    value: "Nomor Akhir",
  },
  {
    name: "GenerateCounter",
    value: "Voucer Yang Terbit",
  },
];

const RenderValue = ({ title, value }) => {
  const [clipTxt, setClipTxt] = useState("");

  switch (title) {
    case "Status":
      return <StatusKartuSubsidi value={value} />;
    case "VoucherType":
      return tipe_voucher.find((i) => i.value == value)?.label;
    case "Budget":
      return FormatRupiah(value);
    case "AmountValue":
      return FormatRupiah(value);
    case "RewardAmount":
      return FormatRupiah(value);
    case "PercentageValue":
      return FormatRupiah(value);
    case "MinTrx":
      return FormatRupiah(value);
    case "MaxTrx":
      return FormatRupiah(value);
    case "IssuerPublic":
      return (
        <>
          <Box
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "300px",
            }}
          >
            {value}
          </Box>
          <Tooltip
            title={clipTxt}
            placement="bottom"
            onMouseEnter={() => setClipTxt("Copy to clipboard")}
            onClick={() => {
              setClipTxt("Copied");
              navigator.clipboard.writeText(value);
            }}
          >
            <IconButton>
              <CopyIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    case "Signature":
      return (
        <>
          <Box
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            id={title}
          >
            {value}
          </Box>
          <Tooltip
            title={clipTxt}
            placement="bottom"
            onMouseEnter={() => setClipTxt("Copy to clipboard")}
            onClick={() => {
              setClipTxt("Copied");
              navigator.clipboard.writeText(value);
            }}
          >
            <IconButton>
              <CopyIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    case "VoucherTC":
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );
    default:
      return (
        <Box
          sx={{
            whiteSpace: "pre-line",
          }}
        >
          {value}
        </Box>
      );
  }
};

RenderValue.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
};

RenderValue.defaultProps = {
  title: "",
  value: "",
};

const DetailBansosVoucher = () => {
  const [res, setRes] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const [informasiData, setInformasiData] = useState([]);
  const [kodeVoucherData, setKodeVoucherData] = useState([]);

  const [cutModal, setCutModal] = useState({
    dokumen_info: true,
    kode_voucher: false,
    ikon: false,
  });

  const id = useParams().id;

  const action = useFetchData({
    action: getDetailVoucerBansos,
    snackbar: false,
  });

  useEffect(() => {
    action.fetch(id);
  }, []);

  useEffect(() => {
    setRes(action.response);
  }, [action.response]);

  useEffect(() => {
    if (res) {
      setInformasiData([]);
      setKodeVoucherData([]);
      let inf = [];
      let kv = [];
      for (const key in informasi) {
        if (
          (res?.informasi?.[informasi[key]] !== undefined && informasi[key]) ||
          (informasi[key] === "PeriodeVoucher" &&
            res?.informasi?.StartDate !== undefined &&
            res?.informasi?.EndDate !== undefined)
        ) {
          inf.push({
            name: renderTitle(informasi[key]),
            value:
              informasi[key] === "PeriodeVoucher" ? (
                moment(res?.informasi?.StartDate).format("DD/MM/YYYY") +
                " -> " +
                moment(res?.informasi?.EndDate).format("DD/MM/YYYY")
              ) : (
                <RenderValue
                  title={informasi[key]}
                  value={res?.informasi?.[informasi[key]]}
                />
              ),
          });
        }
      }

      for (const key in voucher) {
        if (res?.voucher?.[voucher[key]] !== null && voucher[key]) {
          kv.push({
            name: renderTitle(voucher[key]),
            value: String(res?.voucher?.[voucher[key]]) || "-",
          });
        }
      }
      setInformasiData(inf);
      setKodeVoucherData(kv);
    }
  }, [res]);

  const renderTitle = (str) => {
    let returnVal = "";
    for (const key in titleArr) {
      if (titleArr[key].name === str) {
        returnVal = titleArr[key].value;
      }
    }
    return returnVal;
  };

  const DialogData = () => {
    setCutModal({
      dokumen_info: true,
      kode_voucher: false,
      ikon: false,
    });

    const filtered = Object?.keys(res.informasi || {})
      ?.filter((key) => informasiList.includes(key))
      ?.reduce((oj, key) => {
        oj[key] = res.informasi[key];
        return oj;
      }, {});
    setData(filtered);
    setOpen(true);
  };

  const DialogDataVoucher = () => {
    setCutModal({
      dokumen_info: false,
      kode_voucher: true,
      ikon: false,
    });
    const filtered = Object.keys(res.voucher)
      .filter((key) => kode_voucher.includes(key))
      .reduce((oj, key) => {
        oj[key] = res.voucher[key] || "0";
        return oj;
      }, {});

    setData(filtered);
    setOpen(true);
  };

  const DialogDataGambar = () => {
    setCutModal({
      dokumen_info: false,
      kode_voucher: false,
      ikon: true,
      gambar: res.informasi.Gambar,
    });
    const filtered = {};
    filtered["Image1"] = null;
    setData(filtered);
    setOpen(true);
  };

  const isEdit =
    res?.informasi?.DistributeCounter === 0 &&
    res?.voucher?.GenerateCounter === 0;

  return (
    <>
      <Box onMouseEnter={() => setClipTxt("Copy to clipboard")}></Box>
      <PageTitle title={`Detail Voucer`} />
      <Grid container spacing={3}>
        <Grid xs={12} md={7} item>
          <MainCard
            title="Informasi"
            addButton={false}
            isEdit={isEdit}
            onClick={DialogData}
          >
            <MainLoading loading={action.loading} height={700}>
              {informasiData.map((item, i) => (
                <MainGrid
                  key={i}
                  label={item.name}
                  value={item.value}
                  labelMd={4}
                  valueMd={8}
                  striped="odd"
                  center={[
                    "Status Voucer",
                    "Digital Signature",
                    "RSA Public Key",
                  ].includes(item.name)}
                />
              ))}
            </MainLoading>
          </MainCard>

          <MainCard
            title="Kode Voucer"
            addButton={false}
            isEdit={isEdit}
            onClick={DialogDataVoucher}
            margin
          >
            <MainLoading loading={action.loading} height={200}>
              {kodeVoucherData.map((item, i) => (
                <MainGrid
                  key={i}
                  label={item.name}
                  value={item.value}
                  labelMd={4}
                  valueMd={8}
                  striped="odd"
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <MainCard
            title="Gambar Voucer"
            addButton={false}
            isEdit={isEdit}
            onClick={DialogDataGambar}
          >
            <MainLoading loading={action.loading}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "25px",
                  p: 2,
                }}
                src={res?.informasi?.Gambar}
              />
            </MainLoading>
          </MainCard>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        isEdit={true}
        setRes={setRes}
        id={id}
        data={data}
        {...cutModal}
      />
    </>
  );
};

export default DetailBansosVoucher;
