import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import Page from "components/Page";
import MainCard2 from "components/Paper/MainCard2";
import KependudukanDetailStatus from "components/Status/Kependudukan/Detail";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editKTP,
  getDetailKTP,
  getStatus,
  konfirmasiKTP,
  updateJadwalKTP,
  updateStatusKTP,
  verifikasiKTP,
} from "redux/kependudukan/action";
import moment from "moment";
import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputForm from "../components/InputForm";
import useGetRegion from "hook/useGetRegion";
import MainController from "components/Controller/MainController";
import UpdateStatus from "../components/UpdateStatus";
import CheckUpdateStatus from "../components/CheckUpdateStatus";
import DatePicker2 from "components/Picker/DatePicker2";
import TimePicker from "components/Picker/TimePicker";
import getRole from "helper/getRole";
import Autocomplete from "components/AutoComplete/Autocomplete";
import KependudukanNewDetailStatus from "components/Status/Kependudukan/NewDetail";
import { getKecamatan, getKelurahan, getKota } from "redux/region/action";

const confirmOptionOperator = [
  {
    label: "Diterima",
    value: "diproses",
  },
  {
    label: "Ditolak",
    value: "ditolak_lv3",
  },
];

const confirmOptionSupervisor = [
  {
    label: "Dijadwalkan",
    value: "dijadwalkan",
  },
  {
    label: "Ditolak",
    value: "ditolak",
  },
];

const updatestatus = [
  {
    label: "Verifikasi Data",
    data: "verification_date",
    open: false,
    value: moment(new Date()).format("DD/MM/YYYY HH:ss"),
  },
  {
    label: "Dijadwalkan",
    data: "scheduled_date",
    open: false,
    value: "",
  },
  {
    label: "Pencetakan Dok",
    data: "document_print_date",
    open: false,
    value: "",
  },
  {
    label: "Dok Fisik Tersedia",
    data: "available_document_date",
    open: false,
    value: "",
  },
  {
    label: "Selesai",
    data: "done_date",
    open: false,
    value: "",
  },
];

const DetailKTP = () => {
  const { isSuperVisor, isOperator } = getRole();
  const navigate = useNavigate();
  const { id } = useParams();
  const arr = useMemo(() => {
    return [
      {
        label: "NIK",
        name: "data.no_nik",
        warding: "warding.no_nik_warding",
        data: "no_nik",
        commentKey: "no_nik_warding",
        comment: "",
        value: "",
        openComment: false,
        openNotes: false,
        rules: {
          required: "No. NIK wajib diisi",
          maxLength: {
            message: "NO. NIK tidak boleh melebihi 16 karakter",
            value: 16,
          },
        },
      },
      {
        name: "data.no_kk",
        warding: "warding.no_kk_warding",
        data: "no_kk",
        commentKey: "no_kk_warding",
        label: "No. KK",
        value: "",
        comment: "",
        openNotes: false,
        openComment: false,
        rules: {
          required: "No. KK wajib diisi",
          maxLength: {
            message: "NO. KK tidak boleh melebihi 16 karakter",
            value: 16,
          },
        },
      },
      {
        name: "data.nama",
        warding: "warding.nama_warding",
        data: "nama",
        label: "Nama Lengkap",
        value: "",
        comment: "",
        commentKey: "nama_warding",
        openNotes: false,
        openComment: false,
        rules: { required: "Nama wajib diisi" },
      },
      {
        name: "data.alamat",
        warding: "warding.alamat_warding",
        data: "alamat",
        label: "Alamat",
        value: "",
        comment: "",
        commentKey: "alamat_warding",
        openNotes: false,
        openComment: false,
        rules: { required: "Alamat wajib diisi" },
      },
      {
        label: "Provinsi",
        data: "provinsi_id",
        warding: "warding.provinsi_warding",
        name: "data.provinsi_id",
        value: "",
        comment: "",
        commentKey: "provinsi_warding",
        openNotes: false,
        openComment: false,
        type: "autocomplete",
        options: [],
        onOpen: () => {
          provinsi.fetch();
          setValue("forms[4].loading", true);
        },
        loading: true,
        rules: { required: "Provinsi wajib diisi" },
      },
      {
        label: "Kota / Kab.",
        name: "data.kota_id",
        warding: "warding.kota_warding",
        data: "kota_id",
        value: "",
        comment: "",
        commentKey: "kota_warding",
        openNotes: false,
        openComment: false,
        type: "autocomplete",
        options: [],
        onOpen: () => {
          if (!getValues("data.provinsi_id")) {
            return setError("forms[5].value", {
              type: "noid",
              message: "Pilih Provinsi Dahulu",
            });
          }
          kota.fetch(getValues("data.provinsi_id"));
          setValue("forms[5].loading", true);
        },
        loading: true,
        rules: { required: "Kota wajib diisi" },
      },
      {
        name: "data.kecamatan_id",
        warding: "warding.kecamatan_warding",
        label: "Kecamatan",
        data: "kecamatan_id",
        value: "",
        comment: "",
        commentKey: "kecamatan_warding",
        openNotes: false,
        openComment: false,
        type: "autocomplete",
        options: [],
        onOpen: () => {
          if (!Boolean(getValues("data.kota_id"))) {
            return setError("forms[6].value", {
              type: "noid",
              message: "Pilih kota Terlebih Dahulu",
            });
          }
          kecamatan.fetch(getValues("data.kota_id"));
          setValue("forms[6].loading", true);
        },
        loading: true,
        rules: { required: "Kecamatan wajib diisi" },
      },
      {
        name: "data.kelurahan_id",
        warding: "warding.kelurahan_warding",
        label: "Kel. /  Desa",
        data: "kelurahan_id",
        value: "",
        comment: "",
        commentKey: "kelurahan_warding",
        openNotes: false,
        openComment: false,
        type: "autocomplete",
        options: [],
        onOpen: () => {
          if (!Boolean(getValues("data.kecamatan_id"))) {
            return setError("forms[7].value", {
              type: "noid",
              message: "Pilih Kecamatan Terlebih Dahulu",
            });
          }
          kelurahan.fetch(getValues("data.kecamatan_id"));
          setValue("forms[7].loading", true);
        },
        loading: true,
        rules: { required: "Kelurahan wajib diisi" },
      },
    ];
  }, []);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    trigger,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      data: {
        nama: "",
        no_kk: "",
        no_nik: "",
        alamat: "",
        provinsi_id: "",
        kota_id: "",
        kecamatan_id: "",
        kelurahan_id: "",
      },
      forms: arr,
      updateStatus: updatestatus,
    },
    mode: "onChange",
  });

  const { response, loading, fetch } = useFetchData({
    action: getDetailKTP,
    snackbar: false,
    onSuccess: (response) => {
      const data = response?.list[0];

      if (
        (isOperator && data.status == "dijadwalkan") ||
        (isOperator && data.status == "ditolak") ||
        (isOperator && data.notes)
      ) {
        return navigate("/laporan-adjudicator/sentra-kependudukan/ktp");
      }

      // if (isSuperVisor && data.status == "ditolak_lv3") {
      //   return navigate("/laporan-adjudicator/sentra-kependudukan/ktp");
      // }

      resetForm({
        data: {
          nama: data?.nama,
          no_kk: data?.no_kk,
          no_nik: data?.no_nik,
          alamat: data?.alamat,
          provinsi_id: data?.provinsi_id,
          kota_id: data?.kota_id,
          kecamatan_id: data?.kecamatan_id,
          kelurahan_id: data?.kelurahan_id,
        },
        warding: {
          nama_warding: data?.nama_warding,
          no_kk_warding: data?.no_kk_warding,
          no_nik_warding: data?.no_nik_warding,
          alamat_warding: data?.alamat_warding,
          provinsi_warding: data?.provinsi_warding,
          kota_warding: data?.kota_warding,
          kecamatan_warding: data?.kecamatan_warding,
          kecamatan_warding: data?.kecamatan_warding,
        },
        forms: arr,
        updateStatus: [],
        supervisor: {
          tanggal_kedatangan: data?.tanggal_kedatangan,
          lokasi_id: data?.lokasi_id,
          notes_supervisor: "",
          jam: moment(data?.jam_kedatangan, "HH:mm:ss").isValid()
            ? moment(data?.jam_kedatangan, "HH:mm:ss").format("HH")
            : "",
          menit: moment(data?.jam_kedatangan, "HH:mm:ss").isValid()
            ? moment(data?.jam_kedatangan, "HH:mm:ss").format("mm")
            : "",
          status: "",
        },
        operator: {
          notes: "",
          status: "",
        },
      });

      if (data?.status) {
        if (
          !isOperator &&
          data?.status != "diproses" &&
          data?.status != "ditolak" &&
          data?.status != "ditolak_lv3"
        ) {
          Status.fetch(id);
        }

        if (!isOperator) {
          const kecamatan_id = data?.lokasi_id?.slice(0, 4) || "";
          Lokasi.fetch(kecamatan_id);
        }
      }
    },
    onError: () => {
      return navigate("/laporan-adjudicator/sentra-kependudukan/ktp");
    },
  });

  const Status = useFetchData({
    action: getStatus,
    startLoading: true,
    snackbar: false,
  });

  const Jadwal = useFetchData({
    action: updateJadwalKTP,
    snackbar: false,
  });

  const Lokasi = useFetchData({
    action: getKecamatan,
    snackbar: false,
  });

  const Verifikasi = useFetchData({
    action: isSuperVisor ? konfirmasiKTP : verifikasiKTP,
    message: isSuperVisor
      ? "Data berhasil dikonfirmasi"
      : "Data berhasil diverifikasi",
    onSuccess: () => {
      navigate("/laporan-adjudicator/sentra-kependudukan/ktp");
    },
  });

  const UpdateStatusKTP = useFetchData({
    action: updateStatusKTP,
    message: "Update status berhasil",
    onSuccess: (data, index) => {
      setValue(`updateStatus.${index}.open`, false);
      fetch(id);
    },
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const data = response?.list[0];

  const { provinsi, kota, kecamatan, kelurahan } = useGetRegion({
    provinsi_id: data?.provinsi_id,
    kota_id: data?.kota_id,
    kecamatan_id: data?.kecamatan_id,
    kelurahan_id: data?.kelurahan_id,
    onSuccessProvinsi: (data) => {
      setValue("forms[4].options", data);
      setValue("forms[4].loading", false);
    },
    onSuccessKota: (data) => {
      setValue("forms[5].options", data);
      setValue("forms[5].loading", false);
    },
    onSuccessKecamatan: (data) => {
      setValue("forms[6].options", data);
      setValue("forms[6].loading", false);
    },
    onSuccessKelurahan: (data) => {
      setValue("forms[7].options", data);
      setValue("forms[7].loading", false);
    },
  });

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  useEffect(() => {
    let update = [];
    let slice = [];

    if (Status?.response?.values) {
      update = updatestatus?.map((item) => {
        return {
          ...item,
          open: false,
          value: Status?.response?.values[item.data] || "",
        };
      });
      slice = update.slice(0, Status?.response?.total_key);
    }

    setValue("updateStatus", slice);
  }, [Status?.response]);

  const update = useFetchData({
    action: editKTP,
    message: "berhasil mengubah data",
    onSuccess: (
      response,
      { name, data, index, openNotes, type, openComment }
    ) => {
      switch (type) {
        case "autocomplete":
          const arr = [
            "provinsi_id",
            "kota_id",
            "kecamatan_id",
            "kelurahan_id",
          ];

          const forms = watch("forms");
          Object.keys(response).map((item) => {
            if (arr.includes(item)) {
              const getIndex = forms.findIndex((i) => i.data == item);
              setValue(`forms.${getIndex}.openNotes`, false);
              setValue(`data.${item}`, response[item]);
            }
          });

          break;

        default:
          setValue(name, response[data]);
          if (openNotes) {
            setValue(`forms.${index}.openNotes`, !openNotes);
          } else {
            setValue(`forms.${index}.openComment`, !openComment);
          }
          break;
      }
    },
  });

  const handleEdit = (
    data,
    value,
    { name, index, openNotes, type, options, openComment }
  ) => {
    switch (type) {
      case "autocomplete":
        let copyInput = {};
        const arr = ["provinsi_id", "kota_id", "kecamatan_id", "kelurahan_id"];
        const newArr = arr.slice(arr.indexOf(data), arr.length);

        for (let i = 0; i < newArr.length; i++) {
          copyInput[newArr[i]] = "";
          copyInput[newArr[i].split("_id")[0]] = "";
        }
        copyInput[data] = value;
        copyInput[data.split("_id")[0]] = options?.find(
          (item) => item.id == value
        )?.nama;

        update.fetch(id, copyInput, { name, data, index, openNotes, type });
        break;

      default:
        const formData = new FormData();
        formData.append(data, value);
        update.fetch(id, formData, {
          name,
          data,
          index,
          openNotes,
          type,
          openComment,
        });
        break;
    }
  };

  const informasipermohonan = useMemo(() => {
    return [
      {
        label: "Status",
        value: <KependudukanNewDetailStatus value={data?.status} />,
      },
      {
        label: "Tgl. Status",
        value: moment(data?.updated_at).format("DD / MM / YYYY"),
      },
    ];
  }, [data?.status]);

  const handleUpdateStatus = () => {
    const up = watch("updateStatus");
    const nextHiddenItem = updatestatus.find((_, i) => i == up.length);
    nextHiddenItem.value = new Date();
    nextHiddenItem.open = true;
    if (nextHiddenItem) {
      setValue("updateStatus", [...up, nextHiddenItem]);
    }
  };

  const handleCheckUpdateStatus = (index, { data }) => {
    const formData = new FormData();
    formData.append(data, moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    UpdateStatusKTP.fetch(id, formData, index);
  };

  const handleCancelUpdateStatus = () =>
    setValue(`updateStatus`, watch("updateStatus").slice(0, -1));

  // const updateStatus = useMemo(() => {
  //   return
  // });

  const onSubmit = (data) => {
    const submitData = isSuperVisor ? data.supervisor : data.operator;
    if (isSuperVisor) {
      submitData.jam_kedatangan = `${submitData.jam}:${submitData.menit}:00`;
      // let find =
      //   Lokasi?.response?.find(
      //     (item) => item?.kecamatan_id == watch("supervisor.lokasi_id")
      //   )?.nama || null;
      submitData.tempat_kedatangan = "";
      delete submitData.jam;
      delete submitData.menit;
    }
    const { Id } = JSON.parse(localStorage.getItem("profile-ntt"));

    submitData.updatebyid = String(Id);

    Verifikasi.fetch(id, submitData);
    if (isSuperVisor) {
      Jadwal.fetch([
        {
          id: id,
          tanggal_kedatangan: moment(submitData.tanggal_kedatangan).format(
            "YYYY-MM-DD"
          ),
        },
      ]);
    }
  };

  return (
    <>
      <Page
        backBtn
        title={`Perbandingan Data - ${data?.nama.split(" ")[0] || ""}`}
        page={["Laporan Adjudicator", "Sentra Kependudukan", "KTP"]}
        menu="Perbandingan Data"
        to="/laporan-adjudicator/sentra-kependudukan/ktp"
      />

      <Grid
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
        spacing={2}
      >
        <Grid item xs={12} md={7}>
          <MainCard2 margin title="Informasi Permohonan">
            <MainLoading height={100} loading={loading}>
              {informasipermohonan.map((item, i) => (
                <MainGrid
                  key={i}
                  labelMd={3}
                  valueMd={9}
                  {...item}
                  striped="odd"
                  center
                />
              ))}
            </MainLoading>
          </MainCard2>
          <MainCard2 margin title="Informasi Pemohon">
            <MainLoading height={400} loading={loading} center>
              {getValues("forms").map((item, i) => {
                return (
                  <MainGrid
                    key={i}
                    labelMd={3}
                    valueMd={9}
                    label={item.label}
                    value={
                      <InputForm
                        index={i}
                        trigger={trigger}
                        getProps={getProps}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                        setValue={setValue}
                        handleEdit={handleEdit}
                        {...item}
                      />
                    }
                    striped="odd"
                  />
                );
              })}
            </MainLoading>
          </MainCard2>

          {!isOperator && data?.status != "ditolak" && (
            <MainCard2 margin title="Lokasi Kedatangan">
              <MainLoading height={80} loading={loading} center gap={1}>
                <MainGrid
                  labelMd={3}
                  valueMd={9}
                  striped=""
                  label="Lokasi"
                  value={
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      gap={1}
                    >
                      <MainController
                        {...getProps(`supervisor.lokasi_id`)}
                        customError={errors?.supervisor?.lokasi_id?.message}
                        rules={{
                          required:
                            watch("supervisor.status") != "ditolak"
                              ? "Lokasi Kedatangan wajib disii"
                              : false,
                        }}
                        render={({ field, fieldState: { invalid } }) => (
                          <Autocomplete
                            variant="v1"
                            options={Lokasi?.response || []}
                            value={
                              Lokasi?.response?.find(
                                (item) => item?.id == field?.value
                              ) || null
                            }
                            error={invalid}
                            disabled={
                              !isSuperVisor ||
                              (data?.status != "diproses" &&
                                data?.status != "ditolak_lv3")
                            }
                            getOptionLabel={(option) => option.nama}
                            loading={Lokasi.loading}
                            isOptionEqualToValue={(option, value) =>
                              option.id == value.id
                            }
                            placeholder="Pilih lokasi kedatangan"
                            onChange={(_, nv) => {
                              if (nv == null) {
                                return field.onChange(null);
                              }
                              field.onChange(nv.id);
                            }}
                          />
                        )}
                      />
                    </Box>
                  }
                  center
                />
              </MainLoading>
            </MainCard2>
          )}

          {!isOperator && data?.status != "ditolak" && (
            <MainCard2 margin title="Jadwal Kedatangan">
              <MainLoading height={100} loading={loading} center>
                <MainGrid
                  labelMd={3}
                  valueMd={9}
                  striped=""
                  label="Tanggal"
                  value={
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      gap={1}
                    >
                      <MainController
                        {...getProps(`supervisor.tanggal_kedatangan`)}
                        rules={{
                          required:
                            watch("supervisor.status") != "ditolak"
                              ? "Tanggal wajib disii"
                              : false,
                        }}
                        customError={
                          errors?.supervisor?.tanggal_kedatangan?.message
                        }
                        render={({ field, fieldState: { invalid } }) => (
                          <DatePicker2
                            {...field}
                            past
                            // format="DD-MM-YYYY"
                            error={invalid}
                            placeholder="DD/MM/YYYY"
                            disabled={
                              !isSuperVisor ||
                              (data?.status != "diproses" &&
                                data?.status != "ditolak_lv3")
                            }
                          />
                        )}
                      />
                    </Box>
                  }
                  center
                />
                <MainGrid
                  labelMd={3}
                  valueMd={9}
                  striped=""
                  label="Jam"
                  value={
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      gap={1}
                    >
                      <MainController
                        {...getProps(`supervisor.menit`)}
                        rules={{
                          required:
                            watch("supervisor.status") != "ditolak"
                              ? "Waktu wajib disii"
                              : false,
                        }}
                        customError={errors?.supervisor?.menit?.message}
                        render={({ field, fieldState: { invalid } }) => (
                          <TimePicker
                            error={invalid}
                            hours={watch("supervisor.jam")}
                            minutes={field.value}
                            onChangeHours={(e) => {
                              setValue("supervisor.jam", e);
                            }}
                            disabled={
                              !isSuperVisor ||
                              (data?.status != "diproses" &&
                                data?.status != "ditolak_lv3")
                            }
                            onChangeMinutes={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Box>
                  }
                  center
                />
              </MainLoading>
            </MainCard2>
          )}

          <MainCard2 margin title="Catatan">
            <MainLoading height={80} loading={loading} center>
              {data?.notes && (
                <Box display="flex" gap={1} pl={2} pt={1}>
                  <Typography variant="label" width="140px">
                    Dari Operator
                  </Typography>
                  <Typography variant="label">
                    : {data?.notes || "-"}
                  </Typography>
                </Box>
              )}
              {data?.notes_supervisor && (
                <Box display="flex" gap={1} pl={2} pt={1}>
                  <Typography variant="label" width="140px">
                    Dari Supervisor
                  </Typography>
                  <Typography variant="label">
                    : {data?.notes_supervisor || "-"}
                  </Typography>
                </Box>
              )}

              {(data?.status == "diproses" || data?.status == "ditolak_lv3") &&
              (isSuperVisor || isOperator) ? (
                <Box sx={{ p: 2 }}>
                  <MainController
                    {...getProps(
                      isSuperVisor
                        ? "supervisor.notes_supervisor"
                        : "operator.notes"
                    )}
                    customError={
                      isSuperVisor
                        ? errors?.supervisor?.notes_supervisor?.message
                        : errors?.operator?.notes.message
                    }
                    rules={{
                      required: "Catatan wajib disii",
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <OutlinedInput
                        {...field}
                        multiline
                        rows={6}
                        disabled={
                          data?.status != "diproses" &&
                          data?.status != "ditolak_lv3"
                        }
                        error={invalid}
                        placeholder="Cth: warna rambut berbeda"
                      />
                    )}
                  />
                </Box>
              ) : null}
            </MainLoading>
          </MainCard2>

          {(data?.status == "diproses" || data?.status == "ditolak_lv3") &&
          (isSuperVisor || isOperator) ? (
            <MainCard2 margin title="Keputusan">
              <MainLoading height={100} loading={loading} center>
                <Box display="flex" gap={1} p={2}>
                  <MainController
                    {...getProps(
                      `${
                        isSuperVisor ? "supervisor.status" : "operator.status"
                      }`
                    )}
                    rules={{ required: "Status wajib diisi" }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Autocomplete
                        variant="v1"
                        error={invalid}
                        {...field}
                        options={
                          isSuperVisor
                            ? confirmOptionSupervisor
                            : confirmOptionOperator
                        }
                        value={
                          isSuperVisor
                            ? confirmOptionSupervisor.find(
                                (item) => item.value === field.value
                              ) || null
                            : confirmOptionOperator.find(
                                (item) => item.value === field.value
                              ) || null
                        }
                        placeholder="Pilih keputusan akhir"
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        disabled={
                          data?.status != "diproses" &&
                          data?.status != "ditolak_lv3"
                        }
                        onChange={(_, nv) => {
                          if (nv == null) {
                            field.onChange(1);
                          }
                          field.onChange(nv.value);
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    sx={{
                      minWidth: "140px",
                    }}
                    disabled={!isValid}
                  >
                    {isSuperVisor ? "Konfirmasi" : "Validasi"}
                  </Button>
                </Box>
              </MainLoading>
            </MainCard2>
          ) : null}
        </Grid>

        <Grid item xs={12} md={5}>
          <MainCard2 margin title="Pas Photo">
            <MainLoading height={200} loading={loading} center>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  p: 2.5,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: 300,
                    borderRadius: "calc(0.6vw + 0.6vh)",
                    objectFit: "contain",
                    border: "1px solid #E5E7EB",
                  }}
                  src={data?.link_picture}
                />
              </Box>
            </MainLoading>
          </MainCard2>

          {!Status.loading &&
          !isOperator &&
          data?.status != "diproses" &&
          data?.status != "ditolak_lv3" &&
          data?.status != "ditolak" ? (
            <MainCard2 margin title="Update Status">
              <MainLoading height={70} loading={loading} center>
                <UpdateStatus
                  value={watch("updateStatus")?.map((item, i) => {
                    return {
                      label: item.label,
                      value: (
                        <CheckUpdateStatus
                          index={i}
                          key={item.key}
                          onCheck={handleCheckUpdateStatus}
                          onCancel={handleCancelUpdateStatus}
                          {...item}
                        />
                      ),
                    };
                  })}
                  onUpdate={handleUpdateStatus}
                  disabled={
                    watch("updateStatus").length == updatestatus.length ||
                    watch("updateStatus").some((i) => i.open == true) ||
                    Status.loading
                  }
                  status={data?.status}
                />
              </MainLoading>
            </MainCard2>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default DetailKTP;
