import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Box } from "@mui/material";
import { useDrop } from "react-dnd";

const EmptyColumn = ({ section, itemId, v, handleUpdateEmptyColumn }) => {
  const [{ isOverCurrentEmpty }, dropEmpty] = useDrop({
    accept: "builder",
    drop: (dropItem) => {
      if (isOverCurrentEmpty) {
        handleUpdateEmptyColumn(section, dropItem, itemId, v);
      }
    },
    collect: (monitor) => ({
      isOverCurrentEmpty: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <Box
      ref={dropEmpty}
      sx={{
        padding: "0px 0px 0px",
        transition: "height 0.1s ease-in-out",
        textIndent: "-999px",
        height: "100%",
        ...(!isOverCurrentEmpty ? {} : { height: "80px" }),
      }}
    >
      <Box
        sx={{
          ...(isOverCurrentEmpty
            ? {
                border: "2px dashed #cc1d15",
                borderRadius: "4px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "80px",
                color: "#cc1d15",
                width: "100%",
                display: "flex",
                boxSizing: "border-box",
                textIndent: 0,
                fontSize: "18px",
                px: 2,
              }
            : {}),
        }}
      >
        Taruh di sini untuk menambahkan
      </Box>
    </Box>
  );
};

EmptyColumn.propTypes = {
  handleUpdateEmptyColumn: PropTypes.func,
  itemId: PropTypes.any,
  section: PropTypes.any,
  v: PropTypes.any,
};

EmptyColumn.defaultProps = {
  handleUpdateEmptyColumn: null,
  itemId: null,
  section: null,
  v: null,
};

export default EmptyColumn;
