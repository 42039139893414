import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import { newPenerbit, updatePenerbit } from "redux/penerbit/action";
import { OutlinedInput } from "@mui/material";
import Autocomplete from "components/AutoComplete/Autocomplete";
import { getListFormulir, newDokumen } from "redux/CMS/action";
import FileInputBox from "components/TextField/FileInputBox";
import FileInput from "components/TextField/FileInput";
import FileValidator from "helper/FileValidator";
import InputImage from "components/TextField/InputImage";

const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

const adjList = [
  {
    label: "Ya",
    value: 1,
  },
  {
    label: "Tidak",
    value: 0,
  },
];

const kategoriData = [
  {
    value: 1,
    label: "Digital ID",
  },
  {
    value: 2,
    label: "Banner",
  },
];

const subKategoriData = [
  {
    value: 1,
    label: "Subsidi",
  },
  {
    value: 2,
    label: "KTP",
  },
  {
    value: 3,
    label: "Akta Kematian",
  },
  {
    value: 4,
    label: "TEST",
  },
  {
    value: 5,
    label: "Banner",
  },
  {
    value: 6,
    label: "TNI AD",
  },
];

const menuData = [
  {
    value: 1,
    label: "Subsidi",
  },
  {
    value: 2,
    label: "Sentra Kependudukan",
  },
  { value: 3, label: "Sentra Lokasi" },
  {
    value: 4,
    label: "TEST",
  },
  {
    value: 5,
    label: "Tidak ada",
  },
];

const subMenuData = [
  {
    value: 1,
    label: "Subsidi",
  },
  {
    value: 2,
    label: "Akta Kematian",
  },
  { value: 3, label: "Paspor" },
  {
    value: 4,
    label: "KTP",
  },
  {
    value: 5,
    label: "TEST",
  },
  {
    value: 6,
    label: "Tidak Ada",
  },
];

const Dialog = ({ open, onClose, refresh, isEdit, data, id, setRes }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      m_form_id: null,
      alias_formulir: "",
      menu: "",
      sub_menu: "",
      instansi: "",
      adjudikasi: null,
      status: null,
      ikon: "",
      kategori: "",
      sub_kategori: "",
      gambar: null,
    },
    mode: "onChange",
  });

  const action = useFetchData({
    action: newDokumen,
    reset: resetForm,
    message: "Berhasil menambah Dokumen baru",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const formulir = useFetchData({
    action: getListFormulir,
    snackbar: false,
  });

  useEffect(() => {
    if (data) {
      resetForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    await action.fetch(formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Kelola Dokumen"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="sm"
    >
      <HorizontalGrid label={"Nama Dokumen"}>
        <MainController
          {...getProps("m_form_id")}
          rules={{
            required: "Nama Dokumen wajib diisi",
          }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={formulir.response?.list || []}
              value={
                formulir.response?.list?.find(
                  (item) => item.id == field.value
                ) || null
              }
              onOpen={() => {
                formulir.setResponse([]);
                formulir.fetch({
                  filter: {
                    is_draft: 0,
                  },
                });
              }}
              error={invalid}
              getOptionLabel={(option) => option.form_title || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.id);
                }
              }}
              placeholder="Pilih dokumen yang tersedia"
            />
          )}
        />
      </HorizontalGrid>
      <HorizontalGrid label={"Alias Formulir"}>
        <MainController
          {...getProps("alias_formulir")}
          rules={{
            required: "Alias Formulir wajib diisi",
          }}
          render={({ field, fieldState: { invalid } }) => (
            <OutlinedInput
              {...field}
              error={invalid}
              placeholder="Masukan alias untuk formulir"
            />
          )}
        />
      </HorizontalGrid>
      <HorizontalGrid label={"Kategori"}>
        <MainController
          {...getProps("kategori")}
          rules={{ required: "Kategori wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={kategoriData}
              value={kategoriData.find((item) => item.value == field.value)}
              error={invalid}
              isOptionEqualToValue={(option, value) => option.value == value}
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.label);
                }
              }}
              placeholder="Pilih ID atau Dokumen"
            />
          )}
        />
      </HorizontalGrid>
      <HorizontalGrid label={"Sub Kategori"}>
        <MainController
          {...getProps("sub_kategori")}
          rules={{ required: "Sub Kategori wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={subKategoriData}
              value={subKategoriData.find((item) => item.value == field.value)}
              error={invalid}
              isOptionEqualToValue={(option, value) =>
                option.value == value.value
              }
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.label);
                }
              }}
              placeholder="Pilih Sub Kategori"
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Menu"}>
        <MainController
          {...getProps("menu")}
          rules={{ required: "Menu wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={menuData}
              value={menuData.find((item) => item.value == field.value)}
              isOptionEqualToValue={(option, value) =>
                option.value == value.value
              }
              error={invalid}
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.label);
                }
              }}
              placeholder="Pilih Menu"
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Sub Menu"}>
        <MainController
          {...getProps("sub_menu")}
          rules={{ required: "Sub Menu wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={subMenuData}
              value={subMenuData.find((item) => item.value == field.value)}
              isOptionEqualToValue={(option, value) =>
                option.value == value.value
              }
              error={invalid}
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.label);
                }
              }}
              placeholder="Pilih Sub Menu"
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Instansi"}>
        <MainController
          {...getProps("instansi")}
          rules={{
            required: "Instansi wajib diisi",
          }}
          render={({ field, fieldState: { invalid } }) => (
            <OutlinedInput
              {...field}
              error={invalid}
              placeholder="Masukan instansi terkait"
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Adjudikasi"}>
        <MainController
          {...getProps("adjudikasi")}
          rules={{ required: "Adjudikasi wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={adjList}
              value={adjList.find((item) => item.value == field.value)}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.value);
                }
              }}
              placeholder="Ya / Tidak"
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Status"}>
        <MainController
          {...getProps("status")}
          rules={{ required: "Status wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={statusList}
              error={invalid}
              value={statusList.find((item) => item.value == field.value)}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv) {
                  field.onChange(nv.value);
                }
              }}
              placeholder="Aktif / Tidak Aktif"
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Gambar"} center={false}>
        <MainController
          {...getProps("gambar")}
          rules={{ required: "Gambar wajib diisi" }}
          render={({ field }) => (
            <InputImage
              {...field}
              setError={setError}
              name="gambar"
              showImage={field.value}
              onClose={() => field.onChange(null)}
              placeholder={"Pilih gambar"}
            />
          )}
        />
      </HorizontalGrid>

      <HorizontalGrid label={"Ikon"} center={false}>
        <MainController
          {...getProps("ikon")}
          rules={{ required: "Ikon wajib diisi" }}
          desc="Atur kategori ikon. Hanya gambar format *.png dan *.svg yang diterima"
          render={({ field }) => (
            <>
              <FileInputBox
                {...field}
                accept="image/png,image/svg+xml"
                setError={setError}
                onClose={() => field.onChange(null)}
              />
            </>
          )}
        />
      </HorizontalGrid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
