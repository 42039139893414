import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useState, useEffect } from "react";
import { card_status, card_tipe, defaultValues } from "./variables";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import { getPenerbit } from "redux/penerbit/action";
import VerticalGrid from "components/Grid/VerticalGrid";
import { Box, Grid, OutlinedInput } from "@mui/material";
import MainContentEditable from "components/ContentEditable/MainContentEditable";
import MainDateRange from "components/Picker/MainDateRange";
import FormatRupiah from "helper/FormatRupiah";
import FormatNumber from "helper/FormatNumber";
import FileInput from "components/TextField/FileInput";
import FileValidator from "helper/FileValidator";
import MainTemplate from "components/Template/MainTemplate";
import KartuSubsidiBlue from "assets/kartu-subsidi/kartu-subsidi-blue.png";
import KartuSubsidiPurple from "assets/kartu-subsidi/kartu-subsidi-purple.png";
import KartuSubsidiGreen from "assets/kartu-subsidi/kartu-subsidi-green.png";
import { kartuSubsidiNew, kartuSubsidiUpdate } from "redux/kartuSubsidi/action";
import { useParams } from "react-router-dom";
import Autocomplete from "components/AutoComplete/Autocomplete";

const Dialog = ({
  open,
  onClose,
  refresh,
  data,
  informasi,
  ikon,
  isEdit,
  gambar,
  setRes,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: defaultValues(informasi, ikon),
    mode: "onChange",
  });

  const [chooseTemplate, setChooseTemplate] = useState(false);
  const [selectedCircle, setSelectedCircle] = useState({});
  const { id } = useParams();
  useEffect(() => {
    if (data) {
      resetForm(data);
    }
    if (open) {
      penerbit.setResponse([]);
      penerbit.fetch({
        filter: {
          IssuerStatus: 1,
        },
      });
    }
  }, [data]);

  const action = useFetchData({
    action: !isEdit ? kartuSubsidiNew : kartuSubsidiUpdate,
    reset: resetForm,
    message: !isEdit
      ? "Berhasil tambah kartu subsidi baru"
      : "Berhasil edit kartu subsidi",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const penerbit = useFetchData({
    action: getPenerbit,
    snackbar: false,
  });

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async (data) => {
    await convertToBase64(data.Image1, async (base64) => {
      await convertToBase64(data.Image2, async (base64_2) => {
        const body = {};
        if(base64 != null){
          data.Image1 = base64
        }
        if(base64_2 != null){
          data.Image2 = base64_2
        }
        for (let key in data) {
          body[key] = data[key];
        }
  
        const formData = new URLSearchParams(body)
        if (!id) {
          await action.fetch(formData);
        } else {
          await action.fetch(formData, id);
        }
      })
    })
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null || typeof file == 'string'){
      return handler(file)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const SvgToFile = async (field, item) => {
    await fetch(item.image)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const arr = reader.result.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          let bstr = atob(arr[1]);
          let n = bstr.length;
          let u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const file = new File([u8arr], "kartu_subsidi.png", {
            type: mime,
          });

          setSelectedCircle({
            ...item,
          });

          field.onChange(file);
        };
      });
  };

  const circleList = [
    {
      color: "#AB32BF",
      image: KartuSubsidiPurple,
      value: 1,
    },
    {
      color: "#3283BF",
      image: KartuSubsidiBlue,
      value: 2,
    },
    {
      color: "#53B665",
      image: KartuSubsidiGreen,
      value: 3,
    },
  ];

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
        setChooseTemplate(false);
      }}
      title={!isEdit ? "Tambah Kartu Subsidi" : "Edit Kartu Subsidi"}
      loading={action?.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    >
      <Grid container spacing={2}>
        {informasi && (
          <>
            <VerticalGrid label={"Nama Penerbit"}>
              <MainController
                {...getProps("IssuerId")}
                rules={{
                  required: "Nama penerbit wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    {...field}
                    options={penerbit.response?.list || []}
                    value={
                      penerbit.response?.list?.find(
                        (item) => item.id === field.value
                      ) || null
                    }
                    onOpen={() => {
                      penerbit.setResponse([]);
                      penerbit.fetch({
                        filter: {
                          IssuerStatus: 1,
                        },
                      });
                    }}
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                        setValue("MerchantCode", "", {
                          shouldValidate: true,
                        });
                        setValue("PrefixCode", "", {
                          shouldValidate: true,
                        });
                      } else {
                        field.onChange(nv.id);
                        setValue("MerchantCode", nv.IssuerCode, {
                          shouldValidate: true,
                        });
                        setValue("PrefixCode", nv.CardCode, {
                          shouldValidate: true,
                        });
                      }
                    }}
                    error={invalid}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.IssuerName}
                        </li>
                      );
                    }}
                    placeholder="Pilih penerbit"
                    loading={penerbit.loading}
                    getOptionLabel={(option) => option.IssuerName || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Kode Penerbit"} md={3}>
              <MainController
                {...getProps("MerchantCode")}
                rules={{ required: "Kode Penerbit wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    disabled
                    placeholder="Kode Penerbit"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Kode Prefiks"} md={3}>
              <MainController
                {...getProps("PrefixCode")}
                rules={{ required: "Kode Prefiks wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    disabled
                    placeholder="Kode Prefiks"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Nama Kartu Subsidi"} md={12}>
              <MainController
                {...getProps("CardName")}
                rules={{
                  required: "Nama Kartu Subsidi wajib diisi",
                  maxLength: {
                    message:
                      "Nama Kartu Subsidi tidak boleh melebihi 40 karakter",
                    value: 40,
                  },
                }}
                desc="Batas maksimum 40 karakter"
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Beri nama kartu subsidi"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Tipe Kartu Subsidi"}>
              <MainController
                {...getProps("CardType")}
                rules={{ required: "Tipe kartu subsidi wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    {...field}
                    error={invalid}
                    {...field}
                    options={card_tipe}
                    value={
                      card_tipe.find((option) => option.value == field.value) ||
                      null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv.value);
                      }
                    }}
                    placeholder="Pilih tipe kartu subsidi"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Status"}>
              <MainController
                {...getProps("Status")}
                rules={{ required: "Status wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    {...field}
                    options={card_status}
                    error={invalid}
                    value={
                      card_status.find(
                        (option) => option.value === field.value
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv.value);
                      }
                    }}
                    placeholder="Pilih Status"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Deskripsi kartu subsidi"}>
              <MainController
                {...getProps("CardDesc")}
                rules={{ required: "Deskripsi kartu subsidi wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    multiline
                    rows={6}
                    placeholder="Masukan Deskripsi"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Syarat & Ketentuan"}>
              <MainController
                {...getProps("CardTC")}
                rules={{ required: "Syarat kartu subsidi wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <MainContentEditable
                    {...field}
                    placeholder="Masukan Syarat & Ketentuan"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Periode Kartu Subsidi"}>
              <MainController
                {...getProps("EndDate")}
                rules={{ required: "Periode kartu subsidi wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <MainDateRange
                    onChange={(value) => {
                      setValue("StartDate", value[0]);
                      field.onChange(value[1]);
                    }}
                    start={watch("StartDate") || null}
                    end={watch("EndDate") || null}
                    past
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Nomor Awal"}>
              <MainController
                {...getProps("Start")}
                rules={{ required: "Nomor awal wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    onChange={(e) => {
                      e.target.value = FormatNumber(e.target.value);
                      field.onChange(e);
                      const nomor_akhir = parseInt(getValues("End"));
                      if (e.target.value === "") {
                        setValue("End", "", {
                          shouldValidate: true,
                        });
                      }
                      setValue(
                        "Distributed",
                        nomor_akhir - parseInt(e.target.value),
                        {
                          shouldValidate: true,
                        }
                      );
                      const budget =
                        parseInt(watch("AmountValue")) *
                        parseInt(watch("Distributed"));
                      setValue("Budget", budget, {
                        shouldValidate: true,
                      });
                    }}
                    placeholder="Masukan nomor awal"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Nomor Akhir"}>
              <MainController
                {...getProps("End")}
                rules={{ required: "Nomor akhir wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    onChange={(e) => {
                      e.target.value = FormatNumber(e.target.value);
                      field.onChange(e);
                      const nomor_awal = parseInt(getValues("Start"));
                      setValue(
                        "Distributed",
                        parseInt(e.target.value) - nomor_awal,
                        {
                          shouldValidate: true,
                        }
                      );
                      const budget =
                        parseInt(watch("AmountValue")) *
                        parseInt(watch("Distributed"));
                      setValue("Budget", budget, {
                        shouldValidate: true,
                      });
                    }}
                    placeholder="Masukan nomor akhir"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Nominal Nilai"}>
              <MainController
                {...getProps("AmountValue")}
                rules={{ required: "Nominal nilai wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    onChange={(e) => {
                      e.target.value = FormatNumber(e.target.value);
                      field.onChange(e);
                      const budget =
                        e.target.value * parseInt(watch("Distributed"));
                      setValue("Budget", budget, {
                        shouldValidate: true,
                      });
                    }}
                    value={FormatRupiah(field.value)}
                    placeholder="Masukan nominal nilai"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Jumlah Anggaran"}>
              <MainController
                {...getProps("Budget")}
                rules={{ required: "Jumlah anggaran wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    value={FormatRupiah(field.value)}
                    startAdornment={
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          backgroundColor: "#D4D5D5",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          borderTopLeftRadius: "4px",
                          borderBottomLeftRadius: "4px",
                          display: "flex",
                          textSlign: "center",
                          alignItems: "center",
                          padding: "9px 15px",
                          color: "#111",
                        }}
                      >
                        Rp.
                      </Box>
                    }
                    sx={{
                      input: {
                        marginLeft: "50px",
                      },
                    }}
                    placeholder="Masukan nominal anggaran"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Jumlah Distribusi"}>
              <MainController
                {...getProps("Distributed")}
                rules={{ required: "Jumlah distribusi wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    onChange={(e) => {
                      e.target.value = FormatNumber(e.target.value);
                      field.onChange(e);
                      const budget =
                        parseInt(watch("AmountValue")) * e.target.value;
                      setValue("Budget", budget, {
                        shouldValidate: true,
                      });
                    }}
                    value={FormatRupiah(field.value)}
                    placeholder="Total voucher yang akan didistribusikan"
                    disabled
                  />
                )}
              />
            </VerticalGrid>
          </>
        )}

        {ikon && (
          <>
            {Boolean(gambar) && (
              <VerticalGrid label={" "}>
                <Box
                  component={"img"}
                  src={gambar}
                  sx={{
                    maxWidth: "350px",
                    width: "100%",
                    borderRadius: "8px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </VerticalGrid>
            )}

            <VerticalGrid label={"Gambar kartu subsidi"}>
              <MainController
                {...getProps("Image1")}
                rules={{
                  validate: {
                    a: () => {
                      if (watch("Image2") != "") {
                        return true;
                      }

                      return false;
                    },
                    a: () => {
                      if (watch("Image2") == "" && watch("Image1") == "") {
                        return false;
                      }

                      return true;
                    },
                  },
                }}
                desc="Maks 5MB. Gambar dengan ukuran 379x255 piksel untuk hasil optimal."
                render={({ field }) => (
                  <FileInput
                    value={field.value}
                    showImage={watch("Image1") && !chooseTemplate}
                    onClose={() => field.onChange("")}
                    placeholder="Pilih gambar kartu subsidi"
                    onChange={(e) => {
                      const file = e.target.files[0];

                      const err = FileValidator(file, {
                        size: {
                          maxSize: "5mb",
                        },
                        type: ["image/png", "image/jpg", "image/jpeg"],
                      });

                      if (err) {
                        setError("Image1", {
                          type: "file",
                          message: err,
                        });
                        return;
                      }

                      field.onChange(file);
                      setChooseTemplate(false);
                      setSelectedCircle({});
                      setValue("Image2", "", {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />

              {!Boolean(gambar) && (
                <MainController
                  {...getProps("Image2")}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MainTemplate
                      onClick={(item) => {
                        setChooseTemplate(!chooseTemplate);
                        setValue("Image1", "", {
                          shouldValidate: true,
                        });
                        if (chooseTemplate) {
                          setValue("Image2", "", {
                            shouldValidate: true,
                          });
                        } else {
                          SvgToFile(field, item);
                        }
                      }}
                      chooseTemplate={chooseTemplate}
                      selected={selectedCircle}
                      list={circleList}
                      onChange={(item) => SvgToFile(field, item)}
                    />
                  )}
                />
              )}
            </VerticalGrid>
          </>
        )}
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  informasi: PropTypes.bool,
  ikon: PropTypes.bool,
  gambar: PropTypes.bool,
  data: PropTypes.any,
  setRes: PropTypes.any,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  informasi: false,
  ikon: false,
  gambar: false,
  data: null,
  setRes: null,
};
export default Dialog;
