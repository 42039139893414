import React, { useEffect, useMemo, useState } from "react";
import FileDownload from "assets/paper/fileDownload";
import FileUpload from "assets/paper/fileUpload";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FilterDialog from "components/Dialog/FilterDialog";
import SearchIcon from "assets/paper/SearchIcon";
import FilterIconv2 from "assets/paper/v2/FilterIconv2";
import DatePicker2 from "components/Picker/DatePicker2";
import Autocomplete from "components/AutoComplete/Autocomplete";

const MainCardHeader3 = ({
  filterName,
  changeFilter,
  params,
  filterData,
  addFilter,
  title,
  onChangeExport,
  onClickImport,
  filter,
  changeFilterName,
  deleteFilter,
  onClickFilter,
  downloadText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState({
    filter: false,
  });
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const flexInput = (theme) => ({
    display: "flex",
    gap: 1,
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      mr: 1,
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: "10px",
    },
  });

  const flexContainer = (theme) => ({
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: "5px",
    },
    justifyContent: "space-between",
    p: 2,
    alignItems: "center",
    // flexWrap: "wrap",
  });

  const DynamicInput = useMemo(() => {
    let find = filter.find((i) => i.value === filterName);

    switch (find?.type) {
      case "date":
        return (
          <DatePicker2
            onChange={(e) => {
              let value = e;
              dispatch(changeFilter(value));
            }}
            value={params?.filter?.[filterName] || params?.[filterName] || ""}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            sx={{
              maxWidth: {
                xs: "100%",
                lg: "290px",
              },
              minWidth: "290px",
            }}
            variant="v1"
            placeholder="Cari berdasarkan parameter"
          />
        );
      case "option":
        return (
          <Autocomplete
            variant="v1"
            options={find?.option || []}
            value={
              find?.option.find(
                (item) => item.value === params?.filter?.[filterName]
              ) || null
            }
            placeholder="Cari berdasarkan parameter"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                maxWidth: {
                  xs: "100%",
                  lg: "290px",
                },
                minWidth: "290px",
              },
            }}
            onChange={(_, nv) => {
              if (nv == null) {
                dispatch(changeFilter(""));
              }
              dispatch(changeFilter(nv.value));
            }}
          />
        );

      default:
        return (
          <OutlinedInput
            variant="v1"
            onChange={(e) => {
              if (!filterName) {
                return;
              }
              let value = e?.target?.value;
              dispatch(changeFilter(value));
            }}
            value={params?.filter?.[filterName] || params?.[filterName] || ""}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            sx={{
              maxWidth: {
                xs: "100%",
                lg: "290px",
              },
              minWidth: "290px",
            }}
            placeholder="Cari berdasarkan parameter"
          />
        );
    }
  }, [filterName, params]);

  return (
    <>
      <Box
        component="form"
        sx={flexContainer}
        onSubmit={(e) => {
          e.preventDefault();
          if (onClickFilter) {
            onClickFilter();
          } else {
            setOpenDialog({ ...open, filter: true });
          }
        }}
      >
        <Box sx={flexInput}>
          <Autocomplete
            variant="v1"
            options={filter}
            sx={{
              maxWidth: "290px",
              width: "100%",
            }}
            placeholder="Pilih Parameter"
            value={filter?.find((item) => item.value == filterName) || null}
            getOptionLabel={(option) => option.label || ""}
            onChange={(_, v) => {
              if (v === null) {
                dispatch(
                  changeFilterName({
                    name: "",
                  })
                );
                dispatch(deleteFilter());
              } else {
                if (filterName != "") {
                  dispatch(deleteFilter());
                }
                dispatch(
                  changeFilterName({
                    name: v.value,
                  })
                );
              }
            }}
          />

          {DynamicInput}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="secondary-outlined-icon"
            sx={{
              minWidth: "48px",
            }}
            type="submit"
          >
            <FilterIconv2 />
          </Button>

          <Box
            sx={{
              display: "flex",
              gap: "10px",
              height: "46px",
              marginLeft: "auto",
            }}
          >
            {Boolean(onClickImport) && (
              <Button
                variant="secondary-outlined"
                onClick={onClickImport}
                startIcon={<FileUpload />}
              >
                Unggah
              </Button>
            )}
            <Button
              onClick={handleClick}
              variant="secondary-outlined"
              startIcon={<FileDownload />}
              endIcon={
                <KeyboardArrowDown
                  sx={{ position: "absolute", right: 8, top: 13 }}
                />
              }
              sx={{
                "&:after": {
                  position: "absolute",
                  content: '""',
                  top: "0",
                  left: "105px",
                  backgroundColor: "#323232",
                  width: "1px",
                  height: "100%",
                },
                width: "145px",
                position: "relative",
                justifyContent: "start",
                fontWeight: "bold",
                "&:hover": {
                  "&:after": {
                    backgroundColor: "#fff !important",
                  },
                },
              }}
            >
              {downloadText}
            </Button>
          </Box>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            width: "140px",
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.09)",
            marginTop: "10px",
            ".MuiList-root": {
              padding: "0px",
            },
          },
        }}
      >
        <MenuItem
          onClick={() => onChangeExport("excel")}
          sx={{
            py: 1.5,
            borderBottom: "1px solid #f6f6f6",
          }}
        >
          <Typography variant="label">Excel</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onChangeExport("pdf")}
          sx={{
            py: 1.5,
            borderBottom: "1px solid #f6f6f6",
          }}
        >
          <Typography variant="label">PDF</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onChangeExport("csv")}
          sx={{
            py: 1.5,
            borderBottom: "1px solid #f6f6f6",
          }}
        >
          <Typography variant="label">CSV</Typography>
        </MenuItem>
      </Menu>
      <FilterDialog
        open={openDialog.filter}
        onClose={() => setOpenDialog({ ...open, filter: false })}
        filterData={filterData}
        addFilter={addFilter}
        title={title}
      />
    </>
  );
};

MainCardHeader3.propTypes = {
  addFilter: PropTypes.func,
  changeFilter: PropTypes.func,
  changeFilterName: PropTypes.func,
  deleteFilter: PropTypes.func,
  filter: PropTypes.array,
  filterData: PropTypes.array,
  filterName: PropTypes.string,
  params: PropTypes.shape({
    filter: PropTypes.any,
  }),
  title: PropTypes.string,
  onChangeExport: PropTypes.any,
  onClickImport: PropTypes.any,
  onClickFilter: PropTypes.func,
  FilterButton: PropTypes.bool,
  downloadText: PropTypes.string,
};

MainCardHeader3.defaultProps = {
  filter: [],
  changeFilter: () => {},
  changeFilterName: () => {},
  deleteFilter: () => {},
  addFilter: () => {},
  filterName: "",
  filterData: [],
  params: {},
  title: "-",
  onChangeExport: null,
  onClickImport: null,
  onClickFilter: null,
  FilterButton: true,
  downloadText: "Unduh",
};

export default MainCardHeader3;
