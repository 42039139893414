import React from "react";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import AlertProviders from "components/Alert/AlertProviders";
import "./style.css";
import "leaflet/dist/leaflet.css";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <RouterProvider router={router} />
        <AlertProviders />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
