import React from "react";

const VerifikasiIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5955 5.84455C15.5955 5.84455 15.5955 5.80169 15.5955 5.78332L14.9831 2.72162C14.928 2.43382 14.6769 2.23175 14.383 2.23175H3.36091C3.06699 2.23175 2.81593 2.43995 2.76082 2.72162L2.14848 5.78332C2.14848 5.78332 2.14848 5.82618 2.14848 5.84455C2.14848 5.86292 2.13623 5.88129 2.13623 5.90579V6.8243C2.13623 7.67545 2.63835 8.40413 3.36091 8.75317V15.0909C3.36091 15.4277 3.63646 15.7032 3.97325 15.7032H13.7707C14.1075 15.7032 14.383 15.4277 14.383 15.0909V8.75317C15.1056 8.41026 15.6077 7.67545 15.6077 6.8243V5.90579C15.6077 5.90579 15.5955 5.86905 15.5955 5.84455ZM9.4843 14.4785V11.4168H10.709V14.4785H9.4843ZM11.9337 14.4785V10.8045C11.9337 10.4677 11.6581 10.1922 11.3213 10.1922H8.87196C8.53518 10.1922 8.25963 10.4677 8.25963 10.8045V14.4785H4.58559V8.93687C5.06321 8.86951 5.49185 8.64907 5.81027 8.3184C6.20216 8.71643 6.74102 8.96748 7.34112 8.96748C7.94121 8.96748 8.48007 8.71643 8.87196 8.3184C9.26386 8.71643 9.80272 8.96748 10.4028 8.96748C11.0029 8.96748 11.5418 8.71643 11.9337 8.3184C12.2521 8.64907 12.6807 8.86951 13.1583 8.93687V14.4785H11.9337ZM13.4645 7.74281C12.9563 7.74281 12.546 7.33254 12.546 6.8243C12.546 6.48751 12.2704 6.21196 11.9337 6.21196C11.5969 6.21196 11.3213 6.48751 11.3213 6.8243C11.3213 7.33254 10.9111 7.74281 10.4028 7.74281C9.89457 7.74281 9.4843 7.33254 9.4843 6.8243C9.4843 6.48751 9.20875 6.21196 8.87196 6.21196C8.53518 6.21196 8.25963 6.48751 8.25963 6.8243C8.25963 7.33254 7.84936 7.74281 7.34112 7.74281C6.83287 7.74281 6.42261 7.33254 6.42261 6.8243C6.42261 6.48751 6.14705 6.21196 5.81027 6.21196C5.47348 6.21196 5.19793 6.48751 5.19793 6.8243C5.19793 7.33254 4.78766 7.74281 4.27942 7.74281C3.77118 7.74281 3.36091 7.33254 3.36091 6.8243V5.96702L3.86303 3.45643H13.8809L14.383 5.96702V6.8243C14.383 7.33254 13.9728 7.74281 13.4645 7.74281Z"
        fill="white"
      />
      <path
        d="M17.998 11.9362C17.3868 11.3346 16.4041 11.3407 15.8004 11.9498L12.6312 15.147L11.7406 14.316C11.1282 13.7446 10.1742 13.7575 9.57743 14.3452C8.94891 14.9642 8.95688 15.9804 9.59502 16.5895L12.1858 19.0623C12.4476 19.3122 12.8611 19.3071 13.1166 19.0508L18.0083 14.1448C18.6181 13.5332 18.6135 12.542 17.998 11.9362Z"
        fill="white"
        stroke="#CC1D15"
        strokeWidth="1.15275"
      />
    </svg>
  );
};

export default VerifikasiIcon;
