import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useRef, useState } from "react";
import { languangeList, statusList } from "./variables";
import { Controller, useForm } from "react-hook-form";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import MainStepper from "components/Stepper/MainStepper";
import StepView from "components/Stepper/StepView";
import {
  Alert,
  Box,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { addImage, createLokasi, getKategori } from "redux/sentraLokasi/action";
import SelectChips from "components/Status/SelectChips";
import Gallery from "components/FileDrop/Gallery";
import MainMap from "components/Map/MainMap";
import VerticalGrid from "components/Grid/VerticalGrid";
import Autocomplete from "components/AutoComplete/Autocomplete";

const steps = ["Informasi ", "Foto", "Map"];

const defaultValues = {
  data: {
    is_active: 1,
    latitude: 0,
    longitude: 0,
    m_lokasi_kategori_id: "",
    name: "",
    description: "",
    alamat: "",
    bahasa: "",
  },
  fasilitas: [],
  gallery: [],
};

const Dialog = ({ open, onClose, refresh, data, id, setRes }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [fasilitas, setFasilitas] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    watch,
    setError,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const uploadImage = useFetchData({
    action: addImage,
    snackbar: false,
  });

  const action = useFetchData({
    action: createLokasi,
    reset: resetForm,
    message: "Berhasil menambah lokasi baru",
    refresh: refresh,
    onSuccess: (data, gallery) => {
      const id = data.data.id;
      gallery.forEach((file) => {
        convertToBase64(file, async (base64) => {
          const body = {};
          if(base64 != null){
            body["picture"] = base64
          }
    
          const formData = new URLSearchParams(body)
          uploadImage.fetch(formData, id);
        })
      });
      onClose();
      resetForm();
    },
  });

  const convertToBase64 = async (file, handler = null) => {
    if(file == null){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const kategori = useFetchData({
    action: getKategori,
    snackbar: false,
  });

  useEffect(() => {
    if (data) {
      resetForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async ({ data, fasilitas, gallery }) => {
    if (activeStep != 2) {
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    let newData = { data, fasilitas };
    await action.fetch(newData, false, gallery);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        if (activeStep > 0) {
          return setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
        onClose();
        resetForm();
      }}
      title="Tambah Lokasi Baru"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="sm"
    >
      <MainStepper
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        withAction={false}
        handleNext={handleNext}
        handleBack={handleBack}
      >
        <Box
          sx={{
            mt: 3,
            p: 1,
          }}
        >
          <StepView view={0} activeStep={activeStep}>
            <HorizontalGrid label={"Status"} col={[4, 8]}>
              <MainController
                {...getProps("data.is_active")}
                rules={{
                  required: "Status wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    {...field}
                    options={statusList}
                    value={
                      statusList.find((item) => item.value == field.value) ||
                      null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(1);
                      }
                      field.onChange(nv.value);
                    }}
                  />
                )}
              />
             
            </HorizontalGrid>
            <HorizontalGrid label={"Kategori"} col={[4, 8]}>
              <MainController
                {...getProps("data.m_lokasi_kategori_id")}
                rules={{
                  required: "Kategori wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    {...field}
                    options={kategori.response?.list || []}
                    value={
                      kategori.response?.list?.find(
                        (item) => item.id === field.value
                      ) || null
                    }
                    onOpen={() => {
                      kategori.setResponse([]);
                      kategori.fetch({
                        filter: {
                          is_active: 1,
                        },
                      });
                    }}
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv.id);
                      }
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    placeholder="Pilih Kategori"
                    loading={kategori.loading}
                    getOptionLabel={(option) => option.name || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                )}
              />
           
            </HorizontalGrid>
            <HorizontalGrid label={"Nama Lokasi"} col={[4, 8]}>
              <MainController
                {...getProps("data.name")}
                rules={{
                  required: "Nama Lokasi wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan Nama Lokasi"
                  />
                )}
              />
             
            </HorizontalGrid>
            <HorizontalGrid label={"Bahasa"} col={[4, 8]}>
              <MainController
                {...getProps("data.bahasa")}
                rules={{ required: "Bahasa wajib diisi" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={languangeList}
                    value={
                      languangeList.find((item) => item.value == field.value) ||
                      null
                    }
                    placeholder="Pilih Bahasa"
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(1);
                      }
                      field.onChange(nv.value);
                    }}
                  />
                )}
              />
            </HorizontalGrid>
            <HorizontalGrid label={"Alamat"} col={[4, 8]}>
              <MainController
                {...getProps("data.alamat")}
                rules={{
                  required: "Alamat wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan Alamat Lokasi"
                  />
                )}
              />

            </HorizontalGrid>
            <HorizontalGrid label={"Deskripsi"} col={[4, 8]}>
              <MainController
                {...getProps("data.description")}
                rules={{
                  required: "Deskripsi wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan Deskripsi Lokasi"
                  />
                )}
              />
            </HorizontalGrid>
            <HorizontalGrid
              centerLabel={false}
              label={"Fasilitas"}
              col={[4, 8]}
            >
              <MainController
                {...getProps("fasilitas")}
                rules={{ required: "Fasilitas wajib diisi" }}
                render={({ field }) => (
                  <>
                    <OutlinedInput
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (fasilitas == "") {
                            return;
                          }
                          const go = field.value;
                          const value = [...go, { name: fasilitas }];
                          setFasilitas("");
                          field.onChange(value);
                        }
                      }}
                      placeholder="Masukan Fisilitas (Enter)"
                      value={fasilitas}
                      onChange={(e) => {
                        setFasilitas(e.target.value);
                      }}
                    />
                    <SelectChips
                      data={field.value}
                      height="30px"
                      wrap
                      width="auto"
                      tipe={1}
                      sx={{
                        mt: 1,
                        gap: 1,
                      }}
                      getLabel={(item) => item.name}
                      onDelete={(_, index) => {
                        const filtered = field.value.filter(
                          (_, i) => i != index
                        );
                        field.onChange(filtered);
                      }}
                    />
                  </>
                )}
              />
            </HorizontalGrid>
          </StepView>
          <StepView view={1} activeStep={activeStep}>
            <MainController
              rules={{ required: "gambar wajib diisi" }}
              controller={Controller}
              name="gallery"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              render={({ field }) => (
                <>
                  <Gallery
                    {...field}
                    setError={setError}
                    onClose={(i) =>
                      field.onChange(field.value.filter((_, ind) => ind != i))
                    }
                  />
                </>
              )}
            />
          </StepView>
          <StepView view={2} activeStep={activeStep}>
            <MainController
              {...getProps("data.longitude")}
              rules={{ required: "latitude longitude wajib diisi" }}
              render={({ field }) => (
                <>
                  <Alert
                    icon={false}
                    variant="warning"
                    sx={{
                      ".MuiAlert-message": {
                        color: "#F7B500",
                      },
                    }}
                  >
                    Pastikan lokasi yang anda tandai di peta sesuai dengan
                    alamat terinput
                  </Alert>
                  <MainMap
                    latitude={watch("data.latitude")}
                    longitude={field.value}
                    onChange={({ lat, lng }) => {
                      field.onChange(lng);
                      setValue("data.latitude", lat);
                    }}
                  />

                  <Grid container spacing={2} mt={1}>
                    <VerticalGrid label={"Latitude"}>
                      <OutlinedInput
                        placeholder="Input Latitude"
                        value={watch("data.latitude") || ""}
                        onChange={(e) =>
                          setValue("data.latitude", e.target.value)
                        }
                      />
                    </VerticalGrid>
                    <VerticalGrid label={"Longitude"}>
                      <OutlinedInput
                        placeholder="Input Logitude"
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    </VerticalGrid>
                  </Grid>
                </>
              )}
            />
          </StepView>
        </Box>
      </MainStepper>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
