import React from "react-perfect-scrollbar";
import PageNotFound from "pages/PageNotFound";
import PropTypes from "prop-types";

const RouteRoleProviders = ({ permission, children }) => {
  const permissionList = JSON.parse(localStorage.getItem("permission-ntt"));

  if (permission && permissionList) {
    let inc = false;
    if(permission.includes("|")){
      let listPerm = permission.split("|")
      for(let i = 0; i < listPerm.length; i++){
        inc ||= permissionList.includes(listPerm[i])
      }
    }else{
      inc ||= permissionList.includes(permission);
    }
    
    if (!inc) {
      return <PageNotFound type={1} />;
    }
  }

  return children;
};

RouteRoleProviders.propTypes = {
  permission: PropTypes.string,
  baseURL: PropTypes.string,
  children: PropTypes.element,
};

RouteRoleProviders.defaultProps = {
  permission: "",
  baseURL: null,
  children: "",
};
export default RouteRoleProviders;
