import { createAsyncThunk } from "@reduxjs/toolkit";
import { destroy, get, getParams, post, put } from "api";
import { KategoriLokasi, Lokasi } from "api/endpoints";

export const getListKategori = createAsyncThunk(
  "sentraLokasi/getKategoriLokasi",
  async (params) => {
    const response = await getParams(KategoriLokasi.list, params);
    return response.data.data;
  }
);

export const getKategori = async (params) => {
  const response = await getParams(KategoriLokasi.list, params);
  return response;
};

export const kategoriDetail = async (id) => {
  const withId = `${KategoriLokasi.detail}/${id}`;
  const response = await get(withId);

  return response;
};

export const newKategori = async (data) => {
  const response = await post(KategoriLokasi.action, data);

  return response;
};

export const updateKategori = async (data, id) => {
  const withId = `${KategoriLokasi.action}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const deleteKategori = async (id) => {
  const withId = `${KategoriLokasi.action}/${id}`;
  const response = await destroy(withId);
  return response;
};

export const getListLokasi = createAsyncThunk(
  "sentraLokasi/getLokasi",
  async (params) => {
    const response = await getParams(Lokasi.list, params);
    return response.data.data;
  }
);

export const getLokasi = async (params) => {
  const response = await getParams(Lokasi.list, params);
  return response;
};

export const createLokasi = async (params) => {
  const response = await post(Lokasi.list, params);
  return response;
};

export const deleteLokasi = async (id) => {
  const withId = `${Lokasi.list}/${id}`;

  const response = await destroy(withId);
  return response;
};

export const addImage = async (data, id) => {
  const withId = `${Lokasi.addImage}/${id}`;

  const response = await post(withId, data);
  return response;
};

export const getDetailLokasi = async (id) => {
  const withId = `${Lokasi.detail}/${id}`;
  const response = await get(withId);
  return response;
};

export const getLokasiImage = async (id) => {
  const withId = `${Lokasi.getImage}/${id}`;
  const response = await get(withId);
  return response;
};
