import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getListHargaProduk } from "./action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
    type: 1,
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const hargaProdukPPOBSlice = createSlice({
  name: "hargaProdukPPOB",
  initialState,
  reducers: {
    ...pagination,
    changeType: (state, action) => {
      state.params = {
        limit: 10,
        offset: 0,
        type: action.payload,
      };
    },
  },
  extraReducers: {
    [getListHargaProduk.pending]: (state) => {
      state.loading = true;
    },
    [getListHargaProduk.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListHargaProduk.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
  changeType,
} = hargaProdukPPOBSlice.actions;

export default hargaProdukPPOBSlice.reducer;
