import React from "react";

const LaporanAdjudicatorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5002 7.5C15.5002 10.5376 13.0378 13 10.0002 13C6.96268 13 4.50025 10.5376 4.50025 7.5C4.50025 4.46243 6.96268 2 10.0002 2C13.0378 2 15.5002 4.46243 15.5002 7.5ZM13.5002 7.5C13.5002 9.433 11.9332 11 10.0002 11C8.06725 11 6.50025 9.433 6.50025 7.5C6.50025 5.567 8.06725 4 10.0002 4C11.9332 4 13.5002 5.567 13.5002 7.5ZM22.0002 12C22.0002 13.6569 20.6571 15 19.0002 15C17.3434 15 16.0002 13.6569 16.0002 12C16.0002 10.3431 17.3434 9 19.0002 9C20.6571 9 22.0002 10.3431 22.0002 12ZM21.0002 12C21.0002 13.1046 20.1048 14 19.0002 14C17.8957 14 17.0002 13.1046 17.0002 12C17.0002 10.8954 17.8957 10 19.0002 10C20.1048 10 21.0002 10.8954 21.0002 12ZM18.5095 12.9272C18.5456 12.972 18.5967 12.9983 18.6506 12.9999L18.6558 13C18.7079 13 18.7581 12.9769 18.795 12.9356L20.1925 11.3752C20.2695 11.2894 20.2341 11.0995 20.1571 11.0136C20.0804 10.9278 19.8865 10.896 19.8098 10.9818L18.6634 12.2243C18.6634 12.2243 18.2547 11.7466 18.182 11.6565C18.1094 11.5663 17.94 11.6028 17.8592 11.684C17.7783 11.7651 17.7281 11.9567 17.8007 12.047L18.5095 12.9272ZM7.70384 14C4.12777 14 1.34562 17.1084 1.74053 20.6626L2.00025 23H4.00025L3.65249 20.5657C3.30824 18.1559 5.17809 16 7.61228 16H12.3882C14.8224 16 16.6923 18.156 16.348 20.5657L16.0002 23H18.0002L18.26 20.6626C18.6549 17.1084 15.8727 14 12.2967 14H7.70384Z"
        fill="black"
      />
      <mask
        id="mask0_6069_17632"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="22"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5002 7.5C15.5002 10.5376 13.0378 13 10.0002 13C6.96268 13 4.50025 10.5376 4.50025 7.5C4.50025 4.46243 6.96268 2 10.0002 2C13.0378 2 15.5002 4.46243 15.5002 7.5ZM13.5002 7.5C13.5002 9.433 11.9332 11 10.0002 11C8.06725 11 6.50025 9.433 6.50025 7.5C6.50025 5.567 8.06725 4 10.0002 4C11.9332 4 13.5002 5.567 13.5002 7.5ZM22.0002 12C22.0002 13.6569 20.6571 15 19.0002 15C17.3434 15 16.0002 13.6569 16.0002 12C16.0002 10.3431 17.3434 9 19.0002 9C20.6571 9 22.0002 10.3431 22.0002 12ZM21.0002 12C21.0002 13.1046 20.1048 14 19.0002 14C17.8957 14 17.0002 13.1046 17.0002 12C17.0002 10.8954 17.8957 10 19.0002 10C20.1048 10 21.0002 10.8954 21.0002 12ZM18.5095 12.9272C18.5456 12.972 18.5967 12.9983 18.6506 12.9999L18.6558 13C18.7079 13 18.7581 12.9769 18.795 12.9356L20.1925 11.3752C20.2695 11.2894 20.2341 11.0995 20.1571 11.0136C20.0804 10.9278 19.8865 10.896 19.8098 10.9818L18.6634 12.2243C18.6634 12.2243 18.2547 11.7466 18.182 11.6565C18.1094 11.5663 17.94 11.6028 17.8592 11.684C17.7783 11.7651 17.7281 11.9567 17.8007 12.047L18.5095 12.9272ZM7.70384 14C4.12777 14 1.34562 17.1084 1.74053 20.6626L2.00025 23H4.00025L3.65249 20.5657C3.30824 18.1559 5.17809 16 7.61228 16H12.3882C14.8224 16 16.6923 18.156 16.348 20.5657L16.0002 23H18.0002L18.26 20.6626C18.6549 17.1084 15.8727 14 12.2967 14H7.70384Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6069_17632)">
        <rect x="-2" width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default LaporanAdjudicatorIcon;
