const DynamicLabel = (type, name, options) => {
  switch (type) {
    case "autocomplete":
      return options.find((item) => item.id == String(name).trim())?.nama || "";
    default:
      return name;
  }
};

export default DynamicLabel;
