import React from "react";
import Providers from "components/RouteRoleProviders";
import KategoriLokasi from "pages/MasterData/SentraLokasi/Kategori";
import Lokasi from "pages/MasterData/SentraLokasi/Lokasi";
import Detail from "pages/MasterData/SentraLokasi/Kategori/Detail";
import DetailLokasi from "pages/MasterData/SentraLokasi/Lokasi/Detail";
import ResponsiveView from "components/ResponsiveView";
import KategoriLokasiMobile from "pages/MasterData/SentraLokasi/Kategori/Mobile";
import LokasiMobile from "pages/MasterData/SentraLokasi/Lokasi/Mobile";

const sentraLokasiRoutes = [
  {
    path: "/master-data/sentra-lokasi/kategori",
    element: (
      <Providers permission="Web:Voucher:All">
        <ResponsiveView
          onDesktop={KategoriLokasi}
          onMobile={KategoriLokasiMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/master-data/sentra-lokasi/kategori/:id",
    element: (
      <Providers permission="Web:Voucher:All">
        <Detail />
      </Providers>
    ),
  },
  {
    path: "/master-data/sentra-lokasi/lokasi",
    element: (
      <Providers permission="Web:Voucher:All">
        <ResponsiveView onDesktop={Lokasi} onMobile={LokasiMobile} />
      </Providers>
    ),
  },
  {
    path: "/master-data/sentra-lokasi/lokasi/:id",
    element: (
      <Providers permission="Web:Voucher:All">
        <DetailLokasi />
      </Providers>
    ),
  },
];

export default sentraLokasiRoutes;
