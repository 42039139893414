import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  OutlinedInput,
  Paper,
  Popover,
  Popper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Page from "components/Page";
import {
  generateId,
  generateSection,
  generateValue,
  generateValueLeft,
  generateValueRight,
  initialData,
  listItems,
  properties,
} from "./variable";
import Builder from "./components/Builder";
import TabPanel from "components/Tab/TabPanel";
import PageSection from "./components/PageSection";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomDragLayer from "./components/CustomDragLayer";
import Section from "./components/Section";
import {
  SingleLine,
  MultiLine,
  DateInput,
  TimeInput,
  Number,
  Email,
  Password,
  Media,
  Select,
  Foto,
  MultiSelect,
} from "./components/Input";
import Properties from "./components/Properties";
import { useParams } from "react-router-dom";
import SimpanDialog from "./Dialog/SimpanDialog";
import { getFormDocument } from "redux/CMS/action";
import useFetchData from "hook/useFetchData";

const renderInput = (v) => {
  switch (v.type) {
    case "single-line":
      return <SingleLine item={v} />;
    case "multiple-line":
      return <MultiLine item={v} />;
    case "date":
      return <DateInput item={v} />;
    case "time":
      return <TimeInput item={v} />;
    case "number":
      return <Number item={v} />;
    case "email":
      return <Email item={v} />;
    case "password":
      return <Password item={v} />;
    case "media":
      return <Media item={v} />;
    case "select":
      return <Select item={v} />;
    case "multi-select":
      return <MultiSelect item={v} />;
    case "media-capture":
      return <Foto item={v} />;
    default:
      return "";
  }
};

const BuatFormulir = () => {
  const [value, setValue] = useState(0);
  const [section, setSection] = useState("section-1");
  const [data, setData] = useState(initialData);
  const [isColumn1, setIsColumn1] = useState(true);
  const [currentForm, setCurrentForm] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [currentLength, setCurrentLength] = useState(null);
  const [id, setId] = useState(false);
  const { type } = useParams();

  const [dialog, setDialog] = useState({
    simpan: false,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getDetail = useFetchData({
    action: getFormDocument,
    snackbar: false,
    onSuccess: (data) => {
      setData(data.data_form);
    },
  });

  useEffect(() => {
    if (type) {
      let isnum = /^\d+$/.test(type);
      if (!isnum) {
        const newState = {
          is_multi: type === "multi-page" ? 1 : 0,
          form_title: "",
          forms: {},
          detail_sections: {
            "section-1": {
              id: "section-1",
              title: "",
              description: "",
              forms: [],
            },
          },
          sections: ["section-1"],
        };
        setData(newState);
        // setOnEdit({
        //   is_edit: false,
        //   id: null,
        // });
        // }
      } else {
        setId(type)
        getDetail.fetch(type);
      }
    }
  }, [type]);

  const handleChange = (_, newValue) => {
    if (newValue === 0) {
      setIsColumn1(true);
    } else {
      setIsColumn1(false);
    }
    setValue(newValue);
  };

  const handleDelete = (e, index) => {
    if (index === 0) return;
    e.stopPropagation();
    const copySections = [...data.sections];
    let deletedSection = data.sections[index];
    copySections.splice(index, 1);
    delete data.detail_sections[deletedSection];
    setSection(`section-1`);
    setData({
      ...data,
      sections: [...copySections],
    });
  };

  const setSectionData = (payload, id) => {
    setData((prev) => ({
      ...prev,
      detail_sections: {
        ...prev.detail_sections,
        [id]: {
          ...prev.detail_sections[id],
          ...payload,
        },
      },
    }));
  };

  const handleAdd = () => {
    const copySections = [...data.sections];
    const copyDetail = { ...data.detail_sections };

    const newSection = generateSection(data);

    copySections.push(newSection);

    copyDetail[newSection] = {
      id: newSection,
      title: "",
      description: "",
      forms: [],
    };

    setData({
      ...data,
      detail_sections: {
        ...copyDetail,
      },
      sections: [...copySections],
    });
  };

  const handleDrop = (section, item, index = null, isColumn = false) => {
    const copyForms = { ...data.forms };
    const copyDetail = { ...data.detail_sections };
    const copy = { ...copyDetail[section] };
    const forms = [...copy.forms];

    const newId = generateId(copyForms);
    copyForms[newId] = generateValue(newId, item);

    if (!item.copy) {
      if (!isColumn) {
        forms.push(newId);
      } else {
        forms.splice(index, 0, newId);
      }

      setData({
        ...data,
        forms: {
          ...copyForms,
        },
        detail_sections: {
          ...copyDetail,
          [section]: {
            ...copy,
            forms: [...forms],
          },
        },
      });
    } else {
      let form = copyForms[newId];
      form = {
        ...item,
        id: newId,
      };

      delete form.copy;

      copyForms[newId] = form;
      copyForms[newId].content = copyForms[newId].content.map((item, i) => ({
        ...item,
        id: newId + "-" + (i + 1),
      }));

      if (!isColumn) {
        copyDetail[section].forms.push(newId);
      } else {
        copyDetail[section].forms.splice(index, 0, newId);
      }

      setData({
        ...data,
        forms: {
          ...copyForms,
        },
        detail_sections: {
          ...copyDetail,
        },
      });
    }
  };

  const handleDropLeft = (section, item, index, isColumn = false) => {
    const copyForms = { ...data.forms };
    const copyDetail = { ...data.detail_sections };

    const newId = generateId(copyForms);
    copyForms[newId] = generateValueLeft(newId, item);

    if (!isColumn) {
      copyDetail[section].forms.push(newId);
    } else {
      copyDetail[section].forms.splice(index, 0, newId);
    }

    setData({
      ...data,
      forms: {
        ...copyForms,
      },
      detail_sections: {
        ...copyDetail,
      },
    });
  };

  const handleDropRight = (section, item, index, isColumn = false) => {
    const copyForms = { ...data.forms };
    const copyDetail = { ...data.detail_sections };

    const newId = generateId(copyForms);
    copyForms[newId] = generateValueRight(newId, item);

    if (!isColumn) {
      copyDetail[section].forms.push(newId);
    } else {
      copyDetail[section].forms.splice(index, 0, newId);
    }

    setData({
      ...data,
      forms: {
        ...copyForms,
      },
      detail_sections: {
        ...copyDetail,
      },
    });
  };

  const handleSortable = (section, dragIndex, hoverIndex, item) => {
    const copyDetail = { ...data.detail_sections };

    const form = copyDetail[section].forms;

    const [removedForm] = form.splice(dragIndex, 1);
    form.splice(hoverIndex, 0, removedForm);

    setData({
      ...data,
      detail_sections: {
        ...copyDetail,
      },
    });
  };

  const handleDeleteInput = (section, id, length) => {
    const copyForms = { ...data.forms };
    const copyDetail = { ...data.detail_sections };

    if (length === 1) {
      const splitId = id?.split("-")[0] + "-" + id.split("-")[1];
      const forms = copyDetail[section].forms;
      const index = forms.indexOf(splitId);
      forms.splice(index, 1);
      delete copyForms[splitId];
      setData({
        ...data,
        forms: {
          ...copyForms,
        },
      });
    } else if (length === 2) {
      const splitId = id?.split("-")[0] + "-" + id.split("-")[1];
      if (onlyOneContentLeft(copyForms[splitId].content)) {
        const forms = copyDetail[section].forms;
        const index = forms.indexOf(splitId);
        forms.splice(index, 1);
        delete copyForms[splitId];
      } else {
        const position = id?.split("-")[2];
        copyForms[splitId].content.splice(position - 1, 1, {
          id: splitId + "-" + position,
        });
      }

      setData({
        ...data,
        forms: {
          ...copyForms,
        },
        detail_sections: {
          ...copyDetail,
        },
      });
    }
  };

  const onlyOneContentLeft = (v) => {
    let isOne = 0;
    for (let x of v.keys()) {
      if (v[x].type) {
        isOne++;
      }
    }
    if (isOne === 1) {
      return true;
    }
    return false;
  };

  const handleClickItem = (e, v, section, length) => {
    setCurrentForm(v.id);
    setAnchorEl(e.currentTarget);
    setCurrentSection(section);
    setCurrentLength(length);
  };

  const handleClickAway = () => {
    if (currentForm) {
      setAnchorEl(null);
      setCurrentForm(null);
    }
  };

  const handleUpdateEmptyColumn = (section, dropItem, itemId, v) => {
    const copyForms = { ...data.forms };

    const i = copyForms[itemId]?.content?.findIndex((i) => i.id === v.id);
    copyForms[itemId].content[i] = {
      ...copyForms[itemId].content[i],
      ...properties?.[dropItem.type],
      ...dropItem,
    };

    setData({
      ...data,
      forms: {
        ...copyForms,
      },
    });
  };

  const detail = data?.detail_sections?.[section] || [];

  const forms = detail?.forms?.map((form) => data?.forms[form]);

  const getId = (id) => {
    const splitId = id?.split("-")[0] + "-" + id?.split("-")[1];
    return splitId;
  };

  const handleChangeType = (item, currentForm) => {
    const copyForms = { ...data.forms };

    const id = getId(currentForm);
    const form = copyForms[id];
    const i = form.content.findIndex((i) => i.id === currentForm);

    let newItems = listItems.find((i) => i.type === item?.type);

    let allowedProperties = [];
    let allAlowed = {
      ...properties?.[item?.type],
      ...newItems,
    };

    form.content[i] = {
      ...form.content[i],
      ...properties?.[item?.type],
      ...newItems,
      id: form.content[i].id,
    };

    for (const key in allAlowed) {
      allowedProperties.push(key);
    }

    for (const key in form.content[i]) {
      if (!allowedProperties.includes(key)) {
        delete form.content[i][key];
      }
    }

    setData({
      ...data,
      forms: {
        ...copyForms,
      },
    });
  };

  const getContentKey = (key) => {
    const id = getId(currentForm);
    if (currentForm !== null) {
      const keys = data?.forms[id]?.content?.find(
        (item) => item.id === currentForm
      )?.[key];
      return keys;
    }
    return null;
  };

  const handleChangeProperties = (event, fid, key) => {
    const copyForms = { ...data.forms };
    const id = getId(fid);
    const form = copyForms[id];
    const i = form.content.findIndex((i) => i.id === fid);
    form.content[i].property = {
      ...form.content[i].property,
      [key]: {
        ...form.content[i].property[key],
        value: event.target.value,
      },
    };
    setData({
      ...data,
      forms: copyForms,
    });
  };

  const handleChangeContent = (val, fid, key) => {
    const copyForms = { ...data.forms };
    const id = getId(fid);
    const form = copyForms[id];
    const i = form.content.findIndex((i) => i.id === fid);
    form.content[i][key] = val;
    setData({
      ...data,
      forms: copyForms,
    });
  };

  const handleChangeContentObject = (objectVal, fid, parentKey) => {
    const copyForms = { ...data.forms };
    const id = getId(fid);
    const form = copyForms[id];
    const i = form.content.findIndex((i) => i.id === fid);
    form.content[i][parentKey] = {
      ...form?.content[i]?.[parentKey],
      ...objectVal,
    };
    setData({
      ...data,
      forms: copyForms,
    });
  };

  const hideProperties = () => {
    setCurrentForm(null);
  };

  const handleChangePropertiesPanduan = (arr, fid, key) => {
    const copyForms = { ...data.forms };
    const id = getId(fid);
    const form = copyForms[id];
    const i = form.content.findIndex((i) => i.id === fid);
    form.content[i].property = {
      ...form.content[i].property,
      [key]: {
        ...form.content[i].property[key],
        value: arr,
      },
    };
    setData({
      ...data,
      forms: copyForms,
    });
  };

  const handleSortableFiturOpsi = (item, dragIndex, hoverIndex) => {
    const nS = { ...data };
    const id = getId(currentForm);
    const form = nS.forms[id];
    const i = form.content.findIndex((i) => i.id === currentForm);
    const content = form.content[i];
    const opsi = content.option;
    const newOpsi = arrayMove(opsi, dragIndex, hoverIndex);
    content.option = newOpsi;
    setData(nS);
  };

  const arrayMove = (arr, fromIndex, toIndex) => {
    const copy = [...arr];
    const element = copy[fromIndex];
    copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, element);
    return copy;
  };

  const handleChangeTitleDocument = (e) => {
    setData({
      ...data,
      form_title: e.target.value,
    });
  };

  return (
    <>
      <Page title="Buat Formulir" />

      <DndProvider backend={HTML5Backend}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {data?.is_multi ? (
              <PageSection
                data={data}
                section={section}
                setSection={setSection}
                handleDelete={handleDelete}
                handleAdd={handleAdd}
              />
            ) : null}
            <Section
              section={section}
              detail={detail}
              forms={forms}
              setSectionData={setSectionData}
              isColumn1={isColumn1}
              handleDrop={handleDrop}
              handleDropLeft={handleDropLeft}
              handleDropRight={handleDropRight}
              renderInput={renderInput}
              handleSortable={handleSortable}
              currentForm={currentForm}
              handleDeleteInput={handleDeleteInput}
              handleClickItem={handleClickItem}
              handleChange={handleChange}
              handleClickAway={handleClickAway}
              handleUpdateEmptyColumn={handleUpdateEmptyColumn}
              setCurrentForm={setCurrentForm}
            />
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                borderRadius: 2,
                mt: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                position: "sticky",
                top: 0,
              }}
            >
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="cardTitle">Tambah Aset</Typography>
                <Typography variant="text2" color="#4C494A">
                  Geser dan taruh bidang
                </Typography>
              </Box>

              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                sx={{
                  padding: "0px",
                }}
              >
                <Tab
                  sx={{
                    width: "50%",
                  }}
                  label="1 Kolom"
                />
                <Tab
                  sx={{
                    width: "50%",
                  }}
                  label="2 Kolom"
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  sx={{ height: "250px", overflow: "auto" }}
                >
                  {listItems.map((item, i) => (
                    <Builder {...item} key={i} />
                  ))}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  sx={{ height: "250px", overflow: "auto" }}
                >
                  {listItems.map((item, i) => (
                    <Builder {...item} key={i} />
                  ))}
                </Box>
              </TabPanel>

              <Button
                variant="primary"
                onClick={() =>
                  setDialog({
                    ...dialog,
                    simpan: true,
                  })
                }
              >
                Simpan
              </Button>
            </Paper>
          </Grid>
        </Grid>

        <CustomDragLayer renderInput={renderInput} />

        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="right-end"
          disablePortal={true}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <Paper
                sx={{
                  width: "300px",
                }}
              >
                <Properties
                  data={data}
                  currentForm={currentForm}
                  listItems={listItems}
                  getId={getId}
                  handleChangeType={handleChangeType}
                  handleClose={handleClose}
                  currentSection={currentSection}
                  currentLength={currentLength}
                  getContentKey={getContentKey}
                  handleChangeProperties={handleChangeProperties}
                  handleChangeContent={handleChangeContent}
                  handleChangeContentObject={handleChangeContentObject}
                  hideProperties={hideProperties}
                  handleDeleteInput={handleDeleteInput}
                  handleChangePropertiesPanduan={handleChangePropertiesPanduan}
                  handleSortableFiturOpsi={handleSortableFiturOpsi}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </DndProvider>

      <SimpanDialog
        open={dialog.simpan}
        onClose={() =>
          setDialog({
            ...dialog,
            simpan: false,
          })
        }
        data={data}
        id={id}
        value={data.form_title}
        handleChangeTitleDocument={handleChangeTitleDocument}
      />
    </>
  );
};

export default BuatFormulir;
