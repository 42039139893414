import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React from "react";

const AccordionMobileDetail = ({ headers, data }) => {
  return (
    <Box display="flex" flexDirection="column" gap={3} p={2}>
      {headers.map((item, i) => (
        <Box key={i} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="text2">{item.name}</Typography>
          <Typography
            variant="text2"
            sx={{
              color: "#7F7F80",
            }}
          >
            {item.key
              ? data?.[item.key] || "-"
              : item.custom
              ? item?.custom(data)
              : "-"}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

AccordionMobileDetail.propTypes = {
  data: PropTypes.any,
  headers: PropTypes.array,
};

AccordionMobileDetail.defaultProps = {
  data: {},
  headers: [],
};

export default AccordionMobileDetail;
