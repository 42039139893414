import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import LaporanTransaksiStatus from "components/Status/LaporanTransaksiStatus";
import MainTable from "components/Table/MainTable";
import ExportHelper from "helper/ExportHelper";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/laporanTransaksi/TransaksiPBB/slice";
import {
    getDetailTransaksi,
  getListTransaksiPBB,
  getTransaksiPBB,
} from "redux/laporanTransaksi/action";
import DetailTransaksi from "../Dialog";

const LaporanTransaksiPBB = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.transaksiPBB);

  const [open, setOpen] = useState({
    dialog: false,
  });

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListTransaksiPBB(params));

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "Tgl. Transaksi",
      custom: (item) => {
        return moment(item.trxDate).format("DD / MM / YYYY");
      },
      width: 25,
    },
    {
      name: "No. Transaksi",
      key: "trxReceipt",
      width: 25,
    },
    {
      name: "Aktivitas",
      key: "trxActivity",
      width: 25,
    },
    {
      name: "Nama ",
      key: "trxFullName",
      width: 25,
    },
    {
      name: "Status",
      width: 25,
      custom: (item) => {
        return (
          <LaporanTransaksiStatus value={item.trxStatus} minwidth="100px" />
        );
      },
    },
  ];
  const filter = [
    { label: "No. Transaksi", value: "trxReceipt" },
    { label: "Aktivitas", value: "trxActivity" },
    { label: "Nama", value: "trxFullName" },
  ];

  const filterData = [
    {
      label: "Tanggal Status",
      key: "trxDate",
      placeholder: "Pilih Tanggal",
      type: "datepicker2",
    },
  ];

  const exportData = useFetchData({
    action: getTransaksiPBB,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "laporan-transaksi-pbb",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const detail = useFetchData({
    action: getDetailTransaksi,
    snackbar: false,
    onSuccess: (res) => {
      console.log(res);
      setOpen({
        ...open,
        dialog: true,
      });
    },
  });

  return (
    <>
      <Page title="Laporan Transaksi" page={["Laporan Transaksi"]} menu="PBB" />

      <MainCard
        sx={{
          mt: 2,
          borderRadius: "8px",
        }}
        title="Daftar Transaksi PBB"
        addButton={false}
      >
        <MainCardHeader
          filter={filter}
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          filterData={filterData}
          addFilter={addFilter}
          title="Filter Laporan Transaksi PBB"
          onChangeExport={(type) => handleExport(type)}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="trxReceipt"
          detail={[
            {
              type: "button",
              tooltip: "Lihat Detail Transaksi",
              variant: "icon:eye",
              onClick: (_, item) => {
                detail.fetch(item.trxId);
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <DetailTransaksi
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        data={detail?.response?.transaction}
        order={detail?.response?.order}
        loading={detail?.response?.loading}
      />
    </>
  );
};

export default LaporanTransaksiPBB;
