import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";
import { Box, Typography, Divider } from "@mui/material";
import React from "react";

const DrawerTitle = ({ title, toggle }) => {
  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="label2">{title}</Typography>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggle}
          />
        </Box>
      </Box>
      <Divider sx={{ borderColor: "#EAEAEB" }} />
    </>
  );
};

DrawerTitle.propTypes = {
  title: PropTypes.string,
  toggle: PropTypes.any,
};

DrawerTitle.defaultProps = {
  title: "",
  toggle: () => {},
};

export default DrawerTitle;
