import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import FormError from "components/Alert";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";
import { getIpAddress, initialization, LoginAction } from "redux/auth/actions";
import { Link, useSearchParams } from "react-router-dom";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import moment from "moment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublicRoute from "components/PublicRoute";
import InisaIlu from "assets/login/new-ilustrasi.png";
import useFetchData from "hook/useFetchData";
import AuthLogin from "helper/AuthLogin";

const Form = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      Email: "",
      Password: "",
      Source: "web",
    },
  });

  const Appid = useRef(null);
  const [sparams] = useSearchParams();

  const [retry, setRetry] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    (async () => {
      const data = new FormData();
      data.append("AppName", "qoin-apps");
      const response = await initialization(data);
      if (response) {
        Appid.current = response.data.data.AppID;
      } else {
        Appid.current = "hello world";
      }
    })();
    getIpAddress();
  }, []);

  useEffect(() => {
    if (retry > 0) {
      const intervalId = setInterval(() => {
        setRetry(retry - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      action.setResponse({
        ...action.response,
        message: "",
      });
    }
  }, [retry]);

  const action = useFetchData({
    action: LoginAction,
    message: "Login Berhasil",
    snackbarError: false,
    onSuccess: (io) => {
      AuthLogin(io.ClientId, io.Token, io);
      if (sparams.get("navigateTo")) {
        setTimeout(() => {
          return window.location.replace(sparams.get("navigateTo"));
        }, 1500);
      } else {
        setTimeout(() => {
          return window.location.replace("/");
        }, 1500);
      }
    },
    onError: (data, { message, code }) => {
      if (message === "Too Many Request" || code == 429) {
        setRetry(data.RetryAfter);
      }
    },
  });

  const onSubmit = (data) => {
    action.setResponse({
      ...action.response,
      message: "",
    });
    const formData = new FormData();

    formData.append("Email", data.Email);
    formData.append("Password", data.Password);
    formData.append("Source", "web");

    action.fetch(formData, Appid.current);
  };

  const PasswordIcon = useMemo(
    () => (showPassword ? VisibilityIcon : VisibilityOffIcon),
    [showPassword]
  );

  return (
    <PublicRoute ilustration={InisaIlu} title="Sign In">
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          marginTop: "30px",
        }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <FormError
          message={`${action?.response?.message} ${
            retry > 0 ? `| ${moment.utc(retry * 1000).format("mm:ss")}` : ""
          }`}
          open={action?.response?.message ? 0 : 1}
          onClose={() => {
            if (retry) {
              return;
            }
            action.setResponse({
              ...action.response,
              message: "",
            });
          }}
        />
        <Grid container spacing={3}>
          <VerticalGrid label="Username" md={12}>
            <MainController
              controller={Controller}
              name="Email"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Username wajib diisi",
                maxLength: {
                  message: "Username tidak boleh melebihi 255 karakter",
                  value: 255,
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Format Email salah",
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <OutlinedInput
                  {...field}
                  variant="v1"
                  error={invalid}
                  placeholder="Input your username"
                />
              )}
            />
          </VerticalGrid>
          <VerticalGrid label="Password" md={12}>
            <MainController
              controller={Controller}
              name="Password"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Password wajib diisi",
                minLength: {
                  message: "Password Harus Minimal 6 Karakter.",
                  value: 6,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <OutlinedInput
                  variant="v1"
                  {...field}
                  type={showPassword ? "text" : "password"}
                  error={invalid}
                  placeholder="Input your password"
                  endAdornment={
                    <IconButton
                      sx={{
                        color: "#4C4D4F",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <PasswordIcon />
                    </IconButton>
                  }
                />
              )}
            />
          </VerticalGrid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Typography
            variant="textCommon"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Checkbox
              sx={{
                p: 0,
                color: "#4C4D4F",
              }}
            />{" "}
            Remember Me
          </Typography>
          <Link
            to="/auth/forgot-password"
            style={{ marginLeft: "auto", textDecoration: "none" }}
          >
            <Typography variant="textCommon">Forgot Password?</Typography>
          </Link>
        </Box>
        <Button
          variant="primary"
          disabled={Boolean(retry) || action.loading}
          type="submit"
          sx={{ padding: "13px", borderRadius: "8px" }}
        >
          SIGN IN
        </Button>
      </Box>
    </PublicRoute>
  );
};

export default Form;
