import React from "react";

const Number = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71774 17.1517H3.73387V8.98387H1.75V7H5.71774V17.1517ZM10.3468 15.1678V12.7371H12.9919C13.7194 12.7371 14.3145 12.142 14.3145 11.4146V8.32258C14.3145 7.59516 13.7194 7 12.9919 7H8.3629V8.98387H12.3306V11.4146H9.68548C8.95806 11.4146 8.3629 12.0097 8.3629 12.7371V17.1517H14.3145V15.1678H10.3468ZM22.25 8.32258V15.8291C22.25 16.5565 21.6548 17.1517 20.9274 17.1517H16.2984V15.1678H20.2661V12.7371H17.621V11.4146H20.2661V8.98387H16.2984V7H20.9274C21.6548 7 22.25 7.59516 22.25 8.32258Z"
        fill="black"
      />
      <mask
        id="mask0_6780_21051"
        maskUnits="userSpaceOnUse"
        x="1"
        y="7"
        width="22"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.71774 17.1517H3.73387V8.98387H1.75V7H5.71774V17.1517ZM10.3468 15.1678V12.7371H12.9919C13.7194 12.7371 14.3145 12.142 14.3145 11.4146V8.32258C14.3145 7.59516 13.7194 7 12.9919 7H8.3629V8.98387H12.3306V11.4146H9.68548C8.95806 11.4146 8.3629 12.0097 8.3629 12.7371V17.1517H14.3145V15.1678H10.3468ZM22.25 8.32258V15.8291C22.25 16.5565 21.6548 17.1517 20.9274 17.1517H16.2984V15.1678H20.2661V12.7371H17.621V11.4146H20.2661V8.98387H16.2984V7H20.9274C21.6548 7 22.25 7.59516 22.25 8.32258Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_21051)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default Number;
