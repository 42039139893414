import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  0: {
    label: "Dalam Peninjauan",
    background: "#EBECED",
    color: "#4C4D4F",
  },
  1: {
    label: "Diverifikasi",
    background: "#FEF8E8",
    color: "#F5B419",
  },
  2: {
    label: "Disetujui",
    background: "#e7f4ed",
    color: "#129350",
  },
};

const ManagementMerchantStatus = ({ value, ...props }) => {
  let selected = color[value];

  return <StatusChip {...props} {...selected} />;
};

ManagementMerchantStatus.defaultProps = {
  value: 0,
};

ManagementMerchantStatus.propTypes = {
  value: PropTypes.any,
};

export default ManagementMerchantStatus;
