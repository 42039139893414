const { setCookie } = require("./cokkie");

const AuthLogin = (clientId, token, profile) => {
  setCookie("token-ntt-webadmin", "expired-token");

  localStorage.setItem("merchant-id-ntt", clientId);
  localStorage.setItem("token-ntt-webadmin", token);
  localStorage.setItem("profile-ntt", JSON.stringify(profile));
  let account;
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    account = JSON.parse(jsonPayload);

    localStorage.setItem("permission-ntt", JSON.stringify(account.Permission));
  }
};

export default AuthLogin;
