import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentPage,
  FilterName,
  List,
  Loading,
  Params,
  TotalItems,
} from "redux/voucherBansos/selectors";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/voucherBansos/slice";

import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import AktifStatus from "components/Status/AktifStatus";
import PeriodeFormat from "components/Format/PeriodeFormat";
import {
  getListVoucherBansos,
  voucherBansosGet,
} from "redux/voucherBansos/action";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";

const VoucherBansos = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const headers = [
    {
      name: "Penyelenggara",
      width: 30,
      custom: (item) => {
        return `${item.kode_penyelenggara} - ${item.penyelenggara}`;
      },
    },
    {
      name: "Nama Program Bantuan",
      key: "nama_program",
      width: 30,
    },
    {
      name: "Periode Voucer Bantuan",
      custom: (item) => {
        return (
          <PeriodeFormat
            StartDate={item.tanggal_mulai}
            EndDate={item.tanggal_selesai}
          />
        );
      },
      width: 20,
    },
    {
      name: "Status Bantuan",
      width: 10,
      custom: (item) => {
        return <AktifStatus value={item.status} />;
      },
      align: "left",
    },
  ];

  const filter = [
    { label: "Penyelenggara", value: "penyelenggara" },
    { label: "Nama Program", value: "nama_program" },
  ];

  const filterData = [
    {
      label: "Status Bantuan",
      key: "status",
      placeholder: "Pilih status bantuan",
      type: "switch",
    },
  ];

  const fetchData = async () => {
    await dispatch(getListVoucherBansos(params));
  };

  const exportData = useFetchData({
    action: voucherBansosGet,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "voucer-bansos",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Providers
        title="Program Voucer Bansos"
        desc
        currentPage={currentPage}
        params={params}
        get={fetchData}
      >
        <MainCard
          sx={{
            mt: 2,
            borderRadius: "8px",
          }}
          title="Daftar Voucer Bansos"
          onClick={() => setOpen(true)}
        >
          <MainCardHeader
            filter={filter}
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            title="Filter Voucer Bansos"
            onChangeExport={(type) => handleExport(type)}
          />
          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="nama_program"
            detail={[
              {
                type: "link",
                url_key:
                  "csr-bansos/program-bantuan/voucer-bansos/penerima:id",
                tooltip: "Detail Penerima Bantuan",
                variant: "icon:clipboard",
              },
              {
                type: "link",
                url_key: "csr-bansos/program-bantuan/voucer-bansos/voucher:id",
                tooltip: "Lihat Detail Penerbit",
                variant: "icon:ticket",
              },
            ]}
            increment={params?.offset + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>

      <Dialog open={open} onClose={() => setOpen(false)} refresh={fetchData} />
    </>
  );
};

export default VoucherBansos;
