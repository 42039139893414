import React from "react";

const ManagementMerchantIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9818 7.36364C21.9818 7.36364 21.9818 7.3 21.9818 7.27273L21.0727 2.72727C20.9909 2.3 20.6182 2 20.1818 2H3.81818C3.38182 2 3.00909 2.30909 2.92727 2.72727L2.01818 7.27273C2.01818 7.27273 2.01818 7.33636 2.01818 7.36364C2.01818 7.39091 2 7.41818 2 7.45455V8.81818C2 10.0818 2.74545 11.1636 3.81818 11.6818V21.0909C3.81818 21.5909 4.22727 22 4.72727 22H19.2727C19.7727 22 20.1818 21.5909 20.1818 21.0909V11.6818C21.2545 11.1727 22 10.0818 22 8.81818V7.45455C22 7.45455 21.9818 7.4 21.9818 7.36364ZM12.9091 20.1818V15.6364H14.7273V20.1818H12.9091ZM16.5455 20.1818V14.7273C16.5455 14.2273 16.1364 13.8182 15.6364 13.8182H12C11.5 13.8182 11.0909 14.2273 11.0909 14.7273V20.1818H5.63636V11.9545C6.34545 11.8545 6.98182 11.5273 7.45455 11.0364C8.03636 11.6273 8.83636 12 9.72727 12C10.6182 12 11.4182 11.6273 12 11.0364C12.5818 11.6273 13.3818 12 14.2727 12C15.1636 12 15.9636 11.6273 16.5455 11.0364C17.0182 11.5273 17.6545 11.8545 18.3636 11.9545V20.1818H16.5455ZM18.8182 10.1818C18.0636 10.1818 17.4545 9.57273 17.4545 8.81818C17.4545 8.31818 17.0455 7.90909 16.5455 7.90909C16.0455 7.90909 15.6364 8.31818 15.6364 8.81818C15.6364 9.57273 15.0273 10.1818 14.2727 10.1818C13.5182 10.1818 12.9091 9.57273 12.9091 8.81818C12.9091 8.31818 12.5 7.90909 12 7.90909C11.5 7.90909 11.0909 8.31818 11.0909 8.81818C11.0909 9.57273 10.4818 10.1818 9.72727 10.1818C8.97273 10.1818 8.36364 9.57273 8.36364 8.81818C8.36364 8.31818 7.95455 7.90909 7.45455 7.90909C6.95455 7.90909 6.54545 8.31818 6.54545 8.81818C6.54545 9.57273 5.93636 10.1818 5.18182 10.1818C4.42727 10.1818 3.81818 9.57273 3.81818 8.81818V7.54545L4.56364 3.81818H19.4364L20.1818 7.54545V8.81818C20.1818 9.57273 19.5727 10.1818 18.8182 10.1818Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ManagementMerchantIcon;
