import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { forwardRef, Fragment, useRef } from "react";
import { Close } from "@mui/icons-material";

const FileInput = forwardRef(
  ({ onChange, value, showImage, onClose, ...props }, ref) => {
    const imageRef = useRef();
    return (
      <Fragment>
        <input
          type="file"
          hidden
          ref={imageRef}
          value=""
          accept="image/png,image/jpg,image/jpeg"
          onChange={onChange}
        />

        <OutlinedInput
          {...props}
          ref={ref}
          value={value?.name || ""}
          onClick={() => imageRef.current.click()}
          endAdornment={
            <InputAdornment position="end">
              <Box
                sx={{
                  backgroundColor: "#d4d5d5",
                  padding: "11px",
                  color: "#111",
                  fontSize: "14px",
                  fontWeight: "700",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  position: "absolute",
                  right: 0,
                }}
              >
                Buka Folder
              </Box>
            </InputAdornment>
          }
          sx={{
            ".MuiOutlinedInput-root": {
              paddingRight: "0px",
            },
            maxHeight:"46px"
          }}
        />

        {showImage ? (
          <Box
            sx={{
              marginTop: "10px",
              width: "80%",
              position: "relative",
              objectFit: "contain",
            }}
          >
            <Box
              component="img"
              src={
                value
                  ? typeof value === "object"
                    ? URL.createObjectURL(value)
                    : value
                  : ""
              }
              sx={{
                borderRadius: "5%",
                width: "379px",
                height: "255px",
                objectFit: "contain",
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
                color: "#d4d5d5",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Box>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
);

FileInput.displayName = "Fi";

FileInput.propTypes = {
  onChange: PropTypes.func,
  props: PropTypes.any,
  showImage: PropTypes.any,
  value: PropTypes.any,
  onClose: PropTypes.any,
};

FileInput.defaultProps = {
  props: null,
  onChange: () => {},
  showImage: false,
  value: "",
  onClose: null,
};

export default FileInput;
