import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams } from "api";
import { LaporanTransaksi } from "api/endpoints";

export const getListTransaksiPPOB = createAsyncThunk(
  "laporanTransaksi/getListTransaksiPPOB",
  async (params) => {
    const response = await getParams(LaporanTransaksi.transaksiPPOB, params);
    return response.data.data;
  }
);

export const getTransaksiPPOB = async () => {
  const response = await getParams(LaporanTransaksi.transaksiPPOB);
  return response;
};

export const getDetailTransaksi = async (id) => {
  const response = await getParams(`${LaporanTransaksi.transaksiDetail}/${id}`);
  return response;
};

export const getListTransaksiPBB = createAsyncThunk(
  "laporanTransaksi/getListTransaksiPBB",
  async (params) => {
    const response = await getParams(LaporanTransaksi.transaksiPBB, params);
    return response.data.data;
  }
);

export const getTransaksiPBB = async () => {
  const response = await getParams(LaporanTransaksi.transaksiPBB);
  return response;
};

export const getListTransferMasuk = createAsyncThunk(
  "laporanTransaksi/getListTransferMasuk",
  async (params) => {
    const response = await getParams(LaporanTransaksi.transferMasuk, params);
    return response.data.data;
  }
);

export const getTransferMasuk = async () => {
  const response = await getParams(LaporanTransaksi.transferMasuk);
  return response;
};

export const getListTransferKeluar = createAsyncThunk(
  "laporanTransaksi/getListTransferKeluar",
  async (params) => {
    const response = await getParams(LaporanTransaksi.transferKeluar, params);
    return response.data.data;
  }
);

export const getTransferKeluar = async () => {
  const response = await getParams(LaporanTransaksi.transferKeluar);
  return response;
};
