import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@mui/material";
import React from "react";

const MainDialog2 = ({
  open,
  onClose,
  title,
  children,
  loading,
  handleSubmit,
  onSubmit,
  valid,
  width,
  action,
  closeButton,
  submitText,
  customWidth,
  type,
  onReset,
  custom,
  buttonWidth,
  secondaryVariant,
  ...props
}) => {
  const br = width == "lg" ? "1108px" : width == "xs" ? "400px" : "570px";
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (loading) {
          return;
        }

        onClose();
      }}
      scroll="body"
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            maxWidth: Boolean(customWidth) ? customWidth : br,
            width: "calc(100% - 10%)",
            margin: "0px",
          },
        },
      }}
    >
      <DialogContent {...props}>{children}</DialogContent>
    </Dialog>
  );
};

MainDialog2.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.any,
  width: PropTypes.any,
  action: PropTypes.bool,
  closeButton: PropTypes.bool,
  submitText: PropTypes.string,
  customWidth: PropTypes.string,
  type: PropTypes.number,
  onReset: PropTypes.func,
  custom: PropTypes.any,
  buttonWidth: PropTypes.string,
  secondaryVariant: PropTypes.string,
};

MainDialog2.defaultProps = {
  onClose: () => {},
  open: false,
  title: "",
  children: null,
  loading: false,
  valid: true,
  handleSubmit: () => {},
  onSubmit: null,
  width: "lg",
  action: true,
  closeButton: true,
  type: 0,
  submitText: "Submit",
  customWidth: null,
  custom: null,
  onReset: () => {},
  buttonWidth: "170px",
  secondaryVariant: "gray",
};
export default MainDialog2;
