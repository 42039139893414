import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import PageTitle from "components/Page/PageTitle";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import MainCard from "components/Paper/MainCard";
import SearchIcon from "assets/paper/SearchIcon";
import CardRole from "./components/cardRole";
import AktifStatus from "components/Status/AktifStatus";
import MainTable from "components/Table/MainTable";
import {
  RoleName,
  Description,
  Loading,
  Params,
  Users,
  TotalUser,
  Permission,
} from "redux/managementPengguna/Role/Detail/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserBatch,
  getRoleDetail,
} from "redux/managementPengguna/action";
import { useParams } from "react-router-dom";
import moment from "moment";
import Dialog from "./Dialog";
import { changeFilter } from "redux/managementPengguna/Role/Detail/slice";
import DeleteDialog from "components/Dialog/DeleteDialog";
import useFetchData from "hook/useFetchData";

const tempData2 = [
  {
    Email: "calda@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
  {
    Email: "jhon@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
  {
    Email: "alex@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
  {
    Email: "eema@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
  {
    Email: "leio@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
  {
    Email: "leio@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
  {
    Email: "alex@gmail.com",
    TglBergabung: "01 / 02 / 2020",
    Nama: "01 / 02 / 2020",
    Status: 1,
  },
];

const headers = [
  {
    name: "Email",
    key: "Email",
    width: 40,
  },
  {
    name: "Nama",
    key: "Fullname",
    width: 30,
  },
  {
    name: "Tgl. Bergabung",
    custom: (item) => moment(item.RegisterDate).format("DD / MM / YYYY"),
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => <AktifStatus value={item.Status} minWidth="140px" />,
    width: 30,
  },
];

const Detail = () => {
  const [open, setOpen] = useState({
    open: false,
    delete: false,
  });
  const [data, setData] = useState(null);
  const [batchDel, setBatchDel] = useState([]);
  const dispatch = useDispatch();

  const roleName = useSelector(RoleName);
  const description = useSelector(Description);
  const users = useSelector(Users);
  const params = useSelector(Params);
  const loading = useSelector(Loading);
  const totalUser = useSelector(TotalUser);
  const permission = useSelector(Permission);

  const { id } = useParams();
  const fetchData = async () => {
    dispatch(getRoleDetail({ id, params }));
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  const action = useFetchData({
    action: deleteUserBatch,
    message: "Berhasil hapus data",
    refresh: fetchData,
    onSuccess: () => {
      setOpen({
        ...open,
        delete: false,
      });
      setBatchDel([]);
    },
  });

  return (
    <>
      <PageTitle
        title={`Peran`}
        text="Dashboard / Management Pengguna / Peran / "
        lastText="Detail Peran "
        backBtn={false}
        custom={
          <Box display="flex" gap={1}>
           <OutlinedInput
              variant="v2"
              placeholder="Cari..."
              onChange={(e) => {
                dispatch(changeFilter(e.target.value));
              }}
              value={params?.filter || ""}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="primary-icon"
              sx={{
                width: "50px",
              }}
              onClick={() => {
                setData({});

                setOpen({
                  ...open,
                  open: true,
                });
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        }
      />

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              borderRadius: "8px",
              minHeight: "280px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                p: 2,
              }}
            >
              <Typography variant="title2">{roleName}</Typography>

              <Typography variant="desc3" my={1}>
                Total users with this role : {totalUser}
              </Typography>

              <Box display="flex" flexDirection="column" gap={0.5}>
                <CardRole
                  data={permission?.length ? permission : []}
                  limit={false}
                />
              </Box>
              <Box display="flex" gap={1} mt="auto" ml="auto">
                <Button
                  variant="gray"
                  onClick={() => {
                    setData({
                      data: {
                        id: id,
                        MerchantRoleName: roleName,
                        Description: description,
                        Permission: permission,
                      },
                    });

                    setOpen({
                      ...open,
                      open: true,
                    });
                  }}
                >
                  Ubah
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <MainCard
            title="Daftar Pengguna"
            addButton={false}
            margin={false}
            custom={
              <OutlinedInput
                variant="v1"
                onChange={(e) => {
                  dispatch(changeFilter(e.target.value));
                }}
                placeholder="Placeholder..."
                value={params?.filter || ""}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                sx={{
                  minWidth: "100px",
                }}
              />
            }
          >
            <MainTable
              headers={headers}
              loading={loading}
              data={users}
              align="center"
              summaryKey="Email"
              no={false}
              handleClickCheckBox={(item) => {
                const copy = [...batchDel];
                const findIndex = copy.findIndex((i) => i == item.Id);
                if (findIndex != -1) {
                  copy.splice(findIndex, 1);
                } else {
                  copy.push(item.Id);
                }
                setBatchDel(copy);
              }}
              handleCheckBoxAll={(item) => {
                const IdAll = item.map((i) => i.Id);
                if (!IdAll.every((i) => batchDel.includes(i))) {
                  setBatchDel(IdAll);
                } else {
                  setBatchDel([]);
                }
              }}
              checkBoxData={batchDel}
              checkBoxValue="Id"
              action="Aksi"
              checkbox={true}
              detail={[
                {
                  type: "button",
                  tooltip: "Hapus Data",
                  variant: "icon:trash",
                  onClick: (_, item) => {
                    if (!batchDel.length) {
                      const copy = [...batchDel];
                      copy.push(item.Id);
                      setBatchDel(copy);
                    }
                    setOpen({
                      ...open,
                      delete: true,
                    });
                  },
                },
              ]}
              sx={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            />
          </MainCard>
        </Grid>
      </Grid>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        refresh={fetchData}
        {...data}
      />

      <DeleteDialog
        open={open.delete}
        onClose={() => setOpen({ ...open, delete: false })}
        onSubmit={() => {
          const formData = new FormData();
          console.log(batchDel);
          formData.append("id", JSON.stringify(batchDel));
          action.fetch(id, formData);
        }}
      />
    </>
  );
};

export default Detail;
