import {
  Box,
  Button,
  Divider,
  OutlinedInput,
  Radio,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import AktifStatus from "components/Status/AktifStatus";
import moment from "moment";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { Controller, useForm } from "react-hook-form";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import { getListPenerbit } from "redux/penerbit/action";
import DateFormat from "helper/DateFormat";
import RadioMobile from "components/Mobile/Radio";
import {
  changeFilterName,
  changeFilter,
  deleteFilter,
} from "redux/penerbit/slice";

const headers = [
  {
    name: "Kode ",
    key: "IssuerCode",
    width: 25,
  },
  {
    name: "Status ",
    custom: (item) => <AktifStatus auto value={parseInt(item.IssuerStatus)} />,
    width: 25,
  },
  {
    name: "Tgl. Status",
    custom: (item) => {
      return DateFormat(item?.InsertedDate);
    },
    width: 30,
  },
];

const _filter = [
  {
    label: "Nama Penerbit",
    value: "IssuerName",
  },
  {
    label: "Kode Penerbit",
    value: "IssuerCode",
  },
];

const PenerbitMobile = () => {
  const { list, params, filterName, loading } = useSelect(
    (state) => state.penerbit
  );

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListPenerbit(params));

  useAutoFetch(fetchData);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    clearErrors,
    reset,
    resetField,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      filter: "",
      filterName: "",
    },
  });

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const handleChangeFilter = () =>
    dispatch(
      changeFilterName({
        name: getValues("filter"),
      })
    );

  const closeDialog = (event) => {
    toggleDrawer("filter", false)(event);
    reset();
    dispatch(
      changeFilterName({
        name: "",
      })
    );
    dispatch(deleteFilter());
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={0.5}>
          <MobileBadge
            value={watch("filterName") || "Pilih Parameter"}
            active={Boolean(watch("filterName"))}
            onClick={toggleDrawer("filter", true)}
          />

          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile
          title="Daftar Penerbit"
          list={list.length}
          onFilter={watch("filterName")}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="Nama Penerbit"
            value="IssuerName"
            action={false}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.filter}
        onClose={closeDialog}
        onOpen={toggleDrawer("filter", true)}
      >
        <DrawerTitle
          title="Pilih Parameter"
          toggle={toggleDrawer("filter", false)}
        />
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          <RadioMobile
            data={_filter}
            getProps={getProps}
            name="filter"
            checked={(item, field) => item.value == field.value}
            onClick={(field, item) => {
              dispatch(deleteFilter());
              field.onChange(item.value);
            }}
          />
        </Box>

        <Box display="flex" gap={1.5} p={2}>
          <Button
            variant="secondary-outlined"
            sx={{
              width: "100%",
            }}
            onClick={() => resetField("filter")}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            sx={{
              width: "100%",
            }}
            onClick={(event) => {
              const label = _filter.find(
                (i) => i.value == getValues("filter")
              )?.label;
              setValue("filterName", label);
              toggleDrawer("filter", false)(event);
              handleChangeFilter();
            }}
          >
            Terapkan
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default PenerbitMobile;
