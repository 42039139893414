import PropTypes from "prop-types";
import { useDebounceEffect } from "hook/useDebounceEffect";
import React from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "helper/canvasPreview";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

let scale = 1;
let rotate = 0;
let aspect = 16 / 9;

const CropImage = ({
  crop,
  setCrop,
  completedCrop,
  setCompletedCrop,
  imgRef,
  previewCanvasRef,
  src,
  deps,
}) => {
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    deps
  );

  return (
    <ReactCrop
      crop={crop}
      onChange={(_, percentCrop) => setCrop(percentCrop)}
      onComplete={(c) => setCompletedCrop(c)}
      // aspect={aspect}
    >
      <img
        ref={imgRef}
        alt="Crop me"
        src={src}
        crossOrigin="Anonymous"
        style={{
          width: "100%",
          borderRadius: "8px",
        }}
        onLoad={onImageLoad}
      />
    </ReactCrop>
  );
};

CropImage.propTypes = {
  completedCrop: PropTypes.object,
  crop: PropTypes.object,
  imgRef: PropTypes.any,
  previewCanvasRef: PropTypes.any,
  setCompletedCrop: PropTypes.func,
  setCrop: PropTypes.func,
  src: PropTypes.string,
  deps: PropTypes.array,
};

CropImage.defaultProps = {
  completedCrop: {},
  crop: {},
  imgRef: null,
  previewCanvasRef: null,
  setCompletedCrop: () => {},
  setCrop: () => {},
  src: "",
  deps: [],
};

export default CropImage;
