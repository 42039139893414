import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import MainLoading from "components/Loading/MainLoading";
import MainEditCard from "components/Paper/MainEditCard";
import getRole from "helper/getRole";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editAjudicator,
  getDesaList,
  getDetailAdjudicator,
  getKecamatanList,
  getKotaList,
  getProvinsiList,
} from "redux/digitalID/action";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Controller, useForm } from "react-hook-form";
import CropImage from "components/Crop";
import _ToUpperCase from "helper/_ToUppercase";
import MainGrid from "components/Grid/MainGrid";
import InputForm from "pages/LaporanAdjudicator/components/InputForm";
import Page from "components/Page";
import InputRadio from "pages/LaporanAdjudicator/components/inputRadio";
import MainController from "components/Controller/MainController";
import Autocomplete from "components/AutoComplete/Autocomplete";
import AlertDialog from "components/Dialog/AlertDialog";
import Ilustration from "assets/digitalId/ilustrasiEmpty.png";
import DigitalIDStatus from "components/Status/DigitalIDStatus";
import moment from "moment";
import {
  optAgama,
  optGolDar,
  optJenisKelamin,
  optPendidikan,
  optStatusKawin,
  optStatusKeluarga,
  optPekerjaan,
} from "pages/LaporanAdjudicator/variable";

const ConfirmOption = [
  {
    label: "Anomali",
    value: "",
  },
  {
    label: "Diterima",
    value: 2,
  },
  {
    label: "Ditolak",
    value: 5,
  },
];

const ConfirmOption2 = [
  {
    label: "Anomali",
    value: "",
  },
  {
    label: "Diterima",
    value: 3,
  },
  {
    label: "Ditolak",
    value: 5,
  },
  {
    label: "Kirim Kembali",
    value: 1,
  },
];

const removeDot = (string) => {
  if (string.indexOf(" ") >= 0) {
    return string.split(" ")[1];
  }
  return string;
};
const DetailKTP = () => {
  const { isSuperVisor, isOperator } = getRole();
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState({
    dialog: false,
    informasi: true,
    kartu: true,
  });
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      data: {},
      forms: [],
    },
    mode: "onChange",
  });

  const Provinsi = useFetchData({
    action: getProvinsiList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[19].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[19].options", data);
      setValue("forms[19].loading", false);
    },
  });

  const Kota = useFetchData({
    action: getKotaList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[18].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[18].options", data);
      setValue("forms[18].loading", false);
    },
  });

  const Kecamatan = useFetchData({
    action: getKecamatanList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[17].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[17].options", data);
      setValue("forms[17].loading", false);
    },
  });

  const Desa = useFetchData({
    action: getDesaList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[16].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[16].options", data);
      setValue("forms[16].loading", false);
    },
  });

  const { response, loading, fetch } = useFetchData({
    action: getDetailAdjudicator,
    snackbar: false,
    onSuccess: (data) => {
      let newData = {};

      newData.rt = data?.doc_rt_rw?.split("/")[0] || "";
      newData.rw = data?.doc_rt_rw?.split("/")[1] || "";

      Object.keys(data).map((item) => {
        switch (item) {
          case "nik":
            newData["NIK"] = data[item];
            break;
          case "no_kk":
            newData["NoKK"] = data[item];
            break;
          case "doc_pob":
            newData["DocPoB"] = data.doc_pob;
            break;
          case "doc_dob":
            newData["DocDoB"] = data.doc_dob;
            break;
          default:
            newData[_ToUpperCase(item)] = data[item];
            break;
        }
      });

      if (newData.DocKelDesa) {
        Desa.fetch(removeDot(newData.DocKelDesa));
      }
      if (newData.DocKecamatan) {
        Kecamatan.fetch(removeDot(newData.DocKecamatan));
      }
      if (newData.KotamadyaKab) {
        Kota.fetch(removeDot(newData.KotamadyaKab));
      }
      if (newData.Provinsi) {
        Provinsi.fetch(removeDot(newData.Provinsi));
      }
      resetForm({
        data: newData,
        forms: informasiPermohonan,
      });
    },
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const informasiPermohonan = [
    {
      label: "NIK",
      name: "NIK",
      rules: {
        maxLength: {
          message: "NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "No. KK",
      name: "NoKK",
      rules: {
        maxLength: {
          message: "No. KK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Lengkap",
      name: "DocName",
    },
    {
      label: "Tempat Lahir",
      name: "DocPoB",
    },
    {
      label: "Tgl. Lahir",
      type: "date",
      name: "DocDoB",
    },
    {
      label: "Jenis Kelamin",
      name: "DocGender",
      type: "select",
      options: optJenisKelamin,
    },
    {
      label: "Status Keluarga",
      type: "select",
      name: "StatusKeluarga",
      options: optStatusKeluarga,
    },
    {
      label: "Agama",
      type: "select",
      name: "DocReligion",
      options: optAgama,
    },
    {
      label: "Status Kawin",
      type: "select",
      name: "StatusKawin",
      options: optStatusKawin,
    },
    {
      label: "Pend. Akhir",
      type: "select",
      name: "PendidikanTerakhir",
      options: optPendidikan,
    },
    {
      label: "Pekerjaan",
      type: "select",
      name: "DocProfession",
      options: optPekerjaan,
    },
    {
      label: "Gol. Darah",
      type: "select",
      options: optGolDar,
      name: "DocBlood",
    },
    {
      label: "Alamat",
      name: "DocAddress",
    },
    {
      label: "RT",
      name: "rt",
    },
    {
      label: "RW",
      name: "rw",
    },
    {
      label: "Dusun",
      name: "Dusun",
    },
    {
      label: "Kelurahan",
      type: "wilayah",
      options: [],
      loading: false,
      name: "DocKelDesa",
      inputChange: (e) => {
        Desa.fetch(e);
      },
    },
    {
      label: "Kecamatan",
      type: "wilayah",
      options: [],
      loading: false,
      name: "DocKecamatan",
      inputChange: (e) => {
        Kecamatan.fetch(e);
      },
    },
    {
      label: "Kota / Kab",
      type: "wilayah",
      options: [],
      loading: false,
      name: "KotamadyaKab",
      inputChange: (e) => {
        Kota.fetch(e);
      },
    },
    {
      label: "Provinsi",
      type: "wilayah",
      options: [],
      loading: false,
      name: "Provinsi",
      inputChange: (e) => {
        Provinsi.fetch(e);
      },
    },
    {
      label: "Kode Pos",
      name: "KodePos",
    },
    {
      label: "No. Akta Lahir",
      name: "NoAktaLahir",
    },
    {
      label: "No. Akta Kawin",
      name: "NoAktaKawin",
    },
    {
      label: "Tgl. Kawin",
      type: "date",
      name: "TglKawin",
    },
    {
      label: "No. Akta Cerai",
      name: "NoAktaCerai",
    },
    {
      label: "NIK Ayah",
      name: "NikAyah",
      rules: {
        maxLength: {
          message: "NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Ayah",
      name: "NamaAyah",
    },
    {
      label: "NIK Ibu",
      name: "NikIbu",
      rules: {
        maxLength: {
          message: "NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Ibu",
      name: "NamaIbu",
    },
  ];

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const radioData = [
    {
      label: "Ya",
      value: "1",
    },
    {
      label: "Tidak",
      value: "0",
    },
  ];

  const verif = [
    {
      label: "Data KTP dan kartu fisik KTP sesuai?",
      name: "DocAdjKey1Status",
      value: radioData,
    },
    {
      label: "Foto wajah dan foto pada kartu fisik KTP sesuai?",
      name: "DocAdjKey2Status",
      value: radioData,
    },
  ];

  const Verifikasi = useFetchData({
    action: editAjudicator,
    message: isSuperVisor
      ? "Data berhasil dikonfirmasi"
      : "Data berhasil diverifikasi",
    onSuccess: () => {
      navigate("/laporan-adjudicator/digital-id/ktp");
    },
  });

  const onSubmit = (data) => {
    const profile = JSON.parse(localStorage.getItem("profile-ntt"));
    let userNameLogin = profile?.Fullname;
    let newData = data.data;
    newData["DocRtRw"] = `${newData.rt || ""}/${newData.rw || ""}`;
    if (isOperator) {
      if (newData.DocAdjStatus === 2) {
        newData["DocAdjValidatedBy"] = userNameLogin;
      } else if (newData.DocAdjStatus === 5) {
        newData["DocAdjRejectedBy"] = userNameLogin;
      }
    } else {
      if (response.hasOwnProperty("adj_validate_by")) {
        newData["DocAdjValidatedBy"] = response?.adj_validate_by;
      }
      if (response.hasOwnProperty("adj_rejected_by")) {
        newData["DocAdjRejectededBy"] = response?.adj_reject_by;
      }
      if (newData.DocAdjStatus === 3) {
        newData["DocAdjApprovedBy"] = userNameLogin;
      } else if (newData.DocAdjStatus === 5) {
        newData["DocAdjRejectedBy"] = userNameLogin;
      }
      if (response.hasOwnProperty("tgl_cerai")) {
        newData["TglCerai"] = response?.tgl_cerai;
      }
    }

    Verifikasi.fetch(id, newData);
  };

  const onDownloadCropClick = () => {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }
    let io = previewCanvasRef.current.toDataURL("image/jpeg");
    let rep = io.split("data:image/jpeg;base64,")[1];
    setValue("data.DocPictCropping", rep);
  };

  const keputusanOperator = useMemo(() => {
    if (!isOperator) {
      return [
        {
          label: "Status",
          value: <DigitalIDStatus value={response?.adj_status} />,
        },
        {
          label: "Pemberi Status",
          value: response?.adj_validate_by,
        },
        {
          label: "Tgl. Status",
          value: response?.doc_adj_approved_date
            ? moment(response?.doc_adj_approved_date).format("DD / MM / YYYY")
            : "-",
        },
        {
          label: "Catatan",
          value: response?.notes,
        },
      ];
    }
  }, [isOperator, response]);

  const keputusanSupervisor = useMemo(() => {
    if (!isSuperVisor && !isOperator) {
      return [
        {
          label: "Status",
          value: <DigitalIDStatus value={response?.adj_status} />,
        },
        {
          label: "Pemberi Status",
          value: response?.doc_adj_approved_by || "-",
        },
        {
          label: "Catatan",
          value: response?.notes,
        },
      ];
    }
  }, [isOperator, isSuperVisor, response]);

  return (
    <>
      <Page
        title={`Perbandingan Data - ${response?.doc_name.split(" ")[0] || ""}`}
        page={["Laporan Adjudicator", "Sentra Kependudukan"]}
        backBtn
        menu="KTP"
        to="/laporan-adjudicator/digital-id/ktp"
      />
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        mt={1}
      >
        <Grid
          item
          xs={12}
          md={7}
          order={{
            xs: 2,
            md: 1,
          }}
        >
          <MainEditCard
            title="Informasi Permohonan"
            onEdit={open.informasi}
            onClickEdit={() =>
              setOpen({
                ...open,
                informasi: false,
              })
            }
            onClickSimpan={() => {
              setOpen({
                ...open,
                informasi: true,
              });
            }}
            action={isOperator}
          >
            <MainLoading height={400} loading={loading} center>
              {getValues("forms").map((item, i) => {
                return (
                  <MainGrid
                    key={i}
                    labelMd={3}
                    striped={"odd"}
                    center
                    valueMd={9}
                    label={item.label}
                    value={
                      <InputForm
                        getProps={getProps}
                        errors={errors}
                        {...item}
                        open={open.informasi}
                      />
                    }
                  />
                );
              })}

              {!open?.informasi && (
                <Box
                  sx={{
                    display: "flex",
                    p: 2,
                  }}
                >
                  <Button
                    variant="primary"
                    sx={{
                      width: "100%",
                    }}
                    onClick={() => {
                      setOpen({
                        ...open,
                        informasi: true,
                      });
                    }}
                  >
                    Simpan
                  </Button>
                </Box>
              )}
            </MainLoading>
          </MainEditCard>

          <MainEditCard title="Verifikasi Data" action={false} margin>
            <MainLoading height={100} loading={loading} center>
              {verif.map((item, i) => {
                return (
                  <MainGrid
                    key={i}
                    striped="odd"
                    labelMd={8}
                    center
                    valueMd={4}
                    label={item.label}
                    value={<InputRadio getProps={getProps} {...item} />}
                  />
                );
              })}
            </MainLoading>
          </MainEditCard>

          {!isOperator ? (
            <MainEditCard title="Keputusan Operator" action={false} margin>
              <MainLoading height={100} loading={loading} center>
                {keputusanOperator.map((item, i) => {
                  return (
                    <MainGrid
                      key={i}
                      striped="odd"
                      labelMd={3}
                      center
                      valueMd={9}
                      label={item.label}
                      value={item.value}
                    />
                  );
                })}
              </MainLoading>
            </MainEditCard>
          ) : null}

          {!isOperator && !isSuperVisor ? (
            <MainEditCard title="Keputusan Supervisor" action={false} margin>
              <MainLoading height={100} loading={loading} center>
                {keputusanSupervisor.map((item, i) => {
                  return (
                    <MainGrid
                      key={i}
                      striped="odd"
                      labelMd={3}
                      center
                      valueMd={9}
                      label={item.label}
                      value={item.value}
                    />
                  );
                })}
              </MainLoading>
            </MainEditCard>
          ) : null}

          {isOperator && (
            <MainEditCard margin action={false} title="Catatan">
              <MainLoading height={100} loading={loading} center>
                {response?.notes && (
                  <Box display="flex" gap={1} pl={2} pt={1}>
                    <Typography variant="label" width="140px">
                      Dari Operator
                    </Typography>
                    <Typography variant="label">
                      : {response?.notes || "-"}
                    </Typography>
                  </Box>
                )}
                <Box sx={{ p: 2 }}>
                  <MainController
                    {...getProps("data.Notes")}
                    customError={errors?.data?.Notes?.message}
                    rules={{
                      required: "Catatan wajib disii",
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <OutlinedInput
                        {...field}
                        multiline
                        rows={6}
                        variant="v1"
                        error={invalid}
                        placeholder="Cth: warna rambut berbeda"
                      />
                    )}
                  />
                </Box>
              </MainLoading>
            </MainEditCard>
          )}

          {isSuperVisor || isOperator ? (
            <MainEditCard margin title="Keputusan" action={false}>
              <MainLoading height={100} loading={loading} center>
                <Box display="flex" gap={1} p={2}>
                  <MainController
                    {...getProps("data.DocAdjStatus")}
                    rules={{ required: "Status wajib diisi" }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Autocomplete
                        variant="v1"
                        error={invalid}
                        {...field}
                        options={isSuperVisor ? ConfirmOption2 : ConfirmOption}
                        value={
                          isSuperVisor
                            ? ConfirmOption2.find(
                                (item) => item.value === field.value
                              ) || null
                            : ConfirmOption.find(
                                (item) => item.value === field.value
                              ) || null
                        }
                        placeholder="Pilih keputusan akhir"
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(_, nv) => {
                          if (nv == null) {
                            field.onChange(1);
                          }
                          field.onChange(nv.value);
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="primary"
                    sx={{
                      minWidth: "140px",
                    }}
                    disabled={!isValid || !watch("data.DocAdjStatus")}
                    onClick={() =>
                      setOpen({
                        ...open,
                        dialog: true,
                      })
                    }
                  >
                    {isSuperVisor ? "Konfirmasi" : "Validasi"}
                  </Button>
                </Box>
              </MainLoading>
            </MainEditCard>
          ) : null}
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          order={{
            xs: 1,
            md: 2,
          }}
        >
          <MainEditCard
            title="Kartu ID"
            onEdit={open.kartu}
            action={isOperator}
            onClickEdit={() =>
              setOpen({
                ...open,
                kartu: false,
              })
            }
            onClickSimpan={() => {
              onDownloadCropClick();
              setOpen({
                ...open,
                kartu: true,
              });
            }}
          >
            <MainLoading height={100} loading={loading} center>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  p: 2.5,
                }}
              >
                {open.kartu ? (
                  <TransformWrapper>
                    <TransformComponent>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "100%",
                          maxHeight: 300,
                          borderRadius: "calc(0.6vw + 0.6vh)",
                          objectFit: "contain",
                        }}
                        src={response?.doc_pict_secondary}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                ) : (
                  <CropImage
                    crop={crop}
                    setCrop={setCrop}
                    completedCrop={completedCrop}
                    setCompletedCrop={setCompletedCrop}
                    imgRef={imgRef}
                    previewCanvasRef={previewCanvasRef}
                    src={response?.doc_pict_secondary}
                    deps={[completedCrop]}
                  />
                )}
              </Box>
            </MainLoading>
          </MainEditCard>

          {!!completedCrop && (
            <MainEditCard
              title="Foto Yang Ditampilkan"
              onEdit={open.kartu}
              action={false}
              margin
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2.5,
                }}
              >
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    objectFit: "contain",
                    borderRadius: "calc(0.6vw + 0.6vh)",
                    maxWidth: "100%",
                    minHeight: 270,
                  }}
                />
              </Box>
            </MainEditCard>
          )}

          {!isOperator && (
            <MainEditCard title="Foto yang ditampilkan" margin action={false}>
              <MainLoading height={100} loading={loading} center>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    p: 2.5,
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      maxWidth: "100%",
                      maxHeight: 250,
                      borderRadius: "calc(0.4vw + 0.4vh)",
                      objectFit: "contain",
                    }}
                    src={watch("data.DocPictCropping")}
                  />
                </Box>
              </MainLoading>
            </MainEditCard>
          )}

          <MainEditCard title="Foto Wajah" margin action={false}>
            <MainLoading height={100} loading={loading} center>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  p: 2.5,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: 250,
                    borderRadius: "calc(0.4vw + 0.4vh)",
                    objectFit: "contain",
                  }}
                  src={response?.doc_pict_primary}
                />
              </Box>
            </MainLoading>
          </MainEditCard>
        </Grid>

        <AlertDialog
          open={open.dialog}
          onClose={() =>
            setOpen({
              ...open,
              dialog: false,
            })
          }
          customWidth="500px"
          alertTitle={`Data Selesai ${
            isOperator ? "Diverifikasi" : "Dikonfirmasi"
          }?`}
          alertDesc="Kamu sedang melakukan verifikasi data. Pastikan data  sudah sesuai."
          cancelText="Batal"
          submitText="Ya, Simpan "
          type="submit"
          customIcon={<Box component="img" src={Ilustration} />}
        />
      </Grid>
    </>
  );
};

export default DetailKTP;
