import React, { useRef, useState, useMemo } from "react";
import { styled } from "@mui/system";
import { Box, Button } from "@mui/material";
import Page from "components/Page";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import moment from "moment";
import FormatRupiah from "helper/FormatRupiah";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  changeType,
  deleteFilter,
} from "redux/hargaProdukPPOB/slice";
import { useDispatch } from "react-redux";
import {
  deleteHargaProduk,
  getHargaProduk,
  getListHargaProduk,
  refreshOy,
  refreshWND,
} from "redux/hargaProdukPPOB/action";
import MainTable from "components/Table/MainTable";
import MainPagination from "components/Pagination";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { Refresh } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import useFetchData from "hook/useFetchData";
import Dialog from "./Dialog";
import DeleteDialog from "components/Dialog/DeleteDialog";
import ExportHelper from "helper/ExportHelper";
import UbahStatusDialog from "./UbahStatus";

const MyButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, theme }) => ({
  width: 150,
  height: 45,
  borderRadius: "8px 8px 0px 0px",
  color: "#ADADAE",
  ...(active && {
    backgroundColor: "#cc1d15",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#B20600",
    },
  }),
}));

const headers = [
  {
    name: "Kode Produk",
    key: "pCode",
    width: 15,
  },
  {
    name: "Nama Produk",
    key: "pName",
    width: 15,
  },
  {
    name: "Harga Jual",
    custom: (item) => {
      return `Rp. ${FormatRupiah(item.pJual)}`;
    },
    width: 15,
  },
  {
    name: "Harga Biller",
    custom: (item) => {
      return `Rp. ${FormatRupiah(item.pPrice)}`;
    },
    width: 15,
  },
  {
    name: "Tanggal Diubah",
    custom: (data) => {
      return moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format("DD / MM / YYYY")
        : "-";
    },
    width: 15,
  },
  {
    name: "Diubah Oleh",
    key: "updatedBy",
    width: 15,
  },
  {
    name: "Status",
    width: 15,
    custom: (data) => {
      return (
        <AktifStatus
          value={data.pActive}
          sx={{
            minWidth: "118px",
            height: "34px",
          }}
        />
      );
    },
  },
];

const filter = [
  {
    label: "Kode Produk",
    value: "pCode",
  },
  {
    label: "Nama Produk",
    value: "pName",
  },
];

export default function HargaProdukPPOB() {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.hargaProdukPPOB);
  const [batchDel, setBatchDel] = useState([]);

  const [open, setOpen] = useState({
    dialog: false,
    delete: false,
    ubahstatus: false,
  });

  const id = useRef(null);

  const data = useRef(null);

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListHargaProduk(params));

  useAutoFetch(fetchData, currentPage, filterName, params.type);

  const refreshData = useFetchData({
    action: refreshWND,
    message: "Berhasil refresh data",
    onSuccess: () => {
      fetchData();
    },
  });

  const refreshOyData = useFetchData({
    action: refreshOy,
    message: "Berhasil refresh data",
    onSuccess: () => {
      fetchData();
    },
  });

  const deleteData = useFetchData({
    action: deleteHargaProduk,
    snackbar: false,
    onSuccess: () => {
      fetchData();
      setOpen({ ...open, delete: false });
    },
  });

  const handleRefresh = () => {
    refreshData.fetch();
  };

  const handleRefreshOy = () => {
    refreshOyData.fetch();
  };

  const detail = useMemo(() => {
    if (params.type == 1) {
      return [
        {
          type: "link",
          tooltip: "Edit Harga Produk PPOB",
          url_key: "master-data:harga-produk-ppob:Id",
          variant: "icon:pencil",
        },
      ];
    }
    if (params.type == 2) {
      return [
        {
          type: "link",
          tooltip: "Edit Harga Produk PPOB",
          url_key: "master-data:harga-produk-ppob:Id",
          variant: "icon:pencil",
        },
        {
          type: "button",
          tooltip: "Hapus Harga Produk PPOB",
          variant: "icon:trash",
          onClick: (_, item) => {
            id.current = item.Id;
            setOpen({
              ...open,
              delete: true,
            });
          },
        },
      ];
    }
    if (params.type == 3) {
      return [
        {
          type: "link",
          tooltip: "Edit Harga Produk PPOB",
          url_key: "master-data:harga-produk-ppob:Id",
          variant: "icon:pencil",
        },
        {
          type: "button",
          tooltip: "Hapus Harga Produk PPOB",
          variant: "icon:trash",
          onClick: (_, item) => {
            id.current = item.Id;
            setOpen({
              ...open,
              delete: true,
            });
          },
        },
      ];
    }
  }, [params.type]);

  const exportData = useFetchData({
    action: getHargaProduk,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "harga-produk-ppob",
      });
    },
  });

  const handleExport = (type) => {
    exportData.fetch(
      {
        type: params.type,
      },
      null,
      type
    );
  };

  return (
    <>
      <Page
        title="Harga Produk PPOB"
        page={["Master Data"]}
        menu="Harga Produk PPOB"
      />
      <Box display="flex" mt={2}>
        <MyButton
          variant="gray"
          active={params.type == 1}
          onClick={() => {
            dispatch(changeType(1));
            setBatchDel([]);
          }}
        >
          WND
        </MyButton>
        <MyButton
          active={params.type == 2}
          variant="gray"
          onClick={() => {
            dispatch(changeType(2));
            setBatchDel([]);
          }}
        >
          Oy
        </MyButton>
        <MyButton
          variant="gray"
          active={params.type == 3}
          onClick={() => {
            dispatch(changeType(3));
            setBatchDel([]);
          }}
        >
          Rajabiller
        </MyButton>
      </Box>
      <MainCard
        margin={false}
        title="Harga Produk"
        addButton={false}
        sx={{
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
        borderColor="#C8C8C8"
        custom={
          <Box display="flex" gap={2} height="45px">
            {Boolean(batchDel.length) && (
              <Button
                variant="primary-outlined"
                onClick={() => setOpen({ ...open, ubahstatus: true })}
              >
                Ubah Status
              </Button>
            )}
            {params.type == 1 && (
              <Button
                variant="primary"
                disabled={refreshData.loading}
                startIcon={<Refresh />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            )}
            {params.type == 2 && (
              <Button
                variant="primary"
                disabled={handleRefreshOy.loading}
                startIcon={<Refresh />}
                onClick={handleRefreshOy}
              >
                Refresh
              </Button>
            )}
            {params.type == 3 && (
              <Button
                variant="primary"
                startIcon={<AddIcon />}
                onClick={() =>
                  setOpen({
                    ...open,
                    dialog: true,
                  })
                }
              >
                Tambah
              </Button>
            )}
          </Box>
        }
      >
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          fetchData={fetchData}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="pCode"
          detail={detail}
          increment={params?.offset + 1}
          checkbox={params.type != 3}
          handleClickCheckBox={(item) => {
            const copy = [...batchDel];
            const findIndex = copy.findIndex((i) => i.Id == item.Id);
            if (findIndex != -1) {
              copy.splice(findIndex, 1);
            } else {
              copy.push(item);
            }
            setBatchDel(copy);
          }}
          handleCheckBoxAll={(item) => {
            if (!item.every((i) => batchDel.some((o) => o.Id == i.Id))) {
              setBatchDel(item);
            } else {
              setBatchDel([]);
            }
          }}
          checkBoxData={batchDel}
          checkBoxValue="Id"
          customSelectedAll={(data) =>
            data.every((i) => batchDel.some((o) => o.Id === i.Id))
          }
          customSelected={(item) => batchDel.some((i) => i.Id == item.Id)}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
        type={params.type}
        data={data.current}
      />

      <DeleteDialog
        open={open.delete}
        loading={deleteData.loading}
        onClose={() => setOpen({ ...open, delete: false })}
        onSubmit={() => deleteData.fetch(id.current)}
      />

      <UbahStatusDialog
        open={open.ubahstatus}
        onClose={() => setOpen({ ...open, ubahstatus: false })}
        refresh={fetchData}
        data={batchDel}
        setBatchDel={setBatchDel}
      />
    </>
  );
}
