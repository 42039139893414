import React from "react";

const LaporanTransaksiIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2 11H17.01V13.8736C17.01 13.991 17.1534 14.0482 17.2342 13.9631L20.8688 10.1381C20.9423 10.0608 20.9421 9.93937 20.8685 9.86221L17.234 6.05526C17.153 5.97044 17.01 6.02776 17.01 6.14503V8.99999H10.2C10.0895 8.99999 10 9.08954 10 9.19999V10.8C10 10.9105 10.0895 11 10.2 11ZM13.8 15H6.99V17.8684C6.99 17.9858 6.84673 18.0431 6.76581 17.958L3.13116 14.1379C3.05769 14.0606 3.05769 13.9394 3.13116 13.8621L6.76581 10.0419C6.84673 9.95689 6.99 10.0142 6.99 10.1315V13H13.8C13.9105 13 14 13.0895 14 13.2V14.8C14 14.9105 13.9105 15 13.8 15Z"
        fill="black"
      />
      <mask
        id="mask0_6237_4585"
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="18"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2 11H17.01V13.8736C17.01 13.991 17.1534 14.0482 17.2342 13.9631L20.8688 10.1381C20.9423 10.0608 20.9421 9.93937 20.8685 9.86221L17.234 6.05526C17.153 5.97044 17.01 6.02776 17.01 6.14503V8.99999H10.2C10.0895 8.99999 10 9.08954 10 9.19999V10.8C10 10.9105 10.0895 11 10.2 11ZM13.8 15H6.99V17.8684C6.99 17.9858 6.84673 18.0431 6.76581 17.958L3.13116 14.1379C3.05769 14.0606 3.05769 13.9394 3.13116 13.8621L6.76581 10.0419C6.84673 9.95689 6.99 10.0142 6.99 10.1315V13H13.8C13.9105 13 14 13.0895 14 13.2V14.8C14 14.9105 13.9105 15 13.8 15Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6237_4585)">
        <rect
          x="25"
          width="24"
          height="24"
          transform="rotate(90 25 0)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default LaporanTransaksiIcon;
