import {
  optJenisKelamin,
  optPekerjaan,
  optPenolongLahir,
  optTmpDilahirkan,
} from "../components/variabel";
import moment from "moment";

export const bayiData = (data) => {
  return {
    nama_anak: data.nama_anak,
    kelahiran_ke: data.kelahiran_ke,
    berat: data.berat + " KG",
    panjang: data.panjang,
    tempat_lahir: data.tempat_lahir,
    tempat_dilahirkan: data.tempat_dilahirkan,
    jenis_kelahiran: data.jenis_kelahiran,
    penolong_kelahiran: data.penolong_kelahiran,
    jenis_kelamin_anak: data.jenis_kelamin_anak,
    tanggal_lahir: moment(new Date(data.tanggal_lahir)).format("DD / MM / YYYY"),
    pukul_kelahiran: data.pukul_kelahiran,
    is_dengan_ortu: data.is_dengan_ortu ? "YA" : "TIDAK",
    warding: {
      nama_anak_warding: data.nama_anak_warding,
      kelahiran_ke_warding: data.kelahiran_ke_warding,
      berat_warding: data.berat_warding,
      panjang_warding: data.panjang_warding,
      tempat_lahir_warding: data.tempat_lahir_warding,
      tempat_dilahirkan_warding: data.tempat_dilahirkan_warding,
      jenis_kelahiran_warding: data.jenis_kelahiran_warding,
      penolong_kelahiran_warding: data.penolong_kelahiran_warding,
      jenis_kelamin_anak_warding: data.jenis_kelamin_anak_warding,
      tanggal_lahir_warding: data.tanggal_lahir_warding,
      pukul_kelahiran_warding: data.pukul_kelahiran_warding,
      is_dengan_ortu_warding: data.is_dengan_ortu_warding,
    },
    lainnya: {
      jenis_kelahiran_lainnya: data.jenis_kelahiran_lainnya,
      penolong_kelahiran: data.penolong_kelahiran_lainnya,
    },
  };
};

export const bayiForm = () => {
  return [
    {
      label: "Nama Anak",
      name: "bayi.nama_anak",
      warding: "bayi.warding.nama_anak_warding",
      data: "nama_anak",
      commentKey: "nama_anak_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Nama Anak kartu keluarga wajib diisi",
      },
    },
    {
      label: "Kelahiran Ke",
      name: "bayi.kelahiran_ke",
      warding: "bayi.warding.kelahiran_ke_warding",
      data: "kelahiran_ke",
      commentKey: "kelahiran_ke_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Kelahiran wajib diisi" },
    },
    {
      label: "Berat",
      name: "bayi.berat",
      warding: "bayi.warding.berat_warding",
      data: "berat",
      commentKey: "berat_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Berat wajib diisi" },
    },
    {
      label: "Panjang",
      name: "bayi.panjang",
      warding: "bayi.warding.panjang_warding",
      data: "panjang",
      commentKey: "panjang_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Panjang wajib diisi" },
    },
    {
      label: "Tempat Lahir",
      name: "bayi.tempat_lahir",
      warding: "bayi.warding.tempat_lahir_warding",
      data: "tempat_lahir",
      commentKey: "tempat_lahir_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Tempat Lahir wajib diisi" },
    },
    {
      label: "Tempat Dilahirkan",
      name: "bayi.tempat_dilahirkan",
      warding: "bayi.warding.tempat_dilahirkan_warding",
      data: "tempat_dilahirkan",
      commentKey: "tempat_dilahirkan_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optTmpDilahirkan,
      rules: { required: "Tempat Dilahirkan wajib diisi" },
    },
    {
      label: "Jenis Kelahiran",
      data: "jenis_kelahiran",
      name: "bayi.jenis_kelahiran",
      warding: "bayi.warding.jenis_kelahiran_warding",
      lainnyaKey: "bayi.lainnya.jenis_kelahiran_lainnya",
      lainnyaData: "jenis_kelahiran_lainnya",
      value: "",
      comment: "",
      commentKey: "jenis_kelahiran_warding",
      lainnya: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPekerjaan,
      rules: { required: "Jenis Kelahiran wajib diisi" },
    },
    {
      label: "Penolong Lahir",
      data: "penolong_kelahiran",
      name: "bayi.penolong_kelahiran",
      warding: "bayi.warding.penolong_kelahiran_warding",
      lainnyaKey: "bayi.lainnya.penolong_kelahiran_lainnya",
      lainnyaData: "penolong_kelahiran_lainnya",
      value: "",
      comment: "",
      commentKey: "penolong_kelahiran_warding",
      lainnya: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPenolongLahir,
      rules: { required: "Penolong Lahir wajib diisi" },
    },
    {
      label: "Jenis Kelamin",
      data: "jenis_kelamin_anak",
      warding: "bayi.warding.jenis_kelamin_anak_warding",
      name: "bayi.jenis_kelamin_anak",
      value: "",
      comment: "",
      commentKey: "jenis_kelamin_anak_warding",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optJenisKelamin,
      rules: { required: "Jenis Kelamin  wajib diisi" },
    },
    {
      label: "Tanggal Lahir",
      name: "bayi.tanggal_lahir",
      warding: "bayi.warding.tanggal_lahir_warding",
      data: "tanggal_lahir",
      commentKey: "tanggal_lahir_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "date",
      rules: { required: "Tanggal Lahir wajib diisi" },
    },
    {
      label: "Pukul Kelahiran",
      name: "bayi.pukul_kelahiran",
      warding: "bayi.warding.pukul_kelahiran_warding",
      data: "pukul_kelahiran",
      commentKey: "pukul_kelahiran_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "time",
      rules: { required: "Tanggal Lahir wajib diisi" },
    },
    {
      label: "Dengan Ortu",
      name: "bayi.is_dengan_ortu",
      noEdit: true,
    },
  ];
};
