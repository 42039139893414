import React from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import MainCardHeader2 from "components/Paper/MainCardHeader2";
import useSelect from "hook/useSelect";
import Page from "components/Page";
import useAutoFetch from "hook/useAutoFetch";
import {
  changeFilter,
  changeLimit,
  changePage,
} from "redux/managementMerchant/slice";
import moment from "moment";
import {
  getListManagementMerchant,
  getManagementMerchant,
} from "redux/managementMerchant/action";
import ManagementMerchantStatus from "components/Status/ManagementMerchant";

const ManagementMerchant = () => {
  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListManagementMerchant(params));

  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.managementMerchant);

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "Bisnis",
      key: "Bisnis",
      width: 25,
    },
    {
      name: "Tipe Bisnis",
      key: "TipeBisnis",
      width: 25,
    },
    {
      name: "Tgl. Pendaftaran",
      custom: (item) => {
        return moment(item?.StatusDate).isValid()
          ? moment(item?.StatusDate).format("DD / MM / YYYY")
          : "-";
      },
      width: 25,
    },
    {
      name: "Status",
      custom: (item) => <ManagementMerchantStatus value={item.Status} />,
      width: 25,
    },
  ];

  const exportData = useFetchData({
    action: getManagementMerchant,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data,
        filename: "management-merchant",
      });
    },
  });

  const handleExport = (type) => {
    exportData.fetch(null, null, type);
  };

  return (
    <>
      <Page title="Merchant" page={["Management Merchant"]} menu="Merchant" />

      <MainCard title="Daftar Merchant" addButton={false} borderColor="#C8C8C8">
        <MainCardHeader2
          filterName={filterName}
          changeFilter={changeFilter}
          params={params}
          onChangeExport={(type) => handleExport(type)}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="Bisnis"
          detail={[
            {
              type: "link",
              url_key: "management-merchant/merchant:Id",
              tooltip: "Lihat Detail Anggota",
              variant: "text:Proses",
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>
    </>
  );
};

export default ManagementMerchant;
