import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React from "react";
import ArrowDown from "./Icon/ArrowDown";
import { styled } from "@mui/system";

const MyBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => ({
  backgroundColor: "#F0F4FD",
  display: "flex",
  gap: "5px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRadius: "16px",
  alignItems: "center",
  ...(active && {
    backgroundColor: "#cc1d15",
    color: "#fff",
  }),
}));

const MobileBadge = ({ value, active, selected, arrow, ...props }) => {
  return (
    <MyBox active={active} {...props}>
      <Typography
        component="span"
        variant="label2"
        sx={{ display: "flex", gap: 0.5, color: "inherit" }}
      >
        {selected > 0 && (
          <Box component="span" sx={{ whiteSpace: "nowrap" }}>
            {selected}
          </Box>
        )}
        <Box component="span" sx={{ whiteSpace: "nowrap" }}>
          {value}
        </Box>
      </Typography>
      {arrow && <ArrowDown />}
    </MyBox>
  );
};

MobileBadge.propTypes = {
  value: PropTypes.any,
  selected: PropTypes.number,
  active: PropTypes.bool,
  arrow: PropTypes.bool,
};

MobileBadge.defaultProps = {
  value: "",
  selected: null,
  active: false,
  arrow: true,
};

export default MobileBadge;
