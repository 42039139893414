import PageTitle from "components/Page/PageTitle";
import AktifStatus from "components/Status/AktifStatus";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailLokasi, getLokasiImage } from "redux/sentraLokasi/action";
import SelectChips from "components/Status/SelectChips";
import MainCard from "components/Paper/MainCard";
import MainLoading from "components/Loading/MainLoading";
import MainGrid from "components/Grid/MainGrid";
import { languangeList } from "./variables";
import { Box, Grid } from "@mui/material";
import MainMap from "components/Map/MainMap";

const DetailLokasi = () => {
  // const [infoLokasi, setInfoLokasi] = useState();

  const { id } = useParams();

  const detail = useFetchData({
    action: getDetailLokasi,
    snackbar: false,
  });

  const image = useFetchData({
    action: getLokasiImage,
    snackbar: false,
  });

  useEffect(() => {
    detail.fetch(id);
    image.fetch(id);
  }, []);

  const infoLokasi = useMemo(() => {
    if (detail.response) {
      const data = detail?.response?.data;
      const fasilitas = detail?.response?.fasilitas;

      const arr = [
        {
          label: "Status",
          value: <AktifStatus value={data.is_active} />,
        },
        {
          label: "Kategori",
          value: data.kategori,
        },
        {
          label: "Nama Lokasi",
          value: data.name,
        },
        {
          label: "Bahasa",
          value: languangeList.find((i) => i.value == data.bahasa)?.label,
        },
        {
          label: "Alamat",
          value: data.alamat,
        },
        {
          label: "Deskripsi",
          value: data.description,
        },
        {
          label: "Fasilitas",
          value: (
            <SelectChips
              data={fasilitas}
              height="30px"
              wrap
              width="auto"
              tipe={1}
              sx={{
                mt: 1,
                gap: 1,
              }}
              getLabel={(item) => item.name}
            />
          ),
        },
      ];

      return arr;
    }
  }, [detail.response]);

  return (
    <>
      <PageTitle title={`Sentra Lokasi`} />

      <Grid container spacing={2}>
        <Grid item md={7}>
          <MainCard title="Detail Info Lokasi" addButton={false} isEdit>
            <MainLoading loading={detail.loading}>
              {infoLokasi?.map((item, i) => (
                <MainGrid
                  striped="odd"
                  center={["Status", "Fasilitas"].includes(item.label)}
                  key={i}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>

        <Grid item md={5}>
          <MainCard title="Foto Lokasi" addButton={false} isEdit>
            <MainLoading loading={image.loading}>
              <Box
                sx={{
                  padding: "15px",
                  width: "auto",
                  height: "auto",
                  // boxSizing: "border-box",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "200px",
                    backgroundColor: "#f6f6f6",
                    objectFit: "contain",
                    borderRadius: "10px",
                    // p: 2,
                  }}
                  src={image?.response?.[0]?.link_picture}
                />
              </Box>
            </MainLoading>
          </MainCard>
          <MainCard title="Map Lokasi" addButton={false} isEdit>
            <MainLoading loading={image.loading}>
              <Box
                sx={{
                  p: 2,
                }}
              >
                <MainMap
                  latitude={detail.response?.data?.latitude}
                  longitude={detail.response?.data?.longitude}
                  // onChange={({ lat, lng }) => {
                    // field.onChange(lng);
                    // setValue("latitude", lat);
                  // }}
                />
              </Box>
            </MainLoading>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailLokasi;
