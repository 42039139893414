import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, put } from "api";
import { Profile } from "api/endpoints";

const getProfile = createAsyncThunk("users/getProfile", async () => {
  const response = await get(Profile.profile);
  return response.data.data;
});

const getPhoto = createAsyncThunk("users/getPhoto", async () => {
  const response = await get(Profile.photo);
  return response.data.data;
});

export const updateProfile = async (data, id) => {
  const response = await put(`${Profile.editUser}/${id}`, data);
  return response;
};

export const changePassword = async (data) => {
  const response = await put(Profile.changePassword, data);
  return response;
};

export { getProfile, getPhoto };
