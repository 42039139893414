import React from "react";

const WarningIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.16675 14H9.83342V12.3333H8.16675V14ZM9.00008 0.666672C4.40008 0.666672 0.666748 4.40001 0.666748 9.00001C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 9.00001C17.3334 4.40001 13.6001 0.666672 9.00008 0.666672ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.675 2.33341 9.00001C2.33341 5.32501 5.32508 2.33334 9.00008 2.33334C12.6751 2.33334 15.6667 5.32501 15.6667 9.00001C15.6667 12.675 12.6751 15.6667 9.00008 15.6667ZM9.00008 4.00001C7.15841 4.00001 5.66675 5.49167 5.66675 7.33334H7.33342C7.33342 6.41667 8.08342 5.66667 9.00008 5.66667C9.91675 5.66667 10.6667 6.41667 10.6667 7.33334C10.6667 9.00001 8.16675 8.79167 8.16675 11.5H9.83342C9.83342 9.62501 12.3334 9.41667 12.3334 7.33334C12.3334 5.49167 10.8417 4.00001 9.00008 4.00001Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningIcon;
