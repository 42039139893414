import React from "react";

const LogoSmall = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4437 9.03284C17.4437 4.24323 13.5871 0.360352 8.82979 0.360352C4.07246 0.360352 0.21582 4.24323 0.21582 9.03284C0.21582 12.453 2.18284 15.4095 5.03944 16.8214L5.03999 16.8203C5.22307 16.9177 5.4314 16.9733 5.65294 16.9733C6.37919 16.9733 6.96799 16.3805 6.96799 15.6493C6.96799 15.1234 6.66271 14.6704 6.22145 14.4569L6.22283 14.4541C4.22551 13.4788 2.84831 11.4178 2.84831 9.03284C2.84831 5.70699 5.52633 3.01075 8.82979 3.01075C12.1333 3.01075 14.8112 5.70699 14.8112 9.03284C14.8112 11.0303 13.8442 12.7995 12.3569 13.8951C11.205 14.7305 10.0965 13.6777 10.0965 12.5226V9.06648H10.0949V8.17002C10.0949 7.90694 9.88299 7.69359 9.62169 7.69359H7.92387C7.66256 7.69359 7.45066 7.90694 7.45066 8.17002V9.06648V9.15449V13.1415H7.45084C7.45323 14.1519 8.43518 16.2227 10.317 16.4847C12.8129 16.8134 14.0117 15.9667 14.3374 15.6996L14.3377 15.7C14.3406 15.6976 14.3433 15.6949 14.3462 15.6925C14.3572 15.6834 14.3673 15.6749 14.376 15.6674C14.376 15.6674 14.3753 15.6669 14.3753 15.6668C16.2507 14.0759 17.4437 11.6952 17.4437 9.03284Z"
        fill="#D8232A"
      />
      <path
        d="M8.77082 7.11454C9.50212 7.11454 10.0949 6.51766 10.0949 5.78139C10.0949 5.04512 9.50212 4.44824 8.77082 4.44824C8.03953 4.44824 7.44678 5.04512 7.44678 5.78139C7.44678 6.51766 8.03953 7.11454 8.77082 7.11454Z"
        fill="#AB2430"
      />
      <mask
        id="mask0_6982_29599"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.277832 9.03275C0.277832 12.4529 2.24477 15.4094 5.10139 16.8213L5.10194 16.8203C5.28511 16.9177 5.49344 16.9733 5.71489 16.9733C6.44124 16.9733 7.02995 16.3804 7.02995 15.6492C7.02995 15.1233 6.72467 14.6704 6.28341 14.4569L6.28478 14.4541C4.28745 13.4787 2.91033 11.4178 2.91033 9.03275C2.91033 5.7069 5.58828 3.01066 8.89176 3.01066C12.1951 3.01066 14.8733 5.7069 14.8733 9.03275C14.8733 11.0303 13.9063 12.7995 12.419 13.895C11.267 14.7304 10.1584 13.6776 10.1584 12.5226V9.06648H10.1569V8.16992C10.1569 7.90684 9.94496 7.6935 9.68366 7.6935H7.98583C7.72452 7.6935 7.51262 7.90684 7.51262 8.16992V9.06648V9.15449V13.1415H7.5128C7.51519 14.1518 8.49714 16.2227 10.379 16.4847C12.8749 16.8133 14.0737 15.9666 14.3994 15.6996L14.3997 15.6999C14.4026 15.6975 14.4053 15.6948 14.4081 15.6924C14.4192 15.6834 14.4293 15.6749 14.438 15.6673C14.438 15.6673 14.4373 15.6668 14.4373 15.6667C16.3127 14.0758 17.5057 11.6952 17.5057 9.03275C17.5057 4.24314 13.6491 0.360352 8.89176 0.360352C4.13431 0.360352 0.277832 4.24314 0.277832 9.03275Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6982_29599)">
        <path
          d="M10.1569 6.87842C10.1569 9.33497 10.1586 11.9979 10.1586 12.5226C10.1586 13.276 11.1475 17.07 15.0494 15.5631C12.8045 19.115 8.68861 16.856 8.0472 15.9136C7.40578 14.9981 6.57734 9.87019 7.08508 7.66664C7.91361 7.31685 10.1569 6.87842 10.1569 6.87842Z"
          fill="#AB2430"
        />
      </g>
      <path d="M1.8208 21.64H2.53167V17.658H1.8208V21.64Z" fill="#D8232A" />
      <path
        d="M6.71365 19.2566C6.69185 19.1251 6.65052 18.9947 6.59307 18.8744C6.48843 18.6326 6.34713 18.4213 6.16946 18.2428C6.07756 18.1561 5.98015 18.0752 5.87973 18.0028C5.77426 17.9266 5.65798 17.8637 5.53776 17.8164C5.28578 17.7111 5.02967 17.6577 4.77613 17.6577C4.518 17.6577 4.25933 17.7111 4.01092 17.8148C3.88722 17.8635 3.77094 17.9266 3.66511 18.0028C3.60949 18.043 3.55469 18.0857 3.501 18.131L3.5054 17.6577H2.79297V21.6398H3.50384L3.50365 19.6212C3.50778 19.5392 3.51868 19.4583 3.53591 19.3802C3.55268 19.3033 3.57641 19.2271 3.60582 19.155C3.67354 18.9931 3.76489 18.8557 3.88209 18.7418C3.99012 18.6285 4.12647 18.5364 4.28957 18.4671C4.4305 18.4045 4.59424 18.3726 4.77613 18.3726C4.94491 18.3726 5.10737 18.4049 5.25884 18.4684C5.41049 18.5323 5.54738 18.625 5.66558 18.7441C5.7245 18.8036 5.77637 18.8678 5.82026 18.9351C5.86589 19.0051 5.90639 19.0802 5.93938 19.1549C6.00709 19.3175 6.04154 19.4837 6.04154 19.6487V21.6398H6.75232L6.75223 19.6346C6.74701 19.5064 6.73409 19.379 6.71365 19.2566Z"
        fill="#D8232A"
      />
      <path d="M6.98926 21.64H7.70004V17.658H6.98926V21.64Z" fill="#D8232A" />
      <path
        d="M8.61448 19.1626C8.64848 19.0813 8.68825 19.0069 8.73223 18.9413C8.77676 18.8754 8.82927 18.8118 8.88892 18.7518C9.01161 18.6284 9.14823 18.5357 9.29731 18.4758C9.45868 18.4075 9.62389 18.3728 9.78827 18.3728C9.95256 18.3728 10.1177 18.4075 10.2789 18.4756C10.3603 18.5102 10.4344 18.5501 10.499 18.5942C10.5646 18.639 10.6281 18.692 10.6875 18.7518L10.8067 18.8717L11.3227 18.3668L11.1939 18.2437C11.0024 18.0603 10.7915 17.9158 10.5624 17.8118C10.3157 17.7097 10.0551 17.658 9.78827 17.658C9.51594 17.658 9.25746 17.709 9.01986 17.8096C8.78345 17.9102 8.57032 18.0548 8.38678 18.2394C8.20306 18.4241 8.0592 18.6385 7.95942 18.8762C7.85936 19.1146 7.80859 19.3746 7.80859 19.6489V20.01H11.0106C10.9971 20.054 10.9806 20.0983 10.961 20.143C10.9024 20.2893 10.8106 20.4256 10.6879 20.5482C10.6278 20.6083 10.5645 20.6609 10.4992 20.7049C10.4343 20.749 10.3604 20.7886 10.2793 20.8227C10.1181 20.8906 9.95284 20.9251 9.78827 20.9251C9.62389 20.9251 9.45868 20.8904 9.29457 20.821C9.14796 20.7619 9.01152 20.6696 8.88892 20.5461L8.76806 20.4244L8.26638 20.9292L8.38632 21.0508C8.56986 21.2367 8.78272 21.3829 9.01885 21.485C9.25626 21.5879 9.51521 21.64 9.78827 21.64C10.0551 21.64 10.3151 21.5895 10.5662 21.4876C10.7905 21.3882 11.0015 21.2457 11.1968 21.0605C11.2866 20.9705 11.3672 20.8709 11.4362 20.7644C11.5039 20.6604 11.5643 20.547 11.6169 20.4259C11.7172 20.1878 11.7679 19.9287 11.7679 19.6556V19.2951H8.56812C8.58141 19.249 8.59698 19.2045 8.61448 19.1626Z"
        fill="#D8232A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2596 18.2391C15.4383 18.4191 15.5807 18.6318 15.6828 18.8717C15.7857 19.113 15.8378 19.3744 15.8378 19.6487V21.6398H15.127V21.1699C15.0154 21.2646 14.8928 21.348 14.7599 21.4193C14.4875 21.5656 14.1841 21.6398 13.8581 21.6398C13.585 21.6398 13.325 21.5873 13.0853 21.4839C12.8466 21.3811 12.6352 21.2379 12.4566 21.0582C12.2777 20.8784 12.1353 20.6657 12.0333 20.4258C11.9306 20.1849 11.8784 19.9235 11.8784 19.6487C11.8784 19.3741 11.9306 19.1127 12.0333 18.8717C12.1354 18.6316 12.2779 18.4189 12.4566 18.2391C12.6352 18.0594 12.8466 17.9163 13.0853 17.8134C13.325 17.7101 13.585 17.6577 13.8581 17.6577C14.1312 17.6577 14.3911 17.7101 14.6307 17.8134C14.8691 17.916 15.0806 18.0592 15.2596 18.2391ZM15.1022 19.9244C15.1186 19.8495 15.127 19.7714 15.127 19.6924V19.6487C15.127 19.4775 15.0945 19.3078 15.0305 19.1442C14.9984 19.0721 14.9577 18.9972 14.9127 18.9282C14.8709 18.8642 14.8219 18.8078 14.7671 18.7607L14.7596 18.7542L14.753 18.7469C14.6993 18.6888 14.6384 18.6358 14.5715 18.5896C14.5037 18.5428 14.4299 18.5013 14.353 18.4667C14.1972 18.4053 14.0285 18.3726 13.8581 18.3726C13.6835 18.3726 13.5152 18.405 13.358 18.4692C13.2021 18.5394 13.0674 18.6338 12.9634 18.7468L12.9566 18.754L12.9491 18.7607C12.8943 18.8078 12.8453 18.8642 12.8037 18.928C12.7581 18.998 12.7175 19.0728 12.6831 19.1508C12.6219 19.3074 12.5893 19.4772 12.5893 19.6487C12.5893 19.8248 12.6216 19.9939 12.6853 20.1516C12.7554 20.3048 12.8484 20.4422 12.9588 20.5532C13.0692 20.6643 13.2058 20.758 13.3652 20.8314C13.4395 20.863 13.5209 20.8874 13.6023 20.9021C13.686 20.9172 13.7719 20.9249 13.8581 20.9249C13.9443 20.9249 14.0302 20.9172 14.1135 20.9021C14.1953 20.8874 14.2767 20.863 14.356 20.8293C14.4309 20.7958 14.5056 20.7549 14.5749 20.7093C14.6401 20.6662 14.7001 20.6152 14.7529 20.5579C14.8678 20.4422 14.9609 20.3048 15.0341 20.1445C15.0613 20.0781 15.085 20.0018 15.1022 19.9244Z"
        fill="#D8232A"
      />
    </svg>
  );
};

export default LogoSmall;
