import { Wallpaper } from "@mui/icons-material";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React from "react";
// import { StyledTextField } from "../../../../components/Venturo/ui-component/textField/MainTextField";
import PropTypes from "prop-types";

const EditButton = styled(Button)(({ theme }) => ({
  border: "2px solid #cc1d15",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#cc1d15",
  backgroundColor: "#fff",
  "&:hover": {
    outline: "none",
    border: "2px solid #cc1d15",
    boxShadow: "0 2px 4px 0 rgba(17, 17, 17, 0.5)",
    color: "#fff",
    background: "#b20600",
  },
}));

const Foto = ({ item }) => {
  return (
    <Stack direction={"column"}>
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          border: "1px dashed #74788d",
          borderRadius: "4px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            padding: "13px",
            width: "100%",
            // border: "1px dashed #74788d",
          }}
          justifyContent="space-between"
          alignItems={"center"}
          spacing={2}
        >
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Wallpaper fontSize="large" />
            <Typography
              variant="gridLabel"
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              {!item?.property?.label?.value
                ? item?.name.includes("Kolom")
                  ? "Judul " + item?.name
                  : "Judul Kolom " + item?.name
                : item?.property?.label?.value}

              {item?.tampilan?.wajib_diisi && (
                <font color="red" className="mx-1">
                  *
                </font>
              )}
            </Typography>
          </Stack>
          <EditButton variant="outlined">
            {item?.property?.keterangan?.value}
          </EditButton>
        </Stack>
      </Stack>
      {item?.tampilan?.deskripsi && (
        <Box variant="desc6">
          {item?.property?.deskripsi?.value || "Caption (penjelasan isi field)"}
        </Box>
      )}
    </Stack>
  );
};

Foto.propTypes = {
  item: PropTypes.object,
};
Foto.defaultProps = {
  item: {},
};

export default Foto;
