import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import StatusChip from "components/Status/StatusChip";
import MainTable from "components/Table/MainTable";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  exportBroadcastSMS,
  getListEmailMarketing,
} from "redux/broadcast/action";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/broadcast/EmailMarketing/slice";
import Dialog from "./Dialog";

const filter = [
  {
    label: "Subjek",
    value: "MessageTitle",
  },
  {
    label: "Konten",
    value: "MessageBody",
  },
  {
    label: "Penerima",
    value: "TotalReceiver",
  },
  {
    label: "Status",
    value: "Status",
  },
];

const headers = [
  {
    name: "Tgl. Dibuat",
    custom: (data) => {
      return moment(data?.created_at).format("DD / MM / YYYY");
    },
  },
  {
    name: "Nama",
    key: "name",
    width: 40,
  },
  {
    name: "Tgl. Dikirim",
    custom: (data) => {
      return moment(data?.send_at).format("DD / MM / YYYY");
    },
  },
  {
    name: "Tgl. Diubah",
    custom: (data) => {
      return moment(data?.updated_at).format("DD / MM / YYYY");
    },
  },
  {
    name: "Status",
    width: 30,
    custom: (data) => {
      return (
        <StatusChip
          color={"#2c8424"}
          background={"#e9f2e9"}
          label={data.status}
          sx={{
            textTransform: "capitalize",
          }}
        />
      );
    },
  },
];

const BroadcastEmailMarketing = () => {
  const [open, setOpen] = useState({
    dialog: false,
  });
  
  const dispatch = useDispatch();
  
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.emailMarketingBroadcast);

  const fetchData = () => dispatch(getListEmailMarketing(params));

  useAutoFetch(fetchData, currentPage, filterName);

  const exportData = useFetchData({
    action: exportBroadcastSMS,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "broadcast-email-marketing",
      });
    },
  });

  const handleExport = (type) => {
    exportData.fetch("", "", type);
  };

  return (
    <>
      <Page title="Broadcast" page={["Broadcast"]} menu="Email Marketing" />

      <MainCard
        title="Email Marketing"
        onClick={() =>
          setOpen({
            dialog: true,
          })
        }
        borderColor="#C8C8C8"
      >
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          fetchData={fetchData}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="MessageTitle"
          action={""}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
      />
    </>
  );
};

export default BroadcastEmailMarketing;
