import { Typography, Grid } from "@mui/material";
import PeriodeFormat from "components/Format/PeriodeFormat";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import MainStatus from "components/Status/MainStatus";
import MainTable from "components/Table/MainTable";
import DateFormat from "helper/DateFormat";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { penerbitDetail } from "redux/penerbit/action";
import { status_voucher } from "../Voucher/variables";
import Dialog from "./Dialog";

const DetailPenerbit = () => {
  const [res, setRes] = useState(null);
  const [informasiData, setInformasiData] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const id = useParams().id;

  const action = useFetchData({
    action: penerbitDetail,
    snackbar: false,
  });

  useEffect(() => {
    action.fetch(id);
  }, []);

  useEffect(() => {
    setRes(action.response);
  }, [action.response]);

  const headers = [
    {
      name: "Prefix Voucer",
      key: "PrefixCode",
      width: 20,
    },
    {
      name: "Nama Voucer",
      key: "VoucherName",
      width: 30,
    },
    {
      name: "Periode",
      custom: (item) => {
        return (
          <PeriodeFormat StartDate={item?.StartDate} EndDate={item?.EndDate} />
        );
      },
      width: 30,
    },
    {
      name: "Status Voucer",
      custom: (item) => {
        return <MainStatus value={item.Status} arr={status_voucher} />;
      },
      width: 40,
    },
  ];

  const informasi = [
    "IssuerName",
    "IssuerCode",
    "IssuerStatus",
    "InsertedDate",
  ];

  useEffect(() => {
    if (res) {
      setInformasiData([]);
      let inf = [];
      for (const key in informasi) {
        if (res?.informasi?.[informasi[key]] != null) {
          inf.push({
            name: renderTitle(informasi[key]),
            value: renderValue(informasi[key]),
          });
        }
      }
      setInformasiData(inf);
    }
  }, [res]);

  const renderValue = (data) => {
    let go = res?.informasi?.[data];
    if (data === "IssuerStatus") {
      return <AktifStatus value={go} sx={{ maxWidth: "130px" }} />;
    } else if (data === "InsertedDate") {
      return DateFormat(go);
    } else {
      return String(go) || "-";
    }
  };

  const titleArr = [
    {
      name: "IssuerName",
      value: "Nama Penerbit",
    },
    {
      name: "IssuerCode",
      value: "Kode Penerbit",
    },
    {
      name: "IssuerStatus",
      value: "Status Penerbit",
    },
    {
      name: "InsertedDate",
      value: "Tgl. Status",
    },
  ];

  const renderTitle = (str) => {
    let returnVal = "";
    for (const key in titleArr) {
      if (titleArr[key].name === str) {
        returnVal = titleArr[key].value;
      }
    }
    return returnVal;
  };

  const DialogData = () => {
    const informasiEdit = ["IssuerName", "IssuerCode", "IssuerStatus"];

    const filtered = Object?.keys(res?.informasi || {})
      ?.filter((key) => informasiEdit.includes(key))
      ?.reduce((oj, key) => {
        oj[key] = res?.informasi[key];
        return oj;
      }, {});

    setData(filtered);
    setOpen(true);
  };

  return (
    <>
      <PageTitle
        title={`Detail`}
        desc={res?.informasi?.IssuerName}
        to="/master-data/master-bantuan/penerbit"
      />
      <MainCard
        title="Informasi Penerbit"
        addButton={false}
        isEdit
        onClick={DialogData}
      >
        <MainLoading loading={action.loading}>
          {informasiData.map((item, i) => (
            <MainGrid
              striped="odd"
              key={i}
              label={item.name}
              value={item.value}
              center={item.name == "Status Penerbit"}
            />
          ))}
        </MainLoading>
      </MainCard>
      <MainCard title="Voucer Penerbit" addButton={false}>
        <MainTable
          headers={headers}
          loading={action.loading}
          data={res?.voucher}
          summaryKey="VoucherName"
          action={""}
        />
      </MainCard>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        isEdit={true}
        setRes={setRes}
        id={id}
        data={data}
      />
    </>
  );
};

export default DetailPenerbit;
