import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React from "react";
import BorderLinearProgress from "./BarChart";

const Progress = ({ label, value }) => {
  const percentage = (value / 642) * 100;
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="text7">{label}</Typography>
        <Typography variant="text7">{value}</Typography>
      </Box>
      <BorderLinearProgress variant="determinate" value={percentage} />
    </Box>
  );
};

Progress.propTypes = {
  label: PropTypes.any,
  value: PropTypes.number,
};

Progress.defaultProps = {
  label: "",
  value: 0,
};

export default Progress;
