import PropTypes from "prop-types";
import { Button, IconButton, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { IcRef, putTitle } from "./variable";
import { Link } from "react-router-dom";

const MainSmButton = ({ data, root }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton onClick={handleClick} aria-describedby={id}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          {data.map((item, i) => {
            return <LinkButton key={i} {...item} root={root} />;
          })}
        </Box>
      </Popover>
    </>
  );
};

const generateLink = (payload, url_key) => {
  let linkKey = url_key.split(":");
  let realKey = [];
  linkKey.forEach((payload_id) => {
    if (payload?.[payload_id]) {
      realKey.push(payload[payload_id]);
    } else {
      realKey.push(payload_id);
    }
  });

  let result = "/" + realKey.join("/");
  return result;
};

const DetailButton = (item) => {
  const val = item.variant.split(":")[1];
  const variant = item.variant.startsWith("text:");
  if (variant) {
    return (
      <Button
        sx={{
          p: 2,
          display: "flex",
          gap: 1,
          alignItems: "center",
          color: "#111",
        }}
      >
        <Typography
          component="span"
          display="flex"
          variant="label2"
          sx={{
            textDecoration: "none",
          }}
        >
          {val}
        </Typography>
      </Button>
    );
  } else {
    return (
      <Button
        sx={{
          p: 2,
          display: "flex",
          gap: 1,
          alignItems: "center",
          color: "#111",
        }}
      >
        <Box
          component="span"
          display="flex"
          sx={{
            textDecoration: "none",
          }}
        >
          {IcRef?.[val]()}
        </Box>{" "}
        <Typography
          variant="label2"
          component="span"
          display="flex"
          sx={{
            textDecoration: "none",
          }}
        >
          {putTitle(val)}
        </Typography>
      </Button>
    );
  }
};

const LinkButton = ({ url_key, type, custom_link, root, ...other }) => {
  if (type == "link") {
    const navigateTo = custom_link
      ? custom_link(root)
      : generateLink(root, url_key);
    return (
      <Link to={navigateTo} style={{ textDecoration: "none" }}>
        <DetailButton {...other} />
      </Link>
    );
  } else {
    return <DetailButton root={root} {...other} />;
  }
};

LinkButton.propTypes = {
  custom_link: PropTypes.any,
  root: PropTypes.any,
  type: PropTypes.string,
  url_key: PropTypes.any,
};

LinkButton.defaultProps = {
  custom_link: null,
  root: null,
  type: "",
  url_key: null,
};

MainSmButton.propTypes = {
  data: PropTypes.any,
  root: PropTypes.any,
};

MainSmButton.defaultProps = {
  data: null,
  root: null,
};

export default MainSmButton;
