import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import Radio from "components/Radio/Radio";
import TimePicker from "components/Picker/TimePicker";
import DatePicker2 from "components/Picker/DatePicker2";
import moment from "moment";
import {
  createEmailBroadcast,
  getEmailBroadcastTemplateAll,
} from "redux/broadcast/action";
import InputExcel from "components/TextField/InputExcel";
import ExcelTemplate from "assets/broadcast/email/template/template_broadcast.xlsx";
import Autocomplete from "components/AutoComplete/Autocomplete";

const radio = [
  {
    label: "Kirim Sekarang",
    value: 1,
  },
  {
    label: "Jadwalkan Nanti",
    value: 0,
  },
];

const Desc = ({ download }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <span>Hanya dokumen excel format *.xls dan *.xlsx yang diterima.</span>
      <Box
        component="a"
        sx={{ color: "#cc1d15", textDecoration: "none" }}
        download="template-email-receiver.xlsx"
        href={download}
      >
        Download Template
      </Box>
    </Box>
  );
};

Desc.propTypes = {
  download: PropTypes.any,
};

Desc.defaultProps = {
  download: "",
};

const Dialog = ({ open, onClose, refresh, data, id, setRes }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    clearErrors,
    watch,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      messageTitle: "",
      messageBody: "",
      sendDateTime: {
        day: "",
        minutes: "",
      },
      autoSend: null,
      templateId: null,
      listReceiver: null,
      listCC: null,
      listBCC: null,
    },
  });

  const action = useFetchData({
    action: createEmailBroadcast,
    reset: resetForm,
    message: "Broadcast email baru berhasil",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const template = useFetchData({
    action: getEmailBroadcastTemplateAll,
    snackbar: false,
  });

  useEffect(() => {
    if (data) {
      resetForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async (data) => {
    if (typeof data.sendDateTime == "object") {
      const { day, minutes } = data.sendDateTime;
      data.sendDateTime = `${day} ${minutes}:00`;
    }

    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      if (data[item] !== null && data[item] !== undefined) {
        formData.append(item, data[item]);
      }
    });
    await action.fetch(formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Buat Campaign Baru"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="lg"
      custom={
        <>
          <Button variant="secondary-outlined">Tinjau</Button>
          <Button variant="primary-outlined">Simpan</Button>
        </>
      }
      submitText="Submit & Kirim"
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Penerima"}>
          <Grid container spacing={1}>
            <VerticalGrid label={"Kepada"} md={12}>
              <MainController
                {...getProps("listReceiver")}
                rules={{
                  required: "Kepada wajib diisi",
                }}
                desc={<Desc download={ExcelTemplate} />}
                render={({ field, fieldState: { invalid } }) => (
                  <InputExcel
                    {...field}
                    setError={setError}
                    error={invalid}
                    variant="v1"
                    placeholder="Pilih dokumen"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Cc"} md={12}>
              <MainController
                {...getProps("listCC")}
                desc={<Desc download={ExcelTemplate} />}
                render={({ field, fieldState: { invalid } }) => (
                  <InputExcel
                    {...field}
                    setError={setError}
                    error={invalid}
                    variant="v1"
                    placeholder="Pilih dokumen"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Bcc"} md={12}>
              <MainController
                {...getProps("listBCC")}
                desc={<Desc download={ExcelTemplate} />}
                render={({ field, fieldState: { invalid } }) => (
                  <InputExcel
                    {...field}
                    setError={setError}
                    error={invalid}
                    variant="v1"
                    placeholder="Pilih dokumen"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Subjek"} md={12}>
              <MainController
                {...getProps("messageTitle")}
                rules={{
                  required: "Subjek wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    variant="v1"
                    placeholder="Masukan Subjek"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Tambah template"} md={12}>
              <MainController
                {...getProps("templateId")}
                rules={{
                  required: "Template wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    options={template.response || []}
                    value={
                      template.response?.find(
                        (item) => item.template_id === field.value
                      ) || null
                    }
                    onOpen={() => {
                      template.setResponse([]);
                      template.fetch({
                        templateType: 1,
                      });
                    }}
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv?.template_id);
                      }
                    }}
                    error={invalid}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.template_id}>
                          {option.name}
                        </li>
                      );
                    }}
                    placeholder="Tambah template"
                    loading={template.loading}
                    variant="v1"
                    getOptionLabel={(option) => option.name || null}
                    isOptionEqualToValue={(option, value) =>
                      option.template_id === value.template_id
                    }
                  />
                )}
              />
            </VerticalGrid>
          </Grid>
        </VerticalGrid>
        <VerticalGrid label={"Konten"}>
          <Grid container spacing={2}>
            <VerticalGrid md={12}>
              <MainController
                {...getProps("messageBody")}
                rules={{
                  required: "Koten wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    multiline
                    variant="v1"
                    rows={8}
                    placeholder={`
                  Contoh: Selamat, Bapak/Ibu {{name}} telah mendapatkan bantuan sosial senilai 1.000.000
                `.trim()}
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Jadwal Pengiriman"} md={12}>
              <MainController
                {...getProps("autoSend")}
                rules={{
                  required: "Koten wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Radio
                    {...field}
                    data={radio}
                    onChange={(e) => {
                      const value = parseInt(e);
                      field.onChange(value);

                      if (value == 1) {
                        setValue("sendDateTime", {
                          day: moment().format("YYYY-MM-DD"),
                          minutes: moment().format("HH:mm"),
                        });
                      } else {
                        setValue("sendDateTime", {
                          day: "",
                          minutes: "",
                        });
                      }
                    }}
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label="Pilih Tanggal" md={6}>
              <MainController
                {...getProps("sendDateTime.day")}
                rules={
                  watch("autoSend") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field, fieldState: { invalid } }) => {
                  return (
                    <DatePicker2
                      {...field}
                      variant="v1"
                      disabled={watch("autoSend") == 1}
                      error={invalid}
                      placeholder="DD/MM/YYYY"
                    />
                  );
                }}
              />
            </VerticalGrid>

            <VerticalGrid label="Pilih Waktu" md={6}>
              <MainController
                {...getProps("sendDateTime.minutes")}
                rules={
                  watch("autoSend") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field }) => {
                  const split = field.value?.split(":") || [];

                  return (
                    <TimePicker
                      disabled={watch("autoSend") == 1}
                      hours={split[0]}
                      minutes={split[1]}
                      onChangeHours={(e) => {
                        split[0] = e;
                        field.onChange(split.join(":"));
                      }}
                      onChangeMinutes={(e) => {
                        split[1] = e;
                        field.onChange(split.join(":"));
                      }}
                      variant="v1"
                    />
                  );
                }}
              />
            </VerticalGrid>
          </Grid>
          <Typography variant="inputDesc">
            Pesan Anda hanya dapat dibatalkan hingga 3 menit sebelum waktu yang
            dijadwalkan
          </Typography>
        </VerticalGrid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
