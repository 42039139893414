const pagination2 = {
  changeLimit: (state, action) => {
    let limit = action.payload.limit;
    state.params.limit = limit;
  },
  changeFilterName: (state, action) => {
    let filterName = action.payload.name;
    state.filterName = filterName;
  },
  changeFilter: (state, action) => {
    state.params = {
      ...state.params,
      limit: 10,
      page: 1,
      [state.filterName]: action.payload,
    };
  },
  changePage: (state, action) => {
    let limit = action.payload.limit;
    state.params.limit = limit;
    state.params.page = action.payload.page;
  },
  deleteFilter: (state) => {
    delete state.params?.[state.filterName];
  },
  addFilter: (state, action) => {
    if (!action.payload.action) {
      state.params = {
        ...state.params,
        ...action.payload.list,
      };
    } else {
      for (let i in action.payload.tempList) {
        delete state.params[i];
      }
    }
  },
};

export default pagination2;
