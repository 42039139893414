import { Box, Button, OutlinedInput, Paper, Typography } from "@mui/material";
import Autocomplete from "components/AutoComplete/Autocomplete";
import MainController from "components/Controller/MainController";
import MainGrid from "components/Grid/MainGrid";
import Page from "components/Page";
import ManagementMerchantStatus from "components/Status/ManagementMerchant";
import getRole from "helper/getRole";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailMerchant,
  verifMerchant,
} from "redux/managementMerchant/action";

const keputusanDataOperator = [
  {
    label: "Diterima",
    value: 1,
  },
  {
    label: "Ditolak",
    value: 2,
  },
];

const keputusanDataSupervisor = [
  {
    label: "Diterima",
    value: 1,
  },
  {
    label: "Ditolak",
    value: 2,
  },
  {
    label: "Dikembalikan",
    value: 3,
  },
];

const ManagementMerchantDetail = () => {
  const { isSuperVisor, isOperator } = getRole();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    trigger,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      status: "",
      catatan: "",
    },
    mode: "onChange",
  });

  const { response, loading, fetch } = useFetchData({
    action: getDetailMerchant,
    snackbar: false,
  });

  const verif = useFetchData({
    action: verifMerchant,
    message: `Berhasil ${isSuperVisor ? "Konfirmasi" : "Validasi"} data `,
    onSuccess: () => {
      navigate("/management-merchant/merchant");
    },
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const detailBisnis = useMemo(() => {
    const i = response?.DetailBisnis;
    return [
      {
        label: "Status",
        value: <ManagementMerchantStatus value={i?.Status} />,
      },
      {
        label: "Nama Bisnis",
        value: i?.NamaBisnis,
      },
      {
        label: "Kode Bisnis",
        value: i?.KodeBisnis,
      },
      {
        label: "Nama Pemilik Bisnis",
        value: i?.NamaBisnis,
      },
      {
        label: "Email",
        value: i?.Email,
      },
      {
        label: "Website",
        value: i?.Website,
      },
      {
        label: "Instagram",
        value: i?.Instagram,
      },
      {
        label: "Nomor Ponsel",
        value: i?.Phone,
      },
    ];
  }, [response?.DetailBisnis]);

  const InformasiBisnis = useMemo(() => {
    const i = response?.InformasiBisnis;
    return [
      {
        label: "Tipe Bisnis",
        value: i?.TipeBisnis,
      },
      {
        label: "Produk",
        value: i?.Produk,
      },
      {
        label: "Tipe Entitas Bisnis",
        value: i?.TipeEntitasBisnis,
      },
      {
        label: "Kategori Bisnis",
        value: i?.KategoriBisnis,
      },
      {
        label: "URL Bisnis",
        value: i?.URLBisnis,
      },
      {
        label: "Email PIC Finance",
        value: i?.EmailPICFinance,
      },
      {
        label: "No. Ponsel PIC Finance",
        value: i?.NoPICFinance,
      },
      {
        label: "Email PIC Teknis",
        value: i?.EmailPICTeknis,
      },
      {
        label: "No. Ponsel PIC Teknis",
        value: i?.NoPICTeknis,
      },
    ];
  }, [response?.InformasiBisnis]);

  const InformasiPemilik = useMemo(() => {
    const i = response?.InformasiPemilik;
    return [
      {
        label: "Alamat Pemilik",
        value: i?.AlamatPemilik,
      },
      {
        label: "Kewarganegaraan",
        value: String(i?.Kewarganegaraan || "-")?.toUpperCase() || "-",
      },
      {
        label: "Kode Pos",
        value: i?.KodePos,
      },
      {
        label: "No. Ponsel Pemilik",
        value: i?.NoPemilik,
      },
    ];
  }, [response?.InformasiPemilik]);

  const Dokumen = useMemo(() => {
    const i = response?.Dokumen;
    return [
      {
        label: "KTP Pemilik Bisnis",
        value: (
          <Box
            component="img"
            src={i?.KTP}
            sx={{
              width: "292px",
              height: "174px",
              objectFit: "contain",
            }}
          />
        ),
      },
      {
        label: "Nama Sesuai KTP",
        value: i?.NamaKTP,
      },
      {
        label: "Nomor KTP",
        value: i?.NomorKTP,
      },
      {
        label: "NPWP Badan Usaha",
        value: (
          <Box
            component="img"
            src={i?.NPWP}
            sx={{
              width: "292px",
              height: "174px",
              objectFit: "contain",
            }}
          />
        ),
      },
      {
        label: "Nama Sesuai NPWP",
        value: i?.NamaNPWP,
      },
      {
        label: "Nomor NPWP",
        value: i?.NomorNPWP,
      },
      {
        label: "Alamat Sesuai NPWP",
        value: i?.AlamatNPWP,
      },
    ];
  }, [response?.Dokumen]);

  const Bank = useMemo(() => {
    const i = response?.Bank;
    return [
      {
        label: "Nama Bank",
        value: i?.NamaRekening,
      },
      {
        label: "Nomor Rekening",
        value: i?.Rekening,
      },
      {
        label: "Nama Pemilik Rekening",
        value: i?.NamaPemilik,
      },
    ];
  }, [response?.Bank]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("catatan", data.catatan);
    formData.append("status", data.status);

    verif.fetch(id, formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const keputusanOperator = useMemo(() => {
    if (!isOperator) {
      return [
        {
          label: "Status",
          value: (
            <ManagementMerchantStatus value={response?.DetailBisnis?.Status} />
          ),
        },
        {
          label: "Pemberi Status",
          value: "-",
        },
        {
          label: "Tgl. Status",
          value: "-",
        },
        {
          label: "Catatan",
          value: response?.Catatan?.CatatanOperator,
        },
      ];
    }
  }, [isOperator, response]);

  const keputusanSupervisor = useMemo(() => {
    if (!isSuperVisor && !isOperator) {
      return [
        {
          label: "Status",
          value: (
            <ManagementMerchantStatus value={response?.DetailBisnis?.Status} />
          ),
        },
        {
          label: "Pemberi Status",
          value: "-",
        },
        {
          label: "Tgl. Status",
          value: "-",
        },
        {
          label: "Catatan",
          value: response?.Catatan?.CatatanSupervisor,
        },
      ];
    }
  }, [isOperator, isSuperVisor, response]);

  return (
    <>
      <Page
        title={`Detail ${response?.DetailBisnis?.NamaBisnis || ""}`}
        page={["Management Merchant"]}
        menu="Detail Bisnis"
        backBtn
        to="/management-merchant/merchant"
      />

      <Paper
        sx={{
          borderRadius: "16px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: 5,
          mt: 1,
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="title2">Detail Bisnis</Typography>
          {detailBisnis.map(({ label, value }, i) => (
            <MainGrid
              labelMd={3}
              valueMd={9}
              borderRadius={false}
              key={i}
              label={label}
              striped="odd"
              value={value ? value : "-"}
              center={label == "Status"}
            />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="title2">Informasi Bisnis</Typography>
          {InformasiBisnis.map(({ label, value }, i) => (
            <MainGrid
              labelMd={3}
              valueMd={9}
              borderRadius={false}
              key={i}
              striped="odd"
              label={label}
              value={value ? value : "-"}
            />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="title2">Informasi Pemilik</Typography>
          {InformasiPemilik.map(({ label, value }, i) => (
            <MainGrid
              labelMd={3}
              valueMd={9}
              borderRadius={false}
              key={i}
              striped="odd"
              label={label}
              value={value ? value : "-"}
            />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="title2">Dokumen</Typography>
          {Dokumen.map(({ label, value }, i) => (
            <MainGrid
              labelMd={3}
              valueMd={9}
              striped="odd"
              borderRadius={false}
              key={i}
              label={label}
              value={value ? value : "-"}
            />
          ))}
        </Box>
      </Paper>

      <Paper
        sx={{
          borderRadius: "16px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: 5,
          mt: 3,
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="title2">Detail Bank</Typography>
          {Bank.map(({ label, value }, i) => (
            <MainGrid
              labelMd={3}
              valueMd={9}
              striped="odd"
              borderRadius={false}
              key={i}
              label={label}
              value={value ? value : "-"}
            />
          ))}
        </Box>
      </Paper>

      {response?.Catatan?.CatatanOperator ? (
        <Paper
          sx={{
            borderRadius: "16px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            mt: 3,
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="title2">Keputusan Operator</Typography>
            {keputusanOperator.map((item, i) => {
              return (
                <MainGrid
                  key={i}
                  striped="odd"
                  labelMd={3}
                  center
                  valueMd={9}
                  label={item.label}
                  value={item.value}
                />
              );
            })}
          </Box>
        </Paper>
      ) : null}

      {response?.Catatan?.CatatanSupervisor ? (
        <Paper
          sx={{
            borderRadius: "16px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            mt: 3,
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="title2">Keputusan Supervisor</Typography>
            {keputusanSupervisor.map((item, i) => {
              return (
                <MainGrid
                  key={i}
                  striped="odd"
                  labelMd={3}
                  center
                  valueMd={9}
                  label={item.label}
                  value={item.value}
                />
              );
            })}
          </Box>
        </Paper>
      ) : null}

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Paper
          sx={{
            borderRadius: "16px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            mt: 3,
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="title2">Catatan</Typography>
            <MainController
              {...getProps("catatan")}
              rules={{
                required: "Catatan wajib disii",
              }}
              render={({ field, fieldState: { invalid } }) => (
                <OutlinedInput
                  {...field}
                  multiline
                  rows={6}
                  variant="v1"
                  error={invalid}
                  placeholder="Cth: warna rambut berbeda"
                />
              )}
            />
          </Box>
        </Paper>

        {isSuperVisor || isOperator ? (
          <Paper
            sx={{
              borderRadius: "16px",
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 5,
              mt: 3,
            }}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="title2">Keputusan</Typography>
              <Box display="flex" gap={1}>
                <MainController
                  {...getProps("status")}
                  rules={{ required: "Keputusan wajib diisi" }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Autocomplete
                      variant="v1"
                      error={invalid}
                      {...field}
                      options={
                        isSuperVisor
                          ? keputusanDataSupervisor
                          : keputusanDataOperator
                      }
                      value={
                        isSuperVisor
                          ? keputusanDataSupervisor.find(
                              (item) => item.value === field.value
                            ) || null
                          : keputusanDataOperator.find(
                              (item) => item.value === field.value
                            ) || null
                      }
                      placeholder="Pilih keputusan akhir"
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(_, nv) => {
                        if (nv == null) {
                          field.onChange(1);
                        }
                        field.onChange(nv.value);
                      }}
                    />
                  )}
                />
                <Button
                  variant="primary"
                  sx={{
                    minWidth: "140px",
                  }}
                  disabled={!isValid || verif.loading}
                  type="submit"
                >
                  {isSuperVisor ? "Konfirmasi" : "Validasi"}
                </Button>
              </Box>
            </Box>
          </Paper>
        ) : null}
      </Box>
    </>
  );
};

export default ManagementMerchantDetail;
