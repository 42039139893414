import React from "react";

const CameraIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.25C3 8.69772 3.44772 8.25 4 8.25H8.63008C8.86879 8.25 9.09962 8.16461 9.28087 8.00926L13.9691 3.99074C14.1504 3.83539 14.3812 3.75 14.6199 3.75H18H21.3801C21.6188 3.75 21.8496 3.83539 22.0309 3.99074L26.7191 8.00926C26.9004 8.16461 27.1312 8.25 27.3699 8.25H32C32.5523 8.25 33 8.69772 33 9.25V31.25C33 31.8023 32.5523 32.25 32 32.25H4C3.44772 32.25 3 31.8023 3 31.25V9.25ZM10.5 20.25C10.5 16.1079 13.8579 12.75 18 12.75C22.1421 12.75 25.5 16.1079 25.5 20.25C25.5 24.3921 22.1421 27.75 18 27.75C13.8579 27.75 10.5 24.3921 10.5 20.25ZM22.5 20.25C22.5 17.7647 20.4853 15.75 18 15.75C15.5147 15.75 13.5 17.7647 13.5 20.25C13.5 22.7353 15.5147 24.75 18 24.75C20.4853 24.75 22.5 22.7353 22.5 20.25ZM30 11.25H25.5V14.25H30V11.25Z"
        fill="#D8D8D8"
      />
      <mask
        id="mask0_2214_1174"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="30"
        height="30"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 9.25C3 8.69772 3.44772 8.25 4 8.25H8.63008C8.86879 8.25 9.09962 8.16461 9.28087 8.00926L13.9691 3.99074C14.1504 3.83539 14.3812 3.75 14.6199 3.75H18H21.3801C21.6188 3.75 21.8496 3.83539 22.0309 3.99074L26.7191 8.00926C26.9004 8.16461 27.1312 8.25 27.3699 8.25H32C32.5523 8.25 33 8.69772 33 9.25V31.25C33 31.8023 32.5523 32.25 32 32.25H4C3.44772 32.25 3 31.8023 3 31.25V9.25ZM10.5 20.25C10.5 16.1079 13.8579 12.75 18 12.75C22.1421 12.75 25.5 16.1079 25.5 20.25C25.5 24.3921 22.1421 27.75 18 27.75C13.8579 27.75 10.5 24.3921 10.5 20.25ZM22.5 20.25C22.5 17.7647 20.4853 15.75 18 15.75C15.5147 15.75 13.5 17.7647 13.5 20.25C13.5 22.7353 15.5147 24.75 18 24.75C20.4853 24.75 22.5 22.7353 22.5 20.25ZM30 11.25H25.5V14.25H30V11.25Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2214_1174)">
        <rect width="36" height="36" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default CameraIcon;
