import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const MainCard2 = ({
  title,
  children,
  borderColor,
  margin,
  custom,
  ...props
}) => {
  return (
    <Paper
      sx={{
        mt: margin ? 2 : 0,
        borderRadius: "8px",
        minHeight: "60px",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "64px",
          pl: 2,
        }}
      >
        <Typography variant={"h2"}>{title}</Typography>
        {custom}
      </Box>
      <Divider
        sx={{
          borderColor: borderColor,
        }}
      />
      {children}
    </Paper>
  );
};

MainCard2.propTypes = {
  children: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  borderColor: PropTypes.string,
  sx: PropTypes.any,
  margin: PropTypes.bool,
  custom: PropTypes.any,
};

MainCard2.defaultProps = {
  children: null,
  title: "-",
  sx: null,
  borderColor: "#f7f7f7",
  margin: false,
  custom: null,
};
export default MainCard2;
