import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, post, put, get, destroy } from "api";
import { DigitalID } from "api/endpoints";

export const getListDigitalID = createAsyncThunk(
  "pengguna/getListDigitalID",
  async ({ params, type }) => {
    let url;
    switch (type) {
      case "operator":
        url = DigitalID.listOperation;
        break;
      case "supervisor":
        url = DigitalID.listSupervisor;
        break;
      case "superadmin":
        url = DigitalID.listSuperadmin;
        break;
      default:
        url = DigitalID.listOperation;
        break;
    }

    const response = await getParams(url, params);
    return response.data;
  }
);

export const getDigitalID = async ({ params, type }) => {
  let url;
  switch (type) {
    case "operator":
      url = DigitalID.listOperation;
      break;
    case "supervisor":
      url = DigitalID.listSupervisor;
      break;
    case "superadmin":
      url = DigitalID.listSuperadmin;
      break;
    default:
      url = DigitalID.listOperation;
      break;
  }

  const response = await getParams(url, params);
  return response;
};

export const getDetailAdjudicator = async (id) => {
  const response = await get(`${DigitalID.detailAdjudicator}/${id}`);

  return response;
};

export const editAjudicator = async (id, body) => {
  let url = DigitalID.adjudicator + `/${id}` + "/validation";
  const response = await put(url, body);

  return response;
};

export const getDesaList = async (body) => {
  let url = `${DigitalID.masterData}/desa`;

  const params = {
    limit: 100,
    page: 1,
    search: body,
  };

  const response = await getParams(url, params);

  return response;
};

export const getKecamatanList = async (body) => {
  let url = `${DigitalID.masterData}/kecamatan`;

  const params = {
    limit: 100,
    page: 1,
    search: body,
  };

  const response = await getParams(url, params);

  return response;
};

export const getKotaList = async (body) => {
  let url = `${DigitalID.masterData}/kabupaten`;

  const params = {
    limit: 100,
    page: 1,
    search: body,
  };

  const response = await getParams(url, params);

  return response;
};

export const getProvinsiList = async (body) => {
  let url = `${DigitalID.masterData}/provinsi`;

  const params = {
    limit: 100,
    page: 1,
    search: body,
  };

  const response = await getParams(url, params);

  return response;
};
