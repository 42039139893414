import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SidebarOpen from "assets/layout/sidebarOpen";
import SidebarClose from "assets/layout/sidebarClose";
import { AppBar } from "./style";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { getCookie, removeCookie } from "helper/cokkie";
import { useDispatch, useSelector } from "react-redux";
import { getPhoto, getProfile } from "redux/profile/action";
import { Avatar, Drawer, Typography, Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery, useTheme } from "@mui/material";
import { alert, open } from "redux/global/slice";
import { ScrollRestoration } from "react-router-dom";
import { getIpAddress } from "redux/auth/actions";
import SidebarProfile from "./SidebarProfile";
import BarIcon from "assets/paper/BarIcon";
import Logo from "assets/layout/logo";
import LogoSmall from "assets/layout/logoSmall";

const isLogin = getCookie("token-ntt-webadmin");
const Layout = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const inXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState({
    profile: true,
    userProfile: false,
    changePassword: false,
  });

  const [openRight, setOpenRight] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const { sidebar } = useSelector((state) => state.global);
  const {
    photo,
    profile: { Fullname, role, Email },
  } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!isLogin) {
    return window.location.replace("/auth/login");
  }

  useEffect(() => {
    let str = JSON.parse(localStorage.getItem("sidebar"));

    if (str == null) {
      localStorage.setItem("sidebar", true);
      dispatch(open(true));
    } else {
      dispatch(open(str));
    }
  }, []);

  useEffect(() => {
    let isRegistered = JSON.parse(localStorage.getItem("profile-ntt"));
    if (isRegistered?.bussinesRegistered == 1) {
      return navigate("/bussines-registration");
    }

    dispatch(getProfile());
    dispatch(getPhoto());
    // getIpAddress();

    let token = localStorage.getItem("token-ntt-webadmin");
    let account;
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      account = JSON.parse(jsonPayload);

      localStorage.setItem(
        "permission-ntt",
        JSON.stringify(account.Permission)
      );
    }
  }, []);

  const Logout = () => {
    removeCookie("token-ntt-webadmin");
    // navigate("/auth/login");
    dispatch(
      alert({
        open: true,
        message: "Logout Berhasil",
      })
    );
    localStorage.removeItem("token-ntt-webadmin");
    localStorage.removeItem("profile-ntt");
    localStorage.removeItem("merchant-id-ntt");
    return window.location.replace("/auth/login");
  };

  const handleOpenSidebar = () => {
    if (!inXS) {
      dispatch(open());
      localStorage.setItem("sidebar", !sidebar);
    } else {
      setOpenSidebar(!openSidebar);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          open={!inXS ? sidebar : openSidebar}
          match={match ? 1 : 0}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: {
                lg: "75px",
              },
              paddingRight: "5px",
              zIndex: "200",
            }}
          >
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => handleOpenSidebar()}
            >
              {!inXS && <>{sidebar ? <SidebarOpen /> : <SidebarClose />}</>}
              {inXS && <BarIcon />}
            </IconButton>

            <Box
              sx={{
                display: {
                  md: "none",
                  xs: "flex",
                },
              }}
              alignItems="center"
              gap={1}
            >
              <LogoSmall />
              <Typography
                variant="sidebarTitle"
                sx={{
                  position: "relative",
                  fontSize: {
                    xs: "16px",
                  },
                  "&:after": {
                    content: `""`,
                    width: "40px",
                    backgroundColor: "#cc1d15",
                    height: "1px",
                    position: "absolute",
                    bottom: 1,
                    display: "block",
                  },
                }}
              >
                INISA Admin
              </Typography>
            </Box>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                display: "flex",
                // justifyContent: "space-evenly",
                gap: "20px",
                minWidth: {
                  lg: "200px",
                  xs: "0px",
                },
              }}
              onClick={() => {
                setOpenRight(true);
                setPage({
                  profile: true,
                  userProfile: false,
                  changePassword: false,
                });
              }}
            >
              <Avatar src={photo} />
              <Box
                sx={{
                  display: {
                    lg: "flex",
                    xs: "none",
                  },
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <Typography variant="textMedium">{Fullname || "-"}</Typography>
                <Typography variant="textMediumV2">{role || "-"}</Typography>
              </Box>
              <KeyboardArrowDownIcon
                sx={{
                  color: "#3F4144",
                  display: {
                    lg: "flex",
                    xs: "none",
                  },
                }}
              />
            </Button>
          </Toolbar>
        </AppBar>
        <Sidebar
          open={!inXS ? sidebar : openSidebar}
          setOpen={() => {
            if (!inXS) {
              dispatch(open());
            } else {
              setOpenSidebar(!openSidebar);
            }
          }}
        />

        <Box
          component="main"
          sx={{
            flexGrow: "1",
            paddingTop: {
              xs: "80px",
              md: "104px",
            },
            overflowX: "hidden",
            backgroundColor: "#f6f6f6",
          }}
        >
          <Box
            sx={{
              minHeight: {
                md: "calc(100vh - 190px)",
                xs: "calc(100vh - 80px)",
              },
              paddingLeft: match ? "13px" : "32px",
              paddingRight: match ? "13px" : "32px",
              paddingBottom: {
                xs: "50px",
                md: "0px",
              },
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
              height: "50px",
              backgroundColor: "#fff",
              marginTop: "30px",
              justifyContent: "space-between",
              px: 3,
              alignItems: "center",
            }}
          >
            <Typography variant="textMedium">
              V{process.env.REACT_APP_APP_VERSION}
            </Typography>
            <Typography variant="textMedium">
              2022 © &nbsp;
              <a
                href="https://www.inisa.id/"
                target="_blank"
                style={{ color: "#cc1d15", fontFamily: "inherit" }}
                rel="noreferrer"
              >
                INISA
              </a>
              &nbsp;| PT Digital Pemerintahan Indonesia
            </Typography>
          </Box>
        </Box>
      </Box>

      <SidebarProfile
        openRight={openRight}
        setOpenRight={setOpenRight}
        logout={Logout}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

export default Layout;
