import { createAsyncThunk } from "@reduxjs/toolkit";
import { destroy, get, getParams, post, put } from "api";
import { Broadcast } from "api/endpoints";

export const getListBroadcastSMS = createAsyncThunk(
  "broadcast/getListBroadcastSMS",
  async (params) => {
    const response = await getParams(Broadcast.smsBroadcastGet, params);
    return response.data.data;
  }
);

export const exportBroadcastSMS = async (params) => {
  const response = await getParams(Broadcast.smsBroadcastGet, params);
  return response;
};

export const createBroadcastSMS = async (data) => {
  const response = await post(Broadcast.smsBroadcastGet, data);
  return response;
};

export const updateBroadcastSMS = async (data) => {
  const response = await put(Broadcast.smsBroadcastAction, data);
  return response;
};

export const cancelBroadcastSMS = async (id) => {
  const response = await destroy(`${Broadcast.smsBroadcastGet}/${id}`);
  return response;
};

export const detailBroadcastSMS = async (id) => {
  const response = await get(`${Broadcast.smsBroadcastGet}/${id}`);
  return response;
};

export const resendBroadcastSMS = async (id) => {
  const response = await get(`${Broadcast.smsBroadcastResend}/${id}`);
  return response;
};

export const getListPushNotif = createAsyncThunk(
  "broadcast/getListPushNotif",
  async (params) => {
    const response = await getParams(Broadcast.notifBroadcastGet, params);
    return response.data.data;
  }
);

export const cancelNotifBroadcast = (id) => async () => {
  const withId = `${Broadcast.notifBroadcastGet}/${id}`;
  const response = await destroy(withId);
  return response;
};

export const notifBroadcastDetail = async (id) => {
  const withId = `${Broadcast.notifBroadcastGet}/${id}`;
  const response = await get(withId);
  return response;
};

export const createNotifBroadcast = async (data) => {
  const response = await post(Broadcast.notifBroadcastGet, data);
  return response;
};

export const getListEmailBroadcast = createAsyncThunk(
  "broadcast/getListEmail",
  async (params) => {
    const response = await getParams(Broadcast.emailBroadcastGet, params);
    return response.data.data;
  }
);

export const createEmailBroadcast = async (data) => {
  const response = await post(Broadcast.emailBroadcastGet, data);
  return response;
};

export const getEmailBroadcastTemplateAll = async (param) => {
  const response = await getParams(Broadcast.emailBroadcastTemplateGet, param);
  return response;
};

export const cancelEmailBroadcast = (id) => async () => {
  const withId = `${Broadcast.emailBroadcastGet}/${id}`;
  const response = await destroy(withId);
  return response;
};

export const emailBroadcastDetail = async (id) => {
  const withId = `${Broadcast.emailBroadcastGet}/${id}`;
  const response = await get(withId);
  return response;
};

export const getListEmailMarketing = createAsyncThunk(
  "broadcast/getListEmailMarketing",
  async (params) => {
    const response = await getParams(Broadcast.marketing, params);
    return response.data.data;
  }
);

export const getMarketingUnsub = async () => {
  let url = Broadcast.marketingUnsub;
  const response = await get(url);
  return response;
};

export const createEmailMarketing = (data) => async () => {
  const response = await apiPost2(Broadcast.marketingCreate, data);
  return response;
};

export const getMarketingDesign = async () => {
  let url = Broadcast.marketingDesign;
  const response = await get(url);
  return response;
};

export const getMarketingDesignDetail = async (id) => {
  let url = `${Broadcast.marketingDesignDetail}/${id}`;
  const response = await get(url);
  return response;
};
