import KTPSlice from "redux/kependudukan/KTP/slice";
import KKSlice from "redux/kependudukan/KK/slice";
import AktaLahirSlice from "redux/kependudukan/AktaLahir/slice";

const kependudukanReducer = {
  kependudukanKTP: KTPSlice,
  kependudukanKK: KKSlice,
  kependudukanAktaLahir: AktaLahirSlice,
};

export default kependudukanReducer;
