import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const CircleIcon = ({ icon: Icon }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#cc1d15",
        outline: "2px solid #cc1d15",
        outlineOffset: "2px",
        p: 1,
        my: "auto",
        display: "flex",
        width: "fit-content",
        borderRadius: "40px",
      }}
    >
      <Icon />
    </Box>
  );
};

CircleIcon.propTypes = {
  icon: PropTypes.any,
};

CircleIcon.defaultProps = {
  icon: null,
};

export default CircleIcon;
