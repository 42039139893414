import { Box } from "@mui/material";
import React from "react";
import LoaderIcon from "assets/loader/LoaderIcon";

const ProsesPembayaranLoader = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoaderIcon />
    </Box>
  );
};

export default ProsesPembayaranLoader;
