import Date from "assets/cms/formulir/Date";
import Email from "assets/cms/formulir/Email";
import MultiSelect from "assets/cms/formulir/MultiSelect";
import Number from "assets/cms/formulir/Number";
import Password from "assets/cms/formulir/Password";
import Select from "assets/cms/formulir/Select";
import Time from "assets/cms/formulir/Time";
import SingleLine from "assets/cms/formulir/SingleLine";

export const listItems = [
  {
    id: 1,
    name: "Kolom Satu Baris",
    type: "single-line",
    description: "Isi data teks dalam satu baris",
    icon: SingleLine,
  },
  {
    id: 2,
    name: "Kolom Multi Baris",
    type: "multiple-line",
    description: "Isi data teks dalam multi baris",
    icon: MultiSelect,
  },
  {
    id: 3,
    name: "Angka",
    type: "number",
    description: "Bilangan bulat atau desimal",
    icon: Number,
  },
  {
    id: 4,
    name: "Tanggal",
    type: "date",
    description: "Tanggal",
    icon: Date,
  },
  {
    id: 10,
    name: "Waktu",
    type: "time",
    description: "Jam, menit dan detik",
    icon: Time,
  },
  {
    id: 5,
    name: "Email",
    type: "email",
    description: "Dengan format yg diterima",
    icon: Email,
  },
  {
    id: 6,
    name: "Kata Sandi",
    type: "password",
    description: "Dengan enkripsi",
    icon: Password,
  },
  {
    id: 7,
    name: "Tombol Radio",
    type: "select",
    description: "Dropdown or list options",
    icon: Select,
  },
  {
    id: 8,
    name: "Kotak Centang",
    type: "multi-select",
    description: "Plihan lebih dari satu",
    icon: MultiSelect,
  },
  {
    id: 9,
    name: "Media",
    type: "media",
    description: "Berkas, gambar, video dll",
    icon: Email,
  },
  {
    id: 10,
    name: "Foto",
    type: "media-capture",
    description: "Ambil foto dengan kamera",
    icon: Email,
  },
];

export const initialData = Object.freeze({
  form_title: "",
  forms: {},
  detail_sections: {
    "section-1": {
      id: "section-1",
      title: "",
      description: "",
      forms: [],
    },
  },
  sections: ["section-1"],
});

export const properties = {
  "single-line": {
    type: "single-line",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
      batasi_karakter: false,
      primary_key: false,
    },
  },
  "multiple-line": {
    type: "multiple-line",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
      batasi_karakter: false,
      pencatatan: false,
    },
  },
  date: {
    type: "date",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
    },
    format: {
      date: {
        option: [
          { value: "dd/MM/yyyy", label: "DD/MM/YYYY" },
          { value: "MM/dd/yyyy", label: "MM/DD/YYYY" },
          { value: "yyyy/MM/dd", label: "YYYY/MM/DD" },
          { value: "yyyy/dd/MM", label: "YYYY/DD/MM" },
          { value: "dd-MM-yyyy", label: "DD-MM-YYYY" },
          { value: "MM-dd-yyyy", label: "MM-DD-YYYY" },
          { value: "yyyy-MM-dd", label: "YYYY-MM-DD" },
          { value: "yyyy-dd-MM", label: "YYYY-DD-MM" },
        ],
        value: { value: "dd/MM/yyyy", label: "DD/MM/YYYY" },
      },
    },
    value: null,
  },
  time: {
    type: "time",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
    },
    format: {
      time: {
        option: [
          { value: "HH:mm", label: "24 Hours" },
          { value: "hh:mm", label: "AM/PM" },
          {
            value: "HH:mm:ss",
            label: "24 Hours with seconds",
          },
          {
            value: "hh:mm:ss",
            label: "AM/PM with seconds",
          },
        ],
        value: { value: "hh:mm", label: "AM/PM" },
      },
    },
    value: null,
  },
  number: {
    type: "number",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
      primary_key: false,
      batasi_karakter: false,
    },
    format: {
      is_negative: false,
      option: [
        {
          label: "Bilangan Bulat",
          value: (input = "", is_negative) => {
            if (is_negative) {
              let val = input.replace(/[^0-9]/g, "");
              return input.startsWith("-") ? `-${val}` : `${val}`;
            } else {
              return input.replace(/[^0-9]/g, "");
            }
          },
        },
        {
          label: "Bilangan Desimal",
          value: (input = "", is_negative) => {
            let inputTemp = input.replace(/[^0-9.]/g, "");
            let val = parseFloat(inputTemp);
            let res = inputTemp.endsWith(".")
              ? inputTemp
              : isNaN(val)
              ? inputTemp.substring(inputTemp.length - 1)
              : val;

            if (is_negative) {
              return input.startsWith("-") ? `-${res}` : `${res}`;
            } else return res;
          },
        },
      ],
      value: {
        label: "Bilangan Bulat",
        value: (input = "", is_negative) => {
          if (is_negative) {
            let val = input.replace(/[^0-9]/g, "");
            return input.startsWith("-")
              ? -Math.abs(parseInt(val))
              : parseInt(val);
          } else {
            return input.replace(/[^0-9]/g, "");
          }
        },
      },
    },
  },
  email: {
    type: "email",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
      primary_key: false,
    },
  },
  password: {
    type: "password",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
      primary_key: false,
    },
  },
  media: {
    type: "media",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
      primary_key: false,
    },
  },
  select: {
    type: "select",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
    },
    format: {
      type: "dropdown",
    },
    option: [
      { label: "Opsi 1", id: 1 },
      { label: "Opsi 2", id: 2 },
      { label: "Opsi 3", id: 3 },
    ],
    value: "2b9282b1-6cab-422b-bb6c-2c57a56fb33e",
  },
  "multi-select": {
    type: "multi-select",
    property: {
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
    },
    tampilan: {
      deskripsi: false,
      wajib_diisi: true,
    },
    format: {
      type: "dropdown",
    },
    option: [
      {
        label: "Opsi 1",
        id: 1,
        selected: false,
      },
      {
        label: "Opsi 2",
        id: 2,
        selected: false,
      },
      {
        label: "Opsi 3",
        id: 3,
        selected: false,
      },
    ],
  },
  "media-capture": {
    type: "media-capture",
    property: {
      name: {
        type: "text",
        value: "",
        placeholder: "Edit name",
      },
      ilustrasi_panduan: {
        type: "text",
        value: "",
        placeholder: "Edit ",
      },
      label: {
        type: "text",
        value: "",
        placeholder: "Edit label",
      },
      keterangan: {
        type: "text",
        value: "Foto",
        placeholder: "Tambah keterangan kolom",
      },
      deskripsi: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi kolom",
      },
      judul_panduan: {
        type: "text",
        value: "",
        placeholder: "Edit judul panduan  kolom",
      },
      deskripsi_panduan: {
        type: "text",
        value: "",
        placeholder: "Edit deskripsi panduan kolom",
      },
      panduan: {
        type: "text",
        value: [],
        placeholder: "Edit panduan kolom ( Enter )",
      },
    },
    tampilan: {
      ocr: false,
      liveness: false,
      deskripsi: false,
      is_card: true,
      wajib_diisi: true,
      panduan: true,
    },
  },
};

export const generateId = (data) => {
  let idBig = 0;
  Object.keys(data).forEach((formId) => {
    let currentForm = data[formId];
    let currentId = currentForm.id.replace(/[^0-9]/g, "");
    if (parseInt(currentId) > idBig) {
      idBig = currentId;
    }
  });
  const lastNumber = parseInt(idBig) + 1;
  const newInputId = !lastNumber ? `form-1` : `form-${parseInt(lastNumber)}`;

  return newInputId;
};

export const generateSection = (data) => {
  const copyState = { ...data };
  const { [Object.keys(copyState.sections).pop()]: lastItem } =
    copyState.sections;
  const lastNumber = parseInt(lastItem?.split("-")[1]);
  const newSection = !lastNumber
    ? `section-1`
    : `section-${parseInt(lastNumber) + 1}`;

  return newSection;
};

export const getNumberVal = (label, input, is_negative) => {
  if (label === "Bilangan Bulat") {
    if (is_negative) {
      let val = input.replace(/[^0-9]/g, "");
      return input.startsWith("-") ? `-${val}` : `${val}`;
    } else {
      return input.replace(/[^0-9]/g, "");
    }
  } else if (label === "Bilangan Desimal") {
    let inputTemp = input.replace(/[^0-9.]/g, "");
    // let onlyDot = inputTemp.replace(/[^.]/g, "");
    let val = parseFloat(inputTemp);
    let res = inputTemp.endsWith(".")
      ? inputTemp
      : isNaN(val)
      ? inputTemp.substring(inputTemp.length - 1)
      : val;

    if (is_negative) {
      return input.startsWith("-") ? `-${res}` : `${res}`;
    } else return res;
  }
};

export const generateValue = (newId, item) => {
  const newProperty = { ...properties[item.type] };
  return {
    id: newId,
    content: [
      {
        id: newId + "-1",
        ...newProperty,
        ...item,
      },
    ],
  };
};

export const generateValueLeft = (newId, item) => {
  return {
    id: newId,
    content: [
      {
        id: newId + "-1",
        ...properties?.[item.type],
        ...item,
      },
      {
        id: newId + "-2",
      },
    ],
  };
};

export const generateValueRight = (newId, item) => {
  return {
    id: newId,
    content: [
      {
        id: newId + "-1",
      },
      {
        id: newId + "-2",
        ...properties?.[item.type],
        ...item,
      },
    ],
  };
};
