import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "redux/global/slice";
import profileReducer from "redux/profile/slice";
import voucherReducer from "./voucher";
import subsidiBansosReducer from "./subsidiBansos";
import penerimaReducer from "./penerimaBantuan";
import voucherBansosReducer from "./voucherBansos";
import kartuSubsidiReducer from "./kartuSubsidi";
import penerbitReducer from "./penerbit";
import penggunaReducer from "./managementPengguna";
import authReducer from "./auth";
import sentraLokasiReducer from "./sentraLokasi";
import kependudukanReducer from "./kependudukan";
import hargaProdukPPOBReducer from "./hargaProdukPPOB";
import digitalIDReducer from "./digitalID";
import laporanTransaksiReducer from "./laporanTransaksi";
import managementMerchantReducer from "./managementMerchant";
import pangaturanReducer from "./pengaturan";
import broadcastReducer from "./broadcast";
import CMSReducer from "./CMS";

export default configureStore({
  reducer: {
    global: globalReducer,
    profile: profileReducer,
    ...penerimaReducer,
    ...subsidiBansosReducer,
    ...voucherBansosReducer,
    ...voucherReducer,
    ...kartuSubsidiReducer,
    ...penerbitReducer,
    ...penggunaReducer,
    ...authReducer,
    ...sentraLokasiReducer,
    ...kependudukanReducer,
    ...hargaProdukPPOBReducer,
    ...digitalIDReducer,
    ...laporanTransaksiReducer,
    ...managementMerchantReducer,
    ...pangaturanReducer,
    ...broadcastReducer,
    ...CMSReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
