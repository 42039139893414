import React, { useEffect } from "react";
import {
  getKecamatan,
  getKelurahan,
  getKota,
  getProvinsi,
} from "redux/region/action";
import useFetchData from "./useFetchData";

const useGetRegion = ({
  provinsi_id,
  kota_id,
  kecamatan_id,
  kelurahan_id,
  onSuccessProvinsi,
  onSuccessKota,
  onSuccessKecamatan,
  onSuccessKelurahan,
}) => {
  const provinsi = useFetchData({
    action: getProvinsi,
    snackbar: false,
    onSuccess: (data) => onSuccessProvinsi(data),
  });

  const kota = useFetchData({
    action: getKota,
    snackbar: false,
    onSuccess: (data) => onSuccessKota(data),
  });

  const kecamatan = useFetchData({
    action: getKecamatan,
    snackbar: false,
    onSuccess: (data) => onSuccessKecamatan(data),
  });

  const kelurahan = useFetchData({
    action: getKelurahan,
    snackbar: false,
    onSuccess: (data) => onSuccessKelurahan(data),
  });

  useEffect(() => {
    if (provinsi_id) {
      provinsi.fetch();
    }
  }, [provinsi_id]);

  useEffect(() => {
    if (provinsi_id) {
      kota.fetch(provinsi_id);
    }
  }, [kota_id]);

  useEffect(() => {
    if (kota_id) {
      kecamatan.fetch(kota_id);
    }
  }, [kecamatan_id]);

  useEffect(() => {
    if (kecamatan_id) {
      kelurahan.fetch(kecamatan_id);
    }
  }, [kelurahan_id]);

  return { provinsi, kota, kecamatan, kelurahan };
};

export default useGetRegion;
