import React from "react";

const Comment = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5694_15355)">
        <path
          d="M3 22C2.87 22 2.74 21.98 2.62 21.92C2.25 21.77 2 21.4 2 21V5C2 3.35 3.35 2 5 2H19C20.65 2 22 3.35 22 5V15C22 16.65 20.65 18 19 18H7.41L3.7 21.71C3.51 21.9 3.25 22 2.99 22H3ZM5 4C4.45 4 4 4.45 4 5V18.59L6.29 16.3C6.48 16.11 6.73 16.01 7 16.01H19C19.55 16.01 20 15.56 20 15.01V5C20 4.45 19.55 4 19 4H5Z"
          fill="#4C4D4F"
        />
        <path
          d="M15.71 12.53C16.1 12.92 16.1 13.55 15.71 13.94C15.51 14.14 15.26 14.24 15 14.24C14.74 14.24 14.49 14.14 14.29 13.94L12 11.65L9.71 13.94C9.51 14.14 9.26 14.24 9 14.24C8.74 14.24 8.49 14.14 8.29 13.94C7.9 13.55 7.9 12.92 8.29 12.53L10.59 10.24L8.29 7.94005C7.9 7.55005 7.9 6.92005 8.29 6.53005C8.68 6.14005 9.32 6.14005 9.71 6.53005L12 8.82005L14.29 6.53005C14.68 6.14005 15.32 6.14005 15.71 6.53005C16.1 6.92005 16.1 7.55005 15.71 7.94005L13.42 10.24L15.71 12.53Z"
          fill="#4C4D4F"
        />
      </g>
      <defs>
        <clipPath id="clip0_5694_15355">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Comment;
