import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  0: {
    label: "Entri",
    background: "#f7efd9",
    color: "#ff6f05",
  },
  1: {
    label: "Berhasil",
    background: "#e9f2e9",
    color: "#2c8424",
  },
  2: {
    label: "Batal",
    background: "#f5e3e3",
    color: "#eb5151",
  },
  3: {
    label: "Dibayar",
    background: "#eb5151",
    color: "#0c6ce2",
  },
  4: {
    label: "Refund",
    background: "#ececec",
    color: "#4e5d78",
  },
  5: {
    label: "Dikirim",
    background: "#e9f2e9",
    color: "#2c8424",
  },
  6: {
    label: "Tertunda",
    background: "#e9f2e9",
    color: "#ff6f05",
  },
  7: {
    label: "Pembayaran Berhasil",
    background: "#e9f2e9",
    color: "#2c8424",
  },
  8: {
    label: "Refund Gagal",
    background: "#ececec",
    color: "#4e5d78",
  },
  9: {
    label: "Menunggu Pembayaran",
    background: "#fef7e7",
    color: "#e5ba4c",
  },
  10: {
    label: "Gagal Setelah Dibayar",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
  11: {
    label: "Setengah Dibayar",
    background: "#f7efd9",
    color: "#ff6f05",
  },
  12: {
    label: "Dihapus",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
};

const LaporanTransaksiStatus = ({ value, ...props }) => {
  let selected = color[value];

  return (
    <StatusChip {...props} {...selected} minWidth="190px" maxWidth="190px" />
  );
};

LaporanTransaksiStatus.defaultProps = {
  value: 0,
};

LaporanTransaksiStatus.propTypes = {
  value: PropTypes.any,
};

export default LaporanTransaksiStatus;
