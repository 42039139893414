import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, put } from "api";
import { ManagementMerchant } from "api/endpoints";

export const getListManagementMerchant = createAsyncThunk(
  "merchant/getListMerchant",
  async (params) => {
    const response = await getParams(ManagementMerchant.merchantList, params);
    return response.data.data;
  }
);

export const getManagementMerchant = async () => {
  const response = await getParams(ManagementMerchant.merchantList);
  return response;
};

export const getDetailMerchant = async (id) => {
  const response = await getParams(
    `${ManagementMerchant.merchantDetail}/${id}`
  );
  return response;
};

export const verifMerchant = async (id, data) => {
  const response = await put(
    `${ManagementMerchant.merchantAction}/${id}`,
    data
  );
  return response;
};
