import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import Page from "components/Page";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import FormatRupiah from "helper/FormatRupiah";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { detailHargaProduk } from "redux/hargaProdukPPOB/action";
import Dialog from "./Dialog";

const DetailHargaProdukPPOB = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { fetch, response, loading } = useFetchData({
    action: detailHargaProduk,
    snackbar: false,
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const data = useMemo(() => {
    const i = response;
    return [
      {
        label: "Kode Produk",
        value: i?.pCode,
      },
      {
        label: "Nama Produk",
        value: i?.pName,
      },
      {
        label: "Harga Jual",
        value: `Rp. ${FormatRupiah(i?.pJual)}`,
      },
      {
        label: "Harga Biller",
        value: `Rp. ${FormatRupiah(i?.pPrice)}`,
      },
      {
        label: "Tanggal Diubah",
        value: moment(i?.UpdatedAt).isValid()
          ? moment(i?.UpdatedAt).format("DD / MM / YYYY")
          : "-",
      },
      {
        label: "Diubah Oleh",
        value: i?.updatedBy,
      },
      {
        label: "Status",
        value: <AktifStatus value={i?.pActive} />,
      },
    ];
  }, [response]);

  const detailDataDialog = useMemo(() => {
    return {
      id: response?.Id,
      kodeProduk: response?.pCode,
      namaProduk: response?.pName,
      hargaJual: response?.pJual,
      hargaBiller: response?.pPrice,
      status: response?.pActive,
    };
  }, [response]);

  return (
    <>
      <Page
        title={`Detail Harga “${response?.pName || ""}”`}
        page={["Master Data", "Harga Produk PPOB"]}
        backBtn
        menu="Detail"
      />
      <MainCard
        title="Informasi Harga Produk"
        addButton={false}
        isEdit
        onClick={() => setOpen(true)}
      >
        <MainLoading loading={loading}>
          {data.map((item, i) => (
            <MainGrid {...item} striped="odd" key={i} center={item.label == "Status"} />
          ))}
        </MainLoading>
      </MainCard>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        refresh={() => fetch(id)}
        data={detailDataDialog}
        type={response?.pType}
      />
    </>
  );
};

export default DetailHargaProdukPPOB;
