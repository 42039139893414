import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NavItem from "../NavItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router-dom";
import RoleProviders from "components/RoleProviders";

const NavCollapse = ({ item, level, openSidebar, setOpen: closeSidebar }) => {
  const [open, setOpen] = useState({
    menuId: false,
    selectedId: null,
    title: "",
  });
  const popoverAnchor = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const location = useLocation();
  const itemIcon = item?.icon ? (
    <item.icon />
  ) : (
    <FiberManualRecordIcon
      sx={{
        fontSize: "12px",
        color: "inherit",
        ...(open.menuId
          ? {
              outline: "2px solid #ECDCDC",
              borderRadius: "100%",
              WebkitBorderRadius: "100%",
              MozBorderRadius: "100%",
            }
          : {}),
      }}
    />
  );

  const menus = item.children?.map((item) => {
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse
            key={item.id}
            item={item}
            level={level + 1}
            openSidebar={openSidebar}
            setOpen={closeSidebar}
          />
        );
      case "item":
        return (
          <NavItem
            key={item.id}
            item={item}
            level={level + 1}
            openSidebar={openSidebar}
            setOpen={closeSidebar}
          />
        );
      default:
        return "-";
    }
  });

  const popoverEnter = () => {
    setOpenedPopover(true);
    setOpen({
      ...open,
      selectedId: item.id,
      title: item.title,
    });
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  useEffect(() => {
    const currentIndex = location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);

    if (currentIndex > -1) {
      setOpen({
        ...open,
        menuId: true,
        selectedId: item.id,
      });
    }
  }, []);

  return (
    <RoleProviders permission={item.permission}>
      <ListItemButton
        sx={{
          borderRadius: `8px`,
          mb: 0.5,
          alignItems: "flex-start",
          py: level > 1 ? 1.7 : 2,
          pl: `${openSidebar ? (level == 2 ? level * 16 : level * 20) : 16}px`,
          color: "#3F4144",
          ...(level == 1 && location.pathname.match(item.id)
            ? {
                "&.Mui-selected": {
                  backgroundColor: "#FCF4F3",
                  color: "#cc1d15",
                  "&:after": {
                    position: "absolute",
                    content: '""',
                    left: "1px",
                    top: "19%",
                    backgroundColor: "#cc1d15",
                    width: "3px",
                    borderTopRightRadius: "15px",
                    borderBottomRightRadius: "15px",
                    height: "60%",
                  },
                },
              }
            : {
                ...(level > 1 && location.pathname.match(item.id)
                  ? {
                      "&.Mui-selected": {
                        backgroundColor: "#fff",
                        color: "#cc1d15",
                      },
                    }
                  : {
                      "&.Mui-selected": {
                        backgroundColor: "#F6F6F6",
                        color: "#3F4144",
                      },
                    }),
              }),
        }}
        onClick={() => {
          if (!openSidebar) return;
          setOpen({
            menuId: !open.menuId,
            selectedId: !open.selectedId ? item.id : null,
          });
        }}
        ref={popoverAnchor}
        {...(!openSidebar && {
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
        })}
        selected={location.pathname.match(item.id) ? true : false}
      >
        <ListItemIcon
          sx={{ my: "auto", minWidth: level > 1 ? 25 : 38, color: "inherit" }}
        >
          {itemIcon}
        </ListItemIcon>
        {openSidebar ? (
          <ListItemText
            primary={
              <Typography variant={"sidebarItem"} color="inherit">
                {item.title}
              </Typography>
            }
          />
        ) : !openSidebar && level > 1 ? (
          <ListItemText
            primary={
              <Typography variant={"sidebarItem"} color="inherit">
                {item.title}
              </Typography>
            }
          />
        ) : (
          ""
        )}
        {openSidebar ? (
          <KeyboardArrowRightIcon
            sx={{
              marginTop: "8.6px",
              marginBottom: "auto",
              color: "inherit",
              transition: "transform 0.3s",
              ...(open.menuId
                ? {
                    transform: "rotate(90deg)",
                  }
                : {}),
            }}
            fontSize="small"
          />
        ) : !openSidebar && level > 1 ? (
          <KeyboardArrowRightIcon
            sx={{
              marginTop: "8.6px",
              marginBottom: "auto",
              color: "inherit",
              transition: "transform 0.3s",
              marginLeft: "20px",
            }}
            fontSize="small"
          />
        ) : (
          ""
        )}
      </ListItemButton>
      {openSidebar && (
        <Collapse
          in={open.menuId}
          timeout="auto"
          sx={{
            "& .active": {
              ".MuiListItemButton-root ": {
                backgroundColor: "#fff",
                color: "#cc1d15",
              },
            },
          }}
        >
          <List component="div" disablePadding>
            {menus}
          </List>
        </Collapse>
      )}
      {!openSidebar && (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              pointerEvents: "auto",
              borderRadius: "8px",
              marginLeft: "2px",
            },
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
        >
          {open.title && (
            <ListItemText
              primary={
                <Typography
                  variant={"sidebarItem"}
                  color="inherit"
                  sx={{ fontWeight: "bold" }}
                >
                  {item.title}
                </Typography>
              }
              sx={{
                textAlign: "center",
                padding: "8px",
              }}
            />
          )}
          {menus}
        </Popover>
      )}
    </RoleProviders>
  );
};

NavCollapse.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  openSidebar: PropTypes.bool,
  path: PropTypes.string,
  setOpen: PropTypes.any,
};

NavCollapse.defaultProps = {
  item: {},
  level: 1,
  openSidebar: true,
  path: "",
  setOpen: () => {},
};

export default NavCollapse;
