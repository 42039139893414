import PropTypes from "prop-types";
import MainDialog2 from "components/Dialog/MainDialog2";
import React from "react";
import { Box, Typography } from "@mui/material";
import CardDialog from "../components/CardDialog";
import SatuHalaman from "assets/cms/one-page.png";
import MultiHalaman from "assets/cms/multi-page.png";

const Dialog = ({ open, onClose }) => {
  return (
    <MainDialog2
      open={open}
      onClose={() => {
        onClose();
      }}
      customWidth="800px"
    >
      <Typography variant="h2" fontSize="25px">
        Tipe Formulir
      </Typography>
      <Typography variant="text2">
        Pilih tipe formulir sesuai kebutuhan kamu
      </Typography>

      <Box display="flex" gap={3} py={2}>
        <CardDialog
          url={"/master-data/cms/formulir/one-page"}
          img={SatuHalaman}
          title="Satu Halaman"
          desc="Menampilkan isian formulir dalam satu halaman yang bisa digulir dari atas ke bawah."
        />
        <CardDialog
          url={"/master-data/cms/formulir/multi-page"}
          img={MultiHalaman}
          title="Multi Halaman"
          desc="Menampilkan isian formulir dalam bentuk lebih dari satu halaman."
        />
      </Box>
    </MainDialog2>
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.any,
};

Dialog.defaultProps = {
  onClose: () => {},
  open: false,
};

export default Dialog;
