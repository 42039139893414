import { Box, Button, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import MainLoading from "components/Loading/MainLoading";
import MainEditCard from "components/Paper/MainEditCard";
import getRole from "helper/getRole";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { editAjudicator, getDetailAdjudicator } from "redux/digitalID/action";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Controller, useForm } from "react-hook-form";
import CropImage from "components/Crop";
import _ToUpperCase from "helper/_ToUppercase";
import MainGrid from "components/Grid/MainGrid";
import InputForm from "pages/LaporanAdjudicator/components/InputForm";
import Page from "components/Page";
import InputRadio from "pages/LaporanAdjudicator/components/inputRadio";
import MainController from "components/Controller/MainController";
import Autocomplete from "components/AutoComplete/Autocomplete";
import AlertDialog from "components/Dialog/AlertDialog";
import Ilustration from "assets/digitalId/ilustrasiEmpty.png";
import DigitalIDStatus from "components/Status/DigitalIDStatus";
import moment from "moment";
import PageSM from "components/Page/PageSM";
import Badgev2 from "components/Mobile/Badgev2";

const ConfirmOption = [
  {
    label: "Anomali",
    value: "",
  },
  {
    label: "Diterima",
    value: 2,
  },
  {
    label: "Ditolak",
    value: 5,
  },
];

const ConfirmOption2 = [
  {
    label: "Anomali",
    value: "",
  },
  {
    label: "Diterima",
    value: 3,
  },
  {
    label: "Ditolak",
    value: 5,
  },
  {
    label: "Kirim Kembali",
    value: 1,
  },
];

const DetailTNIMobile = () => {
  const [page, setPage] = useState(0);

  const { isSuperVisor, isOperator } = getRole();
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState({
    dialog: false,
    informasi: true,
    kartu: true,
  });
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    trigger,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      data: {},
      forms: [],
    },
    mode: "onChange",
  });

  const { response, loading, fetch } = useFetchData({
    action: getDetailAdjudicator,
    snackbar: false,
    onSuccess: (data) => {
      let newData = {};
      Object.keys(data).map((item) => {
        newData[_ToUpperCase(item)] = data[item];
      });
      resetForm({
        data: newData,
        forms: informasiPermohonan,
      });
    },
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const informasiPermohonan = [
    {
      label: "Nama Lengkap",
      name: "DocName",
      placeholder: "Masukan Nama Lenkap",
      rules: { required: "Nama Lengkap wajib diisi" },
    },
    {
      label: "Pangkat / Korps",
      name: "DocCompany",
      placeholder: "Masukan Pangkat / Korps",
      rules: { required: "Pangkat / Korps wajib diisi" },
    },
    {
      label: "NRP / NBI",
      name: "DocNo",
      placeholder: "Masukan NRP / NBI",
      rules: { required: "NRP / NBI wajib diisi" },
    },
    {
      label: "Kesatuan",
      name: "DocIssuer",
      placeholder: "Masukan Kesatuan",
      rules: { required: "Kesatuan wajib diisi" },
    },
    {
      label: "Berlaku Hingga",
      type: "date",
      name: "DocIssueDate",
      placeholder: "Masukan Berlaku Hingga",
      rules: { required: "Berlaku Hingga wajib diisi" },
    },
    {
      label: "Dikeluarkan di",
      name: "DocPob",
      placeholder: "Masukan Dikeluarkan Di Kartu",
      rules: { required: "Dikeluarkan di wajib diisi" },
    },
  ];

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const radioData = [
    {
      label: "Ya",
      value: "1",
    },
    {
      label: "Tidak",
      value: "0",
    },
  ];

  const verif = [
    {
      label: "Data TNI dan kartu fisik TNI sesuai?",
      name: "DocAdjKey1Status",
      value: radioData,
    },
    {
      label: "Foto wajah dan foto pada kartu fisik TNI sesuai?",
      name: "DocAdjKey2Status",
      value: radioData,
    },
  ];

  const Verifikasi = useFetchData({
    action: editAjudicator,
    message: isSuperVisor
      ? "Data berhasil dikonfirmasi"
      : "Data berhasil diverifikasi",
    onSuccess: () => {
      navigate("/laporan-adjudicator/digital-id/tni");
    },
  });

  const onSubmit = (data) => {
    const profile = JSON.parse(localStorage.getItem("profile-ntt"));
    let userNameLogin = profile?.Fullname;
    let newData = data.data;
    if (isOperator) {
      if (newData.DocAdjStatus === 2) {
        newData["DocAdjValidatedBy"] = userNameLogin;
      } else if (newData.DocAdjStatus === 5) {
        newData["DocAdjRejectedBy"] = userNameLogin;
      }
    } else {
      if (response.hasOwnProperty("adj_validate_by")) {
        newData["DocAdjValidatedBy"] = response?.adj_validate_by;
      }
      if (response.hasOwnProperty("adj_rejected_by")) {
        newData["DocAdjRejectededBy"] = response?.adj_reject_by;
      }
      if (newData.DocAdjStatus === 3) {
        newData["DocAdjApprovedBy"] = userNameLogin;
      } else if (newData.DocAdjStatus === 5) {
        newData["DocAdjRejectedBy"] = userNameLogin;
      }
      if (response.hasOwnProperty("tgl_cerai")) {
        newData["TglCerai"] = response?.tgl_cerai;
      }
    }

    console.log(newData);

    Verifikasi.fetch(id, newData);
  };

  const onDownloadCropClick = () => {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }
    let io = previewCanvasRef.current.toDataURL("image/jpeg");
    let rep = io.split("data:image/jpeg;base64,")[1];
    setValue("data.DocPictCropping", rep);
  };

  const keputusanOperator = useMemo(() => {
    if (!isOperator) {
      return [
        {
          label: "Status",
          value: <DigitalIDStatus value={response?.adj_status} />,
        },
        {
          label: "Pemberi Status",
          value: response?.adj_validate_by,
        },
        {
          label: "Tgl. Status",
          value: response?.doc_adj_approved_date
            ? moment(response?.doc_adj_approved_date).format("DD / MM / YYYY")
            : "-",
        },
        {
          label: "Catatan",
          value: response?.notes,
        },
      ];
    }
  }, [isOperator, response]);

  const keputusanSupervisor = useMemo(() => {
    if (!isSuperVisor && !isOperator) {
      return [
        {
          label: "Status",
          value: <DigitalIDStatus value={response?.adj_status} />,
        },
        {
          label: "Pemberi Status",
          value: response?.doc_adj_approved_by || "-",
        },
        {
          label: "Catatan",
          value: response?.notes,
        },
      ];
    }
  }, [isOperator, isSuperVisor, response]);

  const RenderValue = ({ item }) => {
    switch (item.type) {
      case "wilayah":
        return (
          item?.options?.find((i) => i.nama == getValues(`data.${item.name}`))
            ?.nama || "-"
        );
      case "select":
        return (
          item?.options?.find((i) => i.value == getValues(`data.${item.name}`))
            ?.value || "-"
        );
      case "date":
        return moment(new Date(getValues(`data.${item.name}`))).isValid()
          ? moment(new Date(getValues(`data.${item.name}`))).format(
              "DD / MM / YYYY"
            )
          : "-";
      default:
        return getValues(`data.${item.name}`) || "-";
    }
  };

  return (
    <>
      <PageSM
        title={`Data ${response?.doc_name.split(" ")[0] || ""}`}
        backBtn
        to="/laporan-adjudicator/digital-id/tni"
      />

      <Box
        display="flex"
        gap={2}
        p={2}
        sx={{
          width: "inherit",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Badgev2
          active={page == 0}
          value="Informasi Permohonan"
          onClick={changePage(0)}
        />

        <Badgev2
          active={page == 1}
          value="Kartu & Foto"
          onClick={changePage(1)}
        />
        <Badgev2 active={page == 2} value="Keputusan" onClick={changePage(2)} />
      </Box>

      {page == 0 && (
        <Paper>
          <Box display="flex" flexDirection="column" gap={3} p={2}>
            {getValues("forms").map((item, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="text2">{item.label}</Typography>
                  <Typography variant="label2">
                    <RenderValue item={item} />
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Paper>
      )}

      {page == 1 && (
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="label2">Kartu ID</Typography>
            <Box
              component="img"
              sx={{
                maxWidth: "100%",
                maxHeight: 300,
                borderRadius: "calc(0.6vw + 0.6vh)",
                objectFit: "contain",
              }}
              src={response?.doc_pict_secondary}
            />
          </Box>
          {!isOperator && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label2">Foto yang Ditampilkan</Typography>
              <Box
                component="img"
                sx={{
                  maxWidth: "100%",
                  maxHeight: 250,
                  borderRadius: "calc(0.4vw + 0.4vh)",
                  objectFit: "contain",
                }}
                src={watch("data.DocPictCropping")}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="label2">Foto Wajah</Typography>
            <Box
              component="img"
              sx={{
                maxWidth: "100%",
                maxHeight: 250,
                borderRadius: "calc(0.4vw + 0.4vh)",
                objectFit: "contain",
              }}
              src={response?.doc_pict_primary}
            />
          </Box>
        </Paper>
      )}

      {page == 2 && (
        <Box gap={2} display="flex" flexDirection="column">
          {!isOperator ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label">Keputusan Operator</Typography>
              <Paper
                sx={{ p: 1, flexDirection: "column", display: "flex", gap: 2 }}
              >
                {keputusanOperator.map((item, i) => (
                  <Box
                    key={i}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="text2">{item.label}</Typography>
                    <Typography variant="label2">{item.value}</Typography>
                  </Box>
                ))}
              </Paper>
            </Box>
          ) : null}

          {!isOperator && !isSuperVisor ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label">Keputusan Supervisor</Typography>
              <Paper
                sx={{ p: 1, flexDirection: "column", display: "flex", gap: 2 }}
              >
                {keputusanSupervisor.map((item, i) => (
                  <Box
                    key={i}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="text2">{item.label}</Typography>
                    <Typography variant="label2">{item.value}</Typography>
                  </Box>
                ))}
              </Paper>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default DetailTNIMobile;
