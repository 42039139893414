import React from "react";
import Providers from "components/RouteRoleProviders";
import HargaProdukPPOB from "pages/MasterData/HargaProdukPPOB";
import DetailHargaProdukPPOB from "pages/MasterData/HargaProdukPPOB/Detail";

const hargaprodukppobRoutes = [
  {
    path: "/master-data/harga-produk-ppob",
    element: (
      <Providers permission="Web:Voucher:All" >
        <HargaProdukPPOB />
      </Providers>
    ),
  },
  {
    path: "/master-data/harga-produk-ppob/:id",
    element: (
      <Providers permission="Web:Voucher:All" >
        <DetailHargaProdukPPOB />
      </Providers>
    ),
  },
];

export default hargaprodukppobRoutes;