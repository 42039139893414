import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  diproses: {
    label: "Diproses",
    background: "#E6F0FC",
    color: "#0C6CE2",
  },
  dijadwalkan: {
    label: "Dijadwalkan",
    background: "#f7efd9",
    color: "#ff6f05",
  },
  ditolak: {
    label: "Ditolak",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
  ditolak_lv3: {
    label: "Ditolak",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
};

const KependudukanDetailStatus = ({ value, ...props }) => {
  let selected = color[value];

  return (
    <StatusChip {...props} {...selected} minWidth="160px" maxWidth="160px" />
  );
};

KependudukanDetailStatus.defaultProps = {
  value: "",
};

KependudukanDetailStatus.propTypes = {
  value: PropTypes.any,
};

export default KependudukanDetailStatus;
