import React from "react";

const FileIconv2 = () => {
  return (
    <>
      <svg
        width="128px"
        height="85px"
        viewBox="0 0 128 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M103.2 32.5468C99.5733 14.1468 83.4133 0.333496 64 0.333496C48.5867 0.333496 35.2 9.08016 28.5333 21.8802C12.48 23.5868 0 37.1868 0 53.6668C0 71.3202 14.3467 85.6668 32 85.6668H101.333C116.053 85.6668 128 73.7202 128 59.0002C128 44.9202 117.067 33.5068 103.2 32.5468ZM101.333 75.0002H32C20.2133 75.0002 10.6667 65.4535 10.6667 53.6668C10.6667 42.7335 18.8267 33.6135 29.6533 32.4935L35.36 31.9068L38.0267 26.8402C43.0933 17.0802 53.0133 11.0002 64 11.0002C77.9733 11.0002 90.0267 20.9202 92.7467 34.6268L94.3467 42.6268L102.507 43.2135C110.827 43.7468 117.333 50.7335 117.333 59.0002C117.333 67.8002 110.133 75.0002 101.333 75.0002ZM42.6667 48.3335H56.2667V64.3335H71.7333V48.3335H85.3333L64 27.0002L42.6667 48.3335Z"
          fill="#878889"
        />
      </svg>
    </>
  );
};
export default FileIconv2;
