import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import MainCard2 from "components/Paper/MainCard2";
import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Tooltip as Tool,
  Legend,
  ArcElement,
} from "chart.js";

import Attention from "assets/dasbor/chart/Attention";

ChartJS.register(ArcElement, Tool, Legend);

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.className = "ulli";
    listContainer.style.display = "flex";
    listContainer.style.overflow = "hidden";
    listContainer.style.gap = "10px";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.style.flexDirection = "column";
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement("li");
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.alignItems = "center";
      // li.style.gap = "2px";

      li.onclick = () => {
        const { type } = chart.config;
        if (type === "pie" || type === "doughnut") {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.borderRadius = "50%";
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "10px";
      boxSpan.style.marginRight = "5px";
      boxSpan.style.width = "10px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = "#231F20";
      textContainer.style.fontWeight = "700";
      textContainer.style.margin = 0;
      textContainer.style.width = "100px";
      textContainer.style.overflow = "hidden";
      textContainer.style.textOverflow = "ellipsis";

      textContainer.style.padding = 0;
      textContainer.style.fontSize = "12px";
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

const PieChart = ({ title, data, options, id, sidebar, tooltipString }) => {
  return (
    <MainCard2
      title={title}
      custom={
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                py: 1,
                px: 2,
                textAlign: "left",
                maxWidth: "250px",
              },
            },
          }}
          title={tooltipString}
          placement="left"
          arrow
        >
          <span style={{ cursor: "pointer", marginRight: 11 }}>
            <Attention />
          </span>
        </Tooltip>
      }
    >
      <Box
        display="flex"
        flexDirection={"row"}
        alignItems="center"
        justifyContent="center"
        sx={{
          pb: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "180px",
            height: "180px",
            pt: 2,
            px: 2,
          }}
        >
          <Pie
            data={data}
            options={options}
            plugins={[htmlLegendPlugin, ChartDataLabels]}
          />
        </Box>
        <Box
          id={id}
          sx={{
            whiteSpace: "nowrap",
            width: "100%",
            py: 1.5,
            display: "flex",
          }}
        ></Box>
      </Box>
    </MainCard2>
  );
};

PieChart.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  options: PropTypes.object,
  sidebar: PropTypes.bool,
  title: PropTypes.string,
  tooltipString: PropTypes.string,
};

PieChart.defaultProps = {
  data: {},
  id: "",
  options: {},
  sidebar: null,
  title: "",
  tooltipString: "",
};

export default PieChart;
