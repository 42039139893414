import PropTypes from "prop-types";
import { Box } from "@mui/material";
import React, { forwardRef } from "react";

const DropColumn = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Box
      sx={{
        height: "140px",
        border: "2px dashed #cc1d15",
        backgroundColor: "#F5F6F8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        color: "#cc1d15",
        width: "100%",
        textAlign: "center",
        px: 2,
        ...sx,
      }}
      ref={ref}
      {...props}
    >
      Taruh di sini untuk menambahkan
    </Box>
  );
});

DropColumn.propTypes = {
  sx: PropTypes.object,
};

DropColumn.defaultProps = {
  sx: {},
};

DropColumn.displayName = "dc";
export default DropColumn;
