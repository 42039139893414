import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import FormError from "components/Alert";
import React, { useEffect,  useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";
import { getIpAddress, initialForgotPassword } from "redux/auth/actions";
import { Link, useSearchParams } from "react-router-dom";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import moment from "moment";
import PublicRoute from "components/PublicRoute";
import InisaIlu from "assets/login/new-ilustrasi.png";
import useFetchData from "hook/useFetchData";

const defaultValues = {
  Email: "",
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [sparams] = useSearchParams();
  const [retry, setRetry] = useState(0);

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    getIpAddress();

    if (sparams.get("w")) {
      dispatch(
        alert({
          open: true,
          message: "Expired token atau token tidak sesuai",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (retry > 0) {
      const intervalId = setInterval(() => {
        setRetry(retry - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      action.setResponse({
        ...action.response,
        message: "",
      });
    }
  }, [retry]);

  const action = useFetchData({
    action: initialForgotPassword,
    message: "Instruksi Telah Dikirim",
    snackbarError: false,
    onError: (data, { message, code }) => {
      if (message === "Too Many Request" || code == 429) {

        setRetry(data.RetryAfter);
      }
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    action.setResponse({
      ...action.response,
      message: "",
    });
    formData.append("Email", data.Email);

    action.fetch(formData);
  };

  return (
    <PublicRoute ilustration={InisaIlu} title="Reset Password">
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          marginTop: "30px",
        }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <FormError
          message={`${action?.response?.message} ${
            retry > 0 ? `| ${moment.utc(retry * 1000).format("HH:mm:ss")}` : ""
          }`}
          open={action?.response?.message ? 0 : 1}
          onClose={() => {
            if (retry) {
              return;
            }
            action.setResponse({
              ...action.response,
              message: "",
            });
          }}
        />
        <Grid container spacing={3}>
          <VerticalGrid label="Email" md={12}>
            <MainController
              controller={Controller}
              name="Email"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Email wajib diisi",
                maxLength: {
                  message: "Email tidak boleh melebihi 100 karakter",
                  value: 100,
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Format Email salah",
                },
              }}
              alertType={1}
              render={({ field }) => (
                <OutlinedInput
                  variant="v1"
                  {...field}
                  name="Email"
                  errors={errors}
                  type="text"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="input your email"
                />
              )}
            />
            <Typography variant="textCommon">
              Remember Password? Back to &nbsp;
              <Link to="/auth/login" style={{ textDecoration: "none" }}>
                <span style={{ color: "#cc1d15" }}>Sign In</span>
              </Link>
            </Typography>
          </VerticalGrid>
        </Grid>

        <Button
          variant="primary"
          disabled={Boolean(retry) || action.loading}
          type="submit"
          sx={{
            margin: "10px 0px",
            padding: "13px",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          SEND INSTRUCTION
        </Button>
      </Box>
    </PublicRoute>
  );
};

export default ForgotPassword;
