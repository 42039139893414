import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  0: {
    label: "Belum diverifikasi",
    background: "#f7efd9",
    color: "#ff6f05",
  },
  1: {
    label: "Belum dikonfirmasi",
    background: "#e6f0fc",
    color: "#0c6ce2",
  },
  2: {
    label: "Dikonfirmasi",
    background: "#e9f2e9",
    color: "#2c8424",
  },
  3: {
    label: "Ditolak",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
};

const KependudukanStatus = ({ value, value2, ...props }) => {
  let selected = color[value];

  if (value2 == 5 || value2 == 7) {
    selected = {
      label: "Ditolak",
      background: "#f5e3e3",
      color: "#cc1d15",
      value: 3,
    };
  }

  return <StatusChip {...props} {...selected} minWidth="160px" maxWidth="160px" />;
};

KependudukanStatus.defaultProps = {
  value: 0,
  value2: 0,
};

KependudukanStatus.propTypes = {
  value: PropTypes.any,
  value2: PropTypes.any,
};

export default KependudukanStatus;
