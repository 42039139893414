import moment from "moment";
export const hubungan_keluarga = [
  {
    label: "Kepala Keluarga",
    value: "KEPALA KELUARGA",
  },
  {
    label: "Suami",
    value: "SUAMI",
  },
  {
    label: "Istri",
    value: "ISTERI",
  },
  {
    label: "Anak",
    value: "ANAK",
  },
  {
    label: "Cucu",
    value: "CUCU",
  },
  {
    label: "Menantu",
    value: "MENANTU",
  },
  {
    label: "Orang Tua",
    value: "ORANG TUA",
  },
  {
    label: "Mertua",
    value: "MERTUA",
  },
  {
    label: "Famili Lain",
    value: "FAMILI LAIN",
  },
  {
    label: "Pembantu",
    value: "PEMBANTU",
  },
  {
    label: "Lainnya",
    value: "LAINNYA",
  },
];

export const optJenisKelamin = [
  {
    label: "LAKI-LAKI",
    value: "LAKI-LAKI",
  },
  {
    label: "PEREMPUAN",
    value: "PEREMPUAN",
  },
];

export const optPekerjaan = [
  {
    label: "NEGERI SIPIL",
    value: "NEGERI SIPIL",
  },
  {
    label: "WIRASWASTA",
    value: "WIRASWASTA",
  },
  {
    label: "SWASTA",
    value: "SWASTA",
  },
  {
    label: "PEGAWAI",
    value: "PEGAWAI",
  },
  {
    label: "IBU RUMAH TANGGA",
    value: "IBU RUMAH TANGGA",
  },
  {
    label: "LAINNYA",
    value: "LAINNYA",
  },
];

export const optTmpDilahirkan = [
  {
    label: "RS/RB",
    value: "RS/RB",
  },
  {
    label: "PUSKEMAS",
    value: "PUSKEMAS",
  },
  {
    label: "POLINDES",
    value: "POLINDES",
  },
  {
    label: "RUMAH",
    value: "RUMAH",
  },
];

export const optJenisKelahiran = [
  {
    label: "TUNGGAL",
    value: "TUNGGAL",
  },
  {
    label: "KEMBAR 2",
    value: "KEMBAR 2",
  },
  {
    label: "KEMBAR 3",
    value: "KEMBAR 3",
  },
  {
    label: "KEMBAR 4",
    value: "KEMBAR 4",
  },
  {
    label: "LAINNYA",
    value: "LAINNYA",
  },
];

export const optPenolongLahir = [
  {
    label: "DOKTER",
    value: "DOKTER",
  },
  {
    label: "BIDAN/PERAWAT",
    value: "BIDAN/PERAWAT",
  },
  {
    label: "DUKUN",
    value: "DUKUN",
  },
  {
    label: "LAINNYA",
    value: "LAINNYA",
  },
];

export const optWargaNegara = [
  {
    label: "WNI",
    value: "WNI",
  },
  {
    label: "WNA",
    value: "WNA",
  },
];

export const confirmOptionOperator = [
  {
    label: "Diterima",
    value: "diproses",
  },
  {
    label: "Ditolak",
    value: "ditolak_lv3",
  },
];

export const confirmOptionSupervisor = [
  {
    label: "Dijadwalkan",
    value: "dijadwalkan",
  },
  {
    label: "Ditolak",
    value: "ditolak",
  },
];

export const updatestatus = [
  {
    label: "Verifikasi Data",
    data: "verification_date",
    open: false,
    value: moment(new Date()).format("DD/MM/YYYY HH:ss"),
  },
  {
    label: "Dijadwalkan",
    data: "scheduled_date",
    open: false,
    value: "",
  },
  {
    label: "Pencetakan Dok",
    data: "document_print_date",
    open: false,
    value: "",
  },
  {
    label: "Dok Fisik Tersedia",
    data: "available_document_date",
    open: false,
    value: "",
  },
  {
    label: "Selesai",
    data: "done_date",
    open: false,
    value: "",
  },
];
