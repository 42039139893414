import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useFetchData from "hook/useFetchData";
import { newPenerbit, updatePenerbit } from "redux/penerbit/action";
import { OutlinedInput } from "@mui/material";
import { newFormulir } from "redux/CMS/action";

const SimpanDialog = ({
  open,
  onClose,
  refresh,
  data,
  value,
  handleChangeTitleDocument,
  id,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
  } = useForm({
    mode: "onChange",
  });

  const action = useFetchData({
    action: !id ? newFormulir : updatePenerbit,
    reset: resetForm,
    message: !id ? "Berhasil menambah formulir baru" : "Berhasil edit penerbit",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (data) {
      resetForm({
        data: data,
      });
    }
  }, [data]);

  const onSubmit = async ({ data }) => {
    await action.fetch(data, id);
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Simpan Formulir"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="sm"
    >
      <OutlinedInput
        placeholder="Masukan nama dokumen untuk formulir"
        value={value}
        required
        onChange={(e) => handleChangeTitleDocument(e)}
      />
    </MainDialog>
  );
};

SimpanDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  value: PropTypes.any,
  id: PropTypes.any,
  handleChangeTitleDocument: PropTypes.any,
};

SimpanDialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
  value: null,
  id: null,
  handleChangeTitleDocument: null,
};
export default SimpanDialog;
