export const Auth = {
  initialization: "access/initialization",
  register: "merchant/register",
  login: "access/login/web",
  verifMerchant: "/merchant/verification",
  forgotPassword: "access/forgot-password/initial",
  passwordValidation: "access/forgot-password/validation",
  emailValidate: "access/email/validation",
};

export const Profile = {
  profile: "access/profile/get",
  photo: "profile-photo/list",
  editUser: "access/user/edit",
  changePassword: "access/password/edit",
};

export const Penerbit = {
  list: "Penerbit/List",
  action: "Penerbit",
  validasiImport: "penerbitExcel",
  multiPenerbit: "multiPenerbit",
  merchantPenerbit: "merchant/issuer",
};

export const KartuSubsidi = {
  list: "kartuSubsidiWeb/list",
  action: "kartuSubsidiWeb",
};

export const Voucher = {
  list: "Voucher/List",
  action: "Voucher",
  validasiImport: "voucherCheck",
  multiVoucher: "multiVoucher",
};

export const SubsidiBansos = {
  list: "listSubsidi",
  voucherPenerbit: "listKartuSubsidiByPenerbit",
  action: "subsidi",
  detail: "detailSubsidi",
  listPenerima: "listPenerimaByProgramSubsidi",
  exportUser: "exportUserBySubsidiId",
};

export const VoucherBansos = {
  list: "listBansos",
  listPenerima: "listPenerimaByProgram",
  detailVoucher: "detailBansos",
  listPenerima: "listPenerimaByProgram",
  voucherPenerbit: "listVoucherByPenerbitId",
  createBansosMulti: "create-bansos-multi",
  exportUser: "exportUserBySubsidiId",
};

export const PenerimaBantuan = {
  list: "listBansosPenerima",
  bansosPenerima: "getBansosUserDetail",
  bansosPenerimaVoucherSubsidi: "getBansosUser",
  bansosSubsidiProgramUser: "listSubsidiByProgramAndUser",
  bansosVoucherProgramUser: "listVoucherByBansosAndUserId",
  bansosDetailUserSubsidi: "detailDataSubsidi",
  bansosRiwayatTransaksi: "detailKartuSubsidiPenerima",
  bansosVoucherRedeem: "redeemVoucher",
};

export const Pengguna = {
  list: "access/user/list",
  action: "admin",
  addUser: "access/user/add",
  editUser: "access/user/edit",
  role: "access/roles/list",
  roleAction: "merchant/role/create",
  roleDetail: "merchant/role/permissions",
  listVoucerByIssuer: "listCardByIssuer",
  roleDelete: "/merchant/role/delete",
};

export const Merchant = {
  registerMerchant: "merchant/business/registration",
  detailUserMerchant: "getDetailMerchant",
  list: "inventory/merchant",
};

export const RedeemSubsidi = {
  list: "listTransactionRedeem",
  detailSubsidi: "transactionDetailSubsidi",
  detailVoucer: "transactionDetailBansos",
  listVoucer: "officer/listVoucherByNoId",
  updateFoto: "officer/updateFotoWajah",
  redeemSubsidiBansos: "/officer/subsidiOnlineTransaction",
};

export const Inventory = {
  masterDistribusi: "inventory/distribusi",
  masterProduk: "inventory/produk",
};

export const Report = {
  stock: "merchantReport/stock",
  daily: "merchantReport/daily",
};

export const KategoriLokasi = {
  list: "kategoriLokasiWeb",
  detail: "detailKategoriLokasiWeb",
  action: "kategoriLokasi",
};

export const Lokasi = {
  list: "Lokasi",
  fasilitas: "fasilitasLokasi",
  detail: "lokasiById",
  multi: "multiLokasi",
  addImage: "lokasiAddImage",
  getImage: "lokasiGetImage",
  deleteImage: "lokasiDeleteImage",
  validate: "readExcel",
};

export const Kependudukan = {
  getList: "getDocument",
  getStatus: "docAdjQueue/status",

  getKTP: "Ktp",
  verifikasiKTP: "ktpStatusVerifikasi",
  konfirmasiKTP: "ktpStatusKonfirmasi",
  updateJadwalKTP: "ktpJadwal",

  getKK: "Kk",
  getKKAnggota: `kkAnggota`,
  updateKKAnggota: "kkAnggota",
  updateJadwalKK: "kkJadwal",
  verfikasiKK: "kkStatusVerifikasi",
  konfirmasiKK: "kkStatusKonfirmasi",

  getAktaLahir: `aktaLahir`,
  // aktaStatus: `aktaLahirStatus`,
  verifikasiAktaLahir: `aktaLahirStatusVerifikasi`,
  konfirmasiAktaLahir: `aktaLahirStatusKonfirmasi`,
  updateJadwalAktaLahir: "aktaLahirJadwal",
};

export const Region = {
  getProvinsi: "/getProvinsi",
  getKota: "/getCity",
  getKecamatan: "/getDistrict",
  getKelurahan: "/getSubDistrict",
};

export const HargaProdukPPOB = {
  hargaProdukList: "dataProduct/ppob",
  wndaddupdate: "wnd/addOrUpdate",
  oyaddupdate: "oy/addOrUpdate",
  ubahstatus: "dataProduct/ppob/updateStatusBatch",
};

export const DigitalID = {
  listSupervisor: "organization-user-approval/list",
  listOperation: "organizationuser/list",
  listSuperadmin: "listadjudicator",
  detailAdjudicator: "detail-adjudicator",
  adjudicator: "adjudicator",
  masterData: "master",
};

export const LaporanTransaksi = {
  transaksiPPOB: "transaction/ppob",
  transaksiDetail: "transaction/detail",
  transaksiPBB: "transaction/pbb",
  transferMasuk: "transaction/transferin",
  transferKeluar: "transaction/transferout",
};

export const ManagementMerchant = {
  merchantList: "merchant/list",
  merchantAction: "merchant",
  merchantDetail: "merchant/verif-detail",
};

export const Pengaturan = {
  pembaruanAplikasiGet: "versions",
  pembaruanAplikasiAction: "version",
  loginSessionGet: "loginSession/Get",
  loginSessionAction: "loginSession/Create",
};

export const Broadcast = {
  resendSms: "messaging/temporalBlast",

  smsBroadcastAction: "messaging/smsBlast",
  smsBroadcastGet: "messaging/sms",
  smsBroadcastResend: "messaging/temporalBlast",

  notifBroadcastGet: "messaging/pushNotif",

  emailBroadcastGet: "messaging/email",
  emailBroadcastTemplateGet: "template/list",

  //broadcast email Marketing
  marketing: "messaging/sendgridMarketing/email",
  marketingContact: "messaging/sendgridMarketing/lists",
  marketingDesign: "messaging/sendgridMarketing/designs",
  marketingDesignDetail: "messaging/sendgridMarketing/design",
  marketingUnsub: "messaging/sendgridMarketing/unsubscribes",
  marketingUpload: "messaging/sendgridMarketing/email/upload",
};

export const Banner = {
  bannerGet: "banners",
  bannerFormulir: "getFormulirBanner",
};

export const Dokumen = {
  list: "Document/List",
  action: "Document",
};

export const Formulir = {
  getListFormulir: "Formulir/List",
  action: "Formulir",
};
