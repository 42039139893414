import React from "react";

const CheckActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.3255 16.1142L15.3915 11.0481L12.9516 8.6082L7.8857 13.6744L7.02951 16.2429C6.99016 16.361 6.99016 16.4886 7.02951 16.6066C7.12994 16.9079 7.45561 17.0708 7.75691 16.9703L10.3255 16.1142ZM16.8316 9.60817C17.0561 9.3836 17.0561 9.0195 16.8316 8.79493L15.205 7.16843C14.9805 6.94386 14.6164 6.94386 14.3918 7.16843L13.6587 7.9011L16.0986 10.341L16.8316 9.60817Z"
        fill="#CC1D15"
      />
      <mask
        id="mask0_4076_383170"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.3255 16.1142L15.3915 11.0481L12.9516 8.6082L7.8857 13.6744L7.02951 16.2429C6.99016 16.361 6.99016 16.4886 7.02951 16.6066C7.12994 16.9079 7.45561 17.0708 7.75691 16.9703L10.3255 16.1142ZM16.8316 9.60817C17.0561 9.3836 17.0561 9.0195 16.8316 8.79493L15.205 7.16843C14.9805 6.94386 14.6164 6.94386 14.3918 7.16843L13.6587 7.9011L16.0986 10.341L16.8316 9.60817Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4076_383170)">
        <rect width="50" height="50" fill="#CC1D15" />
      </g>
    </svg>
  );
};

export default CheckActive;
