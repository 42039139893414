import PropTypes from "prop-types";
import { Box, Button, Collapse, Typography } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import React from "react";
import { TransitionGroup } from "react-transition-group";
import useGetRole from "hook/useGetRole";

const UpdateStatus = ({ value, onUpdate, disabled, status }) => {
  const { isSuperVisor } = useGetRole();

  return (
    <>
      <TransitionGroup>
        {value.map((item, i) => (
          <Collapse
            key={i}
            sx={{
              "&:nth-of-type(even)": {
                backgroundColor: "#fff",
              },
              "&:nth-of-type(odd)": {
                backgroundColor: "#F6F6F6",
              },
            }}
          >
            <MainGrid
              justifyContent="space-between"
              labelMd={5}
              valueMd={7}
              center
              {...item}
              striped={""}
            />
          </Collapse>
        ))}
      </TransitionGroup>
      {isSuperVisor &&
      value.length &&
      value.length != 5 &&
      (status == "dijadwalkan" ||
        status == "dok fisik tersedia" ||
        status == "pencetakan dok" ||
        status == "verifikasi data") ? (
        <Box
          display="flex"
          gap={5}
          p={2}
          alignItems="center"
          // justifyContent="center"
          justifyContent="space-between"
          sx={{
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <Typography variant="label2">
            Ubah status ke tahap berikutnya?
          </Typography>
          <Button variant="primary" onClick={onUpdate} disabled={disabled}>
            Update
          </Button>
        </Box>
      ) : null}
    </>
  );
};

UpdateStatus.propTypes = {
  value: PropTypes.array,
  onUpdate: PropTypes.func,
  disabled: PropTypes.bool,
  status: PropTypes.string,
};

UpdateStatus.defaultProps = {
  value: [],
  onUpdate: () => {},
  disabled: false,
  status: "",
};

export default UpdateStatus;
