import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const Years = ({ onChange }) => {
  const getYears = (startYear) => {
    let currentYear = new Date().getFullYear();
    let years = [];
    startYear = startYear || 1970;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };
  return (
    <Box width="360px" height="300px">
      <Grid
        container
        spacing={1}
        sx={{
          p: 2,
        }}
      >
        {getYears().map((item) => (
          <Grid item xs={4} key={item}>
            <Button
              sx={{
                border: "1px solid #E5E7EB",
              }}
              onClick={() => onChange(item)}
            >
              <Typography variant="gridLabel">{item}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

Years.propTypes = {
  onChange: PropTypes.func,
};

Years.defaultProps = {
  onChange: () => {},
};

export default Years;
