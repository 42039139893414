import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  1: {
    label: "Published",
    background: "#e7f4ed",
    color: "#129350",
  },
  2: {
    label: "Inactive",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
};

const BannerStatus = ({ value, ...props }) => {
  let selected = color[value] ?? {
    label: "Inactive",
    background: "#f5e3e3",
    color: "#cc1d15",
  };

  return (
    <StatusChip {...props} {...selected} minWidth="120px" maxWidth="120px" />
  );
};

BannerStatus.defaultProps = {
  value: 0,
};

BannerStatus.propTypes = {
  value: PropTypes.any,
};

export default BannerStatus;
