import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

const Page = ({ title, backBtn, desc, page, menu, to }) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {backBtn && (
          <IconButton
            onClick={() => (to ? navigate(to) : navigate(-1))}
            sx={{
              p: 0,
            }}
          >
            <ChevronLeftIcon
              sx={{
                fontSize: {
                  xs: "40px",
                  md: "50px",
                },
                color: "#111",
                fontWeight: "bold",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h1">{title}</Typography>
        {desc && (
          <Typography
            variant="desc"
            sx={{
              mt: 1,
            }}
          >
            Dasbor{" "}
            {window.location.pathname
              .replaceAll("-", " ")
              .replaceAll(/[0-9]/g, "")
              .split("/")
              .map((path, i, arr) => {
                if (i == arr.length - 1)
                  return (
                    <span style={{ color: "#cc1d15", fontWeight: 700 }} key={i}>
                      {path}
                    </span>
                  );
                else {
                  return path == "csr bansos" ? (
                    <span style={{ textTransform: "none" }} key={i}>
                      {" "}
                      CSR Bansos /{" "}
                    </span>
                  ) : (
                    `${path} / `
                  );
                }
              })}
          </Typography>
        )}
        {Boolean(page.length) && (
          <Typography variant="desc">
            Dasbor / {page.join(" / ")} /{" "}
            <span style={{ color: "#cc1d15", fontWeight: 700 }}>{menu}</span>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

Page.propTypes = {
  desc: PropTypes.bool,
  title: PropTypes.string,
  page: PropTypes.array,
  menu: PropTypes.string,
  backBtn: PropTypes.bool,
  to: PropTypes.string,
};

Page.defaultProps = {
  title: "",
  desc: false,
  page: [],
  backBtn: false,
  menu: "",
  to: "",
};

export default Page;
