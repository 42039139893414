import React from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import MainCardHeader2 from "components/Paper/MainCardHeader2";
import useSelect from "hook/useSelect";
import Page from "components/Page";
import useAutoFetch from "hook/useAutoFetch";
import { getDigitalID, getListDigitalID } from "redux/digitalID/action";
import DigitalIDStatus from "components/Status/DigitalIDStatus";
import {
  changeFilter,
  changeLimit,
  changePage,
} from "redux/digitalID/KTP/slice";
import getRole from "helper/getRole";
import moment from "moment";

const setDocType = (value) => {
  switch (value) {
    case "0":
      return "Other";
    case "1":
      return "AGP";
    case "2":
      return "KTP";
    case "3":
      return "SIM";
    case "4":
      return "NAME CARD";
    case "5":
      return "MITRA NAME CARD";
    case "6":
      return "PASSPORT";

    default:
      break;
  }
};

const KTP = () => {
  const { isSuperVisor, isOperator } = getRole();

  const dispatch = useDispatch();

  const type = isOperator
    ? "operator"
    : isSuperVisor
    ? "supervisor"
    : "superadmin";

  const fetchData = () => dispatch(getListDigitalID({ params, type }));

  const { list, loading, params, totalItems, filterName } = useSelect(
    (state) => state.digitalIDKTP
  );

  useAutoFetch(fetchData, params.page);

  const headers = [
    {
      name: "No. Dokumen",
      key: "doc_no",
      width: 20,
    },
    {
      name: "Nama",
      key: "doc_name",
      width: 20,
    },
    {
      name: "Tipe Dok.",
      custom: (item) => setDocType(item.doc_card_type),
      width: 20,
    },
    {
      name: "Tgl. Diterima",
      custom: (item) => {
        return item?.job_assign_date
          ? moment(item?.job_assign_date).format("DD / MM / YYYY")
          : "-";
      },
      align: "center",
      width: 20,
    },
    {
      name: "Status",
      custom: (item) => {
        return <DigitalIDStatus value={item.adj_status} />;
      },
      width: 20,
    },
  ];

  const exportData = useFetchData({
    action: getDigitalID,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data,
        filename: "digital-id-ktp",
      });
    },
  });

  const handleExport = (type) => {
    exportData.fetch({ params, type }, null, type);
  };

  return (
    <>
      <Page
        title="Digital ID KTP"
        page={["Laporan Adjudicator", "Digital ID"]}
        menu="KTP"
      />

      <MainCard title="Daftar Anggota" addButton={false} borderColor="#C8C8C8">
        <MainCardHeader2
          filterName={filterName}
          changeFilter={changeFilter}
          params={params}
          onChangeExport={(type) => handleExport(type)}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="doc_name"
          detail={[
            {
              type: "link",
              url_key: "laporan-adjudicator/digital-id/ktp:id",
              tooltip: "Lihat Detail Anggota",
              variant: "icon:eye2",
            },
          ]}
          increment={(params.page - 1) * params.pageSize + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          has="pageSize"
          currentPage={params?.page}
        />
      </MainCard>
    </>
  );
};

export default KTP;
