import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getParams, put } from "api";
import { Kependudukan } from "api/endpoints";

export const getListKTP = createAsyncThunk(
  "kependudukan/getListKTP",
  async (params) => {
    const response = await getParams(Kependudukan.getList, params);
    return response.data.data;
  }
);

export const getListKTPExport = async (params) => {
  const response = await getParams(Kependudukan.getList, params);
  return response;
};

export const editKTP = async (id, data) => {
  const response = await put(`${Kependudukan.getKTP}/${id}`, data);
  return response;
};

export const getListKK = createAsyncThunk(
  "kependudukan/getListKK",
  async (params) => {
    const response = await getParams(Kependudukan.getList, params);
    return response.data.data;
  }
);

export const getListKKExport = async (params) => {
  const response = await getParams(Kependudukan.getList, params);
  return response;
};

export const getListAktaLahir = createAsyncThunk(
  "kependudukan/getListKK",
  async (params) => {
    const response = await getParams(Kependudukan.getList, params);
    return response.data.data;
  }
);

export const getListAktaLahirExport = async (params) => {
  const response = await getParams(Kependudukan.getList, params);
  return response;
};

export const getDetailKTP = async (id) => {
  const response = await getParams(`${Kependudukan.getKTP}/${id}`);

  return response;
};

export const getStatus = async (id) => {
  const response = await getParams(`${Kependudukan.getStatus}/${id}`);

  return response;
};

export const updateStatusKTP = async (id, data) => {
  const response = await put(`${Kependudukan.getStatus}/${id}`, data);

  return response;
};

export const verifikasiKTP = async (id, data) => {
  const response = await put(`${Kependudukan.verifikasiKTP}/${id}`, data);

  return response;
};

export const konfirmasiKTP = async (id, data) => {
  const response = await put(`${Kependudukan.konfirmasiKTP}/${id}`, data);

  return response;
};

export const updateJadwalKTP = async (data) => {
  const response = await put(`${Kependudukan.updateJadwalKTP}`, data);

  return response;
};

//kk
export const getDetailKK = async (id) => {
  const response = await getParams(`${Kependudukan.getKK}/${id}`);

  return response;
};

export const verifikasiKK = async (id, data) => {
  const response = await put(`${Kependudukan.verfikasiKK}/${id}`, data);

  return response;
};

export const konfirmasiKK = async (id, data) => {
  const response = await put(`${Kependudukan.konfirmasiKK}/${id}`, data);

  return response;
};

export const updateJadwalKK = async (data) => {
  const response = await put(`${Kependudukan.updateJadwalKK}`, data);

  return response;
};

export const updateStatusData = async (id, data) => {
  const response = await put(`${Kependudukan.getStatus}/${id}`, data);

  return response;
};

export const editKK = async (id, data) => {
  const response = await put(`${Kependudukan.getKK}/${id}`, data);
  return response;
};

export const getKKAnggota = async (id) => {
  const response = await get(`${Kependudukan.getKKAnggota}/${id}`);
  return response;
};

export const editKKAnggota = async (data, { did, aid }) => {
  const response = await put(
    `${Kependudukan.getKKAnggota}/${did}/${aid}`,
    data
  );
  return response;
};

export const getDetailAktaLahir = async (id) => {
  const response = await getParams(`${Kependudukan.getAktaLahir}/${id}`);

  return response;
};

export const editAktaLahir = async (id, data) => {
  const response = await put(`${Kependudukan.getAktaLahir}/${id}`, data);
  return response;
};

export const verifikasiAktaLahir = async (id, data) => {
  const response = await put(`${Kependudukan.verifikasiAktaLahir}/${id}`, data);

  return response;
};

export const konfirmasiAktaLahir = async (id, data) => {
  const response = await put(`${Kependudukan.konfirmasiAktaLahir}/${id}`, data);

  return response;
};

export const updateJadwalAktaLahir = async (data) => {
  const response = await put(`${Kependudukan.updateJadwalAktaLahir}`, data);

  return response;
};
