import { Box, Button, Paper, SwipeableDrawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useParams } from "react-router-dom";
import PageSM from "components/Page/PageSM";
import PeriodeFormat from "components/Format/PeriodeFormat";
import AktifStatus from "components/Status/AktifStatus";
import Validasi from "components/Status/Validasi";
import useFetchData from "hook/useFetchData";
import { validasi_id } from "../variables";
import { exportUser, getDetailVoucher } from "redux/voucherBansos/action";

const VoucherBansosDetailMobile = () => {
  const { id } = useParams();
  const [informasiData, setInformasiData] = useState([]);

  const { fetch, response, loading } = useFetchData({
    action: getDetailVoucher,
    snackbar: false,
  });

  useEffect(() => {
    fetch(id);
  }, []);

  useEffect(() => {
    if (response) {
      setInformasiData([]);
      const i = response.data_bansos;
      let inf = [];
      let test = {
        "Nama Program Bantuan": {
          name: i.NamaProgram,
        },
        "Periode Bantuan": {
          startDate: i.TanggalMulai,
          endDate: i.TanggalSelesai,
          format: "periode",
        },
        "Kode Penyelenggara": {
          name: i.KodePenyelenggara,
        },
        "Nama Penyelenggara": {
          name: i.NamaPenyelenggara,
        },
        "Jenis ID": {
          name: i.JenisId,
          format: "jenisid",
        },
        "Validasi KK": {
          name: i.ValidasiKk,
          format: "validasikk",
        },
        Status: {
          name: i.Status,
          format: "status",
        },
        "Tgl. Status": {
          name: i.TanggalStatus,
        },
        "Daftar Penerima Bantuan": {
          name: i.PenerimaBantuan,
          format: "download",
        },
      };

      for (const key in test) {
        inf.push({
          name: key,
          value: renderValue(test[key]),
        });
      }

      setInformasiData(inf);
    }
  }, [response]);

  const exportData = useFetchData({
    action: exportUser,
    snackbar: false,
  });

  const handleExport = async () => {
    await exportData.fetch(id);
  };

  useEffect(() => {
    if (exportData.response) {
      const list = exportData.response.list;
      const keys = exportData.response?.keys;
      ExportHelper({
        type: "excel",
        data: list,
        filename: response?.data_bansos?.PenerimaBantuan,
        opt: {
          header: keys,
        },
      });
    }
  }, [exportData.response]);

  const renderValue = (data) => {
    switch (data.format) {
      case "status":
        return <AktifStatus value={data.name} auto />;
      case "periode":
        return (
          <PeriodeFormat
            StartDate={data.startDate}
            EndDate={data.endDate}
            sx={{
              paddingRight: "0px",
            }}
          />
        );
      case "jenisid":
        return validasi_id?.find((i) => i.value == data.name)?.label;
      case "validasikk":
        return <Validasi value={data.name} auto />;
      case "download":
        return (
          <Typography
            sx={{
              color: "#cc1d15",
              cursor: "pointer",
            }}
            variant="label"
            onClick={handleExport}
          >
            {data.name}
          </Typography>
        );
      default:
        return data.name || "-";
    }
  };

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  return (
    <>
      <Paper>
        <Box sx={{ px: 2, pt: 2 }}>
          <PageSM
            title={`Detail Program Bantuan`}
            backBtn
            to="/csr-bansos/program-bantuan/voucer-bansos"
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          {informasiData.map((item, i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="text2">{item.name}</Typography>
              <Typography
                variant="label2"
                width="140px"
                sx={{ overflow: "hidden" }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Paper>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default VoucherBansosDetailMobile;
