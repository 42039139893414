import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const CardDialog = ({ url, img, title, desc }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        transition: "all 0.2s ease-in-out",
        borderRadius: "8px",
        "&:hover": {
          boxShadow: "4px 5px 8px 0 #d7d7d7",
          cursor: "pointer",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        width: "400px",
        position: "relative",
      }}
      onClick={() => navigate(url)}
    >
      <Box
        component={"img"}
        src={img}
        sx={{
          width: "100%",
        }}
      />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          pb: 2,
        }}
      >
        <Typography variant="title2">{title}</Typography>

        <Typography variant="text2" textAlign="center">
          {desc}
        </Typography>
      </Box>
    </Box>
  );
};

CardDialog.propTypes = {
  desc: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

CardDialog.defaultProps = {
  desc: "",
  img: "",
  title: "",
  url: "",
};

export default CardDialog;
