import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { emailValidation } from "redux/auth/actions";
// import { emailValidation } from "../../../redux/actions/auth";

function EmailValidate() {
  const history = useNavigate();
  const [sparams] = useSearchParams();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const validate = async () => {
      const formData = new FormData();
      let key = sparams.get("key");
      formData.append("EmailKey", key);
      const response = await emailValidation(formData);
      if (response.data.status === "success") {
        history(
          `/forgot-password/reset-password?key=${response.data.data.ForgotKey}`
        );
      } else {
        setErrorMessage(response.data.message);
      }
    };
    validate();
  }, [history]);

  return <div className="text-center">{errorMessage}</div>;
}

export default EmailValidate;
