import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getListEmailMarketing } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const emailMarketingSlice = createSlice({
  name: "broadcast-email-narketing",
  initialState,
  reducers: pagination,
  extraReducers: {
    [getListEmailMarketing.pending]: (state) => {
      state.loading = true;
    },
    [getListEmailMarketing.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListEmailMarketing.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.lists;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = emailMarketingSlice.actions;

export default emailMarketingSlice.reducer;
