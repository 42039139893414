import React from "react";

const AlertIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        r="49.5"
        stroke="#0090EA"
        strokeOpacity="0.200783"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 68.125C45 66.1154 46.0721 64.2584 47.8125 63.2536C49.5529 62.2488 51.6971 62.2488 53.4375 63.2536C55.1779 64.2584 56.25 66.1154 56.25 68.125C56.25 71.2316 53.7316 73.75 50.625 73.75C47.5184 73.75 45 71.2316 45 68.125ZM45.0312 32.247C44.8054 30.1829 45.8194 28.1753 47.6435 27.0751C49.4677 25.975 51.7823 25.975 53.6065 27.0751C55.4306 28.1753 56.4446 30.1829 56.2188 32.247L54.0434 53.2786C53.8604 54.9674 52.3848 56.25 50.625 56.25C48.8652 56.25 47.3896 54.9674 47.2066 53.2786L45.0312 32.247Z"
        fill="#E1711A"
      />
    </svg>
  );
};

export default AlertIcon;
