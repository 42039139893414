import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import {
  Alert,
  Box,
  Button,
  Grid,
  OutlinedInput,
} from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import Radio from "components/Radio/Radio";
import TimePicker from "components/Picker/TimePicker";
import DatePicker2 from "components/Picker/DatePicker2";
import moment from "moment";
import {
  createEmailMarketing,
  getEmailBroadcastTemplateAll,
  getMarketingDesign,
  getMarketingDesignDetail,
  getMarketingUnsub,
} from "redux/broadcast/action";
import InputExcel from "components/TextField/InputExcel";
import ExcelTemplate from "assets/broadcast/emailMarketing/template/template_marketing.xlsx";
import Autocomplete from "components/AutoComplete/Autocomplete";
import InfoIcon from "@mui/icons-material/Info";

const radio = [
  {
    label: "Kirim Sekarang",
    value: 1,
  },
  {
    label: "Jadwalkan Nanti",
    value: 0,
  },
];

const Desc = ({ download }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <span>Hanya dokumen excel format *.xls dan *.xlsx yang diterima.</span>
      <Box
        component="a"
        sx={{ color: "#cc1d15", textDecoration: "none" }}
        download="template-email-receiver.xlsx"
        href={download}
      >
        Download Template
      </Box >
    </Box>
  );
};

Desc.propTypes = {
  download: PropTypes.any,
};

Desc.defaultProps = {
  download: "",
};

const Dialog = ({ open, onClose, refresh, data, id, setRes }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    clearErrors,
    watch,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      name: "",
      design_id: null,
      suppression_id: null,
      is_auto_send: null,
      send_at: {
        day: "",
        minutes: "",
      },
      html_content: "",
      subject: "",
      excel: null,
    },
  });

  const action = useFetchData({
    action: createEmailMarketing,
    reset: resetForm,
    message: "Broadcast email marketing baru berhasil",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const unsub = useFetchData({
    action: getMarketingUnsub,
    snackbar: false,
  });

  const design = useFetchData({
    action: getMarketingDesign,
    snackbar: false,
  });

  const reqDesign = useFetchData({
    action: getMarketingDesignDetail,
    snackbar: false,
  });

  useEffect(() => {
    if (data) {
      resetForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async (data) => {
    if (typeof data.send_at == "object") {
      const { day, minutes } = data.send_at;
      data.send_at = `${day} ${minutes}:00`;
    }

    data.html_content = reqDesign?.html_content?.replace(
      new RegExp(`{{content}}`, "g"),
      data.html_content
    );

    data.name = data.subject;

    if (data.is_auto_send === 1) {
      delete data.send_at;
    }

    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      if (data[item] !== null && data[item] !== undefined) {
        formData.append(item, data[item]);
      }
    });
    await action.fetch(formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Buat Email Marketing"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="lg"
      custom={
        <>
          <Button variant="secondary-outlined">Tinjau</Button>
          <Button variant="primary-outlined">Simpan</Button>
        </>
      }
      submitText="Submit & Kirim"
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Penerima"}>
          <Grid container spacing={1}>
            <VerticalGrid label={"Kepada"} md={12}>
              <MainController
                {...getProps("excel")}
                rules={{
                  required: "Kepada wajib diisi",
                }}
                desc={<Desc download={ExcelTemplate} />}
                render={({ field, fieldState: { invalid } }) => (
                  <InputExcel
                    {...field}
                    setError={setError}
                    error={invalid}
                    variant="v1"
                    placeholder="Pilih dokumen"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Unsubscribe List"} md={12}>
              <MainController
                {...getProps("suppression_id")}
                rules={{
                  required: "Unsubcribe wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    options={unsub.response || []}
                    value={
                      unsub.response?.find((item) => item.id == field.value) ||
                      null
                    }
                    onOpen={() => {
                      unsub.setResponse([]);
                      unsub.fetch();
                    }}
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv?.id);
                      }
                    }}
                    error={invalid}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    placeholder="Pilih Unsubscribe"
                    loading={unsub.loading}
                    variant="v1"
                    getOptionLabel={(option) => option.name || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Design List"} md={12}>
              <MainController
                {...getProps("design_id")}
                rules={{
                  required: "Design wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    options={design.response?.Result || []}
                    value={
                      design.response?.Result?.find(
                        (item) => item.id == field.value
                      ) || null
                    }
                    onOpen={() => {
                      design.setResponse([]);
                      design.fetch();
                    }}
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv?.id);
                        reqDesign.fetch(nv?.id);
                      }
                    }}
                    error={invalid}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    placeholder="Pilih Design"
                    loading={design.loading}
                    variant="v1"
                    getOptionLabel={(option) => option.name || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Subjek"} md={12}>
              <MainController
                {...getProps("subject")}
                rules={{
                  required: "Subjek wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    variant="v1"
                    placeholder="Masukan Subjek"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Jadwal Pengiriman"} md={12}>
              <MainController
                {...getProps("is_auto_send")}
                rules={{
                  required: "Jadwal Pengiriman wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Radio
                    {...field}
                    data={radio}
                    onChange={(e) => {
                      const value = parseInt(e);
                      field.onChange(value);

                      if (value == 1) {
                        setValue("send_at", {
                          day: moment().format("YYYY-MM-DD"),
                          minutes: moment().format("HH:mm"),
                        });
                      } else {
                        setValue("send_at", {
                          day: "",
                          minutes: "",
                        });
                      }
                    }}
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label="Pilih Tanggal" md={6}>
              <MainController
                {...getProps("send_at.day")}
                rules={
                  watch("is_auto_send") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field, fieldState: { invalid } }) => {
                  return (
                    <DatePicker2
                      {...field}
                      variant="v1"
                      disabled={watch("is_auto_send") == 1}
                      error={invalid}
                      placeholder="DD/MM/YYYY"
                    />
                  );
                }}
              />
            </VerticalGrid>

            <VerticalGrid label="Pilih Waktu" md={6}>
              <MainController
                {...getProps("send_at.minutes")}
                rules={
                  watch("is_auto_send") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field }) => {
                  const split = field.value?.split(":") || [];

                  return (
                    <TimePicker
                      disabled={watch("is_auto_send") == 1}
                      hours={split[0]}
                      minutes={split[1]}
                      onChangeHours={(e) => {
                        split[0] = e;
                        field.onChange(split.join(":"));
                      }}
                      onChangeMinutes={(e) => {
                        split[1] = e;
                        field.onChange(split.join(":"));
                      }}
                      variant="v1"
                    />
                  );
                }}
              />
            </VerticalGrid>
          </Grid>
        </VerticalGrid>
        <VerticalGrid label={"Konten"}>
          <Grid container spacing={2}>
            <VerticalGrid md={12}>
              <MainController
                {...getProps("html_content")}
                rules={{
                  required: "Konten wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    multiline
                    variant="v1"
                    rows={8}
                    placeholder={`
                  Contoh: Selamat, Bapak/Ibu {{name}} telah mendapatkan bantuan sosial senilai 1.000.000
                `.trim()}
                  />
                )}
              />
            </VerticalGrid>
          </Grid>
          <Alert
            severity="info"
            icon={false}
            sx={{
              ".MuiAlert-icon": {
                alignItems: "start",
              },
              ".MuiAlert-message": {
                color: "#0C6CE2",
              },
            }}
          >
            <Box gap={1} display="flex" alignItems="center" fontSize="15px">
              <InfoIcon />
              <b>Petunjuk</b> <br />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
                gap: 2,
              }}
            >
              <span>
                {`{{name}}`}
                <Box component="span" sx={{ ml: 3, mr: 1 }}>{`->`}</Box>{" "}
                mengganti dengan nama dari data excel
              </span>
              <span>
                {`{{email}}`}{" "}
                <Box component="span" sx={{ ml: 2.9, mr: 1 }}>{`->`}</Box>{" "}
                mengganti dengan email dari data excel
              </span>
              <span>
                {`{{phone}}`}{" "}
                <Box component="span" sx={{ ml: 2, mr: 1 }}>{`->`}</Box>{" "}
                mengganti dengan nomor dari data excel
              </span>
            </Box>
          </Alert>
        </VerticalGrid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
