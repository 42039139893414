import EyeIcon from "assets/paper/EyeIcon";
import ClipboardIcon from "assets/paper/ClipboardIcon";
import TicketIcon from "assets/paper/TicketIcon";
import EyeIconv2 from "assets/paper/v2/EyeIconv2";
import PencilIconv2 from "assets/paper/v2/PencilIconv2";
import TrashIcon from "assets/paper/TrashIcon";
import DeleteIcon from "assets/paper/DeleteIcon";
import CancelIcon from "assets/paper/CancelIcon";

const IcRef = {
  eye: EyeIcon,
  eye2: EyeIconv2,
  pencil: PencilIconv2,
  trash: TrashIcon,
  clipboard: ClipboardIcon,
  ticket: TicketIcon,
  delete: DeleteIcon,
  cancel: CancelIcon,
};

export { IcRef };

export const putTitle = (type) => {
  switch (type) {
    case "eye":
    case "eye2":
      return "Detail";
    case "pencil":
      return "Edit";
    case "trash":
    case "delete":
      return "Delete";
    default:
      return "";
  }
};
