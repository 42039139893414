import PropTypes from "prop-types";
import { Add, Close } from "@mui/icons-material";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import React from "react";

const PageSection = ({
  data,
  section,
  setSection,
  handleDelete,
  handleAdd,
}) => {
  return (
    <Paper
      sx={{
        p: 0.5,
        borderRadius: 2,
        mt: 2,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Tabs
        value={section}
        onChange={(_, v) => {
          setSection(v);
        }}
        variant="scrollable"
        textColor="inherit"
        sx={{
          padding: "0px",
        }}
      >
        {data?.sections?.map((item, idx) => (
          <Tab
            key={idx}
            value={item}
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                Page {idx + 1}
                <Close
                  sx={{
                    fontSize: "14px",
                  }}
                  onClick={(e) => handleDelete(e, idx)}
                />
              </Box>
            }
          />
        ))}
      </Tabs>

      <Box
        onClick={handleAdd}
        sx={{
          backgroundColor: "#F6F6F6",
          width: "fit-content",
          height: "fit-content",
          padding: "6px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "10px",
          cursor: "pointer",
          display: "flex",
          borderRadius: 1,
        }}
      >
        <Add />
      </Box>
    </Paper>
  );
};

PageSection.propTypes = {
  data: PropTypes.any,
  handleAdd: PropTypes.any,
  handleDelete: PropTypes.func,
  section: PropTypes.any,
  setSection: PropTypes.func,
};

PageSection.defaultProps = {
  data: {},
  handleAdd: () => {},
  handleDelete: () => {},
  section: "",
  setSection: () => {},
};

export default PageSection;
