import {
  Box,
  Button,
  OutlinedInput,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { Controller, useForm } from "react-hook-form";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import RadioMobile from "components/Mobile/Radio";
import {
  changeFilter,
  changeFilterName,
  deleteFilter,
} from "redux/laporanTransaksi/TransferMasuk/slice";
import {
  getDetailTransaksi,
  getListTransferMasuk,
} from "redux/laporanTransaksi/action";
import DrawerAction from "components/Mobile/DrawerAction";
import LaporanTransaksiStatus from "components/Status/LaporanTransaksiStatus";
import useFetchData from "hook/useFetchData";
import DetailTransaksiMobile from "pages/LaporanTransaksi/DialogMobile";
import moment from "moment";

const headers = [
  {
    name: "Aktivitas",
    key: "trxActivity",
    width: 25,
  },
  {
    name: "Nama Pengirim",
    key: "trxFullName",
    width: 25,
  },
  {
    name: "Tgl. Transaksi",
    custom: (item) => {
      return moment(item.trxDate).format("DD / MM / YYYY");
    },
    width: 25,
  },
  {
    name: "Status",
    width: 25,
    custom: (item) => {
      return <LaporanTransaksiStatus value={item.trxStatus} auto />;
    },
  },
];
const _filter = [
  { label: "No. Transaksi", value: "trxReceipt" },
  { label: "Aktivitas", value: "trxActivity" },
  { label: "Nama", value: "trxFullName" },
];

const TransferMasukMobile = () => {
  const dispatch = useDispatch();

  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.transferMasuk);

  const fetchData = () => dispatch(getListTransferMasuk(params));

  useAutoFetch(fetchData, params, currentPage);

  const {
    formState: { errors },
    control,
    getValues,
    clearErrors,
    reset,
    resetField,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      filter: "",
      filterName: "",
    },
  });

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const handleChangeFilter = () =>
    dispatch(
      changeFilterName({
        name: getValues("filter"),
      })
    );

  const closeDialog = (event) => {
    toggleDrawer("filter", false)(event);
    reset();
    dispatch(
      changeFilterName({
        name: "",
      })
    );
    dispatch(deleteFilter());
  };

  const detail = useFetchData({
    action: getDetailTransaksi,
    snackbar: false,
    onSuccess: (_, event) => {
      toggleDrawer("detail", true)(event);
    },
  });

  const handleClickDetail = (item) => (event) => {
    detail.fetch(item.trxId, null, event);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={1}>
          <MobileBadge
            value={watch("filterName") || "Pilih Parameter"}
            active={Boolean(watch("filterName"))}
            onClick={toggleDrawer("filter", true)}
          />

          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile
          title="Daftar Transfer Masuk"
          list={list?.length}
          onFilter={watch("filterName")}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="No Transaksi"
            value="trxReceipt"
            hideDelete={true}
            handleClickDetail={handleClickDetail}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.filter}
        onClose={closeDialog}
        onOpen={toggleDrawer("filter", true)}
      >
        <DrawerTitle
          title="Pilih Parameter"
          toggle={toggleDrawer("filter", false)}
        />
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          <RadioMobile
            data={_filter}
            getProps={getProps}
            name="filter"
            checked={(item, field) => item.value == field.value}
            onClick={(field, item) => {
              dispatch(deleteFilter());
              field.onChange(item.value);
            }}
          />
        </Box>
        <DrawerAction
          onReset={() => resetField("filter")}
          onSubmit={(event) => {
            const label = _filter.find(
              (i) => i.value == getValues("filter")
            )?.label;
            setValue("filterName", label);
            toggleDrawer("filter", false)(event);
            handleChangeFilter();
          }}
        />
      </SwipeableDrawer>

      <DetailTransaksiMobile
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        data={detail?.response?.transaction}
        order={detail?.response?.order}
      />
    </>
  );
};

export default TransferMasukMobile;
