import { Check } from "@mui/icons-material";
import { Box, Checkbox } from "@mui/material";
import CheckIcon from "assets/paper/CheckIcon";
import React from "react";
import PropTypes from "prop-types";
import CheckSmallIcon from "assets/paper/CheckSmallIcon";
// import CheckIcon from "@mui/icons-material/Check";
const Square = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        border: "2px solid #4E4751",
        width: "20px",
        height: "20px",
        borderRadius: "4px",
        padding: "0px",
      }}
    />
  );
};

const Selected = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#7e696f",
        width: "20px",
        height: "20px",
        display: "flex",
        borderRadius: "4px",
        color: "#fff",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CheckSmallIcon />
    </Box>
  );
};

const MainCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      icon={<Square />}
      sx={{
        padding: 0.5,
        ...props.sx,
      }}
      checkedIcon={<Selected />}
    />
  );
};

MainCheckbox.propTypes = {
  sx: PropTypes.object,
};
MainCheckbox.defaultProps = {
  sx: {},
};
export default MainCheckbox;
