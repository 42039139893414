const getRole = () => {
  let permission = localStorage.getItem("permission-ntt");
  if (permission) {
    permission = JSON.parse(permission);
    let superV = permission.find((p) => p === "Web:AdjSp:All");
    let oprtr = permission.find((p) => p === "Web:AdjOps:All");
    let localRole = {
      isSuperVisor: superV ? true : false,
      isOperator: oprtr ? true : false,
    };
    return localRole;
  }
};

export default getRole;
