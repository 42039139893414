import moment from "moment";
import { optPekerjaan, optWargaNegara } from "../components/variabel";

export const ibuData = (data) => {
  return {
    alamat_ibu: data.alamat_ibu,
    nama_ibu: data.nama_ibu,
    no_nik_ibu: data.no_nik_ibu,
    kewarganegaraan_ibu: data.kewarganegaraan_ibu,
    kebangsaan_ibu: data.kebangsaan_ibu,
    pekerjaan_ibu: data.pekerjaan_ibu,
    tanggal_pernikahan_ibu: moment(
      new Date(data.tanggal_pernikahan_ibu)
    ).format("DD / MM / YYYY"),
    tanggal_lahir_ibu: moment(new Date(data.tanggal_lahir_ibu)).format(
      "DD / MM / YYYY"
    ),
    provinsi_ibu_id: data.provinsi_ibu_id,
    kota_ibu_id: data.kota_ibu_id,
    kecamatan_ibu_id: data.kecamatan_ibu_id,
    kelurahan_ibu_id: data.kelurahan_ibu_id,
    warding: {
      alamat_ibu_warding: data.alamat_ibu_warding,
      nama_ibu_warding: data.nama_ibu_warding,
      no_nik_ibu_warding: data.no_nik_ibu_warding,
      kewarganegaraan_ibu_warding: data.kewarganegaraan_ibu_warding,
      kebangsaan_ibu_warding: data.kebangsaan_ibu_warding,
      pekerjaan_ibu_warding: data.pekerjaan_ibu_warding,
      tanggal_pernikahan_ibu_warding: data.tanggal_pernikahan_ibu_warding,
      tanggal_lahir_ibu_warding: data.tanggal_lahir_ibu_warding,
      provinsi_ibu_warding: data.provinsi_ibu_warding,
      kota_ibu_warding: data.kota_ibu_warding,
      kecamatan_ibu_warding: data.kecamatan_ibu_warding,
      kelurahan_ibu_warding: data.kelurahan_ibu_warding,
    },
    lainnya: {
      pekerjaan_ibu_lainnya: data.pekerjaan_ibu_lainnya,
      penolong_kelahiran: data.penolong_kelahiran_lainnya,
    },
  };
};

export const IbuForm = ({
  provinsi,
  kota,
  kecamatan,
  kelurahan,
  setValue,
  getValues,
  setError,
}) => {
  return [
    {
      label: "Alamat Ibu",
      name: "ibu.alamat_ibu",
      warding: "ibu.warding.alamat_ibu_warding",
      data: "alamat_ibu",
      commentKey: "alamat_ibu_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Alamat Ibu wajib diisi",
      },
    },
    {
      label: "Nama Ibu",
      name: "ibu.nama_ibu",
      warding: "ibu.warding.nama_ibu_warding",
      data: "nama_ibu",
      commentKey: "nama_ibu_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama Ibu wajib diisi" },
    },
    {
      label: "No. NIK Ibu",
      name: "ibu.no_nik_ibu",
      warding: "ibu.warding.no_nik_ibu_warding",
      data: "no_nik_ibu",
      commentKey: "no_nik_ibu_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. NIK Ibu wajib diisi",
        maxLength: {
          message: "NO. NIK Ibu tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Warga Negara",
      name: "ibu.kewarganegaraan_ibu",
      warding: "ibu.warding.kewarganegaraan_ibu_warding",
      data: "kewarganegaraan_ibu",
      commentKey: "kewarganegaraan_ibu_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optWargaNegara,
      rules: { required: "Kewarganegaraan Ibu wajib diisi" },
    },
    // {
    //   label: "Kebangsaan",
    //   name: "ibu.kebangsaan_ibu",
    //   warding: "ibu.warding.kebangsaan_ibu_warding",
    //   data: "kebangsaan_ibu",
    //   commentKey: "kebangsaan_ibu_warding",
    //   comment: "",
    //   value: "",
    //   openNotes: false,
    //   openComment: false,
    //   type: "select",
    //   options: optWargaNegara,
    //   rules: { required: "Kebangsaan wajib diisi" },
    // },
    {
      label: "Pekerjaan",
      name: "ibu.pekerjaan_ibu",
      warding: "ibu.warding.pekerjaan_ibu_warding",
      data: "pekerjaan_ibu",
      commentKey: "pekerjaan_ibu_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPekerjaan,
      rules: { required: "Pekerjaan wajib diisi" },
    },
    {
      label: "Tanggal Nikah",
      name: "ibu.tanggal_pernikahan_ibu",
      warding: "ibu.warding.tanggal_pernikahan_ibu_warding",
      data: "tanggal_pernikahan_ibu",
      commentKey: "tanggal_pernikahan_ibu_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "date",
      rules: { required: "Tanggal Nikah wajib diisi" },
    },
    {
      label: "Tanggal Lahir",
      name: "ibu.tanggal_lahir_ibu",
      warding: "ibu.warding.tanggal_lahir_ibu_warding",
      data: "tanggal_lahir_ibu",
      commentKey: "tanggal_lahir_ibu_wardingF",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "date",
      rules: { required: "Tanggal Lahir wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_ibu_id",
      warding: "ibu.warding.provinsi_ibu_warding",
      name: "ibu.provinsi_ibu_id",
      value: "",
      comment: "",
      commentKey: "provinsi_ibu_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.ibu[7].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi ibu wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "ibu.kota_ibu_id",
      warding: "ibu.warding.kota_ibu_warding",
      data: "kota_ibu_id",
      value: "",
      comment: "",
      commentKey: "kota_ibu_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!getValues("ibu.provinsi_ibu_id")) {
          return setError("forms.ibu[8].value", {
            type: "noid",
            message: "Pilih Provinsi ibu Dahulu",
          });
        }
        kota.fetch(getValues("ibu.provinsi_ibu_id"));
        setValue("forms.ibu[8].loading", true);
      },
      loading: true,
      rules: { required: "Kota ibu wajib diisi" },
    },
    {
      name: "ibu.kecamatan_ibu_id",
      warding: "ibu.warding.kecamatan_ibu_warding",
      label: "Kecamatan",
      data: "kecamatan_ibu_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_ibu_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("ibu.kota_ibu_id"))) {
          return setError("ibu.kecamatan_ibu_id[9].value", {
            type: "noid",
            message: "Pilih kota ibu Terlebih Dahulu",
          });
        }
        kecamatan.fetch(getValues("ibu.kota_ibu_id"));
        setValue("forms.ibu[9].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan ibu wajib diisi" },
    },
    {
      name: "ibu.kelurahan_ibu_id",
      warding: "ibu.warding.kelurahan_ibu_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_ibu_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_ibu_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("ibu.kecamatan_ibu_id"))) {
          return setError("forms.ibu[10].value", {
            type: "noid",
            message: "Pilih Kecamatan ibu Terlebih Dahulu",
          });
        }
        kelurahan.fetch(getValues("ibu.kecamatan_ibu_id"));
        setValue("forms.ibu[10].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan ibu wajib diisi" },
    },
  ];
};
