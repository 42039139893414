import { Box } from "@mui/material";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainTable from "components/Table/MainTable";
import ExportHelper from "helper/ExportHelper";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  deleteDokumen,
  getListDokumenAll,
  getListFormulir,
} from "redux/CMS/action";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/CMS/Formulir/slice";
import Dialog from "./Dialog";

const CMSFormulir = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.CMSFormulir);

  const [open, setOpen] = useState({
    delete: false,
    dialog: false,
  });

  const id = useRef();

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListFormulir(params));

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "Nama Dokumen",
      custom: (item) => {
        return (
          <>
            {item?.form_title ?? "-"}
            {item?.is_draft == "1" && (
              <Box
                sx={{
                  color: "#fff",
                  backgroundColor: "#cc1d15",
                  borderRadius: "4px",
                  padding: "4px",
                  display: "inline-flex",
                  fontSize: "14px",
                  marginLeft: "5px",
                }}
              >
                Draft
              </Box>
            )}
          </>
        );
      },
      width: 50,
    },
    {
      name: "Tipe",
      custom: (item) => {
        return (
          <>{parseInt(item?.is_multi) ? "Multi Halaman" : "Satu Halaman"}</>
        );
      },
      width: 20,
    },
    {
      name: "Tanggal Dibuat",
      custom: (item) => {
        return item?.created_at
          ? moment(item?.created_at).format("DD / MM / YYYY")
          : "-";
      },
      align: "center",
      width: 26,
    },
  ];

  const filter = [{ label: "Nama Dokumen", value: "form_title" }];

  const filterData = [
    {
      label: "Tipe",
      key: "is_multi",
      type: "switch",
      options: [
        { value: 0, label: "Single Page" },
        { value: 1, label: "Multi Page" },
      ],
    },
    {
      label: "Draft",
      options: [
        { value: 0, label: "Tidak" },
        { value: 1, label: "Ya" },
      ],
      key: "is_draft",
      type: "switch",
    },
  ];

  const exportData = useFetchData({
    action: getListDokumenAll,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "dokumen",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const deleteData = useFetchData({
    action: deleteDokumen,
    message: "Dokumen Berhasil di hapus",
    onFinal: () => {
      setOpen({
        ...open,
        delete: false,
      });
      fetchData();
    },
  });

  return (
    <>
      <Page title="CMS - FORMULIR" />

      <MainCard
        sx={{
          mt: 2,
          borderRadius: "8px",
        }}
        title="Daftar Formulir"
        onClick={() =>
          setOpen({
            ...open,
            dialog: true,
          })
        }
      >
        <MainCardHeader
          filter={filter}
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          filterData={filterData}
          addFilter={addFilter}
          title="Filter Banner"
          onChangeExport={(type) => handleExport(type)}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="form_title"
          detail={[
            {
              type: "link",
              url_key: "master-data/cms/formulir:id",
              tooltip: "Lihat Detail Formulir",
              variant: "icon:eye",
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
      />

      <DeleteDialog
        open={open.delete}
        loading={deleteData.loading}
        onClose={() =>
          setOpen({
            ...open,
            delete: false,
          })
        }
        onSubmit={() => {
          deleteData.fetch(id.current);
        }}
      />
    </>
  );
};

export default CMSFormulir;
