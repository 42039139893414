import PropTypes from "prop-types";
import React from "react";
import { Chip, OutlinedInput } from "@mui/material";
import { Close } from "@mui/icons-material";

function CustomMultiSelectInput({
  dataArray = [],
  handleDelete = (index) => {},
  getChipLabel = (data) => data.name,
  placeHolder = "Select",
  onEnter = (val) => {},
  onChange = () => {},
  showDataOnly = false,
  wrap = true,
  chipStyle = { whiteSpace: "nowrap" },
  classname = "",
  sx = {},
}) {
  return (
    <>
      {!showDataOnly && (
        <>
          <OutlinedInput
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                let val = e.target.value.trim();
                if (val) {
                  onEnter(val);
                  e.target.value = "";
                }

                return false;
              }
            }}
            onChange={onChange}
            placeholder={placeHolder}
          />
        </>
      )}
      {dataArray && dataArray.length !== 0 && (
        <div
          style={{
            display: "flex",
            gap: "8px",
            marginTop: "8px",
            flexWrap: wrap ? "wrap" : "nowrap",
          }}
        >
          {dataArray.map((item, index) => (
            <div key={index}>
              {showDataOnly && (
                <Chip
                  style={chipStyle}
                  variant="outlined"
                  label={getChipLabel(item)}
                  sx={{
                    fontSize: "16px",
                    height: "auto",
                    padding: "30px 30px",
                    span: {
                      whiteSpace: "normal",
                    },
                    ...sx,
                  }}
                />
              )}
              {!showDataOnly && (
                <Chip
                  style={chipStyle}
                  variant="outlined"
                  className={classname}
                  onDelete={() => handleDelete(index)}
                  onClick={() => handleDelete(index)}
                  label={getChipLabel(item)}
                  sx={{
                    fontSize: "16px",
                    height: "auto",
                    padding: "2px 0px",
                    borderRadius: "20px",
                    span: {
                      whiteSpace: "normal",
                    },
                  }}
                  deleteIcon={<Close sx={{ marginLeft: "3px" }} />}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

CustomMultiSelectInput.propTypes = {
  chipStyle: PropTypes.any,
  classname: PropTypes.string,
  dataArray: PropTypes.array,
  getChipLabel: PropTypes.func,
  handleDelete: PropTypes.func,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeHolder: PropTypes.string,
  showDataOnly: PropTypes.bool,
  sx: PropTypes.object,
  wrap: PropTypes.bool,
};

CustomMultiSelectInput.defaultProps = {
  chipStyle: null,
  classname: null,
  dataArray: null,
  getChipLabel: null,
  handleDelete: null,
  onChange: null,
  onEnter: null,
  placeHolder: null,
  showDataOnly: null,
  sx: null,
  wrap: null,
};

export default CustomMultiSelectInput;
