import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { defaultValues, statusList } from "./variables";
import { Controller, useForm } from "react-hook-form";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import { newPenerbit, updatePenerbit } from "redux/penerbit/action";
import { OutlinedInput } from "@mui/material";
import Autocomplete from "components/AutoComplete/Autocomplete";

const Dialog = ({ open, onClose, refresh, isEdit, data, id, setRes }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    clearErrors,
  } = useForm({
    defaultValues: { ...defaultValues },
    mode: "onChange",
  });

  const action = useFetchData({
    action: !id ? newPenerbit : updatePenerbit,
    reset: resetForm,
    message: !id ? "Berhasil menambah penerbit baru" : "Berhasil edit penerbit",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (data) {
      resetForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async (data) => {
    await action.fetch(data, id);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Tambah Penerbit Voucher"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="sm"
    >
      <HorizontalGrid label={"Penerbit"}>
        <MainController
          {...getProps("IssuerName")}
          rules={{
            required: "Nama penerbit wajib diisi",
          }}
          render={({ field, fieldState: { invalid } }) => (
            <OutlinedInput
              {...field}
              error={invalid}
              placeholder="Masukan Nama Penerbit"
            />
          )}
        />
      </HorizontalGrid>
      <HorizontalGrid label={"Kode Penerbit"}>
        <MainController
          {...getProps("IssuerCode")}
          rules={{
            required: "Kode penerbit wajib diisi",
            maxLength: {
              message: "Input tidak boleh melebihi 5 karakter",
              value: 5,
            },
          }}
          render={({ field, fieldState: { invalid } }) => (
            <OutlinedInput
              {...field}
              error={invalid}
              placeholder="Masukan Kode Penerbit"
            />
          )}
        />
      </HorizontalGrid>
      <HorizontalGrid label={"Status"}>
        <MainController
          {...getProps("IssuerStatus")}
          rules={{ required: "Status penerbit wajib diisi" }}
          render={({ field, fieldState: { invalid } }) => (
            <Autocomplete
              {...field}
              options={statusList}
              value={
                statusList.find((item) => item.value === field.value) || null
              }
              error={invalid}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv == null) {
                  field.onChange(1);
                }
                field.onChange(nv.value);
              }}
            />
          )}
        />
      </HorizontalGrid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
