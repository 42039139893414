import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getListManagementMerchant } from "./action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
  },
  totalItems: 0,
  message: "",
  filterName: "Bisnis",
  loading: false,
};

export const managementMerchantSlice = createSlice({
  name: "managementMerchant",
  initialState,
  reducers: pagination,
  extraReducers: {
    [getListManagementMerchant.pending]: (state) => {
      state.loading = true;
    },
    [getListManagementMerchant.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListManagementMerchant.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
  changeType,
} = managementMerchantSlice.actions;

export default managementMerchantSlice.reducer;
