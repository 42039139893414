import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getListTransferKeluar } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const laporanTransaksiTransferKeluarSlice = createSlice({
  name: "laporanTransaksiTransferKeluar",
  initialState,
  reducers: pagination,
  extraReducers: {
    [getListTransferKeluar.pending]: (state) => {
      state.loading = true;
    },
    [getListTransferKeluar.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListTransferKeluar.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
  changeType,
} = laporanTransaksiTransferKeluarSlice.actions;

export default laporanTransaksiTransferKeluarSlice.reducer;
