import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  0: {
    label: "Dibuat",
    background: "#e7f4ed",
    color: "#0C6CE2",
  },
  1: {
    label: "Disetujui",
    background: "#e7f4ed",
    color: "#129350",
  },
  2: {
    label: "Dijadwalkan",
    background: "#FEF8E8",
    color: "#F5B419",
  },
  3: {
    label: "Dibatalkan",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
  4: {
    label: "Proses",
    background: "#FEF8E8",
    color: "#F5B419",
  },
  5: {
    label: "Terkirim",
    background: "#e7f4ed",
    color: "#129350",
  },
  6: {
    label: "Gagal",
    background: "#f5e3e3",
    color: "#cc1d15",
  },
};

const BroadcastStatus = ({ value, ...props }) => {
  let selected = color[value];

  return (
    <StatusChip {...props} {...selected} minWidth="120px" maxWidth="120px" />
  );
};

BroadcastStatus.defaultProps = {
  value: 0,
};

BroadcastStatus.propTypes = {
  value: PropTypes.any,
};

export default BroadcastStatus;
