import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const MyBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => ({
  backgroundColor: "#F0F4FD",
  display: "flex",
  gap: "5px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRadius: "16px",
  alignItems: "center",
  color: "#ADADAE",
  ...(active && {
    outline: "1px solid #cc1d15",
    color: "#cc1d15",
  }),
}));

const Badgev2 = ({ value, active, ...props }) => {
  return (
    <MyBox active={active} {...props}>
      <Typography
        component="span"
        variant="text2"
        sx={{ display: "flex", gap: 0.5, color: "inherit" }}
      >
        <Box component="span" sx={{ whiteSpace: "nowrap" }}>
          {value}
        </Box>
      </Typography>
    </MyBox>
  );
};

Badgev2.propTypes = {
  value: PropTypes.any,
  active: PropTypes.bool,
};

Badgev2.defaultProps = {
  value: "",
  active: false,
};

export default Badgev2;
