import { optJenisKelamin, optPekerjaan } from "../components/variabel";

export const pelaporData = (data) => {
  return {
    nama_pelapor: data.nama_pelapor,
    nik_pelapor: data.nik_pelapor,
    umur_pelapor: data.umur_pelapor,
    jenis_kelamin_pelapor: data.jenis_kelamin_pelapor,
    pekerjaan_pelapor: data.pekerjaan_pelapor,
    provinsi_pelapor_id: data.provinsi_pelapor_id,
    kota_pelapor_id: data.kota_pelapor_id,
    kecamatan_pelapor_id: data.kecamatan_pelapor_id,
    kelurahan_pelapor_id: data.kelurahan_pelapor_id,
    warding: {
      nama_pelapor_warding: data.nama_pelapor_warding,
      nik_pelapor_warding: data.nik_pelapor_warding,
      umur_pelapor_warding: data.umur_pelapor_warding,
      jenis_kelamin_pelapor_warding: data.jenis_kelamin_pelapor_warding,
      pekerjaan_pelapor_warding: data.pekerjaan_pelapor_warding,
      provinsi_pelapor_warding: data.provinsi_pelapor_warding,
      kota_pelapor_warding: data.kota_pelapor_warding,
      kecamatan_pelapor_warding: data.kecamatan_pelapor_warding,
      kelurahan_pelapor_warding: data.kelurahan_pelapor_warding,
    },
    lainnya: {
      pekerjaan_pelapor_lainnya: data.pekerjaan_pelapor_lainnya || "",
    },
  };
};

export const PelaporForm = ({
  provinsi,
  kota,
  kecamatan,
  kelurahan,
  setValue,
  getValues,
  setError,
}) => {
  return [
    {
      label: "Nama",
      name: "pelapor.nama_pelapor",
      warding: "pelapor.warding.nama_pelapor_warding",
      data: "nama_pelapor",
      commentKey: "nama_pelapor_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama pelapor wajib diisi" },
    },
    {
      label: "NIK",
      name: "pelapor.nik_pelapor",
      warding: "pelapor.warding.nik_pelapor_warding",
      data: "nik_pelapor",
      commentKey: "nama_pelapor_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. NIK pelapor wajib diisi",
        maxLength: {
          message: "NO. NIK pelapor tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Umur",
      name: "pelapor.umur_pelapor",
      warding: "pelapor.warding.umur_pelapor_warding",
      data: "umur_pelapor",
      value: "",
      comment: "",
      commentKey: "umur_pelapor_warding",
      openNotes: false,
      openComment: false,
      rules: { required: "Umur pelapor wajib diisi" },
    },
    {
      label: "Jenis Kelamin",
      data: "jenis_kelamin_pelapor",
      warding: "pelapor.warding.jenis_kelamin_pelapor_warding",
      name: "pelapor.jenis_kelamin_pelapor",
      value: "",
      comment: "",
      commentKey: "jenis_kelamin_pelapor_warding",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optJenisKelamin,
      rules: { required: "Jenis Kelamin pelapor wajib diisi" },
    },
    {
      label: "Pekerjaan",
      data: "pekerjaan_pelapor",
      name: "pelapor.pekerjaan_pelapor",
      warding: "pelapor.warding.pekerjaan_pelapor_warding",
      lainnyaKey: "pelapor.lainnya.pekerjaan_pelapor_lainnya",
      lainnyaData: "pekerjaan_pelapor_lainnya",
      value: "",
      comment: "",
      commentKey: "pekerjaan_pelapor_warding",
      lainnya: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPekerjaan,
      rules: { required: "Pekerjaan pelapor wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_pelapor_id",
      warding: "pelapor.warding.provinsi_pelapor_warding",
      name: "pelapor.provinsi_pelapor_id",
      value: "",
      comment: "",
      commentKey: "provinsi_pelapor_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.pelapor[5].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi pelapor wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "pelapor.kota_pelapor_id",
      warding: "pelapor.warding.kota_pelapor_warding",
      data: "kota_pelapor_id",
      value: "",
      comment: "",
      commentKey: "kota_pelapor_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!getValues("pelapor.provinsi_pelapor_id")) {
          return setError("forms.pelapor[6].value", {
            type: "noid",
            message: "Pilih Provinsi pelapor Dahulu",
          });
        }
        kota.fetch(getValues("pelapor.provinsi_pelapor_id"));
        setValue("forms.pelapor[6].loading", true);
      },
      loading: true,
      rules: { required: "Kota pelapor wajib diisi" },
    },
    {
      name: "pelapor.kecamatan_pelapor_id",
      warding: "pelapor.warding.kecamatan_pelapor_warding",
      label: "Kecamatan",
      data: "kecamatan_pelapor_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_pelapor_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("pelapor.kota_pelapor_id"))) {
          return setError("pelapor.pelapor[7].value", {
            type: "noid",
            message: "Pilih kota pelapor Terlebih Dahulu",
          });
        }
        kecamatan.fetch(getValues("pelapor.kota_pelapor_id"));
        setValue("forms.pelapor[7].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan pelapor wajib diisi" },
    },
    {
      name: "pelapor.kelurahan_pelapor_id",
      warding: "pelapor.warding.kelurahan_pelapor_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_pelapor_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_pelapor_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("pelapor.kecamatan_pelapor_id"))) {
          return setError("forms.pelapor[7].value", {
            type: "noid",
            message: "Pilih Kecamatan pelapor Terlebih Dahulu",
          });
        }
        kelurahan.fetch(getValues("pelapor.kecamatan_pelapor_id"));
        setValue("forms.pelapor[7].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan pelapor wajib diisi" },
    },
  ];
};
