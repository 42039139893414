import { Box, Typography } from "@mui/material";
import TimeIcon from "assets/penerimaBantuan/voucher/TimeIcon";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const ImgVoucher = ({ dataVoucher }) => {
  return (
    <Box position="relative">
      {/* header */}
      {dataVoucher?.IsImage === 2 ? (
        <Box
          sx={{
            position: "absolute",
            color: "#fff",
            left: "20px",
            top: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              color: "#fff",
            }}
          >
            Voucer
          </Typography>
        </Box>
      ) : (
        ""
      )}
      {/* body */}
      <Typography
        sx={{
          position: "absolute",
          color: "#fff",
          right: "20px",
          top: "10px",
          fontWeight: "400",
          fontSize: "10px",
          borderRadius: "4px",
          backgroundColor: "#921f1a",
          padding: "3px 10px",
        }}
      >
        {dataVoucher?.voucher}
      </Typography>
      {dataVoucher?.IsImage === 2 ? (
        <Typography
          sx={{
            position: "absolute",
            bottom: "65px",
            left: "20px",
            fontWeight: "500",
            color: "#fff",
            fontSize: "22px",
          }}
        >
          {dataVoucher?.VoucherName}
        </Typography>
      ) : (
        ""
      )}
      {/* Circle */}
      <Box
        sx={{
          padding: "15px",
          backgroundColor: " #fff",
          position: "absolute",
          top: "70px",
          left: "-15px",
          borderRadius: "50%",
        }}
      ></Box>
      <Box
        sx={{
          padding: "15px",
          backgroundColor: " #fff",
          position: "absolute",
          top: "70px",
          right: "-15px",
          borderRadius: "50%",
        }}
      ></Box>
      <Box component={"img"} src={dataVoucher?.Gambar} sx={{ width: "100%" }} />

      {/* footer */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "4px",
          paddingTop: "14px",
          paddingLeft: "10px",
          paddingRight: "20px",
          paddingBottom: "10px",
          marginTop: "-7px",
          boxShadow: (theme) => theme.shadows[5],
        }}
      >
        <TimeIcon />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "600",
              color: "#979797",
              marginLeft: "10px",
            }}
          >
            Masa Berlaku
          </Typography>
          <Box
            sx={{
              fontSize: "10px",
              fontWeight: "600",
              color: "#111",
              marginLeft: "10px",
            }}
          >
            {moment(dataVoucher?.StartDate).format("DD MMM YYYY ")} -
            {moment(dataVoucher?.EndDate).format(" DD MMM YYYY")}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ImgVoucher.propTypes = {
  dataVoucher: PropTypes.shape({
    EndDate: PropTypes.any,
    Gambar: PropTypes.any,
    IsImage: PropTypes.number,
    StartDate: PropTypes.any,
    VoucherName: PropTypes.any,
    voucher: PropTypes.any,
  }),
};

ImgVoucher.defaultProps = {
  dataVoucher: {},
};

export default ImgVoucher;
