import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import LocationMarker from "assets/pengguna/location.png";
import L from "leaflet";

function Square({ latitude, longitude, onChange }) {
  const map = useMap();

  const pinMB = L.icon({
    iconUrl: LocationMarker,
    iconSize: [35, 35],
  });

  useEffect(() => {
    if (longitude != 0 && latitude != 0) {
      map.setView([latitude, longitude], map.getZoom());
    }
  }, [longitude, latitude]);

  useEffect(() => {
    if (onChange) {
      map.locate().on("locationfound", (e) => {
        onChange(e.latlng);
        map.setView(e.latlng, map.getZoom());
      });

      map.on("drag", () => {
        onChange(map.getCenter(), map.getZoom());
        map.setView(map.getCenter(), map.getZoom());
      });

      map.on("click", () => {
        map.locate();
      });
    }
  }, []);

  return longitude === null ? null : (
    <Marker position={[latitude, longitude]} icon={pinMB}></Marker>
  );
}

Square.propTypes = {
  latitude: PropTypes.any,
  longitude: PropTypes.any,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

Square.defaultProps = {
  latitude: 0,
  longitude: 0,
  onChange: false,
};

const MainMap = ({ latitude, longitude, onChange }) => {
  return (
    <MapContainer
      style={{ minHeight: "250px", borderRadius: "6px" }}
      center={[parseFloat(latitude), parseFloat(longitude)]}
      zoom={30}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Square
        size={1000}
        latitude={latitude}
        longitude={longitude}
        onChange={onChange}
      />
    </MapContainer>
  );
};

MainMap.propTypes = {
  latitude: PropTypes.any,
  longitude: PropTypes.any,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

MainMap.defaultProps = {
  latitude: 0,
  longitude: 0,
  onChange: false,
};

export default MainMap;
