import PropTypes from "prop-types";
import React, { memo } from "react";
import { Box, Collapse, OutlinedInput } from "@mui/material";
import MainController from "components/Controller/MainController";
import DatePicker2 from "components/Picker/DatePicker2";
import TimePicker from "components/Picker/TimePicker";
import Autocomplete from "components/AutoComplete/Autocomplete";

const DynamicInput = memo(
  ({
    type,
    field,
    invalid,
    options,
    onKeyDown,
    loading,
    set,
    index,
    getProps,
    setValue,
    getValues,
    ...item
  }) => {
    switch (type) {
      case "time":
        const split = field.value.split(":");
        return (
          <TimePicker
            hours={split[0]}
            minutes={split[1]}
            seconds={split[2]}
            withSeconds
            onChangeSeconds={(e) => {
              split[2] = e;
              field.onChange(split.join(":"));
            }}
            onChangeHours={(e) => {
              split[0] = e;
              field.onChange(split.join(":"));
            }}
            onChangeMinutes={(e) => {
              split[1] = e;
              field.onChange(split.join(":"));
            }}
            variant="v3"
          />
        );
      case "date":
        return (
          <DatePicker2
            variant="v3"
            // format={"DD / MM / YYYY"}
            {...field}
            error={invalid}
            placeholder="DD/MM/YYYY"
          />
        );
      case "select":
        return (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            gap={field.value.toLowerCase().includes("lainnya") ? 1 : 0}
          >
            <Autocomplete
              variant="v3"
              {...field}
              options={options}
              value={options.find((item) => item.value == field.value) || null}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv == null) {
                  field.onChange("");
                }

                if (nv.value.toLowerCase().includes("lainnya")) {
                  setValue(
                    `forms.${set}.${index}.lainnya`,
                    getValues(item.lainnyaKey)
                  );
                }
                field.onChange(nv.value);
              }}
            />

            <Collapse in={field.value.toLowerCase().includes("lainnya")}>
              <MainController
                {...getProps(`forms.${set}.${index}.lainnya`)}
                defaultValue=""
                rules={{required:false}}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    variant="v3"
                    {...field}
                    placeholder="Masukan Lainnya"
                    error={invalid}
                    onKeyDown={onKeyDown}
                  />
                )}
              />
            </Collapse>
          </Box>
        );
      case "autocomplete":
        return (
          <Autocomplete
            variant="v3"
            {...field}
            options={!loading ? options : []}
            value={options.find((item) => item.id == field.value) || null}
            getOptionLabel={(option) => option.nama}
            onOpen={() => (item.onOpen ? item.onOpen(item) : null)}
            loading={loading || false}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, nv) => {
              if (nv == null) {
                field.onChange("");
              }
              field.onChange(nv.id);
            }}
          />
        );

      default:
        return (
          <OutlinedInput
            variant="v3"
            {...field}
            error={invalid}
            onKeyDown={onKeyDown}
          />
        );
    }
  }
);

DynamicInput.displayName = "di";

DynamicInput.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  from: PropTypes.any,
  getProps: PropTypes.func,
  getValues: PropTypes.any,
  index: PropTypes.any,
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  onKeyDown: PropTypes.func,
  options: PropTypes.any,
  set: PropTypes.any,
  setValue: PropTypes.any,
  type: PropTypes.string,
};

DynamicInput.defaultProps = {
  field: null,
  invalid: false,
  type: "",
  set: null,
  index: null,
  options: null,
  from: null,
  loading: false,
  onKeyDown: () => {},
  getProps: () => {},
  getValues: () => {},
  setValue: () => {},
};
export default DynamicInput;
