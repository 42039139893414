import { Grid, Typography } from "@mui/material";
import PeriodeFormat from "components/Format/PeriodeFormat";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import MainStatus from "components/Status/MainStatus";
import Validasi from "components/Status/Validasi";
import MainTable from "components/Table/MainTable";
import ExportHelper from "helper/ExportHelper";
import useFetchData from "hook/useFetchData";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { exportUserSubsidi, getDetailSubsidi } from "redux/subsidiBansos/action";
import { validasi_id } from "./variables";

const headers = [
  {
    name: "No. Kartu",
    key: "PrefixCode",
    width: 20,
  },
  {
    name: "Nama Kartu Subsidi",
    key: "CardName",
    width: 35,
  },
  {
    name: "Saldo",
    custom: (item) => {
      return (
        <PeriodeFormat StartDate={item?.StartDate} EndDate={item?.EndDate} />
      );
    },
    width: 25,
  },
  {
    name: "Status Kartu",
    custom: (item) => {
      return <AktifStatus value={item?.status} sx={{ maxWidth: "200px" }} />;
    },
    width: 20,
  },
];

const SubsidiBansosDetail = () => {
  const [informasiData, setInformasiData] = useState([]);

  const { id } = useParams();
  const { fetch, response, loading } = useFetchData({
    action: getDetailSubsidi,
    snackbar: false,
  });

  useEffect(() => {
    fetch(id);
  }, []);

  useEffect(() => {
    if (response) {
      setInformasiData([]);
      const i = response.data_subsidi;
      let inf = [];
      let test = {
        "Nama Program Bantuan": {
          name: i.NamaProgram,
        },
        "Periode Bantuan": {
          startDate: i.TanggalMulai,
          endDate: i.TanggalSelesai,
          format: "periode",
        },
        "Kode Penyelenggara": {
          name: i.KodePenyelenggara,
        },
        "Nama Penyelenggara": {
          name: i.NamaPenyelenggara,
        },
        "Jenis ID": {
          name: i.JenisId,
          format: "jenisid",
        },
        "Validasi KK": {
          name: i.ValidasiKk,
          format: "validasikk",
        },
        Status: {
          name: i.Status,
          format: "status",
        },
        "Tgl. Status": {
          name: i.TanggalStatus,
        },
        "Daftar Penerima Bantuan": {
          name: i.PenerimaBantuan,
          format: "download",
        },
      };

      for (const key in test) {
        inf.push({
          name: key,
          value: renderValue(test[key]),
        });
      }

      setInformasiData(inf);
    }
  }, [response]);

  const exportData = useFetchData({
    action: exportUserSubsidi,
    snackbar: false,
  });

  useEffect(() => {
    if (exportData.response) {
      const list = exportData.response.list;
      const keys = exportData.response?.keys;
      ExportHelper({
        type: "excel",
        data: list,
        filename: response?.data_subsidi?.PenerimaBantuan,
        opt: {
          header: keys,
        },
      });
    }
  }, [exportData.response]);

  const handleExport = async () => {
    await exportData.fetch(id);
  };

  const renderValue = (data) => {
    switch (data.format) {
      case "status":
        return <AktifStatus value={data.name} />;
      case "periode":
        return (
          <PeriodeFormat StartDate={data.startDate} EndDate={data.endDate} />
        );
      case "jenisid":
        return validasi_id.find((i) => i.value == data.name)?.label;
      case "validasikk":
        return <Validasi value={data.name} />;
      case "download":
        return (
          <Typography
            sx={{
              color: "#cc1d15",
              cursor: "pointer",
            }}
            variant="label"
            onClick={handleExport}
          >
            {data.name}
          </Typography>
        );
      default:
        return data.name || "-";
    }
  };

  return (
    <>
      <PageTitle
        title={`Detail Program ${response?.data_subsidi?.NamaProgram || ""}`}
        text="Dasbor / CSR Bansos / Program Bantuan / Kartu Subsidi / "
        lastText="Detail Program Bantuan"
      />
      <Grid container spacing={2}>
        <Grid xs={12} md={12} item>
          <MainCard
            title="Detail Program Bantuan"
            addButton={false}
            isEdit={false}
          >
            <MainLoading loading={loading} height={500}>
              {informasiData.map((item, i) => (
                <MainGrid
                  key={i}
                  label={item.name}
                  value={item.value}
                  labelMd={3}
                  valueMd={9}
                  striped="odd"
                  center={item.name == "Status"}
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>

        <Grid xs={12} item>
          <MainCard
            title="Daftar Kartu Subsidi"
            addButton={false}
            isEdit={false}
            margin={false}
          >
            <MainTable
              headers={headers}
              loading={loading}
              data={response?.daftar_kartu_subsidi || []}
              align="center"
              summaryKey="VoucherName"
              action=""
            />
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default SubsidiBansosDetail;
