import React from "react";

const BarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75275 17.9999H21.2527C21.4427 18.0002 21.6254 18.0726 21.7641 18.2024C21.9027 18.3322 21.987 18.5098 21.9998 18.6993C22.0126 18.8888 21.9531 19.0761 21.8332 19.2234C21.7132 19.3707 21.5419 19.467 21.3537 19.4929L21.2527 19.4999H2.75275C2.56137 19.5019 2.37649 19.4306 2.23596 19.3007C2.09544 19.1707 2.0099 18.992 1.99686 18.8011C1.98382 18.6101 2.04428 18.4214 2.16584 18.2736C2.2874 18.1258 2.46088 18.03 2.65075 18.0059L2.75275 17.9999ZM2.75275 11.5029H21.2527C21.4427 11.5032 21.6254 11.5756 21.7641 11.7054C21.9027 11.8352 21.987 12.0128 21.9998 12.2023C22.0126 12.3918 21.9531 12.5791 21.8332 12.7264C21.7132 12.8737 21.5419 12.97 21.3537 12.9959L21.2527 13.0029H2.75275C2.56137 13.0049 2.37649 12.9336 2.23596 12.8037C2.09544 12.6737 2.0099 12.495 1.99686 12.3041C1.98382 12.1131 2.04428 11.9244 2.16584 11.7766C2.2874 11.6288 2.46088 11.533 2.65075 11.5089L2.75275 11.5029ZM2.75175 5.00293H21.2517C21.4418 5.00299 21.6247 5.07517 21.7635 5.2049C21.9024 5.33463 21.9868 5.51223 21.9998 5.70181C22.0127 5.89139 21.9532 6.07882 21.8333 6.22623C21.7134 6.37364 21.542 6.47003 21.3537 6.49593L21.2517 6.50293H2.75175C2.56172 6.50287 2.3788 6.43069 2.23995 6.30096C2.1011 6.17123 2.01667 5.99363 2.00372 5.80405C1.99077 5.61447 2.05026 5.42704 2.17018 5.27963C2.2901 5.13222 2.4615 5.03583 2.64975 5.00993L2.75175 5.00293Z"
        fill="#616161"
      />
    </svg>
  );
};

export default BarIcon;
