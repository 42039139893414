import React from "react";

const Time = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3583 2 11.975C2 10.6083 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6417 2 12.025 2C13.3917 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM16.2066 13.5C16.6208 13.5 16.9566 13.1642 16.9566 12.75C16.9566 12.3358 16.6208 12 16.2066 12H12.825V7.75C12.825 7.33579 12.4892 7 12.075 7C11.6608 7 11.325 7.33579 11.325 7.75V13C11.325 13.2761 11.5489 13.5 11.825 13.5H16.2066ZM18.025 18.0125C16.375 19.6708 14.375 20.5 12.025 20.5C9.65833 20.5 7.64583 19.6708 5.9875 18.0125C4.32917 16.3542 3.5 14.35 3.5 12C3.5 9.63333 4.32917 7.625 5.9875 5.975C7.64583 4.325 9.65 3.5 12 3.5C14.3667 3.5 16.375 4.325 18.025 5.975C19.675 7.625 20.5 9.625 20.5 11.975C20.5 14.3417 19.675 16.3542 18.025 18.0125Z"
        fill="black"
      />
      <mask
        id="mask0_5896_6479"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3583 2 11.975C2 10.6083 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6417 2 12.025 2C13.3917 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM16.2066 13.5C16.6208 13.5 16.9566 13.1642 16.9566 12.75C16.9566 12.3358 16.6208 12 16.2066 12H12.825V7.75C12.825 7.33579 12.4892 7 12.075 7C11.6608 7 11.325 7.33579 11.325 7.75V13C11.325 13.2761 11.5489 13.5 11.825 13.5H16.2066ZM18.025 18.0125C16.375 19.6708 14.375 20.5 12.025 20.5C9.65833 20.5 7.64583 19.6708 5.9875 18.0125C4.32917 16.3542 3.5 14.35 3.5 12C3.5 9.63333 4.32917 7.625 5.9875 5.975C7.64583 4.325 9.65 3.5 12 3.5C14.3667 3.5 16.375 4.325 18.025 5.975C19.675 7.625 20.5 9.625 20.5 11.975C20.5 14.3417 19.675 16.3542 18.025 18.0125Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5896_6479)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default Time;
