import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  0: {
    label: "Diperiksa",
    background: "#edeced",
    color: "#4E4751",
  },
  1: {
    label: "Ditugaskan",
    background: "#e7f4ed",
    color: "#129350",
  },
  2: {
    label: "Diverifikasi",
    background: "#e7f4ed",
    color: "#2c8424",
  },
  3: {
    label: "Disetujui",
    background: "#e7f4ed",
    color: "#4c4650",
  },
  4: {
    label: "Kadaluarsa",
    background: "#F7B500",
    color: "#f8be21",
  },
  5: {
    label: "Ditolak",
    background: "#e7f4ed",
    color: "#cc1d15",
  },
  6: {
    label: "Anomali",
    background: "#FEF7E5",
    color: "#F7B500",
  },
};

const DigitalIDStatus = ({ value, ...props }) => {
  let selected = color[value];

  return (
    <StatusChip {...props} {...selected} minWidth="130px" maxWidth="130px" />
  );
};

DigitalIDStatus.defaultProps = {
  value: 0,
};

DigitalIDStatus.propTypes = {
  value: PropTypes.any,
};

export default DigitalIDStatus;
