import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";
import EndAdornment from "components/EndAdornment";

const Media = ({ item }) => {
  return (
    <InputComponent item={item}>
      <OutlinedInput
        placeholder={item?.property?.keterangan?.value || "Placeholder"}
        readOnly
        endAdornment={<EndAdornment text="Browser" />}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingRight: "0px",
          },
        }}
      />
    </InputComponent>
  );
};

Media.propTypes = {
  item: PropTypes.object,
};
Media.defaultProps = {
  item: {},
};
export default Media;
