import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import React from "react";
import DatePicker2 from "components/Picker/DatePicker2";
import Autocomplete from "components/AutoComplete/Autocomplete";

const DynamicInput = ({ field, type, open, invalid, ...p }) => {
  const onChangeValue = (a) => {
  }
  switch (type) {
    case "wilayah":
      return (
        <Autocomplete
          variant={open ? "none" : "v2"}
          {...field}
          options={p.loading ? [] : p.options || []}
          value={p?.options?.find((item) => item.nama == field.value) || null}
          isOptionEqualToValue={(option, value) =>
            option.nama == value.nama || null
          }
          getOptionLabel={(option) => option.nama || null}
          loading={p.loading}
          InputProps={{
            sx: {
              minHeight: "38px !important",
            },
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.nama}
              </li>
            );
          }}
          onInputChange={(e, val) => {
            if (e != null) {
              if(e.type == "change"){
                p.inputChange(val);
              }
            }
          }}
          defaultValue={field.value}
          readOnly={open}
          onChange={(e, nv) => {
            e.stopPropagation();
            if (nv == null) {
              return field.onChange("");
            }

            field.onChange(nv.nama);
          }}
        />
      );
    case "select":
      return (
        <Autocomplete
          variant={open ? "none" : "v2"}
          {...field}
          options={p.options}
          value={p.options?.find((item) => item.value == field.value) || null}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          InputProps={{
            sx: {
              minHeight: "38px !important",
            },
          }}
          readOnly={open}
          onChange={(_, nv) => {
            if (nv == null) {
              field.onChange("");
            }

            field.onChange(nv.value);
          }}
        />
      );
    case "date":
      return (
        <DatePicker2
          {...field}
          variant={open ? "none" : "v2"}
          error={invalid}
          value={field.value}
          readOnly={open}
          sx={{
            minHeight: "38px !important",
          }}
          defaultValue="-"
          //   format="DD/MM/YYYY"
          // placeholder="DD/MM/YYYY"
        />
      );

    default:
      return (
        <OutlinedInput
          {...field}
          error={invalid}
          value={field.value}
          placeholder="-"
          variant={open ? "none" : "v2"}
          readOnly={open}
          onKeyUp={onChangeValue}
          sx={{
            minHeight: "38px !important",
          }}
        />
      );
  }
};

DynamicInput.propTypes = {
  field: PropTypes.any,
  invalid: PropTypes.any,
  type: PropTypes.any,
  open: PropTypes.bool,
};

DynamicInput.defaultProps = {
  field: {},
  invalid: false,
  type: "",
  open: true,
};

export default DynamicInput;
