import {
  Box,
  Button,
  OutlinedInput,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { Controller, useForm } from "react-hook-form";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import RadioMobile from "components/Mobile/Radio";
import {
  changeFilterName,
  changeFilter,
  deleteFilter,
} from "redux/sentraLokasi/Kategori/slice";
import DrawerAction from "components/Mobile/DrawerAction";
import { getListKategori } from "redux/sentraLokasi/action";
import AktifStatus from "components/Status/AktifStatus";
import AccordionMobileDetail from "components/Mobile/AccordionMobileDetail";
import moment from "moment";
import { getListSubsidiBansos } from "redux/subsidiBansos/action";
import { useNavigate } from "react-router-dom";
import PeriodeFormat from "components/Format/PeriodeFormat";
import { getListVoucherBansos } from "redux/voucherBansos/action";

const headers = [
  {
    name: "Penyelenggara",
    width: 30,
    custom: (item) => {
      return `${item.kode_penyelenggara} - ${item.penyelenggara}`;
    },
  },
  {
    name: "Nama Program Bantuan",
    key: "nama_program",
    width: 30,
  },
  {
    name: "Periode Voucer Bantuan",
    custom: (item) => {
      return (
        <PeriodeFormat
          StartDate={item.tanggal_mulai}
          EndDate={item.tanggal_selesai}
        />
      );
    },
    width: 20,
  },
  {
    name: "Status Bantuan",
    width: 10,
    custom: (item) => {
      return <AktifStatus value={item.status} auto/>;
    },
    align: "left",
  },
];

const headersDetail = [
  {
    name: "Nama Kategori",
    key: "name",
    width: 30,
  },
  {
    name: "Nama Kategori (Inggris)",
    key: "name_en",
    width: 30,
  },
  {
    name: "Status ",
    custom: (item) => {
      return <AktifStatus value={parseInt(item?.is_active)} auto/>;
    },
    width: 10,
  },
  {
    name: "Tanggal Status",
    custom: (item) => {
      return moment(item?.created_at).isValid()
        ? moment(item?.created_at).format("DD / MM / YYYY")
        : "-";
    },
    align: "center",
    width: 20,
  },
  {
    name: "Tanggal Dibuat",
    custom: (item) => {
      return moment(item?.created_at).isValid()
        ? moment(item?.created_at).format("DD / MM / YYYY")
        : "-";
    },
    align: "center",
    width: 20,
  },
];

const _filter =  [
  { label: "Penyelenggara", value: "penyelenggara" },
  { label: "Nama Program", value: "nama_program" },
];

const VoucherBansosMobile = () => {
  const dispatch = useDispatch();

  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.voucherBansos);

  const navigate = useNavigate();

  const fetchData = async () => {
    await dispatch(getListVoucherBansos(params));
  };

  useAutoFetch(fetchData, currentPage, filterName);

  const {
    formState: { errors },
    control,
    getValues,
    clearErrors,
    reset,
    resetField,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      filter: "",
      filterName: "",
    },
  });

  const detail = useRef(null);

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const handleChangeFilter = () =>
    dispatch(
      changeFilterName({
        name: getValues("filter"),
      })
    );

  const closeDialog = (event) => {
    toggleDrawer("filter", false)(event);
    reset();
    dispatch(
      changeFilterName({
        name: "",
      })
    );
    dispatch(deleteFilter());
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={1}>
          <MobileBadge
            value={watch("filterName") || "Pilih Parameter"}
            active={Boolean(watch("filterName"))}
            onClick={toggleDrawer("filter", true)}
          />

          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile
          title="Program Voucer Bansos"
          list={list.length}
          onFilter={watch("filterName")}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="Nama Program Bantuan"
            value="nama_program"
            action={false}
            customAction={(item) => {
              return (
                <Box display="flex" flexDirection="column" gap={2}>
                  <Button
                    variant="secondary-outlined"
                    onClick={() =>
                      navigate(
                        `/csr-bansos/program-bantuan/voucer-bansos/penerima/${item.id}`
                      )
                    }
                  >
                    Detail Penerima Bantuan
                  </Button>
                  <Button
                    variant="secondary-outlined"
                    onClick={() =>
                      navigate(
                        `/csr-bansos/program-bantuan/voucer-bansos/voucher/${item.id}`
                      )
                    }
                  >
                    Detail Program Bantuan
                  </Button>
                </Box>
              );
            }}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.filter}
        onClose={closeDialog}
        onOpen={toggleDrawer("filter", true)}
      >
        <DrawerTitle
          title="Pilih Parameter"
          toggle={toggleDrawer("filter", false)}
        />
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          <RadioMobile
            data={_filter}
            getProps={getProps}
            name="filter"
            checked={(item, field) => item.value == field.value}
            onClick={(field, item) => {
              dispatch(deleteFilter());
              field.onChange(item.value);
            }}
          />
        </Box>
        <DrawerAction
          onReset={() => resetField("filter")}
          onSubmit={(event) => {
            const label = _filter.find(
              (i) => i.value == getValues("filter")
            )?.label;
            setValue("filterName", label);
            toggleDrawer("filter", false)(event);
            handleChangeFilter();
          }}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        onOpen={toggleDrawer("detail", true)}
      >
        <DrawerTitle
          title="Detail Kategori"
          toggle={toggleDrawer("detail", false)}
        />
        <AccordionMobileDetail headers={headersDetail} data={detail?.current} />
      </SwipeableDrawer>
    </>
  );
};

export default VoucherBansosMobile;
