import {
  Box,
  Button,
  Divider,
  OutlinedInput,
  Radio,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import AktifStatus from "components/Status/AktifStatus";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getListPengguna } from "redux/managementPengguna/action";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import DeleteDialog from "components/Mobile/DeleteDialog";
import { changeFilter } from "redux/managementPengguna/Pengguna/slice";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import AccordionMobileDetail from "components/Mobile/AccordionMobileDetail";

const headers = [
  {
    name: "Source",
    key: "Source",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => (
      <AktifStatus
        sx={{
          height: "28px",
        }}
        maxWidth="120px"
        minWidth="120px"
        value={parseInt(item.Status)}
      />
    ),
    width: 25,
  },
];

const headers2 = [
  {
    name: "Email ",
    key: "Email",
    width: 30,
  },
  {
    name: "Nama",
    key: "Fullname",
    width: 25,
  },
  {
    name: "Last Login",
    custom: (item) => {
      return moment().fromNow();
    },
    width: 30,
  },
  {
    name: "Source",
    key: "Source",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => (
      <AktifStatus
        sx={{
          height: "28px",
        }}
        maxWidth="120px"
        minWidth="120px"
        value={parseInt(item.Status)}
      />
    ),
    width: 25,
  },
];

const _role = [
  {
    label: "Super Admin",
    value: false,
  },
  {
    label: "Officer",
    value: false,
  },
  {
    label: "Supervisor",
    value: false,
  },
  {
    label: "Operator",
    value: false,
  },
  {
    label: "Admin",
    value: false,
  },
];

const _status = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

const PenggunaMobile = () => {
  const { list, loading, params, filterName } = useSelect(
    (state) => state.pengguna
  );

  const dispatch = useDispatch();
  const [dataDetail, setDataDetail] = useState({});

  const fetchData = () => dispatch(getListPengguna(params));

  const deleteRef = useRef(null);

  useAutoFetch(fetchData, params.page);

  const {
    formState: { errors },
    control,
    getValues,
    clearErrors,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      role: _role,
      status: null,
    },
  });

  const [dialog, setDialog] = useState({
    alert: true,
    detail: false,
    role: false,
    status: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const handleClickDetail = (item) => (event) => {
    setDataDetail(item);
    toggleDrawer("detail", true)(event);
  };

  const handleClickDelete = (item) => (event) => {
    deleteRef.current = {
      id: item.Id,
      name: item.Fullname,
    };
    toggleDrawer("delete", true)(event);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const countRole = watch("role").reduce((accumulator, obj) => {
    if (obj.value === true) {
      accumulator++;
    }
    return accumulator;
  }, 0);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={0.5}>
          <MobileBadge
            selected={countRole}
            active={Boolean(countRole)}
            value="Role"
            onClick={toggleDrawer("role", true)}
          />

          <MobileBadge
            value={
              watch("status") === 1
                ? "Aktif"
                : watch("status") === 0
                ? "Tidak Aktif"
                : "Status"
            }
            active={watch("status") != null}
            onClick={toggleDrawer("status", true)}
          />
          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile
          title="Daftar Pengguna"
          list={list?.length}
          onFilter={watch("filterName")}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="Email"
            value="Email"
            handleClickDetail={handleClickDetail}
            handleClickDelete={handleClickDelete}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        onOpen={toggleDrawer("detail", true)}
      >
        <DrawerTitle
          title="Detail Pengguna"
          toggle={toggleDrawer("detail", false)}
        />
        <AccordionMobileDetail headers={headers2} data={dataDetail} />
      </SwipeableDrawer>

      <DeleteDialog
        open={dialog.delete}
        loading={false}
        onClose={toggleDrawer("delete", false)}
        title="Hapus Pengguna"
        desc="Anda yakin ingin menghapus pengguna "
        name={deleteRef?.current?.name}
        onSubmit={() => {}}
      />

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.role}
        onClose={toggleDrawer("role", false)}
        onOpen={toggleDrawer("role", true)}
      >
        <DrawerTitle title="Filter Role" toggle={toggleDrawer("role", false)} />
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          {getValues("role").map((item, i) => (
            <MainController
              {...getProps(`role.${i}.value`)}
              key={i}
              render={({ field }) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  onClick={() => field.onChange(!field.value)}
                >
                  <Typography variant="text2">{item.label}</Typography>
                  <Radio sx={{ p: 0 }} checked={field.value} />
                </Box>
              )}
            />
          ))}
        </Box>

        <Box display="flex" gap={1.5} p={2}>
          <Button
            variant="secondary-outlined"
            sx={{
              width: "100%",
            }}
            onClick={() => resetField("role")}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            sx={{
              width: "100%",
            }}
          >
            Terapkan
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.status}
        onClose={toggleDrawer("status", false)}
        onOpen={toggleDrawer("status", true)}
      >
        <DrawerTitle
          title="Filter Status"
          toggle={toggleDrawer("status", false)}
        />
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          {_status.map((item, i) => (
            <MainController
              {...getProps(`status`)}
              key={i}
              render={({ field }) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  onClick={() => field.onChange(item.value)}
                >
                  <Typography variant="text2">{item.label}</Typography>
                  <Radio sx={{ p: 0 }} checked={item.value == field.value} />
                </Box>
              )}
            />
          ))}
        </Box>

        <Box display="flex" gap={1.5} p={2}>
          <Button
            variant="secondary-outlined"
            sx={{
              width: "100%",
            }}
            onClick={() => resetField("status")}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            sx={{
              width: "100%",
            }}
          >
            Terapkan
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default PenggunaMobile;
