import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import KependudukanStatus from "components/Status/Kependudukan";
import MainTable from "components/Table/MainTable";
import ExportHelper from "helper/ExportHelper";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { getListKTP, getListKTPExport } from "redux/kependudukan/action";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/kependudukan/KTP/slice";

const KependudukanKTP = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.kependudukanKTP);

  const dispatch = useDispatch();

  const fetchData = async () => {
    await dispatch(getListKTP(params));
  };

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "No. Registrasi",
      key: "no_registrasi",

      width: 20,
    },
    {
      name: "Nama",
      key: "nama",
      width: 30,
    },
    {
      name: "Status",
      custom: (item) => {
        return (
          <KependudukanStatus
            value={item.job_status}
            value2={item?.job_status_que}
            sx={{ maxWidth: "130px" }}
          />
        );
      },
      align: "left",
      width: 20,
    },
    {
      name: "Tanggal Permohonan",
      custom: (item) => {
        return moment(item.created_at).format("DD/MM/YYYY - HH:mm");
      },
      align: "center",
      width: 20,
    },
  ];

  const filter = [
    { label: "Nama", value: "nama" },
    { label: "No. Registrasi", value: "no_registrasi" },
  ];

  const filterData = [
    {
      label: "Status",
      key: "job_status",
      type: "switch",
      options: [
        {
          label: "Belum diverifikasi",
          value: 0,
        },
        {
          label: "Belum dikonfirmasi",
          value: 1,
        },
        {
          label: "Dikonfirmasi",
          value: 2,
        },
        {
          label: "Ditolak",
          value: 3,
        },
      ],
      width: "200px",
    },
    {
      label: "Tanggal",
      startDate: "start_date",
      endDate: "end_date",
      type: "daterange",
    },
  ];

  const exportData = useFetchData({
    action: getListKTPExport,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "kependudukan-ktp",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(
      {
        filter: {
          type: "m_ktp",
        },
      },
      null,
      data
    );
  };

  return (
    <>
      <Page
        title="Sentra Kependudukan - KTP"
        page={["Laporan Adjudicator", "Sentra Kependudukan"]}
        menu="KTP"
      />

      <MainCard
        sx={{
          mt: 2,
          borderRadius: "8px",
        }}
        title="Daftar Permohonan"
        addButton={false}
      >
        <MainCardHeader
          filter={filter}
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          filterData={filterData}
          addFilter={addFilter}
          title="Filter Kependudukan KTP"
          onChangeExport={(type) => handleExport(type)}
          width="700px"
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="nama"
          detail={[
            {
              type: "link",
              url_key: "laporan-adjudicator/sentra-kependudukan/ktp:id",
              tooltip: "Lihat Detail KTP",
              variant: "icon:eye",
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>
    </>
  );
};

export default KependudukanKTP;
