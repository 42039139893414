import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import DragCard from "./Card/DragCard";
import { getEmptyImage } from "react-dnd-html5-backend";

const Builder = ({ icon: Icon, name, description, type }) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "builder",
    item: { name, icon: Icon, type, name, description },
  }));

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return collected.isDragging ? (
    <div ref={dragPreview} />
  ) : (
    <Tooltip
      title="Geser dan taruh untuk menambahkan"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "100px",
          },
        },
      }}
      placement="left"
      ref={drag}
    >
      <span>
        <DragCard icon={Icon} name={name} description={description} />
      </span>
    </Tooltip>
  );
};

Builder.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.any,
};
Builder.defaultProps = {
  description: "",
  icon: null,
  name: "",
  type: null,
};
export default Builder;
