import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const MainEditCard = ({
  title,
  children,
  borderColor,
  margin,
  action,
  onEdit,
  onClickEdit,
  onClickSimpan,
  ...props
}) => {
  return (
    <Paper
      sx={{
        mt: margin ? 2 : 0,
        borderRadius: "8px",
        minHeight: "60px",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "64px",
          px: 2,
        }}
      >
        <Typography variant={"h2"}>{title}</Typography>
        {action && (
          <>
            {onEdit && (
              <Button
                variant="primary-outlined"
                onClick={onClickEdit}
                sx={{ padding: "10px 10px" }}
              >
                UBAH
              </Button>
            )}
            {!onEdit && (
              <Button
                variant="primary"
                onClick={onClickSimpan}
                sx={{ padding: "10px 10px" }}
              >
                Simpan
              </Button>
            )}
          </>
        )}
      </Box>
      <Divider
        sx={{
          borderColor: borderColor,
        }}
      />
      {children}
    </Paper>
  );
};

MainEditCard.propTypes = {
  action: PropTypes.bool,
  borderColor: PropTypes.string,
  children: PropTypes.any,
  margin: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickSimpan: PropTypes.func,
  onEdit: PropTypes.bool,
  sx: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

MainEditCard.defaultProps = {
  children: null,
  title: "-",
  sx: null,
  borderColor: "#f7f7f7",
  margin: false,
  action: true,
  onEdit: true,
  onClickEdit: () => {},
  onClickSimpan: () => {},
};
export default MainEditCard;
