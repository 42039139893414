import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import { Box, Checkbox, Grid, IconButton, OutlinedInput } from "@mui/material";
import MainDateRange from "components/Picker/MainDateRange";
import FormatRupiah from "helper/FormatRupiah";
import MainPopper from "components/Popper/MainPopper";
import useFetchData from "hook/useFetchData";
import { getPenerbit } from "redux/penerbit/action";
import FileInputCustom from "components/TextField/FileInputCustom";
import MainPopperCustom from "components/Popper/MainPopperCustom";
import {
  getVoucherSubsidi,
  newSubsidiBansos,
} from "redux/subsidiBansos/action";
import AktifStatus from "components/Status/AktifStatus";
import MainTable from "components/Table/MainTable";
import { Close } from "@mui/icons-material";
import { statusBansos, validasi_id } from "./variables";
import TemplateBansos from "assets/kartu-subsidi/template/template-bansos.xlsx";
import Autocomplete from "components/AutoComplete/Autocomplete";

const Dialog = ({ open, onClose, refresh }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      nama_program: "",
      tanggal_mulai: null,
      tanggal_selesai: null,
      excel: null,
      penyelenggara: "",
      kode_penyelenggara: null,
      validasi_kk: null,
      validasi_id: null,
      status: 1,
      m_issuer_id: null,
      kartu_subsidi: [],
    },
    mode: "onChange",
  });

  const [filter, setFilter] = useState("");

  const action = useFetchData({
    action: newSubsidiBansos,
    reset: resetForm,
    message: "Berhasil menambah program subsidi baru",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const penerbit = useFetchData({
    action: getPenerbit,
    snackbar: false,
  });

  const voucherSubsidi = useFetchData({
    action: getVoucherSubsidi,
    snackbar: false,
  });

  const onSubmit = async (data) => {
    data.kartu_subsidi = JSON.stringify(data.kartu_subsidi);
    await convertToBase64(data.excel, async (base64) => {
      const body = {};
      if(base64 != null){
        data.excel = base64
      }
      for (let key in data) {
        body[key] = data[key];
      }
      const formData = new URLSearchParams(body)
      await action.fetch(formData);
    })
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel" &&
      file.type !== "application/wps-office.xlsx"
    ) {
      inputRef.current.value = "";
      setError("excel", {
        type: "file",
        message: "Format file tidak sesuai",
      });
      return;
    }

    setValue("nama_file", file.name, {
      shouldValidate: true,
    });
  };

  const handleMultiSelect = (option) => {
    const kartu = watch("kartu_subsidi");
    if (kartu.includes(option.PrefixCode)) {
      setValue(
        "kartu_subsidi",
        kartu?.filter((item) => item !== option.PrefixCode),
        {
          shouldValidate: true,
        }
      );
    } else {
      setValue("kartu_subsidi", [...kartu, option.PrefixCode], {
        shouldValidate: true,
      });
    }
  };

  const findListVoucherByPenerbit = (option, key) => {
    return voucherSubsidi.response.find((item) => item.PrefixCode === option)?.[
      key
    ];
  };

  const removeVoucher = (option) => {
    const voucher = watch("kartu_subsidi");
    setValue(
      "kartu_subsidi",
      voucher?.filter((item) => item !== option),
      {
        shouldValidate: true,
      }
    );
  };

  const headers = [
    {
      name: "",
      width: 5,
      custom: (item) => {
        return (
          <Checkbox
            sx={{
              color: "#cc1d15",
              "&.Mui-checked": {
                color: "#cc1d15",
              },
            }}
            onClick={() => handleMultiSelect(item)}
            checked={watch("kartu_subsidi").includes(item.PrefixCode)}
          />
        );
      },
    },
    {
      name: "No. Kartu",
      key: "PrefixCode",
      width: 10,
    },
    {
      name: "Nama Kartu Subsidi",
      key: "CardName",
      width: 35,
    },
    {
      name: "Saldo",
      width: 27,
      custom: (item) => {
        return `Rp. ${FormatRupiah(item.AmountValue)}`;
      },
    },
    {
      name: "Status Kartu Subsidi",
      width: 27,
      custom: (item) => {
        return <AktifStatus value={item.Status} />;
      },
    },
  ];

  const headers2 = [
    {
      name: "No. Kartu",
      width: 20,
      custom: (item) => {
        return item;
      },
    },
    {
      name: "Nama kartu Subsidi",
      width: 45,
      custom: (option) => {
        return findListVoucherByPenerbit(option, "CardName");
      },
    },
    {
      name: "Saldo",
      width: 20,
      custom: (option) => {
        return `Rp. ${FormatRupiah(
          findListVoucherByPenerbit(option, "AmountValue")
        )}`;
      },
    },
    {
      name: "Status Kartu Subsidi",
      width: 10,
      custom: (option) => {
        return (
          <AktifStatus value={findListVoucherByPenerbit(option, "Status")} />
        );
      },
    },
    {
      name: "",
      width: 5,
      custom: (option) => {
        return (
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeVoucher(option);
            }}
          >
            <Close />
          </IconButton>
        );
      },
    },
  ];

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const validasi_kk = [
    {
      value: 1,
      label: "Ya",
      isDisabled: watch("validasi_id") !== 1,
    },
    { value: 0, label: "Tidak" },
  ];

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Tambah Program Kartu Subsidi"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Nama Program Bantuan"}>
          <MainController
            {...getProps("nama_program")}
            rules={{ required: "Nama program bantuan wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                error={invalid}
                placeholder="Masukan nama program"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Periode Bantuan"}>
          <MainController
            {...getProps("tanggal_selesai")}
            rules={{ required: "Periode kartu subsidi wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <MainDateRange
                onChange={(value) => {
                  setValue("tanggal_mulai", value[0]);
                  field.onChange(value[1]);
                  setValue("kode_penyelenggara", "");
                  setValue("penyelenggara", "");
                }}
                start={watch("tanggal_mulai") || null}
                end={watch("tanggal_selesai") || null}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Kode Penyelenggara"}>
          <MainController
            {...getProps("kode_penyelenggara")}
            rules={{ required: "kode penyelenggara wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <MainPopper
                loading={penerbit.loading}
                onClick={() => {
                  penerbit.setResponse([]);
                  penerbit.fetch({
                    filter: {
                      IssuerStatus: 1,
                    },
                  });
                }}
                filter={(option, value) => {
                  return (
                    String(option.IssuerCode).toLowerCase().includes(value) ||
                    String(option.IssuerName).toLowerCase().includes(value)
                  );
                }}
                options={penerbit.response?.list || []}
                placeholder="Pilih kode penyelenggara"
                value={field.value}
                onChange={(option) => {
                  field.onChange(option.IssuerCode);
                  setValue("penyelenggara", option.IssuerName);
                  setValue("m_issuer_id", option.id);
                  voucherSubsidi.setResponse([]);
                  voucherSubsidi.fetch(
                    {
                      start_date: getValues("tanggal_mulai"),
                      end_date: getValues("tanggal_selesai"),
                    },
                    option.id
                  );
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Nama Penyelenggara"}>
          <MainController
            {...getProps("penyelenggara")}
            rules={{ required: "Nama penyelenggara  wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                disabled
                error={invalid}
                placeholder="Nama penyelenggara akan otomatis diisi"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Jenis ID"}>
          <MainController
            {...getProps("validasi_id")}
            rules={{ required: "Jenis ID wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocomplete
                {...field}
                options={validasi_id || []}
                value={
                  validasi_id.find(
                    (option) => option.value === getValues("validasi_id")
                  ) || null
                }
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_, nv) => {
                  if (nv === null) {
                    field.onChange(null);
                  } else {
                    field.onChange(nv.value);
                    setValue("validasi_kk", null);
                  }
                }}
                placeholder="Pilih Jenis ID"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Validasi KK"}>
          <MainController
            {...getProps("validasi_kk")}
            rules={{ required: "Validasi KK wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocomplete
                {...field}
                options={validasi_kk || []}
                getOptionDisabled={(option) => option.isDisabled}
                value={
                  validasi_kk.find((option) => option.value == field.value) ||
                  null
                }
                isOptionEqualToValue={(option, value) =>
                  option.value == value.value
                }
                onChange={(_, nv) => {
                  if (nv === null) {
                    field.onChange(null);
                  } else {
                    field.onChange(nv.value);
                  }
                }}
                placeholder="Ya / Tidak"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Status Bantuan"}>
          <MainController
            {...getProps("status")}
            rules={{ required: "Status bantuan wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocomplete
                options={statusBansos}
                error={invalid}
                value={
                  statusBansos.find((option) => option.value == field.value) ||
                  null
                }
                isOptionEqualToValue={(option, value) =>
                  option.value == value.value
                }
                onChange={(_, nv) => {
                  if (nv === null) {
                    field.onChange(null);
                  } else {
                    field.onChange(nv.value);
                  }
                }}
                placeholder="Pilih Status"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label="Daftar Penerima Bantuan">
          <MainController
            {...getProps("excel")}
            rules={{ required: "Daftar Penerima Bantuan wajib diisi" }}
            desc={
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  Hanya dokumen excel format *.xls dan *.xlsx yang diterima.
                </span>
                <Box
                  component="a"
                  download="template-bansos-voucer-bansos.xlsx"
                  href={TemplateBansos}
                  sx={{
                    color: "#cc1d15",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Download Template
                </Box>
              </Box>
            }
            render={({ field, fieldState: { invalid } }) => (
              <FileInputCustom
                {...field}
                placeholder="Pilih Dokumen"
                error={invalid}
                onChange={(e) => {
                  handleFileChange(e);
                  field.onChange(e.target.files[0]);
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label="Kartu Subsidi" md={12}>
          <MainController
            {...getProps("kartu_subsidi")}
            rules={{ required: "Kartu Subsidi wajib diisi" }}
            render={({ field }) => (
              <MainPopperCustom
                placeholder="Pilih kartu subsidi"
                onChangeFilter={(v) => setFilter(v)}
              >
                <MainTable
                  no={false}
                  headers={headers}
                  loading={voucherSubsidi.loading}
                  filter={(option) => {
                    return (
                      String(option.PrefixCode)
                        .toLowerCase()
                        .includes(filter) ||
                      String(option.CardName).toLowerCase().includes(filter)
                    );
                  }}
                  summaryKey="CardName"
                  data={voucherSubsidi.response || []}
                  action=""
                  sx={{
                    ".MuiTableCell-head": {
                      backgroundColor: "#fff",
                      color: "#111",
                      borderTop: "1px solid #F7F7F7 ",
                      borderBottom: "1px solid #F7F7F7",
                    },
                    ".MuiTableCell-body": {
                      backgroundColor: "#fff",
                      color: "#111",
                      borderTop: "1px solid #F7F7F7 ",
                      borderBottom: "1px solid #F7F7F7",
                    },
                  }}
                />
              </MainPopperCustom>
            )}
          />
        </VerticalGrid>
        {watch("kartu_subsidi").length > 0 && (
          <Box
            sx={{
              width: "100%",
              marginLeft: "15px",
              marginTop: "10px",
            }}
          >
            <MainTable
              no={false}
              headers={headers2}
              loading={false}
              data={watch("kartu_subsidi")}
              summaryKey={(item) => {
                return findListVoucherByPenerbit(item, "CardName");
              }}
              action=""
              sx={{
                ".MuiTableCell-head": {
                  backgroundColor: "#fff",
                  color: "#111",
                  borderTop: "1px solid #F7F7F7 ",
                  borderBottom: "1px solid #F7F7F7",
                },
                ".MuiTableCell-body": {
                  backgroundColor: "#fff",
                  color: "#111",
                  borderTop: "1px solid #F7F7F7 ",
                  borderBottom: "1px solid #F7F7F7",
                },
              }}
            />
          </Box>
        )}
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  informasi: PropTypes.bool,
  ikon: PropTypes.bool,
  gambar: PropTypes.bool,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  informasi: false,
  ikon: false,
  gambar: false,
};
export default Dialog;
