import { Box, Collapse, Typography } from "@mui/material";
import CameraIcon from "assets/paper/CameraIcon";
import React, { forwardRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import FileValidator from "helper/FileValidator";
import { TransitionGroup } from "react-transition-group";
import { Close } from "@mui/icons-material";

const Gallery = forwardRef(
  ({ accept, setError, onChange, value, onClose, name }, ref) => {
    const [index, setIndex] = useState(0);

    const imageRef = useRef();
    return (
      <>
        <input
          type="file"
          hidden
          multiple
          ref={imageRef}
          value=""
          accept={accept}
          onChange={(e) => {
            const files = [...e.target.files];

            // const err = FileValidator(e.target.files[0], {
            //   size: {
            //     maxSize: "5mb",
            //   },
            //   type: accept.split(","),
            // });

            // if (err) {
            //   setError(name, {
            //     type: "file",
            //     message: err,
            //   });
            //   return;
            // }

            if (!value.length) {
              setIndex(0);
            }
            const newFoto = [...value, ...files];
            onChange(newFoto);
          }}
        />

        <Box
          sx={{
            width: "100%",
            height: "250px",
            background: "#F5F6F8",
            border: "2px #DEDEDE",
            borderStyle: !value.length ? "dashed" : "solid",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            if (value.length) {
              return;
            }
            imageRef.current.click();
          }}
        >
          {!Boolean(value.length) ? (
            <>
              <CameraIcon />
              <Typography
                variant="ikonBoxText"
                sx={{
                  mt: 2,
                }}
              >
                <span style={{ color: "#cc1d15" }}>click to browse</span> or
              </Typography>
              <Typography variant="ikonBoxText">drop photo here</Typography>
            </>
          ) : (
            <>
              <Box
                component="img"
                src={
                  value[index]
                    ? typeof value[index] === "object"
                      ? URL.createObjectURL(value[index])
                      : value[index]
                    : ""
                }
                sx={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                }}
              ></Box>
            </>
          )}
        </Box>

        <TransitionGroup
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          <Collapse>
            {value.length != 0 && (
              <Box
                sx={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "4px",
                  background: "#F5F6F8",
                  border: "1px dashed #DEDEDE",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => imageRef.current.click()}
              >
                <CameraIcon />
                <Typography variant="ikonBoxText">Tambah Foto</Typography>
              </Box>
            )}
          </Collapse>
          {value.map((item, i) => (
            <Collapse key={i}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "0px",
                    right: "4px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose(i);
                    if (value.length - 1 == i) {
                      setIndex(0);
                    }
                  }}
                >
                  <Close
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </Box>
                <Box
                  component="img"
                  src={
                    item
                      ? typeof item === "object"
                        ? URL.createObjectURL(item)
                        : item
                      : ""
                  }
                  onClick={() => setIndex(i)}
                  sx={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "4px",
                    border: "1px solid #DEDEDE",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Collapse>
          ))}
        </TransitionGroup>
      </>
    );
  }
);

Gallery.displayName = "Ga";

Gallery.propTypes = {
  onChange: PropTypes.func,
  setError: PropTypes.func,
  value: PropTypes.any,
  onClose: PropTypes.any,
  name: PropTypes.any,
  accept: PropTypes.string,
};

Gallery.defaultProps = {
  onChange: () => {},
  setError: () => {},
  value: null,
  onClose: () => {},
  accept: "image/png,image/jpg,image/jpeg",
  name: "",
};

export default Gallery;
