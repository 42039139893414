import React from "react";
import Providers from "components/RouteRoleProviders";
import KartuSubsidi from "pages/MasterData/MasterBantuan/KartuSubsidi";
import DetailKartuSubsidi from "pages/MasterData/MasterBantuan/KartuSubsidi/DetailKartuSubsidi";
import KartuSubsidiMobile from "pages/MasterData/MasterBantuan/KartuSubsidi/Mobile";
import ResponsiveView from "components/ResponsiveView";

const kartuSubsidiRoutes = [
  {
    path: "/master-data/master-bantuan/kartu-subsidi",
    element: (
      <Providers permission="Web:Voucher:All">
        <ResponsiveView
          onDesktop={KartuSubsidi}
          onMobile={KartuSubsidiMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/master-data/master-bantuan/kartu-subsidi/:id",
    element: (
      <Providers permission="Web:Voucher:All">
        <DetailKartuSubsidi />
      </Providers>
    ),
  },
];

export default kartuSubsidiRoutes;
