import {
  Box,
  Button,
  Collapse,
  IconButton,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import Check from "assets/kependudukan/Check";
import Comment from "assets/kependudukan/Comment";
import MainController from "components/Controller/MainController";
import React, { memo, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import CheckActive from "assets/kependudukan/CheckActive";
import CommentActive from "assets/kependudukan/CommentActive";
import AlertError from "./AlertError";
import useGetRole from "hook/useGetRole";
import AnggotaDynamicLabel from "./AnggotaDynamicLabel";
import AnggotaDynamicInput from "./AnggotaDynamicInput";

const AnggotaForm = memo(
  ({
    name,
    rules,
    setValue,
    trigger,
    index,
    getProps,
    watch,
    errors,
    type,
    openNotes,
    openComment,
    handleEdit,
    commentKey,
    comment,
    warding,
    getValues,
    parentIndex,
    ...item
  }) => {
    const { isOperator } = useGetRole();

    const btnRef = useRef();
    const btnRef2 = useRef();

    const Notes = useMemo(() => {
      if (openNotes) {
        return CheckActive;
      } else {
        return Check;
      }
    }, [openNotes]);

    const CommentI = useMemo(() => {
      if (openComment) {
        return CommentActive;
      } else {
        return Comment;
      }
    }, [openComment]);

    const handleClick = (value, e) => {
      e.preventDefault();

      if (openNotes) {
        trigger(`anggota.${parentIndex}.${index}.data.value`).then(
          (isValid) => {
            if (!isValid) return;
            handleEdit(
              getValues(`anggota.${parentIndex}.${index}.data.key`),
              value,
              getValues(`anggota.${parentIndex}.${index}.data.id`),
              {
                parentIndex,
                index,
                type,
                openNotes,
                openComment,
              }
            );
          }
        );
      } else {
        handleEdit(
          commentKey,
          value,
          getValues(`anggota.${parentIndex}.${index}.data.id`),
          {
            parentIndex,
            index,
            openNotes,
            type: null,
            openComment,
          }
        );
      }
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="center"
      >
        <MainController
          {...getProps(`anggota.${parentIndex}.${index}.data.value`)}
          customError={
            errors?.anggota?.[parentIndex]?.[index]?.data?.value?.message
          }
          rules={openNotes? rules : {required: false}}
          render={({ field, fieldState: { invalid } }) => (
            <Box
              display="flex"
              flexDirection="column"
              gap={
                watch(`anggota.${parentIndex}.${index}.data.openNotes`) ||
                watch(`anggota.${parentIndex}.${index}.data.openComment`)
                  ? 1
                  : 0
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="gridValue">
                  {AnggotaDynamicLabel(
                    type,
                    watch(`anggota.${parentIndex}.${index}.value`)
                  )}{" "}
                  {`\n`}
                  {Boolean(warding) && <AlertError value={warding} />}
                </Typography>
                {isOperator && (
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="gridValue">
                      <Tooltip title="Edit Data" arrow>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setValue(
                              `anggota.${parentIndex}.${index}.data.openNotes`,
                              !getValues(
                                `anggota.${parentIndex}.${index}.data.openNotes`
                              )
                            );
                            setValue(
                              `anggota.${parentIndex}.${index}.data.openComment`,
                              false
                            );
                            field.onChange(
                              getValues(`anggota.${parentIndex}.${index}.value`)
                            );
                          }}
                        >
                          <Notes />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography variant="gridValue">
                      <Tooltip title="Beri Komentar" arrow>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setValue(
                              `anggota.${parentIndex}.${index}.data.openComment`,
                              !getValues(
                                `anggota.${parentIndex}.${index}.data.openComment`
                              )
                            );
                            setValue(
                              `anggota.${parentIndex}.${index}.data.openNotes`,
                              false
                            );
                            setValue(
                              `anggota.${parentIndex}.${index}.data.comment`,
                              warding
                            );
                          }}
                        >
                          <CommentI />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Box>
                )}
              </Box>
              <Collapse
                in={watch(`anggota.${parentIndex}.${index}.data.openNotes`)}
              >
                <Box display="flex" gap={1}>
                  <AnggotaDynamicInput
                    getProps={getProps}
                    field={field}
                    type={type}
                    invalid={invalid}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        btnRef.current.click();
                      }
                    }}
                    parentIndex={parentIndex}
                    index={index}
                    {...item}
                  />
                  <Button
                    ref={btnRef}
                    variant="white"
                    type="button"
                    onClick={(e) => handleClick(field.value, e)}
                    sx={{
                      maxHeight: "40px",
                    }}
                  >
                    Simpan
                  </Button>
                </Box>
              </Collapse>
              <Collapse
                in={watch(`anggota.${parentIndex}.${index}.data.openComment`)}
              >
                <Box display="flex" gap={1}>
                  <MainController
                    {...getProps(
                      `anggota.${parentIndex}.${index}.data.comment`
                    )}
                    rules={{required:false}}
                    render={({ field, fieldState: { invalid } }) => (
                      <>
                        <OutlinedInput
                          variant="v3"
                          {...field}
                          placeholder="Masukan Komentar"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              btnRef2.current.click();
                            }
                          }}
                          value={field.value || ""}
                          onChange={(e) => {
                            const v = e.target.value || "";
                            field.onChange(v);
                          }}
                        />
                        <Button
                          ref={btnRef2}
                          variant="white"
                          type="button "
                          onClick={(e) => handleClick(field.value, e)}
                        >
                          Simpan
                        </Button>
                      </>
                    )}
                  />
                </Box>
              </Collapse>
            </Box>
          )}
        />
      </Box>
    );
  }
);

AnggotaForm.displayName = "if";
AnggotaForm.propTypes = {
  value: PropTypes.string,
  getProps: PropTypes.any,
  rules: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
  trigger: PropTypes.any,
  index: PropTypes.number,
  parentIndex: PropTypes.number,
  watch: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  openNotes: PropTypes.bool,
  openComment: PropTypes.bool,
  type: PropTypes.string,
  handleEdit: PropTypes.func,
  options: PropTypes.array,
  from: PropTypes.any,
  commentKey: PropTypes.string,
  comment: PropTypes.string,
  warding: PropTypes.string,
  remove: PropTypes.any,
  getValues: PropTypes.func,
};

AnggotaForm.defaultProps = {
  getProps: {},
  rules: {},
  value: "-",
  name: "",
  value: "",
  trigger: null,
  index: null,
  parentIndex: null,
  watch: null,
  errors: null,
  setValue: () => {},
  openNotes: false,
  openComment: false,
  type: "",
  options: [],
  handleEdit: () => {},
  from: null,
  commentKey: "",
  comment: "",
  warding: "",
  remove: 0,
  getValues: null,
};

export default AnggotaForm;
