import {
  Box,
  Button,
  Collapse,
  IconButton,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import Check from "assets/kependudukan/Check";
import Comment from "assets/kependudukan/Comment";
import MainController from "components/Controller/MainController";
import React, { memo, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import CheckActive from "assets/kependudukan/CheckActive";
import DynamicInput from "./DynamicInput";
import DynamicLabel from "./DynamicLabel";
import CommentActive from "assets/kependudukan/CommentActive";
import AlertError from "./AlertError";
import useGetRole from "hook/useGetRole";

const InputForm = memo(
  ({
    name,
    rules,
    setValue,
    trigger,
    index,
    getProps,
    watch,
    errors,
    type,
    data,
    openNotes,
    openComment,
    handleEdit,
    options,
    commentKey,
    comment,
    warding,
    remove,
    getValues,
    ...item
  }) => {
    const { isOperator } = useGetRole();

    const btnRef = useRef();
    const btnRef2 = useRef();
    const Notes = useMemo(() => {
      if (openNotes) {
        return CheckActive;
      } else {
        return Check;
      }
    }, [openNotes]);

    const CommentI = useMemo(() => {
      if (openComment) {
        return CommentActive;
      } else {
        return Comment;
      }
    }, [openComment]);

    const handleClick = (value) => {
      if (openNotes) {
        trigger(`forms.${index}.value`).then((isValid) => {
          if (!isValid) return;
          handleEdit(data, value, {
            name,
            index,
            openNotes,
            type,
            options,
          });
        });
      } else {
        handleEdit(commentKey, value, {
          name: warding,
          index,
          openNotes,
          type: null,
          options,
          openComment,
        });
      }
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="center"
        // mt={1.1}
      >
        <MainController
          {...getProps(`forms.${index}.value`)}
          customError={errors?.forms?.[index]?.value?.message}
          rules={openNotes ? rules : {}}
          render={({ field, fieldState: { invalid } }) => (
            <Box
              display="flex"
              flexDirection="column"
              gap={
                watch(`forms.${index}.openNotes`) ||
                watch(`forms.${index}.openComment`)
                  ? 1
                  : 0
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="gridValue">
                  {DynamicLabel(type, watch(name), options)} {`\n`}
                  {Boolean(watch(warding)) && (
                    <AlertError value={watch(warding)} />
                  )}
                </Typography>
                {isOperator && (
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="gridValue">
                      <Tooltip title="Edit Data" arrow>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setValue(`forms.${index}.openNotes`, !openNotes);
                            setValue(`forms.${index}.openComment`, false);
                            field.onChange(getValues(name));
                          }}
                        >
                          <Notes />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography variant="gridValue">
                      <Tooltip title="Beri Komentar" arrow>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setValue(
                              `forms.${index}.openComment`,
                              !openComment
                            );
                            setValue(`forms.${index}.openNotes`, false);
                            setValue(
                              `forms.${index}.comment`,
                              getValues(warding)
                            );
                          }}
                        >
                          <CommentI />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Box>
                )}
              </Box>
              <Collapse in={watch(`forms.${index}.openNotes`)}>
                <Box display="flex" gap={1}>
                  <DynamicInput
                    options={options}
                    field={field}
                    type={type}
                    invalid={invalid}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        btnRef.current.click();
                      }
                    }}
                    {...item}
                  />
                  <Button
                    ref={btnRef}
                    variant="white"
                    type="button"
                    onClick={() => handleClick(field.value)}
                  >
                    Simpan
                  </Button>
                </Box>
              </Collapse>
              <Collapse in={watch(`forms.${index}.openComment`)}>
                <Box display="flex" gap={1}>
                  <MainController
                    {...getProps(`forms.${index}.comment`)}
                    render={({ field, fieldState: { invalid } }) => (
                      <>
                        <OutlinedInput
                          variant="v3"
                          {...field}
                          placeholder="Masukan Komentar"
                          value={field.value || ""}
                          onChange={(e) => {
                            const v = e.target.value || "";
                            field.onChange(v);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              btnRef2.current.click();
                            }
                          }}
                        />
                        <Button
                          ref={btnRef2}
                          variant="white"
                          type="button "
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClick(field.value);
                          }}
                        >
                          Simpan
                        </Button>
                      </>
                    )}
                    rules={{}}
                  />
                </Box>
              </Collapse>
            </Box>
          )}
        />
      </Box>
    );
  }
);

InputForm.displayName = "if";
InputForm.propTypes = {
  value: PropTypes.string,
  getProps: PropTypes.any,
  rules: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
  trigger: PropTypes.any,
  index: PropTypes.number,
  watch: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  openNotes: PropTypes.bool,
  openComment: PropTypes.bool,
  type: PropTypes.string,
  handleEdit: PropTypes.func,
  data: PropTypes.string,
  options: PropTypes.array,
  from: PropTypes.any,
  commentKey: PropTypes.string,
  comment: PropTypes.string,
  warding: PropTypes.string,
  remove: PropTypes.any,
  getValues: PropTypes.func,
};

InputForm.defaultProps = {
  getProps: {},
  rules: {},
  value: "-",
  name: "",
  value: "",
  trigger: null,
  index: null,
  watch: null,
  errors: null,
  setValue: () => {},
  openNotes: false,
  openComment: false,
  type: "",
  data: "",
  options: [],
  handleEdit: () => {},
  from: null,
  commentKey: "",
  comment: "",
  warding: "",
  remove: 0,
  getValues: null,
};

export default InputForm;
