export const optJenisKelamin = [
  {
    label: "LAKI-LAKI",
    value: "Male",
  },
  {
    label: "PEREMPUAN",
    value: "Female",
  },
];

export const optStatusKawin = [
  {
    label: "Belum Kawin",
    value: "Belum Kawin",
  },
  {
    label: "Kawin",
    value: "Kawin",
  },
  {
    label: "Cerai Hidup",
    value: "Cerai Hidup",
  },
  {
    label: "Cerai Mati",
    value: "Cerai Mati",
  },
];

export const optStatusKeluarga = [
  {
    label: "Kepala Keluarga",
    value: "Kepala Keluarga",
  },
  {
    label: "Suami",
    value: "Suami",
  },
  {
    label: "Istri",
    value: "Istri",
  },
  {
    label: "Anak",
    value: "Anak",
  },
  {
    label: "Menantu",
    value: "Menantu",
  },
  {
    label: "Cucu",
    value: "Cucu",
  },
  {
    label: "Orang Tua",
    value: "Orang Tua",
  },
  {
    label: "Mertua",
    value: "Mertua",
  },
  {
    label: "Famili Lain",
    value: "Famili Lain",
  },
  {
    label: "Pembantu",
    value: "Pembantu",
  },
  {
    label: "Lainya",
    value: "Lainya",
  },
];

export const optGolDar = [
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "AB",
    value: "AB",
  },
  {
    label: "O",
    value: "O",
  },
];

export const optPendidikan = [
  { label: "Tidak / Belum Sekolah", value: "Tidak / Belum Sekolah" },
  { label: "Belum Tamat SD/Sederajat", value: "Belum Tamat SD/Sederajat" },
  { label: "Tamat SD / Sederajat", value: "Tamat SD / Sederajat" },
  { label: "SLTP / Sederajat", value: "SLTP / Sederajat" },
  { label: "SLTA / Sederajat", value: "SLTA / Sederajat" },
  { label: "Diploma I / II", value: "Diploma I / II" },
  {
    label: "Akademi / Diploma III /Sarjana Muda",
    value: "Akademi / Diploma III /Sarjana Muda",
  },
  { label: "Diploma IV / Strata-I", value: "Diploma IV / Strata-I" },
  { label: "Strata-II", value: "Strata-II" },
  { label: "Strata-III", value: "Strata-III" },
];

export const optAgama = [
  {
    label: "Budha",
    value: "Budha",
  },
  {
    label: "Islam",
    value: "Islam",
  },
  {
    label: "Hindu",
    value: "Hindu",
  },
  {
    label: "Katholik",
    value: "Katholik",
  },
  {
    label: "Khonghucu",
    value: "Khonghucu",
  },
  {
    label: "Kristen",
    value: "Kristen",
  },
  {
    label: "-",
    value: "",
  },
];

export const optPekerjaan = [
  { label: "Belum / Tidak Bekerja", value: "Belum / Tidak Bekerja" },
  { label: "Mengurus Rumah Tangga", value: "Mengurus Rumah Tangga" },
  { label: "Pelajar / Mahasiswa", value: "Pelajar / Mahasiswa" },
  { label: "Pensiunan", value: "Pensiunan" },
  { label: "Pegawai Negeri Sipil", value: "Pegawai Negeri Sipil" },
  { label: "Tentara Nasional Indonesia", value: "Tentara Nasional Indonesia" },
  { label: "Kepolisian RI", value: "Kepolisian RI" },
  { label: "Perdagangan", value: "Perdagangan" },
  { label: "Petani / Pekebun", value: "Petani / Pekebun" },
  { label: "Peternak", value: "Peternak" },
  { label: "Nelayan / Perikanan", value: "Nelayan / Perikanan" },
  { label: "Industri", value: "Industri" },
  { label: "Konstruksi", value: "Konstruksi" },
  { label: "Transportasi", value: "Transportasi" },
  { label: "Karyawan Swasta", value: "Karyawan Swasta" },
  { label: "Karyawan BUMN", value: "Karyawan BUMN" },
  { label: "Karyawan BUMD", value: "Karyawan BUMD" },
  { label: "Karyawan Honorer", value: "Karyawan Honorer" },
  { label: "Buruh Harian Lepas", value: "Buruh Harian Lepas" },
  { label: "Buruh Tani / Perkebunan", value: "Buruh Tani / Perkebunan" },
  { label: "Buruh Nelayan / Perikanan", value: "Buruh Nelayan / Perikanan" },
  { label: "Buruh Peternakan", value: "Buruh Peternakan" },
  { label: "Pembantu Rumah Tangga", value: "Pembantu Rumah Tangga" },
  { label: "Tukang Cukur", value: "Tukang Cukur" },
  { label: "Tukang Listrik", value: "Tukang Listrik" },
  { label: "Tukang Batu", value: "Tukang Batu" },
  { label: "Tukang Kayu", value: "Tukang Kayu" },
  { label: "Tukang Sol Sepatu", value: "Tukang Sol Sepatu" },
  { label: "Tukang Las / Pandai Besi", value: "Tukang Las / Pandai Besi" },
  { label: "Tukang Jahit ", value: "Tukang Jahit " },
  { label: "Penata Rambut", value: "Penata Rambut" },
  { label: "Penata Rias", value: "Penata Rias" },
  { label: "Penata Busana", value: "Penata Busana" },
  { label: "Mekanik", value: "Mekanik" },
  { label: "Tukang Gigi", value: "Tukang Gigi" },
  { label: "Seniman", value: "Seniman" },
  { label: "Tabib", value: "Tabib" },
  { label: "Paraji", value: "Paraji" },
  { label: "Perancang Busana", value: "Perancang Busana" },
  { label: "Penterjemah", value: "Penterjemah" },
  { label: "Imam Masjid", value: "Imam Masjid" },
  { label: "Pendeta", value: "Pendeta" },
  { label: "Pastur", value: "Pastur" },
  { label: "Wartawan", value: "Wartawan" },
  { label: "Ustadz / Mubaligh", value: "Ustadz / Mubaligh" },
  { label: "Juru Masak", value: "Juru Masak" },
  { label: "Promotor Acara", value: "Promotor Acara" },
  { label: "Anggota DPR-RI", value: "Anggota DPR-RI" },
  { label: "Anggota DPD", value: "Anggota DPD" },
  { label: "Anggota BPK", value: "Anggota BPK" },
  { label: "Presiden", value: "Presiden" },
  { label: "Wakil Presiden", value: "Wakil Presiden" },
  { label: "Anggota MahkamahKonstitusi", value: "Anggota MahkamahKonstitusi" },
  {
    label: "Anggota Kabinet /Kementerian",
    value: "Anggota Kabinet /Kementerian",
  },
  { label: "Duta Besar", value: "Duta Besar" },
  { label: "Gubernur", value: "Gubernur" },
  { label: "Wakil Gubernur", value: "Wakil Gubernur" },
  { label: "Bupati", value: "Bupati" },
  { label: "Wakil Bupati", value: "Wakil Bupati" },
  { label: "Walikota", value: "Walikota" },
  { label: "Wakil Walikota", value: "Wakil Walikota" },
  { label: "Anggota DPRD Propinsi", value: "Anggota DPRD Propinsi" },
  {
    label: "Anggota DPRD Kabupaten/ Kota",
    value: "Anggota DPRD Kabupaten/ Kota",
  },
  { label: "Dosen", value: "Dosen" },
  { label: "Guru", value: "Guru" },
  { label: "Pilot", value: "Pilot" },
  { label: "Pengacara", value: "Pengacara" },
  { label: "Notaris", value: "Notaris" },
  { label: "Arsitek", value: "Arsitek" },
  { label: "Akuntan", value: "Akuntan" },
  { label: "Konsultan", value: "Konsultan" },
  { label: "Dokter", value: "Dokter" },
  { label: "Bidan", value: "Bidan" },
  { label: "Perawat", value: "Perawat" },
  { label: "Apoteker", value: "Apoteker" },
  { label: "Psikiater / Psikolog", value: "Psikiater / Psikolog" },
  { label: "Penyiar Televisi", value: "Penyiar Televisi" },
  { label: "Penyiar Radio", value: "Penyiar Radio" },
  { label: "Pelaut", value: "Pelaut" },
  { label: "Peneliti", value: "Peneliti" },
  { label: "Sopir", value: "Sopir" },
  { label: "Pialang", value: "Pialang" },
  { label: "Paranormal", value: "Paranormal" },
  { label: "Pedagang", value: "Pedagang" },
  { label: "Perangkat Desa", value: "Perangkat Desa" },
  { label: "Kepala Desa", value: "Kepala Desa" },
  { label: "Biarawati", value: "Biarawati" },
];
