import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import useFetchData from "hook/useFetchData";
import { editHargaProduk, newHargaProduk, ubahStatusHargaProduk } from "redux/hargaProdukPPOB/action";
import { Box, Grid, Typography } from "@mui/material";
import SelectChips from "components/Status/SelectChips";
import VerticalGrid from "components/Grid/VerticalGrid";
import PilihStatus from "components/Status/PilihStatus";
import MainController from "components/Controller/MainController";
import AktifStatus from "components/Status/AktifStatus";
import MainTable from "components/Table/MainTable";
import FormatRupiah from "helper/FormatRupiah";

const statusData = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

const headers = [
  {
    name: "Kode Produk",
    key: "pCode",
    width: 15,
  },
  {
    name: "Nama Produk",
    custom: (item) => {
      return (
        <Typography
          component="div"
          sx={{
            overflow: "hidden",
            maxWidth: "140px",
            whiteSpace: "unset",
          }}
          variant="text2"
        >
          {item.pName}
        </Typography>
      );
    },
    width: 15,
  },
  {
    name: "Harga Jual",
    custom: (item) => {
      return `Rp. ${FormatRupiah(item.pJual)}`;
    },
    width: 15,
  },
  {
    name: "Harga Biller",
    custom: (item) => {
      return `Rp. ${FormatRupiah(item.pPrice)}`;
    },
    width: 15,
  },
  {
    name: "Status",
    width: 15,
    custom: (data) => {
      return (
        <AktifStatus
          value={data.pActive}
          minWidth="144px"
          maxWidth="144px"
          sx={{
            height: "34px",
          }}
        />
      );
    },
  },
];

const UbahStatusDialog = ({ open, onClose, data, setBatchDel, refresh }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      status: null,
    },
  });

  const action = useFetchData({
    action: ubahStatusHargaProduk,
    reset: resetForm,
    message: "Berhasil ubah status",
    refresh: refresh,
    onSuccess: () => {
      onClose();
      setBatchDel([])
    },
  });

  const onSubmit = async (submitData) => {
    const pcodes = data.map((i) => i.pCode);
    const formData = {
      pcodes,
      status: submitData.status,
    };
    action.fetch(formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title={"Ubah Status"}
      loading={action.loading}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText={`Apply`}
      customWidth="770px"
      buttonWidth="100px"
      secondaryVariant="secondary-outlined"
      sx={{
        padding: "0px !important",
      }}
      //   width="sm"
    >
      <Box py={4} px={3}>
        <VerticalGrid label="Ubah Status Menjadi" md={12}>
          <MainController
            {...getProps("status")}
            rules={{
              required: "Status wajib diisi",
            }}
            render={({ field }) => (
              <PilihStatus
                {...field}
                data={statusData}
                isactive={(item) => {
                  return item.value == field.value;
                }}
              />
            )}
          />
        </VerticalGrid>
      </Box>
      <MainTable
        action={false}
        headers={headers}
        data={data}
        summaryKey="pCode"
      />
    </MainDialog>
  );
};

UbahStatusDialog.propTypes = {
  data: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.any,
  post: PropTypes.any,
  refresh: PropTypes.any,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  update: PropTypes.any,
  setBatchDel: PropTypes.func
};

UbahStatusDialog.defaultProps = {
  data: null,
  onClose: () => {},
  open: false,
  post: () => {},
  refresh: () => {},
  type: null,
  update: () => {},
  setBatchDel: () => {},
};

export default UbahStatusDialog;
