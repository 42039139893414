import A from "./TransaksiPBB/slice";
import B from "./TransaksiPPOB/slice";
import C from "./TransferKeluar/slice";
import D from "./TransferMasuk/slice";

const laporanTransaksiReducer = {
  transaksiPBB: A,
  transaksiPPOB: B,
  transferKeluar: C,
  transferMasuk: D,
};

export default laporanTransaksiReducer;
