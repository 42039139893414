import AccessObject from "helper/AccessObject";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";

const useFetchData = ({
  action,
  reset,
  snackbar = true,
  snackbarError = true,
  message,
  onSuccess,
  onError,
  onFinal,
  refresh,
  customMessageError = false,
  startLoading = false,
  customRes = "",
  beforeFetch,
}) => {
  const [loading, setLoading] = useState(startLoading);
  const [response, setResponse] = useState(null);
  const dispatch = useDispatch();

  const fetch = async (data, id, params, keyFetch = null) => {
    if (!loading) {
      setLoading(true);
    }
    beforeFetch && beforeFetch();
    try {
      const res = await action(data, id);
      if (!navigator.onLine) {
        return dispatch(
          alert({
            open: true,
            message: "Tidak ada koneksi internet",
          })
        );
      }

      if (
        res?.data?.status_code === 200 ||
        res?.data?.status_code === 201 ||
        res?.data?.code === 200 ||
        res?.data?.code === 201 ||
        res?.data?.code === 202 ||
        res?.data?.status_code === 202
      ) {
        reset && reset();
        snackbar &&
          dispatch(
            alert({
              open: true,
              message: message,
            })
          );
        let io =
          customRes == "" ? res?.data?.data : AccessObject(res, customRes);
        if(keyFetch != null){
          setResponse(io[keyFetch])
        }else{
          setResponse(io);
        }
        onSuccess && onSuccess(io, params);
        refresh && refresh();
      } else {
        snackbarError &&
          dispatch(
            alert({
              open: true,
              message: !customMessageError
                ? res?.data?.message || "Maaf terjadi kesalahan"
                : customMessageError(res?.data) || "Maaf terjadi kesalahan",
            })
          );
        setResponse(res?.data);
        onError && onError(res?.data?.data, res?.data);
      }
    } catch (error) {
      dispatch(
        alert({
          open: true,
          message: error.response?.data?.message || "Maaf terjadi kesalahan",
        })
      );
      console.error(error);
    } finally {
      onFinal && onFinal();
      setLoading(false);
    }
  };

  return { fetch, response, setResponse, loading, setLoading };
};

export default useFetchData;
