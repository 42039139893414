import LaporanTransaksiIcon from "assets/layout/sidebar/LaporanTransaksiIcon";
const permissionList = "Web:Report:All";

const LaporanTransaksi = {
  id: "laporan-transaksi",
  type: "collapse",
  title: "Laporan Transaksi",
  icon: LaporanTransaksiIcon,
  permission: permissionList,
  children: [
    {
      id: "ppob",
      title: "PPOB",
      type: "item",
      url: "/laporan-transaksi/ppob",
      permission: permissionList,
    },
    {
      id: "pbb",
      title: "PBB",
      type: "item",
      url: "/laporan-transaksi/pbb",
      permission: permissionList,
    },
    {
      id: "transfer-masuk",
      title: "Transfer Masuk",
      type: "item",
      url: "/laporan-transaksi/transfer-masuk",
      permission: permissionList,
    },
    {
      id: "transfer-keluar",
      title: "Transfer Keluar",
      type: "item",
      url: "/laporan-transaksi/transfer-keluar",
      permission: permissionList,
    },
  ],
};

export default LaporanTransaksi;
