import PropTypes from "prop-types";
import {
  OutlinedInput,
  Box,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import React from "react";
import InputComponent from "../InputComponent";
import Autocomplete from "components/AutoComplete/Autocomplete";

const Select = ({ item, handleChangeContent }) => {
  const getValue = () => {
    if (!item?.option?.length) return null;
    let val = item.option.filter((item) => item.selected === true);
    return val;
  };

  const renderLabel = (option) => {
    return (
      <FormControlLabel
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="m-0"
        value={option}
        control={
          <Checkbox
            checked={option.selected}
            onClick={() => {
              const oi = {
                ...option,
                selected: !option.selected,
              };

              let index = item.option.findIndex((opt) => opt.id === oi.id);

              const copyItem = [...item.option];

              copyItem[index] = oi;

              handleChangeContent(copyItem, item?.id, "option");
            }}
            className="p-0"
            sx={{
              color: "#dedede",
              "&.Mui-checked": {
                color: "#cc1d15",
              },
            }}
          />
        }
        label={
          <Box
            sx={{
              fontFamily: "ManropeMedium",
              fontWeight: "500",
              fontSize: "16px",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.38",
              letterSpacing: "normal",
              color: "#333",
            }}
          >
            {option?.label}
          </Box>
        }
      />
    );
  };
  return (
    <InputComponent item={item}>
      {item?.format?.type === "dropdown" ? (
        <>
          <Autocomplete
            multiple
            getOptionLabel={(option) => `${String(option.label)}` || null}
            value={getValue()}
            isOptionEqualToValue={(option, value) =>
              option.id === value.id || null
            }
            renderTags={() => null}
            renderOption={(option, io) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: "#111",
                    padding: "10px 15px",
                    gap: "10px",
                    cursor: "pointer",
                    borderBottom: "1px solid #f7f7f7",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const oi = {
                      ...io,
                      selected: !io.selected,
                    };

                    let index = item.option.findIndex(
                      (opt) => opt.id === oi.id
                    );

                    const copyItem = [...item.option];

                    copyItem[index] = oi;

                    handleChangeContent(copyItem, item?.id, "option");
                  }}
                >
                  <Checkbox
                    checked={option?.["aria-selected"]}
                    className="p-0"
                    sx={{
                      color: "#dedede",
                      "&.Mui-checked": {
                        color: "#cc1d15",
                      },
                    }}
                  />
                  <Typography variant="label2">{option.key}</Typography>
                </Box>
              );
            }}
            disablePortal
            placeholder={item?.property?.keterangan?.value || "Placeholder"}
            options={item?.option}
          />

          <Box
            sx={{
              display: "inline-flex",
              gap: "5px",
            }}
          >
            {getValue() &&
              getValue().map((v, i) => (
                <Chip
                  key={i}
                  label={v.label}
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const oi = {
                      ...v,
                      selected: !v.selected,
                    };

                    let index = item.option.findIndex(
                      (opt) => opt.id === oi.id
                    );

                    const copyItem = [...item.option];

                    copyItem[index] = oi;

                    handleChangeContent(copyItem, item?.id, "option");
                  }}
                  onDelete={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const oi = {
                      ...v,
                      selected: !v.selected,
                    };

                    let index = item.option.findIndex(
                      (opt) => opt.id === oi.id
                    );

                    const copyItem = [...item.option];

                    copyItem[index] = oi;

                    handleChangeContent(copyItem, item?.id, "option");
                  }}
                />
              ))}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            margin: "10px 0px",
          }}
        >
          {item?.option?.map((v, i) => (
            <React.Fragment key={i}>{renderLabel(v)}</React.Fragment>
          ))}
        </Box>
      )}
    </InputComponent>
  );
};

Select.propTypes = {
  handleChangeContent: PropTypes.func,
  item: PropTypes.object,
};
Select.defaultProps = {
  item: {},
  handleChangeContent: () => {},
};
export default Select;
