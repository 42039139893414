import React from "react";

const PengaturanIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6317_4610)">
        <path
          d="M22.61 18.99L13.53 9.91C14.46 7.57 13.98 4.81 12.09 2.91C9.78999 0.610002 6.20999 0.400002 3.65999 2.26L7.49999 6.11L6.07999 7.52L2.24999 3.69C0.389992 6.23 0.599992 9.82 2.89999 12.11C4.75999 13.97 7.46999 14.46 9.78999 13.59L18.9 22.7C19.29 23.09 19.92 23.09 20.31 22.7L22.61 20.4C23.01 20.02 23.01 19.39 22.61 18.99ZM19.61 20.59L10.15 11.13C9.53999 11.58 8.85999 11.85 8.14999 11.95C6.78999 12.15 5.35999 11.74 4.31999 10.7C3.36999 9.76 2.92999 8.5 2.99999 7.26L6.08999 10.35L10.33 6.11L7.23999 3.02C8.47999 2.95 9.72999 3.39 10.68 4.33C11.76 5.41 12.17 6.9 11.92 8.29C11.8 9 11.5 9.66 11.04 10.25L20.49 19.7L19.61 20.59Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6317_4610">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PengaturanIcon;
