import PropTypes from "prop-types";
import MainController from "components/Controller/MainController";
import React from "react";
import DynamicInput from "./DynamicInput";
import { Box } from "@mui/material";

const InputForm = ({ getProps, errors, open, ...p }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        width: "100%",
        alignItems: "end",
      }}
    >
      <MainController
        {...getProps(`data.${p.name}`)}
        customError={errors?.data?.[p.name]?.message}
        rules={p.rules || {}}
        render={({ field, fieldState: { invalid } }) => (
          <DynamicInput
            type={p.type}
            field={field}
            error={invalid}
            open={open}
            {...p}
          />
        )}
      />
    </Box>
  );
};

InputForm.propTypes = {
  getProps: PropTypes.func,
  open: PropTypes.any,
  errors: PropTypes.any,
};

InputForm.defaultProps = {
  getProps: {},
  open: true,
  errors: {},
};

export default InputForm;
