import React from "react";
import Providers from "components/RouteRoleProviders";
import SubsidiBansosDetail from "pages/CSR/ProgramBantuan/SubsidiBansos/Subsidi";
import PenerimaSubsidiBansos from "pages/CSR/ProgramBantuan/SubsidiBansos/Penerima";
import SubsidiBansos from "pages/CSR/ProgramBantuan/SubsidiBansos";
import SubsidiBansosMobile from "pages/CSR/ProgramBantuan/SubsidiBansos/Mobile";
import ResponsiveView from "components/ResponsiveView";
import SubsidiBansosPenerimaMobile from "pages/CSR/ProgramBantuan/SubsidiBansos/Mobile/Penerima";
import SubsidiBansosDetailMobile from "pages/CSR/ProgramBantuan/SubsidiBansos/Mobile/Detail";

const subsidiBansosRoutes = [
  {
    path: "/csr-bansos/program-bantuan/kartu-subsidi",
    element: (
      <Providers permission="Web:ProgramBantuan:All">
        <ResponsiveView
          onDesktop={SubsidiBansos}
          onMobile={SubsidiBansosMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/kartu-subsidi/subsidi/:id",
    element: (
      <Providers permission="Web:ProgramBantuan:All">
        <ResponsiveView
          onDesktop={SubsidiBansosDetail}
          onMobile={SubsidiBansosDetailMobile}
        />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/kartu-subsidi/penerima/:id",
    element: (
      <Providers permission="Web:ProgramBantuan:All">
        <ResponsiveView
          onDesktop={PenerimaSubsidiBansos}
          onMobile={SubsidiBansosPenerimaMobile}
        />
      </Providers>
    ),
  },
];

export default subsidiBansosRoutes;
