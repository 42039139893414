import PropTypes from "prop-types";
import { Box, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import InputComponent from "../InputComponent";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Password = ({ item }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputComponent item={item}>
      <OutlinedInput
        placeholder={item?.property?.keterangan?.value || "Placeholder"}
        readOnly
        type={showPassword ? "text" : "password"}
        endAdornment={
          <Box
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </Box>
        }
      />
    </InputComponent>
  );
};

Password.propTypes = {
  item: PropTypes.object,
};
Password.defaultProps = {
  item: {},
};
export default Password;
