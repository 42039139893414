import PropTypes from "prop-types";
import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import Ilu from "assets/mobile/IlustrasiEmptyFilter.png";

const CardMobile = ({ title, children, list, onFilter, loading }) => {

  if (loading) {
    return;
  }

  return list ? (
    <Paper
      sx={{
        minHeight: "60px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "64px",
          pl: 2,
          backgroundColor: "#CC1D15",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Typography variant={"label2"} color="white">
          {title}
        </Typography>
      </Box>
      {children}
    </Paper>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
        mt: 7,
      }}
    >
      <Box
        component="img"
        src={Ilu}
        style={{
          width: "160px",
          height: "160px",
        }}
      />
      <Typography variant="cardTitle2" textAlign="center">
        {onFilter ? "Tidak ada data sesuai filter" : "Tidak ada data"}
      </Typography>
      {onFilter && (
        <Typography variant="text5" textAlign="center">
          Hapus atau ganti ke filter lain {`\n`} untuk menampilkan data
        </Typography>
      )}
      {onFilter && (
        <Button
          variant="primary-outlined"
          textAlign="center"
          sx={{
            width: "70%",
          }}
        >
          Hapus Filter
        </Button>
      )}
    </Box>
  );
};

CardMobile.propTypes = {
  children: PropTypes.any,
  list: PropTypes.number,
  loading: PropTypes.bool,
  onFilter: PropTypes.any,
  title: PropTypes.string,
};

CardMobile.defaultProps = {
  children: null,
  title: "",
  list: 0,
  loading: false,
  onFilter: false,
};

export default CardMobile;
