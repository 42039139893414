import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import MainController from "components/Controller/MainController";
import {
  Box,
  Collapse,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import Radio from "components/Radio/Radio";
import { TransitionGroup } from "react-transition-group";
import WarningIcon from "assets/Pengaturan/WarningIcon";
import TimePicker from "components/Picker/TimePicker";
import { actionPembaruanAplikasi } from "redux/pengaturan/action";
import useFetchData from "hook/useFetchData";

const radioData = [
  {
    label: "Diperbolehkan Nanti",
    value: 0,
  },
  {
    label: "Pembaruan Paksa",
    value: 1,
  },
];

const waktuPembaruan = [
  {
    label: "Perbarui Sekarang",
    value: 1,
  },
  {
    label: "Terjadwal",
    value: 0,
  },
];
const Dialog = ({ open, onClose, version, refresh }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      android_version: "",
      ios_version: "",
      force_update: null,
      type_update: null,
    },
  });

  const pembaruan = useFetchData({
    action: actionPembaruanAplikasi,
    reset: reset,
    message: "Berhasil menambahkan versi baru",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const onSubmit = (data) => {
    if (data.date_active) {
      data.date_active = `${moment().format("YYYY-MM-DD")} ${data.date_active}`;
    }
    pembaruan.fetch(data)
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      title="Konfigurasi"
      loading={false}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText="Perbarui"
      customWidth="680px"
      type={1}
      onReset={() => reset()}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <HorizontalGrid label={"Versi Android"} center={false}>
          <MainController
            {...getProps("android_version")}
            rules={{
              required: "Versi Android wajib diisi",
            }}
            desc={
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography
                  variant="inputDesc"
                  sx={{
                    color: "#878889",
                  }}
                >
                  Versi android terakhir {version.android_version || "-"}
                </Typography>

                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        py: 1,
                        px: 2,
                        textAlign: "left",
                        maxWidth: "210px",
                        background: "#fff",
                        color: "#111",
                        boxShadow: "0px 28px 28px -20px rgba(0, 0, 0, 0.25)",
                      },
                    },
                  }}
                  title={
                    "Masukkan versi aplikasi terbaru dari versi sebelumnya"
                  }
                  placement="bottom"
                >
                  <span>
                    <WarningIcon />
                  </span>
                </Tooltip>
              </Box>
            }
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                variant="v1"
                error={invalid}
                placeholder="Masukan versi android"
              />
            )}
          />
        </HorizontalGrid>

        <HorizontalGrid label={"Versi iOS"} center={false}>
          <MainController
            {...getProps("ios_version")}
            rules={{
              required: "Versi iOS wajib diisi",
            }}
            desc={
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography
                  variant="inputDesc"
                  sx={{
                    color: "#878889",
                  }}
                >
                  Versi iOS terakhir {version.ios_version || "-"}
                </Typography>

                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        py: 1,
                        px: 2,
                        textAlign: "left",
                        maxWidth: "210px",
                        background: "#fff",
                        color: "#111",
                        boxShadow: "0px 28px 28px -20px rgba(0, 0, 0, 0.25)",
                      },
                    },
                  }}
                  title={
                    "Masukkan versi aplikasi terbaru dari versi sebelumnya"
                  }
                  placement="bottom"
                >
                  <span>
                    <WarningIcon />
                  </span>
                </Tooltip>
              </Box>
            }
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                variant="v1"
                error={invalid}
                placeholder="Masukan versi iOS"
              />
            )}
          />
        </HorizontalGrid>

        <HorizontalGrid label={"Pembaruan"} center={false}>
          <MainController
            {...getProps("force_update")}
            rules={{
              required: "Versi iOS wajib diisi",
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Radio
                {...field}
                data={radioData}
                error={invalid}
                flexDirection="column"
              />
            )}
          />
        </HorizontalGrid>

        <HorizontalGrid label={"Waktu Pembaruan"} center={false}>
          <MainController
            {...getProps("type_update")}
            rules={{
              required: "Waktu wajib diisi",
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Radio
                {...field}
                data={waktuPembaruan}
                error={invalid}
                flexDirection="column"
              />
            )}
          />
        </HorizontalGrid>

        <TransitionGroup xs={12}>
          {watch("type_update") === 0 && (
            <Collapse>
              <HorizontalGrid label={"Waktu"} center={false}>
                <MainController
                  {...getProps("date_active")}
                  rules={{
                    required: "Waktu wajib diisi",
                  }}
                  defaultValue=""
                  shouldUnregister={true}
                  render={({ field, fieldState: { invalid } }) => {
                    const split = field.value.split(":");

                    return (
                      <TimePicker
                        error={invalid}
                        hours={split[0]}
                        minutes={split[1]}
                        seconds={split[2]}
                        withSeconds
                        onChangeSeconds={(e) => {
                          split[2] = e;
                          field.onChange(split.join(":"));
                        }}
                        onChangeHours={(e) => {
                          split[0] = e;
                          field.onChange(split.join(":"));
                        }}
                        onChangeMinutes={(e) => {
                          split[1] = e;
                          field.onChange(split.join(":"));
                        }}
                      />
                    );
                  }}
                />
              </HorizontalGrid>
            </Collapse>
          )}
        </TransitionGroup>
      </Box>
    </MainDialog>
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.any,
  refresh: PropTypes.any,
  version: PropTypes.object,
};

Dialog.defaultProps = {
  onClose: () => {},
  open: false,
  refresh: () => {},
  version: {},
};

export default Dialog;
