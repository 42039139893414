import { createAsyncThunk } from "@reduxjs/toolkit";
import { destroy, get, getParams, post, put } from "api";
import { HargaProdukPPOB } from "api/endpoints";

export const getListHargaProduk = createAsyncThunk(
  "hargaProdukPPOB/getList",
  async (params) => {
    const response = await getParams(HargaProdukPPOB.hargaProdukList, params);
    return response.data.data;
  }
);

export const getHargaProduk = async (params) => {
  const response = await getParams(HargaProdukPPOB.hargaProdukList, params);
  console.log(response);
  return response;
};

export const detailHargaProduk = async (id) => {
  const response = await get(`${HargaProdukPPOB.hargaProdukList}/${id}`);

  return response;
};

export const refreshWND = async () => {
  const response = await post(HargaProdukPPOB.wndaddupdate);
  return response;
};

export const refreshOy = async () => {
  const response = await post(HargaProdukPPOB.oyaddupdate);
  return response;
};

export const newHargaProduk = async (data) => {
  const response = await post(HargaProdukPPOB.hargaProdukList, data);
  return response;
};

export const editHargaProduk = async (data, id) => {
  const response = await put(`${HargaProdukPPOB.hargaProdukList}/${id}`, data);

  return response;
};

export const deleteHargaProduk = async (id) => {
  const response = await destroy(`${HargaProdukPPOB.hargaProdukList}/${id}`);

  return response;
};

export const ubahStatusHargaProduk = async (data) => {
  const response = await post(HargaProdukPPOB.ubahstatus, data);
  return response;
};
