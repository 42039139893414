import PengaturanIcon from "assets/layout/sidebar/PengaturanIcon";
const permissionList = "Web:Report:All";

const Pengaturan = {
  id: "pengaturan",
  type: "collapse",
  title: "Pengaturan",
  icon: PengaturanIcon,
  permission: permissionList,
  children: [
    {
      id: "login-session",
      title: "Login Kadaluarsa",
      type: "item",
      url: "/pengaturan/login-kadaluarsa",
      permission: permissionList,
    },
    {
      id: "pembaruan-aplikasi",
      title: "Pembaruan Aplikasi",
      type: "item",
      url: "/pengaturan/pembaruan-aplikasi",
      permission: permissionList,
    },
  ],
};

export default Pengaturan;
