import {
  Box,
  Button,
  OutlinedInput,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import React, { useRef, useState } from "react";
import { ChevronRight, Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { changeFilter, changeLimit, changePage } from "redux/pengaturan/slice";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";

import AccordionMobileDetail from "components/Mobile/AccordionMobileDetail";

import { getListPembaruanAplikasi } from "redux/pengaturan/action";
import moment from "moment";
const headers = [
  {
    name: "Versi iOS",
    key: "IosVersion",
    width: 25,
  },
  {
    name: "Kategori Pembaruan",
    key: "TextTypeUpdate",
    width: 25,
  },
  {
    name: "Tanggal dan waktu",
    custom: (item) => {
      return item?.DateActive
        ? moment(item?.DateActive).format("DD / MM / YYYY - hh:mm A")
        : "-";
    },
    width: 25,
  },
];

const headersDetail = [
  {
    name: "Versi Android",
    key: "AndroidVersion",
    width: 25,
  },
  {
    name: "Versi iOS",
    key: "IosVersion",
    width: 25,
  },
  {
    name: "Kategori Pembaruan",
    key: "TextTypeUpdate",
    width: 25,
  },
  {
    name: "Tanggal dan waktu",
    custom: (item) => {
      return item?.DateActive
        ? moment(item?.DateActive).format("DD / MM / YYYY - hh:mm A")
        : "-";
    },
    width: 25,
  },
];

const PembaruanAplikasiMobile = () => {
  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListPembaruanAplikasi(params));

  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.pembaruanAplikasi);

  useAutoFetch(fetchData, params, currentPage, filterName);

  const detail = useRef({});

  const [dialog, setDialog] = useState({
    alert: true,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const handleClickDetail = (item) => (event) => {
    detail.current = item;
    toggleDrawer("detail", true)(event);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={0.5}>
          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
        </Box>

        <CardMobile
          title="Daftar Pembaruan Aplikasi"
          list={list.length}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="Versi Android"
            value="AndroidVersion"
            handleClickDetail={handleClickDetail}
            hideDelete
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        onOpen={toggleDrawer("detail", true)}
      >
        <DrawerTitle
          title="Detail Pembaruan Aplikasi"
          toggle={toggleDrawer("detail", false)}
        />
        <AccordionMobileDetail headers={headersDetail} data={detail?.current} />
      </SwipeableDrawer>
    </>
  );
};

export default PembaruanAplikasiMobile;
