import PropTypes from "prop-types";
import { Box, InputAdornment, TextField } from "@mui/material";
import React, { forwardRef, Fragment, useRef } from "react";

const FileInputCustom = forwardRef(
  ({ onChange, value, onClose, ...props }, ref) => {
    const imageRef = useRef();
    return (
      <Fragment>
        <input
          type="file"
          hidden
          ref={imageRef}
          value=""
          accept=".xlsx, .xls, .csv"
          onChange={onChange}
        />

        <TextField
          {...props}
          ref={ref}
          value={value?.name || ""}
          onClick={() => imageRef.current.click()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    backgroundColor: "#d4d5d5",
                    padding: "11px",
                    color: "#111",
                    fontSize: "14px",
                    fontWeight: "700",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  Buka Folder
                </Box>
              </InputAdornment>
            ),
          }}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingRight: "0px",
            },
          }}
        />
      </Fragment>
    );
  }
);

FileInputCustom.displayName = "Fi";

FileInputCustom.propTypes = {
  onChange: PropTypes.func,
  props: PropTypes.any,
  showImage: PropTypes.any,
  value: PropTypes.any,
  onClose: PropTypes.any,
};

FileInputCustom.defaultProps = {
  props: null,
  onChange: () => {},
  showImage: false,
  value: "",
  onClose: null,
};

export default FileInputCustom;
