import { Box, Typography } from "@mui/material";
import CameraIcon from "assets/paper/CameraIcon";
import React, { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";
import FileValidator from "helper/FileValidator";

const FileInputBox = forwardRef(
  ({ accept, setError, onChange, value, onClose, name }, ref) => {
    const imageRef = useRef();
    return (
      <>
        <input
          type="file"
          hidden
          ref={imageRef}
          value=""
          accept={accept}
          onChange={(e) => {
            const err = FileValidator(e.target.files[0], {
              size: {
                maxSize: "5mb",
              },
              type: accept.split(","),
            });

            if (err) {
              setError(name, {
                type: "file",
                message: err,
              });
              return;
            }

            onChange(e.target.files[0]);
          }}
        />

        <Box
          ref={ref}
          sx={{
            cursor: "pointer",
            backgroundColor: "#F5F6F8",
            border: "2px dashed #DEDEDE",
            borderRadius: "8px",
            width: "120px",
            height: "120px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            gap: 1,
          }}
          onClick={() => imageRef.current.click()}
        >
          {!Boolean(value) ? (
            <>
              <CameraIcon />
              <Typography variant="ikonBoxText">Tambah Ikon</Typography>
            </>
          ) : (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "4px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <Close
                  sx={{
                    fontSize: "15px",
                  }}
                />
              </Box>
              <Box
                component="img"
                src={
                  value
                    ? typeof value === "object"
                      ? URL.createObjectURL(value)
                      : value
                    : ""
                }
                sx={{
                  objectFit: "contain",
                  width: "100px",
                  height: "100px",
                }}
              ></Box>
            </>
          )}
        </Box>
      </>
    );
  }
);

FileInputBox.displayName = "Fi";

FileInputBox.propTypes = {
  onChange: PropTypes.func,
  setError: PropTypes.func,
  value: PropTypes.any,
  onClose: PropTypes.any,
  name: PropTypes.any,
  accept: PropTypes.string,
};

FileInputBox.defaultProps = {
  onChange: () => {},
  setError: () => {},
  value: null,
  onClose: () => {},
  accept: "image/png,image/jpg,image/jpeg",
  name: "",
};

export default FileInputBox;
