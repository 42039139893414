import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99819 9.37439L11.4899 5.97821C11.5858 5.8847 11.7141 5.83325 11.8508 5.83325C11.9876 5.83325 12.1158 5.8847 12.2118 5.97821L12.5176 6.2758C12.6139 6.36917 12.6668 6.49398 12.6668 6.62698C12.6668 6.75998 12.6139 6.88464 12.5176 6.97808L8.36045 11.0218C8.26408 11.1156 8.13531 11.167 7.99842 11.1666C7.86092 11.167 7.7323 11.1157 7.63586 11.0218L3.48268 6.98184C3.38646 6.8884 3.3335 6.76374 3.3335 6.63067C3.3335 6.49767 3.38646 6.37301 3.48268 6.27949L3.78848 5.98197C3.98751 5.78838 4.31152 5.78838 4.51048 5.98197L7.99819 9.37439Z"
        fill="#969696"
      />
      <mask
        id="mask0_6990_47493"
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="10"
        height="7"
      >
        <path
          d="M7.99819 9.37439L11.4899 5.97821C11.5858 5.8847 11.7141 5.83325 11.8508 5.83325C11.9876 5.83325 12.1158 5.8847 12.2118 5.97821L12.5176 6.2758C12.6139 6.36917 12.6668 6.49398 12.6668 6.62698C12.6668 6.75998 12.6139 6.88464 12.5176 6.97808L8.36045 11.0218C8.26408 11.1156 8.13531 11.167 7.99842 11.1666C7.86092 11.167 7.7323 11.1157 7.63586 11.0218L3.48268 6.98184C3.38646 6.8884 3.3335 6.76374 3.3335 6.63067C3.3335 6.49767 3.38646 6.37301 3.48268 6.27949L3.78848 5.98197C3.98751 5.78838 4.31152 5.78838 4.51048 5.98197L7.99819 9.37439Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6990_47493)">
        <rect y="0.5" width="16" height="16" fill="currentColor" />
      </g>
    </svg>
  );
};

export default ArrowDown;
