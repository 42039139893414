import React from "react";

const CancelIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.3337C14.6024 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6024 1.66699 10 1.66699C5.39765 1.66699 1.66669 5.39795 1.66669 10.0003C1.66669 14.6027 5.39765 18.3337 10 18.3337ZM12.1164 6.82572C12.4086 6.53351 12.8824 6.53351 13.1746 6.82572C13.4668 7.11794 13.4668 7.59171 13.1746 7.88392L11.0582 10.0003L13.1746 12.1167C13.4668 12.4089 13.4668 12.8827 13.1746 13.1749C12.8824 13.4671 12.4086 13.4671 12.1164 13.1749L10 11.0585L7.88362 13.1749C7.5914 13.4671 7.11763 13.4671 6.82542 13.1749C6.5332 12.8827 6.5332 12.4089 6.82542 12.1167L8.94182 10.0003L6.82542 7.88392C6.5332 7.59171 6.5332 7.11794 6.82542 6.82572C7.11763 6.53351 7.59141 6.53351 7.88362 6.82572L10 8.94212L12.1164 6.82572Z"
        fill="#4C4D4F"
      />
      <mask
        id="mask0_6385_6018"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18.3337C14.6024 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6024 1.66699 10 1.66699C5.39765 1.66699 1.66669 5.39795 1.66669 10.0003C1.66669 14.6027 5.39765 18.3337 10 18.3337ZM12.1164 6.82572C12.4086 6.53351 12.8824 6.53351 13.1746 6.82572C13.4668 7.11794 13.4668 7.59171 13.1746 7.88392L11.0582 10.0003L13.1746 12.1167C13.4668 12.4089 13.4668 12.8827 13.1746 13.1749C12.8824 13.4671 12.4086 13.4671 12.1164 13.1749L10 11.0585L7.88362 13.1749C7.5914 13.4671 7.11763 13.4671 6.82542 13.1749C6.5332 12.8827 6.5332 12.4089 6.82542 12.1167L8.94182 10.0003L6.82542 7.88392C6.5332 7.59171 6.5332 7.11794 6.82542 6.82572C7.11763 6.53351 7.59141 6.53351 7.88362 6.82572L10 8.94212L12.1164 6.82572Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6385_6018)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default CancelIcon;
