import React, { useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import FileIconv2 from "assets/paper/v2/FileIconv2";
import FileIcon from "assets/paper/FileIcon";
import { Close } from "@mui/icons-material";
import FileValidator from "helper/FileValidator";

const FileDropv2 = forwardRef(
  (
    {
      accept,
      setError,
      onChange,
      value,
      onClose,
      customType,
      customSize,
      name,
    },
    ref
  ) => {
    const imageRef = useRef();

    return (
      <>
        <input
          type="file"
          ref={imageRef}
          hidden
          accept={accept}
          onChange={(e) => {
            const err = FileValidator(e.target.files[0], {
              size: {
                maxSize: Boolean(customSize) ? customSize : "2mb",
              },
              type: Boolean(customType) ? customType : accept.split(","),
            });

            if (err) {
              setError(name, {
                type: "file",
                message: err,
              });
              return;
            }

            onChange(e.target.files[0]);
          }}
        />
        <Box
          ref={ref}
          sx={{
            width: "100%",
            minHeight: "250px",
            border: "2px dashed #878889",
            display: "flex",
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
            py: 2,
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          // detect files on drop
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();

            const err = FileValidator(e.dataTransfer.files[0], {
              size: {
                maxSize: Boolean(customSize) ? customSize : "2mb",
              },
              type: Boolean(customType) ? customType : accept.split(","),
            });

            if (err) {
              setError(name, {
                type: "file",
                message: err,
              });
              return;
            }

            onChange(e.dataTransfer.files[0]);
          }}
        >
          {!Boolean(value) ? (
            <>
              <FileIconv2 />
              <Typography
                variant="text3"
                sx={{
                  textAlign: "center",
                }}
              >{`Drop your file here \n or`}</Typography>
              <Button
                variant="gray-outlined"
                onClick={() => imageRef.current.click()}
              >
                Browse
              </Button>
            </>
          ) : (
            <Box
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "4px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <Close
                  sx={{
                    fontSize: "15px",
                    color: "#111",
                  }}
                />
              </Box>
              <Box
                sx={{
                  objectFit: "contain",
                  borderRadius: "4px",
                  width: "300px",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <FileIcon />
              </Box>
              <Typography
                variant="title2"
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                {value.name}
              </Typography>
            </Box>
          )}
        </Box>
      </>
    );
  }
);

FileDropv2.displayName = "FD";

FileDropv2.propTypes = {
  onChange: PropTypes.func,
  setError: PropTypes.func,
  value: PropTypes.any,
  onClose: PropTypes.any,
  name: PropTypes.any,
  accept: PropTypes.string,
  customType: PropTypes.string,
  customSize: PropTypes.string,
};

FileDropv2.defaultProps = {
  onChange: () => {},
  setError: () => {},
  value: null,
  onClose: () => {},
  accept: "image/png,image/jpg,image/jpeg",
  name: "",
  customType: null,
  customSize: "",
};

export default FileDropv2;
