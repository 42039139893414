import React from "react";

const SingleLine = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1175 16.5556H8.9325L9.885 13.8563H14.1225L15.0675 16.5556H16.8825L12.9375 6H11.0625L7.1175 16.5556ZM4.5 19C4.5 18.4477 4.94772 18 5.5 18H18.5C19.0523 18 19.5 18.4477 19.5 19C19.5 19.5523 19.0523 20 18.5 20H5.5C4.94772 20 4.5 19.5523 4.5 19ZM11.955 7.96032L10.4325 12.3258H13.5675L12.045 7.96032H11.955Z"
        fill="black"
      />
      <mask
        id="mask0_6780_39432"
        maskUnits="userSpaceOnUse"
        x="4"
        y="6"
        width="16"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.1175 16.5556H8.9325L9.885 13.8563H14.1225L15.0675 16.5556H16.8825L12.9375 6H11.0625L7.1175 16.5556ZM4.5 19C4.5 18.4477 4.94772 18 5.5 18H18.5C19.0523 18 19.5 18.4477 19.5 19C19.5 19.5523 19.0523 20 18.5 20H5.5C4.94772 20 4.5 19.5523 4.5 19ZM11.955 7.96032L10.4325 12.3258H13.5675L12.045 7.96032H11.955Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_39432)">
        <rect width="24" height="24" fill="#111111" />
      </g>
    </svg>
  );
};

export default SingleLine;
