import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const ResponsiveView = ({ onMobile: Mobile, onDesktop: Desktop }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return matches ? <Mobile /> : <Desktop />;
};

ResponsiveView.propTypes = {
  onDesktop: PropTypes.any,
  onMobile: PropTypes.any,
};

ResponsiveView.defaultProps = {
  onDesktop: null,
  onMobile: null,
};

export default ResponsiveView;
