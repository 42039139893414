import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import MainLoading from "components/Loading/MainLoading";
import MainEditCard from "components/Paper/MainEditCard";
import getRole from "helper/getRole";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editAjudicator,
  getDesaList,
  getDetailAdjudicator,
  getKecamatanList,
  getKotaList,
  getProvinsiList,
} from "redux/digitalID/action";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Controller, useForm } from "react-hook-form";
import CropImage from "components/Crop";
import _ToUpperCase from "helper/_ToUppercase";
import MainGrid from "components/Grid/MainGrid";
import InputForm from "pages/LaporanAdjudicator/components/InputForm";
import Page from "components/Page";
import InputRadio from "pages/LaporanAdjudicator/components/inputRadio";
import MainController from "components/Controller/MainController";
import Autocomplete from "components/AutoComplete/Autocomplete";
import AlertDialog from "components/Dialog/AlertDialog";
import Ilustration from "assets/digitalId/ilustrasiEmpty.png";
import DigitalIDStatus from "components/Status/DigitalIDStatus";
import moment from "moment";
import {
  optAgama,
  optGolDar,
  optJenisKelamin,
  optPendidikan,
  optStatusKawin,
  optStatusKeluarga,
  optPekerjaan,
} from "pages/LaporanAdjudicator/variable";
import PageSM from "components/Page/PageSM";
import MobileBadge2 from "components/Mobile/BadgeDetail";
import Badgev2 from "components/Mobile/Badgev2";

const removeDot = (string) => {
  if (string.indexOf(" ") >= 0) {
    return string.split(" ")[1];
  }
  return string;
};
const DetailKTPMobile = () => {
  const { id } = useParams();
  const { isSuperVisor, isOperator } = getRole();

  const [page, setPage] = useState(0);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      data: {},
      forms: [],
    },
    mode: "onChange",
  });

  const Provinsi = useFetchData({
    action: getProvinsiList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[19].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[19].options", data);
      setValue("forms[19].loading", false);
    },
  });

  const Kota = useFetchData({
    action: getKotaList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[18].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[18].options", data);
      setValue("forms[18].loading", false);
    },
  });

  const Kecamatan = useFetchData({
    action: getKecamatanList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[17].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[17].options", data);
      setValue("forms[17].loading", false);
    },
  });

  const Desa = useFetchData({
    action: getDesaList,
    customRes: "data.response.data",
    snackbar: false,
    beforeFetch: () => {
      setValue("forms[16].loading", true);
    },
    onSuccess: (data) => {
      setValue("forms[16].options", data);
      setValue("forms[16].loading", false);
    },
  });

  const { response, loading, fetch } = useFetchData({
    action: getDetailAdjudicator,
    snackbar: false,
    onSuccess: (data) => {
      let newData = {};

      newData.rt = data?.doc_rt_rw?.split("/")[0] || "";
      newData.rw = data?.doc_rt_rw?.split("/")[1] || "";

      Object.keys(data).map((item) => {
        switch (item) {
          case "nik":
            newData["NIK"] = data[item];
            break;
          case "no_kk":
            newData["NoKK"] = data[item];
            break;
          case "doc_pob":
            newData["DocPoB"] = data.doc_pob;
            break;
          case "doc_dob":
            newData["DocDoB"] = data.doc_dob;
            break;
          default:
            newData[_ToUpperCase(item)] = data[item];
            break;
        }
      });

      if (newData.DocKelDesa) {
        Desa.fetch(removeDot(newData.DocKelDesa));
      }
      if (newData.DocKecamatan) {
        Kecamatan.fetch(removeDot(newData.DocKecamatan));
      }
      if (newData.KotamadyaKab) {
        Kota.fetch(removeDot(newData.KotamadyaKab));
      }
      if (newData.Provinsi) {
        Provinsi.fetch(removeDot(newData.Provinsi));
      }
      resetForm({
        data: newData,
        forms: informasiPermohonan,
      });
    },
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const informasiPermohonan = [
    {
      label: "NIK",
      name: "NIK",
      rules: {
        maxLength: {
          message: "NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "No. KK",
      name: "NoKK",
      rules: {
        maxLength: {
          message: "No. KK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Lengkap",
      name: "DocName",
    },
    {
      label: "Tempat Lahir",
      name: "DocPoB",
    },
    {
      label: "Tgl. Lahir",
      type: "date",
      name: "DocDoB",
    },
    {
      label: "Jenis Kelamin",
      name: "DocGender",
      type: "select",
      options: optJenisKelamin,
    },
    {
      label: "Status Keluarga",
      type: "select",
      name: "StatusKeluarga",
      options: optStatusKeluarga,
    },
    {
      label: "Agama",
      type: "select",
      name: "DocReligion",
      options: optAgama,
    },
    {
      label: "Status Kawin",
      type: "select",
      name: "StatusKawin",
      options: optStatusKawin,
    },
    {
      label: "Pend. Akhir",
      type: "select",
      name: "PendidikanTerakhir",
      options: optPendidikan,
    },
    {
      label: "Pekerjaan",
      type: "select",
      name: "DocProfession",
      options: optPekerjaan,
    },
    {
      label: "Gol. Darah",
      type: "select",
      options: optGolDar,
      name: "DocBlood",
    },
    {
      label: "Alamat",
      name: "DocAddress",
    },
    {
      label: "RT",
      name: "rt",
    },
    {
      label: "RW",
      name: "rw",
    },
    {
      label: "Dusun",
      name: "Dusun",
    },
    {
      label: "Kelurahan",
      type: "wilayah",
      options: [],
      loading: false,
      name: "DocKelDesa",
      inputChange: (e) => {
        Desa.fetch(e);
      },
    },
    {
      label: "Kecamatan",
      type: "wilayah",
      options: [],
      loading: false,
      name: "DocKecamatan",
      inputChange: (e) => {
        Kecamatan.fetch(e);
      },
    },
    {
      label: "Kota / Kab",
      type: "wilayah",
      options: [],
      loading: false,
      name: "KotamadyaKab",
      inputChange: (e) => {
        Kota.fetch(e);
      },
    },
    {
      label: "Provinsi",
      type: "wilayah",
      options: [],
      loading: false,
      name: "Provinsi",
      inputChange: (e) => {
        Provinsi.fetch(e);
      },
    },
    {
      label: "Kode Pos",
      name: "KodePos",
    },
    {
      label: "No. Akta Lahir",
      name: "NoAktaLahir",
    },
    {
      label: "No. Akta Kawin",
      name: "NoAktaKawin",
    },
    {
      label: "Tgl. Kawin",
      type: "date",
      name: "TglKawin",
    },
    {
      label: "No. Akta Cerai",
      name: "NoAktaCerai",
    },
    {
      label: "NIK Ayah",
      name: "NikAyah",
      rules: {
        maxLength: {
          message: "NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Ayah",
      name: "NamaAyah",
    },
    {
      label: "NIK Ibu",
      name: "NikIbu",
      rules: {
        maxLength: {
          message: "NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Ibu",
      name: "NamaIbu",
    },
  ];

  const RenderValue = ({ item }) => {
    switch (item.type) {
      case "wilayah":
        return (
          item?.options?.find((i) => i.nama == getValues(`data.${item.name}`))
            ?.nama || "-"
        );
      case "select":
        return (
          item?.options?.find((i) => i.value == getValues(`data.${item.name}`))
            ?.value || "-"
        );
      case "date":
        return moment(new Date(getValues(`data.${item.name}`))).isValid()
          ? moment(new Date(getValues(`data.${item.name}`))).format(
              "DD / MM / YYYY"
            )
          : "-";
      default:
        return getValues(`data.${item.name}`) || "-";
    }
  };

  const changePage = (value) => () => setPage(value);

  const keputusanOperator = useMemo(() => {
    if (!isOperator) {
      return [
        {
          label: "Status",
          value: <DigitalIDStatus value={response?.adj_status} />,
        },
        {
          label: "Pemberi Status",
          value: response?.adj_validate_by,
        },
        {
          label: "Tgl. Status",
          value: response?.doc_adj_approved_date
            ? moment(response?.doc_adj_approved_date).format("DD / MM / YYYY")
            : "-",
        },
        {
          label: "Catatan",
          value: response?.notes,
        },
      ];
    }
  }, [isOperator, response]);

  const keputusanSupervisor = useMemo(() => {
    if (!isSuperVisor && !isOperator) {
      return [
        {
          label: "Status",
          value: <DigitalIDStatus value={response?.adj_status} />,
        },
        {
          label: "Pemberi Status",
          value: response?.doc_adj_approved_by || "-",
        },
        {
          label: "Catatan",
          value: response?.notes,
        },
      ];
    }
  }, [isOperator, isSuperVisor, response]);

  return (
    <>
      <PageSM
        title={`Data ${response?.doc_name.split(" ")[0] || ""}`}
        backBtn
        to="/laporan-adjudicator/digital-id/ktp"
      />

      <Box
        display="flex"
        gap={2}
        p={2}
        sx={{
          width: "inherit",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Badgev2
          active={page == 0}
          value="Informasi Permohonan"
          onClick={changePage(0)}
        />

        <Badgev2
          active={page == 1}
          value="Kartu & Foto"
          onClick={changePage(1)}
        />
        <Badgev2 active={page == 2} value="Keputusan" onClick={changePage(2)} />
      </Box>

      {page == 0 && (
        <Paper>
          <Box display="flex" flexDirection="column" gap={3} p={2}>
            {getValues("forms").map((item, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="text2">{item.label}</Typography>
                  <Typography variant="label2">
                    <RenderValue item={item} />
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Paper>
      )}

      {page == 1 && (
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="label2">Kartu ID</Typography>
            <Box
              component="img"
              sx={{
                maxWidth: "100%",
                maxHeight: 300,
                borderRadius: "calc(0.6vw + 0.6vh)",
                objectFit: "contain",
              }}
              src={response?.doc_pict_secondary}
            />
          </Box>
          {!isOperator && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label2">Foto yang Ditampilkan</Typography>
              <Box
                component="img"
                sx={{
                  maxWidth: "100%",
                  maxHeight: 250,
                  borderRadius: "calc(0.4vw + 0.4vh)",
                  objectFit: "contain",
                }}
                src={watch("data.DocPictCropping")}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="label2">Foto Wajah</Typography>
            <Box
              component="img"
              sx={{
                maxWidth: "100%",
                maxHeight: 250,
                borderRadius: "calc(0.4vw + 0.4vh)",
                objectFit: "contain",
              }}
              src={response?.doc_pict_primary}
            />
          </Box>
        </Paper>
      )}

      {page == 2 && (
        <Box gap={2} display="flex" flexDirection="column">
          {!isOperator ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label">Keputusan Operator</Typography>
              <Paper
                sx={{ p: 1, flexDirection: "column", display: "flex", gap: 2 }}
              >
                {keputusanOperator.map((item, i) => (
                  <Box
                    key={i}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="text2">{item.label}</Typography>
                    <Typography variant="label2">{item.value}</Typography>
                  </Box>
                ))}
              </Paper>
            </Box>
          ) : null}

          {!isOperator && !isSuperVisor ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label">Keputusan Supervisor</Typography>
              <Paper
                sx={{ p: 1, flexDirection: "column", display: "flex", gap: 2 }}
              >
                {keputusanSupervisor.map((item, i) => (
                  <Box
                    key={i}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="text2">{item.label}</Typography>
                    <Typography variant="label2">{item.value}</Typography>
                  </Box>
                ))}
              </Paper>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default DetailKTPMobile;
