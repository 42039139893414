import { Box, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import Page from "components/Page";
import MainCard from "components/Paper/MainCard";
import BroadcastStatus from "components/Status/BroadcastStatus";
import BroadcastPenerimaStatus from "components/Status/BroadcastStatus/BroadcastPenerima";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { detailBroadcastSMS } from "redux/broadcast/action";

const SMSBroadcastDetail = () => {
  const { response, fetch, loading } = useFetchData({
    action: detailBroadcastSMS,
    snackbar: false,
  });

  const { id } = useParams();

  useEffect(() => {
    fetch(id);
  }, []);

  const data = useMemo(() => {
    const res = response?.detail;
    return [
      {
        label: "Pengiriman",
        value: moment(res?.SendDate).format("DD / MM / YYYY HH:mm"),
      },
      {
        label: "Status",
        value: <BroadcastStatus value={res?.Status} />,
      },
      {
        label: "Dibuat oleh",
        value: res?.CreatedByName,
      },
    ];
  }, [response]);
  return (
    <>
      <Page
        title={`Detail SMS Broadcast`}
        page={["Master Data", "SMS Broadcast"]}
        backBtn
        menu="Detail"
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <MainCard title="Informasi Pengirim" addButton={false}>
            <MainLoading loading={loading} height={170} >
              {data.map((item, i) => (
                <MainGrid
                  {...item}
                  striped="odd"
                  key={i}
                  center={item.label == "Status"}
                />
              ))}
            </MainLoading>
          </MainCard>

          <MainCard title="Pesan" addButton={false}>
            <MainLoading loading={loading} height={170}>
              <Box p={2}>
                <OutlinedInput
                  multiline
                  rows={8}
                  value={response?.detail?.MessageBody}
                  disabled
                />
              </Box>
            </MainLoading>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <MainCard
            title="Penerima"
            custom={
              <Typography variant="text2">
                {response?.list_receiver?.length || 0} Email
              </Typography>
            }
            addButton={false}
          >
            <MainLoading loading={loading} height={172}>
              {response?.list_receiver?.map((item, i) => (
                <MainGrid
                  label={item.NoHp}
                  labelMd={6}
                  valueMd={6}
                  variant={["gridValue", "gridValue"]}
                  value={
                    response?.detail?.Status != 2 &&
                    response?.detail?.Status != 3 && (
                      <BroadcastPenerimaStatus value={item.Status} />
                    )
                  }
                  striped="odd"
                  key={i}
                  center
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default SMSBroadcastDetail;
