import React, { useState } from "react";
import FileDownload from "assets/paper/fileDownload";
import FileUpload from "assets/paper/fileUpload";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FilterDialog from "components/Dialog/FilterDialog";
import SearchIcon from "assets/paper/SearchIcon";
import FilterIconv2 from "assets/paper/v2/FilterIconv2";

const MainCardHeader2 = ({
  filterName,
  changeFilter,
  params,
  filterData,
  addFilter,
  title,
  onChangeExport,
  onClickImport,
  onClickFilter,
  placeholder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState({
    filter: false,
  });
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const flexContainer = (theme) => ({
    display: "flex",
    gap: "5px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "5px",
    },
    justifyContent: "space-between",
    p: 2,
    alignItems: "center",
  });

  return (
    <Box
      sx={flexContainer}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        if (onClickFilter) {
          onClickFilter();
        } else {
          setOpenDialog({ ...open, filter: true });
        }
      }}
    >
      <OutlinedInput
        variant="v1"
        onChange={(e) => {
          dispatch(changeFilter(e.target.value));
        }}
        value={params.filter?.[filterName] || params?.[filterName] || ""}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        sx={{
          maxWidth: {
            lg: "292px",
            xs: "100%",
          },
          // minWidth: "292px",
        }}
        placeholder={placeholder}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          width: "100%",
        }}
      >
        <Button
          variant="secondary-outlined-icon"
          sx={{
            minWidth: "48px",
            maxWidth: "48px",
          }}
          type="submit"
        >
          <FilterIconv2 />
        </Button>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            height: "46px",
            marginLeft: "auto",
          }}
        >
          {Boolean(onClickImport) && (
            <Button
              variant="secondary-outlined"
              onClick={onClickImport}
              startIcon={<FileUpload />}
            >
              Unggah
            </Button>
          )}

          <Button
            onClick={handleClick}
            variant="secondary-outlined"
            startIcon={<FileDownload />}
            endIcon={
              <KeyboardArrowDown
                sx={{ position: "absolute", right: 7, top: 12 }}
              />
            }
            sx={{
              "&:after": {
                position: "absolute",
                content: '""',
                top: "0",
                left: "100px",
                backgroundColor: "#323232",
                width: "1px",
                height: "100%",
              },
              width: "140px",
              position: "relative",
              justifyContent: "start",
              fontWeight: "bold",
              "&:hover": {
                "&:after": {
                  backgroundColor: "#fff !important",
                },
              },
            }}
          >
            Unduh
          </Button>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            width: "140px",
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.09)",
            marginTop: "10px",
            ".MuiList-root": {
              padding: "0px",
            },
          },
        }}
      >
        <MenuItem
          onClick={() => onChangeExport("excel")}
          sx={{
            py: 1.5,
            borderBottom: "1px solid #f6f6f6",
          }}
        >
          <Typography variant="label">Excel</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onChangeExport("pdf")}
          sx={{
            py: 1.5,
            borderBottom: "1px solid #f6f6f6",
          }}
        >
          <Typography variant="label">PDF</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onChangeExport("csv")}
          sx={{
            py: 1.5,
            borderBottom: "1px solid #f6f6f6",
          }}
        >
          <Typography variant="label">CSV</Typography>
        </MenuItem>
      </Menu>

      {!onClickFilter && (
        <FilterDialog
          open={openDialog.filter}
          onClose={() => setOpenDialog({ ...open, filter: false })}
          filterData={filterData}
          addFilter={addFilter}
          title={title}
        />
      )}
    </Box>
  );
};

MainCardHeader2.propTypes = {
  addFilter: PropTypes.func,
  changeFilter: PropTypes.func,
  changeFilterName: PropTypes.func,
  deleteFilter: PropTypes.func,
  filter: PropTypes.array,
  filterData: PropTypes.array,
  filterName: PropTypes.string,
  params: PropTypes.shape({
    filter: PropTypes.any,
  }),
  title: PropTypes.string,
  onChangeExport: PropTypes.any,
  onClickImport: PropTypes.any,
  onClickFilter: PropTypes.func,
  FilterButton: PropTypes.bool,
  placeholder: PropTypes.string,
};

MainCardHeader2.defaultProps = {
  filter: [],
  changeFilter: () => {},
  changeFilterName: () => {},
  deleteFilter: () => {},
  addFilter: () => {},
  filterName: "",
  filterData: [],
  params: {},
  title: "-",
  onChangeExport: null,
  onClickImport: null,
  onClickFilter: null,
  FilterButton: true,
  placeholder: "Cari...",
};

export default MainCardHeader2;
