import React from "react";
import Providers from "components/RouteRoleProviders";
import DetailPengguna from "pages/ManagementPengguna/Penggunav2/Detail";
import Pengguna from "pages/ManagementPengguna/Penggunav2";
import Peran from "pages/ManagementPengguna/Peranv2";
import Detail from "pages/ManagementPengguna/Peranv2/Detail";
import LogActivity from "pages/ManagementPengguna/LogActivity";
import ResponsiveView from "components/ResponsiveView";
import PenggunaMobile from "pages/ManagementPengguna/Penggunav2/Mobile";
import PeranMobile from "pages/ManagementPengguna/Peranv2/Mobile";

const penggunaRoutes = [
  {
    path: "/management-pengguna/pengguna",
    element: (
      <Providers permission="Web:User:Get">
        <ResponsiveView onDesktop={Pengguna} onMobile={PenggunaMobile} />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/pengguna/:id",
    element: (
      <Providers permission="Web:User:Get">
        <DetailPengguna />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/peran",
    element: (
      <Providers permission="Web:Role:Get">
        <ResponsiveView onDesktop={Peran} onMobile={PeranMobile} />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/peran/:id",
    element: (
      <Providers permission="Web:Role:Get">
        <Detail />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/log-aktivitas",
    element: (
      <Providers permission="Web:Role:Get">
        <LogActivity />
      </Providers>
    ),
  },
];

export default penggunaRoutes;
