import {
  Box,
  Button,
  Divider,
  Grid,
  OutlinedInput,
  Radio,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useRef, useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import AktifStatus from "components/Status/AktifStatus";
import moment from "moment";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import { Controller, useForm } from "react-hook-form";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import DrawerTitle from "components/Mobile/DrawerTitle";
import { getListPenerbit } from "redux/penerbit/action";
import DateFormat from "helper/DateFormat";
import RadioMobile from "components/Mobile/Radio";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/broadcast/SMS/slice";
import BroadcastStatus from "components/Status/BroadcastStatus";
import {
  cancelBroadcastSMS,
  getListBroadcastSMS,
} from "redux/broadcast/action";
import AccordionMobileDetail from "components/Mobile/AccordionMobileDetail";
import DynamicForm from "components/Form/DynamicForm";
import VerticalGrid from "components/Grid/VerticalGrid";
import FilterIconv2 from "assets/paper/v2/FilterIconv2";
import DeleteDialog from "components/Mobile/DeleteDialog";
import FilterDrawer from "components/Mobile/FilterDrawer";
import useFetchData from "hook/useFetchData";

const headers = [
  {
    name: "Penerima",
    custom: (data) => {
      return `${data?.TotalReceiver || 0} Nomor`;
    },
    width: 10,
  },
  {
    name: "Pengiriman",
    custom: (data) => {
      return moment(data.SendDate).format("DD / MM / YYYY");
    },
    width: 10,
  },
  {
    name: "Dibuat",
    key: "CreatedByName",
    width: 20,
  },
  {
    name: "Status",
    width: 10,
    custom: (data) => {
      return <BroadcastStatus value={data.Status} />;
    },
  },
];

const headersDetail = [
  {
    name: "Pesan",
    key: "MessageBody",
    width: 30,
  },
  {
    name: "Penerima",
    custom: (data) => {
      return `${data?.TotalReceiver || 0} Nomor`;
    },
    width: 10,
  },
  {
    name: "Pengiriman",
    custom: (data) => {
      return moment(data.SendDate).format("DD / MM / YYYY");
    },
    width: 10,
  },
  {
    name: "Dibuat",
    key: "CreatedByName",
    width: 20,
  },
  {
    name: "Status",
    width: 10,
    custom: (data) => {
      return <BroadcastStatus value={data.Status} />;
    },
  },
];

const _filter = [
  {
    label: "Pesan",
    value: "MessageBody",
  },
  {
    label: "Penerima",
    value: "TotalReceiver",
  },
  {
    label: "Pengiriman",
    customType: "date",
    value: "SendDate",
  },
  {
    label: "Status",
    value: "Status",
  },
];

const status = [
  {
    label: "Gagal",
    value: "Gagal",
  },
  {
    label: "Terkirim",
    value: "Terkirim",
  },
  {
    label: "Ditolak",
    value: "Ditolak",
  },
  {
    label: "Dijadwalkan",
    value: "Dijadwalkan",
  },
];

const filterData = [
  {
    label: "Tanggal",
    startDate: "startDate",
    endDate: "endDate",
    type: "daterange",
  },
  {
    label: "Status",
    key: "Status",
    placeholder: "Pilih Status",
    type: "select",
    options: status,
  },
];

const BroadcastSMSMobile = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.smsBroadcast);

  const dispatch = useDispatch();

  const detail = useRef({});

  const fetchData = () => dispatch(getListBroadcastSMS(params));

  useAutoFetch(fetchData, currentPage, filterName);

  const deleteRef = useRef(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    clearErrors,
    reset,
    resetField,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      filter: "",
      filterName: "",
    },
  });

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
    filter2: false,
    delete: false,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const handleChangeFilter = () =>
    dispatch(
      changeFilterName({
        name: getValues("filter"),
      })
    );

  const closeDialog = (event) => {
    toggleDrawer("filter", false)(event);
    reset();
    dispatch(
      changeFilterName({
        name: "",
      })
    );
    dispatch(deleteFilter());
  };

  const handleClickDetail = (item) => (event) => {
    detail.current = item;
    toggleDrawer("detail", true)(event);
  };

  const cancel = useFetchData({
    action: cancelBroadcastSMS,
    message: "Broadcast SMS berhasil dibatalkan",
    onFinal: () => {
      toggleDrawer("delete", false)();
      fetchData();
    },
  });

  const handleClickDelete = (item) => (event) => {
    deleteRef.current = {
      id: item.id,
      name: item.MessageBody,
    };
    toggleDrawer("delete", true)(event);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={1}>
          <MobileBadge
            value={watch("filterName") || "Pilih Parameter"}
            active={Boolean(watch("filterName"))}
            onClick={toggleDrawer("filter", true)}
          />

          <OutlinedInput
            variant="v6"
            placeholder="Cari"
            endAdornment={<SearchIcon />}
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchData();
              }
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
          />
          <Button
            variant="secondary-outlined-icon"
            sx={{
              minWidth: "40px",
            }}
            onClick={toggleDrawer("filter2", true)}
          >
            <FilterIconv2 />
          </Button>
        </Box>

        <CardMobile
          title="Campaign"
          list={list.length}
          onFilter={watch("filterName")}
          loading={loading}
        >
          <AccordionMobile
            list={list}
            headers={headers}
            label="Pesan"
            value="MessageBody"
            handleClickDetail={handleClickDetail}
            handleClickDelete={handleClickDelete}
            hideDelete={(item) => {
              const send = moment(item.SendDate);
              const now = moment();
              if (now.diff(send, "minutes") > 3 || item.Status !== 2) {
                return true;
              }
              return false;
            }}
          />
        </CardMobile>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.filter}
        onClose={closeDialog}
        onOpen={toggleDrawer("filter", true)}
      >
        <DrawerTitle
          title="Pilih Parameter"
          toggle={toggleDrawer("filter", false)}
        />f
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          <RadioMobile
            data={_filter}
            getProps={getProps}
            name="filter"
            checked={(item, field) => item.value == field.value}
            onClick={(field, item) => {
              dispatch(deleteFilter());
              field.onChange(item.value);
            }}
          />
        </Box>

        <Box display="flex" gap={1.5} p={2}>
          <Button
            variant="secondary-outlined"
            sx={{
              width: "100%",
            }}
            onClick={() => resetField("filter")}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            sx={{
              width: "100%",
            }}
            onClick={(event) => {
              const label = _filter.find(
                (i) => i.value == getValues("filter")
              )?.label;
              setValue("filterName", label);
              toggleDrawer("filter", false)(event);
              handleChangeFilter();
            }}
          >
            Terapkan
          </Button>
        </Box>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.detail}
        onClose={toggleDrawer("detail", false)}
        onOpen={toggleDrawer("detail", true)}
      >
        <DrawerTitle
          title="Detail Campaign"
          toggle={toggleDrawer("detail", false)}
        />
        <AccordionMobileDetail headers={headersDetail} data={detail?.current} />
      </SwipeableDrawer>

      <FilterDrawer
        open={dialog.filter2}
        filterData={filterData}
        onClose={toggleDrawer("filter2", false)}
        onOpen={toggleDrawer("filter2", true)}
        fetchData={fetchData}
        addFilter={addFilter}
      />

      <DeleteDialog
        open={dialog.delete}
        loading={false}
        onClose={toggleDrawer("delete", false)}
        title="Hapus SMS"
        desc="Anda yakin ingin menghapus campaign "
        name={deleteRef?.current?.name}
        onSubmit={() => {
          cancel.fetch(deleteRef.current.id);
        }}
      />
    </>
  );
};

export default BroadcastSMSMobile;
