import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

const PageSM = ({ title, backBtn, desc, page, menu, to }) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="center" sx={{ pb: 2, gap: 1.1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {backBtn && (
          <IconButton
            onClick={() => (to ? navigate(to) : navigate(-1))}
            sx={{
              p: 0,
            }}
          >
            <ChevronLeftIcon
              sx={{
                fontSize: "25px",
                color: "#111",
                fontWeight: "bold",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="label2"
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

PageSM.propTypes = {
  desc: PropTypes.bool,
  title: PropTypes.string,
  page: PropTypes.array,
  menu: PropTypes.string,
  backBtn: PropTypes.bool,
  to: PropTypes.string,
};

PageSM.defaultProps = {
  title: "",
  desc: false,
  page: [],
  backBtn: false,
  menu: "",
  to: "",
};

export default PageSM;
