import PropTypes from "prop-types";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import Attention from "assets/dasbor/chart/Attention";

const DasborCard = ({ icon, tooltipString, title, desc }) => {
  return (
    <Paper
      sx={{
        borderRadius: "8px",
        py: 2.4,
        px: 3,
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                py: 1,
                px: 2,
                textAlign: "left",
                maxWidth: "250px",
              },
            },
          }}
          title={tooltipString}
          placement="left"
          arrow
        >
          <span style={{ cursor: "pointer" }}>
            <Attention />
          </span>
        </Tooltip>
      </Box>
      <Box display="flex" gap={2} mt={1}>
        <Box
          sx={{
            backgroundColor: "#cc1d15",
            outline: "2px solid #cc1d15",
            outlineOffset: "2px",
            p: 1.3,
            my: "auto",
            display: "flex",
            width: "fit-content",
            borderRadius: "40px",
          }}
        >
          {icon}
        </Box>

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="label2">{title}</Typography>
          <Typography variant="title4" fontWeight="800">
            {desc}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

DasborCard.propTypes = {
  desc: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipString: PropTypes.string,
  title: PropTypes.string,
};

DasborCard.defaultProps = {
  desc: "",
  icon: "",
  tooltipString: "",
  title: "",
};

export default DasborCard;
