import PropTypes from "prop-types";
import { Box, InputAdornment, OutlinedInput, TextField } from "@mui/material";
import React, { forwardRef, Fragment, useRef } from "react";

const InputExcel = forwardRef(
  ({ onChange, value, setError, ...props }, ref) => {
    const imageRef = useRef();
    return (
      <Fragment>
        <input
          type="file"
          hidden
          ref={imageRef}
          value=""
          accept=".xlsx, .xls, .csv"
          onChange={(e) => {
            const file = e.target.files[0];
            if (!file) return;
            if (
              file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
              file.type !== "application/vnd.ms-excel" &&
              file.type !== "application/wps-office.xlsx"
            ) {
              return setError("excel", {
                type: "file",
                message: "Format file tidak sesuai",
              });
            }
            onChange(file);
          }}
        />

        <OutlinedInput
          {...props}
          ref={ref}
          value={value?.name || ""}
          onClick={() => imageRef.current.click()}
          endAdornment={
            <InputAdornment position="end">
              <Box
                sx={{
                  backgroundColor: "#d4d5d5",
                  padding: "11px",
                  color: "#111",
                  fontSize: "14px",
                  fontWeight: "700",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                Buka Folder
              </Box>
            </InputAdornment>
          }
          sx={{
            paddingRight: "0px",
          }}
        />
      </Fragment>
    );
  }
);

InputExcel.displayName = "Fe";

InputExcel.propTypes = {
  onChange: PropTypes.func,
  props: PropTypes.any,
  value: PropTypes.any,
  setError: PropTypes.any,
};

InputExcel.defaultProps = {
  props: null,
  onChange: () => {},
  value: "",
  setError: null,
};

export default InputExcel;
