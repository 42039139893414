import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import Page from "components/Page";
import MainCard2 from "components/Paper/MainCard2";
import KependudukanDetailStatus from "components/Status/Kependudukan/Detail";
import useFetchData from "hook/useFetchData";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editKK,
  editKKAnggota,
  getDetailKK,
  getKKAnggota,
  getStatus,
  konfirmasiKK,
  updateJadwalKK,
  updateStatusData,
  verifikasiKK,
} from "redux/kependudukan/action";
import moment from "moment";
import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useGetRegion from "hook/useGetRegion";
import MainController from "components/Controller/MainController";
import UpdateStatus from "../components/UpdateStatus";
import CheckUpdateStatus from "../components/CheckUpdateStatus";
import DatePicker2 from "components/Picker/DatePicker2";
import TimePicker from "components/Picker/TimePicker";
import getRole from "helper/getRole";
import InputFormDynamic from "../components/InputFormDynamic";
import AnggotaForm from "../components/AnggotaForm";
import Autocomplete from "components/AutoComplete/Autocomplete";
import KependudukanNewDetailStatus from "components/Status/Kependudukan/NewDetail";
import { getKecamatan } from "redux/region/action";

const confirmOptionOperator = [
  {
    label: "Diterima",
    value: "diproses",
  },
  {
    label: "Ditolak",
    value: "ditolak_lv3",
  },
];

const confirmOptionSupervisor = [
  {
    label: "Dijadwalkan",
    value: "dijadwalkan",
  },
  {
    label: "Ditolak",
    value: "ditolak",
  },
];

const updatestatus = [
  {
    label: "Verifikasi Data",
    data: "verification_date",
    open: false,
    value: moment(new Date()).format("DD/MM/YYYY HH:ss"),
  },
  {
    label: "Dijadwalkan",
    data: "scheduled_date",
    open: false,
    value: "",
  },
  {
    label: "Pencetakan Dok",
    data: "document_print_date",
    open: false,
    value: "",
  },
  {
    label: "Dok Fisik Tersedia",
    data: "available_document_date",
    open: false,
    value: "",
  },
  {
    label: "Selesai",
    data: "done_date",
    open: false,
    value: "",
  },
];

const DetailKK = () => {
  const { isSuperVisor, isOperator } = getRole();
  const navigate = useNavigate();
  const { id } = useParams();

  const domisili = [
    {
      label: "Provinsi",
      data: "provinsi_domisili_id",
      name: "domisili.provinsi_domisili_id",
      warding: "domisili.warding.provinsi_domisili_warding",
      commentKey: "provinsi_domisili_warding",
      value: "",
      comment: "",
      openNotes: false,
      openComment: false,
      loading: true,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsiDomisili.fetch();
        setValue("forms.domisili[0].loading", true);
      },
      rules: { required: "Provinsi Domisili wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      data: "kota_domisili_id",
      name: "domisili.kota_domisili_id",
      warding: "domisili.warding.kota_domisili_warding",
      commentKey: "kota_domisili_warding",
      value: "",
      comment: "",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!watch("domisili.provinsi_domisili_id")) {
          return setError("forms.domisili[1].loading", {
            type: "noid",
            message: "Pilih Provinsi Domisili Dahulu",
          });
        }
        kotaDomisili.fetch(watch("domisili.provinsi_domisili_id"));
        setValue("forms.domisili[1].loading", true);
      },
      loading: true,
      rules: { required: "Kota Domisili wajib diisi" },
    },
    {
      label: "Kecamatan",
      data: "kecamatan_domisili_id",
      name: "domisili.kecamatan_domisili_id",
      warding: "domisili.warding.kecamatan_domisili_warding",
      commentKey: "kecamatan_domisili_warding",
      value: "",
      comment: "",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(watch("domisili.kota_domisili_id"))) {
          return setError("forms.domisili[2].value", {
            type: "noid",
            message: "Pilih kota domisili Terlebih Dahulu",
          });
        }
        kecamatanDomisili.fetch(watch("domisili.kota_domisili_id"));
        setValue("forms.domisili[2].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan wajib diisi" },
    },
    {
      label: "Kel. /  Desa",
      data: "kelurahan_domisili_id",
      name: "domisili.kelurahan_domisili_id",
      warding: "domisili.warding.kelurahan_domisili_warding",
      commentKey: "kelurahan_domisili_warding",
      value: "",
      comment: "",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(watch("domisili.kecamatan_domisili_id"))) {
          return setError("forms.domisili[3].value", {
            type: "noid",
            message: "Pilih Kecamatan Domisili Terlebih Dahulu",
          });
        }
        kelurahanDomilisi.fetch(watch("domisili.kecamatan_domisili_id"));
        setValue("forms.domisili[3].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan domisili wajib diisi" },
    },
  ];

  const pemohon = [
    {
      label: "Alasan",
      name: "pemohon.alasan_permohonan",
      warding: "pemohon.warding.alasan_permohonan_warding",
      data: "alasan_permohonan",
      commentKey: "alasan_permohonan_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Alasan wajib diisi" },
    },
    {
      label: "NIK",
      name: "pemohon.nik_pemohon",
      warding: "pemohon.warding.nik_pemohon_warding",
      data: "nik_pemohon_pemohon",
      commentKey: "nik_pemohon_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. NIK wajib diisi",
        maxLength: {
          message: "NO. NIK tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Nama Lengkap",
      name: "pemohon.nama_pemohon",
      warding: "pemohon.warding.nama_pemohon_warding",
      data: "nama_pemohon",
      value: "",
      comment: "",
      commentKey: "nama_pemohon_warding",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama wajib diisi" },
    },
    {
      name: "pemohon.jumlah_anggota",
      warding: "pemohon.warding.jumlah_anggota_warding",
      data: "jumlah_anggota",
      label: "Jumlah Anggota",
      value: "",
      comment: "",
      commentKey: "jumlah_anggota_warding",
      openNotes: false,
      openComment: false,
      rules: { required: "Jumlah Anggota wajib diisi" },
    },
    {
      name: "pemohon.alamat",
      warding: "pemohon.warding.alamat_warding",
      data: "alamat",
      label: "Alamat",
      value: "",
      comment: "",
      commentKey: "alamat_warding",
      openNotes: false,
      openComment: false,
      rules: { required: "Alamat wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_id",
      warding: "pemohon.warding.provinsi_pemohon_warding",
      name: "pemohon.provinsi_id",
      value: "",
      comment: "",
      commentKey: "provinsi_pemohon_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.pemohon[5].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi pemohon wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "pemohon.kota_id",
      warding: "pemohon.warding.kota_pemohon_warding",
      data: "kota_id",
      value: "",
      comment: "",
      commentKey: "kota_pemohon_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!watch("pemohon.provinsi_id")) {
          return setError("forms.pemohon[6].value", {
            type: "noid",
            message: "Pilih Provinsi pemohon Dahulu",
          });
        }
        kota.fetch(watch("pemohon.provinsi_id"));
        setValue("forms.pemohon[6].loading", true);
      },
      loading: true,
      rules: { required: "Kota pemohon wajib diisi" },
    },
    {
      name: "pemohon.kecamatan_id",
      warding: "pemohon.warding.kecamatan_pemohon_warding",
      label: "Kecamatan",
      data: "kecamatan_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_pemohon_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(watch("pemohon.kota_id"))) {
          return setError("forms.pemohon[7].value", {
            type: "noid",
            message: "Pilih kota pemohon Terlebih Dahulu",
          });
        }
        kecamatan.fetch(watch("pemohon.kota_id"));
        setValue("forms.pemohon[7].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan pemohon wajib diisi" },
    },
    {
      name: "pemohon.kelurahan_id",
      warding: "pemohon.warding.kelurahan_pemohon_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_pemohon_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(watch("pemohon.kecamatan_id"))) {
          return setError("forms.pemohon[7].value", {
            type: "noid",
            message: "Pilih Kecamatan pemohon Terlebih Dahulu",
          });
        }
        kelurahan.fetch(watch("pemohon.kecamatan_id"));
        setValue("forms.pemohon[7].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan wajib diisi" },
    },
  ];

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    trigger,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      pemohon: {
        nama_pemohon: "",
        nik_pemohon: "",
        alasan_permohonan: "",
        jumlah_anggota: "",
        alamat: "",
        provinsi_id: "",
        kota_id: "",
        kecamatan_id: "",
        kelurahan_id: "",
        warding: {
          nama_pemohon_warding: "",
          nik_pemohon_warding: "",
          alasan_permohonan_warding: "",
          jumlah_anggota_warding: "",
          alamat_warding: "",
          provinsi_pemohon_warding: "",
          kota_pemohon__warding: "",
          kecamatan_pemohon_warding: "",
          kelurahan__pemohonan_warding: "",
        },
      },
      domisili: {
        provinsi_domisili_id: "",
        kota_domisili_id: "",
        kecamatan_domisili_id: "",
        kelurahan_domisili_id: "",
        warding: {
          provinsi_domisili_warding: "",
          kota_domisili_warding: "",
          kecamatan_domisili_warding: "",
          kelurahan_domisili_warding: "",
        },
      },
      anggota: [],
      forms: {
        pemohon: [],
        domisili: [],
      },
      updateStatus: [],
      supervisor: {
        tanggal_kedatangan: "",
        lokasi_id: "",
        notes_supervisor: "",
        jam: "",
        menit: "",
        status: null,
      },
      operator: {
        notes: "",
        status: null,
      },
    },
    mode: "onChange",
  });

  const { response, loading, fetch } = useFetchData({
    action: getDetailKK,
    snackbar: false,
    onSuccess: (response) => {
      const data = response?.list[0];
      if (
        (isOperator && data.status == "dijadwalkan") ||
        (isOperator && data.status == "ditolak") ||
        (isOperator && data.notes)
      ) {
        return navigate("/laporan-adjudicator/sentra-kependudukan/kk");
      }

      // if (isSuperVisor && data.status == "ditolak_lv3") {
      //   return navigate("/laporan-adjudicator/sentra-kependudukan/ktp");
      // }
      resetForm({
        pemohon: {
          nama_pemohon: data.nama_pemohon,
          nik_pemohon: data.nik_pemohon,
          alasan_permohonan: data.alasan_permohonan,
          jumlah_anggota: data.jumlah_anggota,
          alamat: data.alamat,
          provinsi_id: data.provinsi_id,
          kota_id: data.kota_id,
          kecamatan_id: data.kecamatan_id,
          kelurahan_id: data.kelurahan_id,
          warding: {
            nama_pemohon_warding: data.nama_pemohon_warding,
            nik_pemohon_warding: data.nik_pemohon_warding,
            alasan_permohonan_warding: data.alasan_permohonan_warding,
            jumlah_anggota_warding: data.jumlah_anggota_warding,
            alamat_warding: data.alamat_warding,
            provinsi_pemohon_warding: data.provinsi_pemohon_warding,
            kota_pemohon_warding: data.kota_pemohon_warding,
            kecamatan_pemohon_warding: data.kecamatan_pemohon_warding,
            kelurahan_pemohon_warding: data.kelurahan_pemohon_warding,
          },
        },
        domisili: {
          provinsi_domisili_id: data.provinsi_domisili_id,
          kota_domisili_id: data.kota_domisili_id,
          kecamatan_domisili_id: data.kecamatan_domisili_id,
          kelurahan_domisili_id: data.kelurahan_domisili_id,
          warding: {
            provinsi_domisili_warding: data.provinsi_domisili_warding,
            kota_domisili_warding: data.kota_domisili_warding,
            kecamatan_domisili_warding: data.kecamatan_domisili_warding,
            kelurahan_domisili_warding: data.kelurahan_domisili_warding,
          },
        },
        anggota: [],
        forms: {
          pemohon: pemohon,
          domisili: domisili,
        },
        updateStatus: [],
        supervisor: {
          tanggal_kedatangan: data?.tanggal_kedatangan,
          lokasi_id: data?.lokasi_id,
          notes_supervisor: "",
          jam: moment(data?.jam_kedatangan, "HH:mm:ss").isValid()
            ? moment(data?.jam_kedatangan, "HH:mm:ss").format("HH")
            : "",
          menit: moment(data?.jam_kedatangan, "HH:mm:ss").isValid()
            ? moment(data?.jam_kedatangan, "HH:mm:ss").format("mm")
            : "",
          status: "",
        },
        operator: {
          notes: "",
          status: null,
        },
      });

      if (data?.status) {
        if (
          !isOperator &&
          data?.status != "diproses" &&
          data?.status != "ditolak" &&
          data?.status != "ditolak_lv3"
        ) {
          Status.fetch(id);
        }

        if (!isOperator) {
          const kecamatan_id = data?.lokasi_id?.slice(0, 4) || "";
          Lokasi.fetch(kecamatan_id);
        }
      }
    },
  });

  const Status = useFetchData({
    action: getStatus,
    startLoading: true,
    snackbar: false,
  });

  const Jadwal = useFetchData({
    action: updateJadwalKK,
    snackbar: false,
  });

  const Lokasi = useFetchData({
    action: getKecamatan,
    snackbar: false,
  });

  const changeTitle = (txt) => {
    switch (txt) {
      case "nama":
        return "Nama Lengkap";
      case "no_nik":
        return "NIK";
      case "status_hubungan":
        return "Status";

      default:
        break;
    }
  };

  const getRules = (txt) => {
    switch (txt) {
      case "nama":
        return {
          required: "Nama Lengkap Wajib Diisi",
        };
      case "no_nik":
        return {
          required: "No NIK Wajib Diisi",
          maxLength: {
            message: "No. NIK tidak boleh melebihi 16 karakter",
            value: 16,
          },
        };
      case "status_hubungan":
        return {
          required: "Status Hubungan Wajib Diisi",
        };
      default:
        break;
    }
  };

  const getWording = (txt) => {
    switch (txt) {
      case "nama":
        return "nama_warding";
      case "no_nik":
        return "no_nik_warding";
      case "status_hubungan":
        return "status_hubungan_warding";
      default:
        break;
    }
  };

  const Anggota = useFetchData({
    action: getKKAnggota,
    snackbar: false,
    onSuccess: (data) => {
      const copy = [...data];

      let arr = copy.map(({ nama, no_nik, status_hubungan }) => {
        return {
          nama,
          no_nik,
          status_hubungan,
        };
      });

      let newArr = arr.map((item, i) => {
        return Object.keys(item).map((ite) => {
          return {
            label: changeTitle(ite),
            value: item[ite],
            warding: data[i][getWording(ite)],
            data: {
              ...data[i],
              key: ite,
              commentKey: getWording(ite),
              value: "",
              comment: "",
              openNotes: false,
              openComment: false,
              type: ite == "status_hubungan" ? "autocomplete" : "",
              rules: getRules(ite),
            },
          };
        });
      });

      setValue("anggota", newArr);
    },
  });

  const Verifikasi = useFetchData({
    action: isSuperVisor ? konfirmasiKK : verifikasiKK,
    message: isSuperVisor
      ? "Data berhasil dikonfirmasi"
      : "Data berhasil diverifikasi",
    onSuccess: () => {
      navigate("/laporan-adjudicator/sentra-kependudukan/kk");
    },
  });

  const UpdateStatusData = useFetchData({
    action: updateStatusData,
    message: "Update status berhasil",
    onSuccess: (_, index) => {
      setValue(`updateStatus.${index}.open`, false);
      fetch(id);
    },
  });

  const UpdateAnggota = useFetchData({
    action: editKKAnggota,
    message: "Update Anggota berhasil",
    onSuccess: (
      response,
      { data, parentIndex, index, openNotes, openComment }
    ) => {
      if (openNotes) {
        setValue(`anggota.${parentIndex}.${index}.value`, response[data]);
        setValue(`anggota.${parentIndex}.${index}.data.openNotes`, !openNotes);
      } else {
        setValue(`anggota.${parentIndex}.${index}.warding`, response[data]);
        setValue(
          `anggota.${parentIndex}.${index}.data.openComment`,
          !openComment
        );
      }
    },
  });

  useEffect(() => {
    const go = async () => {
      await fetch(id);
      await Anggota.fetch(id);
    };

    go();
  }, []);

  const data = useMemo(() => {
    return response?.list[0];
  }, [response]);

  const { provinsi, kota, kecamatan, kelurahan } = useGetRegion({
    provinsi_id: data?.provinsi_id,
    kota_id: data?.kota_id,
    kecamatan_id: data?.kecamatan_id,
    kelurahan_id: data?.kelurahan_id,
    onSuccessProvinsi: (data) => {
      setValue("forms.pemohon[5].options", data);
      setValue("forms.pemohon[5].loading", false);
    },
    onSuccessKota: (data) => {
      setValue("forms.pemohon[6].options", data);
      setValue("forms.pemohon[6].loading", false);
    },
    onSuccessKecamatan: (data) => {
      setValue("forms.pemohon[7].options", data);
      setValue("forms.pemohon[7].loading", false);
    },
    onSuccessKelurahan: (data) => {
      setValue("forms.pemohon[8].options", data);
      setValue("forms.pemohon[8].loading", false);
    },
  });

  const {
    provinsi: provinsiDomisili,
    kota: kotaDomisili,
    kecamatan: kecamatanDomisili,
    kelurahan: kelurahanDomilisi,
  } = useGetRegion({
    provinsi_id: data?.provinsi_domisili_id,
    kota_id: data?.kota_domisili_id,
    kecamatan_id: data?.kecamatan_domisili_id,
    kelurahan_id: data?.kelurahan_domisili_id,
    onSuccessProvinsi: (data) => {
      setValue("forms.domisili[0].options", data);
      setValue("forms.domisili[0].loading", false);
    },
    onSuccessKota: (data) => {
      setValue("forms.domisili[1].options", data);
      setValue("forms.domisili[1].loading", false);
    },
    onSuccessKecamatan: (data) => {
      setValue("forms.domisili[2].options", data);
      setValue("forms.domisili[2].loading", false);
    },
    onSuccessKelurahan: (data) => {
      setValue("forms.domisili[3].options", data);
      setValue("forms.domisili[3].loading", false);
    },
  });

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  useEffect(() => {
    let update = [];
    let slice = [];

    if (Status?.response?.values) {
      update = updatestatus?.map((item) => {
        return {
          ...item,
          open: false,
          value: Status?.response?.values[item.data] || "",
        };
      });
      slice = update.slice(0, Status?.response?.total_key);
    }

    setValue("updateStatus", slice);
  }, [Status?.response]);

  const update = useFetchData({
    action: editKK,
    message: "berhasil mengubah data",
    onSuccess: (
      response,
      { name, data, index, openNotes, type, openComment, set, arr }
    ) => {
      switch (type) {
        case "autocomplete":
          const forms = watch(`forms.${set}`);
          Object.keys(response).map((item) => {
            if (arr.includes(item)) {
              const getIndex = forms.findIndex((i) => i.data == item);
              setValue(`forms.${set}.${getIndex}.openNotes`, false);
              setValue(`${set}.${item}`, response[item]);
            }
          });

          break;

        default:
          setValue(name, response[data]);
          if (openNotes) {
            setValue(`forms.${set}.${index}.openNotes`, !openNotes);
          } else {
            setValue(`forms.${set}.${index}.openComment`, !openComment);
          }
          break;
      }
    },
  });

  const getArray = (set) => {
    let arr;
    if (set == "domisili") {
      arr = [
        "provinsi_domisili_id",
        "kota_domisili_id",
        "kecamatan_domisili_id",
        "kelurahan_domisili_id",
      ];
    } else {
      arr = ["provinsi_id", "kota_id", "kecamatan_id", "kelurahan_id"];
    }
    return arr;
  };

  const handleEdit = (
    data,
    value,
    { name, index, openNotes, type, options, openComment, set }
  ) => {
    switch (type) {
      case "autocomplete":
        let copyInput = {};
        let arr = getArray(set);
        const newArr = arr.slice(arr.indexOf(data), arr.length);

        for (let i = 0; i < newArr.length; i++) {
          copyInput[newArr[i]] = "";
          copyInput[newArr[i].split("_id")[0]] = "";
        }
        copyInput[data] = value;
        copyInput[data.split("_id")[0]] = options?.find(
          (item) => item.id == value
        )?.nama;

        update.fetch(id, copyInput, {
          name,
          data,
          index,
          openNotes,
          type,
          set,
          arr,
        });
        break;

      default:
        const formData = new FormData();
        formData.append(data, value);
        update.fetch(id, formData, {
          name,
          data,
          index,
          openNotes,
          type,
          openComment,
          set,
        });
        break;
    }
  };

  const handleUpdateAnggota = (
    data,
    value,
    aid,
    { parentIndex, index, type, openNotes, openComment }
  ) => {
    const formData = new FormData();
    formData.append(data, value);

    if (type == "autocomplete") {
      if (value.toLowerCase() == "lainnya") {
        const status = getValues(
          `anggota.${parentIndex}.${index}.data.status_hubungan_lainnya`
        );
        formData.append("status_hubungan_lainnya", status);
      }
    }

    UpdateAnggota.fetch(
      formData,
      { aid, did: id },
      { data, parentIndex, index, openNotes, type, openComment }
    );
  };
  const informasiPermohonan = useMemo(() => {
    return [
      {
        label: "Status",
        value: <KependudukanNewDetailStatus value={data?.status} />,
      },
      {
        label: "Tgl. Status",
        value: moment(data?.updated_at).format("DD / MM / YYYY"),
      },
    ];
  }, [data?.status]);

  const handleUpdateStatus = () => {
    const up = watch("updateStatus");
    const nextHiddenItem = updatestatus.find((_, i) => i == up.length);
    nextHiddenItem.value = new Date();
    nextHiddenItem.open = true;
    if (nextHiddenItem) {
      setValue("updateStatus", [...up, nextHiddenItem]);
    }
  };

  const handleCheckUpdateStatus = (index, { data }) => {
    const formData = new FormData();
    formData.append(data, moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    UpdateStatusData.fetch(id, formData, index);
  };

  const handleCancelUpdateStatus = () =>
    setValue(`updateStatus`, watch("updateStatus").slice(0, -1));

  const updateStatus = useMemo(() => {
    return watch("updateStatus")?.map((item, i) => {
      return {
        label: item.label,
        value: (
          <CheckUpdateStatus
            index={i}
            key={item.key}
            onCheck={handleCheckUpdateStatus}
            onCancel={handleCancelUpdateStatus}
            {...item}
          />
        ),
      };
    });
  });

  const onSubmit = (data) => {
    const submitData = isSuperVisor ? data.supervisor : data.operator;
    if (isSuperVisor) {
      submitData.jam_kedatangan = `${submitData.jam}:${submitData.menit}:00`;
      // let find =
      //   Lokasi?.response?.find(
      //     (item) => item?.kecamatan_id == watch("supervisor.lokasi_id")
      //   );
      // console.log(watch("supervisor.lokasi_id"));
      submitData.tempat_kedatangan = "";
      delete submitData.jam;
      delete submitData.menit;
    }
    const { Id } = JSON.parse(localStorage.getItem("profile-ntt"));

    submitData.updatebyid = String(Id);

    Verifikasi.fetch(id, submitData);
    if (isSuperVisor) {
      Jadwal.fetch([
        {
          id: id,
          tanggal_kedatangan: moment(submitData.tanggal_kedatangan).format(
            "YYYY-MM-DD"
          ),
        },
      ]);
    }
  };

  return (
    <>
      <Page
        backBtn
        title={`Perbandingan Data - ${data?.nama_pemohon?.split(" ")[0] || ""}`}
        page={["Laporan Adjudicator", "Sentra Kependudukan", "KK"]}
        menu="Perbandingan Data"
        to="/laporan-adjudicator/sentra-kependudukan/kk"
      />

      <Grid
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
        spacing={2}
      >
        <Grid item xs={12} md={7}>
          <MainCard2 margin title="Informasi Permohonan">
            <MainLoading height={100} loading={loading}>
              {informasiPermohonan.map((item, i) => (
                <MainGrid
                  key={i}
                  labelMd={3}
                  valueMd={9}
                  {...item}
                  striped="odd"
                  center
                />
              ))}
            </MainLoading>
          </MainCard2>
          <MainCard2 margin title="Informasi Domisili">
            <MainLoading height={200} loading={loading} center>
              {getValues("forms.domisili").map((item, i) => {
                return (
                  <MainGrid
                    key={i}
                    labelMd={3}
                    valueMd={9}
                    label={item.label}
                    value={
                      <InputFormDynamic
                        set="domisili"
                        index={i}
                        trigger={trigger}
                        getProps={getProps}
                        watch={watch}
                        errors={errors}
                        setValue={setValue}
                        handleEdit={handleEdit}
                        getValues={getValues}
                        {...item}
                      />
                    }
                    striped="odd"
                  />
                );
              })}
            </MainLoading>
          </MainCard2>

          <MainCard2 margin title="Informasi Pemohon">
            <MainLoading height={200} loading={loading} center>
              {getValues("forms.pemohon").map((item, i) => {
                return (
                  <MainGrid
                    key={i}
                    labelMd={3}
                    valueMd={9}
                    label={item.label}
                    value={
                      <InputFormDynamic
                        set="pemohon"
                        index={i}
                        trigger={trigger}
                        getProps={getProps}
                        watch={watch}
                        errors={errors}
                        setValue={setValue}
                        handleEdit={handleEdit}
                        getValues={getValues}
                        {...item}
                      />
                    }
                    striped="odd"
                  />
                );
              })}
            </MainLoading>
          </MainCard2>

          {getValues("anggota").map((yo, index) => {
            return (
              <MainCard2
                key={index}
                margin
                title={`Informasi Anggota Keluarga ${index + 1}`}
              >
                {yo.map((item, i) => {
                  return (
                    <MainGrid
                      key={i}
                      labelMd={3}
                      valueMd={9}
                      label={item.label}
                      value={
                        <AnggotaForm
                          parentIndex={index}
                          index={i}
                          trigger={trigger}
                          getProps={getProps}
                          watch={watch}
                          errors={errors}
                          setValue={setValue}
                          handleEdit={handleUpdateAnggota}
                          getValues={getValues}
                          {...item.data}
                          {...item}
                        />
                      }
                      striped="even"
                    />
                  );
                })}
              </MainCard2>
            );
          })}

          {!isOperator && data?.status != "ditolak" && (
            <MainCard2 margin title="Lokasi Kedatangan">
              <MainLoading height={80} loading={loading} center>
                <MainGrid
                  labelMd={3}
                  valueMd={9}
                  striped=""
                  label="Lokasi"
                  value={
                    <Box display="flex" flexDirection="column" width="100%">
                      <MainController
                        {...getProps(`supervisor.lokasi_id`)}
                        customError={errors?.supervisor?.lokasi_id?.message}
                        rules={{
                          required:
                            watch("supervisor.status") != "ditolak"
                              ? "Lokasi Kedatangan wajib disii"
                              : false,
                        }}
                        render={({ field, fieldState: { invalid } }) => (
                          <Autocomplete
                            variant="v1"
                            options={Lokasi?.response || []}
                            value={
                              Lokasi?.response?.find(
                                (item) => item?.id == field?.value
                              ) || null
                            }
                            error={invalid}
                            disabled={
                              !isSuperVisor ||
                              (data?.status != "diproses" &&
                                data?.status != "ditolak_lv3")
                            }
                            getOptionLabel={(option) => option.nama}
                            loading={Lokasi.loading}
                            isOptionEqualToValue={(option, value) =>
                              option.id == value.id
                            }
                            placeholder="Pilih lokasi kedatangan"
                            onChange={(_, nv) => {
                              if (nv == null) {
                                return field.onChange(null);
                              }
                              field.onChange(nv.id);
                            }}
                          />
                        )}
                      />
                    </Box>
                  }
                  center
                />
              </MainLoading>
            </MainCard2>
          )}

          {!isOperator && data?.status != "ditolak" && (
            <MainCard2 margin title="Jadwal Kedatangan">
              <MainLoading height={100} loading={loading} center>
                <MainGrid
                  labelMd={3}
                  valueMd={9}
                  striped=""
                  label="Tanggal"
                  value={
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      gap={1}
                    >
                      <MainController
                        {...getProps(`supervisor.tanggal_kedatangan`)}
                        rules={{
                          required:
                            watch("supervisor.status") != "ditolak"
                              ? "Tanggal wajib disii"
                              : false,
                        }}
                        customError={
                          errors?.supervisor?.tanggal_kedatangan?.message
                        }
                        render={({ field, fieldState: { invalid } }) => (
                          <DatePicker2
                            {...field}
                            past
                            error={invalid}
                            placeholder="DD/MM/YYYY"
                            disabled={
                              !isSuperVisor ||
                              (data?.status != "diproses" &&
                                data?.status != "ditolak_lv3")
                            }
                          />
                        )}
                      />
                    </Box>
                  }
                  center
                />
                <MainGrid
                  labelMd={3}
                  valueMd={9}
                  striped=""
                  label="Jam"
                  value={
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      gap={1}
                    >
                      <MainController
                        {...getProps(`supervisor.menit`)}
                        rules={{
                          required:
                            watch("supervisor.status") != "ditolak"
                              ? "Waktu wajib disii"
                              : false,
                        }}
                        customError={errors?.supervisor?.menit?.message}
                        render={({ field, fieldState: { invalid } }) => (
                          <TimePicker
                            error={invalid}
                            hours={watch("supervisor.jam")}
                            minutes={field.value}
                            onChangeHours={(e) => {
                              setValue("supervisor.jam", e);
                            }}
                            disabled={
                              !isSuperVisor ||
                              (data?.status != "diproses" &&
                                data?.status != "ditolak_lv3")
                            }
                            onChangeMinutes={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Box>
                  }
                  center
                />
              </MainLoading>
            </MainCard2>
          )}

          <MainCard2 margin title="Catatan">
            <MainLoading height={80} loading={loading} center>
              {data?.notes && (
                <Box display="flex" gap={1} pl={2} pt={1}>
                  <Typography variant="label" width="140px">
                    Dari Operator
                  </Typography>
                  <Typography variant="label">
                    : {data?.notes || "-"}
                  </Typography>
                </Box>
              )}
              {data?.notes_supervisor && (
                <Box display="flex" gap={1} pl={2} pt={1}>
                  <Typography variant="label" width="140px">
                    Dari Supervisor
                  </Typography>
                  <Typography variant="label">
                    : {data?.notes_supervisor || "-"}
                  </Typography>
                </Box>
              )}

              {(data?.status == "diproses" || data?.status == "ditolak_lv3") &&
              (isSuperVisor || isOperator) ? (
                <Box sx={{ p: 2 }}>
                  <MainController
                    {...getProps(
                      isSuperVisor
                        ? "supervisor.notes_supervisor"
                        : "operator.notes"
                    )}
                    customError={
                      isSuperVisor
                        ? errors?.supervisor?.notes_supervisor?.message
                        : errors?.operator?.notes.message
                    }
                    rules={{
                      required: "Catatan wajib disii",
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <OutlinedInput
                        {...field}
                        multiline
                        rows={6}
                        disabled={
                          data?.status != "diproses" &&
                          data?.status != "ditolak_lv3"
                        }
                        error={invalid}
                        placeholder="Cth: warna rambut berbeda"
                      />
                    )}
                  />
                </Box>
              ) : null}
            </MainLoading>
          </MainCard2>

          {(data?.status == "diproses" || data?.status == "ditolak_lv3") &&
          (isSuperVisor || isOperator) ? (
            <MainCard2 margin title="Keputusan">
              <MainLoading height={100} loading={loading} center>
                <Box display="flex" gap={1} p={2}>
                  <MainController
                    {...getProps(
                      `${
                        isSuperVisor ? "supervisor.status" : "operator.status"
                      }`
                    )}
                    rules={{ required: "Status wajib diisi" }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Autocomplete
                        variant="v1"
                        {...field}
                        error={invalid}
                        options={
                          isSuperVisor
                            ? confirmOptionSupervisor
                            : confirmOptionOperator
                        }
                        value={
                          isSuperVisor
                            ? confirmOptionSupervisor.find(
                                (item) => item.value === field.value
                              ) || null
                            : confirmOptionOperator.find(
                                (item) => item.value === field.value
                              ) || null
                        }
                        placeholder="Pilih keputusan akhir"
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        disabled={
                          data?.status != "diproses" &&
                          data?.status != "ditolak_lv3"
                        }
                        onChange={(_, nv) => {
                          if (nv == null) {
                            field.onChange(1);
                          }
                          field.onChange(nv.value);
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    sx={{
                      minWidth: "140px",
                    }}
                    disabled={!isValid}
                  >
                    {isSuperVisor ? "Konfirmasi" : "Validasi"}
                  </Button>
                </Box>
              </MainLoading>
            </MainCard2>
          ) : null}
        </Grid>

        <Grid item xs={12} md={5}>
          {!Status.loading &&
          !isOperator &&
          data?.status != "diproses" &&
          data?.status != "ditolak_lv3" &&
          data?.status != "ditolak" ? (
            <MainCard2 margin title="Update Status">
              <MainLoading height={70} loading={loading} center>
                <UpdateStatus
                  value={updateStatus}
                  onUpdate={handleUpdateStatus}
                  disabled={
                    watch("updateStatus").length == updatestatus.length ||
                    watch("updateStatus").some((i) => i.open == true) ||
                    Status.loading
                  }
                  status={data?.status}
                />
              </MainLoading>
            </MainCard2>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default DetailKK;
