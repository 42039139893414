import PropTypes from "prop-types";
import React, { memo } from "react";
import { Box, Collapse, OutlinedInput } from "@mui/material";
import { hubungan_keluarga } from "./variabel";
import MainController from "components/Controller/MainController";
import Autocomplete from "components/AutoComplete/Autocomplete";

const AnggotaDynamicInput = memo(
  ({
    type,
    field,
    invalid,
    valueLainnya,
    onChangeLainnya,
    onKeyDown,
    parentIndex,
    index,
    getProps,
    ...item
  }) => {
    switch (type) {
      case "autocomplete":
        return (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            gap={field.value == "LAINNYA" ? 1 : 0}
          >
            <Autocomplete
              variant="v3"
              {...field}
              options={hubungan_keluarga}
              value={
                hubungan_keluarga.find((item) => item.value == field.value) ||
                null
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv == null) {
                  field.onChange("");
                }
                field.onChange(nv.value);
              }}
            />

            <Collapse in={field.value == "LAINNYA"}>
              <MainController
                {...getProps(
                  `anggota.${parentIndex}.${index}.data.status_hubungan_lainnya`
                )}
                rules={{required: false}}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    variant="v3"
                    {...field}
                    error={invalid}
                    onKeyDown={onKeyDown}
                  />
                )}
              />
            </Collapse>
          </Box>
        );

      default:
        return (
          <OutlinedInput
            variant="v3"
            {...field}
            error={invalid}
            onKeyDown={onKeyDown}
          />
        );
    }
  }
);

AnggotaDynamicInput.displayName = "adi";

AnggotaDynamicInput.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  invalid: PropTypes.bool,
  options: PropTypes.any,
  from: PropTypes.any,
  type: PropTypes.string,
  onKeyDown: PropTypes.func,
  valueLainnya: PropTypes.string,
  onChangeLainnya: PropTypes.func,
  parentIndex: PropTypes.number,
  index: PropTypes.number,
  getProps: PropTypes.func,
};

AnggotaDynamicInput.defaultProps = {
  field: null,
  invalid: false,
  type: "",
  options: [],
  from: null,
  onKeyDown: () => {},
  valueLainnya: "",
  parentIndex: null,
  index: null,
  onChangeLainnya: () => {},
  getProps: null,
};
export default AnggotaDynamicInput;
