import { hubungan_keluarga } from "./variabel";

const AnggotaDynamicLabel = (type, name) => {
  switch (type) {
    case "autocomplete":
      return hubungan_keluarga.find((item) => item.value.toLowerCase() == name.trim().toLowerCase())?.label || "";
    default:
      return name;
  }
};

export default AnggotaDynamicLabel;
