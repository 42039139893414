import { Box, Button, Grid, OutlinedInput } from "@mui/material";
import Autocomplete from "components/AutoComplete/Autocomplete";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import Page from "components/Page";
import MainCard2 from "components/Paper/MainCard2";
import InputImage from "components/TextField/InputImage";
import useFetchData from "hook/useFetchData";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getDetailBanner, newBanner, updateBanner } from "redux/CMS/action";

const Status = [
  { id: 1, label: "Published" },
  { id: 2, label: "Inactive" },
];

const CreateBanner = () => {
  const navigate = useNavigate();

  const { response, fetch, loading } = useFetchData({
    action: getDetailBanner,
    snackbar: false,
    onSuccess: (data) => {
      reset({
        nama_banner: data.data_records.nama_banner,
        gambar: data.data_records.gambar,
        status: data.data_records.status,
      });
    },
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, []);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      nama_banner: "",
      gambar: null,
      status: null,
    },
  });

  const action = useFetchData({
    action: id ? updateBanner : newBanner,
    reset,
    message: !id ? "CMS Banner baru berhasil" : "CMS Banner perbarui berhasil",
    onSuccess: () => {
      navigate("/master-data/cms/kelola-banner");
    },
  });

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const onSubmit = (data) => {
    const formData = new URLSearchParams({})
    const nama = JSON.parse(localStorage.getItem("profile-ntt")).Fullname;
    convertToBase64(data.gambar, (base64) => {
      data.gambar = base64
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("dibuat_oleh", nama);
  
      action.fetch(formData, id);

    })
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  return (
    <>
      <Page title="Banner" backBtn />
      <MainCard2 title={`${id ? "Edit" : "Tambah"} Banner`} margin>
        <Box component="form" p={2} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <VerticalGrid label={"Nama Banner"} md={12}>
              <MainController
                {...getProps("nama_banner")}
                rules={{ required: "Nama Banner wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    type="text"
                    placeholder="Masukkan nama banner"
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Gambar Banner"} md={12}>
              <MainController
                {...getProps("gambar")}
                rules={{ required: "Gambar Banner wajib diisi" }}
                desc="Maks 5MB dengan format JPG & PNG. Gambar dengan ukuran 135x 330 piksel untuk hasil optimal."
                render={({ field, fieldState: { invalid } }) => (
                  <InputImage
                    {...field}
                    error={invalid}
                    name="gambar"
                    setError={setError}
                    placeholder="Masukkan gambar banner"
                    showImage={Boolean(field.value)}
                    onClose={() => field.onChange(null)}
                  />
                )}
              />
            </VerticalGrid>
            <VerticalGrid label={"Status"} md={12}>
              <MainController
                {...getProps("status")}
                rules={{ required: "Status wajib diisi" }}
                render={({ field, fieldState: { invalid } }) => (
                  <Autocomplete
                    options={Status || []}
                    value={
                      Status?.find((item) => item.id == field.value) || null
                    }
                    onChange={(_, nv) => {
                      if (nv == null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv?.id);
                      }
                    }}
                    error={invalid}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    placeholder="Pilih Status"
                    getOptionLabel={(option) => option.label || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id == value.id
                    }
                  />
                )}
              />
            </VerticalGrid>
          </Grid>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            gap={2}
            mt={2}
          >
            <Button variant="secondary-outlined" onClick={() => reset()}>
              Reset
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!isValid || action.loading}
            >
              Simpan
            </Button>
          </Box>
        </Box>
      </MainCard2>
    </>
  );
};

export default CreateBanner;
