import React from "react";

const Password = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0625 8.6875H16.875C17.7688 8.6875 18.5 9.41875 18.5 10.3125V18.4375C18.5 19.3312 17.7688 20.0625 16.875 20.0625H7.125C6.23125 20.0625 5.5 19.3312 5.5 18.4375V10.3125C5.5 9.41875 6.23125 8.6875 7.125 8.6875H7.9375V7.0625C7.9375 4.82 9.7575 3 12 3C14.2425 3 16.0625 4.82 16.0625 7.0625V8.6875ZM12 4.625C10.6512 4.625 9.5625 5.71375 9.5625 7.0625V8.6875H14.4375V7.0625C14.4375 5.71375 13.3488 4.625 12 4.625ZM7.125 18.4375V10.3125H16.875V18.4375H7.125ZM13.625 14.375C13.625 15.2688 12.8938 16 12 16C11.1062 16 10.375 15.2688 10.375 14.375C10.375 13.4812 11.1062 12.75 12 12.75C12.8938 12.75 13.625 13.4812 13.625 14.375Z"
        fill="black"
      />
      <mask
        id="mask0_6780_20421"
        maskUnits="userSpaceOnUse"
        x="5"
        y="3"
        width="14"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0625 8.6875H16.875C17.7688 8.6875 18.5 9.41875 18.5 10.3125V18.4375C18.5 19.3312 17.7688 20.0625 16.875 20.0625H7.125C6.23125 20.0625 5.5 19.3312 5.5 18.4375V10.3125C5.5 9.41875 6.23125 8.6875 7.125 8.6875H7.9375V7.0625C7.9375 4.82 9.7575 3 12 3C14.2425 3 16.0625 4.82 16.0625 7.0625V8.6875ZM12 4.625C10.6512 4.625 9.5625 5.71375 9.5625 7.0625V8.6875H14.4375V7.0625C14.4375 5.71375 13.3488 4.625 12 4.625ZM7.125 18.4375V10.3125H16.875V18.4375H7.125ZM13.625 14.375C13.625 15.2688 12.8938 16 12 16C11.1062 16 10.375 15.2688 10.375 14.375C10.375 13.4812 11.1062 12.75 12 12.75C12.8938 12.75 13.625 13.4812 13.625 14.375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_20421)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default Password;
