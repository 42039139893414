import LaporanAdjudicatorIcon from "assets/layout/sidebar/LaporanAdjudicatorIcon";
const permissionList = ["Web:User:Get", "Web:Role:Get"];

const LaporanAdjudicator = {
  id: "laporan-adjudicator",
  type: "collapse",
  title: "Laporan Adjudicator",
  icon: LaporanAdjudicatorIcon,
  permission: permissionList,
  children: [
    {
      id: "digital-id",
      title: "Digital ID",
      type: "collapse",
      url: "/management-pengguna/pengguna",
      permission: permissionList[0],
      children: [
        {
          id: "ktp",
          title: "KTP",
          type: "item",
          url: "/laporan-adjudicator/digital-id/ktp",
          permission: permissionList[0],
        },
        {
          id: "tni",
          title: "TNI",
          type: "item",
          url: "/laporan-adjudicator/digital-id/tni",
          permission: permissionList[0],
        },
      ],
    },
    {
      id: "sentra-kependudukan",
      title: "Sentra Kependudukan",
      type: "collapse",
      permission: permissionList[0],
      children: [
        {
          id: "ktp",
          title: "KTP",
          type: "item",
          url: "/laporan-adjudicator/sentra-kependudukan/ktp",
          permission: permissionList[0],
        },
        {
          id: "kk",
          title: "KK",
          type: "item",
          url: "/laporan-adjudicator/sentra-kependudukan/kk",
          permission: permissionList[0],
        },
        {
          id: "akta-lahir",
          title: "Akta Lahir",
          type: "item",
          url: "/laporan-adjudicator/sentra-kependudukan/akta-lahir",
          permission: permissionList[0],
        },
      ],
    },
  ],
};

export default LaporanAdjudicator;
