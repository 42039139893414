import PropTypes from "prop-types";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import PeriodeFormat from "components/Format/PeriodeFormat";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import MainStatus from "components/Status/MainStatus";
import FormatRupiah from "helper/FormatRupiah";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { kartuSubsidiDetail } from "redux/kartuSubsidi/action";
import { status_voucher } from "../Voucher/variables";
import Dialog from "./Dialog";
import { card_tipe } from "./variables";
import StatusKartuSubsidi from "components/Status/StatusKartuSubsidi";
import CopyIcon from "assets/voucher/detail/CopyIcon";

const informasiList = [
  "IssuerId",
  "IssuerName",
  "MerchantCode",
  "PrefixCode",
  "CardName",
  "CardType",
  "Status",
  "CardDesc",
  "CardTC",
  "StartDate",
  "EndDate",
  "AmountValue",
  "Budget",
  "Distributed",
];

const RenderValue = ({ data, key }) => {
  const [clipTxt, setClipTxt] = useState("");

  if (data.format === "status") {
    return <StatusKartuSubsidi value={data.name} />;
  } else if (data.format === "card_type") {
    return card_tipe?.find((i) => i.value === data.name)?.label || "-";
  } else if (data.format === "number") {
    return `${data.text ? data.text : ""} ${FormatRupiah(data.name) || "-"}`;
  } else if (data.format === "periode") {
    return <PeriodeFormat StartDate={data.startDate} EndDate={data.endDate} />;
  } else if (data.format === "signature") {
    return (
      <>
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "300px",
            textOverflow: "ellipsis",
          }}
          id={key}
        >
          {String(data.name)}
        </Box>
        <Tooltip
          title={clipTxt}
          placement="bottom"
          onMouseEnter={() => setClipTxt("Copy to clipboard")}
          onClick={() => {
            setClipTxt("Copied");
            navigator.clipboard.writeText(data.name);
          }}
        >
          <IconButton>
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  } else {
    return (
      (
        <Box
          dangerouslySetInnerHTML={{ __html: data.name }}
          sx={{
            whiteSpace: "pre-line",
          }}
        ></Box>
      ) || "-"
    );
  }
};

RenderValue.propTypes = {
  data: PropTypes.shape({
    endDate: PropTypes.any,
    format: PropTypes.string,
    name: PropTypes.any,
    startDate: PropTypes.any,
    text: PropTypes.any,
  }),
  key: PropTypes.any,
};

RenderValue.defaultProps = {
  data: null,
  key: null,
};

const DetailKartuSubsidi = () => {
  const [res, setRes] = useState(null);
  const [informasiData, setInformasiData] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [cutModal, setCutModal] = useState({
    informasi: true,
    ikon: false,
  });

  const id = useParams().id;

  const action = useFetchData({
    action: kartuSubsidiDetail,
    snackbar: false,
  });

  useEffect(() => {
    action.fetch(id);
  }, []);

  useEffect(() => {
    setRes(action.response);
  }, [action.response]);

  useEffect(() => {
    if (res) {
      setInformasiData([]);
      const i = res.informasi;
      const j = res.Card;
      let inf = [];
      let test = {
        "Nama Penerbit": {
          name: i.IssuerName,
        },
        "Kode Penerbit": {
          name: i.MerchantCode,
        },
        "Kode Prefix": {
          name: j.PrefixCode,
        },
        "Nama Kartu Subsidi": {
          name: i.CardName,
        },
        "Tipe Kartu Subsidi": {
          name: i.CardType,
          format: "card_type",
        },
        Status: {
          name: i.Status,
          format: "status",
          center: true,
        },
        "Deskripsi Subsidi": {
          name: i.CardDesc,
        },
        "Syarat & Ketentuan": {
          name: i.CardTC,
        },
        "Periode Kartu Subsidi": {
          startDate: i.StartDate,
          endDate: i.EndDate,
          format: "periode",
        },
        "Nomor Awal": {
          name: j.Start,
          format: "number",
        },
        "Nomor Akhir": {
          name: j.End,
          format: "number",
        },
        "Nominal Nilai": {
          name: i.AmountValue,
          format: "number",
        },
        "Jumlah Anggaran": {
          name: i.Budget,
          text: "Rp. ",
          format: "number",
        },
        "Jumlah Distribusi": {
          name: i.Distributed,
          format: "number",
        },
        "Digital Signature": {
          name: i.Signature,
          format: "signature",
          center: true,
        },
        "RSA Public Key": {
          name: i.IssuerPublic,
          format: "signature",
          center: true,
        },
      };

      for (const key in test) {
        inf.push({
          name: key,
          value: <RenderValue data={test[key]} key={key} />,
          center: test[key].center,
        });
      }

      setInformasiData(inf);
    }
  }, [res]);

  const DialogData = () => {
    setCutModal({
      informasi: true,
      ikon: false,
    });

    const filtered = Object?.keys(res.informasi || {})
      ?.filter((key) => informasiList.includes(key))
      ?.reduce((oj, key) => {
        oj[key] = res.informasi[key];
        return oj;
      }, {});

    filtered["PrefixCode"] = res.Card.PrefixCode;
    filtered["Start"] = res.Card.Start || "0";
    filtered["End"] = res.Card.End || "0";
    filtered["StartDate"] = moment(res.informasi.StartDate).format(
      "YYYY-MM-DD"
    );
    filtered["EndDate"] = moment(res.informasi.EndDate).format("YYYY-MM-DD");
    setData(filtered);
    setOpen(true);
  };

  const DialogDataGambar = () => {
    setCutModal({
      informasi: false,
      ikon: true,
      gambar: res.informasi.Gambar,
    });
    const filtered = {};
    filtered["Image1"] = null;
    setData(filtered);
    setOpen(true);
  };

  const isEdit = res?.Card?.GenerateCounter === 0;

  return (
    <>
      <PageTitle
        title={`Detail Kartu Subsidi`}
        to="/master-data/master-bantuan/kartu-subsidi"
      />
      <Grid container spacing={3}>
        <Grid xs={12} md={7} item>
          <MainCard
            title="Informasi"
            addButton={false}
            isEdit={isEdit}
            onClick={DialogData}
          >
            <MainLoading loading={action.loading} height={700}>
              {informasiData.map((item, i) => (
                <MainGrid
                  key={i}
                  striped="odd"
                  label={item.name}
                  value={item.value}
                  center={item.center}
                  labelMd={5}
                  valueMd={7}
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <MainCard
            title="Gambar Kartu Subsidi"
            addButton={false}
            isEdit={isEdit}
            onClick={DialogDataGambar}
          >
            <MainLoading loading={action.loading}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  p: 2,
                }}
                src={res?.informasi?.Gambar}
              />
            </MainLoading>
          </MainCard>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        isEdit={true}
        setRes={setRes}
        id={id}
        data={data}
        {...cutModal}
      />
    </>
  );
};

export default DetailKartuSubsidi;
