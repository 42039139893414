import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Image from "assets/QoinCash/PanggilanTelepon.png";

const ProsesPembayaran = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box component="img" src={Image} />
      <Typography variant="cardTitle4">Pembayaran Sedang Di Proses</Typography>
      <Typography
        variant="cardDesc2"
        sx={{
          textAlign: "center",
          width: "calc(100% - 15%)",
        }}
      >
        Pembayaran anda sedang diproses. Silahkan cek status pembayaran secara
        berkala melalui Aplikasi INISA
      </Typography>

      <Button
        variant="primary"
        sx={{
          width: "80%",
          height: "48px",
          marginTop: "20px",
        }}
        href={`/qoin/back-to-app`}
      >
        Kembali ke INISA
      </Button>
    </Box>
  );
};

export default ProsesPembayaran;
