import PropTypes from "prop-types";
import { Box, Switch, styled, Typography } from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import Autocomplete from "components/AutoComplete/Autocomplete";
import PropertiesInside from "./PropertiesInside";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#cc1d15",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Properties = ({
  data,
  currentForm,
  listItems,
  getId,
  handleChangeType,
  handleClose,
  currentSection,
  currentLength,
  getContentKey,
  handleChangeProperties,
  handleChangeContent,
  handleChangeContentObject,
  hideProperties,
  handleDeleteInput,
  handleChangePropertiesPanduan,
  handleSortableFiturOpsi,
}) => {
  const getValue = () => {
    const fi = data?.forms?.[getId(currentForm)]?.content?.find(
      (item) => item.id === currentForm
    );

    let newObj = {
      name: fi?.name,
      description: fi?.description,
      type: fi?.type,
    };

    return newObj;
  };

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        return;
      }}
    >
      <Box
        sx={{
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          backgroundColor: "#31394c",
          display: "inline-flex",
          justifyContent: "space-between",
          color: "white",
          width: "100%",
          padding: "10px 10px",
        }}
      >
        <Typography variant="label2" color="inherit">
          Properties
        </Typography>
        <Close
          sx={{
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        />
      </Box>
      <Box
        sx={{
          padding: "10px 10px",
        }}
      >
        <Autocomplete
          getOptionLabel={(option) => `${String(option.name) || ""}` || ""}
          value={getValue() || {}}
          isOptionEqualToValue={(option, value) =>
            option.type === value.type || null
          }
          disablePortal
          onChange={(_, option) => handleChangeType(option, currentForm)}
          options={listItems}
        />
      </Box>
      <PropertiesInside
        IOSSwitch={IOSSwitch}
        currentForm={currentForm}
        currentSection={currentSection}
        currentLength={currentLength}
        getContentKey={getContentKey}
        handleChangeProperties={handleChangeProperties}
        handleChangeContent={handleChangeContent}
        handleChangeContentObject={handleChangeContentObject}
        hideProperties={hideProperties}
        handleDeleteInput={handleDeleteInput}
        handleChangePropertiesPanduan={handleChangePropertiesPanduan}
        handleSortableFiturOpsi={handleSortableFiturOpsi}
      />
    </Box>
  );
};

Properties.propTypes = {
  currentForm: PropTypes.any,
  currentLength: PropTypes.any,
  currentSection: PropTypes.any,
  data: PropTypes.any,
  getContentKey: PropTypes.any,
  getId: PropTypes.func,
  handleChangeContent: PropTypes.any,
  handleChangeContentObject: PropTypes.any,
  handleChangeProperties: PropTypes.any,
  handleChangePropertiesPanduan: PropTypes.any,
  handleChangeType: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteInput: PropTypes.any,
  hideProperties: PropTypes.any,
  listItems: PropTypes.any,
  handleSortableFiturOpsi: PropTypes.any,
};

Properties.defaultProps = {
  currentForm: null,
  getId: null,
  handleChangeType: null,
  listItems: null,
  data: null,
  handleClose: () => {},
  currentLength: null,
  currentSection: null,
  getContentKey: null,
  handleChangeContent: null,
  handleChangeContentObject: null,
  handleChangeProperties: null,
  handleChangePropertiesPanduan: null,
  handleDeleteInput: null,
  hideProperties: null,
  handleSortableFiturOpsi: null,
};

export default Properties;
