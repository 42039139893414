import { Box, Typography } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import PropTypes from "prop-types";

const CardRole = ({ data, limit }) => {
  if (!data?.length) return;
  const group = groupBy(data);
  let web = group?.Web;
  const getProp = getLihatEdit(web);
  const slice = Object.keys(getProp).slice(0, 5);
  const other = Object.keys(getProp).slice(slice.length, getProp.length);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {(limit ? slice : Object.keys(getProp))?.map((item, i) => (
        <Box display="flex" key={i}>
          <Typography
            variant="desc3"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CircleIcon
              sx={{
                fontSize: "8px",
                color: "#DD6862",
              }}
            />
            {!item.includes("Officer")
              ? Object.keys(getProp[item]).map(
                  (item, i, arr) =>
                    `${changeItemText(item)} ${
                      i == arr.length - 1 ? "" : "dan "
                    }`
                )
              : ""}{" "}
            {!item.includes("CSRBansos") ? changeListText(item) : "CSR Bansos"}{" "}
          </Typography>
        </Box>
      ))}

      {Boolean(other.length) && limit ? (
        <Box display="flex">
          <Typography
            variant="desc3"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CircleIcon
              sx={{
                fontSize: "8px",
                color: "#DD6862",
              }}
            />
            dan {other.length} lainnya...
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export const groupBy = (data) => {
  const assign = (obj, keyPath, value) => {
    let lastKeyIndex = keyPath.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
      let key = keyPath[i];
      if (!(key in obj)) {
        obj[key] = {};
      }
      obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
  };

  let obj = {};
  data.forEach((item) => {
    assign(obj, item.split(":"), true);
  });

  return obj;
};

const getLihatEdit = (object) => {
  let obj = {};
  let title = "";
  const get = (data, yo) => {
    Object?.keys(data)?.forEach((key) => {
      if (yo == true) {
        title = key;
      }
      if (typeof data?.[key] == "object") {
        get(data?.[key], false);
      } else {
        obj[title] = {
          ...obj[title],
          [key]: true,
        };
      }
    });
  };
  get(object, true);

  return obj;
};

const changeItemText = (txt) => {
  switch (txt) {
    case "Get":
      return "Lihat";
    case "Edit":
      return "Ubah";
    case "Delete":
      return "Hapus";
    case "Create":
      return "Buat";
    case "All":
      return "Semua";
    default:
      return txt;
  }
};

const changeListText = (item) => {
  switch (item) {
    case "Officer":
      return "Akses aplikasi INISA Officer";
    default:
      return item.replace(/([A-Z])/g, " $1").trim();
  }
};

CardRole.propTypes = {
  data: PropTypes.any,
  limit: PropTypes.bool,
};

CardRole.defaultProps = {
  data: [],
  limit: true,
};

export default CardRole;
