import React from "react";
import { Button, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IcRef } from "./variable";

const generateLink = (payload, url_key) => {
  let linkKey = url_key.split(":");
  let realKey = [];
  linkKey.forEach((payload_id) => {
    if (payload?.[payload_id]) {
      realKey.push(payload[payload_id]);
    } else {
      realKey.push(payload_id);
    }
  });

  let result = "/" + realKey.join("/");
  return result;
};

const DetailButton = ({
  variant = "text:Proses",
  onClick = () => {},
  root,
  ...other
}) => {
  const val = variant.split(":")[1];
  if (variant.startsWith("text:")) {
    return (
      <Button
        variant="secondary-outlined"
        sx={{
          height: "30px",
        }}
        onClick={(e) => {
          onClick(e, root);
        }}
        {...other}
      >
        {val}
      </Button>
    );
  } else {
    return (
      <Button
        variant="secondary-outlined-icon"
        onClick={(e) => {
          onClick(e, root);
        }}
        {...other}
      >
        {IcRef?.[val]()}
      </Button>
    );
  }
};

const LinkButton = ({ url_key, type, custom_link, root, ...other }) => {
  if (type == "link") {
    const navigateTo = custom_link
      ? custom_link(root)
      : generateLink(root, url_key);
    return (
      <Link to={navigateTo}>
        <DetailButton {...other} />
      </Link>
    );
  } else {
    return <DetailButton root={root} {...other} />;
  }
};

const TooltipButton = ({ tooltip, isHidden, ...other }) => {
  if (isHidden(other.root)) {
    return null;
  }
  if (Boolean(tooltip)) {
    return (
      <Tooltip title={tooltip} arrow>
        <span>
          <LinkButton {...other} />
        </span>
      </Tooltip>
    );
  } else {
    return <LinkButton {...other} />;
  }
};

TooltipButton.propTypes = {
  isHidden: PropTypes.func,
  tooltip: PropTypes.string,
};

TooltipButton.defaultProps = {
  tooltip: "",
  isHidden: () => false,
};

LinkButton.propTypes = {
  custom_link: PropTypes.any,
  root: PropTypes.any,
  type: PropTypes.string,
  url_key: PropTypes.any,
};

LinkButton.defaultProps = {
  custom_link: null,
  root: null,
  type: "",
  url_key: null,
};

DetailButton.propTypes = {
  onClick: PropTypes.func,
  root: PropTypes.any,
  variant: PropTypes.string,
};

DetailButton.defaultProps = {
  onClick: () => {},
  root: null,
  variant: "text:Proses",
};
export default TooltipButton;
