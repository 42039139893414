import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import Radio from "components/Radio/Radio";
import TimePicker from "components/Picker/TimePicker";
import DatePicker2 from "components/Picker/DatePicker2";
import moment from "moment";
import { createBroadcastSMS, createNotifBroadcast } from "redux/broadcast/action";
import MainStepper from "components/Stepper/MainStepper";
import StepView from "components/Stepper/StepView";
import InputExcel from "components/TextField/InputExcel";
import FileDrop from "components/FileDrop/FileDrop";
import { TransitionGroup } from "react-transition-group";
import FileDropv2 from "components/FileDrop/FileDrop2";
import ExcelTemplate from "assets/broadcast/sms/template-excel-blast.xlsx";
import DeleteIcon from "assets/paper/DeleteIcon";

const radio = [
  {
    label: "Kirim Sekarang",
    value: 1,
  },
  {
    label: "Jadwalkan Nanti",
    value: 0,
  },
];

const multisender = [
  {
    label: "Upload File",
    value: 1,
  },
  {
    label: "Input Nomor Telepon",
    value: 0,
  },
];

const steps = ["Penerima", "Pesan", "Kirim"];

const Dialog = ({ open, onClose, refresh, data, id, setRes }) => {
  const [activeStep, setActiveStep] = useState(0);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    clearErrors,
    watch,
    setError,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      messageBody: "",
      sendDate: null,
      isExcel: 1,
      listPhone: null,
      excel: null,
      phone: null,
      isAutoSend: null,
    },
  });

  const action = useFetchData({
    action: createBroadcastSMS,
    reset: resetForm,
    message: "Broadcast SMS baru berhasil",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });


  const onSubmit = async (data) => {
    if (activeStep < 2) {
      return setActiveStep(activeStep + 1);
    }

    if (typeof data.sendDate == "object") {
      const { day, minutes } = data.sendDate;
      data.sendDate = `${day} ${minutes}:00`;
    }

    const formData = new URLSearchParams({})

    if (data?.isExcel == "1") {
      delete data.listPhone;
    } else {
      if (data.hasOwnProperty("listPhone")) {
        data.listPhone = data.listPhone.map((item) => item.replace("+", ""));
        data.listPhone = JSON.stringify(data.listPhone);
      }
    }

    if (data.autoSend == "0") {
      delete data.sendDate;
    }

    delete data.phone;

    await convertToBase64(data.excel, (base64) => {
      if(base64 != null){
        data.excel = base64
      }
      Object.keys(data).forEach((item) => {
        if (data[item] !== null && data[item] !== undefined) {
          formData.append(item, data[item]);
        }
      });
      action.fetch(formData);
    })
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        if (activeStep > 0) {
          return setActiveStep(activeStep - 1);
        }
        onClose();
        resetForm();
      }}
      title="Buat Campaign Baru"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="sm"
    >
      <Grid container spacing={2}>
        <MainStepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          withAction={false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              mt: 4,
              p: 2,
            }}
          >
            <Grid container spacing={3}>
              <StepView view={0} activeStep={activeStep}>
                <VerticalGrid label={"Multichannel Sender"} md={12}>
                  <MainController
                    {...getProps("isExcel")}
                    rules={{ required: "Channel wajib diisi" }}
                    render={({ field }) => (
                      <Radio
                        version={2}
                        onChange={(e) => {
                          if (e == 1) {
                            setValue("excel", null);
                          } else if (e == 0) {
                            setValue("phone", null);
                            setValue("listPhone", null);
                          }
                          field.onChange(e);
                        }}
                        value={parseInt(field.value)}
                        data={multisender}
                      />
                    )}
                  />

                  <TransitionGroup
                    component={Grid}
                    item
                    xs={12}
                    sx={{
                      paddingTop: "0px !important",
                    }}
                  >
                    {watch("isExcel") == 1 && (
                      <Collapse>
                        <MainController
                          {...getProps("excel")}
                          rules={{ required: "excel wajib diisi" }}
                          desc={
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mt={1}
                            >
                              <span>
                                <a
                                  download="sms-blast.xlsx"
                                  href={ExcelTemplate}
                                  style={{
                                    color: "#0C6CE2",
                                    textDecoration: "none",
                                  }}
                                >
                                  Download
                                </a>
                                {"   "}
                                contoh file .xlsx
                              </span>

                              <span>Supported file types .xls/ .xlsx</span>
                            </Box>
                          }
                          render={({ field }) => (
                            <FileDropv2
                              {...field}
                              customType={[
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "application/vnd.ms-excel",
                                "application/wps-office.xlsx",
                              ]}
                              customSize="50mb"
                              accept=".xlsx, .xls, .csv"
                              setError={setError}
                              onClose={() => setValue("excel", null)}
                            />
                          )}
                        />
                      </Collapse>
                    )}
                  </TransitionGroup>

                  <TransitionGroup
                    component={Grid}
                    item
                    xs={12}
                    sx={{
                      paddingTop: "0px !important",
                    }}
                  >
                    {watch("isExcel") == 0 && (
                      <Collapse>
                        <Grid container spacing={2}>
                          <VerticalGrid label={"Nomor Telepon"} md={12}>
                            <MainController
                              {...getProps("phone")}
                              rules={{ required: "nomor telepon wajib diisi" }}
                              render={({ field }) => (
                                <Box display="flex" gap={1}>
                                  <Box
                                    sx={{
                                      backgroundColor: "#EBECED",
                                      alignItems: "center",
                                      display: "flex",
                                      width: "60px",
                                      borderRadius: "4px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="text2">+62</Typography>
                                  </Box>
                                  <OutlinedInput
                                    {...field}
                                    name="phone"
                                    errors={errors}
                                    type="text"
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        const pattern =
                                          /^(\+62|62|0|8)[1-9][0-9]{6,20}$/g;
                                        if (!pattern.test(e.target.value)) {
                                          return setError("phone", {
                                            type: "validation",
                                            message:
                                              "Format Nomor Telepon Salah",
                                          });
                                        }
                                        clearErrors("phone");
                                        let v = field.value;
                                        if (v.charAt(0) == 8) {
                                          v = `+62${v}`;
                                        }
                                        if (
                                          v.charAt(0) == 0 &&
                                          v.charAt(1) == 8
                                        ) {
                                          v = `+62${v.slice(1)}`;
                                        }
                                        let copy = getValues("listPhone") || [];
                                        copy.push(v);
                                        setValue("listPhone", copy, {
                                          shouldValidate: true,
                                        });
                                        field.onChange("");
                                      }
                                    }}
                                    variant="v1"
                                    placeholder="Placeholder"
                                  />
                                </Box>
                              )}
                            />
                          </VerticalGrid>

                          <VerticalGrid
                            label={watch("listPhone")?.length ? "Penerima" : ""}
                            md={12}
                          >
                            <MainController
                              {...getProps("listPhone")}
                              rules={{ required: "Penerima wajib diisi" }}
                              render={({ field }) => (
                                <TransitionGroup>
                                  {field?.value?.map((item, i) => (
                                    <Collapse key={i}>
                                      <OutlinedInput
                                        name="phone"
                                        errors={errors}
                                        type="text"
                                        placeholder="Placeholder"
                                        disabled
                                        sx={{
                                          my: 1,
                                        }}
                                        variant="v1"
                                        value={item.replace("+62", "+62 ")}
                                        endAdornment={
                                          <IconButton
                                            sx={{
                                              color: "#4C4D4F",
                                              cursor: "pointer",
                                              padding: "5px",
                                            }}
                                            onClick={() => {
                                              const copy = field.value.filter(
                                                (_, ia) => ia !== i
                                              );
                                              if (!copy.length) {
                                                return field.onChange(null);
                                              }
                                              field.onChange(copy);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        }
                                      />
                                    </Collapse>
                                  ))}
                                </TransitionGroup>
                              )}
                            />
                          </VerticalGrid>
                        </Grid>
                      </Collapse>
                    )}
                  </TransitionGroup>
                </VerticalGrid>
              </StepView>
              <StepView view={1} activeStep={activeStep}>
                <VerticalGrid label={"Pesan"} md={12}>
                  <MainController
                    {...getProps("messageBody")}
                    rules={{
                      required: "Pesan Wajib Diisi",
                    }}
                    desc={`Character : ${
                      watch("messageBody")?.length || 0
                    } (${Math.ceil(watch("messageBody")?.length / 160)})`}
                    render={({ field, fieldState: { invalid } }) => (
                      <OutlinedInput
                        {...field}
                        error={invalid}
                        type="text"
                        multiline
                        rows={6}
                        variant="v1"
                        placeholder="Tulis pesan kamu disini"
                      />
                    )}
                  />
                </VerticalGrid>
              </StepView>

              <StepView view={2} activeStep={activeStep}>
                <VerticalGrid label={"Jadwal Pengiriman"} md={12}>
                  <MainController
                    {...getProps("isAutoSend")}
                    rules={{ required: "Jadwal pengiriman wajib diisi" }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Radio
                        {...field}
                        data={radio}
                        onChange={(e) => {
                          field.onChange(e);
                          if (e == 1) {
                            setValue("sendDate", {
                              day: moment().format("YYYY-MM-DD"),
                              minutes: moment().format("HH:mm"),
                            });
                          } else {
                            setValue("sendDate", {
                              day: "",
                              minutes: "",
                            });
                          }
                        }}
                      />
                    )}
                  />
                </VerticalGrid>

                <TransitionGroup component={Grid} item xs={12}>
                  {watch("isAutoSend") == 1 || watch("isAutoSend") == 0 ? (
                    <Collapse>
                      <Grid container spacing={2}>
                        <VerticalGrid label="Pilih Tanggal" md={6}>
                          <MainController
                            {...getProps("sendDate.day")}
                            rules={
                              watch("isAutoSend") == 0
                                ? { required: "Waktu wajib diisi" }
                                : {}
                            }
                            render={({ field, fieldState: { invalid } }) => {
                              return (
                                <DatePicker2
                                  variant="v1"
                                  disabled={watch("isAutoSend") == 1}
                                  {...field}
                                  error={invalid}
                                  placeholder="DD/MM/YYYY"
                                />
                              );
                            }}
                          />
                        </VerticalGrid>

                        <VerticalGrid label="Pilih Waktu" md={6}>
                          <MainController
                            {...getProps("sendDate.minutes")}
                            rules={
                              watch("isAutoSend") == 0
                                ? { required: "Waktu wajib diisi" }
                                : {}
                            }
                            render={({ field }) => {
                              const split = field.value?.split(":") || [];

                              return (
                                <TimePicker
                                  disabled={watch("isAutoSend") == 1}
                                  hours={split[0]}
                                  minutes={split[1]}
                                  onChangeHours={(e) => {
                                    split[0] = e;
                                    field.onChange(split.join(":"));
                                  }}
                                  onChangeMinutes={(e) => {
                                    split[1] = e;
                                    field.onChange(split.join(":"));
                                  }}
                                  variant="v1"
                                />
                              );
                            }}
                          />
                        </VerticalGrid>
                      </Grid>
                    </Collapse>
                  ) : null}
                </TransitionGroup>
              </StepView>
            </Grid>
          </Box>
        </MainStepper>
        {/* <VerticalGrid label={"Judul"} md={12}>
          <MainController
            {...getProps("messageTitle")}
            rules={{
              required: "Judul wajib diisi",
            }}
            desc={`Character : ${watch("messageTitle")?.length || "0"}`}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                error={invalid}
                variant="v1"
                placeholder="Masukan judul"
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Konten"} md={12}>
          <MainController
            {...getProps("messageBody")}
            rules={{
              required: "Koten wajib diisi",
            }}
            desc={`Character : ${watch("messageBody")?.length || "0"}`}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                error={invalid}
                multiline
                variant="v1"
                rows={6}
                placeholder="Masukan konten"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Jadwal Pengiriman"} md={12}>
          <MainController
            {...getProps("isAutoSend")}
            rules={{
              required: "Koten wajib diisi",
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Radio
                {...field}
                data={radio}
                onChange={(e) => {
                  const value = parseInt(e);
                  field.onChange(value);

                  if (value == 1) {
                    console.log(moment().format("YYYY-MM-DD"));
                    setValue("sendDate", {
                      day: moment().format("YYYY-MM-DD"),
                      minutes: moment().format("HH:mm"),
                    });
                  } else {
                    setValue("sendDate", {
                      day: "",
                      minutes: "",
                    });
                  }
                }}
              />
            )}
          />
        </VerticalGrid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <VerticalGrid label="Pilih Tanggal" md={6}>
              <MainController
                {...getProps("sendDate.day")}
                rules={
                  watch("isAutoSend") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field, fieldState: { invalid } }) => {
                  console.log(field.value);
                  return (
                    <DatePicker2
                      variant="v1"
                      disabled={watch("isAutoSend") == 1}
                      {...field}
                      error={invalid}
                      placeholder="DD/MM/YYYY"
                    />
                  );
                }}
              />
            </VerticalGrid>

            <VerticalGrid label="Pilih Waktu" md={6}>
              <MainController
                {...getProps("sendDate.minutes")}
                rules={
                  watch("isAutoSend") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field }) => {
                  const split = field.value?.split(":") || [];

                  return (
                    <TimePicker
                      disabled={watch("isAutoSend") == 1}
                      hours={split[0]}
                      minutes={split[1]}
                      onChangeHours={(e) => {
                        split[0] = e;
                        field.onChange(split.join(":"));
                      }}
                      onChangeMinutes={(e) => {
                        split[1] = e;
                        field.onChange(split.join(":"));
                      }}
                      variant="v1"
                    />
                  );
                }}
              />
            </VerticalGrid>
          </Grid>
        </Grid> */}
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
