import emailSlice from "./Email/slice";
import emailMarketingSlice from "./EmailMarketing/slice";
import pushNotifSlice from "./Notif/slice";
import smsBroadcastSlice from "./SMS/slice";

const broadcastReducer = {
  smsBroadcast: smsBroadcastSlice,
  pushNotifBroadcast: pushNotifSlice,
  emailBroadcast: emailSlice,
  emailMarketingBroadcast: emailMarketingSlice,
};

export default broadcastReducer;
