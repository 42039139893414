import React from "react";

const CommentActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4484_361088)">
        <path d="M3 3H21V16H7L3 20V3Z" fill="#CC1D15" />
        <path
          d="M3 22C2.87 22 2.74 21.98 2.62 21.92C2.25 21.77 2 21.4 2 21V5C2 3.35 3.35 2 5 2H19C20.65 2 22 3.35 22 5V15C22 16.65 20.65 18 19 18H7.41L3.7 21.71C3.51 21.9 3.25 22 2.99 22H3ZM5 4C4.45 4 4 4.45 4 5V18.59L6.29 16.3C6.48 16.11 6.73 16.01 7 16.01H19C19.55 16.01 20 15.56 20 15.01V5C20 4.45 19.55 4 19 4H5Z"
          fill="#CC1D15"
        />
        <path
          d="M15.7101 12.53C16.1001 12.92 16.1001 13.55 15.7101 13.94C15.5101 14.14 15.2601 14.24 15.0001 14.24C14.7401 14.24 14.4901 14.14 14.2901 13.94L12.0001 11.65L9.71006 13.94C9.51006 14.14 9.26006 14.24 9.00006 14.24C8.74006 14.24 8.49006 14.14 8.29006 13.94C7.90006 13.55 7.90006 12.92 8.29006 12.53L10.5901 10.24L8.29006 7.94005C7.90006 7.55005 7.90006 6.92005 8.29006 6.53005C8.68006 6.14005 9.32006 6.14005 9.71006 6.53005L12.0001 8.82005L14.2901 6.53005C14.6801 6.14005 15.3201 6.14005 15.7101 6.53005C16.1001 6.92005 16.1001 7.55005 15.7101 7.94005L13.4201 10.24L15.7101 12.53Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4484_361088">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommentActive;
