import PropTypes from "prop-types";
import { Box, Radio, Typography } from "@mui/material";
import MainController from "components/Controller/MainController";
import React from "react";

const RadioMobile = ({ data, getProps, name, checked, onClick }) => {
  return data.map((item, i) => (
    <MainController
      {...getProps(name)}
      key={i}
      render={({ field }) => (
        <Box
          display="flex"
          justifyContent="space-between"
          onClick={() => onClick(field, item)}
        >
          <Typography variant="text2">{item.label}</Typography>
          <Radio sx={{ p: 0 }} checked={checked(item, field)} />
        </Box>
      )}
    />
  ));
};

RadioMobile.propTypes = {
  data: PropTypes.array,
  getProps: PropTypes.func,
  name: PropTypes.any,
  checked: PropTypes.func,
  onClick: PropTypes.func,
};

RadioMobile.defaultProps = {
  data: [],
  getProps: () => {},
  name: "",
  checked: () => {},
  onClick: () => {},
};

export default RadioMobile;
