import { optPekerjaan } from "../components/variabel";

export const saksi1Data = (data) => {
  return {
    alamat_saksi1: data.alamat_saksi1,
    nama_saksi1: data.nama_saksi1,
    no_nik_saksi1: data.no_nik_saksi1,
    umur_saksi1: data.umur_saksi1,
    pekerjaan_saksi1: data.pekerjaan_saksi1,
    provinsi_saksi1_id: data.provinsi_saksi1_id,
    kota_saksi1_id: data.kota_saksi1_id,
    kecamatan_saksi1_id: data.kecamatan_saksi1_id,
    kelurahan_saksi1_id: data.kelurahan_saksi1_id,
    warding: {
      alamat_saksi1_warding: data.alamat_saksi1_warding,
      nama_saksi1_warding: data.nama_saksi1_warding,
      no_nik_saksi1_warding: data.no_nik_saksi1_warding,
      umur_saksi1_warding: data.umur_saksi1_warding,
      pekerjaan_saksi1_warding: data.pekerjaan_saksi1_warding,
      provinsi_saksi1_warding: data.provinsi_saksi1_warding,
      kota_saksi1_warding: data.kota_saksi1_warding,
      kecamatan_saksi1_warding: data.kecamatan_saksi1_warding,
      kelurahan_saksi1_warding: data.kelurahan_saksi1_warding,
    },
    lainnya: {
      pekerjaan_saksi1_lainnya: data.pekerjaan_saksi1_lainnya,
    },
  };
};

export const Saksi1Form = ({
  provinsi,
  kota,
  kecamatan,
  kelurahan,
  setValue,
  getValues,
  setError,
}) => {
  return [
    {
      label: "Alamat Saksi",
      name: "saksi1.alamat_saksi1",
      warding: "saksi1.warding.alamat_saksi1_warding",
      data: "alamat_saksi1",
      commentKey: "alamat_saksi1_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Alamat saksi wajib diisi",
      },
    },
    {
      label: "Nama Saksi",
      name: "saksi1.nama_saksi1",
      warding: "saksi1.warding.nama_saksi1_warding",
      data: "nama_saksi1",
      commentKey: "nama_saksi1_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama saksi wajib diisi" },
    },
    {
      label: "No. NIK Saksi",
      name: "saksi1.no_nik_saksi1",
      warding: "saksi1.warding.no_nik_saksi1_warding",
      data: "no_nik_saksi1",
      commentKey: "no_nik_saksi1_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. NIK saksi wajib diisi",
        maxLength: {
          message: "NO. NIK saksi tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Umur Saksi",
      name: "saksi1.umur_saksi1",
      warding: "saksi1.warding.umur_saksi1_warding",
      data: "umur_saksi1",
      commentKey: "umur_saksi1_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "Umur saksi wajib diisi",
      },
    },
    {
      label: "Pekerjaan",
      name: "saksi1.pekerjaan_saksi1",
      warding: "saksi1.warding.pekerjaan_saksi1_warding",
      data: "pekerjaan_saksi1",
      commentKey: "pekerjaan_saksi1_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      type: "select",
      options: optPekerjaan,
      lainnyaKey: "saksi1.lainnya.pekerjaan_saksi1_lainnya",
      lainnyaData: "pekerjaan_saksi1_lainnnya",
      lainnya: "",
      rules: { required: "Pekerjaan wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_saksi1_id",
      warding: "saksi1.warding.provinsi_saksi1_warding",
      name: "saksi1.provinsi_saksi1_id",
      value: "",
      comment: "",
      commentKey: "provinsi_saksi1_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.saksi1[5].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi saksi wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "saksi1.kota_saksi1_id",
      warding: "saksi1.warding.kota_saksi1_warding",
      data: "kota_saksi1_id",
      value: "",
      comment: "",
      commentKey: "kota_saksi1_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!getValues("saksi1.provinsi_saksi1_id")) {
          return setError("forms.saksi1[6].value", {
            type: "noid",
            message: "Pilih Provinsi saksi1 Dahulu",
          });
        }
        kota.fetch(getValues("saksi1.provinsi_saksi1_id"));
        setValue("forms.saksi1[6].loading", true);
      },
      loading: true,
      rules: { required: "Kota saksi1 wajib diisi" },
    },
    {
      name: "saksi1.kecamatan_saksi1_id",
      warding: "saksi1.warding.kecamatan_saksi1_warding",
      label: "Kecamatan",
      data: "kecamatan_saksi1_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_saksi1_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("saksi1.kota_saksi1_id"))) {
          return setError("saksi1.kecamatan_saksi1_id[7].value", {
            type: "noid",
            message: "Pilih kota saksi1 Terlebih Dahulu",
          });
        }
        kecamatan.fetch(getValues("saksi1.kota_saksi1_id"));
        setValue("forms.saksi1[7].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan saksi1 wajib diisi" },
    },
    {
      name: "saksi1.kelurahan_saksi1_id",
      warding: "saksi1.warding.kelurahan_saksi1_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_saksi1_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_saksi1_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("saksi1.kecamatan_saksi1_id"))) {
          return setError("forms.saksi1[8].value", {
            type: "noid",
            message: "Pilih Kecamatan saksi1 Terlebih Dahulu",
          });
        }
        kelurahan.fetch(getValues("saksi1.kecamatan_saksi1_id"));
        setValue("forms.saksi1[8].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan saksi1 wajib diisi" },
    },
  ];
};
