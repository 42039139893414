import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import React from "react";

const DrawerAction = ({ onReset, onSubmit }) => {
  return (
    <Box display="flex" gap={1.5} p={2}>
      <Button
        variant="secondary-outlined"
        sx={{
          width: "100%",
        }}
        onClick={onReset}
      >
        Reset
      </Button>
      <Button
        variant="primary"
        sx={{
          width: "100%",
        }}
        onClick={onSubmit}
      >
        Terapkan
      </Button>
    </Box>
  );
};

DrawerAction.propTypes = {
  onReset: PropTypes.any,
  onSubmit: PropTypes.any,
};

DrawerAction.defaultProps = {
  onReset: () => {},
  onSubmit: () => {},
};

export default DrawerAction;
