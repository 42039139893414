import PropTypes from "prop-types";
import { Box } from "@mui/system";
import React, { forwardRef, useEffect, useRef } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

const MainContentEditable = forwardRef(({ onChange, value }, ref) => {
  const syaratRef = useRef();

  useEffect(() => {
    if (document.activeElement !== syaratRef.current) {
      syaratRef.current.innerHTML = value;
    }
  }, [value]);

  const MainBox = (theme) => ({
    fontFamily: "Manrope",
    height: "100%",
    minHeight: "170px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    padding: "18px 18px",
    cursor: "auto",
    width: "100%",
    outline: "none",
    fontWeight: "500",
    fontSize: "14px",
    "&:hover": {
      outline: "1px solid #cc1d15",
    },
    "&:focus": {
      outline: "2px solid #cc1d15",
    },
    "&:empty:before": {
      content: "attr(placeholder)",
      fontFamily: "Manrope",
      fontWeight: "500",
      fontSize: "14px",
      color: "#74788d",
      display: "block",
    },
    "& ul, & ol": {
      paddingLeft: "15px",
      paddingRight: "15px",
      margin: "0px",
      backgroundColor: "transparent !important",
    },
  });

  return (
    <Box position="relative" display="flex" height="100%" ref={ref}>
      <Box
        display={"flex"}
        gap="5px"
        position="absolute"
        marginTop={"8px"}
        sx={{
          top: "5px",
          right: "15px",
          backgroundColor: "#f5f6f8",
        }}
      >
        <FormatListBulletedIcon
          style={{
            color: "#74788d",
            cursor: "pointer",
          }}
          onClick={() => {
            document.execCommand("insertunorderedlist", false);
          }}
        />
        <FormatListNumberedIcon
          style={{
            color: "#74788d",
            cursor: "pointer",
          }}
          onClick={() => {
            document.execCommand("insertorderedlist", false);
          }}
        />
      </Box>
      <Box
        ref={syaratRef}
        spellCheck={false}
        placeholder="Masukan Syarat & Ketentuan"
        contentEditable
        sx={MainBox}
        onInput={(e) => {
          onChange(e.target.innerHTML);
        }}
      ></Box>
    </Box>
  );
});

MainContentEditable.displayName = "mce";
MainContentEditable.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any,
};

MainContentEditable.defaultProps = {
  onChange: () => {},
  value: "",
};

export default MainContentEditable;
