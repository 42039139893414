import {
  Box,
  Button,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import FormError from "components/Alert";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";
import {
  forgotPasswordValidation,
  getIpAddress,
  initialForgotPassword,
} from "redux/auth/actions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import moment from "moment";
import PublicRoute from "components/PublicRoute";
import InisaIlu from "assets/login/new-ilustrasi.png";
import useFetchData from "hook/useFetchData";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const defaultValues = {
  NewPassword: "",
  NewPasswordConfirmation: "",
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [sparams] = useSearchParams();
  const [retry, setRetry] = useState(0);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    conpassword: false,
  });

  if (!sparams.get("key")) {
    navigate("/auth/forgot-password");
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    getIpAddress();

    if (sparams.get("w")) {
      dispatch(
        alert({
          open: true,
          message: "Expired token atau token tidak sesuai",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (retry > 0) {
      const intervalId = setInterval(() => {
        setRetry(retry - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      action.setResponse({
        ...action.response,
        message: "",
      });
    }
  }, [retry]);

  const action = useFetchData({
    action: forgotPasswordValidation,
    message: "Password Berhasil Diubah",
    snackbarError: false,
    onError: (data, { message, code }) => {
      if (message === "Too Many Request" || code == 429) {
        setRetry(data.RetryAfter);
      }
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    action.setResponse({
      ...action.response,
      message: "",
    });
    formData.append("NewPassword", data.NewPassword);
    formData.append("NewPasswordConfirmation", data.NewPasswordConfirmation);
    formData.append("ForgotKey", sparams.get("key"));

    action.fetch(formData);
  };

  const PasswordIcon = useMemo(
    () => (showPassword.password ? VisibilityIcon : VisibilityOffIcon),
    [showPassword.password]
  );

  const ConPasswordIcon = useMemo(
    () => (showPassword.conpassword ? VisibilityIcon : VisibilityOffIcon),
    [showPassword.conpassword]
  );

  return (
    <PublicRoute ilustration={InisaIlu} title="Reset Password">
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          marginTop: "30px",
        }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <FormError
          message={`${action?.response?.message} ${
            retry > 0 ? `| ${moment.utc(retry * 1000).format("mm:ss")}` : ""
          }`}
          open={action?.response?.message ? 0 : 1}
          onClose={() => {
            if (retry) {
              return;
            }
            action.setResponse({
              ...action.response,
              message: "",
            });
          }}
        />
        <Grid container spacing={3}>
          <VerticalGrid label="Kata Sandi" md={12}>
            <MainController
              controller={Controller}
              name="NewPassword"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Kata Sandi Baru wajib diisi",
                minLength: {
                  message: "Kata Sandi Baru tidak boleh kurang dari 6 karakter",
                  value: 6,
                },
              }}
              alertType={1}
              render={({ field, fieldState: { invalid } }) => (
                <OutlinedInput
                  variant="v1"
                  {...field}
                  error={invalid}
                  type={!showPassword.password ? "password" : "text"}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Input your New Password"
                  endAdornment={
                    <IconButton
                      sx={{
                        color: "#4C4D4F",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      <PasswordIcon />
                    </IconButton>
                  }
                />
              )}
            />
          </VerticalGrid>
          <VerticalGrid label="Konfirmasi Kata Sandi" md={12}>
            <MainController
              controller={Controller}
              name="NewPasswordConfirmation"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Konfirmasi Kata Sandi wajib diisi",
                minLength: {
                  message:
                    "Konfirmasi Kata Sandi tidak boleh kurang dari 6 karakter",
                  value: 6,
                },
                validate: (value) =>
                  value === watch("NewPassword") || "Kata Sandi tidak sama",
              }}
              alertType={1}
              render={({ field, fieldState: { invalid } }) => (
                <OutlinedInput
                  variant="v1"
                  {...field}
                  error={invalid}
                  type={!showPassword.conpassword ? "password" : "text"}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Confirm your new password"
                  endAdornment={
                    <IconButton
                      sx={{
                        color: "#4C4D4F",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          conpassword: !showPassword.conpassword,
                        })
                      }
                    >
                      <ConPasswordIcon />
                    </IconButton>
                  }
                />
              )}
            />
          </VerticalGrid>
        </Grid>

        <Button
          variant="primary"
          disabled={Boolean(retry) || action.loading}
          type="submit"
          sx={{
            margin: "10px 0px",
            padding: "13px",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          RESET PASSWORD
        </Button>
      </Box>
    </PublicRoute>
  );
};

export default ForgotPassword;
