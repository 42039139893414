import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import React from "react";

const HorizontalGrid = ({ children, label, center, col }) => {
  return (
    <Grid
      container
      mb={2}
      sx={{
        "&:last-child": {
          marginBottom: "0px !important",
        },
      }}
    >
      <Grid
        item
        xs={12}
        md={col[0]}
        sx={{
          marginTop: center ? "auto" : "10px",
          marginBottom: center ? "auto" : "0px",
        }}
      >
        <Typography variant="label2">{label}</Typography>
      </Grid>

      <Grid item xs={12} md={col[1]}>
        {children}
      </Grid>
    </Grid>
  );
};

HorizontalGrid.propTypes = {
  children: PropTypes.any,
  col: PropTypes.array,
  label: PropTypes.string,
  center: PropTypes.bool,
};

HorizontalGrid.defaultProps = {
  children: null,
  col: [5, 7],
  label: "",
  center: true,
};

export default HorizontalGrid;
