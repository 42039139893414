import { Box, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import Page from "components/Page";
import MainCard from "components/Paper/MainCard";
import BroadcastStatus from "components/Status/BroadcastStatus";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { notifBroadcastDetail } from "redux/broadcast/action";

const NotifBroadcastDetail = () => {
  const { response, fetch, loading } = useFetchData({
    action: notifBroadcastDetail,
    snackbar: false,
  });

  const { id } = useParams();

  useEffect(() => {
    fetch(id);
  }, []);

  const data = useMemo(() => {
    const res = response;
    return [
      {
        label: "Pengiriman",
        value: moment(res?.SendDate).format("DD / MM / YYYY HH:mm"),
      },
      {
        label: "Status",
        value: <BroadcastStatus value={res?.Status} />,
      },
      {
        label: "Dibuat oleh",
        value: res?.CreatedByName,
      },
    ];
  }, [response]);
  return (
    <>
      <Page
        title={`Detail SMS Broadcast`}
        page={["Master Data", "SMS Broadcast"]}
        backBtn
        menu="Detail"
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainCard title="Informasi Pengirim" addButton={false}>
            <MainLoading loading={loading} height={170}>
              {data.map((item, i) => (
                <MainGrid
                  {...item}
                  striped="odd"
                  key={i}
                  center={item.label == "Status"}
                />
              ))}
            </MainLoading>
          </MainCard>

          <Paper sx={{ mt: 2 }}>
            <MainLoading loading={loading} height={170}>
              <Box display="flex" flexDirection="column" gap={2} p={2}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="formTitle">Judul</Typography>
                  <OutlinedInput
                    value={response?.MessageTitle}
                    disabled
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="formTitle">Konten</Typography>
                  <OutlinedInput
                    multiline
                    rows={8}
                    value={response?.MessageBody}
                    disabled
                  />
                </Box>
              </Box>
            </MainLoading>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default NotifBroadcastDetail;
