import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import AlertIcon from "./Icon/AlertIcon";

const DeleteDialog = ({
  open,
  loading,
  onClose,
  title,
  desc,
  name,
  onSubmit,
}) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      customWidth="372px"
      action={false}
      closeButton={false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          pt: 1,
        }}
      >
        <AlertIcon />
        <Typography variant="cardTitle">{title}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="text2">{desc}</Typography>
          <Typography
            variant="text2"
            sx={{ color: "#cc1d15", fontWeight: "bold" }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: 1,
          }}
        >
          <Button
            variant="gray"
            sx={{
              minWidth: "100px",
              height: "50px",
            }}
            onClick={onClose}
          >
            Batal
          </Button>
          <Button
            variant="primary"
            sx={{
              minWidth: "150px",
              height: "50px",
            }}
            onClick={onSubmit}
            disabled={loading}
          >
            Ya, Hapus
          </Button>
        </Box>
      </Box>
    </MainDialog>
  );
};

DeleteDialog.propTypes = {
  desc: PropTypes.any,
  loading: PropTypes.bool,
  name: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};

DeleteDialog.defaultProps = {
  open: false,
  onClose: null,
  onSubmit: null,
  loading: false,
  title: "Yakin Hapus Data",
  desc: "",
  name: "",
};

export default DeleteDialog;
