import React from "react";

const TerdaftarIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5193 5.84455C15.5193 5.84455 15.5193 5.80169 15.5193 5.78332L14.9069 2.72162C14.8518 2.43382 14.6008 2.23175 14.3068 2.23175H3.28474C2.99081 2.23175 2.73976 2.43995 2.68464 2.72162L2.07231 5.78332C2.07231 5.78332 2.07231 5.82618 2.07231 5.84455C2.07231 5.86292 2.06006 5.88129 2.06006 5.90579V6.8243C2.06006 7.67545 2.56218 8.40413 3.28474 8.75317V15.0909C3.28474 15.4277 3.56029 15.7032 3.89708 15.7032H13.6945C14.0313 15.7032 14.3068 15.4277 14.3068 15.0909V8.75317C15.0294 8.41026 15.5315 7.67545 15.5315 6.8243V5.90579C15.5315 5.90579 15.5193 5.86905 15.5193 5.84455ZM9.40813 14.4785V11.4168H10.6328V14.4785H9.40813ZM11.8575 14.4785V10.8045C11.8575 10.4677 11.5819 10.1922 11.2452 10.1922H8.79579C8.45901 10.1922 8.18345 10.4677 8.18345 10.8045V14.4785H4.50942V8.93687C4.98704 8.86951 5.41568 8.64907 5.7341 8.3184C6.12599 8.71643 6.66485 8.96748 7.26494 8.96748C7.86504 8.96748 8.4039 8.71643 8.79579 8.3184C9.18769 8.71643 9.72655 8.96748 10.3266 8.96748C10.9267 8.96748 11.4656 8.71643 11.8575 8.3184C12.1759 8.64907 12.6045 8.86951 13.0822 8.93687V14.4785H11.8575ZM13.3883 7.74281C12.8801 7.74281 12.4698 7.33254 12.4698 6.8243C12.4698 6.48751 12.1943 6.21196 11.8575 6.21196C11.5207 6.21196 11.2452 6.48751 11.2452 6.8243C11.2452 7.33254 10.8349 7.74281 10.3266 7.74281C9.8184 7.74281 9.40813 7.33254 9.40813 6.8243C9.40813 6.48751 9.13258 6.21196 8.79579 6.21196C8.45901 6.21196 8.18345 6.48751 8.18345 6.8243C8.18345 7.33254 7.77319 7.74281 7.26494 7.74281C6.7567 7.74281 6.34644 7.33254 6.34644 6.8243C6.34644 6.48751 6.07088 6.21196 5.7341 6.21196C5.39731 6.21196 5.12176 6.48751 5.12176 6.8243C5.12176 7.33254 4.71149 7.74281 4.20325 7.74281C3.69501 7.74281 3.28474 7.33254 3.28474 6.8243V5.96702L3.78686 3.45643H13.8047L14.3068 5.96702V6.8243C14.3068 7.33254 13.8966 7.74281 13.3883 7.74281Z"
        fill="white"
      />
    </svg>
  );
};

export default TerdaftarIcon;
