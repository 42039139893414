import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  List,
  Loading,
  Params,
  TotalItems,
  CurrentPage,
  FilterName,
} from "redux/sentraLokasi/Lokasi/selectors";

import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/sentraLokasi/Lokasi/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import Import from "./Import";
import {
  deleteLokasi,
  getKategori,
  getListKategori,
  getListLokasi,
  getLokasi,
} from "redux/sentraLokasi/action";
import moment from "moment";
import MainMap from "components/Map/MainMap";
import DeleteDialog from "components/Dialog/DeleteDialog";

const KategoriLokasi = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);
  const dispatch = useDispatch();
  const id = useRef(null);

  const [open, setOpen] = useState({
    open: false,
    import: false,
    delete: false,
  });

  const getLanguage = (param) => {
    param = param.toLowerCase();
    switch (param) {
      case "id":
        return "Indonesia";

      case "en":
        return "Inggris";

      default:
        return "-";
    }
  };

  const headers = [
    {
      name: "Nama Lokasi",
      key: "name",
      width: 30,
    },
    {
      name: "Kategori Lokasi",
      key: "kategori",
      width: 30,
    },
    {
      name: "Bahasa",
      custom: (item) => {
        return getLanguage(item?.bahasa ?? "-");
      },
      align: "center",
      width: 20,
    },
    {
      name: "Status",
      custom: (item) => {
        return <AktifStatus value={item?.is_active} />;
      },
      width: 10,
    },
    {
      name: "Tanggal Status",
      custom: (item) => {
        return item?.created_at
          ? moment(item?.created_at).format("DD / MM / YYYY")
          : "-";
      },
      width: 20,
    },
  ];

  const filter = [
    {
      label: "Nama Lokasi",
      value: "name",
    },
    {
      label: "Kategori Lokasi",
      value: "kategori",
    },
  ];

  const filterData = [
    {
      label: "Status",
      key: "is_active",
      type: "switch",
    },
    {
      label: "Bahasa",
      key: "bahasa",
      type: "switch",
      options: [
        {
          label: "Indonesia",
          value: "id",
        },
        {
          label: "Inggris",
          value: "en",
        },
      ],
    },
  ];

  const fetchData = async () => {
    await dispatch(getListLokasi(params));
  };

  const exportData = useFetchData({
    action: getLokasi,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "lokasi",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const deleteData = useFetchData({
    action: deleteLokasi,
    message: "Berhasil Hapus Data",
    refresh: fetchData,
    onSuccess: () => {
      setOpen({
        ...open,
        delete: false,
      });
    },
  });


  return (
    <>
      <Providers
        title="Sentra Lokasi"
        currentPage={currentPage}
        params={params}
        get={fetchData}
      >
        <MainCard
          sx={{
            mt: 2,
            borderRadius: "8px",
          }}
          title="Daftar Lokasi"
          onClick={() =>
            setOpen({
              ...open,
              open: true,
            })
          }
        >
          <MainCardHeader
            filter={filter}
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            title="Filter Lokasi"
            onChangeExport={(type) => handleExport(type)}
          />

          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="name"
            detail={[
              {
                type: "link",
                url_key: "master-data/sentra-lokasi/lokasi:id",
                tooltip: "Lihat Detail Lokasi",
                variant: "icon:eye",
              },
              {
                type: "button",
                tooltip: "Lihat Hapus Kategori",
                variant: "icon:delete",
                onClick: (_, item) => {
                  id.current = item.id;
                  setOpen({
                    ...open,
                    delete: true,
                  });
                },
              },
            ]}
            increment={params?.offset + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        refresh={fetchData}
        isEdit={false}
      />

      <Import
        open={open.import}
        onClose={() =>
          setOpen({
            ...open,
            import: false,
          })
        }
        refresh={fetchData}
        isEdit={false}
      />

      <DeleteDialog
        open={open.delete}
        onClose={() => setOpen({ ...open, delete: false })}
        onSubmit={() => deleteData.fetch(id.current)}
      />
    </>
  );
};

export default KategoriLokasi;
