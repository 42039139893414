import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  detailBansosPenerima,
  getDetailBansosVoucherSubsidi,
} from "redux/penerimaBantuan/action";
import FotoId from "assets/penerimaBantuan/foto-id.png";
import FotoWajah from "assets/penerimaBantuan/foto-wajah.png";
import { useDispatch, useSelector } from "react-redux";
import TabList from "./TabList";
import {
  subsidi,
  voucher,
  load,
  params,
} from "redux/penerimaBantuan/detail/selectors";
import SearchIcon from "@mui/icons-material/Search";
import { changeFilter } from "redux/penerimaBantuan/detail/slice";

const DetailPenerimaBantuan = () => {
  const Voucher = useSelector(voucher);
  const Subsidi = useSelector(subsidi);
  const Load = useSelector(load);
  const Params = useSelector(params);

  const [informasiData, setInformasiData] = useState([]);
  const id = useParams().id;
  const dispatch = useDispatch();
  const { fetch, loading, response } = useFetchData({
    action: detailBansosPenerima,
    snackbar: false,
  });

  useEffect(() => {
    fetch(id);
  }, []);

  useEffect(() => {
    const io = () => {
      fetchData();
    };
    io();
  }, [Params.search]);

  const fetchData = async () => {
    await dispatch(getDetailBansosVoucherSubsidi({ id, params: Params }));
  };

  useEffect(() => {
    if (response) {
      setInformasiData([]);
      const i = response.data_diri;
      let inf = [];
      let test = {
        Nama: {
          name: i.nama,
        },
        NIK: {
          name: i.nik,
        },
        "No. Telepon": {
          name: i.no_tlp,
        },
        Email: {
          name: i.email,
        },
        "Tgl. Pendaftaran": {
          name: i.tanggal_pendaftaran,
          format: "date",
        },
        "Tgl. Verifikasi": {
          name: i.tanggal_verifikasi,
          format: "date",
        },
        Status: {
          name: i.status_verif,
          format: "status",
        },
      };

      for (const key in test) {
        inf.push({
          name: key,
          value: renderValue(test[key], key),
        });
      }

      setInformasiData(inf);
    }
  }, [response]);

  const renderValue = (data, key) => {
    if (data.format === "date") {
      return moment(data.name).format("YYYY-MM-DD - h:mm:ss") || "-";
    } else if (data.format === "status") {
      return <AktifStatus value={data.name} />;
    } else {
      return data.name || "-";
    }
  };

  return (
    <>
      <PageTitle
        title={`Detail Penerima Bantuan`}
        text="Dasbor / CSR Bansos / Penerima Bantuan / "
        lastText="Detail Penerima Bantuan"
      />
      <Grid container spacing={2}>
        <Grid xs={12} md={7} item>
          <MainCard
            title="Data Diri"
            addButton={false}
            isEdit={false}
            sx={{
              height: "97.7%",
            }}
          >
            <MainLoading loading={loading} height={"100%"}>
              {informasiData.map((item, i) => (
                <MainGrid
                  key={i}
                  label={item.name}
                  value={item.value}
                  labelMd={3}
                  valueMd={7}
                  striped="odd"
                  sx={{
                    height: "calc(100%/8)",
                  }}
                  center={true}
                />
              ))}
            </MainLoading>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <MainCard title="Kartu ID" addButton={false} isEdit={false}>
            <MainLoading loading={loading}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: "20px",
                }}
                src={FotoId}
              />
            </MainLoading>
          </MainCard>
          <MainCard title="Foto Wajah" addButton={false} isEdit={false}>
            <MainLoading loading={loading}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: "20px",
                }}
                src={FotoWajah}
              />
            </MainLoading>
          </MainCard>
        </Grid>
        <Grid xs={12} item>
          <MainCard
            title="Program Bantuan"
            addButton={false}
            isEdit={false}
            custom={
              <TextField
                placeholder="Search..."
                onChange={(e) => {
                  dispatch(changeFilter(e.target.value));
                }}
                value={Params?.search || ""}
                InputProps={{
                  sx: {
                    outline: "1px solid #D0D0D0",
                    backgroundColor: "#fff !important",
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ marginRight: "20px" }}
                    >
                      <SearchIcon
                        fontSize="medium"
                        sx={{
                          color: "#4E4751",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            }
          >
            <TabList voucher={Voucher} subsidi={Subsidi} loading={Load} />
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailPenerimaBantuan;
