import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useState, useMemo } from "react";
import { statusList } from "./variables";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import {
  createPengguna,
  updatePengguna,
} from "redux/managementPengguna/action";
import { Grid, OutlinedInput } from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import FormatNumber from "helper/FormatNumber";
import Autocomplete from "components/AutoComplete/Autocomplete";

const defaultValues = {
  Email: "",
  NIK: "",
  ClientId: 3,
  Fullname: "",
  Phone: "",
  Source: "web",
  Status: null,
  Password: "",
  PasswordConfirmation: "",
};

const Dialog = ({ open, onClose, refresh, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    conPassword: false,
  });

  const PasswordIcon = useMemo(
    () => (showPassword.password ? RemoveRedEyeIcon : VisibilityOffIcon),
    [showPassword.password]
  );

  const ConfPasswordIcon = useMemo(
    () => (showPassword.conPassword ? RemoveRedEyeIcon : VisibilityOffIcon),
    [showPassword.conPassword]
  );

  const action = useFetchData({
    action: data?.id ? updatePengguna : createPengguna,
    reset: resetForm,
    message: data?.id
      ? "Berhasil Update Pengguna"
      : "Berhasil menambah pengguna baru",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
    onError: (data) => {
      Object.keys(data).forEach((item) => {
        setError(item, {
          type: "error",
          message: data[item][0],
        });
      });
    },
  });

  const onSubmit = async (submitData) => {
    const formData = new FormData();

    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }

    await action.fetch(formData, data?.id);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={!data?.id ? "Tambah Pengguna Baru" : "Ubah Data Pengguna"}
      loading={action.loading}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText={!data?.id ? "Tambah" : "Simpan Perubahan"}
      onReset={() => {
        resetForm();
      }}
      type={1}
      customWidth="700px"
    >
      <Grid container spacing={3}>
        <VerticalGrid label={"Nama Lengkap"} md={12}>
          <MainController
            {...getProps("Fullname")}
            rules={{ required: "Fullname wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                variant="v1"
                {...field}
                error={invalid}
                placeholder="Masukkan nama lengkap"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"NIK"}>
          <MainController
            {...getProps("NIK")}
            rules={{ required: "NIK wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                variant="v1"
                error={invalid}
                placeholder="Masukan NIK"
                onChange={(e) => {
                  e.target.value = FormatNumber(e.target.value);
                  field.onChange(e);
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Email"}>
          <MainController
            {...getProps("Email")}
            rules={{
              required: "Email wajib diisi",
              maxLength: {
                message: "Email tidak boleh melebihi 255 karakter",
                value: 255,
              },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Format Email salah",
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                variant="v1"
                error={invalid}
                placeholder="Masukan alamat email"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"No. HP"}>
          <MainController
            {...getProps("Phone")}
            rules={{
              required: "No. HP wajib diisi",
              maxLength: {
                message: "No. HP tidak boleh melebihi 20 karakter",
                value: 20,
              },
              minLength: {
                message: "No. HP tidak boleh kurang dari 8 karakter",
                value: 8,
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                error={invalid}
                variant="v1"
                placeholder="Masukan No. HP"
                onChange={(e) => {
                  e.target.value = FormatNumber(e.target.value);
                  field.onChange(e);
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Status"}>
          <MainController
            {...getProps("Status")}
            rules={{ required: "Status wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocomplete
                variant="v1"
                {...field}
                options={statusList}
                error={invalid}
                value={
                  statusList.find((item) => item.value === field.value) || null
                }
                placeholder="Masukan status barang"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_, nv) => {
                  if (nv == null) {
                    field.onChange(1);
                  }
                  field.onChange(nv.value);
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Kata Sandi"}>
          <MainController
            {...getProps("Password")}
            rules={{
              required: "Password wajib diisi",
              minLength: {
                message: "Password tidak boleh kurang dari 6 karakter",
                value: 6,
              },
            }}
            desc="Password must be at least 8 character"
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                variant="v1"
                type={showPassword.password ? "text" : "password"}
                endAdornment={
                  <PasswordIcon
                    sx={{
                      color: "#969696",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword?.password,
                      })
                    }
                  />
                }
                error={invalid}
                placeholder="Masukan kata sandi"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Konfirmasi Kata Sandi"}>
          <MainController
            {...getProps("PasswordConfirmation")}
            rules={{
              required: "Confirm Password wajib diisi",
              minLength: {
                message: "Confirm Password tidak boleh kurang dari 6 karakter",
                value: 6,
              },
              validate: (value) =>
                value === getValues("Password") || "Password Tidak sama",
            }}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                variant="v1"
                type={showPassword.conPassword ? "text" : "password"}
                error={invalid}
                endAdornment={
                  <ConfPasswordIcon
                    sx={{
                      color: "#969696",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        conPassword: !showPassword?.conPassword,
                      })
                    }
                  />
                }
                placeholder="Ketik ulang kata sandi"
              />
            )}
          />
        </VerticalGrid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
