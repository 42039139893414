import BannerSlice from "./Banner/slice";
import DokumenSlice from "./Dokumen/slice";
import FormulirSlice from "./Formulir/slice";

const CMSReducer = {
  CMSBanner: BannerSlice,
  CMSDokumen: DokumenSlice,
  CMSFormulir: FormulirSlice,
};

export default CMSReducer;
