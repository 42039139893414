import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: true,
  alert: {
    open: false,
    message: "",
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    open: (state, action) => {
      state.sidebar = !action.payload ? !state.sidebar : action.payload;
    },
    alert: (state, action) => {
      state.alert = {
        open: action.payload.open,
        message: action.payload.message,
      };
    },
  },
});

export const { open, alert } = globalSlice.actions;

export default globalSlice.reducer;
