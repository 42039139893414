import {
  Box,
  Button,
  OutlinedInput,
  Paper,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/paper/SearchIcon";
import MobileBadge from "components/Mobile/Badge";
import React, { useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AlertIcon from "components/Mobile/Icon/AlertIcon";
import { useDispatch } from "react-redux";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import AccordionMobile from "components/Mobile/AccordionMobile";
import CardMobile from "components/Mobile/CardMobile";
import { getPenerimaBansos } from "redux/subsidiBansos/action";
import NIKStatus from "components/Status/NIKStatus";
import { useParams } from "react-router-dom";
import PageSM from "components/Page/PageSM";

const penerimaOption = [
  {
    id: 0,
    label: "Semua",
    color: "#4E4751",
  },
  {
    id: 1,
    label: "Terverifikasi",
    backgroundColor: "#E7F4ED",
    color: "#129350",
  },
  {
    id: 2,
    label: "Tidak Terverifikasi",
    backgroundColor: "#F1E5FE",
    color: "#7300F7",
  },
  {
    id: 3,
    label: "Tidak Ditemukan",
    backgroundColor: "#EDECED",
    color: "#4E4751",
  },
  {
    id: 4,
    label: "Tidak Sama",
    backgroundColor: "#FEF7E5",
    color: "#F7B500",
  },
];

const headers = [
  {
    name: "Nama Penerima",
    key: "nama",
    width: 30,
  },
  {
    name: "NIK/NIP/ID Lain",
    key: "no_identitas",
    width: 50,
  },
  {
    name: "Status",
    custom: (item) => {
      return <NIKStatus arr={penerimaOption} value={item?.status_verif} />;
    },
    width: 20,
  },
];

const SubsidiBansosPenerimaMobile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.subsidiBansosPenerima);

  const fetchData = async () => {
    await dispatch(
      getPenerimaBansos({
        params,
        id,
      })
    );
  };

  useAutoFetch(fetchData, currentPage, filterName);

  const [dialog, setDialog] = useState({
    alert: true,
    filter: false,
    detail: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDialog({ ...dialog, [anchor]: open });
  };

  return (
    <>
      <Paper>
        <Box sx={{ px: 2, pt: 2 }}>
          <PageSM
            title={`Detail Penerima`}
            backBtn
            to="/csr-bansos/program-bantuan/kartu-subsidi"
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <AccordionMobile
            list={list}
            headers={headers}
            label="Nama Penerima"
            value="nama"
            action={false}
          />
        </Box>
      </Paper>

      <SwipeableDrawer
        anchor="bottom"
        open={dialog.alert}
        onClose={toggleDrawer("alert", false)}
        onOpen={toggleDrawer("alert", true)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2} pb={3}>
          <Close
            sx={{
              marginLeft: "auto",
              display: "flex",
            }}
            onClick={toggleDrawer("alert", false)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AlertIcon />
          </Box>

          <Box textAlign="center" display="flex" gap={2} flexDirection="column">
            <Typography variant="title5">
              Anda Tidak dapat membuat dan memperbarui data di perangkat ini
            </Typography>
            <Typography>
              Untuk membuat dan memperbarui data harap gunakan perangkat lain
              (tablet atau desktop)
            </Typography>
          </Box>

          <Button variant="primary" onClick={toggleDrawer("alert", false)}>
            Mengerti
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default SubsidiBansosPenerimaMobile;
