import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import useFetchData from "hook/useFetchData";
import { Box, OutlinedInput } from "@mui/material";
import MainController from "components/Controller/MainController";
import FormatNumber from "helper/FormatNumber";
import FormatRupiah from "helper/FormatRupiah";
import HorizontalGrid from "components/Grid/HorizontalGrid";
import { editHargaProduk, newHargaProduk } from "redux/hargaProdukPPOB/action";
import Autocomplete from "components/AutoComplete/Autocomplete";

export const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

const defaultValues = {
  kodeProduk: "",
  namaProduk: "",
  hargaJual: "",
  hargaBiller: "",
  status: 1,
};

const Dialog = ({ open, onClose, type, refresh, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });

  const action = useFetchData({
    action: !data?.id ? newHargaProduk : editHargaProduk,
    reset: resetForm,
    message: !data?.id
      ? "Berhasil menambah harga produk baru"
      : "berhasil update data harga produk",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const text = useMemo(() => {
    if (type == 1 || type == "PPOBWND") {
      return "WND";
    }
    if (type == 2 || type == "PPOBOY") {
      return "OY";
    }
    if (type == 3 || type == "PPOB") {
      return "Raja Biller";
    }
  }, [type]);

  const onSubmit = async (submitData) => {
    const formData = new FormData();
    Object.keys(submitData).forEach((item) => {
      formData.append(item, submitData[item]);
    });
    formData.append("group", "PANGANAN");

    if (!data?.id) {
      formData.append("type", type - 1);
    }

    await action.fetch(formData, data?.id);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  useEffect(() => {
    if (data != null) {
      const copy = { ...data };
      delete copy.id;
      resetForm(copy);
    } else {
      resetForm(defaultValues);
    }
  }, [data]);

  useEffect(() => {
    if (data?.id && type != "PPOBWND") {
      const copyData = { ...data };
      delete copyData.id;
      resetForm(copyData);
    }
    if (data?.id && type == "PPOBWND") {
      const copyData = { ...data };
      delete copyData.id;

      resetForm({
        status: data.status,
      });
    }
  }, [data]);

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={
        data?.id ? `Edit Harga Produk ${text || ""}` : `Tambah Harga Produk ${text || ""}`
      }
      loading={action.loading}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText={data?.id ? "Simpan perubahan" : `Tambah`}
      type={2}
      customWidth="500px"
    >
      <Box display="flex" flexDirection="column" gap={1}>
        {type != "PPOBWND" ? (
          <>
            <HorizontalGrid label={"Kode Produk"}>
              <MainController
                {...getProps("kodeProduk")}
                rules={{
                  required: "Kode Produk wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan kode produk"
                  />
                )}
              />
            </HorizontalGrid>

            <HorizontalGrid label={"Nama Produk"}>
              <MainController
                {...getProps("namaProduk")}
                rules={{
                  required: "Nama Produk wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    placeholder="Masukan nama produk"
                  />
                )}
              />
            </HorizontalGrid>

            <HorizontalGrid label={"Harga Biller"}>
              <MainController
                {...getProps("hargaBiller")}
                rules={{
                  required: "Harga Biller wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    onChange={(e) => {
                      e.target.value = FormatNumber(e.target.value);
                      field.onChange(e.target.value);

                      const hargaJual =
                        Number(e.target.value) * 0.01 +
                        500 +
                        Number(e.target.value);

                      setValue("hargaJual", Math.round(hargaJual));
                    }}
                    placeholder="Masukan Harga biller"
                    value={FormatRupiah(field.value)}
                  />
                )}
              />
            </HorizontalGrid>

            <HorizontalGrid label={"Harga Jual"}>
              <MainController
                {...getProps("hargaJual")}
                rules={{
                  required: "Harga Jual wajib diisi",
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <OutlinedInput
                    {...field}
                    error={invalid}
                    disabled
                    placeholder="Masukan Harga jual"
                    value={FormatRupiah(field.value)}
                  />
                )}
              />
            </HorizontalGrid>
          </>
        ) : null}

        <HorizontalGrid label={"Status"}>
          <MainController
            {...getProps("status")}
            rules={{
              required: "Status wajib diisi",
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocomplete
                {...field}
                options={statusList}
                value={
                  statusList.find((item) => item.value == field.value) || null
                }
                error={invalid}
                isOptionEqualToValue={(option, value) =>
                  option.value == value.value
                }
                onChange={(_, nv) => {
                  if (nv == null) {
                    field.onChange(null);
                  }
                  field.onChange(nv.value);
                }}
              />
            )}
          />
        </HorizontalGrid>
      </Box>
    </MainDialog>
  );
};

Dialog.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.any,
  post: PropTypes.any,
  refresh: PropTypes.any,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  update: PropTypes.any,
};

Dialog.defaultProps = {
  data: null,
  onClose: () => {},
  open: false,
  post: () => {},
  refresh: () => {},
  type: null,
  update: () => {},
};

export default Dialog;
