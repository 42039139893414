import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
const InputComponent = ({ item, children }) => {
  return (
    <Box display="flex" gap={1} width="100%" flexDirection="column">
      <Typography
        variant="gridLabel"
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {!item?.property?.label?.value
          ? item?.name.includes("Kolom")
            ? "Judul " + item?.name
            : "Judul Kolom " + item?.name
          : item?.property?.label?.value}

        {item?.tampilan?.wajib_diisi && <font color="red">*</font>}
      </Typography>
      {children}
      {item?.tampilan?.deskripsi && (
        <Typography variant="desc6">
          {item?.property?.deskripsi?.value || "Caption (penjelasan isi field)"}
        </Typography>
      )}
    </Box>
  );
};

export default InputComponent;

InputComponent.propTypes = {
  item: PropTypes.any,
  children: PropTypes.any,
};

InputComponent.defaultProps = {
  item: {},
  children: null,
};
