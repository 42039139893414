import React from "react";

const Email = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 11.5C4.5 7.36 7.86 4 12 4C16.14 4 19.5 7.36 19.5 11.5V12.5725C19.5 14.05 18.3525 15.25 16.875 15.25C15.9825 15.25 15.1425 14.815 14.655 14.1475C13.98 14.83 13.035 15.25 12 15.25C9.93 15.25 8.25 13.57 8.25 11.5C8.25 9.43 9.93 7.75 12 7.75C14.07 7.75 15.75 9.43 15.75 11.5V12.5725C15.75 13.165 16.2825 13.75 16.875 13.75C17.4675 13.75 18 13.165 18 12.5725V11.5C18 8.245 15.255 5.5 12 5.5C8.745 5.5 6 8.245 6 11.5C6 14.755 8.745 17.5 12 17.5H15.75V19H12C7.86 19 4.5 15.64 4.5 11.5ZM9.75 11.5C9.75 12.745 10.755 13.75 12 13.75C13.245 13.75 14.25 12.745 14.25 11.5C14.25 10.255 13.245 9.25 12 9.25C10.755 9.25 9.75 10.255 9.75 11.5Z"
        fill="black"
      />
      <mask
        id="mask0_6780_20633"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="16"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 11.5C4.5 7.36 7.86 4 12 4C16.14 4 19.5 7.36 19.5 11.5V12.5725C19.5 14.05 18.3525 15.25 16.875 15.25C15.9825 15.25 15.1425 14.815 14.655 14.1475C13.98 14.83 13.035 15.25 12 15.25C9.93 15.25 8.25 13.57 8.25 11.5C8.25 9.43 9.93 7.75 12 7.75C14.07 7.75 15.75 9.43 15.75 11.5V12.5725C15.75 13.165 16.2825 13.75 16.875 13.75C17.4675 13.75 18 13.165 18 12.5725V11.5C18 8.245 15.255 5.5 12 5.5C8.745 5.5 6 8.245 6 11.5C6 14.755 8.745 17.5 12 17.5H15.75V19H12C7.86 19 4.5 15.64 4.5 11.5ZM9.75 11.5C9.75 12.745 10.755 13.75 12 13.75C13.245 13.75 14.25 12.745 14.25 11.5C14.25 10.255 13.245 9.25 12 9.25C10.755 9.25 9.75 10.255 9.75 11.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_20633)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default Email;
