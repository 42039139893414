import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { changePassword, updateProfile } from "redux/profile/action";
import useFetchData from "hook/useFetchData";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SidebarProfile = ({ page, setPage, openRight, setOpenRight, logout }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
    clearErrors,
    watch,
  } = useForm();

  const [showPassword, setShowPassword] = useState({
    currpassword: false,
    password: false,
    conpassword: false,
  });

  const CurrPasswordIcon = useMemo(
    () => (showPassword.currpassword ? VisibilityIcon : VisibilityOffIcon),
    [showPassword.currpassword]
  );

  const PasswordIcon = useMemo(
    () => (showPassword.password ? VisibilityIcon : VisibilityOffIcon),
    [showPassword.password]
  );

  const ConPasswordIcon = useMemo(
    () => (showPassword.conpassword ? VisibilityIcon : VisibilityOffIcon),
    [showPassword.conpassword]
  );

  const updateData = useFetchData({
    action: page.userProfile ? updateProfile : changePassword,
    message: page.userProfile
      ? "Berhasil update profile"
      : "Berhasil update password",
    onSuccess: () => {
      window.location.reload();
    },
  });

  const {
    photo,
    profile: {
      Fullname,
      role,
      Email,
      Phone,
      NIKConfirmed,
      PhoneConfirmed,
      EmailConfirmed,
      Status,
      Id,
    },
  } = useSelector((state) => state.profile);

  useEffect(() => {
    if (page.userProfile) {
      resetForm({
        Fullname,
        Email,
        Phone,
        NIKConfirmed,
        PhoneConfirmed,
        EmailConfirmed,
        Status,
      });
    }
    if (page.changePassword) {
      resetForm({
        OldPassword: "",
        NewPassword: "",
        PasswordConfirmation: "",
      });
    }
  }, [page]);

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });
    updateData.fetch(formData, Id);
  };

  return (
    <Drawer
      anchor={"right"}
      open={openRight}
      onClose={() => setOpenRight(false)}
      PaperProps={{
        sx: {
          width: {
            md: "300px",
            xs: "100%",
          },
          zIndex: "200",
        },
      }}
    >
      {page.profile && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "#FAE8E8",
              //   py: 6,
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                p: 2,
                display: {
                  xs: "flex",
                  md: "none",
                },
                alignItems: "center",
              }}
              gap={1}
              onClick={() => {
                setOpenRight(false);
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="label2">Profile</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                py: 5,
              }}
            >
              <Avatar
                src={photo}
                sx={{ margin: "auto", width: 75, height: 75 }}
              />
              <Typography
                variant="cardTitle3"
                sx={{
                  textAlign: "center",
                }}
              >
                {Fullname}
              </Typography>
              <Typography
                variant="text3"
                sx={{
                  textAlign: "center",
                }}
              >
                {Email}
              </Typography>
            </Box>
          </Box>

          <Box m={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPage({
                  profile: false,
                  userProfile: true,
                  changePassword: false,
                });
              }}
            >
              <Typography variant="label2">User Profile</Typography>
              <IconButton sx={{ p: 0 }}>
                <ChevronRight />
              </IconButton>
            </Box>
            <Divider sx={{ my: 1 }} />

            <Box
              display="flex"
              justifyContent="space-between"
              mt={3}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPage({
                  profile: false,
                  userProfile: false,
                  changePassword: true,
                });
              }}
            >
              <Typography variant="label2">Change Password</Typography>
              <IconButton sx={{ p: 0 }}>
                <ChevronRight />
              </IconButton>
            </Box>
            <Divider sx={{ my: 1 }} />
          </Box>

          <Button
            variant="secondary-outlined"
            type="button"
            sx={{ margin: "auto 15px 50px 15px", padding: "15px" }}
            onClick={logout}
          >
            Logout
          </Button>
        </>
      )}

      {page.userProfile && (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box display="flex" flexDirection="column" py={3} px={2}>
            <Box
              display="flex"
              sx={{
                cursor: "pointer",
              }}
              gap={2}
              onClick={() => {
                setPage({
                  profile: true,
                  userProfile: false,
                  changePassword: false,
                });
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="label2">User Profile</Typography>
            </Box>
            <Avatar
              src={photo}
              sx={{ margin: "auto", my: 4, width: 75, height: 75 }}
            />

            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">Name</Typography>
                <MainController
                  {...getProps("Fullname")}
                  rules={{
                    required: "Name wajib diisi",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput variant="v1" error={invalid} {...field} />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">Email</Typography>
                <MainController
                  {...getProps("Email")}
                  rules={{
                    required: "Email wajib diisi",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput variant="v1" error={invalid} {...field} />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">Phone Number</Typography>
                <MainController
                  {...getProps("Phone")}
                  rules={{
                    required: "Phone wajib diisi",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput variant="v1" error={invalid} {...field} />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">Role</Typography>
                <OutlinedInput variant="v1" disabled value={role} />
              </Box>
            </Box>
          </Box>

          <Button
            variant="primary"
            type="submit"
            sx={{
              width: "90%",
              display: "flex",
              padding: "15px",
              mx: "auto",
              mb: 3,
            }}
          >
            Save Change
          </Button>
        </Box>
      )}

      {page.changePassword && (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box display="flex" flexDirection="column" py={3} px={2}>
            <Box
              display="flex"
              sx={{
                cursor: "pointer",
              }}
              gap={2}
              onClick={() => {
                setPage({
                  profile: true,
                  userProfile: false,
                  changePassword: false,
                });
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="label2">Change Password</Typography>
            </Box>

            <Box display="flex" flexDirection="column" gap={2} mt={4}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">Current Password</Typography>
                <MainController
                  {...getProps("OldPassword")}
                  rules={{
                    required: "Current Password wajib diisi",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput
                      type={showPassword.currpassword ? "text" : "password"}
                      variant="v1"
                      error={invalid}
                      {...field}
                      endAdornment={
                        <IconButton
                          sx={{
                            color: "#4C4D4F",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              currpassword: !showPassword.currpassword,
                            })
                          }
                        >
                          <CurrPasswordIcon />
                        </IconButton>
                      }
                    />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">New Password</Typography>
                <MainController
                  {...getProps("NewPassword")}
                  rules={{
                    required: "New Password wajib diisi",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput
                      variant="v1"
                      type={showPassword.password ? "text" : "password"}
                      error={invalid}
                      {...field}
                      endAdornment={
                        <IconButton
                          sx={{
                            color: "#4C4D4F",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              password: !showPassword.password,
                            })
                          }
                        >
                          <PasswordIcon />
                        </IconButton>
                      }
                    />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="text2">Confirm New Password</Typography>
                <MainController
                  {...getProps("PasswordConfirmation")}
                  rules={{
                    required: "Password Confirmation wajib diisi",
                    validate: (value) =>
                      value == watch("NewPassword") || "Kata Sandi tidak sama",
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <OutlinedInput
                      variant="v1"
                      type={showPassword.conpassword ? "text" : "password"}
                      error={invalid}
                      {...field}
                      endAdornment={
                        <IconButton
                          sx={{
                            color: "#4C4D4F",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              conpassword: !showPassword.conpassword,
                            })
                          }
                        >
                          <ConPasswordIcon />
                        </IconButton>
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1} mb={3}>
            <Button
              variant="primary"
              type="submit"
              sx={{
                width: "90%",
                display: "flex",
                padding: "15px",
                mx: "auto",
              }}
            >
              Confirm
            </Button>
            <Button
              variant="gray"
              type="button"
              sx={{
                width: "90%",
                display: "flex",
                padding: "15px",
                mx: "auto",
              }}
              onClick={() => {
                setPage({
                  profile: true,
                  userProfile: false,
                  changePassword: false,
                });
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

SidebarProfile.propTypes = {
  logout: PropTypes.func,
  openRight: PropTypes.bool,
  page: PropTypes.object,
  setOpenRight: PropTypes.func,
  setPage: PropTypes.func,
};

SidebarProfile.defaultProps = {
  logout: () => {},
  openRight: false,
  setOpenRight: () => {},
  page: {},
  setPage: () => {},
};

export default SidebarProfile;
