const { createTheme } = require("@mui/material");

const theme = createTheme({
  typography: {
    fontFamily: `"Manrope", sans-serif`,
  },
  shadows: [
    "none",
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    "0px 1px 2px rgba(100, 116, 139, 0.12)",
    "0px 1px 4px rgba(100, 116, 139, 0.12)",
    "0px 1px 5px rgba(100, 116, 139, 0.12)",
    "0px 1px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 6px rgba(100, 116, 139, 0.12)",
    "0px 3px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    "0px 5px 12px rgba(100, 116, 139, 0.12)",
    "0px 5px .875rem rgba(100, 116, 139, 0.12)",
    "0px 5px 15px rgba(100, 116, 139, 0.12)",
    "0px 6px 15px rgba(100, 116, 139, 0.12)",
    "0px 7px 15px rgba(100, 116, 139, 0.12)",
    "0px 8px 15px rgba(100, 116, 139, 0.12)",
    "0px 9px 15px rgba(100, 116, 139, 0.12)",
    "0px 10px 15px rgba(100, 116, 139, 0.12)",
    "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px .875rem 24px -8px rgba(100, 116, 139, 0.25)",
    "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

theme.components.MuiButton = {
  styleOverrides: {
    root: {
      fontFamily: `"Manrope", sans-serif`,
      textTransform: "none",
      fontSize: ".875rem",
      minWidth: "100px",
    },
  },
  variants: [
    {
      props: { variant: "primary" },
      style: {
        backgroundColor: "#cc1d15",
        color: "#fff",
        padding: "10px 20px",
        "&:hover": {
          backgroundColor: "#B20600",
        },
        "&.Mui-disabled": {
          pointerEvents: "auto",
          backgroundColor: "#EBA5A1",
          color: "#fff",
          cursor: "not-allowed",
        },
      },
    },
    {
      props: { variant: "primary-icon" },
      style: {
        backgroundColor: "#cc1d15",
        padding: "8px",
        minWidth: "0px",
        minHeight: "0px",
        display: "inline-flex",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#B20600",
        },
        "&.Mui-disabled": {
          pointerEvents: "auto",
          backgroundColor: "#EBA5A1",
          color: "#fff",
          cursor: "not-allowed",
        },
      },
    },
    {
      props: { variant: "secondary-outlined" },
      style: {
        padding: "10px 20px",
        background: "#fff",
        color: "#354053",
        border: "1px solid #354053",
        "&:hover": {
          background: "#354053",
          color: "#fff",
        },
        "&.Mui-disabled": {
          pointerEvents: "auto",
          color: "#354053",
          cursor: "not-allowed",
          "&:hover": {
            color: "#fff",
          },
        },
      },
    },
    {
      props: { variant: "secondary-outlined-icon" },
      style: {
        backgroundColor: "#fff",
        padding: "8px",
        minWidth: "0px",
        display: "inline-flex",
        color: "#4c4d4f",
        border: "1px solid",
        borderColor: "#4c4d4f",
        "&:hover": {
          boxShadow: "0 2px 4px 0 rgba(17, 17, 17, 0.5)",
          backgroundColor: "#4c4d4f",
          color: "#fff",
        },
      },
    },
    {
      props: { variant: "primary-outlined" },
      style: {
        border: "1px solid #cc1d15",
        backgroundColor: "#fff",
        color: "#cc1d15",
        padding: "13px 13px",
        fontWeight: "700",
        fontSize: ".875rem",
        "&:hover": {
          backgroundColor: "#cc1d15",
          color: "#fff",
        },
        "&.Mui-disabled": {
          pointerEvents: "auto",
          backgroundColor: "#EBA5A1",
          color: "#fff",
          cursor: "not-allowed",
        },
      },
    },
    {
      props: { variant: "warning" },
      style: {
        backgroundColor: "#FBB12F",
        color: "#fff",
        minWidth: "50px",
        padding: "0px",
        "&:hover": {
          background: "#ecb14b",
          color: "#fff",
        },
      },
    },
    {
      props: { variant: "primary-dotted" },
      style: {
        backgroundColor: "#f5f6f8",
        color: "#92b0b3",
        minWidth: "80px",
        padding: "10px 20px",
        border: "1px dotted #92b0b3",
        "&:hover": {
          background: "#fff",
        },
      },
    },
    {
      props: { variant: "gray" },
      style: {
        backgroundColor: "#EBECED",
        color: "#202021",
        minWidth: "54px",
        padding: "10px 15px",
        display: "flex",
        gap: "10px",
        fontWeight: "700",
      },
    },
    {
      props: { variant: "gray-outlined" },
      style: {
        backgroundColor: "#fff",
        minWidth: "54px",
        padding: "5px 15px",
        display: "flex",
        gap: "10px",
        border: "1px solid #878889",
        color: "#878889",
      },
    },
    {
      props: { variant: "gray-icon" },
      style: {
        backgroundColor: "#fff",
        border: "1px solid #adadae",
        color: "#adadae",
        minWidth: "0px",
        padding: "6px",
        display: "flex",
        gap: "10px",
      },
    },
    {
      props: { variant: "white" },
      style: {
        backgroundColor: "#fff",
        color: "#111111",
        minWidth: "68px",
        padding: "0px",
        border: "1px solid #74788D",
        borderRadius: "8px",
      },
    },
  ],
};

// theme.components.MuiTextField = {
//   styleOverrides: {
//     root: {
//       width: "100%",
//       "& .MuiOutlinedInput-root": {
//         backgroundColor: "#f8f8f8",
//         borderRadius: "4px",
//         fontWeight: "500",
//         "& fieldset": {
//           border: "none",
//         },
//         "&:hover": {
//           outline: "1px solid #cc1d15",
//         },
//         height: "46px",
//         "&.Mui-disabled": {
//           cursor: "not-allowed",
//           backgroundColor: "#EDECED",
//           color: "#111 !important",
//         },
//         "&.Mui-error": {
//           outline: "2px solid #cc1d15 !important",
//         },
//         "&.Mui-focused": {
//           outline: "2px solid #cc1d15",
//         },
//       },
//       "& .MuiInputBase-multiline": {
//         height: "100% !important",
//       },
//       "& .MuiOutlinedInput-input": {
//         boxSizing: "border-box",
//         "&::-webkit-input-placeholder": {
//           opacity: "1",
//           color: "#878889",
//         },
//         "&.Mui-disabled": {
//           "&::placeholder": {
//             WebkitTextFillColor: "#b0b7c3 !important",
//           },
//           WebkitTextFillColor: "#111 !important",
//         },
//         fontSize: ".875rem",
//       },
//     },
//   },
// };

theme.components.MuiOutlinedInput = {
  styleOverrides: {
    root: {
      backgroundColor: "#f8f8f8",
      borderRadius: "4px",
      fontWeight: "500",
      width: "100%",
      // height: "100%",
      "& fieldset": {
        border: "none",
      },
      "&:hover": {
        outline: "1px solid #cc1d15",
      },
      minHeight: "46px",
      "&.Mui-disabled": {
        cursor: "not-allowed",
        backgroundColor: "#EDECED",
        color: "#111 !important",
      },
      "&.Mui-error": {
        outline: "2px solid #cc1d15 !important",
      },
      "&.Mui-focused": {
        outline: "2px solid #cc1d15",
      },
      "& .MuiInputBase-multiline": {
        height: "100% !important",
      },
      "& .MuiOutlinedInput-input": {
        boxSizing: "border-box",
        "&::-webkit-input-placeholder": {
          opacity: "1",
          color: "#878889",
        },
        "&.Mui-disabled": {
          "&::placeholder": {
            WebkitTextFillColor: "#b0b7c3 !important",
          },
          WebkitTextFillColor: "#111 !important",
        },
        fontSize: ".875rem",
      },
    },
  },
  variants: [
    {
      props: { variant: "none" },
      style: {
        backgroundColor: "transparent",
        minHeight: "38px !important",
        "&:hover": {
          outline: "1px solid transparent",
        },
        "&.Mui-focused": {
          outline: "2px solid transparent",
        },
        "& .MuiOutlinedInput-input": {
          cursor: "default",
        },
        "& .IconEndAdornment": {
          display: "none",
        },
        // "&.Mui-focused": {
        //   backgroundColor: "#fff",
        // },
      },
    },
    {
      props: { variant: "v1" },
      style: {
        backgroundColor: "#EBECED",
        "&.Mui-focused": {
          backgroundColor: "#fff",
        },
      },
    },
    {
      props: { variant: "v2" },
      style: {
        backgroundColor: "#F8F8F8",
        outline: "1px solid #4E4751",
        "&.Mui-focused": {
          backgroundColor: "#fff",
        },
      },
    },
    {
      props: { variant: "v3" },
      style: {
        backgroundColor: "#fff",
        outline: "1px solid #74788D",
        "&.Mui-focused": {
          backgroundColor: "#fff",
        },
        minHeight: "38px !important",
        borderRadius: "8px",
      },
    },
    {
      props: { variant: "v4" },
      style: {
        backgroundColor: "transparent",
        outline: "#fff",
        fontWeight: "700",
        "&.Mui-focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          outlineColor: "#fff",
        },
        "&.Mui-focused": {
          outlineColor: "#fff",
        },
        "& .MuiOutlinedInput-input": {
          "&::-webkit-input-placeholder": {
            opacity: "1",
            color: "#A7A5A6",
          },
          fontSize: "20px",
        },
        borderRadius: "8px",
      },
    },
    {
      props: { variant: "v5" },
      style: {
        backgroundColor: "transparent",
        outline: "#fff",
        "&.Mui-focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          outlineColor: "#fff",
        },
        "&.Mui-focused": {
          outlineColor: "#fff",
        },
        "& .MuiOutlinedInput-input": {
          "&::-webkit-input-placeholder": {
            opacity: "1",
            color: "#A7A5A6",
          },
          fontSize: "14px",
        },
        borderRadius: "8px",
      },
    },
    {
      props: { variant: "v6" },
      style: {
        backgroundColor: "#F0F4FD",
        outline: "1px solid #d4d7dc",
        borderRadius: "20px",
        minHeight: "35px !important",
        width: "100%",
        "&.Mui-focused": {
          backgroundColor: "#fff",
        },
      },
    },
  ],
};

theme.components.MuiAutocomplete = {
  styleOverrides: {
    input: {
      padding: "0px !important",
      paddingLeft: "5px !important",
      "&:focus .MuiInputBase-root": {
        outline: "1px solid #cc1d15",
      },
    },
    root: {
      width: "100%",
    },
    popper: {
      boxShadow: "none !important",
      borderRadius: "1px",
    },
    option: {
      fontSize: ".875rem",
      fontWeight: "600",
      padding: "15px 10px !important",
      color: "#202021",
      borderBottom: "1px solid #f7f7f7",
      position: "relative",
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    noOptions: {
      fontWeight: "600",
      fontSize: ".875rem",
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    loading: {
      fontWeight: "600",
      fontSize: ".875rem",
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    paper: {
      "*::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "*::-webkit-scrollbar-track": {
        borderRadius: "2px",
      },
      "*::-webkit-scrollbar-thumb": {
        background: "rgba(95, 95, 95, 0.3)",
        borderRadius: "2px",
      },
      "& .MuiPaper-root": {
        fontSize: ".875rem",
      },
      ul: {
        padding: "0px 0px !important",
      },
      ".MuiAutocomplete-option[aria-selected='true']": {
        "&:after": {
          position: "absolute",
          content: '""',
          left: "1px",
          top: "10%",
          backgroundColor: "#cc1d15",
          width: "2px",
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
          height: "80%",
        },
        // borderLeft: "2px solid #cc1d15 !important",
        backgroundColor: "#fce7e6 !important",
      },
      ".Mui-focused": {
        backgroundColor: "#fce7e6",
      },
      borderRadius: "8px",
    },
  },
};

theme.components.MuiTableHead = {
  styleOverrides: {
    root: {
      ".MuiTableCell-head": {
        fontWeight: "700",
        padding: "0px 15px",
        height: "50px",
        borderBottom: "none",
        backgroundColor: "#D8D8D8",
        color: "#202021",
        fontSize: ".875rem",
        // [theme.breakpoints.up("xl")]: {
        //   fontSize: "16px",
        // },
      },
    },
  },
};

theme.components.MuiTableBody = {
  styleOverrides: {
    root: {
      ".MuiTableCell-body": {
        fontWeight: "500",
        padding: "0px 15px",
        height: "66px",
        borderBottom: "none",
        color: "#202021",
        lineHeight: "22px",
        fontSize: ".875rem",
      },
      ".MuiTableRow-root": {
        "&:nth-of-type(even)": {
          backgroundColor: "#F6F6F6",
        },
      },
    },
  },
};

theme.components.MuiPaginationItem = {
  styleOverrides: {
    root: {
      fontWeight: "600",
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
      "&.Mui-selected": {
        backgroundColor: "#cc1d15",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#B20600",
        },
      },
    },
  },
};

theme.components.MuiChip = {
  styleOverrides: {
    root: {
      width: "90%",
      fontSize: "inherit",
    },
  },
};

theme.components.MuiDialog = {
  styleOverrides: {
    root: {
      "& .MuiDialogContent-root": {
        padding: theme.spacing(3),
      },
      "& .MuiDialogTitle-root": {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(2.1),
        paddingRight: theme.spacing(3),
      },
      "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
          marginTop: "30px !important",
          marginBottom: "30px !important",
          // width: "100%",
          boxShadow: theme.shadows[3],
          // maxWidth: "570px !important",
          borderRadius: "6px",
        },
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
      },
    },
  },
};

theme.components.MuiTooltip = {
  styleOverrides: {
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      color: "#fff",
      fontSize: "12px",
      minWidth: "137px",
      textAlign: "center",
    },
    arrow: {
      color: "rgba(0, 0, 0, 0.85)",
    },
  },
};

theme.components.MuiAlert = {
  styleOverrides: {
    root: {
      backgroundColor: "#F9E8E7",
      boxShadow: "none",
      padding: "5px 15px",
      margin: "10px 0px",
      ".MuiAlert-message": {
        fontWeight: "500",
        fontSize: ".875rem",
        color: "#cc1d15",
      },
      ".MuiAlert-action": {
        padding: "0px",
        marginTop: "auto",
        marginBottom: "auto",
        svg: {
          fontSize: "18px",
        },
      },
      ".MuiAlert-icon": {
        alignItems: "center",
      },
    },
  },
  variants: [
    {
      props: { variant: "warning" },
      style: {
        backgroundColor: "#fef3d6",
        padding: "5px 15px",
        margin: "10px 0px",
        // textAlign: "center",
        display: "flex",
        ".MuiAlert-message": {
          fontWeight: "500",
          fontSize: ".875rem",
          color: "#363738",
        },
        ".MuiAlert-action": {
          padding: "0px",
          marginTop: "auto",
          marginBottom: "auto",
        },
      },
    },
    {
      props: { variant: "primary" },
      style: {
        backgroundColor: "#FFFAE6",
        padding: "5px 15px",
        margin: "10px 0px",
        // textAlign: "center",
        color: "#cc1d15",
        display: "flex",
        ".MuiAlert-message": {
          fontWeight: "700",
          fontSize: ".875rem",
          // margin: "auto",
        },
        ".MuiAlert-action": {
          padding: "0px",
          marginTop: "auto",
          marginBottom: "auto",
        },
      },
    },
  ],
};

theme.components.MuiTabs = {
  styleOverrides: {
    root: {
      padding: "20px 20px 0px 20px",
      fontFamily: "Manrope",
      ".Mui-selected": {
        color: "#202021",
      },
    },
    indicator: {
      backgroundColor: "#cc1d15",
    },
  },
};

theme.components.MuiTab = {
  styleOverrides: {
    root: {
      fontFamily: "Manrope",
      fontSize: ".875rem",
      color: "#4C4D4f",
      opacity: "1 !important",
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    // selected: {
    // },
  },
};

theme.components.MuiAccordion = {
  styleOverrides: {
    root: {
      // marginTop: "10px",
      boxShadow: "none",
      borderRadius: "4px",
      borderColor: "#fff",
      "&:before": {
        display: "none",
      },
      ".MuiAccordionSummary-root": {
        background: "#fff",
      },
      ".MuiCollapse-root": {
        background: "#fff",
      },
    },
  },
};

theme.components.MuiPaper = {
  styleOverrides: {
    root: {
      boxShadow: "none",
      border: "thin solid #E5E7EB",
    },
  },
};

theme.components.MuiCheckbox = {
  styleOverrides: {
    root: {
      color: "#cc1d15",
      "&.Mui-checked": {
        color: "#cc1d15",
      },
    },
  },
};

theme.components.MuiRadio = {
  styleOverrides: {
    root: {
      color: "#EBECED",
      input: {
        backgroundColor: "#EBECED",
      },
      "&.Mui-checked": {
        color: "#cc1d15",
      },
    },
  },
};

theme.components.MuiDrawer = {
  styleOverrides: {
    paperAnchorBottom: {
      minHeight: "200px",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      pb: 4,
    },
  },
};

theme.typography.h1 = {
  fontWeight: 800,
  color: "#111111",
  fontSize: "30px",
  // [theme.breakpoints.up("xl")]: {
  //   fontSize: "32px",
  // },
};

theme.typography.h2 = {
  fontWeight: 800,
  color: "#231F20",
  fontSize: "18px",
  // [theme.breakpoints.up("xl")]: {
  //   fontSize: "20px",
  // },
};

theme.typography.button = {
  fontWeight: 600,
  color: "#FFFFFF",
  fontSize: ".875rem",
  // [theme.breakpoints.up("xl")]: {
  //   fontSize: "16px",
  // },
};

theme.typography.loginTitle = {
  fontWeight: 700,
  whiteSpace: "nowrap",
  color: "#3F4144",
  lineHeight: "40px",
  fontSize: "28px",
  fontFamily: `'Quicksand', sans-serif`,
};

theme.typography.loginFormTitle = {
  fontWeight: "800",
  color: "#111111",
  lineHeight: 1.5,
  fontSize: "30px",
  textAlign: "left",
};

theme.typography.loginFormLabel = {
  fontWeight: "600",
  whiteSpace: "nowrap",
  color: "#111111",
  fontSize: ".875rem",
  marginBottom: "10px",
};

theme.typography.textCommon = {
  fontWeight: "600",
  whiteSpace: "nowrap",
  color: "#111111",
  fontSize: ".875rem",
};

theme.typography.alertTitle = {
  fontWeight: "700",
  color: "#3F4144",
  fontSize: ".875rem",
};

theme.typography.alertDesc = {
  fontWeight: "400",
  whiteSpace: "nowrap",
  color: "#3F4144",
  fontSize: ".875rem",
};

theme.typography.sidebarTitle = {
  fontWeight: 700,
  whiteSpace: "nowrap",
  color: "#3F4144",
  fontFamily: `'Quicksand', sans-serif`,
  lineHeight: 1.5,
  fontSize: "22px",
};

theme.typography.sidebarItem = {
  fontWeight: 700,
  whiteSpace: "nowrap",
  lineHeight: 1.5,
  fontSize: ".875rem",
};

theme.typography.pageTitle = {
  fontWeight: "800",
  whiteSpace: "nowrap",
  color: "#231F20",
  fontSize: "32px",
};

theme.typography.textMedium = {
  fontWeight: "500",
  whiteSpace: "nowrap",
  color: "#111111",
  fontSize: ".875rem",
};

theme.typography.textMediumV2 = {
  fontWeight: "500",
  whiteSpace: "nowrap",
  textAlign: "right",
  color: "#3F4144",
  fontSize: "12px",
};

theme.typography.textSelect = {
  color: "#666666",
  fontWeight: "500",
  fontSize: ".875rem",
};

theme.typography.label = {
  color: "#111",
  fontWeight: "600",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.desc = {
  color: "#231F20",
  fontWeight: "500",
  fontSize: "12px",
  textTransform: "capitalize",
  whiteSpace: "pre-line",
};

theme.typography.inputDesc = {
  color: "#74788D",
  fontWeight: "500",
  fontSize: "12px",
  whiteSpace: "pre-line",
};

theme.typography.gridLabel = {
  fontWeight: "700",
  whiteSpace: "nowrap",
  color: "#231F20",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.gridValue = {
  fontWeight: "500",
  color: "#111111",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.cardText2 = {
  fontWeight: "500",
  color: "#363738",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.cardText = {
  fontWeight: "500",
  color: "#111111",
  fontSize: "16px",
  whiteSpace: "pre-line",
};

theme.typography.cardTitle = {
  fontWeight: "700",
  color: "#231F20",
  fontSize: "18px",
  whiteSpace: "pre-line",
};

theme.typography.cardTitle4 = {
  fontWeight: "800",
  color: "#231F20",
  fontSize: "21px",
  whiteSpace: "pre-line",
};

theme.typography.cardTitle2 = {
  fontWeight: "700",
  color: "#111111",
  fontSize: "16px",
  whiteSpace: "pre-line",
};

theme.typography.cardTitle3 = {
  fontWeight: "700",
  color: "#202021",
  fontSize: "16px",
  whiteSpace: "pre-line",
};

theme.typography.cardDesc = {
  fontWeight: "500",
  whiteSpace: "pre-line",
  color: "#74788D",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.cardDesc2 = {
  fontWeight: "500",
  whiteSpace: "pre-line",
  color: "#333333",
  fontSize: "1rem",
  whiteSpace: "pre-line",
};

theme.typography.formTitle = {
  fontWeight: "700",
  whiteSpace: "pre-line",
  color: "#111",
  marginBottom: "10px !important",
  fontSize: "16px",
  whiteSpace: "pre-line",
};

theme.typography.label2 = {
  color: "#202021",
  fontWeight: "700",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.labelMedium2 = {
  color: "#202021",
  fontWeight: "600",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.label3 = {
  color: "#4C4D4F",
  fontWeight: "700",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.title2 = {
  color: "#202021",
  fontWeight: "700",
  fontSize: "18px",
  whiteSpace: "pre-line",
};

theme.typography.title3 = {
  color: "#202021",
  fontWeight: "700",
  fontSize: "30px",
  whiteSpace: "pre-line",
};

theme.typography.title4 = {
  color: "#202021",
  fontWeight: "700",
  fontSize: "20px",
  whiteSpace: "pre-line",
};

theme.typography.title5 = {
  color: "#202021",
  fontWeight: "700",
  fontSize: "18px",
  whiteSpace: "pre-line",
};

theme.typography.text2 = {
  color: "#202021",
  fontWeight: "500",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.text3 = {
  color: "#878889",
  fontWeight: "500",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.text4 = {
  color: "#4C4D4F",
  fontWeight: "500",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.text4 = {
  color: "#4C4D4F",
  fontWeight: "500",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};
theme.typography.text5 = {
  color: "#202021",
  fontWeight: "500",
  fontSize: "16px",
  whiteSpace: "pre-line",
};

theme.typography.text6 = {
  color: "#2A2A2B",
  fontWeight: "500",
  fontSize: ".875rem",
  whiteSpace: "pre-line",
};

theme.typography.text7 = {
  color: "#757575",
  fontWeight: "500",
  fontSize: "14px",
};

theme.typography.desc3 = {
  color: "#202021",
  fontWeight: "500",
  fontSize: "12px",
  whiteSpace: "pre-line",
};

theme.typography.desc2 = {
  color: "#4C4D4F",
  fontWeight: "500",
  fontSize: "12px",
};

theme.typography.desc4 = {
  color: "#4C4D4F",
  fontWeight: "500",
  fontSize: "11px",
};

theme.typography.desc5 = {
  color: "#363738",
  fontWeight: "500",
  fontSize: "12px",
};

theme.typography.desc6 = {
  color: "#a7a5a6",
  fontWeight: "500",
  fontSize: "12px",
};

theme.typography.ikonBoxText = {
  fontWeight: "700",
  whiteSpace: "pre-line",
  color: "#969696",
  fontSize: ".875rem",
};

export default theme;
