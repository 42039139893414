import MasterDataIcon from "assets/layout/sidebar/MasterDataIcon";

const permisPenerbit = [
  "Web:Voucher:All",
  "Web:Voucher:All",
  "Web:Voucher:All",
];

const MasterData = {
  id: "master-data",
  type: "collapse",
  title: "Master Data",
  icon: MasterDataIcon,
  permission: permisPenerbit,
  children: [
    {
      id: "master-bantuan",
      title: "Master Bantuan",
      type: "collapse",
      permission: permisPenerbit,
      children: [
        {
          id: "penerbit",
          title: "Penerbit",
          type: "item",
          url: "/master-data/master-bantuan/penerbit",
          permission: permisPenerbit[0],
        },
        {
          id: "kartu-subsidi",
          title: "Kartu Subsidi",
          type: "item",
          url: "/master-data/master-bantuan/kartu-subsidi",
          permission: permisPenerbit[1],
        },
        {
          id: "voucer",
          title: "Voucer",
          type: "item",
          url: "/master-data/master-bantuan/voucer",
          permission: permisPenerbit[2],
        },
      ],
    },
    {
      id: "sentra-lokasi",
      title: "Sentra Lokasi",
      type: "collapse",
      permission: permisPenerbit,
      children: [
        {
          id: "kategori-lokasi",
          title: "Kategori",
          type: "item",
          url: "/master-data/sentra-lokasi/kategori",
          permission: permisPenerbit,
        },
        {
          id: "lokasi",
          title: "Lokasi",
          type: "item",
          url: "/master-data/sentra-lokasi/lokasi",
          permission: permisPenerbit,
        },
      ],
    },
    {
      id: "cms",
      title: "CMS",
      type: "collapse",
      permission: permisPenerbit,
      children: [
        {
          id: "formulir",
          title: "Formulir",
          type: "item",
          url: "master-data/cms/formulir",
          permission: permisPenerbit,
        },
        {
          id: "kelola-dokumen",
          title: "Kelola Dokumen",
          type: "item",
          url: "master-data/cms/kelola-dokumen",
          permission: permisPenerbit,
        },
        { 
          id: "kelola-banner",
          title: "Kelolar Banner",
          type: "item",
          url: "master-data/cms/kelola-banner",
          permission: permisPenerbit,
        },
      ],
    },
    {
      id: "harga-produk-ppob",
      title: "Harga Produk PPOB",
      type: "item",
      url: "/master-data/harga-produk-ppob",
      permission: permisPenerbit,
    },
  ],
};

export default MasterData;
