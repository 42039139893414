import React from "react";
import Providers from "components/RouteRoleProviders";
import CMSDokumen from "pages/CMS/Dokumen";

const cmsDokumenRoutes = [
  {
    path: "master-data/cms/kelola-dokumen",
    element: (
      <Providers permission="Web:Voucher:All">
        <CMSDokumen />
      </Providers>
    ),
  },
];

export default cmsDokumenRoutes;
