import ManagementMerchantIcon from "assets/layout/sidebar/ManagementMerchantIcon";
const permissionList = "Web:User:Get";

const ManagementMerchant = {
  id: "management-merchant",
  type: "collapse",
  title: "Management Merchant",
  icon: ManagementMerchantIcon,
  permission: permissionList,
  children: [
    {
      id: "merchant",
      title: "Merchant",
      type: "item",
      url: "/management-merchant/merchant",
      permission: permissionList,
    },
  ],
};

export default ManagementMerchant;
