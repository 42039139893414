import PropTypes from "prop-types";
import React from "react";
import { Chip, Stack, styled } from "@mui/material";
import { Close } from "@mui/icons-material";

const StyledChip = styled(Chip)(({ theme, height, isactive, width, tipe }) => ({
  cursor: "pointer",
  color: isactive ? "#cc1d15" : "#111",
  border: "1px solid",
  height: height ? height : "40px",
  width: width ? width : "120px",
  background: tipe ? "#fff" : "#e6e6e6",
  borderColor: tipe ? "#DEDEDE" : isactive ? "#cc1d15" : "#e5e5e5",
  fontSize: "14px !important",
  fontWeight: "600",
}));

const PilihStatus = ({
  data,
  onChange,
  isactive,
  getLabel,
  wrap,
  scroll,
  sx,
  onDelete,
  ...props
}) => {
  return (
    <Stack
      direction="row"
      // spacing={1}
      flexWrap={wrap ? "wrap" : ""}
      overflow={scroll ? "auto" : ""}
      sx={{
        gap: 1,
        ...sx,
      }}
    >
      {data?.map((item, index) => {
        return (
          <StyledChip
            onClick={() => onChange(item.value)}
            key={index}
            label={getLabel(item)}
            isactive={isactive(item)}
            sx={item?.sx}
            onDelete={onDelete ? () => onDelete(item, index) : null}
            deleteIcon={
              <Close
                sx={{
                  color: "#111 !important",
                  fontSize: "12px !important",
                }}
              />
            }
            {...props}
          />
        );
      })}
    </Stack>
  );
};

PilihStatus.propTypes = {
  data: PropTypes.any,
  getLabel: PropTypes.func,
  isactive: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  scroll: PropTypes.bool,
  wrap: PropTypes.bool,
  sx: PropTypes.object,
};

PilihStatus.defaultProps = {
  data: [
    {
      label: "Aktif",
      value: 1,
    },
    {
      label: "Tidak Aktif",
      value: 0,
    },
  ],
  getLabel: (item) => item.label,
  isactive: () => {},
  onChange: () => {},
  onDelete: null,
  scroll: false,
  sx: {},
  wrap: false,
};

export default PilihStatus;
