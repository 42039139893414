import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";
import Cancel from "assets/kependudukan/updateStatus/Cancel";
import Check from "assets/kependudukan/updateStatus/Check";
import React from "react";
import moment from "moment";

const CheckUpdateStatus = ({
  value,
  index,
  open,
  onCheck,
  onCancel,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      {moment(value, "YYYY-MM-DD HH:mm:ss").isValid()
        ? moment(value).format("DD/MM/YYYY HH:mm")
        : "-"}
      {open && (
        <Box display="flex" gap={0.5}>
          <IconButton
            onClick={() => onCheck(index, props)}
            sx={{
              p: 0,
            }}
          >
            <Check />
          </IconButton>
          <IconButton
            sx={{
              p: 0,
            }}
            onClick={() => onCancel(index, props)}
          >
            <Cancel />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

CheckUpdateStatus.propTypes = {
  open: PropTypes.any,
  onCheck: PropTypes.func,
  onCancel: PropTypes.func,
  index: PropTypes.number,
  value: PropTypes.string,
};

CheckUpdateStatus.defaultProps = {
  open: false,
  onCheck: () => {},
  onCancel: () => {},
  index: null,
  value: "",
};

export default CheckUpdateStatus;
