import { createSlice } from "@reduxjs/toolkit";
import getRole from "helper/getRole";
import { pagination } from "redux/pagination";
import { getListKK } from "../action";
const { Id: user_id } = JSON.parse(localStorage.getItem("profile-ntt")) || "";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
    filter: {
      type: "m_kk",
    },
    user_id,
    role: getRole()?.isSuperVisor
      ? "supervisor"
      : getRole()?.isOperator
      ? "operator"
      : "",
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const KKSlice = createSlice({
  name: "kependudukanKK",
  initialState,
  reducers: pagination,
  extraReducers: {
    [getListKK.pending]: (state) => {
      state.loading = true;
    },
    [getListKK.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListKK.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = KKSlice.actions;

export default KKSlice.reducer;
