import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomNavLink from "../NavLink";
import RoleProviders from "components/RoleProviders";

const NavItem = ({ item, level, openSidebar, setOpen }) => {
  const theme = useTheme();

  const inXS = useMediaQuery(theme.breakpoints.down("sm"));

  const itemIcon = item?.icon ? (
    <item.icon />
  ) : (
    <FiberManualRecordIcon
      className="dot"
      sx={{
        fontSize: "12px",
        color: "inherit",
        ...{
          ".Mui-selected": {
            outline: "2px solid #ECDCDC",
            borderRadius: "100%",
            WebkitBorderRadius: "100%",
            MozBorderRadius: "100%",
          },
        },
      }}
    />
  );

  const popoverAnchor = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <RoleProviders permission={item.permission}>
      <ListItemButton
        component={CustomNavLink}
        to={item.url}
        end={item.id == "dasbor"}
        {...(inXS && {
          onClick: setOpen,
        })}
        sx={{
          borderRadius: `4px`,
          mb: 0.5,
          alignItems: "flex-start",
          py: level > 1 ? 1.7 : 2,
          pl: `${openSidebar ? (level == 2 ? level * 16 : level * 20) : 16}px`,
          color: "#3F4144",
          ...(level === 1
            ? {
                "&.Mui-selected": {
                  backgroundColor: "#F6F6F6",
                  color: "#cc1d15",
                  "&:after": {
                    position: "absolute",
                    content: '""',
                    left: "1px",
                    top: "19%",
                    backgroundColor: "#cc1d15",
                    width: "3px",
                    borderTopRightRadius: "15px",
                    borderBottomRightRadius: "15px",
                    height: "60%",
                  },
                },
              }
            : {
                "&.Mui-selected": {
                  backgroundColor: "#fff",
                  color: "#cc1d15",
                  ".dot": {
                    outline: "2px solid #ECDCDC",
                    borderRadius: "100%",
                    WebkitBorderRadius: "100%",
                    MozBorderRadius: "100%",
                  },
                },
              }),
        }}
        ref={level == 1 ? popoverAnchor : null}
        {...(!openSidebar && {
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
        })}
        // selected={location.pathname.match(item.id) ? true : false}
      >
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: level > 1 ? 25 : 38,
            color: "inherit",
            paddingRight: "0px",
          }}
        >
          {itemIcon}
        </ListItemIcon>
        {openSidebar ? (
          <ListItemText
            primary={
              <Typography variant={"sidebarItem"} color="inherit">
                {item.title}
              </Typography>
            }
          />
        ) : !openSidebar && level > 1 ? (
          <ListItemText
            primary={
              <Typography variant={"sidebarItem"} color="inherit">
                {item.title}
              </Typography>
            }
          />
        ) : (
          ""
        )}
      </ListItemButton>
      {!openSidebar && level == 1 ? (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              pointerEvents: "auto",
              borderRadius: "8px",
              marginLeft: "2px",
              backgroundColor: "#fff",
            },
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
        >
          <ListItemButton
            component={CustomNavLink}
            to={item.url}
            end={item.id == "dasbor"}
          >
            <ListItemText
              primary={
                <Typography
                  variant={"sidebarItem"}
                  color="inherit"
                  sx={{ fontWeight: "bold" }}
                >
                  {item.title}
                </Typography>
              }
              sx={{
                textAlign: "center",
                padding: "5px 10px",
              }}
            />
          </ListItemButton>
        </Popover>
      ) : (
        ""
      )}
    </RoleProviders>
  );
};

NavItem.propTypes = {
  level: PropTypes.number,
  item: PropTypes.object,
  openSidebar: PropTypes.bool,
  setOpen: PropTypes.func,
};

NavItem.defaultProps = {
  item: {},
  level: 1,
  openSidebar: true,
  setOpen: () => {},
};

export default NavItem;
