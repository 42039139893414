import React from "react";

const Check = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10C19 15.2467 14.7467 19.5 9.5 19.5C4.25329 19.5 0 15.2467 0 10C0 4.75329 4.25329 0.5 9.5 0.5C14.7467 0.5 19 4.75329 19 10ZM17.8125 10C17.8125 14.5909 14.0909 18.3125 9.5 18.3125C4.90913 18.3125 1.1875 14.5909 1.1875 10C1.1875 5.40913 4.90913 1.6875 9.5 1.6875C14.0909 1.6875 17.8125 5.40913 17.8125 10ZM7.94979 13.9578C7.95769 13.9583 7.96506 13.9583 7.97296 13.9583C8.20409 13.9583 8.42627 13.867 8.59001 13.7032L14.7858 7.52664C15.127 7.18705 15.127 6.63647 14.7858 6.29636C14.4457 5.95677 13.8923 5.95677 13.5522 6.29636L8.00665 11.8253L5.47948 9.02303C5.15726 8.66612 4.60602 8.63673 4.24748 8.95795C3.88894 9.27916 3.85998 9.8287 4.1822 10.1861L7.32431 13.6702C7.48437 13.8476 7.71076 13.9515 7.94979 13.9578Z"
        fill="#979797"
      />
      <mask
        id="mask0_5757_380789"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 10C19 15.2467 14.7467 19.5 9.5 19.5C4.25329 19.5 0 15.2467 0 10C0 4.75329 4.25329 0.5 9.5 0.5C14.7467 0.5 19 4.75329 19 10ZM17.8125 10C17.8125 14.5909 14.0909 18.3125 9.5 18.3125C4.90913 18.3125 1.1875 14.5909 1.1875 10C1.1875 5.40913 4.90913 1.6875 9.5 1.6875C14.0909 1.6875 17.8125 5.40913 17.8125 10ZM7.94979 13.9578C7.95769 13.9583 7.96506 13.9583 7.97296 13.9583C8.20409 13.9583 8.42627 13.867 8.59001 13.7032L14.7858 7.52664C15.127 7.18705 15.127 6.63647 14.7858 6.29636C14.4457 5.95677 13.8923 5.95677 13.5522 6.29636L8.00665 11.8253L5.47948 9.02303C5.15726 8.66612 4.60602 8.63673 4.24748 8.95795C3.88894 9.27916 3.85998 9.8287 4.1822 10.1861L7.32431 13.6702C7.48437 13.8476 7.71076 13.9515 7.94979 13.9578Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5757_380789)">
        <rect y="0.5" width="19" height="19" fill="#129350" />
      </g>
    </svg>
  );
};

export default Check;
