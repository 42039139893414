import { createSlice } from "@reduxjs/toolkit";
import { getListDigitalID } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    pageSize: 10,
    page: 1,
    DocCardType: 2,
  },
  totalItems: 0,
  message: "",
  filterName: "DocNo",
  loading: false,
};

export const digitalIDSliceKTP = createSlice({
  name: "digitalIDKTP",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      let limit = action.payload.limit;
      state.params.pageSize = limit;
    },
    changeFilter: (state, action) => {
      state.params = {
        ...state.params,
        pageSize: 10,
        page: 1,
        [state.filterName]: action.payload,
      };
    },
    changePage: (state, action) => {
      let limit = action.payload.limit;
      state.params.pageSize = limit;
      state.params.page = action.payload.page;
    },
  },
  extraReducers: {
    [getListDigitalID.pending]: (state) => {
      state.loading = true;
    },
    [getListDigitalID.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    [getListDigitalID.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.data || [];
      state.totalItems = action.payload.paginator.total_record;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = digitalIDSliceKTP.actions;

export default digitalIDSliceKTP.reducer;
