import PropTypes from "prop-types";

const StepView = ({ activeStep, view, children }) => {
  if (activeStep == view) {
    return children;
  }
};

StepView.propTypes = {
  activeStep: PropTypes.any,
  children: PropTypes.node,
  view: PropTypes.any,
};

StepView.defaultProps = {
  activeStep: null,
  children: null,
  view: null,
};

export default StepView;
