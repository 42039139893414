import React from "react";

const DragIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99658 4.5C8.89658 4.5 7.99658 5.4 7.99658 6.5C7.99658 7.6 8.89658 8.5 9.99658 8.5C11.0966 8.5 11.9966 7.6 11.9966 6.5C11.9966 5.4 11.0966 4.5 9.99658 4.5ZM7.99658 12.5C7.99658 11.4 8.89658 10.5 9.99658 10.5C11.0966 10.5 11.9966 11.4 11.9966 12.5C11.9966 13.6 11.0966 14.5 9.99658 14.5C8.89658 14.5 7.99658 13.6 7.99658 12.5ZM9.99658 20.5C11.0966 20.5 11.9966 19.6 11.9966 18.5C11.9966 17.4 11.0966 16.5 9.99658 16.5C8.89658 16.5 7.99658 17.4 7.99658 18.5C7.99658 19.6 8.89658 20.5 9.99658 20.5ZM17.9966 6.5C17.9966 7.6 17.0966 8.5 15.9966 8.5C14.8966 8.5 13.9966 7.6 13.9966 6.5C13.9966 5.4 14.8966 4.5 15.9966 4.5C17.0966 4.5 17.9966 5.4 17.9966 6.5ZM15.9966 10.5C14.8966 10.5 13.9966 11.4 13.9966 12.5C13.9966 13.6 14.8966 14.5 15.9966 14.5C17.0966 14.5 17.9966 13.6 17.9966 12.5C17.9966 11.4 17.0966 10.5 15.9966 10.5ZM13.9966 18.5C13.9966 17.4 14.8966 16.5 15.9966 16.5C17.0966 16.5 17.9966 17.4 17.9966 18.5C17.9966 19.6 17.0966 20.5 15.9966 20.5C14.8966 20.5 13.9966 19.6 13.9966 18.5Z"
        fill="white"
      />
      <mask
        id="mask0_6780_39483"
        maskUnits="userSpaceOnUse"
        x="7"
        y="4"
        width="11"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99658 4.5C8.89658 4.5 7.99658 5.4 7.99658 6.5C7.99658 7.6 8.89658 8.5 9.99658 8.5C11.0966 8.5 11.9966 7.6 11.9966 6.5C11.9966 5.4 11.0966 4.5 9.99658 4.5ZM7.99658 12.5C7.99658 11.4 8.89658 10.5 9.99658 10.5C11.0966 10.5 11.9966 11.4 11.9966 12.5C11.9966 13.6 11.0966 14.5 9.99658 14.5C8.89658 14.5 7.99658 13.6 7.99658 12.5ZM9.99658 20.5C11.0966 20.5 11.9966 19.6 11.9966 18.5C11.9966 17.4 11.0966 16.5 9.99658 16.5C8.89658 16.5 7.99658 17.4 7.99658 18.5C7.99658 19.6 8.89658 20.5 9.99658 20.5ZM17.9966 6.5C17.9966 7.6 17.0966 8.5 15.9966 8.5C14.8966 8.5 13.9966 7.6 13.9966 6.5C13.9966 5.4 14.8966 4.5 15.9966 4.5C17.0966 4.5 17.9966 5.4 17.9966 6.5ZM15.9966 10.5C14.8966 10.5 13.9966 11.4 13.9966 12.5C13.9966 13.6 14.8966 14.5 15.9966 14.5C17.0966 14.5 17.9966 13.6 17.9966 12.5C17.9966 11.4 17.0966 10.5 15.9966 10.5ZM13.9966 18.5C13.9966 17.4 14.8966 16.5 15.9966 16.5C17.0966 16.5 17.9966 17.4 17.9966 18.5C17.9966 19.6 17.0966 20.5 15.9966 20.5C14.8966 20.5 13.9966 19.6 13.9966 18.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_39483)">
        <rect x="0.996582" y="0.5" width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default DragIcon;
