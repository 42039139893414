import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { getCookie } from "helper/cokkie";
import { Box, Grid, Typography } from "@mui/material";
import InisaLogo from "assets/login/new-inisa-logo.png";
import Ilu2 from "assets/login/ilustrasi2.png";
import { useSearchParams } from "react-router-dom";
import { alert } from "redux/global/slice";
import { useDispatch } from "react-redux";

const isLogin = getCookie("token-ntt-webadmin");
const PublicRoute = ({ ilustration, title, desc, children }) => {
  const [sparams] = useSearchParams();
  const dispatch = useDispatch();

  if (isLogin) {
    return window.location.replace("/");
  }

  useEffect(() => {
    if (sparams.get("w")) {
      localStorage.removeItem("permission-ntt");
      dispatch(
        alert({
          open: true,
          message: "Expired token atau token tidak sesuai",
        })
      );
    }
  }, []);

  const styles = {
    height: {
      lg: "455px",
      md: "270px",
      xs: "185px",
    },
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "auto",
    objectFit: "contain",
  };

  return (
    <>
      <Grid
        container
        sx={{
          height: {
            xs: "auto",
            md: "100%",
          },
          width: {
            xs: "auto",
            md: "100%",
          },
          position: "relative",
          marginTop: {
            xs: 10,
            md: 0,
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "1.5em",
              mt: "1.2em",
              position: "relative ",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                sx={{
                  width: {
                    xs: 25,
                    md: "62px",
                  },
                  objectFit: "contain",
                }}
                src={InisaLogo}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  "&:after": {
                    content: `""`,
                    width: {
                      md: "80px",
                      xs: "40px",
                    },
                    backgroundColor: "#cc1d15",
                    height: {
                      md: "3px",
                      xs: "1px",
                    },
                    position: "absolute",
                    bottom: {
                      md: -7,
                      xs: 5,
                    },
                    display: "block",
                  },
                }}
              >
                <Typography
                  variant="loginTitle"
                  sx={{
                    fontSize: {
                      md: 28,
                      xs: 14,
                    },
                  }}
                >
                  INISA Admin
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            height="calc(100% - 110px)"
            justifyContent="center"
            sx={{
              marginTop: {
                xs: "50px",
                md: 0,
              },
            }}
          >
            <Box component={"img"} src={ilustration} sx={styles} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              marginTop: {
                xs: "50px",
                md: "80px",
              },
              height: "calc(100% - 110px)",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // maxWidth: "530px",
                width: {
                  xs: "85%",
                  lg: "70%",
                },
              }}
            >
              <Typography variant="loginFormTitle" id="halo">
                {title}
              </Typography>
              <Typography variant="text2">{desc}</Typography>

              {children}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        component="img"
        src={Ilu2}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          width: {
            xs: "180px",
            md: "200px",
          },
        }}
      />
    </>
  );
};

PublicRoute.propTypes = {
  children: PropTypes.node,
  ilustration: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

PublicRoute.defaultProps = {
  children: null,
  ilustration: null,
  title: "",
  desc: "",
};

export default PublicRoute;
