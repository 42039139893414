import CSRIcon from "assets/layout/sidebar/CSRIcon";

const permissionList = [
  "Web:ProgramBantuan:All",
  "Web:ProgramBantuan:All",
];

const penerimaPermission = ["Web:PenerimaBantuan:All"];

const CSRBansos = {
  id: "csr-bansos",
  type: "collapse",
  title: "CSR Bansos",
  icon: CSRIcon,
  permission: [...permissionList, ...penerimaPermission],
  children: [
    {
      id: "program-bantuan",
      title: "Program Bantuan",
      type: "collapse",
      permission: permissionList,
      children: [
        {
          id: "kartu-subsidi",
          title: "Kartu Subsidi",
          type: "item",
          url: "/csr-bansos/program-bantuan/kartu-subsidi",
          permission: permissionList[0],
        },
        {
          id: "voucer-bansos",
          title: "Voucer Bansos",
          type: "item",
          url: "/csr-bansos/program-bantuan/voucer-bansos",
          permission: permissionList[1],
        },
      ],
    },
    {
      id: "penerima-bantuan",
      title: "Penerima Bantuan",
      type: "item",
      url: "/csr-bansos/penerima-bantuan",
      permission: penerimaPermission[0],
    },
  ],
};

export default CSRBansos;
