import PropTypes from "prop-types";
import { Box } from "@mui/material";
import React, { Fragment } from "react";
import DragCard from "./Card/DragCard";
import { useDragLayer } from "react-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function CustomDragLayer({ renderInput }) {
  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 99999,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  };

  const getItemStyles = (initialOffset, currentOffset, item, itemType) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }
    let { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      width: item.copy || itemType === "section" ? "700px" : "300px",
      backgroundColor: "#fff",
      outline: "1px solid #cc1d15",
      borderRadius: "8px",
    };
  };

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => {
      return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
      };
    });

  if (!isDragging) {
    return null;
  }

  function renderItem() {
    switch (itemType) {
      case "builder":
        return (
          <>
            {!item.copy ? (
              <DragCard icon={item.icon} name={item.name} desc={item.desc} />
            ) : null}
          </>
        );
      case "section":
        return (
          <>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                color: "#969696",
                padding: "5px",
                borderRadius: "4px",
                marginBottom: "4px",
                backgroundColor: "#31394c !important",
              }}
            >
              <DeleteIcon fontSize="small" />
              <ContentCopyIcon />
              <DragIndicatorIcon />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                padding: "10px 20px",
                border: "2px solid #CC1D15",
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                backgroundColor: "#fff !important",
              }}
            >
              {item?.content?.map((item, idx) => (
                <Fragment key={idx}>{renderInput(item)}</Fragment>
              ))}
            </Box>
          </>
        );
      case "opsi":
        return (
          <>
            <Box
              sx={{
                display: "flex",
                fontWeight: "600",
                gap: "20px",
                marginTop: "15px",
                backgroundColor: "#31394C",
                border: "2px solid #cc1d15",
                borderRadius: "4px",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                zIndex: "9999",
              }}
            >
              <Box
                sx={{
                  border: "none",
                  outline: "none",
                }}
              >
                <DragIndicatorIcon />
              </Box>
              <Box
                sx={{
                  border: "none",
                  outline: "none",
                }}
              >
                {item.label}
              </Box>
              <Box
                sx={{
                  border: "none",
                  outline: "none",
                }}
              >
                <DeleteIcon fontSize="small" />
              </Box>
            </Box>
          </>
        );
      default:
        return null;
    }
  }
  return (
    <>
      <Box sx={layerStyles}>
        <Box sx={getItemStyles(initialOffset, currentOffset, item, itemType)}>
          {renderItem()}
        </Box>
      </Box>
    </>
  );
}

CustomDragLayer.propTypes = {
  renderInput: PropTypes.func,
};

CustomDragLayer.defaultProps = {
  renderInput: null,
};
export default CustomDragLayer;
