import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeLimit,
  changePage,
  changeFilter,
} from "redux/managementPengguna/Pengguna/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import {
  deletePengguna,
  getListPengguna,
  getPengguna,
  getUserDetailNoRedux,
} from "redux/managementPengguna/action";
import RoleUser from "components/Status/RoleUser";
import MainCardHeader2 from "components/Paper/MainCardHeader2";
import DeleteDialog from "components/Dialog/DeleteDialog";
import useSelect from "hook/useSelect";
import Page from "components/Page";
import useAutoFetch from "hook/useAutoFetch";
import moment from "moment";

const Pengguna = () => {
  const [open, setOpen] = useState({
    open: false,
    import: false,
    isEdit: false,
  });

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListPengguna(params));

  const { list, loading, params, totalItems, filterName } = useSelect(
    (state) => state.pengguna
  );

  useAutoFetch(fetchData, params.page);

  const id = useRef(null);
  const [dialogData, setDialogData] = useState({});

  const headers = [
    {
      name: "Email ",
      key: "Email",
      width: 30,
    },
    {
      name: "Nama",
      key: "Fullname",
      width: 25,
    },
    {
      name: "Last Login",
      custom: (item) => {
        return moment().fromNow();
      },
      width: 30,
    },
    {
      name: "Source",
      key: "Source",
      width: 30,
    },
    {
      name: "Status",
      custom: (item) => <AktifStatus value={parseInt(item.Status)} />,
      width: 25,
    },
  ];

  const exportData = useFetchData({
    action: getPengguna,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.records,
        filename: "pengguna",
        opt: {
          header: ["Email", "Fullname", "LastUpdate", "RoleName", "Status"],
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const action = useFetchData({
    action: deletePengguna,
    message: "Berhasil hapus data",
    refresh: fetchData,
    onSuccess: () => {
      setOpen({
        ...open,
        delete: false,
      });
    },
  });

  return (
    <>
      <Page title="Pengguna" desc />

      <MainCard
        title="Daftar Pengguna"
        onClick={() => {
          setDialogData({});
          setOpen({
            ...open,
            open: true,
          });
        }}
        borderColor="#C8C8C8"
      >
        <MainCardHeader2
          filterName={filterName}
          changeFilter={changeFilter}
          params={params}
          onChangeExport={(type) => handleExport(type)}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="Fullname"
          detail={[
            {
              type: "link",
              url_key: "management-pengguna/pengguna:Id",
              tooltip: "Lihat Detail Pengguna",
              variant: "icon:eye2",
            },
          ]}
          increment={(params.page - 1) * params.limit + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={params?.page}
        />
      </MainCard>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        isEdit={open.isEdit}
        refresh={fetchData}
        data={dialogData}
      />

      <DeleteDialog
        open={open.delete}
        onClose={() => setOpen({ ...open, delete: false })}
        onSubmit={() => action.fetch(id.current)}
      />
    </>
    
  );
};

export default Pengguna;
