import React from "react";

const Email = () => {
  return (
    <svg
      width="33"
      height="20"
      viewBox="0 0 33 20"
      style={{
        width: "20px",
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8873 13.556L1.10854 3.3942C0.741695 3.10248 0.200195 3.36371 0.200195 3.83241V16.5906C0.200195 18.4459 1.70419 19.9499 3.55947 19.9499H24.2152C26.0705 19.9499 27.5745 18.4459 27.5745 16.5906V3.83241C27.5745 3.36371 27.033 3.10248 26.6662 3.3942L13.8873 13.556Z"
        fill="white"
      />
      <path
        d="M13.8873 11.0206L1.4724 1.14799C1.05753 0.818077 1.29082 0.149902 1.82088 0.149902H25.9538C26.4838 0.149902 26.7171 0.818078 26.3023 1.14799L13.8873 11.0206Z"
        fill="white"
      />
      <path
        d="M31.0205 8.02053C30.1684 7.18189 28.7986 7.19034 27.957 8.03943L22.9729 13.0676L21.4646 11.6602C20.6105 10.8633 19.2801 10.8813 18.4479 11.7009C17.5713 12.5642 17.5824 13.9814 18.4724 14.8309L22.4305 18.6087C22.7486 18.9123 23.251 18.9061 23.5615 18.5947L31.035 11.0994C31.8851 10.2468 31.8786 8.86511 31.0205 8.02053Z"
        fill="white"
        stroke="#CC1D15"
        strokeWidth="1.34488"
      />
    </svg>
  );
};

export default Email;
