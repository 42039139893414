import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import TrashIcon from "assets/paper/TrashIcon";

const DeleteButton = ({ item, hideDelete, handleClickDelete }) => {
  const show = typeof hideDelete == "function" ? hideDelete(item) : hideDelete;
  if (show) {
    return;
  }
  return (
    <Button
      variant="secondary-outlined-icon"
      sx={{
        width: "60px",
      }}
      onClick={handleClickDelete(item)}
    >
      <TrashIcon />
    </Button>
  );
};

DeleteButton.propTypes = {
  handleClickDelete: PropTypes.func,
  item: PropTypes.any,
  hideDelete: PropTypes.any,
};

DeleteButton.defaultProps = {
  handleClickDelete: () => {},
  item: {},
  hideDelete: true,
};

const AccordionMobile = ({
  list,
  headers,
  label,
  value,
  handleClickDetail,
  handleClickDelete,
  action,
  hideDelete,
  customAction,
}) => {
  return list?.map((row, index) => (
    <Accordion disableGutters key={index}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "&.Mui-expanded": {
            ".arrow": {
              transform: "rotate(90deg)",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              width: "50%",
            }}
          >
            <ChevronRight
              className="arrow"
              sx={{
                transition: "0.2s",
              }}
            />
            <Typography variant="text2">{label}</Typography>
          </Box>

          <Typography
            variant="label2"
            sx={{
              whiteSpace: "nowrap",
              width: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row[value]}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        {headers &&
          headers?.map((header, hi) => {
            return (
              <Fragment key={hi}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    sx={{
                      width: "calc(50% - 32px)",
                      marginLeft: "32px",
                    }}
                  >
                    <Typography variant="text2">{header.name}</Typography>
                  </Box>

                  <Typography
                    variant="label2"
                    sx={{
                      whiteSpace: "nowrap",
                      whiteSpace: "nowrap",
                      width: "50%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {header.key
                      ? row?.[header.key] || "-"
                      : header.custom
                      ? header?.custom(row)
                      : "-"}
                  </Typography>
                </Box>
                <Divider sx={{ borderColor: "#f5f5f5" }} />
              </Fragment>
            );
          })}

        {action && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Button
              variant="secondary-outlined"
              sx={{
                width: "100%",
              }}
              onClick={handleClickDetail(row)}
            >
              Lihat Detail
            </Button>
            <DeleteButton
              item={row}
              hideDelete={hideDelete}
              handleClickDelete={handleClickDelete}
            />
          </Box>
        )}

        {customAction && customAction(row)}
      </AccordionDetails>
    </Accordion>
  ));
};

AccordionMobile.propTypes = {
  handleClickDelete: PropTypes.func,
  handleClickDetail: PropTypes.func,
  headers: PropTypes.array,
  list: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  action: PropTypes.bool,
  hideDelete: PropTypes.any,
  customAction: PropTypes.any,
};

AccordionMobile.defaultProps = {
  handleClickDelete: () => {},
  handleClickDetail: () => {},
  action: true,
  label: "Fullname",
  value: "Fullname",
  headers: [],
  list: [],
  hideDelete: false,
  customAction: null,
};

export default AccordionMobile;
