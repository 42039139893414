import React from "react";

const Date = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4ZM5 20H19V10H5V20ZM7 14V12H9V14H7ZM11 14H13V12H11V14ZM15 14V12H17V14H15ZM7 16V18H9V16H7ZM13 18H11V16H13V18ZM15 16V18H17V16H15Z"
        fill="black"
      />
      <mask
        id="mask0_6780_21106"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="18"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4ZM5 20H19V10H5V20ZM7 14V12H9V14H7ZM11 14H13V12H11V14ZM15 14V12H17V14H15ZM7 16V18H9V16H7ZM13 18H11V16H13V18ZM15 16V18H17V16H15Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6780_21106)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default Date;
