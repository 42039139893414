import MainDialog from "components/Dialog/MainDialog";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MainGrid from "components/Grid/MainGrid";
import Validasi from "components/Status/Validasi";
import moment from "moment";
import FormatRupiah from "helper/FormatRupiah";
import { Box, SwipeableDrawer, Typography } from "@mui/material";
import DrawerTitle from "components/Mobile/DrawerTitle";
import AktifStatus from "components/Status/AktifStatus";

const DetailTransaksiMobile = ({ open, onClose, data, order, loading }) => {
  const tanggal = ["tgl_transaksi"];
  const status = ["status"];

  const rupiah = [
    "denda",
    "tagihan",
    "nominal",
    "harga",
    "biaya_admin",
    "biaya_transaksi",
    "total_transaksi",
    "total_tagihan",
    "total",
  ];

  const merge = [...tanggal, ...rupiah, ...status];

  const changeValue = (key, value) => {
    if (rupiah.includes(key)) {
      return `Rp. ${FormatRupiah(value)}`;
    }
    if (tanggal.includes(key)) {
      return moment(value).format("DD / MM / YYYY");
    }
    if (status.includes(key)) {
      return (
        <AktifStatus value={value} customText={["Aktif", "Tidak Aktif"]} auto/>
      );
    }
  };

  const changeLabel = (value) => {
    switch (value) {
      case "id_pelanggan":
        return "ID Pelanggan";
      case "tgl_transaksi":
        return "Tgl. Transaksi";
      case "no_transaksi":
        return "No. Transaksi";
      case "no_referensi":
        return "No. Referensi";
      default:
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {value.replaceAll("_", " ")}
          </span>
        );
    }
  };
  const newData = useMemo(() => {
    if (data) {
      const newArr = Object.keys(data)
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
        .map((item) => {
          if (merge.includes(item)) {
            data[item] = changeValue(item, data[item]);
          }
          return {
            label: changeLabel(item),
            value: data[item] == null || !data[item] ? "-" : data[item],
          };
        });
      return newArr;
    }
  }, [data]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onClose}
    >
      <DrawerTitle
        title="Detail Transaksi"
        toggle={onClose}
      />

      <Box display="flex" flexDirection="column" gap={3} p={2}>
        {newData.map((item, i) => (
          <Box
            key={i}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="text2">{item.label}</Typography>
            <Typography
              variant="text2"
              sx={{
                color: "#7F7F80",
              }}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </SwipeableDrawer>
  );
};

DetailTransaksiMobile.propTypes = {
  data: PropTypes.object,
  detail: PropTypes.array,
  loading: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  order: PropTypes.array,
};

DetailTransaksiMobile.defaultProps = {
  onClose: () => {},
  data: {},
  loading: false,
  open: false,
  detail: [],
  order: [],
};

export default DetailTransaksiMobile;
