import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import { newPenerbit, updatePenerbit } from "redux/penerbit/action";
import { Collapse, Grid, OutlinedInput } from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import Radio from "components/Radio/Radio";
import TimePicker from "components/Picker/TimePicker";
import DatePicker2 from "components/Picker/DatePicker2";
import moment from "moment";
import { createNotifBroadcast } from "redux/broadcast/action";

const radio = [
  {
    label: "Kirim Sekarang",
    value: 1,
  },
  {
    label: "Jadwalkan Nanti",
    value: 0,
  },
];

const Dialog = ({ open, onClose, refresh, data, id, setRes }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset: resetForm,
    clearErrors,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      messageTitle: "",
      messageBody: "",
      sendDate: null,
      isAutoSend: null,
      sendDate: {
        day: "",
        minutes: "",
      },
    },
  });

  const action = useFetchData({
    action: createNotifBroadcast,
    reset: resetForm,
    message: "Notif baru berhasil",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (data) {
      resetForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const onSubmit = async (data) => {
    if (typeof data.sendDate == "object") {
      const { day, minutes } = data.sendDate;
      data.sendDate = `${day} ${minutes}:00`;
    }

    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      if (data[item] !== null && data[item] !== undefined) {
        formData.append(item, data[item]);
      }
    });
    await action.fetch(formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title="Buat Campaign Baru"
      loading={action.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      width="sm"
      type={1}
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Judul"} md={12}>
          <MainController
            {...getProps("messageTitle")}
            rules={{
              required: "Judul wajib diisi",
            }}
            desc={`Character : ${watch("messageTitle")?.length || "0"}`}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                error={invalid}
                variant="v1"
                placeholder="Masukan judul"
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Konten"} md={12}>
          <MainController
            {...getProps("messageBody")}
            rules={{
              required: "Koten wajib diisi",
            }}
            desc={`Character : ${watch("messageBody")?.length || "0"}`}
            render={({ field, fieldState: { invalid } }) => (
              <OutlinedInput
                {...field}
                error={invalid}
                multiline
                variant="v1"
                rows={6}
                placeholder="Masukan konten"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Jadwal Pengiriman"} md={12}>
          <MainController
            {...getProps("isAutoSend")}
            rules={{
              required: "Koten wajib diisi",
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Radio
                {...field}
                data={radio}
                onChange={(e) => {
                  const value = parseInt(e);
                  field.onChange(value);

                  if (value == 1) {
                    console.log(moment().format("YYYY-MM-DD"));
                    setValue("sendDate", {
                      day: moment().format("YYYY-MM-DD"),
                      minutes: moment().format("HH:mm"),
                    });
                  } else {
                    setValue("sendDate", {
                      day: "",
                      minutes: "",
                    });
                  }
                }}
              />
            )}
          />
        </VerticalGrid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <VerticalGrid label="Pilih Tanggal" md={6}>
              <MainController
                {...getProps("sendDate.day")}
                rules={
                  watch("isAutoSend") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field, fieldState: { invalid } }) => {
                  return (
                    <DatePicker2
                      variant="v1"
                      disabled={watch("isAutoSend") == 1}
                      {...field}
                      error={invalid}
                      placeholder="DD/MM/YYYY"
                    />
                  );
                }}
              />
            </VerticalGrid>

            <VerticalGrid label="Pilih Waktu" md={6}>
              <MainController
                {...getProps("sendDate.minutes")}
                rules={
                  watch("isAutoSend") == 0
                    ? { required: "Waktu wajib diisi" }
                    : {}
                }
                render={({ field }) => {
                  const split = field.value?.split(":") || [];

                  return (
                    <TimePicker
                      disabled={watch("isAutoSend") == 1}
                      hours={split[0]}
                      minutes={split[1]}
                      onChangeHours={(e) => {
                        split[0] = e;
                        field.onChange(split.join(":"));
                      }}
                      onChangeMinutes={(e) => {
                        split[1] = e;
                        field.onChange(split.join(":"));
                      }}
                      variant="v1"
                    />
                  );
                }}
              />
            </VerticalGrid>
          </Grid>
        </Grid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
