import PropTypes from "prop-types";
import { Box, Grid, IconButton, styled } from "@mui/material";
import React, { Fragment, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EmptyColumn from "./EmptyColumn";
import DropTopColumn from "./DropTopColumn";

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, copy, columnright, columnleft }) => ({
  borderRadius: "4px",
  padding: "4px 10px !important",
  transition: "0.1s",
  position: "relative",
  ...(active
    ? {
        border: "2px solid #cc1d15",
        transition: "0.1s",
      }
    : copy
    ? {
        display: "flex",
        gap: "10px",
        border: "2px dashed #cc1d15",
        transition: "0.1s",
        ...(columnright
          ? {
              borderRight: "none !important",
              borderTopRightRadius: "0px !important",
              borderBottomRightRadius: "0px !important",
            }
          : columnleft
          ? {
              borderLeft: "none !important",
              borderTopLeftRadius: "0px !important",
              borderBottomLeftRadius: "0px !important",
            }
          : {}),
      }
    : {}),
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ active }) => ({
  top: "-48px",
  left: "0px",
  backgroundColor: "#31394c",
  display: "flex",
  justifyContent: "space-between",
  color: "#fff",
  padding: "5px 8px",
  borderRadius: "4px",
  position: "absolute",
  visibility: "hidden",
  alignItems: "center",
  justifyContent: "center",
  gap: 4,
  opacity: "0",
  transition: "visibility 0s, opacity 0.5s ease-in-out",
  ...(active
    ? {
        visibility: "visible",
        opacity: 1,
      }
    : {}),
}));

const Items = ({
  section,
  item,
  index,
  renderInput,
  handleSortable,
  currentForm,
  handleDeleteInput,
  handleClickItem,
  handleChange,
  handleUpdateEmptyColumn,
  handleDrop,
  handleDropLeft,
  handleDropRight,
  isColumn1,
  setCurrentForm,
}) => {
  const dragRef = useRef(null);
  const dropRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "section",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === undefined) {
        return;
      }
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleSortable(section, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "section",
    item: () => {
      return { ...item, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isDraggingCopy }, dragCopy, previewCopy] = useDrag({
    type: "builder",
    item: () => {
      return { ...item, index, copy: true };
    },
    collect: (monitor) => ({
      isDraggingCopy: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    previewCopy(getEmptyImage(), { captureDraggingState: true });
  }, []);

  drag(dragRef);
  drop(dropRef);

  return (
    <Grid container spacing={1}>
      <Grid style={{ padding: "0px" }} item xs={12}>
        <DropTopColumn
          item={item}
          index={index}
          section={section}
          handleDrop={handleDrop}
          handleDropLeft={handleDropLeft}
          handleDropRight={handleDropRight}
          isColumn1={isColumn1}
          currentForm={currentForm}
          setCurrentForm={setCurrentForm}
        />
      </Grid>
      <StyledGrid
        container
        style={{ padding: "0px" }}
        ref={dropRef}
        data-handler-id={handlerId}
      >
        {item?.content?.map((v, i) => (
          <Fragment key={v.id}>
            {v.type ? (
              <StyledGrid
                item
                xs={12 / item.content.length}
                onClick={(e) =>
                  handleClickItem(e, v, section, item.content.length)
                }
                id={`${section}-${item.id}-${i + 1}`}
                active={currentForm === v.id && !isDragging ? 1 : 0}
                copy={isDragging || isDraggingCopy ? 1 : 0}
                columnright={
                  item.content.length === 2 && i === 0 && item.content[1]?.type
                    ? 1
                    : 0
                }
                columnleft={
                  item.content.length === 2 && i === 1 && item.content[0]?.type
                    ? 1
                    : 0
                }
              >
                <StyledBox active={currentForm === v.id ? 1 : 0}>
                  <IconButton
                    sx={{
                      p: 0,
                      color: "#fff !important",
                    }}
                    onClick={() =>
                      handleDeleteInput(section, v.id, item.content.length)
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>

                  {item.content.length === 2 && i === 1 ? (
                    ""
                  ) : (
                    <>
                      <IconButton
                        sx={{
                          p: 0,
                          color: "#fff !important",
                        }}
                        ref={dragCopy}
                        onDragStart={() => {
                          handleChange("_", 0);
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>

                      <IconButton
                        sx={{
                          p: 0,
                          color: "#fff !important",
                        }}
                        ref={dragRef}
                      >
                        <DragIndicatorIcon fontSize="medium" />
                      </IconButton>
                    </>
                  )}
                  {(item.content.length === 2 && item.content[0].type) ||
                  item.content.length === 1 ? (
                    ""
                  ) : (
                    <>
                      <IconButton
                        sx={{
                          p: 0,
                          color: "#fff !important",
                        }}
                        ref={dragCopy}
                        onDragStart={() => {
                          handleChange("_", 0);
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0,
                          color: "#fff !important",
                        }}
                        ref={dragRef}
                      >
                        <DragIndicatorIcon fontSize="medium" />
                      </IconButton>
                    </>
                  )}
                </StyledBox>

                {renderInput(v)}
              </StyledGrid>
            ) : (
              <Grid item xs={6}>
                <EmptyColumn
                  itemId={item.id}
                  section={section}
                  v={v}
                  handleUpdateEmptyColumn={handleUpdateEmptyColumn}
                />
              </Grid>
            )}
          </Fragment>
        ))}
      </StyledGrid>
    </Grid>
  );
};

Items.propTypes = {
  currentForm: PropTypes.any,
  handleChange: PropTypes.func,
  handleClickAway: PropTypes.any,
  handleClickItem: PropTypes.func,
  handleDeleteInput: PropTypes.func,
  handleDrop: PropTypes.any,
  handleDropLeft: PropTypes.any,
  handleDropRight: PropTypes.any,
  handleSortable: PropTypes.func,
  handleUpdateEmptyColumn: PropTypes.any,
  index: PropTypes.any,
  isColumn1: PropTypes.any,
  item: PropTypes.any,
  renderInput: PropTypes.func,
  section: PropTypes.any,
  setCurrentForm: PropTypes.any,
};

Items.defaultProps = {
  currentForm: null,
  handleChange: null,
  handleClickItem: null,
  handleDeleteInput: null,
  handleSortable: null,
  renderInput: null,
  index: null,
  item: null,
  section: null,
  handleClickAway: null,
  handleUpdateEmptyColumn: null,
  handleDrop: null,
  handleDropLeft: null,
  handleDropRight: null,
  isColumn1: null,
  setCurrentForm: null,
};
export default Items;
