import { createSlice } from "@reduxjs/toolkit";
import getRole from "helper/getRole";
import { pagination } from "redux/pagination";
import { getListKTP } from "../action";
const { Id: user_id } = JSON.parse(localStorage.getItem("profile-ntt")) || "";
export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
    filter: {
      type: "m_ktp",
    },
    user_id,
    role: getRole()?.isSuperVisor
      ? "supervisor"
      : getRole()?.isOperator
      ? "operator"
      : "",
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const KTPSlice = createSlice({
  name: "kependudukanKTP",
  initialState,
  reducers: pagination,
  extraReducers: {
    [getListKTP.pending]: (state) => {
      state.loading = true;
    },
    [getListKTP.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListKTP.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = KTPSlice.actions;

export default KTPSlice.reducer;
