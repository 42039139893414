import { Box, Typography } from "@mui/material";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Page from "components/Page";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import AktifStatus from "components/Status/AktifStatus";
import BannerStatus from "components/Status/BannerStatus";
import Validasi from "components/Status/Validasi";
import MainTable from "components/Table/MainTable";
import ExportHelper from "helper/ExportHelper";
import useAutoFetch from "hook/useAutoFetch";
import useFetchData from "hook/useFetchData";
import useSelect from "hook/useSelect";
import moment from "moment";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDokumen,
  getListDokumen,
  getListDokumenAll,
} from "redux/CMS/action";
import {
  addFilter,
  changeFilter,
  changeFilterName,
  changeLimit,
  changePage,
  deleteFilter,
} from "redux/CMS/Dokumen/slice";
import Dialog from "./Dialog";

const CMSDokumen = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.CMSDokumen);

  const navigate = useNavigate();

  const [open, setOpen] = useState({
    delete: false,
    dialog: false,
  });

  const id = useRef();

  const dispatch = useDispatch();

  const fetchData = () => dispatch(getListDokumen(params));

  useAutoFetch(fetchData, params, currentPage, filterName);

  const headers = [
    {
      name: "#",
      align: "center",
      custom: (item) => {
        return (
          <Box
            sx={{ minWidth: "50px", marginLeft: "25px", marginRight: "10px" }}
          >
            {item?.ikon ? (
              <Box
                component="img"
                src={item.ikon}
                sx={{
                  width: "60px",
                  height: "36px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
            ) : (
              <Box>-</Box>
            )}
          </Box>
        );
      },
      width: 10,
    },
    {
      name: "Nama Dokumen",
      custom: (item) => {
        return (
          <Box
            onClick={() => {
              //   if (mobilePreview?.loading) return;
              //   getFormDetail(item.m_form_id);
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                "*": {
                  color: "#cc1d15 !important",
                },
              },
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              variant="text2"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {item?.doc_title}
            </Typography>
            <Typography
              variant="text2"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {item?.alias_formulir}
            </Typography>
          </Box>
        );
      },
      width: 30,
    },
    {
      name: "Kategori",
      key: "kategori",
      width: 20,
    },
    {
      name: "Sub Kategori",
      key: "sub_kategori",
      width: 20,
    },
    {
      name: "Menu",
      key: "menu",
      width: 20,
    },
    {
      name: "Sub Menu",
      key: "sub_menu",
      width: 20,
    },
    {
      name: "Instansi",
      key: "instansi",
      width: 20,
    },
    {
      name: "Adjudikasi",
      width: 10,
      custom: (item) => <Validasi value={item.adjudikasi} />,
    },
    {
      name: "Status",
      width: 10,
      custom: (item) => (
        <AktifStatus minWidth="144px" maxWidth="144px" value={item.status} />
      ),
    },
    {
      name: "Tgl Dibuat",
      width: 10,
      custom: (item) =>
        item?.tanggal_dibuat
          ? moment(item?.tanggal_dibuat).format("DD / MM / YYYY")
          : "-",
    },
    {
      name: "Tgl Status",
      width: 10,
      custom: (item) =>
        item?.tanggal_status
          ? moment(item?.tanggal_status).format("DD / MM / YYYY")
          : "-",
    },
  ];

  const filter = [
    { label: "Nama Dokumen", value: "doc_title" },
    { label: "Menu", value: "menu" },
    { label: "Sub Menu", value: "sub_menu" },
    { label: "Instansi", value: "instansi" },
  ];

  const filterData = [
    {
      label: "Status",
      key: "status",
      type: "switch",
    },
    {
      label: "Adjudikasi",
      key: "adjudikasi",
      type: "switch",
    },
  ];

  const exportData = useFetchData({
    action: getListDokumenAll,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "dokumen",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const deleteData = useFetchData({
    action: deleteDokumen,
    message: "Dokumen Berhasil di hapus",
    onFinal: () => {
      setOpen({
        ...open,
        delete: false,
      });
      fetchData();
    },
  });

  return (
    <>
      <Page title="Kelola Dokumen" />

      <MainCard
        sx={{
          mt: 2,
          borderRadius: "8px",
        }}
        title="Daftar Dokumen"
        onClick={() =>
          setOpen({
            ...open,
            dialog: true,
          })
        }
      >
        <MainCardHeader
          filter={filter}
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          filterData={filterData}
          addFilter={addFilter}
          title="Filter Banner"
          onChangeExport={(type) => handleExport(type)}
        />
        <MainTable
          headers={headers}
          no={false}
          loading={loading}
          data={list}
          summaryKey="doc_title"
          detail={[
            {
              type: "button",
              tooltip: "Hapus Banner",
              variant: "icon:delete",
              onClick: (e, item) => {
                id.current = item.id;
                setOpen({
                  ...open,
                  delete: true,
                });
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
        isEdit={false}
      />

      <DeleteDialog
        open={open.delete}
        loading={deleteData.loading}
        onClose={() =>
          setOpen({
            ...open,
            delete: false,
          })
        }
        onSubmit={() => {
          deleteData.fetch(id.current);
        }}
      />
    </>
  );
};

export default CMSDokumen;
