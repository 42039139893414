import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";

const DropTopColumn = ({
  section,
  index,
  handleDrop,
  handleDropLeft,
  handleDropRight,
  isColumn1,
  currentForm,
  setCurrentForm,
}) => {
  const [{ isOverCurrent }, drop] = useDrop({
    accept: "builder",
    drop: (item) => {
      if (isOverCurrent) {
        handleDrop(section, item, index, true);
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver(),
    }),
    hover: () => {
      if (currentForm) {
        setCurrentForm(null);
      }
    },
  });

  const [{ isOverCurrentLeft }, dropLeftColumn] = useDrop({
    accept: "builder",
    drop: (item) => {
      if (isOverCurrentLeft) {
        handleDropLeft(section, item, index, true);
      }
    },
    collect: (monitor) => ({
      isOverCurrentLeft: monitor.isOver(),
    }),
    hover: () => {
      if (currentForm) {
        setCurrentForm(null);
      }
    },
  });

  const [{ isOverCurrentRight }, dropRightColumn] = useDrop({
    accept: "builder",
    drop: (item) => {
      if (isOverCurrentRight) {
        handleDropRight(section, item, index, true);
      }
    },
    collect: (monitor) => ({
      isOverCurrentRight: monitor.isOver(),
    }),
    hover: () => {
      if (currentForm) {
        setCurrentForm(null);
      }
    },
  });

  return (
    <Grid container>
      {isColumn1 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "block",
            padding: "0px 17px",
          }}
        >
          <Box
            ref={drop}
            sx={{
              height: "20px",
              padding: "0px 0px 0px",
              transition: "height 0.1s ease-in-out",
              ...(!isOverCurrent ? {} : { height: "139px" }),
            }}
          >
            <Box
              sx={{
                ...(isOverCurrent
                  ? {
                      border: "3px dashed #cc1d15",
                      borderRadius: "4px",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      height: "140px",
                      marginTop: "10px",
                      color: "#cc1d15",
                      width: "100%",
                      display: "flex",
                      fontSize: "large",
                      boxSizing: "border-box",
                    }
                  : {}),
              }}
            >
              {isOverCurrent ? "Taruh di sini untuk menambahkan" : ""}
            </Box>
          </Box>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={6}
            sx={{
              display: "block",
              paddingLeft: "17px",
            }}
          >
            <Box
              ref={dropLeftColumn}
              sx={{
                height: "20px",
                padding: "0px 0px 0px",
                transition: "height 0.1s ease-in-out",
                ...(!isOverCurrentLeft ? {} : { height: "139px" }),
              }}
              // className="drop__column"
            >
              <Box
                sx={{
                  ...(isOverCurrentLeft
                    ? {
                        border: "3px dashed #cc1d15",
                        borderRadius: "4px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        height: "140px",
                        marginTop: "10px",
                        color: "#cc1d15",
                        width: "100%",
                        display: "flex",
                        fontSize: "large",
                        boxSizing: "border-box",
                      }
                    : {}),
                }}
              >
                {isOverCurrentLeft ? "Taruh di sini untuk menambahkan" : ""}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "block",
              paddingRight: "17px",
            }}
          >
            <Box
              ref={dropRightColumn}
              sx={{
                height: "20px",
                padding: "0px 0px 0px",
                transition: "height 0.1s ease-in-out",
                ...(!isOverCurrentRight ? {} : { height: "139px" }),
              }}
            >
              <Box
                sx={{
                  ...(isOverCurrentRight
                    ? {
                        border: "3px dashed #cc1d15",
                        borderRadius: "4px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        height: "140px",
                        marginTop: "10px",
                        color: "#cc1d15",
                        width: "100%",
                        display: "flex",
                        fontSize: "large",
                        boxSizing: "border-box",
                      }
                    : {}),
                }}
              >
                {isOverCurrentRight ? "Taruh di sini untuk menambahkan" : ""}
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

DropTopColumn.propTypes = {
  currentForm: PropTypes.any,
  handleDrop: PropTypes.func,
  handleDropLeft: PropTypes.func,
  handleDropRight: PropTypes.func,
  index: PropTypes.any,
  isColumn1: PropTypes.any,
  propertiesRef: PropTypes.any,
  section: PropTypes.any,
  setCurrentForm: PropTypes.func,
};

DropTopColumn.defaultProps = {
  currentForm: null,
  handleDrop: null,
  handleDropLeft: null,
  handleDropRight: null,
  index: null,
  isColumn1: null,
  propertiesRef: null,
  section: null,
  setCurrentForm: null,
};

export default DropTopColumn;
