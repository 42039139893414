export const kartuKeluargaData = (data) => {
  return {
    no_kk: data.no_kk,
    nama_kepala_keluarga: data.nama_kepala_keluarga,
    provinsi_id: data.provinsi_id,
    kota_id: data.kota_id,
    kecamatan_id: data.kecamatan_id,
    kelurahan_id: data.kelurahan_id,
    warding: {
      no_kk_warding: data.no_kk_warding,
      nama_kepala_keluarga_warding: data.nama_kepala_keluarga_warding,
      provinsi_kk_warding: data.provinsi_kk_warding,
      kota_kk_warding: data.kota_kk_warding,
      kecamatan_kk_warding: data.kecamatan_kk_warding,
      kelurahan_kk_warding: data.kelurahan_kk_warding,
    },
  };
};

export const KartuKeluargaForm = ({
  provinsi,
  kota,
  kecamatan,
  kelurahan,
  setValue,
  getValues,
  setError,
}) => {
  return [
    {
      label: "No. KK",
      name: "kartukeluarga.no_kk",
      warding: "kartukeluarga.warding.no_kk_warding",
      data: "no_kk",
      commentKey: "no_kk_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: {
        required: "No. KK kartu keluarga wajib diisi",
        maxLength: {
          message: "NO. KK kartu keluarga tidak boleh melebihi 16 karakter",
          value: 16,
        },
      },
    },
    {
      label: "Kepala Keluarga",
      name: "kartukeluarga.nama_kepala_keluarga",
      warding: "kartukelaurga.warding.nama_kepala_keluarga_warding",
      data: "nama_kepala_keluarga",
      commentKey: "nama_kepala_keluarga_warding",
      comment: "",
      value: "",
      openNotes: false,
      openComment: false,
      rules: { required: "Nama Kepala Keluarga  wajib diisi" },
    },
    {
      label: "Provinsi",
      data: "provinsi_id",
      warding: "kartukeluarga.warding.provinsi_kk_warding",
      name: "kartukeluarga.provinsi_id",
      value: "",
      comment: "",
      commentKey: "provinsi_kk_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        provinsi.fetch();
        setValue("forms.kartukeluarga[2].loading", true);
      },
      loading: true,
      rules: { required: "Provinsi kartu keluarga wajib diisi" },
    },
    {
      label: "Kota / Kab.",
      name: "kartukeluarga.kota_id",
      warding: "kartukeluarga.warding.kota_kk_warding",
      data: "kota_id",
      value: "",
      comment: "",
      commentKey: "kota_kk_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!getValues("kartukeluarga.provinsi_id")) {
          return setError("forms.kartukeluarga[3].value", {
            type: "noid",
            message: "Pilih Provinsi kartu keluar dahulu",
          });
        }
        kota.fetch(getValues("kartukeluarga.provinsi_id"));
        setValue("forms.kartukeluarga[3].loading", true);
      },
      loading: true,
      rules: { required: "Kota kartu keluarga wajib diisi" },
    },
    {
      name: "kartukeluarga.kecamatan_id",
      warding: "kartukeluarga.warding.kecamatan_kk_warding",
      label: "Kecamatan",
      data: "kecamatan_id",
      value: "",
      comment: "",
      commentKey: "kecamatan_kk_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("kartukeluarga.kota_id"))) {
          return setError("forms.kartukeluarga[4].value", {
            type: "noid",
            message: "Pilih kota kartu keluarga Terlebih Dahulu",
          });
        }
        kecamatan.fetch(getValues("kartukeluarga.kota_id"));
        setValue("forms.kartukeluarga[4].loading", true);
      },
      loading: true,
      rules: { required: "Kecamatan kartu keluarga wajib diisi" },
    },
    {
      name: "kartukeluarga.kelurahan_id",
      warding: "kartukeluarga.warding.kelurahan_kk_warding",
      label: "Kel. /  Desa",
      data: "kelurahan_id",
      value: "",
      comment: "",
      commentKey: "kelurahan_kk_warding",
      openNotes: false,
      openComment: false,
      type: "autocomplete",
      options: [],
      onOpen: () => {
        if (!Boolean(getValues("kartukeluarga.kecamatan_id"))) {
          return setError("forms.kartukeluarga[5].value", {
            type: "noid",
            message: "Pilih Kecamatan kartu keluarga Terlebih Dahulu",
          });
        }
        kelurahan.fetch(getValues("kartukeluarga.kecamatan_id"));
        setValue("forms.kartukeluarga[7].loading", true);
      },
      loading: true,
      rules: { required: "Kelurahan kartu keluarga wajib diisi" },
    },
  ];
};
